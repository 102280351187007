import { Injectable } from '@angular/core';

import { ID, RigActivity } from '@cyberloop/core';
import { UpdateFileMeta } from '@cyberloop/web/files/model';
import { MeterageItem } from '@cyberloop/web/wells/model';

import { FilesProviderService } from './abstractions/files-provider.service';
import { FilesTagProviderService } from './abstractions/files-tag-provider.service';

@Injectable({ providedIn: 'root' })
export class FilesDataService {
    constructor(
        private readonly filesProviderService: FilesProviderService,
        private readonly filesTagProviderService: FilesTagProviderService
    ) { }

    //#region FileMeta

    watchWellFiles(wellId: ID, planningId?: ID) {
        return this.filesProviderService.watchWellFiles(wellId, planningId);
    }

    watchPlanningFiles(planningId: ID) {
        return this.filesProviderService.watchPlanningFiles(planningId);
    }

    watchByIDs(ids: string[]) {
        return this.filesProviderService.watchByIDs(ids);
    }

    getDownloadLink(id: ID) {
        return this.filesProviderService.getDownloadLink(id);
    }

    createWellFile(file: File, wellId: ID) {
        return this.filesProviderService.createWellFile(file, wellId);
    }

    createWellMeterageFile(file: File, wellId: ID, itemId: number, itemList: MeterageItem[]) {
        return this.filesProviderService.createWellMeterageFile(file, wellId, itemId, itemList);
    }

    createPlanningFile(file: File, planningId: ID) {
        return this.filesProviderService.createPlanningFile(file, planningId);
    }

    createPlanningStageFile(file: File, planningId: ID, stageId: ID, activityId: RigActivity) {
        return this.filesProviderService.createPlanningStageFile(file, planningId, stageId, activityId);
    }

    update(id: ID, update: UpdateFileMeta) {
        return this.filesProviderService.update(id, update);
    }

    delete(id: ID) {
        return this.filesProviderService.delete(id);
    }

    //#endregion FileMeta

    //#region Tag

    watchAllTags() {
        return this.filesTagProviderService.watchAll();
    }

    createTag(name: string) {
        return this.filesTagProviderService.create(name);
    }

    //#endregion Tag
}
