import { Injectable } from '@angular/core';

import { SettingsProviderService, WellKnownUnitIds } from '@cyberloop/core';
import { tndChartDefaultSettings } from '@cyberloop/web/tnd/model';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Store, createAction } from '@ngrx/store';
import { Observable, catchError, filter, first, from, of, switchMap } from 'rxjs';

import { TndActions } from './tnd.actions';
import { TndSelectors } from './tnd.selectors';
import { TND_FEATURE } from './tnd.state';

import type { TndChartSettings } from '@cyberloop/web/tnd/model';
import type { Action } from '@ngrx/store';

/**
 * Settings of a T&D that can be stored in a DB
 */
type TndChartSettingsSerializable = Omit<TndChartSettings, 'selectedModelId'>;

/**
 * T&D settings, as it is stored in DB
 */
type TndSettings = {
    weightsChartSettings: TndChartSettingsSerializable,
    torqueChartSettings: TndChartSettingsSerializable
}

/** @internal */
const tndSettingsDefault: TndSettings = {
    weightsChartSettings: tndChartDefaultSettings,
    torqueChartSettings: tndChartDefaultSettings
};

/** @internal */
const settingsKey = 'tnd.settings';

/** @internal */
const initAction = createAction(`[${TND_FEATURE}] Initialize T&D`);

@Injectable()
export class TndEffects implements OnInitEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly settings: SettingsProviderService
    ) { }

    /** @internal */
    readonly onInitAction$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),

        // On init we'll watch for settings changes
        switchMap(() => this.settings.watchSettings<TndSettings>(settingsKey, tndSettingsDefault)),
        filter(Boolean),

        // Save last widgets
        switchMap(settings => ([
            TndActions.setWeightsChartSettings({ settings: settings.weightsChartSettings }),
            TndActions.setTorqueChartSettings({ settings: settings.torqueChartSettings })
        ])),

        catchError(error => ([
            TndActions.setError({ error })
        ]))
    ));


    /**
     * When settings of the Weights chart changes, we'll dispatch
     * setWeightsChartSettings action with the new chart settings.
     */
    readonly onUpdateWeightsSettings$ = createEffect(() => this.actions$.pipe(
        ofType(TndActions.updateWeightsChartSettings),

        switchMap(({ settings }) => {
            const newSettings = { ...settings };

            // Pick the current settings so we could roll it back in case of error
            return this.store.select(TndSelectors.weightsChartSettings).pipe(
                first(),

                // Put new settings to the store immediately
                switchMap(lastSettings => {
                    // And put new ones to the state
                    this.store.dispatch(TndActions.setWeightsChartSettings({
                        settings: newSettings
                    }));

                    let ob$: Observable<boolean>;

                    // Try to save settings to the storage
                    try {
                        ob$ = from(this.settings.updateSettings<TndSettings>(settingsKey, {
                            weightsChartSettings: newSettings
                        }));
                    }
                    catch (e) {
                        console.error('Could not save T&D weights settings', e);
                        ob$ = of(false);
                    }

                    return ob$.pipe(
                        switchMap(saved => {
                            if (!saved) {
                                // Roll back
                                return [ TndActions.setWeightsChartSettings({
                                    settings: lastSettings ?? tndChartDefaultSettings
                                }) ];
                            }
                            else {
                                // Do nothing - widgets was saved
                                return [];
                            }
                        })
                    );
                })
            );
        })
    ));

    /**
     * When settings of the Torque chart changes, we'll dispatch
     * updateTorqueChartSettings action with the new chart settings.
     */
    readonly onUpdateTorqueSettings$ = createEffect(() => this.actions$.pipe(
        ofType(TndActions.updateTorqueChartSettings),

        switchMap(({ settings }) => {
            const newSettings = { ...settings };

            // Pick the current settings so we could roll it back in case of error
            return this.store.select(TndSelectors.torqueChartSettings).pipe(
                first(),

                // Put new settings to the store immediately
                switchMap(lastSettings => {
                    // And put new ones to the state
                    this.store.dispatch(TndActions.setTorqueChartSettings({
                        settings: newSettings
                    }));

                    let ob$: Observable<boolean>;

                    // Try to save settings to the storage
                    try {
                        ob$ = from(this.settings.updateSettings<TndSettings>(settingsKey, {
                            torqueChartSettings: newSettings
                        }));
                    }
                    catch (e) {
                        console.error('Could not save T&D torque settings', e);
                        ob$ = of(false);
                    }

                    return ob$.pipe(
                        switchMap(saved => {
                            if (!saved) {
                                // Roll back
                                return [ TndActions.setTorqueChartSettings({
                                    settings: lastSettings ?? tndChartDefaultSettings
                                }) ];
                            }
                            else {
                                // Do nothing - widgets was saved
                                return [];
                            }
                        })
                    );
                })
            );
        })
    ));


    readonly onUpdateXUnitForWeights$ = createEffect(() => this.actions$.pipe(
        ofType(TndActions.updateXUnitIdForWeightsChart),

        switchMap(({ unitId }) => {
            return this.store.select(TndSelectors.weightsChartSettings).pipe(
                first(),
                switchMap(settings => {
                    const newSettings = { ...settings };

                    if (unitId) {
                        newSettings.xUnitId = unitId;
                    }
                    else {
                        delete newSettings.xUnitId;
                    }

                    return [
                        TndActions.updateWeightsChartSettings({
                            settings: newSettings
                        })
                    ];
                })
            );
        })
    ));

    readonly onUpdateXUnitForTorque$ = createEffect(() => this.actions$.pipe(
        ofType(TndActions.updateXUnitIdForTorqueChart),

        switchMap(({ unitId }) => {
            return this.store.select(TndSelectors.torqueChartSettings).pipe(
                first(),
                switchMap(settings => {
                    const newSettings = { ...settings };

                    if (unitId) {
                        newSettings.xUnitId = unitId;
                    }
                    else {
                        delete newSettings.xUnitId;
                    }

                    return [
                        TndActions.updateTorqueChartSettings({
                            settings: newSettings
                        })
                    ];
                })
            );
        })
    ));


    readonly onUpdateYUnitForWeights$ = createEffect(() => this.actions$.pipe(
        ofType(TndActions.updateYUnitIdForWeightsChart),

        switchMap(({ unitId }) => {
            return this.store.select(TndSelectors.weightsChartSettings).pipe(
                first(),
                switchMap(settings => {
                    const newSettings = { ...settings };

                    newSettings.yUnitId = unitId ?? WellKnownUnitIds.Meter;

                    return [
                        TndActions.updateWeightsChartSettings({
                            settings: newSettings
                        })
                    ];
                })
            );
        })
    ));

    readonly onUpdateYUnitForTorque$ = createEffect(() => this.actions$.pipe(
        ofType(TndActions.updateYUnitIdForTorqueChart),

        switchMap(({ unitId }) => {
            return this.store.select(TndSelectors.torqueChartSettings).pipe(
                first(),
                switchMap(settings => {
                    const newSettings = { ...settings };

                    newSettings.yUnitId = unitId ?? WellKnownUnitIds.Meter;

                    return [
                        TndActions.updateTorqueChartSettings({
                            settings: newSettings
                        })
                    ];
                })
            );
        })
    ));


    /** @internal */
    ngrxOnInitEffects(): Action {
        return initAction();
    }
}
