import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import type { NavigationItem } from '@cyberloop/web/app/model';

@Component({
  selector: 'cyberloop-engineering-head',
  standalone: true,
  imports: [
    NgFor,
    RouterLink,
    RouterLinkActive,
    MatRippleModule
  ],
  templateUrl: './engineering-head.component.html',
  styleUrls: ['./engineering-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EngineeringHeadComponent {

  @Input() navigationItems: Omit<NavigationItem, 'icon'>[] | null = [];

}
