import { AsyncPipe, NgIf, formatNumber } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnChanges, ViewChild } from '@angular/core';

import { ClChartComponent, UnitsConverterService, UnitsSelectors, WellKnownUnitGroupIds } from '@cyberloop/core';
import { KpiActions, KpiSelectors } from '@cyberloop/web/wells/data';
import { WidgetDataProvider, WidgetSettingsHandler, WidgetSize, WidgetType, slideRotaryDefaultSettings } from '@cyberloop/web/wells/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, filter, map, switchMap, tap } from 'rxjs';

import * as Highcharts from 'highcharts';

import { KpiWidgetComponent } from '../widget/kpi-widget.component';
import { SettingsComponent } from './settings/settings.component';

import type { SimpleChangesOf, UnitDescriptor } from '@cyberloop/core';
import type { KpiWidget } from '@cyberloop/web/wells/model';
import type { SlideRotaryWidgetSettings } from '@cyberloop/web/wells/model';

const SLIDE_POINT_ID = 'slide';
const ROTARY_POINT_ID = 'rotary';

@Component({
    selector: 'cyberloop-slide-rotary-widget',
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        KpiWidgetComponent,
        ClChartComponent
    ],
    templateUrl: './slide-rotary-widget.component.html',
    styleUrls: ['./slide-rotary-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class SlideRotaryWidgetComponent implements KpiWidget, AfterViewInit, OnChanges {

    private readonly _id$ = new BehaviorSubject<string>('-');
    private readonly _dataLoading$ = new BehaviorSubject<boolean>(true);

    private _lastSlide = 0;
    private _lastRotary = 0;
    private _timeUnit?: UnitDescriptor;

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string,
        private readonly store: Store,
        private readonly settings: WidgetSettingsHandler,
        private readonly data: WidgetDataProvider,
        private readonly unitsConverter: UnitsConverterService
    ) {
        const idPipe = this._id$.pipe(
            untilDestroyed(this),
            filter(Boolean),
            tap(() => this._dataLoading$.next(true))
        );

        combineLatest([
            // Get widget data
            idPipe.pipe(
                switchMap(id => this.data.getData(id, WidgetType.SlideRotary))
            ),
            // Get widget settings (for units conversion)
            idPipe.pipe(
                switchMap(id => this.store.select(KpiSelectors.widgetSettings<SlideRotaryWidgetSettings>(id))),
                map(widgetSettings => widgetSettings?.timeUnitId ?? slideRotaryDefaultSettings.timeUnitId),
                switchMap(timeUnitId => this.store.select(
                    UnitsSelectors.getUnit(WellKnownUnitGroupIds.Time, timeUnitId)
                ))
            )
        ]).pipe(
            untilDestroyed(this)
        ).subscribe(([widgetData, unit]) => {
            if (!this.chart) {
                return;
            }

            const shouldRedraw = unit?.id !== this._timeUnit?.id;

            this._timeUnit = unit;

            if (widgetData.slide !== this._lastSlide) {
                this.chart.updatePoint(SLIDE_POINT_ID, { y: this.getConvertedValue(widgetData.slide) });
                this._lastSlide = widgetData.slide;
            }

            if (widgetData.rotary !== this._lastRotary) {
                this.chart.updatePoint(ROTARY_POINT_ID, { y: this.getConvertedValue(widgetData.rotary) });
                this._lastRotary = widgetData.rotary;
            }

            if (shouldRedraw) {
                this.chart.redraw();
            }

            // This is to hide zero values on the chart before the data is drawn
            setTimeout(() => {
                this._dataLoading$.next(false);
            }, 100);
        });
    }

    readonly options = this.getOptions();
    readonly dataLoading$ = this._dataLoading$.asObservable();

    @Input() id = '-';
    @Input() size = WidgetSize.Tiny;

    @ViewChild(ClChartComponent, { static: true }) chart?: ClChartComponent;

    ngAfterViewInit(): void {
        if (!this.chart) {
            throw new Error('<cyberloop-chart> component should be present');
        }
    }

    ngOnChanges(changes: SimpleChangesOf<SlideRotaryWidgetComponent>): void {
        if (changes.id?.currentValue) {
            this._id$.next(changes.id.currentValue);
        }
    }

    async onSettings(): Promise<void> {
        await this.settings.showSettings<SlideRotaryWidgetSettings>(
            this.id,
            SettingsComponent,
            slideRotaryDefaultSettings
        );
    }

    onDelete(): void {
        this.store.dispatch(KpiActions.deleteWidget({ widgetId: this.id }));
    }

    private getOptions(): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        return {
            chart: {
                type: 'pie',
                marginTop: 0,
                marginBottom: 25,

                events: {
                    load: function () {
                        this.title.attr({
                            text: self.getTitleText()
                        });
                    },
                    render: function () {
                        // Draw title at the center
                        if (this.series) {
                            this.setTitle({
                                text: self.getTitleText()
                            });

                            this.title.attr({
                                y: this.plotHeight * .5 + this.plotTop + 6
                            });
                        }
                    }
                }
            },
            plotOptions: {
                pie: {
                    // innerSize: '30%',
                    innerSize: '50%',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true,
                    borderWidth: 0
                }
            },
            legend: {
                enabled: true,
                floating: false,
                align: 'center',
                verticalAlign: 'bottom',
                x: 0,
                y: 24,
                itemMarginTop: 5,
                itemMarginBottom: 5,
                symbolWidth: 8,
                symbolHeight: 8,
                symbolPadding: 10
            },
            tooltip: {
                valueDecimals: 2,
                padding: 0,
                useHTML: true,
                formatter: function () {
                    const title = this.point.name;
                    let value = this.point.y?.toFixed(2).replace(/\.00$/, '');

                    if (self._timeUnit) {
                        value += ' ' + self._timeUnit.label;
                    }

                    return `<span class="dot highcharts-color-${this.colorIndex}"></span><span class="title">${title}</span><span class="value">${value}</span>`;
                }
            },
            series: [{
                name: 'Main',
                type: 'pie',
                colorByPoint: true,
                data: [
                    { id: SLIDE_POINT_ID, name: 'Slide', y: this.getConvertedValue(this._lastSlide) ?? 0, colorIndex: 1 },
                    { id: ROTARY_POINT_ID, name: 'Rotary', y: this.getConvertedValue(this._lastRotary) ?? 0, colorIndex: 2 }
                ]
            }]
        };
    }

    private getConvertedValue(source: number): number {
        return this._timeUnit
            ? this.unitsConverter.convertToUnit(source, this._timeUnit)
            : source;
    }

    private getTitleText(): string {
        const slide = formatNumber(this.getConvertedValue(this._lastSlide), this.locale, '1.0-2');
        const rotary = formatNumber(this.getConvertedValue(this._lastRotary), this.locale, '1.0-2');

        return `${slide} / ${rotary}`;
    }

}
