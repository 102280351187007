<block-with-heading [heading]="heading" fullscreen #fs="fullScreen" class="wrapper"
    (fullscreenChange)="fullscreenChanged.emit($event)">
    <ng-container block-header-content>
        <ng-content select="[widget-header]"></ng-content>
        <div class="header-controls">
            <button *ngIf="showSettings" mat-icon-button class="settings" (click)="settingsRequested.emit()">
                <icon name="settings"></icon>
            </button>
            <button mat-icon-button class="enlarge" (click)="fs.toggleFullscreen()">
                <icon name="enlarge"></icon>
            </button>

            <button *ngIf="!fs.isFullScreen" mat-icon-button [matMenuTriggerFor]="menu" class="menu">
                <icon name="dots__vertical"></icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <icon name="dots__vertical"></icon>
                <button mat-menu-item (click)="editRequested.emit()">
                    <icon name="edit"></icon>
                    Edit widget
                </button>
                <button mat-menu-item (click)="deleteRequested.emit()">
                    <icon name="delete"></icon>
                    Delete
                </button>
            </mat-menu>
        </div>
    </ng-container>
    <ng-container block-main-content>
        <ng-content select="[widget-body]"></ng-content>
    </ng-container>
</block-with-heading>
