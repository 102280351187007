import { Injectable, Optional } from '@angular/core';

import { AccessService, RigInfoProviderService, WellInfoProviderService } from '@cyberloop/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { EMPTY, catchError, combineLatest, filter, first, map, switchMap } from 'rxjs';

import { RecentItemsService } from '../services/recent-items.service';
import { UserAppService } from '../services/user-app.service';
import { WellFavoritesService } from '../services/well-favorites.service';
import { WellObjectExtenderService } from '../services/well-object-extender.service';
import { AppActions } from './app.actions';
import { AppSelectors } from './app.selectors';
import { APP_FEATURE } from './app.state';

const initAction = createAction(`[${APP_FEATURE}] Initialize app`);

@Injectable()
export class AppEffects implements OnInitEffects {
    constructor(
        private readonly store: Store,
        private readonly actions$: Actions,
        private readonly userAppService: UserAppService,
        private readonly recentItemsService: RecentItemsService,
        private readonly wellExtender: WellObjectExtenderService,
        private readonly favoritesService: WellFavoritesService,
        private readonly accessService: AccessService,
        @Optional() private rip: RigInfoProviderService,
        @Optional() private wip: WellInfoProviderService
    ) {
    }

    /**
     * Starts apps get on init
     */
    readonly getApps$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        map(() => AppActions.getApps())
    ));

    /**
     * Performs apps loading
     */
    readonly onLoadApps$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.loadApps),
        switchMap(() => this.store.select(AppSelectors.appsNotLoaded)),
        filter(notLoaded => !notLoaded),
        // Fetch the meta
        switchMap(() => this.userAppService.getApps()),
        // Dispatch the success
        map(apps => AppActions.appsLoaded({ apps })),
        // Dispatch the error
        catchError(error => ([AppActions.appsLoadError({ error })]))
    ));

    /**
     * Performs apps get
     */
    readonly onGetApps$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.getApps),
        switchMap(() => this.store.select(AppSelectors.appsNotLoaded)),
        filter(Boolean),
        map(() => AppActions.loadApps())
    ));

    // --

    /**
     * Starts recent items get on init
     */
    readonly getRecentItems$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        map(() => AppActions.getRecentItems())
    ));

    /**
     * Performs recent items loading
     */
    readonly onLoadRecentItems$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.loadRecentItems),
        switchMap(() => this.store.select(AppSelectors.recentItemsNotLoaded)),
        filter(notLoaded => !notLoaded),
        // Fetch the meta
        switchMap(() => this.recentItemsService.watchRecentItems().pipe(
            // TODO VVVVV Must be moved to service. VVVVV
            // switchMap(items => {
            //     return combineLatest(items
            //         .filter(item => Boolean(item.customData))
            //         .map(item => {
            //             const itemWithData = item as RecentItemWithWellId & Required<Pick<RecentItemWithWellId, 'customData'>>;
            //             return this.store.select(AppSelectors.wellById(itemWithData.customData.wellId)).pipe(
            //                 map(wellMeta => ({ ...item, customData: wellMeta }))
            //             );
            //         }));
            // }),

            // Handle the error
            catchError(error => {
                this.store.dispatch(AppActions.recentItemsLoadError({ error }));
                return EMPTY;
            })
        )),
        // Dispatch the success
        map(recentItems => AppActions.recentItemsLoaded({ recentItems }))
    ));

    /**
     * Performs recent items get
     */
    readonly onGetRecentItems$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.getRecentItems),
        switchMap(() => this.store.select(AppSelectors.recentItemsNotLoaded)),
        filter(Boolean),
        map(() => AppActions.loadRecentItems())
    ));

    // --

    readonly toggleFavorite$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.toggleFavorite),
        switchMap(({ wellId }) => {
            return this.store.select(AppSelectors.favorites).pipe(
                first(),
                switchMap((favorites) => {
                    let newFavoritesList = [...favorites];
                    if (newFavoritesList.includes(wellId)) {
                        newFavoritesList = newFavoritesList.filter(well => well !== wellId);
                    }
                    else {
                        newFavoritesList.push(wellId);
                    }
                    return [
                        AppActions.setFavorites({ favorites: newFavoritesList }),
                        this.favoritesService.setFavorites(newFavoritesList)
                    ];
                })
            );
        })
    ), { dispatch: false });

    readonly getFavorites$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(() => this.favoritesService.getFavorites()),
        switchMap((settings) => [AppActions.setFavorites({ favorites: settings.favorites })])
    ));

    readonly getWellsData$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(() => combineLatest([
            this.rip.watchRigs(),
            this.wip.watchWells()
        ]).pipe(
            switchMap(([rigs, wells]) => this.accessService.filterWells(rigs, wells)),
            switchMap((wells) => {
                const wellsData = wells.map((well) => this.wellExtender.getWellWithData(well));
                return [AppActions.setWellsData({ wells: wellsData })];
            })
        ))
    ));

    ngrxOnInitEffects(): Action {
        return initAction();
    }
}
