<div class="user-menu" (click)="$event.stopPropagation()">
    <div class="profile">
        <div class="icon">
            <svg viewBox="0 0 41 41" fill="none" *ngIf="!userAvatar; else image" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20.5" cy="20.5" r="20.5" fill="var(--cl-bg-panel-sec)" />
                <path
                    d="M19.925 19.2126C18.6978 19.2126 17.6937 18.8293 16.9128 18.0628C16.1319 17.2963 15.7414 16.3108 15.7414 15.1063C15.7414 13.9018 16.1319 12.9163 16.9128 12.1498C17.6937 11.3833 18.6978 11 19.925 11C21.1522 11 22.1562 11.3833 22.9372 12.1498C23.7181 12.9163 24.1086 13.9018 24.1086 15.1063C24.1086 16.3108 23.7181 17.2963 22.9372 18.0628C22.1562 18.8293 21.1522 19.2126 19.925 19.2126ZM11 28V25.4267C11 24.7332 11.1766 24.1401 11.5299 23.6473C11.8832 23.1546 12.3387 22.7805 12.8966 22.525C14.1423 21.9775 15.337 21.5668 16.4805 21.2931C17.624 21.0193 18.7722 20.8824 19.925 20.8824C21.0778 20.8824 22.2213 21.0239 23.3555 21.3068C24.4898 21.5896 25.6798 21.9957 26.9255 22.525C27.502 22.7805 27.9668 23.1546 28.3201 23.6473C28.6734 24.1401 28.85 24.7332 28.85 25.4267V28H11ZM12.6734 26.3575H27.1766V25.4267C27.1766 25.1347 27.0882 24.8564 26.9116 24.5918C26.735 24.3272 26.5165 24.131 26.2562 24.0032C25.0662 23.4375 23.9784 23.0497 22.993 22.8398C22.0075 22.6299 20.9848 22.525 19.925 22.525C18.8652 22.525 17.8332 22.6299 16.8291 22.8398C15.8251 23.0497 14.7373 23.4375 13.5659 24.0032C13.3056 24.131 13.0918 24.3272 12.9245 24.5918C12.7571 24.8564 12.6734 25.1347 12.6734 25.4267V26.3575ZM19.925 17.57C20.6502 17.57 21.2498 17.3374 21.7239 16.872C22.1981 16.4066 22.4352 15.818 22.4352 15.1063C22.4352 14.3945 22.1981 13.806 21.7239 13.3406C21.2498 12.8752 20.6502 12.6425 19.925 12.6425C19.1998 12.6425 18.6002 12.8752 18.1261 13.3406C17.6519 13.806 17.4148 14.3945 17.4148 15.1063C17.4148 15.818 17.6519 16.4066 18.1261 16.872C18.6002 17.3374 19.1998 17.57 19.925 17.57Z"
                    fill="currentColor" />
            </svg>

            <ng-template #image>
                <img class="item" [src]="userAvatar">
            </ng-template>
        </div>

        <div class="display-name">
            {{displayName??login}}
        </div>
        <div class="login">{{login}}</div>
    </div>

    <div class="labeled-item">
        <span>theme</span>
        <theme-switcher class="switcher"></theme-switcher>
    </div>
    <button class="units" (click)="onOpenUnitsSelector()">
        <icon name="drill__my_workspace"></icon>
        <span>Units</span>
    </button>



    <button mat-ripple class="button logout labeled-item" (click)="logout.emit()" data--tutorial-id="logout">
        <span>Logout</span>

        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.83333 17C1.34444 17 0.916667 16.8269 0.55 16.4806C0.183333 16.1343 0 15.7302 0 15.2685V1.73148C0 1.26975 0.183333 0.865741 0.55 0.519445C0.916667 0.173148 1.34444 0 1.83333 0H10.725V1.73148H1.83333V15.2685H10.725V17H1.83333ZM14.6833 13.5502L13.3694 12.3093L16.4861 9.36574H7.79167V7.63426H16.425L13.3083 4.69074L14.6222 3.44985L20 8.52886L14.6833 13.5502Z"
                fill="currentColor" />
        </svg>
    </button>
</div>