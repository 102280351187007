import { ChangeDetectionStrategy, Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { createCustomElement } from '@angular/elements';

/** @internal */
const ICONS_SPRITE_PATH = `/assets/icons-sprite.svg`;

/** @internal */
const selector = 'icon';
/** @internal */
const wcSelector = `cl-${selector}`;

/** svg sprive-based icon. */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: selector,
    standalone: true,
    template: `
        <svg class="icon">
            <use [attr.href]="spritePath + '#' + (href.innerText || name)" />
        </svg>
        <div #href>
            <ng-content></ng-content>
        </div>
    `,
    styles: [`
        .cl-icon__container {
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
        .icon {
            display: inline-flex;

            --color: var(--icon-color, currentColor);

            --size: var(--icon-size, 1rem);
            --width: var(--icon-width, var(--size));
            --height: var(--icon-height, var(--size));

            color: var(--color);

            width: var(--width);
            height: var(--height);
        }

        .cl-icon__container > .icon + div{
            display: none;
        }
    `],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'cl-icon__container'
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
    /** Name of svg sprite */
    @Input() name?: string;
    /** Path to svg sprites set */
    @Input() spritePath: string = ICONS_SPRITE_PATH;

    /** Returnes WebComponent-based HTML string to render specific icon */
    static getHTML(name: string, spritePath?: string): string {
        const items = ['<', wcSelector];
        if (spritePath) {
            items.push(' spritePath="', spritePath, '"');
        }
        items.push('>', name, '</', wcSelector, '>');
        return items.join('');
    }

    /** @internal */
    static register(injector: Injector) {
        const el = createCustomElement(IconComponent, { injector });
        customElements.define(wcSelector, el);
    }
}
