import { TndChart } from '@cyberloop/web/tnd/model';
import { Observable } from 'rxjs';

import type { TndChartDataWithModel, TndTorqueChartDataWithModel, TndWeightsChartDataWithModel } from '@cyberloop/web/tnd/model';

/**
 * Provides chart data (with models) for T&D charts.
 */
export abstract class TndChartDataProvider {

    /**
     * Provides for T&D Weights chart
     * @param realmId Realm ID to fetch data for. It can be well ID or plan ID
     * @param type Weights chart type
     * @param sectionId ID of the section to fetch data for
     * @param modelId ID of the active model to add to the data
     */
    abstract watchChartData(realmId: string, type: TndChart.Weights, sectionId?: number, modelId?: string): Observable<TndWeightsChartDataWithModel>;
    /**
     * Provides for T&D Torque chart
     * @param realmId Realm ID to fetch data for. It can be well ID or plan ID
     * @param type Torque chart type
     * @param sectionId ID of the section to fetch data for
     * @param modelId ID of the active model to add to the data
     */
    abstract watchChartData(realmId: string, type: TndChart.Torque, sectionId?: number, modelId?: string): Observable<TndTorqueChartDataWithModel>;
    /**
     * Provides for T&D charts depending by type
     * @param realmId Realm ID to fetch data for. It can be well ID or plan ID
     * @param type Type of the chart. Can be 'weights' or 'torque'
     * @param sectionId ID of the section to fetch data for
     * @param modelId ID of the active model to add to the data
     */
    abstract watchChartData(realmId: string, type: TndChart, sectionId?: number, modelId?: string): Observable<TndChartDataWithModel>;

}
