
/**
 * Types of T&D charts: Weights, Torque
 */
export enum TndChart {
    Weights = 'weights',
    Torque = 'torque'
}

export enum TndRealm {
    Plan = 'plan',
    Well = 'well',
}

/**
 * One item of T&D data
 */
export type TndItem = {
    dtime: string,
    depth: number,
    pickUp: number,
    slackOff: number,
    rotary: number,
    onBottomTorque: number,
    offBottomTorque: number
}
