import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiDrillingMetricsPerJointMetersItem } from '@cyberloop/web/wells/model';
type KpiDrillingMetricsPerJointInput = {
    rig: string,
    since: string,
    until: string
}

type KpiDrillingMetricsPerJointMeters = {
    rig: {
        drillingMetricsPerJointMeters: KpiDrillingMetricsPerJointMetersItem[]
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiDrillingMetricsPerJointMetersQuery extends GraphQLQuery<KpiDrillingMetricsPerJointMeters, KpiDrillingMetricsPerJointInput> {
    override readonly document = `
        query GetRigDrillingMetricsPerJointMeters($rig: String!, $since: DateTime!, $until: DateTime!) {
            rig(name: $rig) {
                ... on Rig {
                    drillingMetricsPerJointMeters(since: $since, until: $until) {
                        startTime
                        rotaryMetersDrilled
                        slideMetersDrilled
                    }
                }
            }
        }
    `;
}