import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { ClChartComponent, FormatNumberPipe, HighchartsOptions, Point, SimpleChangesOf } from '@cyberloop/core';
import { isEqual } from 'lodash';
import { ReplaySubject, distinctUntilChanged, map } from 'rxjs';

const MAX_SERIES_COUNT = 18;

@Component({
    selector: 'cyberloop-dd-chart',
    standalone: true,
    imports: [
        ClChartComponent
    ],
    templateUrl: './dd-chart.component.html',
    styleUrls: ['./dd-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FormatNumberPipe]
})
export class DdChartComponent implements AfterViewInit, OnChanges {

    private readonly _data$ = new ReplaySubject<Point[][]>(1);

    constructor(private readonly _formatPipe: FormatNumberPipe) { }

    @ViewChild(ClChartComponent, { static: true }) chart!: ClChartComponent;

    @Input() xAxisTitle = 'Days';
    @Input() yAxisTitle = 'Depth';
    @Input() depthLabel?: string | null;
    @Input() set data(values: Point[][] | null) {
        this._data$.next(values ?? []);
    }
    @Input() activatedSeries: number[] | null = null;

    options = this.getOptions();

    ngAfterViewInit(): void {
        if (!this.chart) {
            throw new Error('<cyberloop-chart> component should be present');
        }

        for (let index = 0; index < MAX_SERIES_COUNT; index++) {
            this.chart.addSeries(
                { type: 'line', name: `series-${index}`, colorIndex: index },
                this._data$.pipe(
                    distinctUntilChanged((a, b) => isEqual(a, b)),
                    map((series) => series[index] ?? [])
                )
            );
        }

        this.showActivatedSeries();
    }

    ngOnChanges(changes: SimpleChangesOf<DdChartComponent>): void {
        if(!isEqual(changes.xAxisTitle?.currentValue, changes.xAxisTitle?.previousValue) || 
        !isEqual(changes.yAxisTitle?.currentValue, changes.yAxisTitle?.previousValue)) {
            //If axis titles changes we need to update chart options
            this.options = this.getOptions();
        }
        

        if(!isEqual(changes.activatedSeries?.currentValue, changes.activatedSeries?.previousValue)) {
            this.showActivatedSeries();
        }
    }

    showActivatedSeries() {
        if(this.activatedSeries) {
            //Hide all series and show only activated
            for (const series of this.chart.series) {
                if(series.index !== 0) {
                    if(series.visible) {
                        series.hide();
                    }
                }
                if(this.activatedSeries.includes(series.index)) {
                    if(!series.visible) {
                        series.show();
                    }
                }
            }
        }
    }

    private getOptions(): HighchartsOptions {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        return {
            chart: {
                marginTop: 0,
                marginBottom: 30,
                marginLeft: 70,
                marginRight: 0,
                spacingTop: 50,
                
                panning: {
                    enabled: true,
                    type: 'x'
                },

                zooming: {
                    type: 'xy'
                }
            },
            xAxis: {
                min: 0,
                //tickInterval: 1,
                minPadding: 0,
                maxPadding: 0,
                showEmpty: false,
                showFirstLabel: true,
                gridLineWidth: 1,
                title: {
                    text: this.xAxisTitle,
                    align: 'low',
                    x: -40,
                    y: -25
                }
            },
            yAxis: {
                reversed: this.yAxisTitle !== 'NS',
                minPadding: 0.001,
                maxPadding: 0.001,
                showEmpty: false,
                showFirstLabel: false,
                showLastLabel: false,
                title: {
                    text: this.yAxisTitle,
                    align: 'middle'
                }
            },
            plotOptions: {
                series: {
                    softThreshold: false,
                    marker: {
                        enabled: false,
                        symbol: 'circle'
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormatter() {
                            return `${self.xAxisTitle}: ${self._formatPipe.transform(this.x)} <br> ${self.yAxisTitle}: ${self._formatPipe.transform(this.y)}`;
                        }
                    }
                }
            },
            disableUpdateExtremes: true
        };
    }

}
