import { Widget } from './widget';
import { WidgetSettings } from './widget-settings';

/** @deprecated Not used anymore */
export type KpiSettings = {
    widgets: Widget[];
    widgetSettings: Record<string, WidgetSettings>;
}

/** @deprecated Not used anymore */
export const kpiSettingsDefault: KpiSettings = {
    widgets: [],
    widgetSettings: {}
}
