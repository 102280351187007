import { Overlay, OverlayConfig, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, Type } from '@angular/core';

import { merge } from 'lodash';

import { PopupContent, PopupHostComponent } from '../components/popup-host/popup-host.component';

/** Promise that could be aborted */
export type AbortablePromise<T> = Promise<T> & { abort: () => void };

/** Settings for popup */
export type PopupSettings<T> = {
    /** Custom data to be passed to popup */
    data?: T,
    /** Settings to be passed to overlay */
    overlaySettings?: OverlayConfig,
    /** Element this popup relates to */
    relativeElement?: ElementRef<HTMLElement>
};

/** Use this service to show popups */
@Injectable({
    providedIn: 'root'
})
export class PopupService {
    /** @internal */
    constructor(private readonly overlay: Overlay) { }

    /**
     * Show component in popup
     * @param component Component to be rendered
     * @param settings Settings for component
     */
    show(component: Type<PopupContent<void>>, settings?: PopupSettings<never>): AbortablePromise<void> {
        return this.showForResult<void>(component, settings);
    }

    /**
     * Show component in popup and wait for result
     * @param component Component to be rendered
     * @param settings Settings for component
     */
    showForResult<TRes, TInput = never>(component: Type<PopupContent<TRes, TInput>>, settings?: PopupSettings<TInput>): AbortablePromise<TRes> {
        const { relativeElement, data, overlaySettings } = settings ?? {};

        const promise: AbortablePromise<TRes> = new Promise<TRes>((res) => {
            let pos;
            if (relativeElement) {
                const p = this.overlay.position();
                const res = p
                    .flexibleConnectedTo(relativeElement)
                    .withDefaultOffsetX(0)
                    .withPositions([
                        { originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top', offsetY: 0 },
                        { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top', offsetY: 0 },
                        { originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom', offsetY: 0 },
                        { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'bottom', offsetY: 0 }
                    ]);

                pos = res;
            } else {
                pos = this.overlay.position().global().centerHorizontally().centerVertically();
            }

            const overlayRef = this.overlay.create(
                merge({
                    hasBackdrop: true,
                    positionStrategy: pos,
                    scrollStrategy: this.overlay.scrollStrategies.reposition({
                        autoClose: false
                    }),
                    panelClass: 'not-opaque',
                    backdropClass: 'popup-backdrop'
                }, overlaySettings || {})
            );
            const popupHost = new ComponentPortal(PopupHostComponent);
            const compRef = overlayRef.attach(popupHost);

            const closeOverlay = () => {
                overlayRef?.dispose();
            };

            setTimeout(() => {
                promise.abort = () => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    res(undefined!);
                    closeOverlay();
                }
            })

            let result: TRes | null = null;
            compRef.instance.attachView(component, data, (resultValue) => {
                result = resultValue as TRes;
                closeOverlay();
            });

            compRef.onDestroy(() => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                res(result!);
            });

            overlayRef.backdropClick().subscribe(() => {
                closeOverlay();
            });
        }) as unknown as AbortablePromise<TRes>;

        return promise;
    }

    /** @internal */
    private position(): OverlayPositionBuilder {
        return this.overlay.position();
    }
}
