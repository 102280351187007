import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DatetimePipe, IconComponent, NoDataPipe } from '@cyberloop/core';
import { PlanningHeadActions } from '@cyberloop/web/planning/shared/model';

import { Moment } from 'moment';

@Component({
    selector: 'cyberloop-planning-head',
    standalone: true,
    imports: [
        NgIf,
        DatetimePipe,
        NoDataPipe,
        IconComponent
    ],
    templateUrl: './planning-head.component.html',
    styleUrls: ['./planning-head.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningHeadComponent {
    @Input() name?: string | null;
    @Input() updatedDate?: Moment | null;
    @Input() versionHistoryVisible?: boolean;
    @Input() disableActions?: boolean | null;
    @Input() canSave?: boolean | null;
    @Input() readonly?: boolean | null;

    @Output() readonly edit = new EventEmitter();
    @Output() readonly action = new EventEmitter<PlanningHeadActions>();

    readonly PlanningHeadActions = PlanningHeadActions;

    onAction(action: PlanningHeadActions) {
        this.action.emit(action);
    }
}
