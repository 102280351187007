import { ID, RigActivity } from '@cyberloop/core';
import { FileMeta, UpdateFileMeta } from '@cyberloop/web/files/model';
import { MeterageItem } from '@cyberloop/web/wells/model';

import type { Observable } from 'rxjs';

/** Base class for files provider. Used as Injection token */
export abstract class FilesProviderService {
    /** Watch list of Well files */
    abstract watchWellFiles(wellId: ID, planningId?: ID): Observable<FileMeta[]>;

    /** Watch list of Planning files */
    abstract watchPlanningFiles(planningId: ID): Observable<FileMeta[]>;

    /** Watch files by IDs */
    abstract watchByIDs(ids: ID[]): Observable<FileMeta[]>;

    /** Get download link by ID */
    abstract getDownloadLink(id: ID): Observable<string>;

    /** Create Well file */
    abstract createWellFile(file: File, wellId: ID): Observable<FileMeta>;

    /** Create Well Meterage file */
    abstract createWellMeterageFile(file: File, wellId: ID, itemId: number, itemList: MeterageItem[]): Observable<FileMeta>;

    /** Create Planning file */
    abstract createPlanningFile(file: File, planningId: ID): Observable<FileMeta>;

    /** Create Planning Stage file */
    abstract createPlanningStageFile(file: File, planningId: ID, stageId: ID, activityId: RigActivity): Observable<FileMeta>;

    /** Update file info */
    abstract update(id: ID, update: UpdateFileMeta): Observable<FileMeta>;

    /** Delete file by ID */
    abstract delete(id: ID): Observable<FileMeta>;
}
