import { Injectable } from '@angular/core';

import { WellPlansProviderSerivce } from '@cyberloop/web/wells/data';
import { WellPlan } from '@cyberloop/web/wells/model';
import { Observable, map, shareReplay } from 'rxjs';

import { WellPlansQuery } from '../queries/well-plans.query';
import { WellPlansSubscription } from '../subscriptions/well-plans.subscription';

@Injectable({
    providedIn: 'root'
})
export class WellPlansProviderSerivceImpl extends WellPlansProviderSerivce {

    private readonly _wellPlansSubscriptions: Record<string, Observable<WellPlan[]>> = {};

    constructor(
        private readonly wellPlansQuery: WellPlansQuery,
        private readonly wellPlansSubscription: WellPlansSubscription
    ) {
        super();
    }

    getWellPlans(wellID: string): Observable<WellPlan[]> {
        return this.wellPlansQuery.fetch({wellID}).pipe(
            map((response) => response.data.wellPlans)
        );
    }
    watchWellPlans(wellID: string): Observable<WellPlan[] | undefined> {
        return this._wellPlansSubscriptions[wellID] ??= this.wellPlansQuery.watch({wellID}, {pollInterval: 60 * 1000}).pipe(
            map((response) => response.data.wellPlans),
            shareReplay(1)
        );
        /* 
        TODO: uncomment on subscription works
        return this.wellPlansSubscription.subscribe({wellID}).pipe(
            map((response) => response.data.wellPlansSubscription)
        );
        */
    }
}