<button class="user-menu-trigger" (click)="menuVisible = true">
    <div class="user-info" data--tutorial-id="user-info">
        <span class="icon">
            <svg viewBox="0 0 15 15" *ngIf="!userAvatar; else image" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.5 7.28022C6.46875 7.28022 5.625 6.95816 4.96875 6.31403C4.3125 5.66991 3.98438 4.84175 3.98438 3.82956C3.98438 2.81737 4.3125 1.98921 4.96875 1.34509C5.625 0.700968 6.46875 0.378906 7.5 0.378906C8.53125 0.378906 9.375 0.700968 10.0312 1.34509C10.6875 1.98921 11.0156 2.81737 11.0156 3.82956C11.0156 4.84175 10.6875 5.66991 10.0312 6.31403C9.375 6.95816 8.53125 7.28022 7.5 7.28022ZM0 14.6646V12.5022C0 11.9194 0.148437 11.421 0.445312 11.0069C0.742187 10.5928 1.125 10.2785 1.59375 10.0637C2.64062 9.60366 3.64453 9.25859 4.60547 9.02855C5.56641 8.79851 6.53125 8.68348 7.5 8.68348C8.46875 8.68348 9.42969 8.80234 10.3828 9.04005C11.3359 9.27776 12.3359 9.619 13.3828 10.0637C13.8672 10.2785 14.2578 10.5928 14.5547 11.0069C14.8516 11.421 15 11.9194 15 12.5022V14.6646H0ZM1.40625 13.2844H13.5938V12.5022C13.5938 12.2568 13.5195 12.023 13.3711 11.8006C13.2227 11.5782 13.0391 11.4133 12.8203 11.306C11.8203 10.8306 10.9062 10.5047 10.0781 10.3283C9.25 10.1519 8.39062 10.0637 7.5 10.0637C6.60938 10.0637 5.74219 10.1519 4.89844 10.3283C4.05469 10.5047 3.14063 10.8306 2.15625 11.306C1.9375 11.4133 1.75781 11.5782 1.61719 11.8006C1.47656 12.023 1.40625 12.2568 1.40625 12.5022V13.2844ZM7.5 5.89996C8.10938 5.89996 8.61328 5.70442 9.01172 5.31334C9.41016 4.92227 9.60938 4.42768 9.60938 3.82956C9.60938 3.23145 9.41016 2.73685 9.01172 2.34578C8.61328 1.95471 8.10938 1.75917 7.5 1.75917C6.89062 1.75917 6.38672 1.95471 5.98828 2.34578C5.58984 2.73685 5.39062 3.23145 5.39062 3.82956C5.39062 4.42768 5.58984 4.92227 5.98828 5.31334C6.38672 5.70442 6.89062 5.89996 7.5 5.89996Z"
                    fill="currentColor" />
            </svg>

            <ng-template #image>
                <img class="item" [src]="userAvatar">
            </ng-template>
        </span>
        <span class="display-name">{{displayName??login}}</span>
    </div>
</button>

<div class="backdrop" *ngIf="menuVisible" (click)="hideMenu()" [class.closing]="closing$|async">
    <cyberloop-user-menu 
        [class.closing]="closing$|async" 
        [displayName]="displayName" 
        [login]="login"
        [userAvatar]="userAvatar" 
        (logout)="sendLogoutCommand()"
        (openUnitsSelector)="openUnitsSelector()">
    </cyberloop-user-menu>
</div>