import type { Observable } from 'rxjs';

/** Base class for settings provider */
export abstract class SettingsProviderService {

    /** 
     * Look after document changes
     * 
     * @param key setting key
     * @param defaultSettings default setting
     */
    abstract watchSettings<T>(key: string, defaultSettings?: T): Observable<T>;

    /**
     * Update settings
     * 
     * @param key settings key
     * @param data data to update
     */
    abstract updateSettings<T>(key: string, data: Partial<T>): Promise<boolean>;
}