import { Injectable } from '@angular/core';

import { merge } from 'lodash';
import { Subject, Subscription, throttleTime } from 'rxjs';

import * as Highcharts from 'highcharts';
import HC_3d from 'highcharts/highcharts-3d';
// import HC_Gauge from 'highcharts/modules/solid-gauge';
import HC_More from 'highcharts/highcharts-more';
import HC_Accessibility from 'highcharts/modules/accessibility';
import HC_Boost from 'highcharts/modules/boost-canvas';
import HC_Cylinder from 'highcharts/modules/cylinder';
import HC_Variwide from 'highcharts/modules/variwide';

/** Chart object */
export type ChartEx = Highcharts.Chart & {
    /** if chart was destroyed or not */
    isDestroyed: boolean;

    /** if chart loaded */
    isLoaded: boolean;

    /** syncronization group for chart */
    userOptions: HighchartsOptions;

    /** flag if chart setExtremes */
    syncInProcess?: boolean;
};

/** @internal */
export interface RemoveSeries {
    (redraw?: boolean, animation?: (boolean | Partial<Highcharts.AnimationOptionsObject>), withEvent?: boolean, internal?: boolean, ...args: any[]): any;
}
/** @internal */
export type SeriesEx = Highcharts.Series & {
    removeHook?: RemoveSeries;
}
/** @internal */
HC_3d(Highcharts);
HC_Boost(Highcharts);
HC_More(Highcharts);
HC_Cylinder(Highcharts);
HC_Accessibility(Highcharts);
HC_Variwide(Highcharts);
((hc) => {
    // TODO add loaded
    hc.wrap(hc.Chart.prototype, 'destroy', function (this: ChartEx, orig: (...args: any[]) => any, ...args: any[]) {
        if (this.isDestroyed) {
            return;
        }

        const ref = this.container;
        try {
            return orig.apply(this, args);
        }
        finally {
            this.isDestroyed = true;
            this.container = ref;
        }
    });
    hc.addEvent(hc.Chart as any, 'load', function(this: ChartEx) {
        this.isLoaded = true;
    });
    hc.wrap(hc.Series.prototype, 'remove', function(this: SeriesEx, orig: RemoveSeries, redraw?: boolean, animation?: (boolean | Partial<Highcharts.AnimationOptionsObject>), withEvent?: boolean, internal?: boolean, ...args: any[]) {
        this.removeHook?.(redraw, animation, withEvent, internal, ...args);

        try {
            return orig.apply(this, args);
        } catch(e) {
            console.warn('Something happend in remove orig func');
        }

    });
})(Highcharts);

/** Extended highcharts options */
export type HighchartsOptions = Highcharts.Options & {
    /** chart syncronization options */
    sync?: {
        /** name of syncronization group */
        syncGroup: string,
        /** syncronization type */
        type: 'zoom' | 'tooltip' | 'all'
    },
    /** don't update extremes */
    disableUpdateExtremes?: boolean
};

/**
 * This service helps to create Charts. It provides some defaults used in application
 */
@Injectable({
    providedIn: 'root'
})
export class HcHelperService {

    /**
     * Create new instance of chart with options provided
     * @param el Element to render chart in
     * @param options Options of chart
     * @returns New instance of chart
     */
    create(el: HTMLElement, options: Highcharts.Options): ChartEx {
        const oldOpacity = el.style.opacity;
        el.style.opacity = '0';

        const chart = new Highcharts.Chart(el, merge(this.getDefaultOptions(el.id), options));

        setTimeout(() => {
            chart.setSize();
            el.style.opacity = oldOpacity;
        });

        return chart as ChartEx;
    }

    /** @private */
    private getDefaultOptions(id?: string): HighchartsOptions {
        return {
            chart: {
                styledMode: true,
                className: id ? `chart-${id}` : undefined,
                borderWidth: 0,
                //margin: 0,
                marginBottom: 0,
                plotBorderWidth: 0,
                animation: false,
            },
            legend: {
                enabled: false
            },
            boost: {
                allowForce: true,
                enabled: true,
                useGPUTranslations: true
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            time: {
                useUTC: false
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            plotOptions: {
                line: {
                    cropThreshold: 900,
                    connectNulls: false,
                    marker: {
                        enabled: false
                    },
                    threshold: Number.MIN_VALUE
                },
                series: {
                    turboThreshold: 5000,
                    animation: false
                }
            }
        }
    }
}
