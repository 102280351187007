// TODO json (cloud function)

import { PermissionIDs } from '../../models/access';

/** @internal */
export const permissions = [
    { id: PermissionIDs.Wells, name: 'Wells', parentId: null },

    { id: PermissionIDs.Drilling, name: 'Drilling', parentId: PermissionIDs.Wells },
    { id: PermissionIDs.SendNotesToRig, name: 'Send Notes to Rig', parentId: PermissionIDs.Drilling },

    { id: PermissionIDs.Export, name: 'Export', parentId: PermissionIDs.Wells },
    { id: PermissionIDs.ExportData, name: 'Export Data', parentId: PermissionIDs.Export },

    { id: PermissionIDs.Reports, name: 'Reports', parentId: PermissionIDs.Wells },
    { id: PermissionIDs.ViewTourReport, name: 'View tour Report', parentId: PermissionIDs.Reports },
    { id: PermissionIDs.ViewDDreports, name: 'View DD Reports', parentId: PermissionIDs.Reports },
    { id: PermissionIDs.ReportsDownload, name: 'Download', parentId: PermissionIDs.Reports },

    { id: PermissionIDs.DirectionalDrilling, name: 'Directional Drilling', parentId: PermissionIDs.Wells },
    { id: PermissionIDs.DirectionalDrillingExport, name: 'Export', parentId: PermissionIDs.DirectionalDrilling },

    { id: PermissionIDs.Forecast, name: 'Forecast', parentId: PermissionIDs.Wells },
    { id: PermissionIDs.ForecastEdit, name: 'Edit', parentId: PermissionIDs.Forecast },
    { id: PermissionIDs.ForecastExport, name: 'Export', parentId: PermissionIDs.Forecast },

    { id: PermissionIDs.Meterage, name: 'Meterage', parentId: PermissionIDs.Wells },
    { id: PermissionIDs.MeterageEdit, name: 'Edit', parentId: PermissionIDs.Meterage },
    { id: PermissionIDs.MeterageExport, name: 'Export', parentId: PermissionIDs.Meterage },

    { id: PermissionIDs.KPI, name: 'KPI', parentId: PermissionIDs.Wells },
    { id: PermissionIDs.KPIDownload, name: 'Download', parentId: PermissionIDs.KPI },

    { id: PermissionIDs.Engineering, name: 'Engineering', parentId: PermissionIDs.Wells },
    { id: PermissionIDs.EngineeringAddEditModels, name: 'Add/Edit Models', parentId: PermissionIDs.Engineering },
    { id: PermissionIDs.EngineeringExport, name: 'Export', parentId: PermissionIDs.Engineering },

    { id: PermissionIDs.Planning, name: 'Planning', parentId: null },

    { id: PermissionIDs.PlanningView, name: 'View', parentId: PermissionIDs.Planning },
    { id: PermissionIDs.PlanningEdit, name: 'Edit', parentId: PermissionIDs.PlanningView },

    { id: PermissionIDs.PlanningFiles, name: 'View', parentId: PermissionIDs.Planning },
    { id: PermissionIDs.PlanningFilesEdit, name: 'Edit', parentId: PermissionIDs.PlanningFiles },

    { id: PermissionIDs.RiskRegister, name: 'View', parentId: PermissionIDs.Planning },
    { id: PermissionIDs.RiskRegisterEdit, name: 'Edit', parentId: PermissionIDs.RiskRegister },

    { id: PermissionIDs.GanttChart, name: 'Gantt Chart', parentId: null },
    { id: PermissionIDs.GanttChartEdit, name: 'Edit', parentId: PermissionIDs.GanttChart },
];
