import { Injectable } from '@angular/core';

import { SettingsProviderService, deepMerge, uuid } from '@cyberloop/core';
import { AssetMetric, AssetMetrics, DashboardSettings, Widget, WidgetSettings } from '@cyberloop/web/wells/model';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { isEqual } from 'lodash';
import { catchError, combineLatest, debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs';

import { KpiActions } from './kpi.actions';
import { KpiSelectors } from './kpi.selectors';
import { KPI_FEATURE } from './kpi.state';

const initAction = createAction(`[${KPI_FEATURE}] Initialize KPI`);

export type KpiSettings = {
    widgets: Widget[],
    widgetSettings: Record<string, WidgetSettings>,
    dashboards: DashboardSettings[],
    activeDashboard: string,
    selectedMetrics: AssetMetric[]
}

const defaultDashboardId = uuid();
const kpiSettingsDefault: KpiSettings = {
    widgets: [],
    widgetSettings: {},
    dashboards: [
        {
            title: 'Default dashboard',
            id: defaultDashboardId
        }
    ],
    activeDashboard: defaultDashboardId,
    selectedMetrics: [AssetMetrics[0]]
};
const settingsKey = 'kpi.settings';

@Injectable()
export class KpiCommonEffects implements OnInitEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly settings: SettingsProviderService
    ) { }

    readonly onInitAction$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),

        // On init we'll watch for settings changes
        switchMap(() => this.settings.watchSettings<KpiSettings>(settingsKey, kpiSettingsDefault)),
        distinctUntilChanged((a, b) => isEqual(a, b)),
        map((kpiSettings) => deepMerge({}, [kpiSettingsDefault, kpiSettings])),

        // Save last widgets, settings and dashboards
        switchMap(settings => {
            const activeDashboardId = settings.activeDashboard ?? (settings.dashboards ?? kpiSettingsDefault.dashboards)[0].id;
            //set tabIf if not exist
            const widgets = settings.widgets.map(widget => ({...widget, tadId: widget.tadId ?? activeDashboardId}));

            return [
                KpiActions.setWidgets({ widgets }),
                KpiActions.setAllWidgetsSettings({ settings: settings.widgetSettings ?? {} }),
                KpiActions.setDashboards({ dashboards: settings.dashboards ?? kpiSettingsDefault.dashboards }),
                KpiActions.setActiveDashboard({ dashboardId: activeDashboardId }),
                KpiActions.setSelectedMetrics({ metrics: settings.selectedMetrics })
            ];
        }),

        catchError(error => ([KpiActions.setError({ error })]))
    ));

    readonly onStoreChanges$ = createEffect(() => this.actions$.pipe(
        ofType(
            KpiActions.setDashboards,
            KpiActions.setActiveDashboard,
            KpiActions.setAllWidgetsSettings,
            KpiActions.setWidgetSettings,
            KpiActions.updateWidgets,
            KpiActions.setSelectedMetrics
        ),

        switchMap(() => ([KpiActions.updateSettings()]))
    ));

    readonly onUpdateSettings$ = createEffect(() => this.actions$.pipe(
        ofType(KpiActions.updateSettings),
        
        debounceTime(500),
        switchMap(() => {
            return combineLatest([
                this.store.select(KpiSelectors.activeDashboard),
                this.store.select(KpiSelectors.dashboards),
                this.store.select(KpiSelectors.widgets),
                this.store.select(KpiSelectors.allWidgetsSettings),
                this.store.select(KpiSelectors.selectedMetrics)
            ]).pipe(
                first(),
                distinctUntilChanged((a, b) => isEqual(a, b)),
                filter(([ activeDashboard, dashboards, widgets, allWidgetsSettings, selectedMetrics ]) => Boolean(activeDashboard) && Boolean(dashboards) && Boolean(widgets) && Boolean(allWidgetsSettings) && Boolean(selectedMetrics)),
                tap(([ activeDashboard, dashboards, widgets, allWidgetsSettings, selectedMetrics ]) => {
                    this.settings.updateSettings<KpiSettings>(settingsKey, {
                        activeDashboard,
                        dashboards, 
                        widgets,
                        widgetSettings: allWidgetsSettings,
                        selectedMetrics
                    });
                })
            );
        })
    ), {dispatch: false});

    ngrxOnInitEffects(): Action {
        return initAction();
    }

}