import { Injectable } from '@angular/core';

import { ID } from '@cyberloop/core';
import { CreatePlanningVersion, PlanningStage, PlanningVersion, SaveAsResult } from '@cyberloop/web/planning/shared/model';
import { Observable } from 'rxjs';

import * as moment from 'moment';

import { PlanningVersionProviderService } from './abstractions/planning-version-provider.service';

@Injectable({ providedIn: 'root' })
export class PlanningVersionDataService {
    constructor(private readonly providerService: PlanningVersionProviderService) { }

    watchAll(planningId: ID): Observable<PlanningVersion[]> {
        return this.providerService.watchAll(planningId);
    }

    watch(planningId: ID, versionId: ID): Observable<PlanningVersion | null> {
        return this.providerService.watch(planningId, versionId);
    }

    create(planningId: ID, saveAsResult: SaveAsResult, stages: PlanningStage[]): Observable<PlanningVersion> {
        const versionToCreate: CreatePlanningVersion = {
            ...saveAsResult,
            planningId,
            stages
        };
        return this.providerService.create(planningId, versionToCreate);
    }

    update(planningId: ID, version: PlanningVersion, saveAsResult: SaveAsResult, updatedStages: PlanningStage[]): Observable<PlanningVersion> {
        const versionToUpdate: PlanningVersion = {
            ...version,
            ...saveAsResult,
            updatedAt: moment(),
            stages: updatedStages
        };
        return this.providerService.update(planningId, versionToUpdate);
    }

    replace(planningId: ID, version: PlanningVersion, saveAsResult: SaveAsResult, updatedStages: PlanningStage[]): Observable<PlanningVersion> {
        const versionToReplace: PlanningVersion = {
            ...version,
            ...saveAsResult,
            updatedAt: moment(),
            stages: updatedStages
        };
        return this.providerService.replace(planningId, versionToReplace);
    }

    delete(planningId: ID, versionId: ID): Observable<PlanningVersion> {
        return this.providerService.delete(planningId, versionId);
    }
}
