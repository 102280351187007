import * as Highcharts from 'highcharts';
import * as moment from 'moment';

export function formatTooltipWithRigData(this: Highcharts.TooltipFormatterContextObject, type: 'rig-state' | 'rig-activity'): string {
    const key = this.point.options.id;
    const title = this.point.name;
    const unit = this.point.options.custom?.['unit'] ?? '';
    const value = this.point.y?.toFixed(2).replace(/\.00$/, '') + (unit ? ' ' + unit : '');
    return (
        `<span class="dot ${type === 'rig-activity' ? 'ra' : 'rs'}-${key}"></span>` +
        `<span class="title">${title}</span>` +
        `<span class="value">${value}</span>`
    );
}

export function formatTooltipWithDateAndTime(this: Highcharts.TooltipFormatterContextObject): string {
    const color = this.point.colorIndex;
    const title = this.point.series.name;
    const value = this.point.y?.toFixed(2).replace(/\.00$/, '');
    const x = moment(parseInt(String(this.x), 10));

    return `<div class="tooltip">
        <span class="dot highcharts-color-${color}"></span>
        <div class="tooltip-content">
            <span class="title">${title}</span>
            <span class="value">${value}</span>
            <span class="date">${x.format('D.MM')}</span>
            <span class="time">${x.format('H:mm')}</span>
        </div>
    </div>`;
}