import { Injectable } from '@angular/core';

import { isNil } from 'lodash';

import { Unit } from '../models';

/** Converts values from Si to specific unit and back to Si */
@Injectable({
    providedIn: 'root'
})
export class UnitsConverterService {
    /**
     * Convert value of specific unit to Si
     * @param unitValue Value in specific unit
     * @param currentUnit Unit value is in
     * @returns si value
     */
    convertToSi(unitValue: number, currentUnit: Unit): number {
        if (isNil(unitValue)) {
            return unitValue;
        }

        unitValue = this.coerceValue(unitValue);

        if (!currentUnit) {
            throw new Error('Unit could not be null');
        }

        return (unitValue - (currentUnit.offset ?? 0)) / (currentUnit.ratio ?? 1);
    }

    /**
     * Convert value from Si to specific unit
     * @param siValue Value to convert
     * @param targetUnit Unit to convert to
     * @returns Value in specific unit
     */
    convertToUnit(siValue: number | null | undefined, targetUnit: Unit): number {
        if (isNil(siValue)) {
            return siValue as unknown as number;
        }

        siValue = this.coerceValue(siValue);


        if (!targetUnit) {
            throw new Error('Unit could not be null');
        }

        return siValue * (targetUnit.ratio ?? 1) + (targetUnit.offset ?? 0);
    }

    /** @internal */
    private coerceValue(value: number) {
        if (typeof value === 'string') {
            value = Number(value);
            if (Number.isNaN(value)) {
                throw new Error('Value must be a number');
            }
        }
        else if (typeof value !== 'number') {
            throw new Error('Value must be a number');
        }

        return value;
    }
}
