import { Injectable } from '@angular/core';

import { RigState, WellKnownParams } from '@cyberloop/core';
import { KpiDataService } from '@cyberloop/web/wells/data';
import { isEqual } from 'lodash';
import { Observable, distinctUntilChanged, filter, map, switchMap, timer } from 'rxjs';

import { QueryResult } from '../internals/cl-gql/models';
import { KpiBitOnBitOffBottomHoursQuery } from '../queries/kpi/bit-on-bit-off-hours.query';
import { KpiRigActivityDurationsQuery } from '../queries/kpi/rig-activity-durations.query';
import { KpiDrillingMetricsPerJointMetersQuery } from '../queries/kpi/rig-drilling-metrics-per-joint-meters.query';
import { KpiDrillingMetricsPerJointTimeQuery } from '../queries/kpi/rig-drilling-metrics-per-joint-time.query';
import { KpiDrillingMetricsQuery } from '../queries/kpi/rig-drilling-metrics.query';
import { KpiRigConnectionSumsQuery } from '../queries/kpi/rig-kpi-connection-sums.query';
import { KpiRigConnectionsQuery } from '../queries/kpi/rig-kpi-connections.query';
import { KpiRigRateOfAdvanceQuery } from '../queries/kpi/rig-kpi-rate-of-advance.query';
import { KpiRigStateDurationsQuery } from '../queries/kpi/rig-state-durations.query';
import { KpiRigStateHoursQuery } from '../queries/kpi/rig-state-hours.query';
import { KpiRigStateSlideRotaryHoursQuery } from '../queries/kpi/rig-state-slide-rotary-hours.query';
import { KpiRopSumsQuery } from '../queries/kpi/rop-sums.query';
import { KpiWellTagDepthHistoryQuery } from '../queries/kpi/well-tag-depth-history.query';
import { KpiWellTagTimeHistoryQuery } from '../queries/kpi/well-tag-time-history.query';

import type { Well, Section } from '@cyberloop/core';
import type { KpiDrillingMetricsItem, KpiDrillingMetricsPerJointMetersItem, KpiDrillingMetricsPerJointTimeItem, KpiRigActivityDurationItem, KpiRigBitOnBitOffBottomHour, KpiRigConnection, KpiRigConnectionSumsItem, KpiRigRateOfAdvanceItem, KpiRigStateDuration, KpiRigStateHour, KpiRigStateSLideRotaryHour, KpiWellTagTimeHistoryItem, KpiRopSumItem, KpiWellTagDepthHistoryItem } from '@cyberloop/web/wells/model';

const POLLING_INTERVAL = 30;
const POLLING_INTERVAL_LONG = 45;
const REFRESH_INTERVAL_MIN = 1;

@Injectable({ providedIn: 'root' })
export class KpiDataServiceGraphQL extends KpiDataService {

    constructor(
        // Queries
        private readonly wellTagTimeHistoryQuery: KpiWellTagTimeHistoryQuery,
        private readonly wellTagDepthHistoryQuery: KpiWellTagDepthHistoryQuery,
        private readonly rigDrillingMetricsQuery: KpiDrillingMetricsQuery,
        private readonly rigDrillingMetricsPerJointMetersQuery: KpiDrillingMetricsPerJointMetersQuery,
        private readonly rigDrillingMetricsPerJointTimeQuery: KpiDrillingMetricsPerJointTimeQuery,
        private readonly bitOnOffBottomHoursQuery: KpiBitOnBitOffBottomHoursQuery,
        private readonly rigStateRotarySlideHoursQuery: KpiRigStateSlideRotaryHoursQuery,
        private readonly rigStateHoursQuery: KpiRigStateHoursQuery,
        private readonly rigStateDurationsQuery: KpiRigStateDurationsQuery,
        private readonly rigActivityDurationsQuery: KpiRigActivityDurationsQuery,
        private readonly rigConnectionsQuery: KpiRigConnectionsQuery,
        private readonly rigConnectionSumsQuery: KpiRigConnectionSumsQuery,
        private readonly rigRopSumsQuery: KpiRopSumsQuery,
        private readonly rigRateOfAdvance: KpiRigRateOfAdvanceQuery
    ) {
        super();
    }

    watchTagTimeHistory(well: Well, tags: WellKnownParams[], step: number, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiWellTagTimeHistoryItem[]> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec, POLLING_INTERVAL_LONG);

        return this.wellTagTimeHistoryQuery.watch({
            wellId: well.id,
            tags,
            since: since.toISOString(),
            until: until.toISOString(),
            step
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.well.timeHistory ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchTagTimeHistoryByWellId(wellId: string, tags: WellKnownParams[], step: number, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiWellTagTimeHistoryItem[]> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec, POLLING_INTERVAL_LONG);

        return this.wellTagTimeHistoryQuery.watch({
            wellId: wellId,
            tags,
            since: since.toISOString(),
            until: until.toISOString(),
            step
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.well.timeHistory ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchTagDepthHistory(well: Well, section: Section, tags: WellKnownParams[], step: number, from: number, to: number, refreshIntervalSec?: number): Observable<KpiWellTagDepthHistoryItem[]> {
        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec, POLLING_INTERVAL_LONG);

        return this.wellTagDepthHistoryQuery.watch({
            wellId: well.id,
            sectionId: section.id,
            tags,
            from,
            to,
            step
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.well.depthHistory ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchDrillingMetrics(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiDrillingMetricsItem> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec, POLLING_INTERVAL_LONG);

        return this.rigDrillingMetricsQuery.watch({
            rig,
            since: since.toISOString(),
            until: until.toISOString()
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => (
                x?.rig?.drillingMetrics &&
                typeof x.rig.drillingMetrics.rotaryDrilled !== 'undefined' &&
                typeof x.rig.drillingMetrics.slideDrilled !== 'undefined'
            )
                ? x.rig.drillingMetrics
                : { rotaryDrilled: 0, slideDrilled: 0 }),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchDrillingMetricsPerJointMeters(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiDrillingMetricsPerJointMetersItem[]> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec, POLLING_INTERVAL_LONG);

        return this.rigDrillingMetricsPerJointMetersQuery.watch({
            rig,
            since: since.toISOString(),
            until: until.toISOString()
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.rig?.drillingMetricsPerJointMeters ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchDrillingMetricsPerJointTime(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiDrillingMetricsPerJointTimeItem[]> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec, POLLING_INTERVAL_LONG);

        return this.rigDrillingMetricsPerJointTimeQuery.watch({
            rig,
            since: since.toISOString(),
            until: until.toISOString()
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.rig?.drillingMetricsPerJointTime ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchBitOnOffBottomHours(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigBitOnBitOffBottomHour[]> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec);

        return this.bitOnOffBottomHoursQuery.watch({
            rig,
            since: since.toISOString(),
            until: until.toISOString()
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.rig?.rigStateBitOnBitOffBottomHours ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchRigStateRotarySlideHours(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigStateSLideRotaryHour[]> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec);

        return this.rigStateRotarySlideHoursQuery.watch({
            rig,
            since: since.toISOString(),
            until: until.toISOString()
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.rig?.rigStateSlideRotaryHours ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchRigStateHours(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigStateHour[]> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec);

        return timer(0, refreshIntervalSec * 1000).pipe(
            switchMap(() => this.rigStateHoursQuery.fetch({
                rig,
                since: since.toISOString(),
                until: until?.toISOString() ?? new Date().toISOString()
            })),
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.rig?.rigStateHours ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchRigStateDurations(rig: string, since: Date, until?: Date, only?: RigState[], refreshIntervalSec?: number): Observable<KpiRigStateDuration[]> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec);

        return timer(0, refreshIntervalSec * 1000).pipe(
            switchMap(() => this.rigStateDurationsQuery.fetch({
                rig,
                since: since.toISOString(),
                until: until?.toISOString() ?? new Date().toISOString(),
                only
            })),
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.rig?.rigStateDurations ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchRigActivityDurations(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigActivityDurationItem[]> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec);

        return this.rigActivityDurationsQuery.watch({
            rig,
            since: since.toISOString(),
            until: until.toISOString()
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.rig?.rigActivityDurations ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchRigConnections(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigConnection[]> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec);

        return this.rigConnectionsQuery.watch({
            rig,
            since: since.toISOString(),
            until: until.toISOString()
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.rig?.rigKPIConnections ?? [])
        );
    }

    watchRigConnectionSums(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigConnectionSumsItem> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec);

        return this.rigConnectionSumsQuery.watch({
            rig,
            since: since.toISOString(),
            until: until.toISOString()
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => (
                x?.rig?.rigKPIConnectionsSums &&
                typeof x.rig.rigKPIConnectionsSums.sumS2S !== 'undefined' &&
                typeof x.rig.rigKPIConnectionsSums.sumS2W !== 'undefined' &&
                typeof x.rig.rigKPIConnectionsSums.sumW2S !== 'undefined'
            )
                ? x.rig.rigKPIConnectionsSums
                : { sumS2S: 0, sumS2W: 0, sumW2S: 0 }),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchRopSums(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRopSumItem> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec);

        return this.rigRopSumsQuery.watch({
            rig,
            since: since.toISOString(),
            until: until.toISOString()
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => (
                x?.rig?.rigKPIRopSums &&
                typeof x.rig.rigKPIRopSums.avgValueRotaryDrilling !== 'undefined' &&
                typeof x.rig.rigKPIRopSums.avgValueRotaryDrilling !== 'undefined'
            )
                ? x.rig.rigKPIRopSums
                : { avgValueRotaryDrilling: 0, avgValueSlideDrilling: 0 }),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    watchRigRateOfAdvance(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigRateOfAdvanceItem[]> {
        if (!until) {
            until = new Date();
        }

        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec);

        return this.rigRateOfAdvance.watch({
            rig,
            since: since.toISOString(),
            until: until.toISOString()
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(x => x?.rig?.rigRateOfAdvance ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    // --

    private handleQueryErrors<T>(response: QueryResult<T>): QueryResult<T>['data'] {
        if (response.error) {
            throw new Error(response.error.message);
        }
        else if (response.errors) {
            throw new Error(response.errors.map(err => err.message).join('; '));
        }

        return response.data;
    }

    private assertRefreshInterval(interval?: number, defaultInterval = POLLING_INTERVAL): number {
        if (typeof interval !== 'number' || interval < REFRESH_INTERVAL_MIN) {
            interval = defaultInterval;
        }

        return interval;
    }

}