<div class="list" scrollShadow>
    <div class="item" *ngFor="let item of (options$|async)">
        <button mat-button ripple class="button" [class.selected]="isSelected(item)" (click)="onSelectItem(item)">
            <span>{{ item.name }}</span>
        </button>
        <div class="buttons">
            <div class="separator"></div>
            <button mat-button (click)="onEdit(item)">
                <icon name="edit"></icon>
            </button>
            <div class="separator"></div>
            <button mat-button (click)="onDelete(item)" [disabled]="!canDelete">
                <icon name="delete" ></icon>
            </button>
        </div>
    </div>
</div>