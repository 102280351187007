<carousel heading="" class="carousel">
    <div *ngFor="let file of skeletonCount" carousel-item class="card">
        <div class="head">
            <span class="extension skeleton">
                skeleton
            </span>
        </div>

        <div class="footer">
            <div class="meta skeleton">
                <span class="name skeleton">skeleton</span>
                <time class="date skeleton">skeleton</time>
            </div>

            <button [disabled]="true" class="action-button cl-icon-button skeleton">
                skel
            </button>

            <div class="divider"></div>

            <button [disabled]="true" class="action-button cl-icon-button skeleton">
                skel
            </button>
        </div>
    </div>
</carousel>