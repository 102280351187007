import { Injectable } from '@angular/core';

import { IIndexDBDatabase, IndexedDBWrapper, Point } from '@cyberloop/core';

type DrillingDb = {
    tagData: undefined
};

@Injectable({
    providedIn: 'root'
})
export class DrillingIndexerDb extends IndexedDBWrapper<DrillingDb> {
    constructor() {
        super('drilling');
    }

    public async getTagData(wellId: string, tagId: string, key: number): Promise<Point[]> {
        const id = `${wellId}|${tagId}|${key}`;
        const points = await this.getInternal<Point[]>('tagData', id);
        return points;
    }

    public async setTagData(wellId: string, tagId: string, key: number, data: Point[]): Promise<void> {
        const id = this.getId(wellId, tagId, key);
        await this.setInternal('tagData', data, id);
    }

    protected override migrateDb(db: IIndexDBDatabase<DrillingDb>): void {
        db.createObjectStore('tagData');
    }

    private getId(wellId: string, tagId: string, key: number): string {
        return `${wellId}|${tagId}|${key}`;
    }
}
