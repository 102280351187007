import { isNil } from 'lodash';

/**
 * Returns the start times of each N-hour slice within a specified time interval.
 *
 * @param {number} from - The start interval, in meters.
 * @param {number} to - The end interval, in meters.
 * @param {number} [meterPerSlice=100] - The number of meters per slice should be power of 10 (default: 100 meters).
 * @returns {number[]} An array of numbers, representing the start of each slice.
 * @example
 * const from = 333;
 * const to = 555;
 * console.log(getDepthSlices(from, to));
 * // Output: [ 300, 400, 500, 600 ]
 */
export function getDepthSlices(from: number, to: number, meterPerSlice: number = 100): number[] {
    if (isNil(from) || isNil(to)) {
        return [];
    }

    const starts: number[] = [];

    // Find the start of the first slice
    let currentStart = Math.floor(from / meterPerSlice) * meterPerSlice;

    currentStart = currentStart < meterPerSlice ? 0 : currentStart;

    // Add the start times for all slices in the time interval
    while (currentStart < to) {
        starts.push(currentStart);
        currentStart += meterPerSlice;
    }

    return starts.length ? starts : [0];
}