import { Injectable } from '@angular/core';

import { BehaviorSubject, distinctUntilChanged, filter, shareReplay, switchMap } from 'rxjs';

import jwt_decode from 'jwt-decode';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenProviderService {
    private static readonly _token$ = new BehaviorSubject<string | null>(null);

    private static readonly _intitialized$ = new BehaviorSubject(false);


    public get authToken(): string | null {
        return AuthTokenProviderService._token$.value;
    }

    public set authToken(value: string | null) {
        AuthTokenProviderService._token$.next(value);
        AuthTokenProviderService._intitialized$.next(true);
    }



    static readonly lastToken$ = AuthTokenProviderService._intitialized$.pipe(
        filter(Boolean),
        switchMap(() => AuthTokenProviderService._token$),
        filter(token => !token || Boolean(AuthTokenProviderService.coerceLastToken(token))),
        distinctUntilChanged(),
        shareReplay(1)
    );

    public static getLastToken() {
        return this.coerceLastToken(AuthTokenProviderService._token$.value);
    }

    private static coerceLastToken(token: string | null): string | null {
        if (!token) {
            return null;
        }

        const jwt = jwt_decode<{ exp: number }>(token);

        const exp = moment(jwt.exp * 1000);
        const now = moment();

        if (exp.isSameOrBefore(now)) {
            return null;
        }

        return token;
    }
}
