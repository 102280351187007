import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DatetimePipe, ID, IconComponent } from '@cyberloop/core';
import { PlanningVersion } from '@cyberloop/web/planning/shared/model';

@Component({
    selector: 'cyberloop-planning-version-history',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        DatetimePipe,
        IconComponent
    ],
    templateUrl: './planning-version-history.component.html',
    styleUrls: ['./planning-version-history.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningVersionHistoryComponent {
    @Input() data?: PlanningVersion[] | null;
    @Input() activeId?: ID | null;
    @Input() disableActions?: boolean | null;
    @Input() readonly?: boolean | null;

    @Output() readonly restore = new EventEmitter<PlanningVersion>();
    @Output() readonly delete = new EventEmitter<PlanningVersion>();
}
