import { Injectable } from '@angular/core';

import { SettingsProviderService } from '@cyberloop/core';
import { WellsSettings, defaultWellsSettings } from '@cyberloop/web/wells/model';

const WELL_FAVORITES = 'well.favorites';

@Injectable({
    providedIn: 'root'
})
export class WellFavoritesService {

    constructor(private readonly _settingsService: SettingsProviderService) { }

    getFavorites() {
        return this._settingsService.watchSettings<WellsSettings>(WELL_FAVORITES, defaultWellsSettings);
    }

    setFavorites(newFavorites: string[]) {
        this._settingsService.updateSettings(WELL_FAVORITES, {favorites: newFavorites});
    }

}
