import { Injectable } from '@angular/core';

import { Survey } from '@cyberloop/web/wells/model';

import { GraphQLSubscription } from '../internals/cl-gql';

type QueryRequest = {
    wellID: string
}

type QueryResponse = {
    surveysSubscription: Survey[];
}

@Injectable({
    providedIn: 'root'
})
export class SurveyDataSubscription extends GraphQLSubscription<QueryResponse, QueryRequest> {
    override readonly document = `subscription survey($wellID: String!) {
        surveysSubscription(wellId: $wellID) {
            vsec,
            hOffset,
            thl,
            ew,
            tvd,
            tvdss,
            md,
            hd,
            dls,
            inc,
            incCor,
            aziCor,
            ns,
            ID,
            sectionID,
            selected,
            number
        }
    }`;
}