export const DD_FEATURE = 'DIRECTIONAL_DRILLING';

/**
 * State for directional drilling
 * */
export interface DDState {
    sections: Record<string, number[]>;
    wellPlanId: Record<string, string | null>;
}

export function getDefaultDirectionalDrillingState(): DDState {
    return {
        sections: {},
        wellPlanId: {}
    };
}            
