import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, ClSelectorComponent, CoreSelectors, UnitsSelectors, WellKnownUnitGroupIds, WellKnownUnitIds } from '@cyberloop/core';
import { slideVsRotaryDefaultSettings } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { KpiWidgetSettingsComponent } from '../../widget-settings/kpi-widget-settings.component';

import type { PopupContent } from '@cyberloop/core';
import type { SlideVsRotaryWidgetSettings } from '@cyberloop/web/wells/model';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        FormsModule,
        ReactiveFormsModule,
        KpiWidgetSettingsComponent,
        ClSelectorComponent,
        ClButtonToggleComponent
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements PopupContent<SlideVsRotaryWidgetSettings> {

    private _closeFn?: (result: SlideVsRotaryWidgetSettings | null) => void;
    private _settings?: SlideVsRotaryWidgetSettings;

    constructor(
        private readonly store: Store
    ) {}

    readonly form = new FormGroup({
        sectionId: new FormControl<number | null>(null, [ Validators.required ]),
        timeUnits: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ]),
        lengthUnits: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ])
    });

    readonly sections$ = this.store.select(CoreSelectors.sectionsOfCurrentWell);

    readonly lengthUnits$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Length)).pipe(
        map(group => Object.values(group.units))
    );
    readonly timeUnits$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Time)).pipe(
        map(group => Object.values(group.units))
    );

    setData(data: SlideVsRotaryWidgetSettings): void {
        this._settings = data;

        if (data.sectionId) {
            this.form.controls.sectionId.setValue(data.sectionId);
        }
        if (data.lengthUnitId) {
            this.form.controls.lengthUnits.setValue(data.lengthUnitId as WellKnownUnitIds);
        }
        if (data.timeUnitId) {
            this.form.controls.timeUnits.setValue(data.timeUnitId as WellKnownUnitIds);
        }
    }

    setClose(fn: (result: SlideVsRotaryWidgetSettings | null) => void): void {
        this._closeFn = fn;
    }

    onCancel(): void {
        this._closeFn?.(null);
    }

    onSubmit(): void {
        // Compose settings

        const result: SlideVsRotaryWidgetSettings = {
            mode: this._settings?.mode ?? slideVsRotaryDefaultSettings.mode
        };

        if (this.form.controls.sectionId.value) {
            result.sectionId = this.form.controls.sectionId.value;
        }
        if (this.form.controls.lengthUnits.value) {
            result.lengthUnitId = this.form.controls.lengthUnits.value;
        }
        if (this.form.controls.timeUnits.value) {
            result.timeUnitId = this.form.controls.timeUnits.value;
        }

        this._closeFn?.(result);
    }

}
