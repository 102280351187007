import { HttpClientModule } from '@angular/common/http';
import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';

import { GraphQLClient } from './cl-gql/client';
import { GraphQLHttpClient } from './cl-gql/gql-http-client';
import { GraphQLLinkOptions, OPTIONS_TOKEN } from './options.token';

export function getGqlProviders(opts: GraphQLLinkOptions): EnvironmentProviders[] {
    return [
        makeEnvironmentProviders([
            { provide: OPTIONS_TOKEN, useValue: opts },

            { provide: GraphQLClient, useClass: GraphQLHttpClient }
        ]),
        importProvidersFrom(HttpClientModule)
    ];
}
