import { FileTag } from '@cyberloop/web/files/model';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const FILES_TAG_FEATURE = 'FILES_TAG';

/**
 * State for files tag
 * */
export interface FilesTagState extends EntityState<FileTag> {
    listLoading: boolean;
    listError?: Error;

    createLoading: boolean;
    createError?: Error;
}

export const adapter = createEntityAdapter<FileTag>({
    sortComparer: (a, b) => b.createdAt.valueOf() - a.createdAt.valueOf()
});

export const initialState: FilesTagState = adapter.getInitialState({
    listLoading: false,
    createLoading: false
});
