import { Injectable } from '@angular/core';

import { ID, NotificationService } from '@cyberloop/core';
import { FilesActions } from '@cyberloop/web/files/data';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { catchError, combineLatest, debounceTime, filter, first, map, switchMap, takeUntil, tap } from 'rxjs';

import { PlanningVersionDataService } from '../../services/planning-version-data.service';
import { PlanningService } from '../../services/planning.service';
import { PlanningActions } from '../planning.actions';
import { PlanningVersionActions } from './version.actions';
import { PlanningVersionSelectors } from './version.selectors';
import { VERSION_FEATURE } from './version.state';

const initAction = createAction(`[${VERSION_FEATURE}] Initialize version`);

@Injectable()
export class VersionEffects implements OnInitEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly planningVersionDataService: PlanningVersionDataService,
        private readonly planningService: PlanningService,
        private readonly notificationService: NotificationService
    ) { }

    //#region Common
    readonly handleError$ = createEffect(() => this.actions$.pipe(
        ofType(
            PlanningVersionActions.loadListFailure,
            PlanningVersionActions.loadVersionFailure,
            PlanningVersionActions.createVersionFailure,
            PlanningVersionActions.updateVersionFailure,
            PlanningVersionActions.replaceVersionFailure,
            PlanningVersionActions.deleteVersionFailure
        ),
        tap(({ error }) => this.notificationService.error(error))
    ), { dispatch: false });
    //#endregion Common

    //#region List
    readonly loadList$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.loadList),
        switchMap(() => this.planningService.planningId$.pipe(
            first(),
            switchMap((planningId) => this.planningVersionDataService.watchAll(planningId).pipe(
                takeUntil(this.actions$.pipe(ofType(PlanningActions.unwatchPlanning))),
                map(list => PlanningVersionActions.loadListSuccess({ list })),
                catchError(error => [PlanningVersionActions.loadListFailure({ error })])
            ))
        ))
    ));
    //#endregion List

    //#region Version
    //#region Load
    readonly loadVersionAfterPlanningLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.loadPlanningSuccess, PlanningActions.loadWellPlanSuccess),
        map(({ planning }) => planning),
        filter(Boolean),
        map(() => PlanningVersionActions.loadVersion()))
    );

    readonly loadActiveVersion$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.loadVersion),
        switchMap(() => this.planningService.planning$.pipe(
            first(),
            filter(Boolean),
            switchMap(({ id: planningId, activeVersionId }) => this.planningVersionDataService.watch(planningId, activeVersionId).pipe(
                takeUntil(this.actions$.pipe(ofType(PlanningActions.unwatchPlanning))),
                filter(Boolean),
                debounceTime(100),
                map(version => PlanningVersionActions.loadVersionSuccess({ version })),
                catchError(error => [PlanningVersionActions.loadVersionFailure({ error })])
            ))
        ))
    ));

    readonly updateStages$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.loadVersionSuccess),
        map(({ version }) => version?.stages),
        filter(Boolean),
        map((stages) => PlanningVersionActions.setStages({ stages }))
    ));
    //#endregion Load

    //#region Create
    readonly createVersion$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.createVersion),
        switchMap(({ saveAsResult }) => combineLatest([
            this.planningService.planningId$.pipe(first(), filter(Boolean)),
            this.planningService.editedStages$.pipe(first(), filter(Boolean))
        ]).pipe(
            switchMap(([planningId, updatedStages]) => this.planningVersionDataService.create(planningId, saveAsResult, updatedStages).pipe(
                map((result) => PlanningVersionActions.createVersionSuccess({ version: result })),
                catchError(error => [PlanningVersionActions.createVersionFailure({ error })])
            ))
        ))
    ));

    readonly createVersionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.createVersionSuccess),
        tap(({ version }) => this.notificationService.info(`Version '${version.name}' created`))
    ), { dispatch: false });
    //#endregion Create

    //#region Update
    readonly updateVersion$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.updateVersion),
        switchMap(({ saveAsResult }) => combineLatest([
            this.planningService.planningId$.pipe(first(), filter(Boolean)),
            this.planningService.version$.pipe(first(), filter(Boolean)),
            this.planningService.editedStages$.pipe(first(), filter(Boolean))
        ]).pipe(
            switchMap(([planningId, version, updatedStages]) =>
                this.planningVersionDataService.update(planningId, version, saveAsResult, updatedStages).pipe(
                    map((result) => PlanningVersionActions.updateVersionSuccess({ version: result })),
                    catchError(error => [PlanningVersionActions.updateVersionFailure({ error })])
                )
            )
        ))
    ));

    readonly updateVersionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.updateVersionSuccess),
        tap(({ version }) => this.notificationService.info(`Version '${version.name}' updated`))
    ), { dispatch: false });
    //#endregion Update

    //#region Replace
    readonly replaceVersion$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.replaceVersion),
        switchMap(({ replaceVersionId, saveAsResult }) => combineLatest([
            this.planningService.planningId$.pipe(first(), filter(Boolean)),
            this.store.select(PlanningVersionSelectors.selectVersion(replaceVersionId)).pipe(first(), filter(Boolean)),
            this.planningService.editedStages$.pipe(first(), filter(Boolean))
        ]).pipe(
            switchMap(([planningId, version, updatedStages]) =>
                this.planningVersionDataService.replace(planningId, version, saveAsResult, updatedStages).pipe(
                    map((result) => PlanningVersionActions.replaceVersionSuccess({ version: result })),
                    catchError(error => [PlanningVersionActions.replaceVersionFailure({ error })])
                )
            )
        ))
    ));

    readonly replaceVersionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.replaceVersionSuccess),
        tap(({ version }) => this.notificationService.info(`Version '${version.name}' replaced`))
    ), { dispatch: false });
    //#endregion Replace

    //#region Delete
    readonly deleteVersion$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.deleteVersion),
        switchMap(({ version }) => this.planningService.planningId$.pipe(
            filter(Boolean),
            first(),
            switchMap((planningId) => this.planningVersionDataService.delete(planningId, version.id).pipe(
                map(() => PlanningVersionActions.deleteVersionSuccess({ version })),
                catchError(error => [PlanningVersionActions.deleteVersionFailure({ error })])
            ))
        ))
    ));

    readonly deleteVersionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.deleteVersionSuccess),
        tap(({ version }) => this.notificationService.info(`Version '${version.name}' deleted`))
    ), { dispatch: false });
    //#endregion Delete

    //#region Files
    readonly loadFileList$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningVersionActions.loadVersionSuccess),
        map(({ version }) => {
            const stages = version?.stages ?? [];
            const fileIdsToLoad: ID[] = [];

            for (let index = 0; index < stages.length; index++) {
                const stage = stages[index];
                fileIdsToLoad.push(...stage.files);
            }

            return fileIdsToLoad;
        }),
        map(ids => FilesActions.loadListByIds({ ids }))
    ));
    //#endregion Files
    //#endregion Version

    ngrxOnInitEffects(): Action {
        return initAction();
    }
}
