import { TndChart } from '@cyberloop/web/tnd/model';
import { createActionGroup, props } from '@ngrx/store';

import { PLANNING_ENGINEERING_TND_FEATURE } from './tnd.state';

import type { PlanningTndChartSettings } from '@cyberloop/web/planning/shared/model';



const TndActionsInternal = createActionGroup({
    source: PLANNING_ENGINEERING_TND_FEATURE,
    events: {
        'Set weights chart settings': props<{ settings: PlanningTndChartSettings }>(),
        'Set torque chart settings': props<{ settings: PlanningTndChartSettings }>(),

        'Select model for weights chart': props<{ modelId?: string }>(),
        'Select model for torque chart': props<{ modelId?: string }>(),

        'Set error': props<{ error?: Error }>(),
    }
});

/** Sets the selected model ID for T&D chart by type */
const selectModel = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return TndActionsInternal.selectModelForTorqueChart;

        default:
            return TndActionsInternal.selectModelForWeightsChart;
    }
};


/**
 * @internal
 * Actions for Wells T&D used internally
 * */
export const TndActions = {
    ...TndActionsInternal,
    selectModel
};

/**
 * Public actions for Wells T&D
 * */
export const PublicTndActions = {
    selectModelForWeightsChart: TndActionsInternal.selectModelForWeightsChart,
    selectModelForTorqueChart: TndActionsInternal.selectModelForTorqueChart,
    selectModel
};
