import { Injectable } from '@angular/core';

import { Points, RigActivity, RigState, WellKnownParams } from '@cyberloop/core';
import { Observable, map, of, timer } from 'rxjs';

function generatePoints() {
    const pointsPerMinute = .1;
    const numDots = 900;
    const msPerMinute = 60000;
    const points: Points = [];

    const now = new Date().getTime();

    const timeStep = msPerMinute / pointsPerMinute;

    for (let index = 0; index < numDots; index++) {
        points.push({ y: Math.random() * 20, x: now - timeStep * (numDots - index) });
    }

    return points;
}

const WELL_CURRENT_POINTS: Points = generatePoints();
const WELL_PLANNED_POINTS: Points = generatePoints();

@Injectable({
    // This should be the Singleton
    providedIn: 'root'
})
export class WellDataService {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getWellCurrentPoints(wellId: string): Observable<Points> {
        // TODO: Fetch well chart points
        return timer(0, 30000).pipe(
            map(() => {
                const result: Points = [];

                for (let i = 0; i < WELL_CURRENT_POINTS.length; i++) {
                    result.push({
                        x: WELL_CURRENT_POINTS[i].x,
                        y: Math.random() * 20
                    });
                }

                return result;
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getWellPlannedPoints(wellId: string): Observable<Points> {
        // TODO: Fetch well chart points
        return of(WELL_PLANNED_POINTS);
    }

    getAssetValue$(wellId: string, tagName: WellKnownParams): Observable<number | null> {
        // TODO
        return timer(0, (Math.random() * 9 + 1) * 1000).pipe(
            map(() => {
                if (tagName === WellKnownParams.RST) {
                    const rigStates = Object.values(RigState);
                    return rigStates[Math.floor(Math.random() * rigStates.length)] as number;
                }
                if (tagName === WellKnownParams.RAC) {
                    const rigActivity = Object.values(RigActivity);
                    return rigActivity[Math.floor(Math.random() * rigActivity.length)] as number;
                }
                return Math.random() * 100;
            })
        );
    }

}