import { Injectable } from '@angular/core';

import { Template, TemplateRepositryService } from '@cyberloop/core';
import { SelectedTrace } from '@cyberloop/web/wells/model';
import { Observable } from 'rxjs';

import { DrillingExportTemplatesProvideService } from './drilling-export-templates.provider.service';

export interface ExportTemplate extends Template {
    data?: ExportTemplateToSave
}

export interface ExportTemplateToSave {
    traces?: SelectedTrace[], 
    precision?: number,
    timeStep?: number,
    depthStep?: number
}

@Injectable()
export class ExportTemplateService extends TemplateRepositryService<ExportTemplate> {

    constructor(private readonly provider: DrillingExportTemplatesProvideService) {
        super();
    }

    override watchAll(): Observable<ExportTemplate[]> {
        return this.provider.watchAll();
    }
    override get(id: string): Observable<ExportTemplate | null> {
        return this.provider.get(id);
    }
    override create(template: Omit<ExportTemplate, 'id' | 'author' | 'createdAt' | 'updatedAt'>): Observable<ExportTemplate> {
        return this.provider.create(template);
    }
    override delete(templateId: string): Observable<ExportTemplate> {
        return this.provider.delete(templateId);
    }

}