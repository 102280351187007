import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { DirectionalDrillingEffects } from './dd.effects';
import { directionalDrillingReducer } from './dd.reducer';
import { DD_FEATURE } from './dd.state';

export const DirectionalDrillingProvides = [
    provideEffects([DirectionalDrillingEffects]),
    provideState(DD_FEATURE, directionalDrillingReducer)
];