import { createReducer, on } from '@ngrx/store';

import { FilesTagActions } from './tag.actions';
import { FilesTagState, adapter, initialState } from './tag.state';

export const filesTagReducer = createReducer<FilesTagState>(initialState,
    //#region Load list
    on(FilesTagActions.loadList, (state): FilesTagState => ({
        ...state,
        listLoading: true,
        listError: undefined
    })),
    on(FilesTagActions.loadListSuccess, (state, { list }): FilesTagState =>
        adapter.setAll<FilesTagState>(list, {
            ...state,
            listLoading: false
        })
    ),
    on(FilesTagActions.loadListFailure, (state, { error }): FilesTagState => ({
        ...state,
        listLoading: false,
        listError: error
    })),
    //#endregion Load list

    //#region Create
    on(FilesTagActions.create, (state): FilesTagState => ({
        ...state,
        createLoading: true,
        createError: undefined
    })),
    on(FilesTagActions.createSuccess, (state, { tag }): FilesTagState =>
        adapter.addOne<FilesTagState>(tag, {
            ...state,
            createLoading: false
        })
    ),
    on(FilesTagActions.createFailure, (state, { error }): FilesTagState => ({
        ...state,
        createLoading: false,
        createError: error
    }))
    //#endregion Create
);
