<div class="head">
    <h1 class="title">
        Load template
    </h1>

    <cl-input [formControl]="searchControl" placeholder="Search" class="search">
        <icon name="search" suffix></icon>
    </cl-input>

    <cl-checkbox-group [formControl]="selectedFiltersControl" [list]="filterItems"
        class="checkbox-group"></cl-checkbox-group>
</div>

<div class="table">
    <div class="table-head">
        <div class="col">template name</div>
        <div class="col">description</div>
        <div class="col">created by</div>
        <div class="col">last edited</div>
        <div class="col"></div>
    </div>
    <div class="table-body">
        <ng-container *ngIf="listLoaded$ | async">
            <div *ngFor="let template of list$ | async; empty noData" class="table-row">
                <div class="col name">{{ template.name }}</div>
                <div class="col">{{ template.description }}</div>
                <div class="col">{{ template.author }}</div>
                <div class="col">{{ template.updatedAt | datetime }}</div>
                <div class="col actions">
                    <button (click)="onSelect(template)" class="action cl-button">
                        <icon name="download"></icon>
                        <span class="label">Upload</span>
                    </button>

                    <button (click)="onDelete(template)" class="action cl-button">
                        <icon name="delete"></icon>
                        <span class="label">Delete</span>
                    </button>
                </div>
            </div>
            <ng-template #noData>
                <div class="no-data">No data</div>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="listLoading$ | async">
            <div *ngFor="let template of skeletonCount" class="table-row">
                <div class="col name skeleton">lorem</div>
                <div class="col skeleton">lorem</div>
                <div class="col skeleton">lorem</div>
                <div class="col skeleton">lorem</div>
                <div class="col actions skeleton-frame">lorem</div>
            </div>
        </ng-container>
    </div>
</div>


<button (click)="close()" class="close cl-icon-button">
    <icon name="close"></icon>
</button>
