import type { NavigationItem, RecentItem, WellDataObject } from '@cyberloop/web/app/model';
import { WellsFilters } from '@cyberloop/web/wells/model';
import { createAction, props } from '@ngrx/store';

import { APP_FEATURE } from './app.state';

const getApps = createAction(`[${APP_FEATURE}] Get apps`);
const loadApps = createAction(`[${APP_FEATURE}] Load apps`);
const appsLoaded = createAction(`[${APP_FEATURE}] Apps loaded`, props<{ apps: NavigationItem[] }>());
const appsLoadError = createAction(`[${APP_FEATURE}] Apps load error`, props<{ error?: Error }>());

const getRecentItems = createAction(`[${APP_FEATURE}] Get recent items`);
const loadRecentItems = createAction(`[${APP_FEATURE}] Load recent items`);
const recentItemsLoaded = createAction(`[${APP_FEATURE}] Recent items loaded`, props<{ recentItems: RecentItem<any>[] }>());
const recentItemsLoadError = createAction(`[${APP_FEATURE}] Recent items load error`, props<{ error?: Error }>());


const setFilters = createAction(`[${APP_FEATURE}] Set filters`, props<{ filters: WellsFilters }>());
const setWellsData = createAction(`[${APP_FEATURE}] Set wells data`, props<{ wells: WellDataObject[] }>());
const setFavorites = createAction(`[${APP_FEATURE}] Set favorites`, props<{ favorites:string[] }>());
const toggleFavorite = createAction(`[${APP_FEATURE}] Toggle favorite`, props<{ wellId: string }>());

/**
 * Actions for app
 * */
export const PublicAppActions = {
    getApps,
    getRecentItems,
    toggleFavorite,
    setFilters
};

/**
 * @internal
 * Actions for app used internally
 * */
export const AppActions = {
    ...PublicAppActions,
    getApps,
    loadApps,
    appsLoaded,
    appsLoadError,

    getRecentItems,
    loadRecentItems,
    recentItemsLoaded,
    recentItemsLoadError,

    setWellsData,
    setFavorites
};
