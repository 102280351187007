<div class="container" [class.released]="releaseTime" [class.suspended]="suspendTime" [class.navigated-from-me]="navigatedFromMe">
    <div class="favorite">
        <div class="star" [class.active]="favorite" (click)="clickFavorite()">
            <icon name="star"></icon>
        </div>
    </div>

    <div class="well-section">
        <div class="title">Well</div>
        <div class="value-container">
            <div matTooltip="{{ wellName }}" matTooltipPosition="below" class="value">{{ wellName }}</div>

            <div class="chart-section-inner">
                <icon matRipple class="chart-icon" name="chart" (click)="openChartDialog()"></icon>
            </div>
        </div>
    </div>

    <div class="rig-section">
        <div class="left-panel">
            <div class="title">Rig</div>
            <div class="value" matTooltip="{{ rigName }}" matTooltipPosition="below"><span>{{ rigName }}</span></div>
        </div>
        <ng-container *ngIf="releaseTime; else activeOrSuspendedRigStatus">
            <cyberloop-rig-status text="Released" [aminationDelay]="randomDelay"></cyberloop-rig-status>
        </ng-container>
        <ng-template #activeOrSuspendedRigStatus>
            <ng-container *ngIf="suspendTime; else activeRigStatus">
                <cyberloop-rig-status text="Suspended" [aminationDelay]="randomDelay"></cyberloop-rig-status>
            </ng-container>
            <ng-template #activeRigStatus>
                <cyberloop-rig-status [text]="getRigActivityName(rigActivity)" [aminationDelay]="randomDelay" [activityStatus]="rigActivity"></cyberloop-rig-status>
            </ng-template>
        </ng-template>
    </div>

    <cyberloop-well-card-text class="field drilled" title="Drilled" [value]="currentDrilled | fromSi:depthUnit" [format]="getFormat(currentDrilled | fromSi:depthUnit)" [label]="depthUnitLabel"></cyberloop-well-card-text>


    <cyberloop-well-card-text class="field spud" title="Since Spud" [value]="currentSinceSpud" label="days" format="1.0-0"></cyberloop-well-card-text>

    <cyberloop-well-card-text class="field crot" title="Slide/Rot" [value]="currentRotary" [value2]="currentSlide" format="1.0-0" label="%"></cyberloop-well-card-text>

    <cyberloop-well-card-text class="field cbtm" title="On Btm" [value]="currentOnBtm" label="%"></cyberloop-well-card-text>

    <cyberloop-well-card-text class="field bde" title="Bit Depth" [value]="currentBitDepth | fromSi:depthUnit" [label]="depthUnitLabel" [format]="getFormat(currentBitDepth | fromSi:depthUnit)"></cyberloop-well-card-text>

    <cyberloop-well-card-text class="field wde" title="Well Depth" [value]="currentWellDepth | fromSi:depthUnit" [format]="getFormat(currentWellDepth | fromSi:depthUnit)" [label]="depthUnitLabel"></cyberloop-well-card-text>

    <div class="chart-section">
        <icon matRipple class="chart-icon" name="chart" (click)="openChartDialog()"></icon>
    </div>

    <div *ngIf="releaseTime || suspendTime; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div class="is-released start-section">
            <div class="title">Start time</div>
            <div class="date">{{ startTime | date:'hh:mm dd.MM.y' }}</div>
        </div>
        <div class="is-released released-section">
            <ng-container *ngIf="releaseTime; then releaseBlock else suspendBlock">
            </ng-container>
            <ng-template #releaseBlock>
                <div class="title">Released time</div>
                <div class="date">{{ releaseTime | date:'hh:mm dd.MM.y' }}</div>
            </ng-template>
            <ng-template #suspendBlock>
                <div class="title">Suspend time</div>
                <div class="date">{{ suspendTime | date:'hh:mm dd.MM.y' }}</div>
            </ng-template>
        </div>
        <div class="is-released wellbores-section">
            <div class="title">Wellbores</div>
            <div class="date">{{ sectionsCount }}</div>
        </div>
    </ng-template>
    <ng-template #elseBlock>

        <div class="daily-section">
            <div class="title">Daily metrics</div>
            <div class="value">Last 24 hrs</div>
        </div>
        <cyberloop-well-card-text class="field daily-drilled" title="Drilled" [value]="dailyDrilled" [label]="depthUnitLabel"></cyberloop-well-card-text>
        <cyberloop-well-card-text class="field daily-slide" title="Slide/Rot" [value]="dailySlide" [value2]="dailyRotary" format="1.0-0" label="%"></cyberloop-well-card-text>
        <cyberloop-well-card-text class="field daily-rop" title="ROP" [value]="dailyRop" [label]="ropUnitLabel"></cyberloop-well-card-text>
        <cyberloop-well-card-text class="field daily-btm" title="On Btm" [value]="dailyOnBtm" label="%"></cyberloop-well-card-text>

    </ng-template>

    <div class="expand-section" [attr.aria-label]="'Go to well ' + wellName">
        <div class="chart-section-expand">
            <icon matRipple class="chart-icon" name="chart" (click)="openChartDialog()"></icon>
        </div>

        <div class="btn-expand">
            <icon name="arrow__rounded__down"></icon>
            <input type="checkbox" matRipple (click)="navigateToWell.emit()">
        </div>
    </div>
</div>
