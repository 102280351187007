import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { ClNumericInputComponent, ClSelectorComponent, CoreSelectors } from '@cyberloop/core';
import { casingRunningDefaultSettings } from '@cyberloop/web/wells/model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';

import { KpiWidgetSettingsComponent } from '../../widget-settings/kpi-widget-settings.component';

import type { CasingRunningWidgetSettings } from '@cyberloop/web/wells/model';
import type { PopupContent } from '@cyberloop/core';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        FormsModule,
        ReactiveFormsModule,
        KpiWidgetSettingsComponent,
        ClSelectorComponent,
        ClNumericInputComponent,
        MatButtonToggleModule
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class SettingsComponent implements PopupContent<CasingRunningWidgetSettings> {

    private _closeFn?: (result: CasingRunningWidgetSettings | null) => void;

    constructor(
        private readonly store: Store
    ) { }

    readonly form = new FormGroup({
        sectionId: new FormControl<number | null>(null, [ Validators.required ]),
        target: new FormControl<number | null>(null)
    });

    readonly sections$ = this.store.select(CoreSelectors.sectionsOfCurrentWell);

    setData(data: CasingRunningWidgetSettings): void {
        if (data.sectionId) {
            this.form.controls.sectionId.setValue(data.sectionId);
        }

        if (!isNil(data.target)) {
            this.form.controls.target.setValue(data.target);
        }
    }

    setClose(fn: (result: CasingRunningWidgetSettings | null) => void): void {
        this._closeFn = fn;
    }

    onCancel(): void {
        this._closeFn?.(null);
    }

    onSubmit(): void {
        // Compose settings

        const result: CasingRunningWidgetSettings = {
            distanceUnitId: casingRunningDefaultSettings.distanceUnitId
        };

        if (this.form.controls.sectionId.value) {
            result.sectionId = this.form.controls.sectionId.value;
        }

        if (!isNil(this.form.controls.target.value) && String(this.form.controls.target.value).length > 0) {
            result.target = parseInt(String(this.form.controls.target.value), 10);
        }

        this._closeFn?.(result);
    }

}
