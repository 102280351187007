import { createReducer, on } from '@ngrx/store';

import { AppActions } from './app.actions';
import { AppState, getDefaultAppState } from './app.state';

export const appReducer = createReducer<AppState>(getDefaultAppState(),

    // Apps

    on(AppActions.loadApps, (state): AppState => ({
        ...state,
        appsLoadError: undefined,
        appsLoadingState: 'LOADING'
    })),

    on(AppActions.appsLoaded, (state, { apps }): AppState => ({
        ...state,
        apps,
        appsLoadingState: 'LOADED'
    })),

    on(AppActions.appsLoadError, (state, { error }): AppState => ({
        ...state,
        appsLoadError: error,
        appsLoadingState: 'NOT_LOADED'
    })),

    // Recent places

    on(AppActions.loadRecentItems, (state): AppState => ({
        ...state,
        recentItemsLoadError: undefined,
        recentItemsLoadingState: 'LOADING'
    })),

    on(AppActions.recentItemsLoaded, (state, { recentItems }): AppState => ({
        ...state,
        recentItems,
        recentItemsLoadingState: 'LOADED'
    })),

    on(AppActions.recentItemsLoadError, (state, { error }): AppState => ({
        ...state,
        recentItemsLoadError: error,
        recentItemsLoadingState: 'NOT_LOADED'
    })),

    //wells
    on(AppActions.setFilters, (state, { filters }): AppState => ({
        ...state,
        wellsFilters: filters
    })),
    on(AppActions.setWellsData, (state, { wells }): AppState => ({
        ...state,
        wellsData: wells,
        wellsDataIsLoaded: true
    })),
    on(AppActions.setFavorites, (state, { favorites }): AppState => ({
        ...state,
        favoritesAreLoaded: true,
        favorites
    }))
);

