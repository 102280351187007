import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiDrillingMetricsPerJointTimeItem } from '@cyberloop/web/wells/model';
type KpiDrillingMetricsPerJointInput = {
    rig: string,
    since: string,
    until: string
}

type KpiDrillingMetricsPerJointTime = {
    rig: {
        drillingMetricsPerJointTime: KpiDrillingMetricsPerJointTimeItem[]
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiDrillingMetricsPerJointTimeQuery extends GraphQLQuery<KpiDrillingMetricsPerJointTime, KpiDrillingMetricsPerJointInput> {
    override readonly document = `
        query GetRigDrillingMetricsPerJointTime($rig: String!, $since: DateTime!, $until: DateTime!) {
            rig(name: $rig) {
                ... on Rig {
                    drillingMetricsPerJointTime(since: $since, until: $until) {
                        startTime
                        rotaryTimeDrilled
                        slideTimeDrilled
                    }
                }
            }
        }
    `;
}