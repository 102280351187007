import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FORECAST_FEATURE, ForecastState } from './forecast.state';

const feature = createFeatureSelector<ForecastState>(FORECAST_FEATURE);

const forecast = createSelector(feature, state => state.forecast);
const forecastId = createSelector(feature, state => state.forecast?.id);
const forecastLoading = createSelector(feature, state => state.forecastLoadingState === 'LOADING');
const forecastLoaded = createSelector(feature, state => state.forecastLoadingState === 'LOADED');

const eventList = createSelector(feature, state => state.eventList);
const eventListLoading = createSelector(feature, state => state.eventListLoadingState === 'LOADING');
const eventListLoaded = createSelector(feature, state => state.eventListLoadingState === 'LOADED');

const isLoading = createSelector(feature, state =>
    state.forecastLoadingState === 'LOADING' ||
    state.eventListLoadingState === 'LOADING' ||
    state.createEventLoadingState === 'LOADING' ||
    state.updateEventLoadingState === 'LOADING' ||
    state.expandToNextEventLoadingState === 'LOADING' ||
    state.deleteEventLoadingState === 'LOADING' ||
    state.importFromWellPlanLoadingState === 'LOADING' ||
    state.exportLoadingState === 'LOADING' ||
    state.equipmentAndPersonnelEventListLoadingState === 'LOADING' ||
    state.createEquipmentAndPersonnelEventLoadingState === 'LOADING' ||
    state.updateEquipmentAndPersonnelEventLoadingState === 'LOADING'
);

const editableEventId = createSelector(feature, state => state.editableEventId);

const equipmentAndPersonnelEventList = createSelector(feature, state => state.equipmentAndPersonnelEventList);
const equipmentAndPersonnelEventListLoading = createSelector(feature, state => state.equipmentAndPersonnelEventListLoadingState === 'LOADING');
const equipmentAndPersonnelEventListLoaded = createSelector(feature, state => state.equipmentAndPersonnelEventListLoadingState === 'LOADED');

const estimatedRigReleaseDate = createSelector(eventList, state => {
    const sorted = state.slice().sort((a, b) => a.endDate.valueOf() - b.endDate.valueOf());
    return sorted.at(-1)?.endDate;
});

/**
 * Selectors for forecast
 * */
export const ForecastSelectors = {
    forecast,
    forecastId,
    forecastLoading,
    forecastLoaded,

    eventList,
    eventListLoading,
    eventListLoaded,

    editableEventId,

    isLoading,

    equipmentAndPersonnelEventList,
    equipmentAndPersonnelEventListLoading,
    equipmentAndPersonnelEventListLoaded,

    estimatedRigReleaseDate
};
