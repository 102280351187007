import { Injectable } from '@angular/core';

import { ConfirmationDialogService, Point, Points, PopupService, SectionInput, UnitsSelectors, WellKnownParams, WellKnownUnitGroupIds } from '@cyberloop/core';
import { WellDataObject } from '@cyberloop/web/app/model';
import { PlanningActions, PlanningService } from '@cyberloop/web/planning/shared/data';
import { DaysVsDepthWidgetData } from '@cyberloop/web/wells/model';
import { SetPlanComponent } from '@cyberloop/web/wells/ui';
import { Store } from '@ngrx/store';
import { PlanningDataService } from 'libs/planning/shared/data/src/lib/services/planning-data.service';
import { PlanningVersionDataService } from 'libs/planning/shared/data/src/lib/services/planning-version-data.service';
import { isNil } from 'lodash';
import { EMPTY, Observable, combineLatest, filter, map, switchMap } from 'rxjs';

import * as moment from 'moment';

import { WellsActions } from '../state/wells.actions';
import { KpiDataService } from './kpi-data.service';
import { TagHistoryInterval, getPointsFromTagHistoryData } from './kpi-widget-data/utils';

@Injectable({
    providedIn: 'root'
})
export class WellsService {
    constructor(
        private readonly store: Store,
        private readonly popupService: PopupService,
        private readonly planningService: PlanningService,
        private readonly confirmationDialogService: ConfirmationDialogService,
        private readonly kpiDataService: KpiDataService,
        private readonly planningDataService: PlanningDataService,
        private readonly planningVersionDataService: PlanningVersionDataService
    ) { }

    async openSetPlanPopup() {
        const result = await this.popupService.showForResult(SetPlanComponent, {
            data: {
                planningList$: this.planningService.list$.pipe(map(list => list.filter(planning => isNil(planning.wellId)))),
                planningListLoading$: this.planningService.listLoading$,
                planningListLoaded$: this.planningService.listLoaded$
            }
        });

        if (isNil(result)) {
            return;
        }

        if (isNil(result.planningId)) {
            this.store.dispatch(PlanningActions.openAddPlanningPopup());
        }
        else {
            this.store.dispatch(WellsActions.setPlan({ planningId: result.planningId }));
        }
    }

    confirmImportFromWellPlan() {
        return this.confirmationDialogService.show('Do you want to import data from Well Plan?');
    }

    getWellChartData(well: WellDataObject): Observable<DaysVsDepthWidgetData> {
        if (!well) {
            return EMPTY;
        }

        const since = well.startTime;
        const until = well.releaseTime ?? well.suspendTime ?? new Date();

        const step = 3600;

        return combineLatest([
            this.kpiDataService.watchTagTimeHistoryByWellId(
                well.id, [ WellKnownParams.WDE, WellKnownParams.BDE ], step, since, until
            ),
            this.planningDataService.watchByWellId(well.id).pipe(
                switchMap((planing) => this.planningVersionDataService.watch(planing?.id ?? '', planing?.activeVersionId ?? '')),
                map((version) => version?.stages ?? []),
                switchMap((stages) => this.store.select(UnitsSelectors.getUserUnit(WellKnownUnitGroupIds.Length)).pipe(
                    filter(Boolean),
                    map((depthUnit) => this.planningService.mapChartData(depthUnit, stages))
                ))
            )
        ]).pipe(
            map(([historyData, planData]) => {

                let wdeData: TagHistoryInterval[] = [];
                let bdeData: TagHistoryInterval[] = [];

                for (const item of historyData) {
                    switch (item.tagName) {

                        case WellKnownParams.WDE:
                            wdeData = item.intervals;
                            break;

                        case WellKnownParams.BDE:
                            bdeData = item.intervals;
                            break;

                        default:
                            break;
                    }
                }

                const wde: Points = getPointsFromTagHistoryData(step, wdeData);
                const bde: Points = getPointsFromTagHistoryData(step, bdeData);

                const sections: SectionInput[] = this.getWellSections(well, wde);
                const { plan, planLimit } = this.getPlanPoints(well, planData);

                const data: DaysVsDepthWidgetData = {
                    wde,
                    bde,
                    plan,
                    planLimit,
                    sections
                };

                return data;
            })
        );
    }

    private getWellSections(well: WellDataObject, wde: Points): SectionInput[] {
        const sections: SectionInput[] = [];
        for (let i = 0; i < well.sections.length; i++) {
            const section = well.sections[i];

            let startTime = section.startTime;

            // Fix bad start times

            if (startTime.getFullYear() < 1970) {
                // Make it the same as well start time
                startTime = well.startTime;
            }
            if (startTime.getFullYear() < 1970 && wde.length > 0) {
                // Set it to the first WDE point time
                startTime = new Date(wde[0].x);
            }

            sections.push({
                id: section.id.toString(),
                name: section.name,
                value: startTime.getTime()
            });
        }

        return sections;
    }

    private getPlanPoints(well: WellDataObject, planData: Point[][]) {
        const plan: Points = [];
        const planLimit: Points = [];

        if (planData && planData.length > 0) {
            const startTime = moment(well.startTime);

            plan.push({
                x: startTime.valueOf(),
                y: 0
            });

            const startTimeLimit = moment(well.startTime);

            planLimit.push({
                x: startTimeLimit.valueOf(),
                y: 0
            });

            for (const item of planData[0]) {
                const currentTime = startTime.clone().add(item.x, 'days');

                plan.push({
                    x: currentTime.valueOf(),
                    y: item.y
                });
            }
            for (const item of planData[1]) {
                const currentTime = startTimeLimit.clone().add(item.x, 'days');

                planLimit.push({
                    x: currentTime.valueOf(),
                    y: item.y
                });
            }
        }

        return {
            plan,
            planLimit
        };
    }
}
