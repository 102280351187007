import { FirebaseApp } from '@firebase/app';
import { deleteDoc, getDoc, getFirestore, onSnapshot, setDoc, updateDoc } from '@firebase/firestore';
import { isNil } from 'lodash';
import { Observable, catchError, shareReplay, takeWhile, throwError } from 'rxjs';

import { DocumentData, FirestoreDataConverter, Query, QueryCompositeFilterConstraint, QueryConstraint, QueryNonFilterConstraint, UpdateData, getDocs, query, runTransaction } from 'firebase/firestore';

import { IClTransaction } from '../../../models/transaction';
import { ClBase } from './cl-firestore-base';
import { ClTransaction } from './cl-firestore-transaction';

export class ClFirestore extends ClBase {
    private _disposed = false;

    /**
     *
     */
    private constructor(app: FirebaseApp) {
        super(app);
    }

    static create(app: FirebaseApp): ClFirestore {
        return new ClFirestore(app);
    }

    async get<T>(path: string, converter?: FirestoreDataConverter<T>): Promise<T | null> {
        try {
            let docRef = this.getDocumentReference<T>(path);

            if (!isNil(converter)) {
                docRef = docRef.withConverter(converter);
            }

            const snapshot = await getDoc(docRef);
            return snapshot.exists() ? snapshot.data() : null;
        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    async getCollection<T = DocumentData>(path: string, converter?: FirestoreDataConverter<T>, queryConstraints?: QueryConstraint[], filter?: QueryCompositeFilterConstraint): Promise<T[]> {
        try {
            let collReference: Query<T> = this.getCollectionReference<T>(path);

            if (!isNil(converter)) {
                collReference = collReference.withConverter(converter);
            }

            if (!isNil(filter)) {
                collReference = query(collReference, filter, ...queryConstraints as QueryNonFilterConstraint[] ?? []);
            }
            else if (!isNil(queryConstraints)) {
                collReference = query(collReference, ...queryConstraints ?? []);
            }

            const snapshot = await getDocs(collReference);
            return snapshot.empty ? [] : snapshot.docs.map(x => x.data());
        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    async set<T extends object>(path: string, data: T): Promise<T> {
        try {
            const docRef = this.getDocumentReference(path);
            await setDoc(docRef, data);
            return data;
        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    async update<T extends object>(path: string, data: UpdateData<T>): Promise<UpdateData<T>> {
        try {
            const docRef = this.getDocumentReference<T>(path);
            await updateDoc<T>(docRef, data);
            return data;
        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    async delete(path: string): Promise<boolean> {
        try {
            const docRef = this.getDocumentReference(path);
            await deleteDoc(docRef);
            return true;
        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    watch<T>(path: string, converter?: FirestoreDataConverter<T>): Observable<T | null> {
        return new Observable<T | null>(subs => {
            let docRef = this.getDocumentReference<T>(path);

            if (!isNil(converter)) {
                docRef = docRef.withConverter(converter);
            }

            return onSnapshot(docRef, (snapshot) => {
                try {
                    subs.next(snapshot.exists() ? snapshot.data() : null);
                }
                catch (error) {
                    throw this.handleError(error);
                }
            }, error => {
                subs.error(this.handleError(error));
            }, () =>
                subs.complete()
            );
        }).pipe(
            catchError(error => throwError(() => this.handleError(error))),
            takeWhile(() => !this._disposed),
            shareReplay(1)
        );
    }

    watchCollection<T = DocumentData>(path: string, converter?: FirestoreDataConverter<T>, queryConstraints?: QueryConstraint[], filter?: QueryCompositeFilterConstraint): Observable<T[]> {
        return new Observable<T[]>(subs => {
            let collReference: Query<T> = this.getCollectionReference<T>(path);

            if (!isNil(converter)) {
                collReference = collReference.withConverter(converter);
            }

            if (!isNil(filter)) {
                collReference = query(collReference, filter, ...queryConstraints as QueryNonFilterConstraint[] ?? []);
            }
            else if (!isNil(queryConstraints)) {
                collReference = query(collReference, ...queryConstraints ?? []);
            }

            return onSnapshot<T>(collReference, (snapshot) => {
                try {
                    subs.next(snapshot.empty ? [] : snapshot.docs.map(x => x.data()));
                }
                catch (error) {
                    throw this.handleError(error);
                }
            }, error => {
                subs.error(this.handleError(error));
            }, () =>
                subs.complete()
            );
        }).pipe(
            catchError(error => throwError(() => this.handleError(error))),
            takeWhile(() => !this._disposed),
            shareReplay(1)
        );
    }

    async runTransaction<T>(cb: (clTransaction: IClTransaction) => Promise<T>) {
        try {
            return await runTransaction(getFirestore(this.app), async (transaction) => {
                const clTransaction: IClTransaction = new ClTransaction(this.app, transaction);
                return await cb(clTransaction);
            });
        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    dispose() {
        this._disposed = true;
    }
}
