
import { CoreSelectors } from '@cyberloop/core';
import { ConnectionTimeWidgetData, ConnectionTimeWidgetSettings, connectionTimeDefaultSettings, getMomentsFromTimeRange } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, map, switchMap } from 'rxjs';

import * as moment from 'moment';

import { KpiSelectors } from '../../state/kpi/kpi.selectors';
import { KpiDataService } from '../kpi-data.service';

export function getConnectionTimeWidgetData(this: { store: Store, data: KpiDataService }, id: string): Observable<ConnectionTimeWidgetData> {
    return combineLatest([
        this.store.select(CoreSelectors.currentWell),
        this.store.select(KpiSelectors.widgetSettings<ConnectionTimeWidgetSettings>(id))
    ]).pipe(
        switchMap(([well, settings]) => {
            if (!well) {
                return EMPTY;
            }

            const timeRangeObservable = getMomentsFromTimeRange(
                this.store,
                settings?.timeRange ?? connectionTimeDefaultSettings.timeRange,
                well
            );

            return timeRangeObservable.pipe(
                switchMap(timeRange => this.data.watchRigConnections(
                    well.rig,
                    timeRange.since.clone().startOf('second').toDate(),
                    timeRange.until.clone().startOf('second').toDate()
                )),
                map(connections => connections.map(item => ({
                    startDate: moment(item.startTime).toDate(),
                    s2s: item.s2s,
                    s2w: item.s2w,
                    w2s: item.w2s
                }))),
                map(connections => ({ connections }))
            );
        })
    );
}
