import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { KpiCommonEffects } from './kpi-common.effects';
import { KpiDashboardsEffects } from './kpi-dashboards.effects';
import { KpiWidgetsEffects } from './kpi-widgets.effects';
import { kpiReducer } from './kpi.reducer';
import { KPI_FEATURE } from './kpi.state';

export const KpiProviders = [
    provideEffects([KpiCommonEffects, KpiDashboardsEffects, KpiWidgetsEffects]),
    provideState(KPI_FEATURE, kpiReducer)
];