import { Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Params, Router } from '@angular/router';

import { AccessService, CoreActions, CoreSelectors, RigInfoProviderService } from '@cyberloop/core';
import { AppActions, AppSelectors } from '@cyberloop/web/app/data';
import { PlanningActions } from '@cyberloop/web/planning/shared/data';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, filter, first, from, map, of, switchMap, take } from 'rxjs';

import { WellsService } from '../services';
import { ForecastActions } from './forecast';
import { WellsActions } from './wells.actions';
import { WELLS_FEATURE } from './wells.state';

const initAction = createAction(`[${WELLS_FEATURE}] Initialize wells`);

@Injectable()
export class WellsEffects implements OnInitEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly router: Router,
        private readonly store: Store,
        private readonly wellsService: WellsService,
        private readonly accessService: AccessService,
        @Optional() private rip: RigInfoProviderService
    ) { }

    readonly handleCurrentWell$ = createEffect(() => this.router.events.pipe(
        map(x => x as NavigationEnd),
        filter(x => x instanceof NavigationEnd),
        switchMap(() => this.store.select(CoreSelectors.currentWell)),
        map(currentWellId => {
            const snapshot = this.router.routerState.snapshot.root;
            const params = this.getRouteParams(snapshot);
            const newWellId = params['wellId'] ?? undefined;

            return { currentWellId, newWellId };
        }),
        filter(x => x.currentWellId !== x.newWellId),
        map(x => x.newWellId),
        distinctUntilChanged(),
        map(wellId => CoreActions.setCurrentWell({ wellId }))
    ));

    //#region Set planning
    readonly openSetPlanPopup$ = createEffect(() => this.actions$.pipe(
        ofType(WellsActions.openSetPlanPopup),
        switchMap(() => this.wellsService.openSetPlanPopup())
    ), { dispatch: false });

    readonly loadPlanningListOnOpenSetPlanPopup$ = createEffect(() => this.actions$.pipe(
        ofType(WellsActions.openSetPlanPopup),
        map(() => PlanningActions.loadList())
    ));

    readonly setPlan$ = createEffect(() => this.actions$.pipe(
        ofType(WellsActions.setPlan),
        switchMap(({ planningId }) => this.store.select(CoreSelectors.currentWellId).pipe(
            take(1),
            filter(Boolean),
            map((wellId) => PlanningActions.setWellid({ planningId, wellId }))
        ))
    ));

    readonly setWellidSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.setWellidSuccess),
        switchMap(() =>
            from(this.wellsService.confirmImportFromWellPlan()).pipe(
                filter(Boolean),
                map(() => ForecastActions.tryImportFromWellPlan())
            )
        )
    ));
    //#endregion Set planning

    readonly getAllRigs$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(() => this.rip.watchRigs()),
        switchMap((meta) =>
            combineLatest([
                of(meta),
                this.store.select(AppSelectors.wellsFilters).pipe(first())
            ])
        ),
        map(([rigs, filters]) => {
            const allRigs = [...[{ id: 'all rigs', name: 'all rigs', operator: '' }], ...rigs];

            this.store.dispatch(AppActions.setFilters({ filters: { ...filters, rig: 'all rigs' } }));
            return allRigs;
        }),
        switchMap(rigs => this.accessService.filterRigs(rigs)),
        map(rigs => WellsActions.setRigs({ rigs }))
    ));

    ngrxOnInitEffects(): Action {
        return initAction();
    }

    private getRouteParams(snapshot: ActivatedRouteSnapshot): Params {
        if (snapshot.firstChild) {
            const childSnapshot = snapshot.firstChild;
            return { ...snapshot.params, ...this.getRouteParams(childSnapshot) };
        }
        return snapshot.params;
    }
}
