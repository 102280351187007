import { Injectable } from '@angular/core';

import { Units, UnitsPreset, UnitsProviderService, UnitsSnapshot, deepFreeze } from '@cyberloop/core';
import { isEqual } from 'lodash';
import { EMPTY, Observable, catchError, distinctUntilChanged, filter, from, map, of, share, startWith, switchMap, takeWhile, tap, timer } from 'rxjs';

import { ClApplicationManager } from './internals/client-app/cl-app-manager';
import { ClApplication } from './internals/client-app/cl-application';
import { SsoRootFunctions } from './internals/sso-root/sso-root-functions';

const UNITS_LOCAL_STORAGE_KEY = 'cl-units';

type UnitsGroupResponse = {
    id: number,
    name: string,
    defaultUnitId: UnitResponse['id'],
    units: UnitResponse['id'][]
}

type UnitResponse = {
    id: string,
    name: string,
    label: string,
    offset: number,
    ratio: number
}

type UnitsPresetResponse = {
    id: string,
    name: string,
    mappings: Record<UnitsGroupResponse['id'], UnitResponse['id']>
}

type UnitsSnapshotResponse = {
    success: true;
    payload: {
        groups: UnitsGroupResponse[],
        units: UnitResponse[],
        presets: UnitsPresetResponse[]
    }
} | {
    success: false;
    error: any
}

@Injectable({ providedIn: 'root' })
export class UnitsProviderServiceImpl extends UnitsProviderService {
    /**
     * @internal
     */
    constructor(
        private readonly func: SsoRootFunctions,
        private readonly appManager: ClApplicationManager
    ) {
        super();
    }

    onUserUnitsPresetSnapshot(): Observable<UnitsPreset> {
        // TODO: Refactor it to use `SettingsProviderService`
        return this.appManager.tenantApp$.pipe(
            takeWhile(Boolean),
            switchMap(app => app.getAuth().currentUser$.pipe(
                map(x => x?.uid),
                filter(Boolean),
                switchMap(uid => this.watchUserUnits(app, uid))
            )),
            share()
        );
    }

    updateUserUnitsPreset(value: UnitsPreset): Observable<void> {
        return this.appManager.tenantApp$.pipe(
            takeWhile(Boolean),
            switchMap(app => app.getAuth().currentUser$.pipe(
                map(x => x?.uid),
                filter(Boolean),
                switchMap(uid => this.updateUserUnits(app, uid, value))
            ))
        );
    }


    onUnitsSnapshot(): Observable<Readonly<UnitsSnapshot>> {
        return timer(0, 5 * 60 * 1000 /* emit every 5 minutes */).pipe(
            switchMap(() => this.appManager.tenantApp$), // Read units only is there is an user
            filter(Boolean),
            switchMap(() => from(this.func.call<Record<string, never>, UnitsSnapshotResponse>('getUnits', {})).pipe(
                map(x => {
                    if (x.success) {
                        return x.payload;
                    }

                    throw new Error(x.error);
                }),
                catchError(err => {
                    console.warn(err);
                    return EMPTY;
                })
            )),
            filter(Boolean)
        ).pipe(
            map(x => {
                const snapshot: UnitsSnapshot = {
                    groups: {},
                    presets: {},
                    units: {}
                };

                for (const unit of x.units) {
                    snapshot.units[unit.id] = unit;
                }

                for (const group of x.groups) {
                    snapshot.groups[group.id] = {
                        id: group.id,
                        name: group.name,
                        defaultUnit: snapshot.units[group.defaultUnitId],
                        units: group.units.reduce((prev, curr) => {
                            prev[curr] = snapshot.units[curr];
                            return prev;
                        }, {} as Units)
                    };
                }

                for (const preset of x.presets) {
                    snapshot.presets[preset.id] = {
                        id: preset.id,
                        name: preset.name,
                        mappings: { ...preset.mappings }
                    };
                }

                return snapshot;
            }),
            distinctUntilChanged((a, b) => isEqual(a, b)),
            tap(snapshot => this.setItemsFromTheStorage(snapshot)),
            startWith(this.getItemsFromTheStorage() ?? {}),
            map(x => deepFreeze(x)),
            share<UnitsSnapshot>()
        );
    }

    private watchUserUnits(app: ClApplication, uid: string): Observable<UnitsPreset> {
        return app.getFirestore().watch<Record<number, string>>(`settings/users/${uid}/(units)`).pipe(
            map(x => x ?? {} as UnitsPreset),
            map(x => Object.keys(x).reduce((prev, curr) => {
                const key = Number(curr);
                if (Number.isFinite(key)) {
                    prev.mappings[key] = x[curr as any];
                }
                return prev;
            }, {
                id: `uid:${uid}`,
                name: 'User\'s units preset',
                mappings: {}
            } as UnitsPreset))
        );
    }

    private updateUserUnits(app: ClApplication, uid: string, value: UnitsPreset): Observable<void> {
        return of(app.getFirestore().set<Record<number, string>>(`settings/users/${uid}/(units)`, value.mappings)).pipe(
            map(() => undefined)
        );
    }

    private setItemsFromTheStorage(snapshot: UnitsSnapshot): void {
        const values = JSON.stringify(snapshot);
        localStorage.setItem(UNITS_LOCAL_STORAGE_KEY, values);
    }

    private getItemsFromTheStorage(): UnitsSnapshot {
        try {
            const value = localStorage.getItem(UNITS_LOCAL_STORAGE_KEY);
            if (value) {
                return JSON.parse(value);
            }
        }
        catch (err) {
            console.warn(err);
        }

        return null as any;

    }
}
