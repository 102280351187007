import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';

import { ChartEx, ClChartComponent, ClWellChartLegendComponent, ClWellChartSeries, CompactNumberFormatter, Points, SectionInput, SimpleChangesOf, UnitDescriptor, UnitsConverterService } from '@cyberloop/core';
import { BehaviorSubject, Subscription, shareReplay } from 'rxjs';

import * as moment from 'moment';

/** @internal Series ID for the BDE */
const BDE_SERIES_ID = 'bde';
/** @internal Series ID for the WDE */
const WDE_SERIES_ID = 'wde';
/** @internal Series ID for the Plan curve */
const PLAN_SERIES_ID = 'plan';
/** @internal Series ID for the Plan technical limit curve */
const PLAN_LIMIT_SERIES_ID = 'plan-limit';

/**
 * A component for displaying WDE & BDE lines and sections during the whole
 * well. Used by Days vs Depth KPI widget and by the `cl-well-range-picker`
 * component.
 */
@Component({
    selector: 'cyberloop-well-chart',
    standalone: true,
    imports: [
        ClChartComponent
    ],
    templateUrl: './well-chart.component.html',
    styleUrls: ['./well-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClWellChartComponent implements AfterViewInit, OnChanges, OnDestroy {

    private readonly _plotAreaX$ = new BehaviorSubject<number>(0);
    private readonly _plotAreaY$ = new BehaviorSubject<number>(0);
    private readonly _plotAreaWidth$ = new BehaviorSubject<number>(0);
    private readonly _plotAreaHeight$ = new BehaviorSubject<number>(0);

    private _sectionContainer?: Highcharts.SVGElement;
    private _lastThreshold?: number;

    private _legendSeriesOverSub?: Subscription;
    private _legendSeriesOutSub?: Subscription;
    private _legendSeriesClickSub?: Subscription;

    constructor(
        private readonly unitsConverter: UnitsConverterService
    ) {}

    /** @internal Chart options */
    readonly options = this.getChartOptions();

    /** @internal Chart plot area X */
    readonly plotAreaX$ = this._plotAreaX$.pipe(shareReplay(1));
    /** @internal Chart plot area Y */
    readonly plotAreaY$ = this._plotAreaY$.pipe(shareReplay(1));
    /** @internal Chart plot area width */
    readonly plotAreaWidth$ = this._plotAreaWidth$.pipe(shareReplay(1));
    /** @internal Chart plot area height */
    readonly plotAreaHeight$ = this._plotAreaHeight$.pipe(shareReplay(1));

    /** An array of WDE points */
    @Input() wde: Points | null = [];
    /** An array of BDE points */
    @Input() bde: Points | null = [];
    /** An array of Plan curve points */
    @Input() plan: Points | null = [];
    /** An array of Plan technical limit curve points */
    @Input() planLimit: Points | null = [];
    /** An array of sections */
    @Input() sections: SectionInput[] | null = [];
    /** The depth unit to display numbers in */
    @Input() unit: UnitDescriptor | null = null;

    /** A legend of the chart */
    @Input() legend: ClWellChartLegendComponent | null = null;

    /** @internal Chart component */
    @ViewChild(ClChartComponent, { static: true }) chart?: ClChartComponent;

    ngAfterViewInit(): void {
        // Init chart

        if (!this.chart) {
            throw new Error('<cyberloop-chart> component should be present');
        }

        // Draw chart if any data is already exists

        if (this.bde?.length) {
            this.chart.setSeriesData(BDE_SERIES_ID, this.bde);
        }

        if (this.wde?.length) {
            this.chart.setSeriesData(WDE_SERIES_ID, this.wde);
        }

        if (this.plan?.length) {
            this.chart.setSeriesData(PLAN_SERIES_ID, this.plan);
        }

        if (this.planLimit?.length) {
            this.chart.setSeriesData(PLAN_LIMIT_SERIES_ID, this.planLimit);
        }

        if (this.sections?.length) {
            // We'll draw them on chart render event
            this.chart.redraw();
        }
    }

    ngOnChanges(changes: SimpleChangesOf<ClWellChartComponent>): void {
        const chart = this.chart;

        if (!chart) {
            return;
        }

        const bde = changes.bde;
        const wde = changes.wde;
        const plan = changes.plan;
        const planLimit = changes.planLimit;
        const sections = changes.sections;
        const unit = changes.unit;

        if (bde || unit) {
            const bdeConverted = this.getConvertedPoints(bde?.currentValue ?? []);
            chart.setSeriesData(BDE_SERIES_ID, bdeConverted);
        }

        if (wde || unit) {
            const wdeConverted = this.getConvertedPoints(wde?.currentValue ?? []);
            chart.setSeriesData(WDE_SERIES_ID, wdeConverted);
        }

        if (plan || unit) {
            const planConverted = this.getConvertedPoints(plan?.currentValue ?? []);
            chart.setSeriesData(PLAN_SERIES_ID, planConverted);
        }

        if (planLimit || unit) {
            const planLimitConverted = this.getConvertedPoints(planLimit?.currentValue ?? []);
            chart.setSeriesData(PLAN_LIMIT_SERIES_ID, planLimitConverted);
        }

        if (sections) {
            // We'll draw them on chart render event
            chart.redraw();
        }

        if (changes.legend) {
            this._legendSeriesOverSub?.unsubscribe();
            this._legendSeriesOutSub?.unsubscribe();
            this._legendSeriesClickSub?.unsubscribe();

            const legend = changes.legend.currentValue;

            if (legend) {
                this._legendSeriesOverSub = legend.seriesOver.subscribe(this.onLegendHover.bind(this, true));
                this._legendSeriesOutSub = legend.seriesOut.subscribe(this.onLegendHover.bind(this, false));
                this._legendSeriesClickSub = legend.seriesVisibility.subscribe(this.onLegendVisibility.bind(this));
            }
        }
    }

    ngOnDestroy(): void {
        this._legendSeriesOverSub?.unsubscribe();
        this._legendSeriesOutSub?.unsubscribe();
        this._legendSeriesClickSub?.unsubscribe();
    }

    private onLegendHover(hover: boolean, series: ClWellChartSeries): void {
        if (!this.chart) {
            return;
        }

        if (!hover) {
            this.setAllSeriesState('normal');
            this.chart.series.forEach(s => s.onMouseOut());
            return;
        }

        const seriesObject = this.findSeries(series);

        if (!seriesObject) {
            console.warn(`[WELL CHART]: Series ${series} not found`);
            return;
        }

        this.setAllSeriesState('inactive');
        seriesObject.setState('hover');
        seriesObject.onMouseOver();
    }

    private onLegendVisibility({ series, visible }: { series: ClWellChartSeries, visible: boolean }): void {
        if (!this.chart) {
            return;
        }

        const seriesObject = this.findSeries(series);

        if (!seriesObject) {
            console.warn(`[WELL CHART]: Series ${series} not found`);
            return;
        }

        if (visible) {
            seriesObject.show();
        }
        else {
            seriesObject.hide();
        }
    }

    private setAllSeriesState(state?: "" | Highcharts.SeriesStateValue | undefined): void {
        this.chart?.series.forEach(s => s.setState(state));
    }

    private findSeries(series: ClWellChartSeries): Highcharts.Series | undefined {
        let seriesId: string;

        switch (series) {
            case ClWellChartSeries.BDE:
                seriesId = BDE_SERIES_ID;
                break;
            case ClWellChartSeries.Plan:
                seriesId = PLAN_SERIES_ID;
                break;
            case ClWellChartSeries.PlanLimit:
                seriesId = PLAN_LIMIT_SERIES_ID;
                break;
            default:
                seriesId = WDE_SERIES_ID;
                break;
        }

        return this.chart?.series.find(s => s.userOptions.id === seriesId);
    }

    private getChartOptions(): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        // const updateThreshold = this.getUpdateThreshold(this);

        return {
            // Area gradient
            defs: {
                chartGradientWde: {
                    tagName: 'linearGradient',
                    attributes: {
                        id: 'chart-gradient-wde',
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    children: [
                        {
                            tagName: 'stop',
                            attributes: {
                                offset: 0
                            }
                        },
                        {
                            tagName: 'stop',
                            attributes: {
                                offset: 1
                            }
                        }
                    ]
                },
                chartGradientBde: {
                    tagName: 'linearGradient',
                    attributes: {
                        id: 'chart-gradient-bde',
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    children: [
                        {
                            tagName: 'stop',
                            attributes: {
                                offset: 0
                            }
                        },
                        {
                            tagName: 'stop',
                            attributes: {
                                offset: 1
                            }
                        }
                    ]
                },
                sectionBarGradient: {
                    tagName: 'linearGradient',
                    attributes: {
                        id: 'section-bar-gradient',
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    children: [
                        {
                            tagName: 'stop',
                            attributes: {
                                offset: 0
                            }
                        },
                        {
                            tagName: 'stop',
                            attributes: {
                                offset: 1
                            }
                        }
                    ]
                }
            },

            chart: {
                type: 'area',
                marginLeft: 62,
                marginRight: 0,
                marginBottom: 59,
                styledMode: true,
                animation: false,

                events: {
                    // load: updateThreshold,
                    // redraw: updateThreshold,
                    // Here we will draw our section lines
                    render: function () {
                        self.onChartRender(this as ChartEx);
                    }
                }
            },
            title: {
                text: undefined
            },
            credits: {
                enabled: false
            },
            tooltip: {
                enabled: false
            },
            xAxis: {
                offset: 4,
                type: 'datetime',
                ordinal: false,
                title: {
                    text: 'Days',
                    margin: -1
                },
                labels: {
                    formatter: function () {
                        const time = moment(this.value);

                        return (
                            `<span class="main">${time.format('D.MM')}</span>` +
                            '<br>' +
                            `<span class="sub">${time.format('H:mm')}</span>`
                        );
                    }
                },
                tickLength: 0,
                tickWidth: 0,
                minPadding: 0,
                maxPadding: 0
            },
            yAxis: {
                reversed: true,
                startOnTick: false,
                endOnTick: false,
                min: 0,
                offset: -4,
                title: {
                    text: 'Depth',
                    margin: 12
                },
                labels: {
                    enabled: true,
                    formatter: function () {
                        const ticks: number[] = (this.axis as any).paddedTicks;
                        let className = '';

                        if (this.value === ticks[0]) {
                            // First tick tick
                            className = 'first';
                        }
                        else if (this.value === ticks[ticks.length - 1]) {
                            className = 'last';
                        }

                        const value = CompactNumberFormatter.format(parseFloat(String(this.value)));

                        return `<span class="${className}">${value}</span>`;
                    }
                }
            },
            plotOptions: {
                series: {
                    animation: false,
                    clip: true,
                    marker: {
                        enabled: false
                    },
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            series: [
                {
                    id: WDE_SERIES_ID,
                    name: 'WDE',
                    type: 'area',
                    colorIndex: 1,
                    data: []
                },
                {
                    id: BDE_SERIES_ID,
                    name: 'BDE',
                    type: 'area',
                    colorIndex: 2,
                    data: []
                },
                {
                    id: PLAN_SERIES_ID,
                    name: 'Plan',
                    type: 'line',
                    colorIndex: 3,
                    data: []
                },
                {
                    id: PLAN_LIMIT_SERIES_ID,
                    name: 'Plan tech limit',
                    type: 'line',
                    colorIndex: 4,
                    data: []
                }
            ]
        };
    }

    private getConvertedValue(source: number): number {
        return this.unit
            ? this.unitsConverter.convertToUnit(source, this.unit)
            : source;
    }

    /** @deprecated Not using anymore but who knows */
    private getUpdateThreshold(self: ClWellChartComponent) {
        return function (this: Highcharts.Chart) {
            // Set a threshold to keep area fill at the bottom

            // Find min and max data values

            const maxes: number[] = [];

            for (const axis of this.yAxis) {
                const max = axis.getExtremes().max;

                if (typeof max !== 'undefined') {
                    maxes.push(max);
                }
            }

            if (maxes.length <= 0) {
                // No maxes - no calculations
                return;
            }

            const mins: number[] = [];

            for (const series of this.series) {
                mins.push(series.dataMin ?? 0);
            }

            const dataSpan = Math.max(...maxes) - Math.min(...mins);

            this.update({
                plotOptions: {
                    area: {
                        threshold: dataSpan
                    }
                }
            }, false);

            if (typeof self._lastThreshold === 'undefined') {
                self._lastThreshold = dataSpan;
                setTimeout(() => this.redraw());
            }
        };
    }

    private getConvertedPoints(source: Points): Points {
        const converted: Points = [];

        for (const item of source) {
            converted.push({
                ...item,
                y: item.y ? this.getConvertedValue(item.y) : item.y
            });
        }

        return converted;
    }

    private onChartRender(chart: ChartEx) {
        const ren = chart.renderer;

        // Destroy the existing container
        this._sectionContainer?.destroy();
        // And create a new one
        this._sectionContainer = ren.g('sections-container')
            .attr({
                zIndex: 3
            })
            .add();

        const extremes = chart.xAxis[0].getExtremes();

        const totalData = extremes.dataMax - extremes.dataMin;

        if (totalData > 0) {
            // Now render all the sections

            const sectionsList = this.sections ?? [];

            for (let i = 0; i < sectionsList.length; i++) {
                const item = sectionsList[i];

                // Calculate position for each section

                const width = 26;
                const textHeight = 15;

                const relativeX = (item.value - extremes.dataMin) / totalData;
                // Restrict it to the left edge of the plot area
                const x = Math.min(
                    Math.max(relativeX * chart.plotWidth + chart.plotLeft, width + chart.plotLeft),
                    chart.plotLeft + chart.plotWidth
                );

                // Background bar
                ren
                    .rect(x - width, chart.plotTop, width, chart.plotHeight)
                    .addClass('section-bar')
                    .add(this._sectionContainer);

                // Line
                ren
                    .path([
                        ['M', x, chart.plotTop],
                        ['V', chart.plotTop + chart.plotHeight]
                    ])
                    .addClass('section-line')
                    .add(this._sectionContainer);

                // And text label
                const labelX = x - width * .5 + textHeight * .25 + 1;

                ren
                    .text(item.name, labelX, chart.plotTop + chart.plotHeight - 10)
                    .addClass('section-label')
                    .attr({
                        rotation: -90
                    })
                    .add(this._sectionContainer);
            }
        }

        // A hack to set the plot area radius
        (chart as any).plotBackground.attr({ rx: 6, ry: 6 });

        // Update the plot area size
        this._plotAreaX$.next(chart.plotLeft);
        this._plotAreaY$.next(chart.plotTop);
        this._plotAreaWidth$.next(chart.plotWidth);
        this._plotAreaHeight$.next(chart.plotHeight);
    }

}
