import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { WellsEffects } from './wells.effects';
import { wellsReducer } from './wells.reducer';
import { WELLS_FEATURE } from './wells.state';

export const WellsProviders = [
    provideEffects([WellsEffects]),
    provideState(WELLS_FEATURE, wellsReducer)
];