/** Export primary columns ids */
export enum PrimaryColumnId {
    /** depth column */
    Depth = 'depth',
    /** date column */
    Date = 'date'
}

/** Export column object */
export interface ExportDataColumn {
    /** column id - tag id */
    id: string;
    /** second id - name to indicate if have several equal tags for export */
    secondId: string;
    /** column name */
    name: string;
    /** column value unit name */
    unit: string;
    // unitGroupId?: number;
    /** unit label */
    label?: string;
}

/** Primary columns object */
export const primaryColumData: Record<string, ExportDataColumn> = {
    /** Time column */
    [PrimaryColumnId.Date]: {
        /** id */
        id: PrimaryColumnId.Date,
        /** second id */
        secondId: PrimaryColumnId.Date,
        /** name */
        name: 'Date',
        /** unit */
        unit: 'timedate',
        // unitGroupId: 0,
        /** unit label */
        label: 'timedate'
    },
    /** Date column */
    [PrimaryColumnId.Depth]: {
        /** id */
        id: PrimaryColumnId.Depth,
        /** second id */
        secondId: PrimaryColumnId.Depth,
        /** name */
        name: 'Depth',
        /** unit */
        unit: '',
        // unitGroupId: 0,
        /** label */
        label: ''
    }
};