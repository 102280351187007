import { Injectable } from '@angular/core';

import { RigState } from '@cyberloop/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiRigStateDuration } from '@cyberloop/web/wells/model';
type KpiRigStateDurationsInput = {
    rig: string,
    since: string,
    until: string,
    only?: RigState[]
}

type KpiRigWellTagTimeHistory = {
    rig: {
        rigStateDurations: KpiRigStateDuration[]
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiRigStateDurationsQuery extends GraphQLQuery<KpiRigWellTagTimeHistory, KpiRigStateDurationsInput> {
    override readonly document = `
        query GetRigStateDuration($rig: String!, $since: DateTime!, $until: DateTime!, $only: [Int!]) {
            rig(name: $rig) {
                ... on Rig {
                    rigStateDurations(since: $since, until: $until, only: $only) {
                        state
                        duration
                    }
                }
            }
        }
    `;
}