import { createReducer, on } from '@ngrx/store';

import { TndActions } from './tnd.actions';
import { getDefaultPlanningEngineeringTndState } from './tnd.state';

import type { PlanningEngineeringTndState } from './tnd.state';

export const planningEngineeringTndReducer = createReducer<PlanningEngineeringTndState>(getDefaultPlanningEngineeringTndState(),

    on(TndActions.setWeightsChartSettings, (state, { settings }): PlanningEngineeringTndState => {
        return ({
            ...state,
            weightsSettings: settings
        });
    }),
    on(TndActions.setTorqueChartSettings, (state, { settings }): PlanningEngineeringTndState => {
        return ({
            ...state,
            torqueSettings: settings
        });
    }),



    on(TndActions.selectModelForWeightsChart, (state, { modelId }): PlanningEngineeringTndState => {
        return ({
            ...state,
            weightsSettings: {
                ...state.weightsSettings,
                selectedModelId: modelId
            }
        });
    }),

    on(TndActions.selectModelForTorqueChart, (state, { modelId }): PlanningEngineeringTndState => {
        return ({
            ...state,
            torqueSettings: {
                ...state.torqueSettings,
                selectedModelId: modelId
            }
        });
    }),



    on(TndActions.setError, (state, { error }): PlanningEngineeringTndState => ({
        ...state,
        error
    }))

);
