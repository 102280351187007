import { ID } from '@cyberloop/core';

import { DayPilot } from 'daypilot-pro-angular';
import { Moment } from 'moment';

import { Timestamps } from '../timestamp';

export interface ForecastEvent extends Timestamps {
    id: ID;
    forecastId: ID;
    startDate: Moment;
    endDate: Moment;
    description: string;
    color: ForecastEventColor;
    isNpt: boolean;
}

export type CreateForecastEvent = Pick<ForecastEvent, 'startDate' | 'endDate' | 'isNpt'>;
export type UpdateForecastEvent = Partial<Pick<ForecastEvent, 'startDate' | 'endDate' | 'description' | 'color' | 'isNpt'>>;

export type DaypilotForecastEvent = DayPilot.EventData & {
    id: ID;
    color: ForecastEventColor;
    isNpt: boolean;
};

export const forecastEventColors = [
    'color-1',
    'color-2',
    'color-3',
    'color-4',
    'color-5'
] as const;
export type ForecastEventColor = typeof forecastEventColors[number];
