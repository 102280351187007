import { Injectable } from '@angular/core';

import { Point } from '@cyberloop/core';
import { Observable, buffer, bufferCount, defaultIfEmpty, firstValueFrom, skip, take } from 'rxjs';

import { DrillingIndexerDb } from './drilling-indexedDb.service';

@Injectable({
    providedIn: 'root'
})
export class IndexedDbProviderService {
    constructor(private readonly dbWrapper: DrillingIndexerDb) { }

    public async get(wellId: string, tagId: string, key: number): Promise<Point[]> {
        return await this.dbWrapper.getTagData(wellId, tagId, key);
    }

    public async set(wellId: string, tagId: string, key: number, data: Observable<Point[]>): Promise<void> {
        debugger;
        const points = await firstValueFrom(data.pipe(
            defaultIfEmpty(undefined),
            skip(1)
        ));
        if (points) {
            await this.dbWrapper.setTagData(wellId, tagId, key, points);
        }
    }
}
