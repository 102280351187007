import { TemplateRepositryService } from '@cyberloop/core';
import { Observable } from 'rxjs';

import { WorkspacesTemplate } from './workspaces-template.service';

export abstract class DrillingWorkspacesTemplatesProvideService implements TemplateRepositryService<WorkspacesTemplate> {
    abstract watchAll(): Observable<WorkspacesTemplate[]>;

    abstract get(id: string): Observable<WorkspacesTemplate | null>;

    abstract create(template: Omit<WorkspacesTemplate, 'id' | 'author' | 'createdAt' | 'updatedAt'>): Observable<WorkspacesTemplate>;

    abstract delete(templateId: string): Observable<WorkspacesTemplate>;
}
