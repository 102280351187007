import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiWellTagTimeHistoryItem } from '@cyberloop/web/wells/model';

type KpiRigWellTagTimeHistoryInput = {
    wellId: string,
    tags: string[],
    since: string,
    until: string,
    step: number
}

type KpiRigWellTagTimeHistory = {
    well: {
        timeHistory: KpiWellTagTimeHistoryItem[]
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiWellTagTimeHistoryQuery extends GraphQLQuery<KpiRigWellTagTimeHistory, KpiRigWellTagTimeHistoryInput> {
    override readonly document = `
        query GetWellTagTimeHistory($wellId: ID!, $tags: [TagName!], $since: DateTime!, $until: DateTime!, $step: Int!) {
            well (id: $wellId) {
                ...on Well {
                    timeHistory (only: $tags, since: $since, until: $until, step: $step) {
                        tagId
                        tagName
                        intervals {
                            startTime
                            minTime
                            maxTime
                            startValue
                            avgValue
                            minValue
                            maxValue
                        }
                    }
                }
            }
        }
    `;
}