import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DatetimePipe, ID, IconComponent, NgForEmptyDirective, NgVarDirective, RigActivity } from '@cyberloop/core';
import { FileMeta, FileTag } from '@cyberloop/web/files/model';
import { isNil } from 'lodash';

@Component({
    selector: 'cyberloop-table',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgForEmptyDirective,
        NgClass,
        NgVarDirective,
        DatetimePipe,
        IconComponent
    ],
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent {
    @Input() files?: FileMeta[] | null;
    @Input() tags?: FileTag[] | null;
    @Input() disableActions?: boolean | null;
    @Input() readonly?: boolean | null;

    @Output() readonly download = new EventEmitter<ID>();
    @Output() readonly delete = new EventEmitter<ID>();

    readonly RigActivity = RigActivity;
    readonly isNil = isNil;

    getTagName(id: ID) {
        return this.tags?.find(tag => tag.id === id)?.name;
    }
}
