import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SettingsProviderService } from '@cyberloop/core';
import { RecentItem, RecentItemMeta, RecentItemWithWellAndSection, RecentItemsSettings, defaultRecentItemsSettings } from '@cyberloop/web/app/model';
import { Observable, first, firstValueFrom, map, switchMap } from 'rxjs';

const RECENT_ITEMS_KEY = 'recent-items';

@Injectable({
    // This should be the Singleton
    providedIn: 'root'
})
export class RecentItemsService {

    constructor(
        private readonly _settingsService: SettingsProviderService,
        private readonly _router: Router
    ) { }

    /**
     * Provides recent items (or places) that the user has visited/used
     */
    watchRecentItems(): Observable<RecentItemWithWellAndSection[]> {
        return this._settingsService.watchSettings<RecentItemsSettings>(RECENT_ITEMS_KEY, defaultRecentItemsSettings).pipe(
            map((settings) => settings.items.map((item) => ({...item, time: item.time.toDate()})))
        );
    }

    /**
     * Add new item to start of recent visited place collection
     */
    pushItem<T = any>(data: RecentItemMeta<T>) {
        const item: RecentItem<T> = {
            ...data,         
            link: this._router.url, 
            time: new Date()
        };

        firstValueFrom(this._settingsService.watchSettings<RecentItemsSettings>(RECENT_ITEMS_KEY, defaultRecentItemsSettings).pipe(
            first(),
            switchMap((settings) => {
                const items = settings.items.slice(0, 9).filter((path) => path.link !== item.link).map(recent => ({...recent, time: recent.time.toDate()}));
                items.unshift(item);

                return this._settingsService.updateSettings(RECENT_ITEMS_KEY, {items});
            })
        ));
    }

}
