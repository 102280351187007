import { AsyncPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';

import { ClChartComponent, HighchartsOptions, Point } from '@cyberloop/core';
import { isNil, round } from 'lodash';
import { ReplaySubject, map } from 'rxjs';

@Component({
    selector: 'cyberloop-planning-chart',
    standalone: true,
    imports: [
        AsyncPipe,
        ClChartComponent
    ],
    templateUrl: './planning-chart.component.html',
    styleUrls: ['./planning-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningChartComponent implements AfterViewInit {
    private readonly _data$ = new ReplaySubject<Point[][]>(1);
    private readonly _xAxisTitle = 'Days';
    private readonly _yAxisTitle = 'Depth';

    constructor() { }

    @ViewChild(ClChartComponent, { static: true }) chart!: ClChartComponent;

    @Input() depthLabel?: string | null;
    @Input() set data(values: Point[][] | null) {
        this._data$.next(values ?? []);
    }

    readonly options = this.getOptions();

    ngAfterViewInit(): void {
        if (!this.chart) {
            throw new Error('<cyberloop-chart> component should be present');
        }

        this.chart.addSeries(
            { type: 'area' },
            this._data$.pipe(map(([techLimitSeriesData]) => techLimitSeriesData ?? []))
        );

        this.chart.addSeries(
            { type: 'area' },
            this._data$.pipe(map(([, estmationSeriesData]) => estmationSeriesData ?? []))
        );
    }

    private getOptions(): HighchartsOptions {
        const self = this;

        return {
            chart: {
                marginTop: 0,
                marginBottom: 30,
                marginLeft: 70,
                marginRight: 0,
                spacingTop: 50
            },
            xAxis: {
                min: 0,
                tickInterval: 1,
                minPadding: 0,
                maxPadding: 0,
                showEmpty: false,
                showFirstLabel: false,
                title: {
                    text: this._xAxisTitle,
                    align: 'low',
                    x: -40,
                    y: -25
                }
            },
            yAxis: {
                reversed: true,
                minPadding: 0.001,
                maxPadding: 0.001,
                showEmpty: false,
                showFirstLabel: false,
                showLastLabel: false,
                title: {
                    text: this._yAxisTitle,
                    align: 'low'
                }
            },
            plotOptions: {
                series: {
                    softThreshold: false,
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormatter() {
                            const days = Math.floor(this.x);
                            const hours = Math.floor(this.x * 24 % 24);
                            const hoursString = hours > 0 ? `${hours} h.` : '';
                            const depth = isNil(this.y) ? '' : round(this.y, 2);
                            const depthLabel = isNil(self.depthLabel) ? '' : `${self.depthLabel}.`;
                            return `${days} d. ${hoursString} <br> ${depth} ${depthLabel}`;
                        }
                    }
                },
                area: {
                    threshold: -Infinity
                }
            },
            defs: {
                gradient0: {
                    tagName: 'linearGradient',
                    attributes: {
                        id: 'gradient-0',
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    children: [{
                        tagName: 'stop',
                        attributes: {
                            offset: 0
                        }
                    }, {
                        tagName: 'stop',
                        attributes: {
                            offset: 1
                        }
                    }]
                }
            },
            disableUpdateExtremes: true
        };
    }
}
