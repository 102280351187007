import { EMPTY, Observable, filter, map, share, tap } from 'rxjs';

import { LiveData } from '../../models/live-data';

/** This service provides access to live data */
export abstract class LiveDataProviderService {
    /**
     * Get live data stream for specific rig
     * @param rigName Identifier of rig to get data for
     */
    abstract getForRig(rigName: string): Observable<LiveData[]>;

    /**
     * Get live data stream for specific rig and tag id
     * @param rigName Identifier of rig to get data for
     * @param tagName Id of tag we are interested in
     * @returns Observable of LiveData items
     */
    getForRigAndTag(rigName: string, tagName: string): Observable<LiveData>;
    /**
     * Get live data stream for specific rig
     * @param rigName Identifier of rig to get data for
     * @param tagNames List of tags we are interested in
     * @returns Observable of Arrays of LiveData items. Each array will contain at least one item
     */
    getForRigAndTag(rigName: string, ...tagNames: string[]): Observable<LiveData[]>;
    /** @internal */
    getForRigAndTag(rigName: string, ...tagNames: string[]): Observable<LiveData[] | LiveData> {
        if (!tagNames.length) {
            return EMPTY;
        }

        tagNames = tagNames.map(x => x.toUpperCase());

        const result = this.getForRig(rigName).pipe(
            map(entries => entries.filter(entry => tagNames.includes(entry.tagName))),
            filter(filteredEntries => filteredEntries.length > 0),
            share()
        );

        if (tagNames.length === 1) {
            return result.pipe(
                map(x => x[0])
            );
        }

        return result;
    }
}