<cyberloop-kpi-widget-settings class="settings" (close)="onCancel()">

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <!-- Units -->
        <fieldset>
            <legend>Select units</legend>
            <cl-button-toggle
                formControlName="units"
                selectionType="radio"
                [list]="(units$ | async) ?? []"
                class="units-selector"
            ></cl-button-toggle>
        </fieldset>

        <!-- Plan -->

        <h4>Show plan</h4>
        <fieldset class="plan">
            <cl-checkbox [(checked)]="showPlan" [disabled]="!(havePlan$ | async)" class="plan-input">
                <ng-container *ngIf="havePlan$ | async">Show plan: <strong>{{planName$ | async}}</strong></ng-container>
                <ng-container *ngIf="!(havePlan$ | async)">No plan for this well</ng-container>
            </cl-checkbox>
            <cl-checkbox *ngIf="havePlan$ | async" [(checked)]="showPlanLimit" class="plan-input">
                Show plan technical limit
            </cl-checkbox>
        </fieldset>

        <section class="actions">
            <button type="button" class="cl-flat-button" (click)="onCancel()">Cancel</button>
            <button type="submit" class="cl-primary-button">Apply</button>
        </section>

    </form>

</cyberloop-kpi-widget-settings>