import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FormatNumberPipe, NoDataPipe } from '@cyberloop/core';

@Component({
    selector: 'cyberloop-kpi-card',
    standalone: true,
    imports: [
        FormatNumberPipe,
        NoDataPipe
    ],
    templateUrl: './kpi-card.component.html',
    styleUrls: ['./kpi-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiCardComponent {

  @Input() title = '';
  
  @Input() value12: number | null = null;
  @Input() value24: number | null = null;

  @Input() unitLabel: string | null = null;
}
