<cyberloop-kpi-widget heading="Bit On / Off Bottom" [size]="size" class="widget" (settingsRequested)="onSettings()" (deleteRequested)="onDelete()">
    <div widget-body class="chart-container" [class.loading]="dataLoading$ | async">
        <cyberloop-chart
            [options]="options"
            class="chart"
        ></cyberloop-chart>
        <svg viewBox="0 0 100 100" *ngIf="dataLoading$ | async" class="placeholder">
            <circle cx="50" cy="50" r="37" stroke-width="26" fill="none" />
        </svg>
    </div>
</cyberloop-kpi-widget>
