import { Injectable } from '@angular/core';

import { CoreSelectors, SettingsProviderService } from '@cyberloop/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { isNil } from 'lodash';
import { combineLatest, first, switchMap, tap } from 'rxjs';

import { DDActions, DDSelectors } from '../../..';
import { DD_FEATURE } from './dd.state';

const SETTINGS_PATH = 'dd-settings';
const SELECTED_SECTIONS_KEY = 'charts-selected-sections';
const SELECTED_WELL_PLAN_KEY = 'charts-selected-well-plan';

const initAction = createAction(`[${DD_FEATURE}] Initialize directional drilling`);

@Injectable()
export class DirectionalDrillingEffects implements OnInitEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly settings: SettingsProviderService,
        private readonly store: Store
    ) {}

    readonly onInitAction$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(() => {
            return combineLatest([
                this.settings.watchSettings<any>(SETTINGS_PATH, {}),
                this.store.select(CoreSelectors.currentWellId),
                this.store.select(CoreSelectors.sectionsOfCurrentWell)
            ]);
        }),
        switchMap(([settings, wellID, sectionsOfCurrentWell]) => {
            return this.store.select(DDSelectors.sectionsByWellID(wellID ?? '')).pipe(
                first(),
                tap((sections) => {
                    const sectionsAreLoaded = !isNil(sections);

                    if(!isNil(wellID)) {
                        if(isNil(settings[SELECTED_SECTIONS_KEY + '-' + wellID])) {
                            if(sectionsAreLoaded === false && Array.isArray(sectionsOfCurrentWell) && sectionsOfCurrentWell.length) {
                                this.store.dispatch(DDActions.setSelectedSections({wellID, sections: [sectionsOfCurrentWell[sectionsOfCurrentWell.length - 1].id] ?? []}));
                            }
                        } 
                        else {
                            this.store.dispatch(DDActions.setSelectedSections({wellID, sections: settings[SELECTED_SECTIONS_KEY + '-' + wellID] ?? []}));
                        }
                        
                        this.store.dispatch(DDActions.setWellPlan({wellID, wellPlanId: settings[SELECTED_WELL_PLAN_KEY + '-' + wellID] ?? null}));
                    }
                })
            );
        })
    ), {dispatch: false});

    readonly onSetSections$ = createEffect(() => this.actions$.pipe(
        ofType(DDActions.setSelectedSections),
        tap(({sections, wellID}) => {
            if(!isNil(wellID)) {
                this.settings.updateSettings(SETTINGS_PATH, {
                    [SELECTED_SECTIONS_KEY + '-' + wellID]: sections
                });
            }
        })
    ), {dispatch: false});

    readonly onSetWellPlan$ = createEffect(() => this.actions$.pipe(
        ofType(DDActions.setWellPlan),
        tap(({wellPlanId, wellID}) => {
            if(!isNil(wellID)) {
                this.settings.updateSettings(SETTINGS_PATH, {
                    [SELECTED_WELL_PLAN_KEY + '-' + wellID]: wellPlanId
                });
            }
        })
    ), {dispatch: false});

    ngrxOnInitEffects(): Action {
        return initAction();
    }

}