import type { Observable } from 'rxjs';
import type { UnitsPreset, UnitsSnapshot } from '../../models';


/**
 * This is abstraction for getting units
 */
export abstract class UnitsProviderService {
    /**
     * Get observable of units preset for current user.
     */
    abstract onUserUnitsPresetSnapshot(): Observable<UnitsPreset>;

    /**
     * Update unit preset for the current user.
     */
    abstract updateUserUnitsPreset(value: UnitsPreset): Observable<void>;

    /**
     * Get observable of unit groups that are defined in the system.
     */
    abstract onUnitsSnapshot(): Observable<UnitsSnapshot>;
}
