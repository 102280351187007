import { AssetInfo, ClSelectorOptions, FileType, Range, Section, TagValueType, UnitDescriptor, UnitGroup, UnitGroups } from '@cyberloop/core';

import { Mode } from './mode';

export type ExportInputData = {
    section?: Section;
    sections: Section[];
    assets: AssetInfo[];
    templates: ClSelectorOptions[];
    unitGroups: UnitGroups;
    lastDepth?: number;
};

export type MetaForExport = {
    fileType: FileType;
    precision: number;

    rangeMode: RangeMode;

    mode: Mode;

    preselectTimeRange?: number;
    timeStep?: number;
    timeRange?: Range;

    depthStep?: number;
    depthRange?: Range;

    selectedTraces: TracesForExport[];

    selectedSection?: Section;
    selectedSections?: (Section | undefined)[];
    includeParent?: boolean;
};


export interface TracesForExport {
    id: string;
    title: string;
    unit: UnitDescriptor;
    interval: TagValueType;
    number?: number;
}

export interface SelectedTrace {
    id: string,
    title: string,
    unitId: string,
    unitGroup: UnitGroup['id'],
    interval: TagValueType
}

export enum RangeMode {
    SingleSection = 'SingleSection',
    MultipleSections = 'MultipleSections',
    Custom = 'Custom'
}

export enum AddToArray {
    SectionArray = 'selectedSection',
    AssetArray = 'selectedTraces'
}