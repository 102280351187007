import { ID, RigActivity } from '@cyberloop/core';
import { FileMeta, UpdateFileMeta } from '@cyberloop/web/files/model';
import { MeterageItem } from '@cyberloop/web/wells/model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { FILES_FEATURE } from './files.state';

/**
 * Actions for files used internally
 * @internal
 * */
export const FilesActions = createActionGroup({
    source: FILES_FEATURE,
    events: {
        'Load Well file list': emptyProps(),
        'Load Planning file list': emptyProps(),
        'Load list success': props<{ list: FileMeta[] }>(),
        'Load list failure': props<{ error: Error }>(),
        'Unwatch list': emptyProps(),

        'Load list by IDs': props<{ ids: ID[] }>(),
        'Load list by IDs success': props<{ list: FileMeta[] }>(),
        'Load list by IDs failure': props<{ error: Error }>(),

        'Download': props<{ id: ID }>(),
        'Download success': emptyProps(),
        'Download failure': props<{ error: Error }>(),

        'Upload Well file': props<{ file: File, wellId: ID }>(),
        'Upload Well Meterage file': props<{ file: File, wellId: ID, itemId: number, itemList: MeterageItem[] }>(),
        'Upload Planning file': props<{ file: File, planningId: ID }>(),
        'Upload Planning Stage file': props<{ file: File, planningId: ID, stageId: ID, activityId: RigActivity }>(),
        'Upload success': props<{ meta: FileMeta }>(),
        'Upload failure': props<{ error: Error }>(),

        'Try Update': props<{ meta: FileMeta }>(),
        'Update': props<{ id: ID, update: UpdateFileMeta }>(),
        'Update success': props<{ meta: FileMeta }>(),
        'Update failure': props<{ error: Error }>(),

        'Try Delete': props<{ id: ID }>(),
        'Delete': props<{ id: ID }>(),
        'Delete success': props<{ meta: FileMeta }>(),
        'Delete failure': props<{ error: Error }>()
    }
});

/**
 * Actions for files
 * */
export const PublicFilesActions = {
    loadWellFileList: FilesActions.loadWellFileList,
    loadPlanningFileList: FilesActions.loadPlanningFileList,
    unwatchList: FilesActions.unwatchList,
    loadListByIds: FilesActions.loadListByIds,
    download: FilesActions.download,
    uploadWellFile: FilesActions.uploadWellFile,
    uploadWellMeterageFile: FilesActions.uploadWellMeterageFile,
    uploadPlanningFile: FilesActions.uploadPlanningFile,
    uploadPlanningStageFile: FilesActions.uploadPlanningStageFile,
    tryUpdate: FilesActions.tryUpdate,
    tryDelete: FilesActions.tryDelete
};
