import { isNil } from 'lodash';

/** @internal */
const UNKNOWN_RIG_ACTIVITY = -1;

/** Well known rig activities */
export enum RigActivity {
    /** Unknown activity  */
    Unknown = UNKNOWN_RIG_ACTIVITY,
    /** Drilling  */
    Drilling = 0,
    /** Casing running  */
    CasingRunning = 1,
    /** Cementing  */
    Cementing = 2,
    /** Wireline  */
    Wireline = 3,
    /** Rig move  */
    RigMove = 4,
    /** BOP testing  */
    BOPTesting = 5
}

/** @internal */
const RIG_ACTIVITY_NAME = {
    [UNKNOWN_RIG_ACTIVITY]: 'Unknown',
    [RigActivity.Drilling]: 'Drilling',
    [RigActivity.CasingRunning]: 'Casing Running',
    [RigActivity.Cementing]: 'Cementing',
    [RigActivity.Wireline]: 'Wireline',
    [RigActivity.RigMove]: 'Rig Move',
    [RigActivity.BOPTesting]: 'BOP Testing'
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RigActivity {
    /** Get name of rig activity by it's value */
    export function getName(activity: RigActivity) {
        const res = RIG_ACTIVITY_NAME[activity];
        if (isNil(res)) {
            return RIG_ACTIVITY_NAME[UNKNOWN_RIG_ACTIVITY];
        }

        return res;
    }

    /**
     * Get kebab-case id of rig activity
     * @param state Rig activity
     * @returns rig activity id or unknown if state if not known
     */
    export function getId(state: RigActivity) {
        const name = getName(state);
        return name.replace(/[^a-zA-Z0-9]+/g, '-')
            .replace(/-+/g, '-')
            .toLowerCase();
    }

    /**
     * Returns the CSS class name corresponding to the given rig activity.
     * @param state Optional rig activity value.
     * @returns The CSS class name for the given rig activity value or null if state is undefined or null.
     * @example
     * const className = RigActivity.getClassName(RigActivity.Cementing); // returns "ra-cementing"
     */
    export function getClassName(state?: RigActivity) {
        if (isNil(state)) {
            return null;
        }

        return 'ra-' + getId(state);
    }

    /** List of id<=>name mappings */
    export const list = Object.keys(RigActivity)
        .map(Number)
        .filter((key) => !isNaN(key))
        .map((key) => ({ id: key, name: RigActivity.getName(key) }));
}
