import { Injectable } from '@angular/core';

import { AlarmsSettings, GlobalAlarmSettingsService } from '@cyberloop/core';
import { Observable, of } from 'rxjs';

import { TagTimeDataQuery } from '../queries/tags-data.query';

@Injectable({
    providedIn: 'root'
})
export class GlobalAlarmSettingsServiceImpl extends GlobalAlarmSettingsService {
    /**
     *
     */
    constructor(
        private readonly q: TagTimeDataQuery
    ) {
        super();
    }

    override get(ids: string[]): Observable<AlarmsSettings> {
        const settings: AlarmsSettings = {};
        for (const id of ids) {
            settings[id] = {
                lo: 0,
                lolo: 1,
                hi: 2,
                hihi: 3
            };
        }
        return of(settings);
    }

}