import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { DatetimePipe, ID, IconComponent } from '@cyberloop/core';
import { FileMeta } from '@cyberloop/web/files/model';

/** Attaching files component*/
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cl-attach-files',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        DatetimePipe,
        MatMenuModule,
        IconComponent
    ],
    templateUrl: './attach-files.component.html',
    styleUrls: ['./attach-files.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachFilesComponent {
    /** File list */
    @Input() fileList?: FileMeta[] | null;
    /** Disabled */
    @Input() disabled?: boolean | null;
    /** Can add */
    @Input() canAdd?: boolean | null = true;
    /** Can download */
    @Input() canDownload?: boolean | null = true;
    /** Can delete */
    @Input() canDelete?: boolean | null = true;

    /** Add file */
    @Output() readonly add = new EventEmitter();
    /** Download file */
    @Output() readonly download = new EventEmitter<ID>();
    /** Delete file */
    @Output() readonly delete = new EventEmitter<ID>();
}
