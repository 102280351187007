import { Injectable } from '@angular/core';

import { ID } from '@cyberloop/core';
import { CreatePlanning, InitPlanningVersion, Planning, PlanningVersion, UpdatePlanning } from '@cyberloop/web/planning/shared/model';
import { Observable } from 'rxjs';


import { PlanningProviderService } from './abstractions/planning-provider.service';

@Injectable({ providedIn: 'root' })
export class PlanningDataService {
    constructor(private readonly providerService: PlanningProviderService) { }

    watchAll(): Observable<Planning[]> {
        return this.providerService.watchAll();
    }

    watch(id: ID): Observable<Planning | null> {
        return this.providerService.watch(id);
    }

    watchByWellId(wellId: ID): Observable<Planning | null> {
        return this.providerService.watchByWellId(wellId);
    }

    create(data: CreatePlanning): Observable<Planning> {
        const versionToInit: InitPlanningVersion = {
            name: 'v1',
            description: '',
            stages: []
        };
        return this.providerService.create(data, versionToInit);
    }

    update(planningId: ID, data: UpdatePlanning): Observable<Planning> {
        return this.providerService.update(planningId, data);
    }

    selectVersion(version: PlanningVersion): Observable<Planning> {
        return this.providerService.setActiveVersion(version.planningId, version);
    }

    setWellId(planningId: ID, wellId: ID): Observable<Planning> {
        return this.providerService.setWellId(planningId, wellId);
    }
}
