import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';

import { AttachFilesComponent, ClInputComponent, ClNumericInputComponent, ConfirmationDialogService, DatetimePipe, ID, IconComponent, NgForEmptyDirective, NgVarDirective, PopupService, RigActivity, SyncScrollDirective, UnitDescriptor, uuid } from '@cyberloop/core';
import { FileMeta } from '@cyberloop/web/files/model';
import { PlanningActivityDialogData, PlanningStage } from '@cyberloop/web/planning/shared/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isNil, round } from 'lodash';
import { debounceTime } from 'rxjs';

import { PlanningActivityComponent } from './planning-activity/planning-activity.component';

enum StageControls {
    Id = 'id',

    Activity = 'activity',
    WellPhase = 'wellPhase',

    EstTimeHours = 'estTimeHours',
    EstTimeDays = 'estTimeDays',
    EstTimeCumulative = 'estTimeCumulative',

    StartDepth = 'startDepth',
    EndDepth = 'endDepth',
    IntervalLength = 'intervalLength',

    TechLimitHours = 'techLimitHours',
    TechLimitDays = 'techLimitDays',
    TechLimitCumulative = 'techLimitCumulative',

    Comment = 'comment',
    Files = 'files',
}

type NonUndefined<T> = Exclude<T, undefined>;

@UntilDestroy()
@Component({
    selector: 'cyberloop-planning-stages',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgForEmptyDirective,
        NgClass,
        NgVarDirective,
        ReactiveFormsModule,
        NgVarDirective,
        SyncScrollDirective,
        DatetimePipe,
        MatMenuModule,
        PlanningActivityComponent,
        IconComponent,
        ClInputComponent,
        ClNumericInputComponent,
        AttachFilesComponent
    ],
    templateUrl: './planning-stages.component.html',
    styleUrls: ['./planning-stages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningStagesComponent implements OnInit {
    constructor(
        private readonly fb: FormBuilder,
        private readonly popupService: PopupService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly confirmationDialogService: ConfirmationDialogService
    ) { }

    readonly RigActivity = RigActivity;
    readonly StageControls = StageControls;

    readonly stageArray = this.fb.array([this.getStageFormGroup()]);

    get stageArrayControls() {
        return [...this.stageArray.controls];
    }

    @Output() readonly update = new EventEmitter<PlanningStage[]>();
    @Output() readonly toggle = new EventEmitter();
    @Output() readonly attachFile = new EventEmitter<Pick<PlanningStage, 'id' | 'activity'>>();
    @Output() readonly downloadFile = new EventEmitter<ID>();
    @Output() readonly deleteFile = new EventEmitter<ID>();

    @Input() expanded?: boolean;
    @Input() set data(data: PlanningStage[] | null | undefined) {
        this.stageArray.clear({ emitEvent: false });

        data?.forEach(item => {
            this.stageArray.push(this.getStageFormGroup(item), { emitEvent: false });
        });
    }
    @Input() stageFileMap?: Record<ID, FileMeta[]> | null;
    @Input() depthUnit?: UnitDescriptor | null;
    @Input() activityList?: PlanningActivityDialogData['list'];
    @Input() disableActions?: boolean | null;
    @Input() readonly?: boolean | null;

    get depthUnitLabel() {
        return this.depthUnit?.label;
    }

    get disabled() {
        return this.readonly || this.disableActions;
    }

    ngOnInit() {
        this.initCalcObservable();
    }

    onToggle() {
        this.toggle.emit();
    }

    onAddFile(id: ID, activity: RigActivity) {
        this.attachFile.emit({
            id,
            activity
        });
    }

    onDownloadFile(fileId: ID) {
        this.downloadFile.emit(fileId);
    }

    onDeleteFile(fileId: ID) {
        this.deleteFile.emit(fileId);
    }

    async onInsertAboveStage(index: number) {
        const result = await this.openActivityPopup();

        if (!isNil(result)) {
            this.stageArray.insert(index, this.getStageFormGroup({ activity: result }));
            this.changeDetectorRef.markForCheck();
        }
    }

    async onInsertBelowStage(index: number) {
        const result = await this.openActivityPopup();

        if (!isNil(result)) {
            this.stageArray.insert(index + 1, this.getStageFormGroup({ activity: result }));
            this.changeDetectorRef.markForCheck();
        }
    }

    onCopyPasteAboveStage(index: number) {
        const group = cloneDeep(this.stageArray.at(index));
        group.patchValue({ id: uuid() });
        this.stageArray.insert(index, group);
    }

    onCopyPasteBelowStage(index: number) {
        const group = cloneDeep(this.stageArray.at(index));
        group.patchValue({ id: uuid() });
        this.stageArray.insert(index + 1, group);
    }

    async onEditStage(index: number) {
        const stageControl = this.stageArray.at(index);

        const result = await this.openActivityPopup(stageControl.value.activity);

        if (!isNil(result)) {
            stageControl.patchValue({ activity: result });
            this.changeDetectorRef.markForCheck();
        }
    }

    async onDeleteStage(index: number) {
        if (await this.confirmationDialogService.show('Do you really want to delete this stage?')) {
            this.stageArray.removeAt(index);
        }
    }

    async onAddStage() {
        const result = await this.openActivityPopup();

        if (!isNil(result)) {
            this.stageArray.push(this.getStageFormGroup({ activity: result }));
            this.changeDetectorRef.markForCheck();
        }
    }

    trackByStageControl(index: number, stage: FormGroup) {
        return stage.get(StageControls.Id)?.value ?? index;
    }

    private async openActivityPopup(selectedId?: RigActivity | null) {
        return await this.popupService.showForResult<RigActivity | undefined, PlanningActivityDialogData>(PlanningActivityComponent, {
            data: {
                list: this.activityList ?? [],
                selectedId
            }
        });
    }

    private getStageFormGroup(stage?: Partial<PlanningStage>) {
        const group = this.fb.group({
            [StageControls.Id]: [uuid()],

            [StageControls.Activity]: [RigActivity.Unknown],
            [StageControls.WellPhase]: [''],

            [StageControls.EstTimeHours]: [0],
            [StageControls.EstTimeDays]: [{
                value: 0,
                disabled: true
            }],
            [StageControls.EstTimeCumulative]: [{
                value: 0,
                disabled: true
            }],

            [StageControls.StartDepth]: [0],
            [StageControls.EndDepth]: [0],
            [StageControls.IntervalLength]: [{
                value: 0,
                disabled: true
            }],

            [StageControls.TechLimitHours]: [0],
            [StageControls.TechLimitDays]: [{
                value: 0,
                disabled: true
            }],
            [StageControls.TechLimitCumulative]: [{
                value: 0,
                disabled: true
            }],

            [StageControls.Comment]: [''],
            [StageControls.Files]: this.fb.control<ID[]>([])
        });

        if (!isNil(stage)) {
            group.patchValue(stage as NonUndefined<PlanningStage>);
        }

        return group;
    }

    private initCalcObservable() {
        this.stageArray.valueChanges.pipe(
            untilDestroyed(this),
            debounceTime(500)
        ).subscribe(() => {
            this.stageArray.controls.forEach((control, index) => {
                const stage = control.getRawValue();

                if (
                    isNil(stage.startDepth) ||
                    isNil(stage.endDepth) ||
                    isNil(stage.estTimeHours) ||
                    isNil(stage.techLimitHours)
                ) {
                    return;
                }

                const prevStage = this.stageArray.controls[index - 1]?.getRawValue();

                const estTimeDays = round(stage.estTimeHours! / 24, 2);
                const estTimeCumulative = round((prevStage?.estTimeCumulative ?? 0) + estTimeDays, 2);

                const intervalLength = round(stage.endDepth! - stage.startDepth!, 2);

                const techLimitDays = round(stage.techLimitHours! / 24, 2);
                const techLimitCumulative = round((prevStage?.techLimitCumulative ?? 0) + techLimitDays, 2);

                control.patchValue({
                    estTimeDays,
                    estTimeCumulative,
                    intervalLength,
                    techLimitDays,
                    techLimitCumulative
                }, { emitEvent: false });
            });

            const stages = this.stageArray.getRawValue() as PlanningStage[];
            this.update.emit(stages);
        });
    }
}
