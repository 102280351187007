import { Injectable } from '@angular/core';

import { AssetId, CoreSelectors, Points, UnitDescriptor, UnitGroup, UnitsConverterService } from '@cyberloop/core';
import { ViewPortDataProvider } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { Observable, combineLatest, debounceTime, distinctUntilChanged, filter, map, share, switchMap } from 'rxjs';

import { DrillingActions, DrillingSelectors } from '../../state/drilling';
import { PointsStorageService } from './points-storage.service';

@Injectable({
    providedIn: 'root'
})
export class ViewPortDataProviderService extends ViewPortDataProvider {
    private readonly _cache: Record<AssetId, Observable<Points>> = {};

    constructor(
        private readonly store: Store,
        private readonly pss: PointsStorageService,
        private readonly unitConverter: UnitsConverterService
    ) {
        super();
    }


    override getByTag(wellId: string, tagId: AssetId, unitGroup: UnitGroup['id'], unitIdObs: Observable<UnitDescriptor['id'] | undefined>): Observable<Points> {
        return combineLatest([
            this.store.select(CoreSelectors.getWellById(wellId)),
            this.store.select(DrillingSelectors.sectionId)])
            .pipe(
                debounceTime(300),
                filter(([well, section]) => Boolean(well) && Boolean(section)),
                distinctUntilChanged(isEqual),
                map(([well, sectionId]) => ({ isLive: !well?.releaseTime && !well?.suspendTime, sectionId })),
                switchMap(({ isLive, sectionId }) => this.getPointsObservable(wellId, tagId, isLive, sectionId).pipe(
                    debounceTime(300),
                    switchMap(ppts => unitIdObs.pipe(
                        switchMap((unitId) => this.store.select(CoreSelectors.units.getUnit(unitGroup, unitId))),
                        map(unit => ({ ppts, unit }))
                    )),
                    map(({ ppts, unit }) => unit ? ppts.map(({ x, y }) => ({ x, y: typeof y === 'number' ? this.unitConverter.convertToUnit(y, unit) : y })) : ppts)
                ))
            );

    }

    override reset(): void {
        return this.pss.resetCache();
    }

    private getPointsObservable(wellId: string, tagId: string, isLive: boolean, sectionId: string | undefined): Observable<Points> {
        this.store.dispatch(DrillingActions.changeLiveSubscriptionStatus({ liveSubscriptionStatus: false }));
        const forTags = this.pss.forTagAndWell(tagId, wellId, isLive, sectionId);
        return combineLatest([
            this.store.select(DrillingSelectors.viewport),
            this.store.select(DrillingSelectors.wellViewport),
            this.store.select(DrillingSelectors.isTime)
        ]).pipe(
            debounceTime(200),
            filter(([vp]) => Boolean(vp)),
            distinctUntilChanged(isEqual),
            switchMap(([vp, wellVp, isTime]) => forTags.getFor(vp, wellVp, isTime ? undefined : (sectionId ?? undefined))),
            share()
        );
    }
}

