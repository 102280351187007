import { Injectable } from '@angular/core';

import { DepthExportQueryInput, ExportDataColumn, ExportDataObject, ExportDataService, PrimaryColumnId, Range, Section, SectionForDepthExport, TagValueType, TagsValue, UnitsConverterService, Well, primaryColumData } from '@cyberloop/core';
import { MetaForExport, Mode } from '@cyberloop/web/wells/model';
import { isNil } from 'lodash';
import { firstValueFrom } from 'rxjs';

import { ExportToFileService } from './export-to-file.service';

@Injectable()
export class ExportDepthDataService {

    constructor(
        private readonly _exportData: ExportDataService,
        private readonly _exportToFile: ExportToFileService,
        private readonly _unitConverter: UnitsConverterService
    ) { }

    public async processExport(well: Well, obj: MetaForExport, allSections: Section[]) {
        //#region GET DATA
        const rigName = well.rig;
        const step = (obj.depthStep ?? 10) / 10;
        const traces = obj.selectedTraces;
        const tags = [...new Set(traces.map(x => x.id)).values()];
        const intervals = [... new Set(obj.selectedTraces.map(x => x.interval)).values()];
        const depthRange = obj.depthRange;

        if (!obj.selectedSection) {
            return;
        }

        const sections = this.getSectionsForExport(allSections, obj.selectedSection, depthRange, obj.includeParent);

        const input: DepthExportQueryInput = { rigName, wellId: Number(well.id), step, tags, intervals, sections };

        const query = this._exportData.getExportDataByDepth(input);

        const data: TagsValue[] = await firstValueFrom(query);

        if (!data.length) {
            return;
        }
        //#endregion

        //#region MAKE COLUMN
        const columns: ExportDataColumn[] = traces.map(trace => ({
            id: trace.id,
            name: trace.title,
            unit: trace.unit.id,
            // unitGroupId: trace.unit.unitGroup,
            label: trace.unit.label,
            secondId: trace.number ? trace.id + trace.number : trace.id 
        }));
        columns.unshift(primaryColumData[PrimaryColumnId.Depth]); // IMPORTANT! Primary column must be first in array
        //#endregion

        //#region MAPPED DATA
        const mappedData = new Map<number, Record<string, number>>();
        const byDepth = obj.mode === Mode.Depth;
        const primaryKey = PrimaryColumnId.Depth;
        const primaryField = TagValueType.StartDepth;
        const sectionsKeys = sections.map(x => x.name);

        for (const trace of traces) { // tags loop
            const fieldKey = trace.interval;
            const unit = trace.unit;
            const tagKey = trace.id;
            const storeKey = trace.number ? tagKey + trace.number : tagKey;
            const tag = data.find(x => x.name === tagKey);

            for (const section of sectionsKeys) { // section loop
                const tagData = tag?.[section];
                if (!tagData) {
                    continue;
                }

                for (const item of tagData) {
                    const mapKey = item[primaryField];
                    let val = mappedData.get(mapKey);
                    if (!val) {
                        val = { [primaryKey]: mapKey };
                        mappedData.set(mapKey, val);
                    }

                    
                    val[storeKey] = this._unitConverter.convertToUnit(item[fieldKey], unit);

                }
            }
        }

        const mappedValues = Array.from(mappedData.values());

        //#endregion
        const exportDataObj: ExportDataObject = {
            fileName: 'drilling_',
            columns: columns,
            data: mappedValues,
            from: depthRange?.from ?? sections[0].from ?? 0,
            to: depthRange?.to ?? sections[0].to ?? 0,
            format: obj.fileType,
            byDepth,
            step: step,
            precision: obj.precision,
            well
        };

        this._exportToFile.exportData(exportDataObj);
    }

    private getSectionsForExport(rawSections: (Section | undefined)[], selectedSection: Section | undefined, depthRange?: Range, includeParent?: boolean): SectionForDepthExport[] {
        if (!selectedSection) {
            return [];
        }
        if (!depthRange || isNil(depthRange.from) || isNil(depthRange.to)) {
            return [];
        }

        const sections: SectionForDepthExport[] = [];

        if (includeParent) {
            const sectionsWithParents: Section[] = [];
            let current: number | undefined = selectedSection.id;

            do {
                const section = rawSections.find(x => x?.id === current);
                if (section) {
                    sectionsWithParents.push(section);
                }
                current = section?.parent;
            } while(current);

            let lastFrom: number | undefined;
            for (const section of sectionsWithParents) {
                if (section.from > depthRange.to) {
                    continue;
                }
                const name = 's' + section.id;
                const to = Math.min(depthRange.to, section?.to ?? depthRange.to, lastFrom ?? depthRange.to);
                const from = Math.max(depthRange.from, section.from);
                lastFrom = section.from;
    
                sections.push({ name, id: section.id, to, from });
            }
        }
        else {
            const name = 's' + selectedSection.id;
            const to = depthRange.to ?? selectedSection.to;
            const from = depthRange.from ?? selectedSection.from;
            sections.push({ name, id: selectedSection.id, to, from });
        }

        return sections;
    }
}