import { AssetMetricWithData, AssetMetricWithUnit, AssetMetrics, WidgetSettings } from '@cyberloop/web/wells/model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { KPI_FEATURE, KpiState } from './kpi.state';

const feature = createFeatureSelector<KpiState>(KPI_FEATURE);

// KPI data loading state
const dataLoading = createSelector(feature, state => state.dataState === 'LOADING');
const dataLoaded = createSelector(feature, state => state.dataState === 'LOADED');
const dataNotLoaded = createSelector(feature, state => state.dataState === 'NOT_LOADED');

// KPI widgets
const widgets = createSelector(feature, state => state.widgets);
const widgetsOnCurrentTab = createSelector(feature, state => state.widgets?.filter(widget => widget.tadId === state.activeDashboard) ?? []);

const dashboards = createSelector(feature, state => state.dashboards);
const activeDashboard = createSelector(feature, state => state.activeDashboard);

const selectedMetrics = createSelector(feature, state => state.selectedMetrics);
const selectedMetricsWithUnits = createSelector(feature, state => {
    const result: AssetMetricWithUnit[] = [];
    for (const metric of AssetMetrics) {
        const found = state.selectedMetrics.find(m => m.tag === metric.tag);
        if(found) {
            result.push(metric);
        }
    }
    return result;
});

// Specific widgets settings
const allWidgetsSettings = createSelector(feature, state => state.widgetSettings ?? {});
const widgetSettings = <TSettings extends WidgetSettings>(widgetId: string) =>
    createSelector(allWidgetsSettings, (settings) => settings[widgetId] as TSettings | undefined);

/**
 * Selectors for wells
 * */
export const KpiSelectors = {
    dataLoading,
    dataLoaded,
    dataNotLoaded,

    widgets,
    allWidgetsSettings,
    widgetSettings,
    dashboards,
    activeDashboard,
    selectedMetrics
};

/**
 * Public Selectors for wells
 * */
export const PublicKpiSelectors = {
    dataLoading,
    dataLoaded,

    widgets,
    widgetSettings,
    dashboards,
    activeDashboard,
    widgetsOnCurrentTab,
    selectedMetrics,
    selectedMetricsWithUnits
};