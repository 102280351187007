import { KeyValuePipe, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
A component for displaying errors for an Angular AbstractControl.
@remarks
This component displays a list of error messages for an AbstractControl. It is used
as an attribute on an element containing a form control.
@example
<cl-form-errors [control]="formControl"></cl-form-errors
*/
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cl-form-errors',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault,
        KeyValuePipe
    ],
    templateUrl: './form-errors.component.html',
    styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent {
    /**
    The form control to display errors for.
    */
    @Input() control?: AbstractControl;

    /**
    Gets the validation errors for the form control.
    */
    get errors(): ValidationErrors | null {
        return this.control?.errors ?? null;
    }
}
