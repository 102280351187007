<div class="container content">
    <div class="title">Unit selection</div>

    <ng-container *ngIf="unitsLoaded; else loader">
        <div class="groups" *ngIf="groups">

            <div class="group" *ngFor="let groupKey of groupKeys">
                <div class="group-name">{{ groups[groupKey].name }}</div>
                <cl-selector class="selector" [(value)]="presets[groupKey]" [options]="unitsOptions[groupKey]">
                </cl-selector>
            </div>

        </div>

        <div class="btns">
            <button color="primary" (click)="cancel()">Cancel</button>
            <button color="primary" (click)="saveAndClose()">Apply</button>
        </div>
    </ng-container>

    <ng-template #loader>
        <pseudo-loader></pseudo-loader>
    </ng-template>
</div>