import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DatetimePipe, ID, IconComponent, NgVarDirective, RigActivity } from '@cyberloop/core';
import { CarouselComponent, CarouselItemDirective } from '@cyberloop/web/app/ui';
import { FileMeta } from '@cyberloop/web/files/model';
import { isNil } from 'lodash';

export type RecentFileMeta = FileMeta & {
    extension: string | null;
}

@Component({
    selector: 'cyberloop-recent',
    standalone: true,
    imports: [NgIf, NgFor, NgClass, NgVarDirective, DatetimePipe, IconComponent, CarouselComponent, CarouselItemDirective],
    templateUrl: './recent.component.html',
    styleUrls: ['./recent.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecentComponent {
    @Input() files?: RecentFileMeta[] | null;
    @Input() disableActions?: boolean | null;
    @Input() readonly?: boolean | null;

    @Output() readonly download = new EventEmitter<ID>();
    @Output() readonly delete = new EventEmitter<ID>();

    readonly RigActivity = RigActivity;
    readonly isNil = isNil;

    onDownload(id: ID) {
        this.download.emit(id);
    }

    onDelete(id: ID) {
        this.delete.emit(id);
    }
}
