import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MenuItemsComponent } from './menu-items/menu-items.component';
import { RecentActivitiesComponent } from './recent-activities/recent-activities.component';

import type { NavigationItem, RecentItemWithWellMeta } from '@cyberloop/web/app/model';

@Component({
    selector: 'cyberloop-side-menu',
    standalone: true,
    imports: [
        CommonModule,
        MenuItemsComponent,
        RecentActivitiesComponent
    ],
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
    @Input() showPanel = false;
    @Input() navigationItems: NavigationItem[] | null = [];
    @Input() recentItems: RecentItemWithWellMeta[] | null = [];

    @Output() readonly navigationRequest = new EventEmitter<string>();

    toggle() {
        this.showPanel = !this.showPanel;
    }

    navigateAndHide(url: string) {
        this.navigationRequest.emit(url);
        this.hide();
    }

    hide() {
        this.showPanel = false;
    }
}
