import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { TndItem } from '@cyberloop/web/tnd/model';

type TorqueAndDragResult = {
    well: {
        torqndrag: {
            entries: {
                edges: TndItem[]
            }
        }
    }
}

type TorqueAndDragInput = {
    wellId: string,
    since: string,
    until: string,
}

@Injectable({
    providedIn: 'root'
})
export class TorqueAndDragQuery extends GraphQLQuery<TorqueAndDragResult, TorqueAndDragInput>{
    override readonly document = `
        query GetTorqueAndDrag($wellId: ID!, $since: DateTime!, $until: DateTime!) {
            well(id: $wellId) {
                ... on Well {
                    torqndrag {
                        entries(since: $since, until: $until) {
                            edges {
                                dtime
                                depth
                                pickUp
                                slackOff
                                rotary
                                onBottomTorque
                                offBottomTorque
                            }
                        }
                    }
                }
            }
        }
    `;
}
