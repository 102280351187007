import { Injectable } from '@angular/core';

import { UnitsSelectorComponent } from '../components/units-selector/units-selector.component';
import { PopupService } from './popup.service';

/** Units selector */
@Injectable({ providedIn: 'root' })
export class UnitsSelectorService {
    /** @private @internal */
    constructor(private readonly popup: PopupService) { }

    /**
     * Show units selector dialog
     */
    show(): Promise<void> {
        return this.popup.show(UnitsSelectorComponent);
    }
}
