import { WellKnownUnitIds } from '@cyberloop/core';

import { TndChart } from './common';

/**
 * A model (plan) for a T&D chart
 */
export type TndChartModel = {
    id: string,
    kind: TndChart,
    name: string,
    valueUnitId?: WellKnownUnitIds,
    depthUnitId: WellKnownUnitIds,
    startDepth: number,
    endDepth: number
}

export type TndChartWeightsModel = TndChartModel & {
    kind: TndChart.Weights,
    pullAlarmLine?: string,
    pushAlarmLine?: string
}
export type TndChartTorqueModel = TndChartModel & {
    kind: TndChart.Torque,
    highTorqueAlarmLine?: string
}

export type TndModelDataItem = {
    depth: number,
    value: number
}

export type TndChartModelDataset = {
    /** Column name */
    name: string,
    /** Column data */
    data: TndModelDataItem[],
}

/**
 * A weights model (plan) for a T&D chart with data items
 */
export type TndChartWeightsModelWithData = TndChartWeightsModel & {
    dataSets: TndChartModelDataset[]
}
/**
 * A torque model (plan) for a T&D chart with data items
 */
export type TndChartTorqueModelWithData = TndChartTorqueModel & {
    dataSets: TndChartModelDataset[]
}
/**
 * A model (plan) for a T&D chart with data items
 */
export type TndChartModelWithData = TndChartWeightsModelWithData | TndChartTorqueModelWithData;

export function isTndChartWeightsModel(model: Pick<TndChartModel, 'kind'>): model is TndChartWeightsModel {
    return model.kind === TndChart.Weights;
}
export function isTndChartTorqueModel(model: Pick<TndChartModel, 'kind'>): model is TndChartTorqueModel {
    return model.kind === TndChart.Torque;
}
