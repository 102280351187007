import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiRigBitOnBitOffBottomHour } from '@cyberloop/web/wells/model';
type KpiRigBitOnBitOffBottomHoursInput = {
    rig: string,
    since: string,
    until: string
}

type KpiRigBitOnBitOffBottomHours = {
    rig: {
        rigStateBitOnBitOffBottomHours: KpiRigBitOnBitOffBottomHour[]
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiBitOnBitOffBottomHoursQuery extends GraphQLQuery<KpiRigBitOnBitOffBottomHours, KpiRigBitOnBitOffBottomHoursInput> {
    override readonly document = `
        query GetBitOnBitOffBottomHours($rig: String!, $since: DateTime!, $until: DateTime!) {
            rig(name: $rig) {
                ... on Rig {
                    rigStateBitOnBitOffBottomHours(since: $since, until: $until) {
                        hour
                        durationDrilling
                        durationNoDrilling
                    }
                }
            }
        }
    `;
}