import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CalendarRange, DatetimePipe, IconComponent } from '@cyberloop/core';
import { isNil } from 'lodash';

import * as moment from 'moment';

/**
 * Represents the available calendar views.
 * @enum {number}
 * @readonly
 */
export enum CalendarView {
    Day = 1,
    Month,
    Year
}

/**
 * Angular component representing a calendar.
 */
@Component({
    selector: 'cyberloop-calendar',
    standalone: true,
    imports: [NgIf, NgFor, NgClass, NgSwitch, NgSwitchCase, DatetimePipe, IconComponent],
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements OnInit {
    /**
     * Represents the current moment in time.
     * @type {moment.Moment}
     * @readonly
     * @private
     */
    private readonly now: moment.Moment = moment().utc(true);

    /**
     * Emits an event whenever the selected date changes.
     * @type {EventEmitter<moment.Moment>}
     * @readonly
     */
    @Output() readonly dateChange = new EventEmitter<moment.Moment | null>();

    /**
     * Emits an event whenever the selected date range changes.
     * @type {EventEmitter<CalendarRange>}
     * @readonly
     */
    @Output() readonly rangeChange = new EventEmitter<CalendarRange>();

    /**
     * The abbreviated names of the weekdays in the current locale.
     * @type {string[]}
     * @readonly
     */
    readonly weekdays = moment.weekdaysShort(true);

    /**
     * An enum representing the different views of the calendar component.
     * @type {typeof CalendarView}
     * @readonly
     */
    readonly CalendarView = CalendarView;

    /**
     * Range or single date select
     * @type {boolean}
     * @default false
     */
    @Input() range = false;

    /**
     * The start date of the currently selected date range.
     * @type {moment.Moment | null}
     * @default null
     * @input
     */
    @Input() startDate: moment.Moment | null = null;

    /**
     * The end date of the currently selected date range.
     * @type {moment.Moment | null}
     * @default null
     * @input
     */
    @Input() endDate: moment.Moment | null = null;

    /**
     * The earliest date that can be selected in the calendar component.
     * @type {moment.Moment | null}
     * @default null
     * @input
     */
    @Input() minStartDate: moment.Moment | null = null;

    /**
     * The latest date that can be selected in the calendar component.
     * @type {moment.Moment | null}
     * @default null
     * @input
     */
    @Input() maxStartDate: moment.Moment | null = null;

    /**
     * The current view of the calendar component.
     * @type {CalendarView}
     * @default CalendarView.Day
     */
    currentView: CalendarView = CalendarView.Day;

    /**
     * The current month being displayed by the calendar component.
     * @type {moment.Moment}
     * @default The current month
     */
    currentMonth: moment.Moment = this.now.clone().startOf('month');

    /**
     * An array of weeks to be displayed in the current view of the calendar component.
     * @type {Array<Array<moment.Moment | null>>}
     * @default []
     */
    weeks: (moment.Moment | null)[][] = [];

    /**
     * An array of months to be displayed in the month view of the calendar component.
     * @type {Array<moment.Moment>}
     * @default []
     */
    months: moment.Moment[] = [];

    /**
     * An array of years to be displayed in the year view of the calendar component.
     * @type {Array<moment.Moment>}
     * @default []
     */
    years: moment.Moment[] = [];

    /**
    Method to initialize the component and generate the calendar.
    */
    ngOnInit() {
        this.generateCalendar();
    }

    /**
    Method to change the current view of the calendar.
    @param view - The new calendar view to set.
    */
    changeView(view: CalendarView) {
        if (this.currentView === view) {
            this.currentView = CalendarView.Day;
            this.generateCalendar();
            return;
        }

        this.currentView = view;
        this.generateCalendar();
    }

    /**
    Method to navigate to the previous month in the calendar.
    */
    previousMonth() {
        this.currentMonth = this.currentMonth.clone().subtract(1, 'month');
        this.generateCalendar();
    }

    /**
    Method to navigate to the next month in the calendar.
    */
    nextMonth() {
        this.currentMonth = this.currentMonth.clone().add(1, 'month');
        this.generateCalendar();
    }

    /**
    Method to select a year in the calendar and generate the calendar with the selected year.
    @param date - The moment object representing the selected year.
    */
    selectYear(date: moment.Moment) {
        this.currentView = CalendarView.Day;
        this.currentMonth = this.currentMonth.clone().set('year', date.year())
        this.generateCalendar();
    }

    /**
    Method to select a month in the calendar and generate the calendar with the selected month.
    @param date - The moment object representing the selected month.
    */
    selectMonth(date: moment.Moment) {
        this.currentView = CalendarView.Day;
        this.currentMonth = this.currentMonth.clone().set('month', date.month())
        this.generateCalendar();
    }

    /**
    Method to select a day in the calendar and emit the change event with the selected date or range of dates.
    @param date - The moment object representing the selected day.
    */
    selectDay(date: moment.Moment) {
        if (this.isDisabled(date)) {
            return;
        }
        if (this.range) {
            if (!this.startDate || (this.startDate && this.endDate)) {
                this.startDate = date;
                this.endDate = null;
            } else if (date.isBefore(this.startDate)) {
                this.endDate = this.startDate;
                this.startDate = date;
            } else {
                this.endDate = date;
            }

            if (!isNil(this.startDate) && !isNil(this.endDate)) {
                this.rangeChange.emit({
                    start: this.startDate,
                    end: this.endDate
                });
            }

        } else {
            this.startDate = date;
            this.endDate = null;
            this.dateChange.emit(this.startDate);
        }
    }

    /**
    Method to check if a date is selected.
    @param date - The moment object representing the date to check.
    @returns A boolean indicating if the date is selected.
    */
    isDateSelected(date: moment.Moment) {
        return this.startDate && this.endDate && date.isBetween(this.startDate, this.endDate, 'day', '[]');
    }

    /**
    Method to check if a date is the start date of the selected range.
    @param date - The moment object representing the date to check.
    @returns A boolean indicating if the date is the start date of the selected range.
    */
    isDateStart(date: moment.Moment) {
        return this.startDate && date.isSame(this.startDate, 'day');
    }

    /**
    Method to check if a date is the end date of the selected range.
    @param date - The moment object representing the date to check.
    @returns A boolean indicating if the date is the end date of the selected range.
    */
    isDateEnd(date: moment.Moment) {
        return this.endDate && date.isSame(this.endDate, 'day');
    }

    /**
    Method to check if a date is between the start and end date of the selected range.
    @param date - The moment object representing the date to check.
    @returns A boolean indicating if the date is between the start and end date of the selected range.
    */
    isDateBetween(date: moment.Moment) {
        return this.startDate && this.endDate && date.isBetween(this.startDate, this.endDate, 'day', '()');
    }

    /**
     * Determines whether a given date is disabled in the calendar component.
     * @param date - The date to check.
     * @returns A boolean indicating whether the date is disabled.
     */
    isDisabled(date: moment.Moment) {
        if (!isNil(this.minStartDate) && date.isBefore(this.minStartDate)) {
            return true;
        } else if (!isNil(this.maxStartDate) && date.isAfter(this.maxStartDate)) {
            return true;
        }

        return false;
    }

    /**
     * Clear date or range dates
     */
    clear() {
        this.dateChange.emit(null);
        this.rangeChange.emit({
            start: null,
            end: null
        });
    }

    /**
    Generates the weeks, months or years based on the currentView and currentMonth.
    If currentView is "Day", generates an array of weeks with each containing an array of days.
    If currentView is "Month", generates an array of months.
    If currentView is "Year", generates an array of years.
    @returns {void}
    */
    private generateCalendar() {
        if (this.currentView === CalendarView.Day) {
            const weeks: (moment.Moment | null)[][] = [];
            const startMonth = this.currentMonth.clone().startOf('month');
            const startDate = startMonth.clone().startOf('week');
            const endMonth = this.currentMonth.clone().endOf('month')
            const endDate = endMonth.clone().endOf('week');

            const currentDay = startDate.clone();

            while (currentDay.isBefore(endDate)) {
                const week: (moment.Moment | null)[] = [];

                for (let i = 0; i < 7; i++) {
                    if (currentDay.isSameOrAfter(startMonth) && currentDay.isSameOrBefore(endMonth)) {
                        week.push(currentDay.clone());
                    } else {
                        week.push(null);
                    }

                    currentDay.add(1, 'day');
                }
                weeks.push(week);
            }

            this.weeks = weeks;
        } else if (this.currentView === CalendarView.Month) {
            // generate months for month view
            const months: moment.Moment[] = [];
            const startYear = this.now.clone().startOf('year');
            const endYear = this.now.clone().endOf('year');
            const currentMonth = startYear.clone();

            while (currentMonth.isBefore(endYear)) {
                months.push(currentMonth.clone());
                currentMonth.add(1, 'month');
            }

            this.months = months;
        } else if (this.currentView === CalendarView.Year) {
            // generate years for year view
            const years: moment.Moment[] = [];
            const currentYear = this.now.clone().startOf('year').subtract(10, 'year');

            for (let i = 0; i < 12; i++) {
                years.push(currentYear.clone());
                currentYear.add(1, 'year');
            }

            this.years = years;
        }
    }
}
