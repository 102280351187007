import { Injectable } from '@angular/core';

import { Rig, RigInfoProviderService } from '@cyberloop/core';
import { isEqual } from 'lodash';
import { EMPTY, Observable, distinctUntilChanged, filter, map, of, shareReplay, switchMap } from 'rxjs';

import { RigsMetaQuery } from '../queries/rigs-meta.query';

@Injectable({
    providedIn: 'root'
})
export class RigInfoProviderServiceImpl extends RigInfoProviderService {
    /**
     *
     */
    constructor(
        private readonly rigsMetaQuery: RigsMetaQuery
    ) {
        super();
    }

    readonly rigs$ = this.rigsMetaQuery.watch({ pollInterval: 60 * 1000 }).pipe(
        filter(x => !x.loading),
        switchMap(res => {
            if (res.errors) {
                res.errors.forEach(err => {
                    console.warn({ err });
                });
                return EMPTY;
            }

            if (!res.data?.rigs) {
                console.warn({ err: 'NO DATA' });
                return EMPTY;
            }

            return of(res.data.rigs);
        }),
        distinctUntilChanged(),
        shareReplay(1)
    );

    watchRigs(): Observable<Rig[]> {
        return this.rigs$.pipe(
            map(x => x.map(rig => ({
                id: rig.name,
                name: rig.name
            }))),
            distinctUntilChanged(isEqual),
            shareReplay(1)
        );
    }
}
