<main [class.skeleton]="skeleton">
  <ng-container *ngIf="!skeleton">
    <div class="top-panel">
      <cl-selector placeholder="Pick plan" [options]="wellPlansOptions" 
      (valueChange)="planSelect.emit($event)" [value]="selectedPlanId"></cl-selector>
    
      <cl-button-toggle [selected]="selectedPlanType" (selectedChange)="typeSelect.emit($event)" [list]="planTypeOptions"
      class="button-toggle"></cl-button-toggle>
    
      <div class="vsa-block">
        <label>VSA</label>
        <div class="vsa-value-container">
          <div class="value">{{ wellPlan?.vsa | fromSi:angleUnit | formatNumber | noData }}</div>
          <div class="unit">{{ angleUnit?.label | noData }}</div>
        </div>
      </div>
    </div>
    
    <section class="table">
      <div class="table-container">
        <table mat-table [dataSource]="tableData">
          <ng-container *ngFor="let col of tableColumns" [matColumnDef]="col.id">
              <th mat-header-cell *matHeaderCellDef>
                <div class="column-content">
                  {{col.name}} <span class="unit">{{ col.unitsGroup ? (col.unitLabel | async) : '' }}</span>
                </div> 
              </th>
              <td mat-cell *matCellDef="let element; let i = index;">
                  {{ (col.unitsGroup ? (element[col.id] | fromSi:(col.unit | async)) : element[col.id]) | formatNumber | noData: 'n/a' }}
              </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </section>
  </ng-container>
</main>