import { Injectable } from '@angular/core';

import { TndChartDataProvider, TndModelProvider } from '@cyberloop/web/tnd/data';
import { TndChart } from '@cyberloop/web/tnd/model';
import { Observable, map, of } from 'rxjs';

import type { TndChartData, TndChartDataWithModel, TndTorqueChartDataWithModel, TndWeightsChartDataWithModel } from '@cyberloop/web/tnd/model';

/**
 * Provides a data for T&D chart by it's type
 */
@Injectable({ providedIn: 'root' })
export class TndChartDataProviderService extends TndChartDataProvider {

    constructor(
        private readonly modelProvider: TndModelProvider
    ) {
        super();
    }

    /**
     * Provides for T&D Weights chart
     */
    watchChartData(realmId: string, type: TndChart.Weights, sectionId?: number, modelId?: string): Observable<TndWeightsChartDataWithModel>;
    /**
     * Provides for T&D Torque chart
     */
    watchChartData(realmId: string, type: TndChart.Torque, sectionId?: number, modelId?: string): Observable<TndTorqueChartDataWithModel>;
    /**
     * Provides for T&D charts depending by type
     */
    watchChartData(realmId: string, type: TndChart, sectionId?: number, modelId?: string): Observable<TndChartDataWithModel>;
    watchChartData(realmId: string, type: TndChart, sectionId?: number, modelId?: string): Observable<TndChartDataWithModel> {
        // We don't have any T&D data here, just models

        const data: TndChartData = {
            pickUp: [],
            slackOff: [],
            rotary: [],
            onBottomTorque: [],
            offBottomTorque: []
        };

        if (modelId) {
            return this.modelProvider.getData(modelId).pipe(
                map(model => ({
                    ...data,
                    model
                } as TndChartDataWithModel))
            );
        }
        else {
            return of(data);
        }
    }

}
