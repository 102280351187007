import { Directive, ElementRef } from '@angular/core';

/** @internal */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[formControl], [formControlName], [ngModel]',
    standalone: true
})
export class FormControlAccessorDirective<T extends HTMLElement = HTMLElement> {

    constructor(public readonly el: ElementRef<T>) {

    }

}
