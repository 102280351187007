import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { Store, provideState } from '@ngrx/store';
import { catchError, filter, first, firstValueFrom, of } from 'rxjs';

import { AuthEffects } from './auth.effects';
import { authReducer } from './auth.reducer';
import { AuthSelectors } from './auth.selectors';
import { AUTH_FEATURE } from './auth.state';

/** Get providers for auth */
export function getAuthProviders(): EnvironmentProviders[] {
    return [
        provideEffects([AuthEffects]),
        provideState(AUTH_FEATURE, authReducer),
        makeEnvironmentProviders([
            { provide: APP_INITIALIZER, multi: true, deps: [Store], useFactory: (store: Store) => initializeAuth(store) }
        ])
    ];
}

/** @internal */
function initializeAuth(store: Store): () => Promise<void> {
    return async () => {
        await firstValueFrom(store.select(AuthSelectors.isInitialized).pipe(
            filter(Boolean),
            first(),
            catchError(() => of(false))
        ));
    };
}
