import { WellKnownParams, WellKnownUnitIds } from '@cyberloop/core';

import { WidgetSettingsTimeRangeType, WidgetSettingsTimeRanges, widgetSettingsAllowedTimeRanges } from './widget-time-range';

import type { WidgetSettingsTimeRange } from './widget-time-range';

export type AssetDailyMetricsWidgetSettings = {
    /** Units for rotation data display */
    rotationUnitId?: string;
    /** Units for distance data display */
    distanceUnitId?: string;
    /** Units for time display */
    timeUnitId?: string;
};

export const assetDailyMetricsDefaultSettings: Required<AssetDailyMetricsWidgetSettings> = {
    rotationUnitId: WellKnownUnitIds.MeterPerSecond,
    distanceUnitId: WellKnownUnitIds.Meter,
    timeUnitId: WellKnownUnitIds.Seconds
};

export type BitBottomWidgetSettings = {
    /** Interval which we'll display the data for */
    timeRange?: WidgetSettingsTimeRange;
    /** Units for time display */
    timeUnitId?: string;
};

export const bitBottomDefaultSettings: Required<BitBottomWidgetSettings> = {
    timeRange: {
        type: WidgetSettingsTimeRangeType.TimeRange,
        range: widgetSettingsAllowedTimeRanges[WidgetSettingsTimeRanges.Hours_24].value
    },
    timeUnitId: WellKnownUnitIds.Seconds
};

export type ConnectionTimeWidgetSettings = {
    /** Interval which we'll display the data for */
    timeRange?: WidgetSettingsTimeRange;
    /** Units for time display */
    timeUnitId?: string;
    // Y axis settings
    yMin?: number;
    yMax?: number;
    // Target line
    target?: number;
};

export const connectionTimeDefaultSettings: Required<Pick<ConnectionTimeWidgetSettings, 'timeRange' | 'timeUnitId'>> & Exclude<ConnectionTimeWidgetSettings, 'timeRange' | 'timeUnitId'> = {
    timeRange: {
        type: WidgetSettingsTimeRangeType.TimeRange,
        range: widgetSettingsAllowedTimeRanges[WidgetSettingsTimeRanges.Hours_24].value
    },
    timeUnitId: WellKnownUnitIds.Seconds
};

export type DaysVsDepthWidgetSettings = {
    /** Units for depth data display */
    depthUnitId?: string;
    /** To show the current well plan or not */
    showPlan?: boolean;
    /** To show the current well plan technical limit or not */
    showPlanLimit?: boolean;
};

export const daysVsDepthDefaultSettings: Required<DaysVsDepthWidgetSettings> = {
    depthUnitId: WellKnownUnitIds.Meter,
    showPlan: false,
    showPlanLimit: false
};

export type RigActivityWidgetSettings = {
    /** Interval which we'll display the data for */
    timeRange?: WidgetSettingsTimeRange;
    /** Units for time display */
    timeUnitId?: string;
};

export const rigActivityDefaultSettings: Required<RigActivityWidgetSettings> = {
    timeRange: {
        type: WidgetSettingsTimeRangeType.TimeRange,
        range: widgetSettingsAllowedTimeRanges[WidgetSettingsTimeRanges.Hours_24].value
    },
    timeUnitId: WellKnownUnitIds.Seconds
};

export type RigActivityPerHourWidgetSettings = {
    /** Units for depth data display */
    depthUnitId?: string;
};

export const rigActivityPerHourDefaultSettings: Required<RigActivityPerHourWidgetSettings> = {
    depthUnitId: WellKnownUnitIds.Meter
};

export type RigStateWidgetSettings = {
    /** Interval which we'll display the data for */
    timeRange?: WidgetSettingsTimeRange;
    /** Units for time display */
    timeUnitId?: string;
};

export const rigStateDefaultSettings: Required<RigStateWidgetSettings> = {
    timeRange: {
        type: WidgetSettingsTimeRangeType.TimeRange,
        range: widgetSettingsAllowedTimeRanges[WidgetSettingsTimeRanges.Hours_24].value
    },
    timeUnitId: WellKnownUnitIds.Seconds
};

export type RigStatePerHourWidgetSettings = {
    /** Interval which we'll display the data for */
    timeRange?: WidgetSettingsTimeRange;
    /** Units for depth display */
    depthUnitId?: string;
};

export const rigStatePerHourDefaultSettings: Required<RigStatePerHourWidgetSettings> = {
    timeRange: {
        type: WidgetSettingsTimeRangeType.TimeRange,
        range: widgetSettingsAllowedTimeRanges[WidgetSettingsTimeRanges.Hours_24].value
    },
    depthUnitId: WellKnownUnitIds.Meter
};

export type SlideRotaryWidgetSettings = {
    /** Interval which we'll display the data for */
    timeRange?: WidgetSettingsTimeRange;
    /** Units for time display */
    timeUnitId?: string;
};

export const slideRotaryDefaultSettings: Required<SlideRotaryWidgetSettings> = {
    timeRange: {
        type: WidgetSettingsTimeRangeType.TimeRange,
        range: widgetSettingsAllowedTimeRanges[WidgetSettingsTimeRanges.Hours_24].value
    },
    timeUnitId: WellKnownUnitIds.Seconds
};

export enum SlideVsRotaryWidgetSettingsMode {
    Time = 'time',
    Length = 'length'
}

export type SlideVsRotaryWidgetSettings = {
    mode: SlideVsRotaryWidgetSettingsMode,
    /** Interval which we'll display the data for. In this case it can be only a section */
    sectionId?: number;
    /** Units for length & time display */
    lengthUnitId?: string;
    timeUnitId?: string;
};

export const slideVsRotaryDefaultSettings: Required<Pick<SlideVsRotaryWidgetSettings, 'timeUnitId' | 'mode'>> & Omit<SlideVsRotaryWidgetSettings, 'timeUnitId' | 'mode'> = {
    mode: SlideVsRotaryWidgetSettingsMode.Time,
    lengthUnitId: WellKnownUnitIds.Meter,
    timeUnitId: WellKnownUnitIds.Seconds
};

export type TrippingSpeedWidgetSettings = {
    /** Interval which we'll display the data for. In this case it can be only a section */
    sectionId?: number;
    // Target ROP
    target?: number;
};

export const trippingSpeedDefaultSettings: TrippingSpeedWidgetSettings = {};

export type CasingRunningWidgetSettings = {
    /** Interval which we'll display the data for. In this case it can be only a section */
    sectionId?: number;
    // Target ROP
    target?: number;
    // Units for distance
    distanceUnitId: WellKnownUnitIds;
};

export const casingRunningDefaultSettings: Required<Pick<CasingRunningWidgetSettings, 'distanceUnitId'>> & Omit<CasingRunningWidgetSettings, 'distanceUnitId'> = {
    distanceUnitId: WellKnownUnitIds.Meter
};

export type RateOfAdvanceWidgetSettings = {
    /** Time interval for each column (in milliseconds) */
    aggregationTimeSpan: number;
    /** Units for distance display */
    distanceUnitId?: string;
};

export const rateOfAdvanceDefaultSettings: Required<RateOfAdvanceWidgetSettings> = {
    aggregationTimeSpan: 24 * 3600 * 1000,
    distanceUnitId: WellKnownUnitIds.Meter
};

export type RopWidgetSettings = {
    /** Interval which we'll display the data for. In this case it can be only a section */
    sectionId?: number;
    includeParentSection?: boolean;
    /** Min & Max depth */
    minDepth?: number;
    maxDepth?: number;
    /** Target ROP */
    target?: number;
    /** Units for ROP display */
    ropUnitId: WellKnownUnitIds;
    /** Units for depth display */
    depthUnitId: WellKnownUnitIds;
};

export const ropDefaultSettings: RopWidgetSettings = {
    ropUnitId: WellKnownUnitIds.MeterPerSecond,
    depthUnitId: WellKnownUnitIds.Meter
};

export type CustomVsTimeWidgetSettings = {
    /** Interval which we'll display the data for */
    timeRange?: WidgetSettingsTimeRange;

    tag1?: WellKnownParams;
    tag1UnitId?: WellKnownUnitIds;
    tag1Min?: number;
    tag1Max?: number;

    tag2?: WellKnownParams;
    tag2UnitId?: WellKnownUnitIds;
    tag2Min?: number;
    tag2Max?: number;

    tag3?: WellKnownParams;
    tag3UnitId?: WellKnownUnitIds;
    tag3Min?: number;
    tag3Max?: number;
};

export const customVsTimeDefaultSettings: Required<Pick<CustomVsTimeWidgetSettings, 'timeRange'>> & Omit<CustomVsTimeWidgetSettings, 'timeRange'> = {
    timeRange: {
        type: WidgetSettingsTimeRangeType.TimeRange,
        range: widgetSettingsAllowedTimeRanges[WidgetSettingsTimeRanges.Hours_24].value
    }
};

export type WidgetSettings =
    AssetDailyMetricsWidgetSettings |
    BitBottomWidgetSettings |
    ConnectionTimeWidgetSettings |
    DaysVsDepthWidgetSettings |
    RigActivityWidgetSettings |
    RigActivityPerHourWidgetSettings |
    RigStateWidgetSettings |
    SlideRotaryWidgetSettings |
    SlideVsRotaryWidgetSettings |
    TrippingSpeedWidgetSettings |
    CasingRunningWidgetSettings |
    RateOfAdvanceWidgetSettings |
    RopWidgetSettings |
    CustomVsTimeWidgetSettings;
