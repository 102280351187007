export enum SinglePagePaths {
    Info = 'info',
    CO2 = 'co2',
    Files = 'files',
    Engineering = 'engineering',
    RiskRegister = 'risk-register',
}

export enum EngineeringPaths {
    TnD = 'tnd'
}
