import { NgClass, NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { IconComponent } from '@cyberloop/core';

/** Options interface */
export type ClDropdownOptions = {
    /** Key (id) */
    id: number | string,
    /** Text */
    name: string
}

/** Custom dropdown component */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cl-dropdown',
    standalone: true,
    imports: [
        MatMenuModule,
        IconComponent,
        NgForOf,
        NgClass
    ],
    templateUrl: './cl-dropdown.component.html',
    styleUrls: ['./cl-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClDropdownComponent {
    /** private field */
    private _isOpen = false;

    /** current value will be selected */
    @Input() current?: string | number | null = '';
    /** items for dropdown menu */
    @Input() items?: ClDropdownOptions[] | null = [];
    /** return value to parent */
    @Output() readonly currentChange = new EventEmitter<ClDropdownOptions>();
    /** @internal */
    get isOpen() {
        return this._isOpen;
    }

    /** @internal to open dropdown*/
    public onOpen(value: boolean) {
        this._isOpen = value;
    }
    /** @internal to select item*/
    public onSelect(item: ClDropdownOptions) {
        this.currentChange.emit(item);
    }
}
