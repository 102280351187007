import { AsyncPipe, NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';

import { ClInputComponent, ClSelectorOptions, IconComponent } from '@cyberloop/core';
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, map, of, startWith } from 'rxjs';

@Component({
    selector: 'cyberloop-workspaces-popup--list',
    standalone: true,
    imports: [
        ClInputComponent,
        IconComponent,
        MatButtonModule,
        MatRippleModule,
        NgForOf,
        AsyncPipe
    ],
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListOfWorkspacesComponent {
    private readonly _optionsSubj = new BehaviorSubject<ClSelectorOptions[]>([]);
    private readonly _searchSubj = new BehaviorSubject<string>('');

    constructor() {
        this.options$ = combineLatest([this._optionsSubj, this._searchSubj.pipe(
            debounceTime(100),
            map(x => x.trim()),
            distinctUntilChanged()
        )]).pipe(
            map(([sub, val]) => val
                ? sub.filter(y => y.name.toString().toLocaleLowerCase().includes(val as string) || y.id.toString().toLocaleLowerCase().includes(val as string))
                : sub
            ),
            map(x => x || []),
            startWith(this._optionsSubj.value)
        );
    }

    @Input() set options(value: ClSelectorOptions[] | null) {
        if (!value) {
            return;
        }

        this._optionsSubj.next(value);
    }

    @Input() selected: string | null = null;
    @Input() canDelete: boolean | null = null;

    @Output() selectItem = new EventEmitter<ClSelectorOptions>();
    @Output() editItem = new EventEmitter<ClSelectorOptions>();
    @Output() deleteItem = new EventEmitter<ClSelectorOptions>();

    public options$: Observable<ClSelectorOptions[]> = of([]);

    public isSelected(value: ClSelectorOptions) {
        return this.selected === value.id;
    }

    public onSelectItem(event: ClSelectorOptions) {
        this.selectItem.emit(event);
    }

    public onEdit(event: ClSelectorOptions) {
        this.editItem.emit(event);
    }

    public onDelete(event: ClSelectorOptions) {
        this.deleteItem.emit(event);
    }

}