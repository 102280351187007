import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { first } from 'rxjs';

import { ConfirmationDialogComponent, DialogData } from '../components/confirmation-dialog/confirmation-dialog.component';

/** Confirm any operation you need */
@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {
    /** @private  @internal */
    private _dialogRef: MatDialogRef<ConfirmationDialogComponent> | undefined;

    /** @private @internal */
    constructor(private readonly dialog: MatDialog) { }

    /**
     * Show confirmation dialog
     * @param text Text of confirmation
     * @param title Title of the dialog
     * @param opts Some more options
     * @returns Promise that resolves with true if 'YES' was chosen and false - otherwise
     */
    show(
        text?: string,
        title?: string,
        opts?: {
            /** Text to be displayed on YES button */
            yesText?: string,
            /** Text to be displayed on NO button */
            noText?: string,
            /** timeout to automatically close confirmation dialog */
            timoutSec?: number,
            /** Default value to be highlighted, focused and used for autoclose. @default false */
            defaultValue?: boolean
        }
    ): Promise<boolean> {
        return new Promise((resolve) => {
            const data: DialogData = { 
                text, 
                title, 
                yesText: opts?.yesText, 
                noText: opts?.noText, 
                timoutSec: opts?.timoutSec,
                defaultValue: opts?.defaultValue 
            };

            this._dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data,
                hasBackdrop: true,
                maxWidth: '80vw',
                minWidth: '20vw',
                exitAnimationDuration: 600,
                panelClass: ['animate__animated', 'animate__fadeInDown', 'animate__faster']
            });

            this._dialogRef.beforeClosed().pipe(first()).subscribe(() => {
                const elem = document.getElementsByClassName("animate__animated")[0];
                elem.classList.remove("animate__fadeInDown");
                elem.classList.add("animate__fadeOutUp");
            });

            this._dialogRef.afterClosed().subscribe((result) => {
                resolve(result);
            });

        });
    }

    /** Close confirmation dialog */
    forceClose() {
        this._dialogRef?.close();
    }
}
