import { createReducer, on } from '@ngrx/store';
import { isNil } from 'lodash';

import { DirectionalDrillingActions } from './dd.actions';
import { DDState, getDefaultDirectionalDrillingState } from './dd.state';

export const directionalDrillingReducer = createReducer<DDState>(
    getDefaultDirectionalDrillingState(),

    on(DirectionalDrillingActions.setSelectedSections, (state, { wellID, sections }) => {
        const newSections = {...state.sections};
        if(isNil(sections)) {
            delete newSections[wellID ?? ''];    
        } 
        else {
            newSections[wellID ?? ''] = sections;
        }

        return { ...state, sections: newSections };
    }),
    on(DirectionalDrillingActions.setWellPlan, (state, { wellID, wellPlanId }) => {
        const newWellPlans = {...state.wellPlanId};
        if(isNil(wellPlanId)) {
            delete newWellPlans[wellID ?? ''];    
        } 
        else {
            newWellPlans[wellID ?? ''] = wellPlanId;
        }

        return { ...state, wellPlanId: newWellPlans };
    })
);

