import { OverlayModule } from '@angular/cdk/overlay';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as moment from 'moment';

import { DatetimePipe } from '../../pipes';
import { CalendarComponent } from '../calendar/calendar.component';
import { IconComponent } from '../icon/icon.component';

/** @internal */
type Value = moment.Moment | null;
/** @internal */
type ChangeMethod = (x: Value) => void;
/** @internal */
type TouchMethod = () => void;

/**
 * Angular component representing a datepicker.
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cl-datepicker',
    standalone: true,
    imports: [
        NgIf,
        OverlayModule,
        DatetimePipe,
        IconComponent,
        CalendarComponent
    ],
    templateUrl: './cl-datepicker.component.html',
    styleUrls: ['./cl-datepicker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ClDatepickerComponent), multi: true }
    ]
})
export class ClDatepickerComponent implements ControlValueAccessor {
    /** @private */
    private _registerOnChange: ChangeMethod | undefined;
    /** @private */
    private _onTouched: TouchMethod | undefined;

    /** Placeholder text */
    @Input() placeholder = 'Pick';

    /** Date format */
    @Input() format = 'dd.MM.YYYY';

    /** @internal */
    value: Value = null;
    /** @internal */
    calendarVisible = false;
    /** @internal */
    isDisabled = false;

    /** @internal */
    onClick() {
        if (this.isDisabled) {
            return;
        }

        this.calendarVisible = !this.calendarVisible;
    }

    /** @internal */
    dateChange(date: Value) {
        this.value = date;
        this.calendarVisible = false;
        this._onTouched?.();
        this._registerOnChange?.(this.value);
    }

    /** @internal */
    writeValue(value: Value): void {
        this.value = value;
    }

    /** @internal */
    registerOnChange(fn: ChangeMethod): void {
        this._registerOnChange = fn;
    }

    /** @internal */
    registerOnTouched(fn: TouchMethod): void {
        this._onTouched = fn;
    }

    /** @internal */
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
