import { createReducer, on } from '@ngrx/store';

import { UnitsActions } from './units.actions';
import { UnitsState, getDefaultUnitsState } from './units.state';

/** @internal */
export const unitsReducer = createReducer<UnitsState>(getDefaultUnitsState(),
    on(UnitsActions.setUnitGroups, (state, { groups }) => ({
        ...state,
        groups
    })),
    on(UnitsActions.setUnitsPresets, (state, { presets }) => ({
        ...state,
        presets
    })),
    on(UnitsActions.destroyUserUnitPreset, (state) => ({
        ...state,
        userPreset: undefined,
        userPresetLoadingState: 'NOT_LOADED'
    })),
    on(UnitsActions.setUserUnitsPreset, (state, { preset }) => ({
        ...state,
        userPreset: preset,
        userPresetLoadingState: 'LOADED'
    })),
    on(UnitsActions.onStartLoading, (state) => ({
        ...state,
        userPreset: undefined,
        userPresetLoadingState: 'LOADING'
    }))
);

