import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { isNil } from 'lodash';

import { ClInputComponent, FormErrorsComponent, IconComponent, PseudoLoader } from '../../../components';
import { SimpleChangesOf } from '../../../models';
import { removeFormControlError } from '../../../utils';

enum Controls {
    newPassword = 'newPassword',
    newPassword2 = 'newPassword2',
}

/** @internal */
@Component({
    selector: 'cyberloop-set-new-password',
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        ClInputComponent,
        FormErrorsComponent,
        PseudoLoader,
        IconComponent
    ],
    templateUrl: './set-new-password.component.html',
    styleUrls: ['./set-new-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetNewPasswordUIComponent implements OnChanges {
    constructor(private readonly fb: FormBuilder) { }

    @Input() loading: boolean | null = false;
    @Input() error?: string | null;
    @Output() readonly trySetNewPassword = new EventEmitter<string>();
    @Output() readonly backToLogin = new EventEmitter();

    readonly Controls = Controls;

    readonly form = this.fb.group({
        [Controls.newPassword]: ['', [Validators.required]],
        [Controls.newPassword2]: ['', [Validators.required]],
    }, {
        updateOn: 'submit',
        validators: [this.newPasswordIsEqualValidator(), this.minLengthValidator()]
    });

    get newPasswordControl() {
        return this.form?.get(Controls.newPassword) as FormControl;
    }

    get newPassword2Control() {
        return this.form?.get(Controls.newPassword2) as FormControl;
    }

    ngOnChanges(changes: SimpleChangesOf<SetNewPasswordUIComponent>): void {
        const loadingChanged = changes.loading;
        if (loadingChanged) {
            loadingChanged.currentValue ? this.form.disable() : this.form.enable();
        }
    }

    onSubmit() {
        if (this.loading) {
            return;
        }

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.trySetNewPassword.emit(this.newPasswordControl.value);
    }

    @HostListener('document:keydown.escape', ['$event'])
    resetForm() {
        if (this.form.enabled) {
            this.form.reset();
        }
    }

    private newPasswordIsEqualValidator(): ValidatorFn {
        return (): ValidationErrors | null => {
            if (isNil(this.form)) {
                return null;
            }

            const { newPassword, newPassword2 } = this.form.value;

            if (isNil(newPassword) || isNil(newPassword2)) {
                return null
            }

            const isEqual = newPassword === newPassword2;
            const errorName = 'newPasswordNotEquals';

            if (isEqual) {
                removeFormControlError(this.newPasswordControl!, errorName)
                removeFormControlError(this.newPassword2Control!, errorName)

                return null;
            }

            const error = { [errorName]: `Passwords don't match` };

            this.newPasswordControl?.setErrors({
                ...this.newPasswordControl.errors,
                ...error
            });

            this.newPassword2Control?.setErrors({
                ...this.newPassword2Control.errors,
                ...error
            });

            return error;
        };
    }

    private minLengthValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const { newPassword, newPassword2 } = control.value;

            if (isNil(newPassword) || isNil(newPassword2)) {
                return null
            }

            const length = 6;
            const invalid = newPassword.length < length || newPassword2 < length;
            const errorName = 'weakPassword';

            if (invalid) {
                const error = { [errorName]: `Password should be at least ${length} characters` };

                this.newPasswordControl?.setErrors({
                    ...this.newPasswordControl.errors,
                    ...error
                });

                this.newPassword2Control?.setErrors({
                    ...this.newPassword2Control.errors,
                    ...error
                });

                return error;
            }

            removeFormControlError(this.newPasswordControl!, errorName);
            removeFormControlError(this.newPassword2Control!, errorName);
            return null;
        };
    }
}
