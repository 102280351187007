import { Injectable } from '@angular/core';

import { SettingsProviderService, uuid } from '@cyberloop/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, first, map, switchMap } from 'rxjs';

import { KpiActions } from './kpi.actions';
import { KpiSelectors } from './kpi.selectors';

import type { Widget } from '@cyberloop/web/wells/model';

@Injectable()
export class KpiWidgetsEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly settings: SettingsProviderService
    ) { }

    /**
     * When a widget added, we'll dispatch updateSettings action with the new
     * widget appended to the settings.
     */
    readonly onAddWidget$ = createEffect(() => this.actions$.pipe(
        ofType(KpiActions.addWidget),

        switchMap(({ widget }) => {
            return this.store.select(KpiSelectors.activeDashboard).pipe(
                first(),
                switchMap((tadId) => {
                    const newWidget: Widget = { ...widget, id: widget.id ?? uuid(), tadId };

                    return this.store.select(KpiSelectors.widgets).pipe(
                        first(),
                        map(widgets => {
                            const lastWidgets = widgets ?? [];
                            return KpiActions.updateWidgets({ widgets: [...lastWidgets, newWidget] });
                        })
                    );
                })
            );
        })
    ));

    /**
     * When a widget gets deleted, we'll delete it from the widgets list and
     * dispatch updateSettings action with the updated settings object.
     */
    readonly onDeleteWidget$ = createEffect(() => this.actions$.pipe(
        ofType(KpiActions.deleteWidget),

        switchMap(({ widgetId }) => {
            return this.store.select(KpiSelectors.widgets).pipe(
                first(),
                switchMap(widgets => {
                    const lastWidgets = widgets ? [...widgets] : [];

                    if (!lastWidgets.length) {
                        return [];
                    }

                    const widgetIndex = lastWidgets.findIndex(item => item.id === widgetId);

                    if (widgetIndex < 0) {
                        return [];
                    }

                    // Delete it
                    lastWidgets.splice(widgetIndex, 1);

                    return [
                        KpiActions.updateWidgets({ widgets: lastWidgets }),
                        KpiActions.deleteWidgetSettings({ widgetId })
                    ];
                })
            );
        })
    ));

    readonly onDeleteWidgets$ = createEffect(() => this.actions$.pipe(
        ofType(KpiActions.deleteWidgets),

        switchMap(({ widgetsIds }) => {
            return this.store.select(KpiSelectors.widgets).pipe(
                first(),
                switchMap(widgets => {
                    let lastWidgets = widgets ? [...widgets] : [];

                    if (!lastWidgets.length) {
                        return [];
                    }

                    lastWidgets = lastWidgets.filter(item => !widgetsIds.includes(item.id));

                    return this.store.select(KpiSelectors.allWidgetsSettings).pipe(
                        first(),
                        switchMap((settings) => {
                            const newSettings = { ...settings };
                            for (const widget of widgetsIds) {
                                delete newSettings[widget];
                            }

                            return [
                                KpiActions.setAllWidgetsSettings({ settings: newSettings })
                            ];
                        })
                    );
                })
            );
        })
    ));

    /**
     * When updateWidgets action fires, we'll put new widgets to the store and
     * will try to save the settings. In case of some error we'll roll back
     * the previous settings to the store.
     */
    readonly onUpdateWidgets$ = createEffect(() => this.actions$.pipe(
        ofType(KpiActions.updateWidgets),

        switchMap(({ widgets: newWidgets }) => {
            return this.store.select(KpiSelectors.widgets).pipe(
                first(),
                switchMap(() => ([KpiActions.setWidgets({ widgets: newWidgets })]))
            );
        })
    ));

    /**
     * When settings of a widget changes, we'll dispatch setWidgetSettings
     * action with the new widget settings.
     */
    readonly onUpdateWidgetSettings$ = createEffect(() => this.actions$.pipe(
        ofType(KpiActions.updateWidgetSettings),

        filter(({ widgetId, settings }) => Boolean(widgetId) && Boolean(settings)),

        switchMap(({ widgetId, settings }) => {
            return [
                KpiActions.setWidgetSettings({ widgetId, settings })
            ];       
        })
    ));

    /**
     * When settings of a widget are being deleted, we'll dispatch
     * setAllWidgetsSettings action with all widgets settings except the
     * deleted one's.
     */
    readonly onDeleteWidgetSettings$ = createEffect(() => this.actions$.pipe(
        ofType(KpiActions.deleteWidgetSettings),

        switchMap(({ widgetId }) => {
            return this.store.select(KpiSelectors.allWidgetsSettings).pipe(
                first(),

                switchMap(lastWidgetSettings => {
                    const newSettings = { ...lastWidgetSettings };
                    delete newSettings[widgetId];

                    return [
                        KpiActions.setAllWidgetsSettings({ settings: newSettings })
                    ];
                })
            );
        })
    ));
}