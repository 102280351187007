import { Injectable } from '@angular/core';

import { SettingsProviderService } from '@cyberloop/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Store, createAction } from '@ngrx/store';
import { first, switchMap } from 'rxjs';

import { TndActions } from './tnd.actions';
import { TndSelectors } from './tnd.selectors';
import { PLANNING_ENGINEERING_TND_FEATURE } from './tnd.state';

import type { Action } from '@ngrx/store';

const initAction = createAction(`[${PLANNING_ENGINEERING_TND_FEATURE}] Initialize T&D`);

@Injectable()
export class TndEffects implements OnInitEffects {

    ngrxOnInitEffects(): Action {
        return initAction();
    }

}
