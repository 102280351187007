import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { RigActivity, SimpleChangesOf } from '../../models';

/**
 * Rig status component
 */
@Component({
    selector: 'cyberloop-rig-status',
    standalone: true,
    templateUrl: './rig-status.component.html',
    styleUrls: ['./rig-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RigStatusComponent implements OnInit, OnDestroy, OnChanges {

    private readonly _resize = new ResizeObserver(this.handleResize.bind(this));
    /** @private */
    private _elementWidth = 0;
    /**
     * Creates an instance of RigStatusComponent.
     * @param {ElementRef} elementRef
     * @memberof RigStatusComponent
     */
    constructor(private elementRef: ElementRef) { }

    /** @internal */
    @HostBinding('style.--_animation-delay') internalDelayValue = '0s';

    /** RigActivity status */
    @Input() activityStatus?: RigActivity | null;
    /** Text to display */
    @Input() text?: string;
    /** Delay for an animation */
    @Input() aminationDelay?: number | null;

    /** @internal */
    public animationActive = false;

    /** @internal */
    ngOnInit(): void {
        this._resize.observe(document.body);
        this.handleResize();
    }

    ngOnDestroy(): void {
        this._resize.disconnect();
    }

    /** @internal */
    ngOnChanges(changes: SimpleChangesOf<RigStatusComponent>): void {
        const delayChange = changes.aminationDelay;
        if (delayChange) {
            let value = Number(delayChange.currentValue);
            if (!Number.isFinite(value)) {
                value = 0;
            }
            else if (value < 0) {
                value = Number.MAX_SAFE_INTEGER;
            }

            value = Math.round(value);

            this.internalDelayValue = `${value}ms`;
        }

        //If the component gets new text from Input, it needs to calculate its width
        this.handleResize();
    }

    /**
     * Get id of RigActivity object
     *
     * @param {(RigActivity | null)} [state]
     * @return {*}  {string}
     * @memberof RigStatusComponent
     */
    getRigActivityId(state?: RigActivity | null): string {
        return RigActivity.getId(state ?? RigActivity.Unknown);
    }


    /** Enable animation if is needed */
    private handleResize() {
        this._elementWidth = this.elementRef.nativeElement.offsetWidth;

        const widthInChars = Math.ceil((this._elementWidth - 24 /* 24px - sum of left and right padding */) / 10);
        if (this.text && this.text.length > widthInChars) {
            this.animationActive = true;
        } else {
            this.animationActive = false;
        }
    }

}
