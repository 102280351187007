import { Injectable } from '@angular/core';

import { SettingsProviderService } from '@cyberloop/core';
import { isEqual } from 'lodash';
import { EMPTY, Observable, distinctUntilChanged, filter, map, switchMap } from 'rxjs';

import { ClApplicationManager } from './internals/client-app/cl-app-manager';

import type { UpdateData } from 'firebase/firestore';

@Injectable({
    providedIn: 'root'
})
export class FirebaseSettingsProviderLinkService extends SettingsProviderService {
    /**
     *
     */
    constructor(
        private readonly appManager: ClApplicationManager
    ) {
        super();
    }

    public watchSettings<T>(key: string, defaultSettings?: T): Observable<T> {
        return this.appManager.tenantApp$.pipe(
            switchMap(x => x?.getAuth().currentUser$ ?? EMPTY),
            filter(user => Boolean(user)),
            switchMap(user => {
                const fullPath = `/settings/users/${user?.uid}/${key}`;
                return this.appManager.getApp().getFirestore().watch<T>(fullPath).pipe(
                    switchMap(async settings => {
                        if (settings) {
                            return settings;
                        }

                        await this.appManager.getApp().getFirestore().set(fullPath, defaultSettings ?? {});
                        return null;
                    })
                );
            }),
            filter(Boolean),
            distinctUntilChanged((a, b) => isEqual(a, b)),
            map(settings => settings as T)
        );
    }

    public async updateSettings<T extends object>(key: string, data: UpdateData<T>): Promise<boolean> {
        if (!data) {
            return false;
        }

        const app = this.appManager.getApp();
        const user = app.getAuth().currentUser;
        if (!user) {
            return false;
        }

        const fullPath = `/settings/users/${user?.uid}/${key}`;
        await app.getFirestore().update(fullPath, data);
        return true;
    }
}
