import { Injectable } from '@angular/core';

import { GraphQLClient } from './client';

@Injectable()
export abstract class GraphQLCore {
    /**
     *
     */
    constructor(
        protected readonly cli: GraphQLClient
    ) {

    }

    abstract readonly document: string;
}
