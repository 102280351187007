import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Entity } from '@cyberloop/core';

@Component({
    selector: 'cyberloop-list',
    standalone: true,
    imports: [
        NgIf,
        NgFor
    ],
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent<T = string | number> {
    @Input() name?: string;
    @Input() data?: Entity<T>[] | null;
    @Input() addText?: string;
    @Input() selectedId?: T | null;
    @Input() disableActions?: boolean | null;
    @Input() readonly?: boolean | null;

    @Output() readonly selected = new EventEmitter<T>();
    @Output() readonly add = new EventEmitter();

    onSelect(id: T) {
        this.selected.emit(id);
    }

    onAdd() {
        this.add.emit();
    }
}
