import { Provider } from '@angular/core';

import { AuthService, SettingsProviderService, UnitsProviderService } from '@cyberloop/core';
import { MeterageDataService } from '@cyberloop/web/app/data';
import { FilesProviderService, FilesTagProviderService } from '@cyberloop/web/files/data';
import { PlanningProviderService, PlanningTemplateProviderService, PlanningVersionProviderService } from '@cyberloop/web/planning/shared/data';
import { DrillingExportTemplatesProvideService, DrillingWorkspacesTemplatesProvideService, ForecastProviderService } from '@cyberloop/web/wells/data';

import { FirebaseOptions } from 'firebase/app';

import { FirebaseDrillingExportTemplatesProviderLinkService } from './services/firebase-drilling-export-provider.service';
import { FirebaseDrillingWorkspacesTemplatesProviderLinkService } from './services/firebase-drilling-workspaces-provider.service';
import { FirebaseFilesProviderLinkService } from './services/firebase-files-provider.service';
import { FirebaseFilesTagProviderLinkService } from './services/firebase-files-tag-provider.service';
import { FirebaseForecastProviderLinkService } from './services/firebase-forecast-provider.service';
import { FirebaseMeterageDataService } from './services/firebase-meterage-data.service';
import { FirebasePlanningProviderLinkService } from './services/firebase-planning-provider.service';
import { FirebasePlanningTemplateProviderLinkService } from './services/firebase-planning-template-provider.service';
import { FirebasePlanningVersionProviderLinkService } from './services/firebase-planning-version-provider.service';
import { SsoAuthService } from './services/internals/sso-link';
import { SsoRootApplication } from './services/internals/sso-root/sso-root-app';
import { SsoRootFunctions } from './services/internals/sso-root/sso-root-functions';
import { FirebaseSettingsProviderLinkService } from './services/settings-provider.service';
import { UnitsProviderServiceImpl } from './services/units-provider.service.impl';
import { ROOT_FB_CONFIG_TOKEN } from './tokens';

export { AuthTokenProviderService } from './services/internals/auth-token.provider.service';

export function getFirebaseProviders(options: FirebaseOptions): Provider[] {
    return [
        { provide: ROOT_FB_CONFIG_TOKEN, useValue: options },

        { provide: AuthService, useClass: SsoAuthService },
        { provide: SettingsProviderService, useClass: FirebaseSettingsProviderLinkService },
        { provide: UnitsProviderService, useClass: UnitsProviderServiceImpl },
        { provide: PlanningProviderService, useClass: FirebasePlanningProviderLinkService },
        { provide: PlanningVersionProviderService, useClass: FirebasePlanningVersionProviderLinkService },
        { provide: PlanningTemplateProviderService, useClass: FirebasePlanningTemplateProviderLinkService },
        { provide: ForecastProviderService, useClass: FirebaseForecastProviderLinkService },
        { provide: MeterageDataService, useClass: FirebaseMeterageDataService },

        { provide: FilesProviderService, useClass: FirebaseFilesProviderLinkService },
        { provide: FilesTagProviderService, useClass: FirebaseFilesTagProviderLinkService },

        { provide: DrillingExportTemplatesProvideService, useClass: FirebaseDrillingExportTemplatesProviderLinkService },
        { provide: DrillingWorkspacesTemplatesProvideService, useClass: FirebaseDrillingWorkspacesTemplatesProviderLinkService },

        SsoRootFunctions,
        SsoRootApplication
    ];
}
