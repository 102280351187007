import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ClInputComponent } from '@cyberloop/core';

@Component({
    selector: 'cyberloop-toolbar-skeleton',
    standalone: true,
    imports: [
        ClInputComponent
    ],
    templateUrl: './toolbar-skeleton.component.html',
    styleUrls: ['./toolbar-skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarSkeletonComponent {

}
