import { createReducer, on } from '@ngrx/store';

import { ForecastActions } from './forecast.actions';
import { ForecastState, getDefaultForecastState } from './forecast.state';

export const forecastReducer = createReducer<ForecastState>(getDefaultForecastState(),
    //#region Forecast
    on(ForecastActions.loadForecast, (state) => ({
        ...state,
        forecastLoadingState: 'LOADING',
        forecastError: undefined,
        eventList: [],
        listEventLoadingState: 'NOT_LOADED',
        equipmentAndPersonnelEventList: [],
        equipmentAndPersonnelEventListLoadingState: 'NOT_LOADED'
    })),
    on(ForecastActions.loadForecastSuccess, (state, { forecast }) => (
        {
            ...state,
            forecast,
            forecastLoadingState: 'LOADED'
        })
    ),
    on(ForecastActions.loadForecastFailure, (state, { error }) => ({
        ...state,
        forecastLoadingState: 'NOT_LOADED',
        forecastError: error
    })),
    //#endregion Forecast

    //#region Event

    //#region List
    on(ForecastActions.loadEventList, (state) => ({
        ...state,
        listEventLoadingState: 'LOADING',
        listEventError: undefined
    })),
    on(ForecastActions.loadEventListSuccess, (state, { eventList }) => (
        {
            ...state,
            eventList,
            listEventLoadingState: 'LOADED'
        })
    ),
    on(ForecastActions.loadEventListFailure, (state, { error }) => ({
        ...state,
        listEventLoadingState: 'NOT_LOADED',
        listEventError: error
    })),
    //#endregion List

    //#region Create
    on(ForecastActions.createEvent, (state) => ({
        ...state,
        createEventLoadingState: 'LOADING',
        createEventError: undefined
    })),
    on(ForecastActions.createEventSuccess, (state) => (
        {
            ...state,
            createEventLoadingState: 'LOADED'
        })
    ),
    on(ForecastActions.createEventFailure, (state, { error }) => ({
        ...state,
        createEventLoadingState: 'NOT_LOADED',
        createEventError: error
    })),
    //#endregion Create

    //#region Update
    on(ForecastActions.updateEvent, (state) => ({
        ...state,
        updateEventLoadingState: 'LOADING',
        updateEventError: undefined
    })),
    on(ForecastActions.updateEventSuccess, (state) => (
        {
            ...state,
            updateEventLoadingState: 'LOADED'
        })
    ),
    on(ForecastActions.updateEventFailure, (state, { error }) => ({
        ...state,
        updateEventLoadingState: 'NOT_LOADED',
        updateEventError: error
    })),
    //#endregion Update

    //#region Expand to next
    on(ForecastActions.expandToNextEvent, (state) => ({
        ...state,
        expandToNextEventLoadingState: 'LOADING',
        expandToNextEventError: undefined
    })),
    on(ForecastActions.expandToNextEventSuccess, (state) => (
        {
            ...state,
            expandToNextEventLoadingState: 'LOADED'
        })
    ),
    on(ForecastActions.expandToNextEventFailure, (state, { error }) => ({
        ...state,
        expandToNextEventLoadingState: 'NOT_LOADED',
        expandToNextEventError: error
    })),
    //#endregion Expand to next

    //#region Delete
    on(ForecastActions.deleteEvent, (state) => ({
        ...state,
        deleteEventLoadingState: 'LOADING',
        deleteEventError: undefined
    })),
    on(ForecastActions.deleteEventSuccess, (state) => (
        {
            ...state,
            deleteEventLoadingState: 'LOADED'
        })
    ),
    on(ForecastActions.deleteEventFailure, (state, { error }) => ({
        ...state,
        deleteEventLoadingState: 'NOT_LOADED',
        deleteEventError: error
    })),
    //#endregion Delete

    on(ForecastActions.setEditableEventId, (state, { eventId: id }) => ({
        ...state,
        editableEventId: id
    })),
    //#endregion Event


    //#region Import from Well plan
    on(ForecastActions.importFromWellPlan, (state) => ({
        ...state,
        importFromWellPlanLoadingState: 'LOADING',
        importFromWellPlanError: undefined
    })),
    on(ForecastActions.importFromWellPlanSuccess, (state, { forecast }) => ({
        ...state,
        forecast,
        importFromWellPlanLoadingState: 'LOADED'
    })),
    on(ForecastActions.importFromWellPlanFailure, (state, { error }) => ({
        ...state,
        importFromWellPlanLoadingState: 'NOT_LOADED',
        importFromWellPlanError: error
    })),
    //#endregion Import from Well plan

    //#region Export
    on(ForecastActions.export, (state) => ({
        ...state,
        exportLoadingState: 'LOADING',
        exportError: undefined
    })),
    on(ForecastActions.exportSuccess, (state) => ({
        ...state,
        exportLoadingState: 'LOADED'
    })),
    on(ForecastActions.exportFailure, (state, { error }) => ({
        ...state,
        exportLoadingState: 'NOT_LOADED',
        exportError: error
    })),
    //#endregion Export

    //#region Equipment and Personnel

    //#region List
    on(ForecastActions.loadEquipmentAndPersonnelEventList, (state) => ({
        ...state,
        equipmentAndPersonnelEventListLoadingState: 'LOADING',
        equipmentAndPersonnelEventListError: undefined
    })),
    on(ForecastActions.loadEquipmentAndPersonnelEventListSuccess, (state, { equipmentAndPersonnelEventList }) => (
        {
            ...state,
            equipmentAndPersonnelEventList,
            equipmentAndPersonnelEventListLoadingState: 'LOADED'
        })
    ),
    on(ForecastActions.loadEquipmentAndPersonnelEventListFailure, (state, { error }) => ({
        ...state,
        equipmentAndPersonnelEventListLoadingState: 'NOT_LOADED',
        equipmentAndPersonnelEventListError: error
    })),
    //#endregion List

    //#region Create
    on(ForecastActions.createEquipmentAndPersonnelEvent, (state) => ({
        ...state,
        createEquipmentAndPersonnelEventLoadingState: 'LOADING',
        createEquipmentAndPersonnelEventError: undefined
    })),
    on(ForecastActions.createEquipmentAndPersonnelEventSuccess, (state) => (
        {
            ...state,
            createEquipmentAndPersonnelEventLoadingState: 'LOADED'
        })
    ),
    on(ForecastActions.createEquipmentAndPersonnelEventFailure, (state, { error }) => ({
        ...state,
        createEquipmentAndPersonnelEventLoadingState: 'NOT_LOADED',
        createEquipmentAndPersonnelEventError: error
    })),
    //#endregion Create

    //#region Update
    on(ForecastActions.updateEquipmentAndPersonnelEvent, (state) => ({
        ...state,
        updateEquipmentAndPersonnelEventLoadingState: 'LOADING',
        updateEquipmentAndPersonnelEventError: undefined
    })),
    on(ForecastActions.updateEquipmentAndPersonnelEventSuccess, (state) => (
        {
            ...state,
            updateEquipmentAndPersonnelEventLoadingState: 'LOADED'
        })
    ),
    on(ForecastActions.updateEquipmentAndPersonnelEventFailure, (state, { error }) => ({
        ...state,
        updateEquipmentAndPersonnelEventLoadingState: 'NOT_LOADED',
        updateEquipmentAndPersonnelEventError: error
    }))
    //#endregion Update

    //#endregion Equipment and Personnel
);

