import { Injectable } from '@angular/core';

import { Point, Points } from '@cyberloop/core';
import { isNil } from 'lodash';

/** This service helps to compress number of points to a smaller amount of points with keeping significant ones available */
@Injectable({
    providedIn: 'root'
})
export class DataCompressorService {
    /**
     * Process the input data points and return an array of checkpoints and local extremes.
     * @param data - An array of input data points.
     * @param maxPoints - The desired maximum number of points (default: 1500).
     * @returns An array of processed data points.
     */
    public processPoints(data: Points, maxPoints: number = 1500): Points {
        if (data.length < maxPoints) {
            return [...data];
        }

        const di = Math.max(Math.ceil(data.length / (maxPoints)), 1);
        if (di === 1) {
            return [...data];
        }

        const points = new Array<Point>(maxPoints);
        let i = 0;
        const l = data.length - 1;
        for (let x = 0; x <= l; x += di, i++) {
            points[i] = data[x];

            if (x < l) {
                for (const extreme of this.findPointsToAdd(data, x, x + di - 1)) {
                    points[++i] = extreme;
                }
            }
            else {
                return points.filter(Boolean);
            }
        }

        const lastPoint = data[data.length - 1];
        if (lastPoint !== points[i]) {
            points[i] = lastPoint;
        }

        return points.filter(Boolean);
    }

    private findPointsToAdd(data: Points, startIndex: number, endIndex: number): Point[] {
        const base = data[startIndex].y;
        const baseAt = data[startIndex].x;

        let min = base ?? Number.MAX_VALUE;
        let max = base ?? Number.MIN_VALUE;

        let maxAt = baseAt;
        let minAt = baseAt;

        endIndex = Math.min(endIndex, data.length - 1);

        for (let i = startIndex; i <= endIndex; i++) {
            if (!data[i]) {
                break;
            }

            const v = data[i].y;
            if (isNil(v)) {
                continue;
            }

            if (v < min) {
                min = v;
                minAt = data[i].x;
            }

            if (v > max) {
                max = v;
                maxAt = data[i].x;
            }
        }


        const points: Point[] = [];
        if (maxAt !== baseAt) {
            points.push({ x: maxAt, y: max });
        }

        if (minAt !== baseAt) {
            if (points.length) {
                if (maxAt !== minAt) {
                    if (maxAt < minAt) {
                        points.push({ x: minAt, y: min });
                    }
                    else {
                        points.unshift({ x: minAt, y: min });
                    }
                }
            }
            else {
                points.push({ x: minAt, y: min });
            }
        }

        return points;
    }
}