<h3 class="name">{{ name }}</h3>

<div class="items">
    <button *ngFor="let item of data" (click)="onSelect(item.id)" [class.active]="item.id === selectedId" class="item">
        <div class="text">{{ item.name }}</div>
    </button>
</div>

<button *ngIf="addText && !readonly" (click)="onAdd()" [disabled]="disableActions" class="item add">
    {{ addText }}
</button>
