import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../internals/cl-gql';

type TagDataQueryDto = {
    well: {
        depthHistory: {
            tagName: string;
            intervals: {
                x: number,
                y: number | null
            }[]
        }[]
    }
}

type TagDepthDataQueryVariablesDto = {
    wellId: string;
    sectionNo: number;
    from: number;
    to: number;
    step: number;
    tags: string[];
}

@Injectable({
    providedIn: 'root'
})
export class TagDepthDataQuery extends GraphQLQuery<TagDataQueryDto, TagDepthDataQueryVariablesDto>{
    override readonly document = `query tagTimeData($wellId: ID!, $sectionNo: Int!, $from: Float!, $to: Float!, $step: Float!, $tags: [TagName!]!) {
    well(id: $wellId) {
        ...on Well {
            depthHistory(sectionNo: $sectionNo, from: $from, to: $to, step: $step, only: $tags) {
                tagName
                intervals {
                    x:startDepth
                    y:startValue
                }
            }
        }
    }
}`;
}