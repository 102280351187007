import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';

import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { sortBy } from 'lodash';
import { EMPTY, catchError, delay, firstValueFrom, from, map, switchMap, tap } from 'rxjs';

import { AuthRoutes } from '../../auth/routing';
import { AuthService, NotificationService } from '../../services';
import { AuthActions } from './auth.actions';
import { AuthSelectors } from './auth.selectors';
import { AUTH_FEATURE } from './auth.state';

/** @internal */
const initAction = createAction(`[${AUTH_FEATURE}] Initialize auth`);

/** @internal */
@Injectable({
    providedIn: 'root'
})
export class AuthEffects implements OnInitEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly authService: AuthService,
        private readonly store: Store,
        private readonly router: Router,
        private readonly notificationService: NotificationService,
        private readonly location: Location,
        @Inject(DOCUMENT) private document: Document
    ) { }

    readonly onInitAction$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(async () => {
            const user = await this.authService.tryAutologin();

            if (user) {
                // this must be thrown as soon as possible
                this.store.dispatch(AuthActions.setUser({ user }));
            }
        }),
        map(() => AuthActions.initialized())
    ));

    readonly onTryLogin$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.tryLogin),
        switchMap(req => from(this.authService.login(req.login, req.password, req.tenantId)).pipe(
            catchError((err) => {
                if (err instanceof Error) {
                    this.store.dispatch(AuthActions.setLoginError({ err: err.message }));
                }
                else {
                    this.store.dispatch(AuthActions.setLoginError({ err }));
                }

                return EMPTY;
            })
        )),
        map(x => {
            if (!x) {
                return AuthActions.logout();
            }
            if (Array.isArray(x)) {
                const tenants = sortBy(x, x => x.name.toLowerCase().trimStart()[0]);
                return AuthActions.setListOfTenants({ tenants });
            }

            return AuthActions.setUser({ user: x });
        })
    ));

    readonly watchurrentUser$ = createEffect(() => this.authService.currentUser$.pipe(
        delay(0),
        switchMap(async user => {
            const existing = await firstValueFrom(this.store.select(AuthSelectors.currentUser));

            return { user, existing };
        }),
        switchMap(({ user, existing }) => {
            if (existing?.uid === user?.uid) {
                return EMPTY;
            }

            if (!user) {
                return [AuthActions.logout()];
            }

            return [AuthActions.setUser({ user })];
        })
    ));

    readonly onUserLoggedIn$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.setUser),
        switchMap(async () => {
            const returnUrl = this.router.routerState.snapshot.root.queryParams['q'];
            if (!returnUrl ||
                !await this.router.navigateByUrl(returnUrl)) {
                if (this.router.routerState.snapshot.url !== '') {
                    await this.router.navigateByUrl('/');
                }
            }
        })
    ), { dispatch: false });

    readonly onUserLoggedOut$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.logout),
        switchMap(() => this.authService.logout()),
        tap(() => {
            const url = this.router.routerState.snapshot.url;
            if (url.startsWith(`/${AuthRoutes.Root}`)) {
                return;
            }

            this.navigateToLoginPage({
                q: url
            })
        })
    ), { dispatch: false });

    //#region Load tenant list
    readonly loadTenantList$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.loadTenantList),
        switchMap(({ login }) => from(this.authService.getTenantList(login)).pipe(
            map((listOfTenants) => {
                listOfTenants = sortBy(listOfTenants, x => x.name.toLowerCase().trimStart()[0]);
                return AuthActions.loadTenantListSuccess({ listOfTenants })
            }),
            catchError(error => [AuthActions.loadTenantListFailure({ error })])
        ))
    ));
    //#endregion Load tenant list

    //#region Restore password
    readonly tryRestorePassword$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.tryRestorePassword),
        map(({ login, tenantId }) => AuthActions.restorePassword({ login, tenantId }))
    ));

    readonly restorePassword$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.restorePassword),
        switchMap(({ login, tenantId }) => from(this.authService.sendPasswordResetEmailByLoginAndTenantId(login, tenantId, this.getResetPasswordLink())).pipe(
            map(() => AuthActions.restorePasswordSuccess()),
            catchError(error => [AuthActions.restorePasswordFailure({ error })])
        ))
    ));

    readonly restorePasswordSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.restorePasswordSuccess),
        switchMap(() => this.router.navigate([AuthRoutes.Root, AuthRoutes.PasswordResetRequested]))
    ), { dispatch: false });
    //#endregion Restore password

    //#region Set new password
    readonly trySetNewPassword$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.trySetNewPassword),
        map(({ code, password }) => AuthActions.setNewPassword({ code, password }))
    ));

    readonly setNewPassword$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.setNewPassword),
        switchMap(({ code, password }) => from(this.authService.confirmPasswordReset(code, password)).pipe(
            map(() => AuthActions.setNewPasswordSuccess()),
            catchError(error => [AuthActions.setNewPasswordFailure({ error })])
        ))
    ));

    readonly setNewPasswordSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.setNewPasswordSuccess),
        tap(() => this.notificationService.info('Password changed successfully')),
        switchMap(() => this.navigateToLoginPage())
    ), { dispatch: false });
    //#endregion Set new password

    //#region Common
    readonly handleError$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthActions.loadTenantListFailure,
            AuthActions.restorePasswordFailure,
            AuthActions.setNewPasswordFailure
        ),
        tap(({ error }) => this.notificationService.error(error))
    ), { dispatch: false });
    //#endregion Common

    ngrxOnInitEffects(): Action {
        return initAction();
    }

    private async navigateToLoginPage(queryParams?: Params | null) {
        return await this.router.navigate([AuthRoutes.Root, AuthRoutes.SignIn], {
            queryParams
        });
    }

    private getResetPasswordLink() {
        return [this.document.location.origin, AuthRoutes.Root, AuthRoutes.ChangePassword].join('/');
    }
}
