import { Injectable } from '@angular/core';

import { Observable, delay, of } from 'rxjs';

import type { NavigationItem } from '@cyberloop/web/app/model';

const APPS_MOCKUP: NavigationItem[] = [
    { text: 'Dashboard', link: '/dashboard', icon: 'dashboard' },
    { text: 'Wells', link: '/wells', icon: 'wells' },
    { text: 'Planning', link: '/planning', icon: 'planning' },
    { text: 'Rigs', link: '/rigs', icon: 'rigs' },
    { text: 'Assets', link: '/assets', icon: 'assets' },
    { text: 'Gantt Chart', link: '/gantt-chart', icon: 'gantt-chart' },
    { text: 'Meterage', link: '/meterage', icon: 'meterage' }
];

@Injectable({
    // This should be the Singleton
    providedIn: 'root'
})
export class UserAppService {

    /**
     * Provides apps available for the current user as `NavigationItem`s
     */
    getApps(): Observable<NavigationItem[]> {
        // TODO: Fetch apps
        return of(APPS_MOCKUP).pipe(
            delay(1500)
        );
    }

}
