import { ID, Rig } from '@cyberloop/core';
import { createAction, props } from '@ngrx/store';

import { WELLS_FEATURE } from './wells.state';

const setRigs = createAction(`[${WELLS_FEATURE}] Set rigs`, props<{ rigs: Rig[] }>());
const openSetPlanPopup = createAction(`[${WELLS_FEATURE}] Open set plan popup`);
const setPlan = createAction(`[${WELLS_FEATURE}] Set plan`, props<{ planningId: ID }>());

/**
 * Public actions for wells
 * */
export const PublicWellsActions = {
    openSetPlanPopup
};

/**
 * @internal
 * Actions for wells used internally
 * */
export const WellsActions = {
    ...PublicWellsActions,
    setRigs,
    setPlan
};
