import { Injectable } from '@angular/core';

import { Point, Points, Section } from '@cyberloop/core';
import { ChartType, Survey, SurveyFields, Toolface, WellPlan, WellPlanDataFields } from '@cyberloop/web/wells/model';
import { Observable, map, of, startWith, switchMap } from 'rxjs';

import { SurveyDataProviderSerivce } from './survey-data-provider.service';
import { TFArrayProviderSerivce } from './tf-array-provider.service';
import { WellPlansProviderSerivce } from './well-plans-provider.service';

@Injectable({
    providedIn: 'root'
})
export class DdService {

    constructor(
        private readonly surveyDataService: SurveyDataProviderSerivce,
        private readonly tfArray: TFArrayProviderSerivce,
        private readonly wellPlansService: WellPlansProviderSerivce
    ) { }

    getTopChartDataTree(chartType: ChartType, wellID: string, sections: Section[], currentSectionId: number): Observable<Points>[] {
        switch (chartType) {
            case ChartType.VSEC:
                return [
                    of([]), 
                    this.getChartSeriesTree(wellID, sections, currentSectionId, SurveyFields.vsec, SurveyFields.tvd)
                ];
            case ChartType.HD:
                return [
                    of([]),
                    this.getChartSeriesTree(wellID, sections, currentSectionId, SurveyFields.md, SurveyFields.tvd)
                ];
            case ChartType.THL:
                return [
                    of([]), 
                    this.getChartSeriesTree(wellID, sections, currentSectionId, SurveyFields.vsec, SurveyFields.thl)
                ];
        }
    }

    getBottomChartDataTree(chartType: ChartType, wellID: string, sections: Section[], currentSectionId: number): Observable<Points>[] {
        switch (chartType) {
            case ChartType.VSEC:
                return [of([]), this.getChartSeriesTree(wellID, sections, currentSectionId, SurveyFields.vsec, SurveyFields.hOffset)];
            case ChartType.HD:
                return [of([]), this.getChartSeriesTree(wellID, sections, currentSectionId, SurveyFields.ew, SurveyFields.ns)];
            case ChartType.THL:
                return [of([]), this.getChartSeriesTree(wellID, sections, currentSectionId, SurveyFields.ew, SurveyFields.ns)];
        }
    }

    getTopChartData(chartType: ChartType, wellID: string, sections: Section[], selectedPlanId: string | null): Observable<Points>[] {
        switch (chartType) {
            case ChartType.VSEC: {
                const arrayOfSeries = [this.getChartSeriesWellPlan(wellID, selectedPlanId ?? '', WellPlanDataFields.vsec, WellPlanDataFields.tvd)];
                for (const section of sections) {
                    arrayOfSeries.push(this.getChartSeriesTree(wellID, sections, section.id, SurveyFields.vsec, SurveyFields.tvd));
                }
                return arrayOfSeries;
            }
            case ChartType.HD: {
                const arrayOfSeries = [this.getChartSeriesWellPlan(wellID, selectedPlanId ?? '', WellPlanDataFields.md, WellPlanDataFields.tvd)];
                for (const section of sections) {
                    arrayOfSeries.push(this.getChartSeriesTree(wellID, sections, section.id, SurveyFields.md, SurveyFields.tvd));
                }
                return arrayOfSeries;
            }
            case ChartType.THL: {
                const arrayOfSeries = [this.getChartSeriesWellPlan(wellID, selectedPlanId ?? '', WellPlanDataFields.vsec, WellPlanDataFields.thl)];
                for (const section of sections) {
                    arrayOfSeries.push(this.getChartSeriesTree(wellID, sections, section.id, SurveyFields.vsec, SurveyFields.thl));
                }
                return arrayOfSeries;
            }
        }
    }

    getBottomChartData(chartType: ChartType, wellID: string, sections: Section[], selectedPlanId: string | null): Observable<Points>[] {
        switch (chartType) {
            case ChartType.VSEC: {
                const arrayOfSeries = [this.getChartSeriesWellPlan(wellID, selectedPlanId ?? '', WellPlanDataFields.vsec, WellPlanDataFields.hOffset)];
                for (const section of sections) {
                    arrayOfSeries.push(this.getChartSeriesTree(wellID, sections, section.id, SurveyFields.vsec, SurveyFields.hOffset));
                }
                return arrayOfSeries;
            }
            case ChartType.HD: {
                const arrayOfSeries = [this.getChartSeriesWellPlan(wellID, selectedPlanId ?? '', WellPlanDataFields.ew, WellPlanDataFields.ns)];
                for (const section of sections) {
                    arrayOfSeries.push(this.getChartSeriesTree(wellID, sections, section.id, SurveyFields.ew, SurveyFields.ns));
                }
                return arrayOfSeries;
            }
            case ChartType.THL: {
                const arrayOfSeries = [this.getChartSeriesWellPlan(wellID, selectedPlanId ?? '', WellPlanDataFields.ew, WellPlanDataFields.ns)];
                for (const section of sections) {
                    arrayOfSeries.push(this.getChartSeriesTree(wellID, sections, section.id, SurveyFields.ew, SurveyFields.ns));
                }
                return arrayOfSeries;
            }
        }
    }

    get3dChartData(wellID: string, sections: Section[], selectedPlanId: string | null): Observable<Points>[] {
        const arrayOfSeries = [this.getChartSeriesWellPlan(wellID, selectedPlanId ?? '', WellPlanDataFields.ns, WellPlanDataFields.tvd, WellPlanDataFields.ew)];
        for (const section of sections) {
            arrayOfSeries.push(this.getChartSeriesTree(wellID, sections, section.id, SurveyFields.ns, SurveyFields.tvd, SurveyFields.ew));
        }
        return arrayOfSeries;
    }

    getSurveyDataTree(wellID: string, sections: Section[], currentSectionId: number): Observable<Survey[]> {
        return this.surveyDataService.getSurveyDataTree(wellID, sections, currentSectionId);
    }

    watchSurveyData(wellID: string, sectionID: string): Observable<Survey[] | undefined> {
        return this.surveyDataService.watchSurveyData(wellID, sectionID);
    }

    watchSurveyDataTree(wellID: string, sections: Section[], currentSectionId: number): Observable<Survey[] | undefined> {
        return this.surveyDataService.watchSurveyDataTree(wellID, sections, currentSectionId);
    }

    getTfArray(rigName: string): Observable<Toolface[]> {
        return this.tfArray.getTFArrayData(rigName).pipe(
            switchMap((value) => this.tfArray.watchTFArrayData(rigName).pipe(startWith(value)))
        );
    }

    watchWellPlans(wellID: string): Observable<WellPlan[] | undefined> {
        return this.wellPlansService.watchWellPlans(wellID);
    }

    private getChartSeriesTree(wellID: string, sections: Section[], currentSectionId: number, xAxisDataType: SurveyFields, yAxisDataType: SurveyFields, zAxisDataType: SurveyFields | null = null): Observable<Point[]> {
        return this.surveyDataService.watchSurveyDataTree(wellID, sections, currentSectionId).pipe(
            map((surveys) => {
                const result: Point[] = [];

                const uniqueSurveys = surveys?.filter((survey, index, self) => {
                    return self.findIndex(s => s.number === survey.number) === index;
                });

                for (const survey of uniqueSurveys ?? []) {
                    result.push({
                        x: survey[xAxisDataType] ?? 0, 
                        y: survey[yAxisDataType] ?? 0,
                        z: zAxisDataType ? survey[zAxisDataType] : undefined
                    });
                }
                return result;
            })
        );
    }

    private getChartSeriesWellPlan(wellID: string, wellPlanId: string | null, xAxisDataType: WellPlanDataFields, yAxisDataType: WellPlanDataFields, zAxisDataType: WellPlanDataFields | null = null): Observable<Point[]> {
        return this.wellPlansService.watchWellPlans(wellID).pipe(
            map((plans) => plans?.find(plan => plan.id === wellPlanId)?.data ?? []),
            map((planData) => {
                const result: Point[] = [];

                for (const plan of planData ?? []) {
                    result.push({
                        x: plan[xAxisDataType] ?? 0, 
                        y: plan[yAxisDataType] ?? 0,
                        z: zAxisDataType ? plan[zAxisDataType] : undefined
                    });
                }
                return result;
            })
        );
    }

}
