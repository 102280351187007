import { Injectable } from '@angular/core';

import { FirebaseOptions } from 'firebase/app';

import { LocalStorageService } from './local-storage';

export const FB_TOKEN = 'fb_token';
export const FB_DB_CONFIG = 'fb_config';

export interface SsoTokens {
    api: string,
    access: {
        exp: number,
        token: string,
        refresh: string
    }
}

@Injectable({
    providedIn: 'root'
})
export class FirebaseDataStorageService {
    /**
     *
     */
    constructor(private storage: LocalStorageService) {

    }

    getToken() {
        const value = this.storage.get<string>(FB_TOKEN) || null;

        if (value && typeof value !== 'string') {
            return null;
        }

        return value;
    }

    getFirebaseConfig() {
        return this.storage.get<FirebaseOptions>(FB_DB_CONFIG) || null;
    }

    setFirebaseData(token: string, dbConfig?: FirebaseOptions) {
        if (!token || !dbConfig) {
            token = null as any;
            dbConfig = null as any;
        }

        try {
            this.storage.set(FB_TOKEN, token);
            this.storage.set(FB_DB_CONFIG, dbConfig);
        }
        catch (e) {
            console.error(e);
            this.clear();
        }
    }

    clear() {
        this.storage.delete(FB_TOKEN);
        this.storage.delete(FB_DB_CONFIG);
    }
}
