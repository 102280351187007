import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnChanges, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { ClChartComponent, CompactNumberFormatter, CoreSelectors, IconComponent, UnitsConverterService, UnitsSelectors, WellKnownParams } from '@cyberloop/core';
import { KpiActions, KpiSelectors } from '@cyberloop/web/wells/data';
import { WidgetDataProvider, WidgetSettingsHandler, WidgetSize, WidgetType, customVsTimeDefaultSettings } from '@cyberloop/web/wells/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { merge } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, debounceTime, filter, map, of, switchMap, tap } from 'rxjs';

import * as Highcharts from 'highcharts';
import * as moment from 'moment';

import { KpiWidgetComponent } from '../widget/kpi-widget.component';
import { SettingsComponent } from './settings/settings.component';

import type { UnitDescriptor, WellKnownUnitIds } from '@cyberloop/core';
import type { SimpleChangesOf } from '@cyberloop/core';
import type { KpiWidget, CustomVsTimeWidgetSettings, CustomVsTimeWidgetData } from '@cyberloop/web/wells/model';

type TagData = { tag: WellKnownParams, unit?: UnitDescriptor, min?: number, max?: number };

const Y_AXES_SIZE = 75;
const Y_AXES_SPACING = 10;
const Y_AXES_OFFSET = 10;

const Y_AXIS_COMMON_OPTIONS: Highcharts.YAxisOptions = {
    // min: 0,
    margin: 5,

    top: '2.5%',
    height: '95%',

    tickWidth: 1,
    tickLength: 5,

    // startOnTick: false,
    // endOnTick: false,

    title: {
        useHTML: true
    }
};


function getTagUnit$(store: Store, tagKey: string, unitId?: WellKnownUnitIds, widgetSettings?: CustomVsTimeWidgetSettings): Observable<Partial<TagData>> {
    const tagKeyTyped = tagKey as keyof CustomVsTimeWidgetSettings;
    const tagMinKeyTyped = (tagKey + 'Min') as keyof CustomVsTimeWidgetSettings;
    const tagMaxKeyTyped = (tagKey + 'Max') as keyof CustomVsTimeWidgetSettings;

    const tagName = widgetSettings?.[tagKeyTyped] as WellKnownParams;

    const defaultValue: Partial<TagData> = {
        tag: (widgetSettings?.[tagKeyTyped] as WellKnownParams) ?? undefined,
        unit: undefined,
        min: (widgetSettings?.[tagMinKeyTyped] as number) ?? undefined,
        max: (widgetSettings?.[tagMaxKeyTyped] as number) ?? undefined
    };

    if (!unitId && !tagName) {
        return of(defaultValue);
    }

    // Get tag unit
    return store.select(CoreSelectors.tagUnits(tagName)).pipe(
        map(units => Object.values(units).find(item => item.id === unitId)),
        map(unit => ({
            ...defaultValue,
            unit
        }))
    );
}


@Component({
    selector: 'cyberloop-custom-vs-time-widget',
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        KpiWidgetComponent,
        MatFormFieldModule,
        ClChartComponent,
        MatSelectModule,
        IconComponent
    ],
    templateUrl: './custom-vs-time-widget.component.html',
    styleUrls: ['./custom-vs-time-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class CustomVsTimeWidgetComponent implements KpiWidget, OnChanges {

    private readonly _id$ = new BehaviorSubject<string>('-');
    private readonly _dataLoading$ = new BehaviorSubject<boolean>(true);

    private _lastSettings?: CustomVsTimeWidgetSettings;
    // We use it to control when we redraw the chart and where we not
    private _hadAxesChanged = false;

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string,
        private readonly store: Store,
        private readonly settings: WidgetSettingsHandler,
        private readonly data: WidgetDataProvider,
        private readonly unitsConverter: UnitsConverterService
    ) {
        const idPipe = this._id$.pipe(
            untilDestroyed(this),
            filter(Boolean),
            tap(() => this._dataLoading$.next(true))
        );

        combineLatest([
            // Get widget data
            idPipe.pipe(
                switchMap(id => this.data.getData(id, WidgetType.CustomVsTime))
            ),
            // Get widget settings (for units conversion)
            idPipe.pipe(
                switchMap(id => this.store.select(KpiSelectors.widgetSettings<CustomVsTimeWidgetSettings>(id))),
                switchMap(widgetSettings => {
                    if (
                        widgetSettings?.tag1 !== this._lastSettings?.tag1 ||
                        widgetSettings?.tag2 !== this._lastSettings?.tag2 ||
                        widgetSettings?.tag3 !== this._lastSettings?.tag3
                    ) {
                        this._hadAxesChanged = true;
                    }

                    this._lastSettings = widgetSettings;

                    const tag1UnitId = widgetSettings?.tag1UnitId ?? customVsTimeDefaultSettings.tag1UnitId;
                    const tag2UnitId = widgetSettings?.tag2UnitId ?? customVsTimeDefaultSettings.tag2UnitId;
                    const tag3UnitId = widgetSettings?.tag3UnitId ?? customVsTimeDefaultSettings.tag3UnitId;

                    return combineLatest([
                        getTagUnit$(this.store, 'tag1', tag1UnitId, widgetSettings),
                        getTagUnit$(this.store, 'tag2', tag2UnitId, widgetSettings),
                        getTagUnit$(this.store, 'tag3', tag3UnitId, widgetSettings)
                    ]);
                })
            )
        ]).pipe(
            debounceTime(100),
            switchMap(async ([widgetData, [tag1Data, tag2Data, tag3Data]]) => {
                // Fill up tag data

                const tagsData: Record<string, TagData> = {};

                if (tag1Data.tag) {
                    tagsData['tag1'] = tag1Data as TagData;
                }
                if (tag2Data.tag) {
                    tagsData['tag2'] = tag2Data as TagData;
                }
                if (tag3Data.tag) {
                    tagsData['tag3'] = tag3Data as TagData;
                }

                const chart = this.chart;

                if (chart) {
                    await this.setChartData(
                        chart,
                        widgetData,
                        tagsData
                    );
                }
            })
        ).subscribe(() => {
            this._dataLoading$.next(false);
        });
    }

    readonly options = this.getOptions();
    readonly dataLoading$ = this._dataLoading$.asObservable();

    @Input() id = '-';
    @Input() size = WidgetSize.Medium;

    @ViewChild(ClChartComponent, { static: true }) chart?: ClChartComponent;

    ngOnChanges(changes: SimpleChangesOf<CustomVsTimeWidgetComponent>): void {
        if (changes.id) {
            this._id$.next(changes.id.currentValue);
        }
    }

    async onSettings(): Promise<void> {
        await this.settings.showSettings<CustomVsTimeWidgetSettings>(
            this.id,
            SettingsComponent,
            customVsTimeDefaultSettings,
            { maxWidth: '575px' }
        );
    }

    onDelete(): void {
        this.store.dispatch(KpiActions.deleteWidget({ widgetId: this.id }));
    }

    // --

    private async setChartData(
        chart: ClChartComponent,
        widgetData: CustomVsTimeWidgetData,
        tagsData: Record<string, TagData>
    ): Promise<void> {
        const tagKeys = Object.keys(tagsData);

        const promises: Promise<any>[] = [];

        if (this._hadAxesChanged) {
            this._hadAxesChanged = false;

            // Delete all existing series
            await chart.removeAllSeries();
            // Delete all Y axes
            await chart.removeAllYAxes();

            // await (new Promise(res => setTimeout(res, 5000)));
            chart.reCreateChart();

            // Create new Y Axes

            for (let i = 0; i < tagKeys.length; i++) {
                const tagKey = tagKeys[i];
                const tagData = tagsData[tagKey];

                promises.push(chart.addAxis(merge({}, Y_AXIS_COMMON_OPTIONS, {
                    offset: Y_AXES_OFFSET + (Y_AXES_SIZE + Y_AXES_SPACING) * i,
                    title: {
                        text: `<span class="dot highcharts-color-${i + 1}"></span>${tagData.tag}`
                    },
                    min: tagData.min,
                    max: tagData.max,

                    labels: {
                        formatter: function () {
                            return CompactNumberFormatter.format(parseFloat(String(this.value)));
                        }
                    }
                } as Highcharts.YAxisOptions), false));
            }

            await Promise.all(promises);
            promises.splice(0, promises.length);

            // Create new series

            for (let i = 0; i < tagKeys.length; i++) {
                const tagKey = tagKeys[i];

                promises.push(chart.addSeries({
                    id: tagKey,
                    xAxis: 0,
                    yAxis: i,
                    type: 'line',
                    colorIndex: i + 1,
                    data: []
                }));
            }

            await Promise.all(promises);
            promises.splice(0, promises.length);
        }

        // Update some params

        chart.update({
            chart: {
                marginLeft: tagKeys.length * (Y_AXES_SIZE + Y_AXES_SPACING) + Y_AXES_OFFSET
            }
        });

        // And set series data

        for (let i = 0; i < tagKeys.length; i++) {
            const tagKey = tagKeys[i];
            const tagData = tagsData[tagKey];

            const seriesData = widgetData[tagKey as keyof CustomVsTimeWidgetData];

            promises.push(chart.setSeriesData(tagKey, seriesData.map(item => ({
                x: item.x,
                y: item.y ? this.getConvertedValue(item.y, tagData.unit) : item.y,
                name: tagData.tag
            }))));
        }

        await Promise.all(promises);
    }

    private getOptions(): Highcharts.Options {
        return {
            chart: {
                type: 'line',
                marginRight: 0,
                marginBottom: 59,

                styledMode: true,
                animation: false,

                events: {
                    render: function () {
                        // A hack to set the plot area radius
                        (this as any).plotBackground.attr({ rx: 6, ry: 6 });
                    }
                }
            },
            tooltip: {
                valueDecimals: 2,
                padding: 0,
                useHTML: true,
                formatter: function () {
                    const title = this.point.name;
                    const value = this.point.y?.toFixed(2).replace(/\.00$/, '');

                    // TODO:
                    // if (self._ropUnit) {
                    //     value += ' ' + self._ropUnit.label;
                    // }

                    return `<span class="dot highcharts-color-${this.colorIndex}"></span><span class="title">${title}</span><span class="value">${value}</span>`;
                }
            },
            xAxis: {
                offset: 4,
                type: 'datetime',
                ordinal: false,
                title: {
                    text: 'Days',
                    margin: -1
                },
                labels: {
                    allowOverlap: true,
                    autoRotation: undefined,
                    formatter: function () {
                        const time = moment(this.value);

                        return (
                            `<span class="date">${time.format('D.MM')}</span>` +
                            '<br>' +
                            `<span class="time">${time.format('H:mm')}</span>`
                        );
                    },
                    style: {
                        textOverflow: 'none'
                    }
                },
                tickLength: 0,
                tickWidth: 0,
                minPadding: 0,
                maxPadding: 0
            },
            yAxis: [],
            plotOptions: {
                line: {
                    animation: false,
                    clip: true,
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 3
                    }
                }
            },
            series: []
        };
    }

    private getConvertedValue(source: number, unit?: UnitDescriptor): number {
        return unit
            ? this.unitsConverter.convertToUnit(source, unit)
            : source;
    }

}
