import { Injectable, Optional } from '@angular/core';

import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { isEqual } from 'lodash';
import { distinctUntilChanged, filter, firstValueFrom, map, switchMap, tap } from 'rxjs';

import { AuthService, UnitsProviderService } from '../../services';
import { deepMerge, haltApplication } from '../../utils';
import { UnitsActions } from './units.actions';
import { UnitsSelectors } from './units.selectors';
import { UNITS_FEATURE } from './units.state';

/** @internal */
const initAction = createAction(`[${UNITS_FEATURE}] Initialize units`);

/** @internal */
@Injectable()
export class UnitsEffects implements OnInitEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly auth: AuthService,
        @Optional() private ups: UnitsProviderService
    ) {
        if (!ups) {
            haltApplication("UnitsProviderService was not defined. Please register this service first");
        }
    }

    readonly syncUnits$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(() => this.ups.onUnitsSnapshot()),
        switchMap(async x => {
            const unitGroups = await firstValueFrom(this.store.select(UnitsSelectors.unitGroups));

            if (!isEqual(unitGroups, x.groups)) {
                this.store.dispatch(UnitsActions.setUnitGroups({ groups: x.groups }));
            }

            const presets = await firstValueFrom(this.store.select(UnitsSelectors.unitsPresets));
            if (!isEqual(presets, x.presets)) {
                this.store.dispatch(UnitsActions.setUnitsPresets({ presets: x.presets }));
            }
        })
    ), { dispatch: false });

    readonly syncUserPreset$ = createEffect(() => this.auth.currentUser$.pipe(
        switchMap(x => {
            if (!x) {
                return [UnitsActions.destroyUserUnitPreset()];
            }

            return [UnitsActions.loadUserUnitPreset()];
        })
    ));

    readonly loadUserUnitPreset$ = createEffect(
        () => this.actions$.pipe(
            ofType(UnitsActions.loadUserUnitPreset),
            switchMap(() => firstValueFrom(this.store.select(UnitsSelectors.userPresetIsLoading))),
            filter(isLoading => !isLoading),
            tap(() => this.store.dispatch(UnitsActions.onStartLoading())),
            switchMap(() => this.ups.onUserUnitsPresetSnapshot())
        )
            .pipe(
                switchMap(userPreset => this.store.select(UnitsSelectors.siUnitsPreset).pipe(
                    map(x => deepMerge({}, [x, userPreset]))
                )),
                distinctUntilChanged((a, b) => isEqual(a, b)),
                switchMap(preset => ([UnitsActions.setUserUnitsPreset({ preset })]))
            )
    );

    readonly updateUserUnitsPreset$ = createEffect(() => this.actions$.pipe(
        ofType(UnitsActions.updateUserUnitsPreset),
        switchMap(x => this.ups.updateUserUnitsPreset(x.preset)),
        tap(() => console.log('Units preset updated'))
    ), { dispatch: false });

    ngrxOnInitEffects(): Action {
        return initAction();
    }
}
