import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { UnitsSelectorService } from '@cyberloop/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

import { UserMenuComponent } from './user-menu/user-menu.component';

@Component({
    selector: 'cyberloop-user-menu-trigger',
    standalone: true,
    imports: [
        UserMenuComponent,
        NgIf,
        AsyncPipe
    ],
    templateUrl: './user-menu-trigger.component.html',
    styleUrls: ['./user-menu-trigger.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuTriggerComponent {
    private readonly _closing$ = new BehaviorSubject(false);

    constructor(private readonly unitsSelector: UnitsSelectorService) {}

    @Input() userAvatar: string | null = '';
    @Input() displayName: string | null = '';
    @Input() login: string | null = '';

    @Output() readonly logout = new EventEmitter<void>();

    @Input() menuVisible = false;

    readonly closing$ = this._closing$.pipe(
        distinctUntilChanged()
    );

    hideMenu() {
        this.menuVisible = false;
    }

    sendLogoutCommand() {
        this.logout.emit();
        this.hideMenu();
    }

    async openUnitsSelector() {
        this.hideMenu();
        await this.unitsSelector.show();
    }

    // onAnimationEnd() {
    //     if (!this._closing$.value) {
    //         return;
    //     }

    //     this.menuVisible = false;
    //     this._closing$.next(false);
    // }
}
