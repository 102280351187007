<button *ngIf="fileList?.length; else attachTemplate" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
    [class.opened]="menuTrigger?.menuOpen" [disabled]="disabled" class="files-button cl-button">
    <span class="count">{{ fileList?.length }}</span>
    <span class="label">files</span>
</button>

<mat-menu #menu="matMenu" (click)="$event.stopPropagation()" xPosition="before" class="attach-files-menu">
    <div (click)="$event.stopPropagation()" class="menu-wrap">
        <ng-container *ngFor="let file of fileList">
            <div class="menu-item">
                <button (click)="download.emit(file.id)" [disabled]="disabled || !canDownload"
                    class="download-button cl-button">
                    <icon name="download"></icon>
                    <div class="meta">
                        <span class="name">{{ file.name }}</span>
                        <time class="date">{{ file.updatedAt | datetime }}</time>
                    </div>
                </button>

                <ng-container *ngIf="canDelete">
                    <div class="divider vertical"></div>

                    <button (click)="delete.emit(file.id)" [disabled]="disabled" class="cl-icon-button">
                        <icon name="delete"></icon>
                    </button>
                </ng-container>
            </div>

            <div class="divider horizontal"></div>
        </ng-container>

        <div *ngIf="canAdd" class="add-wrap">
            <button (click)="add.emit()" [disabled]="disabled" class="add-button cl-button">
                <icon name="plus"></icon>
                add file
            </button>
        </div>
    </div>
</mat-menu>

<ng-template #attachTemplate>
    <button *ngIf="canAdd" (click)="add.emit()" [disabled]="disabled" class="attach-button cl-button">
        <icon name="attach"></icon>
        <span class="label">attach file</span>
    </button>
</ng-template>