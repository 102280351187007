<div mat-dialog-title>{{data.title || 'Confirmation'}}</div>
<div mat-dialog-content class="content">
    {{data.text || 'Do you really want to continue?'}}
</div>
<div mat-dialog-actions align="end">
    <ng-container *ngIf="defaultValue; else defaultIsNo">
        <button mat-button [mat-dialog-close]="false" hotkey="Escape">{{data.noText ||
            'Cancel'}}</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial hotkey="Enter">
            {{data.yesText || 'OK'}} <ng-container
                *ngIf="showTimeout">&nbsp;({{timeLeft$|async}})</ng-container></button>
    </ng-container>
    <ng-template #defaultIsNo>
        <button mat-button [mat-dialog-close]="false" cdkFocusInitial hotkey="Escape">{{data.noText ||
            'Cancel'}} <ng-container *ngIf="showTimeout">&nbsp;({{timeLeft$|async}})</ng-container></button>
        <button mat-button [mat-dialog-close]="true" hotkey="Enter">{{data.yesText || 'OK'}}</button>
    </ng-template>
</div>