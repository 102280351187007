import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { ClInputComponent, IconComponent } from '@cyberloop/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'cyberloop-toolbar',
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        ClInputComponent,
        IconComponent
    ],
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit {
    @Input() set searchValue(value: string | null) {
        this.searchControl.setValue(value ?? '');
    }
    @Input() disableActions?: boolean | null;
    @Input() readonly?: boolean | null;

    @Output() readonly search = new EventEmitter<string>();
    @Output() readonly upload = new EventEmitter();

    readonly searchControl = new FormControl('');

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => this.search.emit(value ?? ''));
    }
}
