import { Injectable } from '@angular/core';

import { ExportDataObject, FileType, PrimaryColumnId } from '@cyberloop/core';

import { CsvWriterService } from './csv-writer.service';
import { LasWriterService } from './las-writer.service';

@Injectable({ providedIn: 'root' })
export class ExportToFileService {
    
    /**
     * Export data
     * @param obj export data object
     */
    public exportData(obj: ExportDataObject): void {
        const { data: oldData, format, columns, fileName, from: origFrom, to: origTo, step, byDepth, precision, well } = obj;
        const primaryColumnId = byDepth ? PrimaryColumnId.Depth : PrimaryColumnId.Date;

        const data = oldData
            .filter(x => x[primaryColumnId] >= origFrom && x[primaryColumnId] <= origTo)
            .sort((a, b) => a[primaryColumnId] - b[primaryColumnId]);

        const from = data?.[0]?.[primaryColumnId];
        const to = data?.[data.length - 1]?.[primaryColumnId];

        const writer = format === FileType.Las ? new LasWriterService() : new CsvWriterService();

        const content = writer.writeToString({ data, columns, from, to, step, byDepth, precision, well });

        const filename = `${fileName}_by${byDepth ? 'Depth' : 'Time'}_${origFrom}_${origTo}`;
        this.downloadData(content, filename + writer.fileExt, writer.mime);
    }

    private downloadData(content: any, fileName: string, mime: string) {
        const encodedUri = `data:${mime};base64,${btoa(content)}`;
        const link = document.createElement('a');
        link.style.display = 'inline';
        link.style.opacity = '0';

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName);
        document.body.appendChild(link); // Required for FF

        link.click();
        
        link.remove();
    }
}