import { NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ViewChild, forwardRef } from '@angular/core';
import { AsyncValidatorFn, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidatorFn } from '@angular/forms';

import { Unit, UnitDescriptor, UnitsConverterService } from '@cyberloop/core';
import { isNil } from 'lodash';
import { Subject, map, takeUntil } from 'rxjs';

import { FormControlAccessorDirective, ScrubbingDirective } from '../../directives';

/** @internal */
type ChangeMethod = (x: number | null) => void;
/** @internal */
type TouchMethod = () => void;

/**
 * Input with units
 * Input value must be in Si units
 * Represent value in passed unit
 * Output value in Si units
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cl-numeric-input',
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        FormControlAccessorDirective,
        ScrubbingDirective
    ],
    templateUrl: './cl-numeric-input.component.html',
    styleUrls: ['./cl-numeric-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ClNumericInputComponent), multi: true }
    ]
})
export class ClNumericInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    /** @private */
    private readonly _isDestroyed$ = new Subject<void>();

    /** @private */
    private _siValue: number | null = null;
    /** @private */
    private _validators: ValidatorFn[] = [];
    /** @private */
    private _asyncValidators: AsyncValidatorFn[] = [];
    /** @private */
    private _onChange: ChangeMethod | undefined;
    /** @private */
    private _onTouched: TouchMethod | undefined;
    /** @private */
    private _input: FormControlAccessorDirective<HTMLInputElement> | undefined;
    /** @private */
    private _unit?: Unit | UnitDescriptor | null;
    /** @private */
    private _readonly = false;
    /** @private */
    private markTouchebCb;

    /** @internal */
    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly unitsConverterService: UnitsConverterService
    ) {
        this.markTouchebCb = () => {
            this._onTouched?.();
            this.changeDetectorRef.markForCheck();
        };
    }

    /** Unit */
    @Input() set unit(value: UnitDescriptor | null | undefined) {
        this._unit = value;
        this.setConvertedValue(this._siValue);
    }
    /** Show unit label */
    @Input() hasUnitLabel = false;
    /** Placeholder to be shown if no value provided */
    @Input() placeholder: number | string | null | undefined;
    /** Alignment field */
    @Input() alignment: 'center' | 'start' | 'end' = 'start';
    /** Readonly */
    @Input() set readonly(value: boolean | null | undefined) {
        this._readonly = value ?? false;
    }
    /** @internal */
    get readonly() {
        return this._readonly;
    }
    /** Mark the control touched */
    @Input() markTouchedOn: 'focus' | 'blur' = 'focus';
    /** Validators to be attached to the input */
    @Input()
    public set validators(value: ValidatorFn[] | ValidatorFn | null | undefined) {
        this.ctrl.removeValidators(this._validators);
        if (!value) {
            value = [];
        }
        if (!Array.isArray(value)) {
            value = [value];
        }
        this._validators = value;
        this.ctrl.addValidators(this._validators);
    }
    /** @internal */
    public get validators(): ValidatorFn[] {
        return this._validators;
    }

    /** Async validators to be attached to the input */
    @Input()
    public set asyncValidators(value: AsyncValidatorFn[] | AsyncValidatorFn | null | undefined) {
        this.ctrl.removeAsyncValidators(this._asyncValidators);
        if (!value) {
            value = [];
        }
        if (!Array.isArray(value)) {
            value = [value];
        }
        this._asyncValidators = value;
        this.ctrl.addAsyncValidators(this._asyncValidators);
    }
    /** @internal */
    public get asyncValidators(): AsyncValidatorFn[] {
        return this._asyncValidators;
    }

    /** @internal */
    readonly ctrl = new FormControl<number | null>(null, { updateOn: 'change' });

    /** @internal */
    @ViewChild(FormControlAccessorDirective)
    public set input(value: FormControlAccessorDirective<HTMLInputElement> | undefined) {
        this._input = value;

        if (value) {
            value.el.nativeElement.addEventListener(this.markTouchedOn, this.markTouchebCb);
        }
    }
    /** @internal */
    public get input(): FormControlAccessorDirective<HTMLInputElement> | undefined {
        return this._input;
    }

    /** @internal */
    get unitLabel() {
        return (this._unit as UnitDescriptor)?.label ?? '';
    }

    /** @internal */
    ngAfterViewInit(): void {
        this.ctrl.valueChanges.pipe(
            map((value) => {
                if (typeof value === 'string') {
                    value = Number(value)
                }

                if (!Number.isFinite(value)) {
                    return null;
                }

                return value;
            }),
            takeUntil(this._isDestroyed$),
        ).subscribe((x) => {
            this._siValue = this.convertToSi(x);
            this._onChange?.(this._siValue)
        });
    }

    /** @internal */
    ngOnDestroy(): void {
        this._isDestroyed$.next();
        this._input?.el.nativeElement.removeEventListener(this.markTouchedOn, this.markTouchebCb);
    }

    /** @internal */
    writeValue(value: number): void {
        this._siValue = value;
        this.setConvertedValue(this._siValue);
    }
    /** @internal */
    registerOnChange(fn: ChangeMethod): void {
        this._onChange = fn;
    }
    /** @internal */
    registerOnTouched(fn: TouchMethod): void {
        this._onTouched = fn;
    }
    /** @internal */
    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.ctrl.disable({ emitEvent: false });
        }
        else {
            this.ctrl.enable({ emitEvent: false });
        }
    }

    /** @internal */
    private setConvertedValue(value: number | null) {
        const newValue = this.convertToUnit(value);
        if (typeof newValue === 'number' && !Number.isFinite(newValue)) {
            console.warn('Value must be finite, got ', { value, newValue });
            return;
        }
        this.ctrl.patchValue(newValue, { emitEvent: false });
    }

    /** @internal */
    private convertToUnit(value: number | null) {
        if (isNil(value)) {
            return null;
        }

        if (typeof value !== 'number') {
            throw new Error('Value must be a number');
        }

        if (!Number.isFinite(value)) {
            return value;
        }

        if (isNil(this._unit)) {
            return value;
        }

        return this.unitsConverterService.convertToUnit(value, this._unit);
    }

    /** @internal */
    private convertToSi(value: number | null) {
        if (isNil(value)) {
            return null;
        }

        if (typeof value !== 'number') {
            throw new Error('Value must be a number');
        }

        if (!Number.isFinite(value)) {
            return value;
        }

        if (isNil(this._unit)) {
            return value;
        }

        return this.unitsConverterService.convertToSi(value, this._unit);
    }
}
