import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ClCheckboxGroupComponent, ClDropdownComponent, ClDropdownOptions, Entity, IconComponent, Rig } from '@cyberloop/core';
import { WellsFilters, WellsTypeFilters } from '@cyberloop/web/wells/model';

@Component({
    selector: 'cyberloop-wells-page-top-bar',
    standalone: true,
    imports: [
        CommonModule,
        ClCheckboxGroupComponent,
        ClDropdownComponent,
        IconComponent
    ],
    templateUrl: './wells-page-top-bar.component.html',
    styleUrls: ['./wells-page-top-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WellsPageTopBarComponent {

    constructor() { }

    @Input() rigs: Rig[] | null = [];
    @Input() activeFilters: WellsFilters | null = null;
    @Output() changeFilter: EventEmitter<WellsFilters> = new EventEmitter<WellsFilters>();

    readonly filters: Entity<WellsTypeFilters>[] = Object.values(WellsTypeFilters).map((filter) => ({id: filter, name: filter}));

    onSelectedRig(item: ClDropdownOptions) {
        if(this.activeFilters) {
            this.changeFilter.emit({...this.activeFilters, rig: String(item.id)});
        }
    }

    get selectedTypeFilters(): Entity<WellsTypeFilters>[] {
        if(this.activeFilters?.type) {
            return this.activeFilters.type.map((type) => ({id: type, name: type} as Entity<WellsTypeFilters>));
        }
        return [];
    }

    onToggleTypeFilter(event: any[]) {
        const type = event[event.length - 1];
        if(this.activeFilters) {
            let types = [...this.activeFilters.type];
            if(types?.includes(type)) {
                types = types.filter(f => f !== type);
            } 
            else {
                types?.push(type);
            }
            this.changeFilter.emit({...this.activeFilters, type: types});
        }
    }
}
