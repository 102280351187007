import type { TndChartSettings } from '@cyberloop/web/tnd/model';
import { tndChartDefaultSettings } from '@cyberloop/web/tnd/model';

/**
 * T&D feature name
 */
export const TND_FEATURE = 'TnD';

// export type DataState = 'NOT_LOADED' | 'LOADED' | 'LOADING';

/**
 * State for T&D
 */
export interface TndState {
    // dataState: DataState;

    /** An error during the state update */
    error?: Error;

    /** Settings of the Weights chart */
    weightsSettings: TndChartSettings;
    /** Settings of the Torque chart */
    torqueSettings: TndChartSettings;
}

/** @internal */
export function getDefaultTndState(): TndState {
    return {
        // dataState: 'LOADING',
        weightsSettings: tndChartDefaultSettings,
        torqueSettings: tndChartDefaultSettings
    };
}
