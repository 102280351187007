import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, filter, of, switchMap } from 'rxjs';

import { AuthSelectors } from '../state/auth/auth.selectors';

/**
 * Use this guard to check if user is logged in and can access to you route or not
 */
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
    /** @internal */
    constructor(
        private readonly store: Store,
        private readonly router: Router
    ) {
    }

    /** @internal */
    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const gotoAuth = () => {
            this.router.navigate(['auth', 'signin'], {
                queryParams: {
                    q: route.url.join('/')
                }
            });
        };
        return this.store.select(AuthSelectors.isInitialized).pipe(
            filter(Boolean),
            switchMap(() => this.store.select(AuthSelectors.loginState)),
            switchMap(x => {
                switch (x) {
                    case 'NOT_LOGGED_IN': {
                        gotoAuth();
                        return of(false);
                    }
                }

                return of(true);
            })
        );
    }
}
