import { isEqual } from 'lodash';
import { Observable, distinctUntilChanged, switchMap, timer } from 'rxjs';

import { GraphQLCore } from './core';
import { QueryOptions, QueryResult } from './models';

export type GraphQLQueryWatchOptions = {
    pollInterval: number
}

export abstract class GraphQLQuery<TResult = never, TVariables = never>
    extends GraphQLCore {

    fetch(variables: TVariables): Observable<QueryResult<TResult>> {
        const o: QueryOptions<TVariables> = {
            query: this.document,
            variables
        } as any;
        return this.cli.query<TResult, TVariables>(o);
    }


    watch(variables: TVariables): Observable<QueryResult<TResult>>;
    watch(options?: GraphQLQueryWatchOptions): Observable<QueryResult<TResult>>;
    watch(variables: TVariables, options: GraphQLQueryWatchOptions): Observable<QueryResult<TResult>>;
    watch(variables?: TVariables | GraphQLQueryWatchOptions, options?: GraphQLQueryWatchOptions): Observable<QueryResult<TResult>> {
        if (variables && !options && typeof (variables as GraphQLQueryWatchOptions).pollInterval === 'number') {
            options = variables as GraphQLQueryWatchOptions;
            variables = undefined as any;
        }

        options = {
            pollInterval: options?.pollInterval ?? 30000
        };

        return timer(0, options.pollInterval).pipe(
            switchMap(() => this.fetch(variables ?? undefined as any)),
            distinctUntilChanged(isEqual)
        );
    }
}
