import { Observable } from 'rxjs';

import { GraphQLCore } from './core';
import { MutationResult, QueryOptions } from './models';

export abstract class GraphQLMutation<TResult = never, TVariables = void>
    extends GraphQLCore {
    mutate(variables: TVariables): Observable<MutationResult<TResult>> {
        const o :  QueryOptions<TVariables> = {
            query: this.document,
            variables: variables
        } as any;
        return this.cli.mutate(o);
    }
}
