import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, ClChecboxComponent, ClNumericInputComponent, ClSelectorComponent, CoreSelectors, UnitsSelectors, WellKnownUnitGroupIds, WellKnownUnitIds } from '@cyberloop/core';
import { RopWidgetSettings, ropDefaultSettings } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, of, startWith, switchMap } from 'rxjs';

import { KpiWidgetSettingsComponent } from '../../widget-settings/kpi-widget-settings.component';

import type { PopupContent } from '@cyberloop/core';

@Component({
    standalone: true,
    imports: [
        NgFor,
        AsyncPipe,
        KpiWidgetSettingsComponent,
        FormsModule,
        ReactiveFormsModule,
        ClSelectorComponent,
        ClChecboxComponent,
        ClNumericInputComponent,
        ClButtonToggleComponent
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements PopupContent<RopWidgetSettings> {

    private readonly _selectedRopUnit$ = new BehaviorSubject<WellKnownUnitIds>(ropDefaultSettings.ropUnitId);
    private readonly _selectedDepthUnit$ = new BehaviorSubject<WellKnownUnitIds>(ropDefaultSettings.depthUnitId);

    private _closeFn?: (result: RopWidgetSettings | null) => void;
    private _data?: RopWidgetSettings;

    constructor(
        private readonly store: Store
    ) { }

    readonly form = new FormGroup({
        sectionId: new FormControl<number | null>(null),
        includeParent: new FormControl<boolean | null>(null),

        minDepth: new FormControl<number | null>(null),
        maxDepth: new FormControl<number | null>(null),

        ropUnits: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ]),

        depthUnits: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ]),
        targetRop: new FormControl<number | null>(null)
    });

    readonly sections$ = this.store.select(CoreSelectors.sectionsOfCurrentWell);

    readonly ropUnits$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Speed)).pipe(
        map(group => Object.values(group.units))
    );
    readonly depthUnits$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Length)).pipe(
        map(group => Object.values(group.units))
    );

    readonly selectedRopUnits$ = combineLatest([
        this._selectedRopUnit$,
        this.form.valueChanges.pipe(startWith(null))
    ]).pipe(
        switchMap(([selected, formData]) => (
            formData?.ropUnits || selected
                ? this.store.select(UnitsSelectors.getUnit(WellKnownUnitGroupIds.Speed, formData?.ropUnits ?? selected))
                : of(undefined)
        ))
    );

    readonly selectedDepthUnits$ = combineLatest([
        this._selectedDepthUnit$,
        this.form.valueChanges.pipe(startWith(null))
    ]).pipe(
        switchMap(([selected, formData]) => (
            formData?.depthUnits || selected
                ? this.store.select(UnitsSelectors.getUnit(WellKnownUnitGroupIds.Length, formData?.depthUnits ?? selected))
                : of(undefined)
        ))
    );

    setData(data: RopWidgetSettings): void {
        this._data = data;

        if (data.sectionId) {
            this.form.controls.sectionId.setValue(data.sectionId);
        }
        if (data.includeParentSection) {
            this.form.controls.includeParent.setValue(data.includeParentSection);
        }

        if (data.minDepth) {
            this.form.controls.minDepth.setValue(data.minDepth);
        }
        if (data.maxDepth) {
            this.form.controls.maxDepth.setValue(data.maxDepth);
        }

        if (data.ropUnitId) {
            const id = data.ropUnitId as WellKnownUnitIds;
            this.form.controls.ropUnits.setValue(id);
            this._selectedRopUnit$.next(id);
        }

        if (data.depthUnitId) {
            const id = data.depthUnitId as WellKnownUnitIds;
            this.form.controls.depthUnits.setValue(id);
            this._selectedDepthUnit$.next(id);
        }
        if (data.target) {
            this.form.controls.targetRop.setValue(data.target);
        }
    }

    setClose(fn: (result: RopWidgetSettings | null) => void): void {
        this._closeFn = fn;
    }

    onCancel(): void {
        this._closeFn?.(null);
    }

    async onSubmit(): Promise<void> {
        // Compose settings

        const result: RopWidgetSettings = {} as any;

        if (this.form.controls.sectionId.value) {
            result.sectionId = this.form.controls.sectionId.value;
        }
        if (this.form.controls.includeParent.value) {
            result.includeParentSection = this.form.controls.includeParent.value;
        }

        if (this.form.controls.minDepth.value) {
            result.minDepth = this.form.controls.minDepth.value;
        }
        if (this.form.controls.maxDepth.value) {
            result.maxDepth = this.form.controls.maxDepth.value;
        }

        if (this.form.controls.ropUnits.value) {
            result.ropUnitId = this.form.controls.ropUnits.value;
        }

        if (this.form.controls.depthUnits.value) {
            result.depthUnitId = this.form.controls.depthUnits.value;
        }
        if (this.form.controls.targetRop.value) {
            result.target = this.form.controls.targetRop.value;
        }

        this._closeFn?.(result);
    }

}
