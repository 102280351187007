import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

/** @internal */
const UTC = 'UTC';

/** Use this pipe to format dates. It supports momentjs and bigint in addition to standard types supported by DatePipe */
@Pipe({ name: 'datetime', standalone: true })
export class DatetimePipe extends DatePipe implements PipeTransform {
    /** Transofrm momentjs or big value using format, timezone and locale */
    override transform(value: moment.MomentInput | bigint, format?: string, timezone?: string, locale?: string): string | null;
    /** Transofrm standard value using format, timezone and locale */
    override transform(value: string | number | Date, format?: string | undefined, timezone?: string | undefined, locale?: string | undefined): string | null;
    /** Transofrm null or undefined */
    override transform(value: null | undefined, format?: string | undefined, timezone?: string | undefined, locale?: string | undefined): null;
    /** Transofrm value using UTC timezone and locale */
    override transform(value: string | number | Date | moment.MomentInput | bigint | null | undefined, format?: typeof UTC, locale?: string | undefined): null;
    /** @internal */
    override transform(value: string | number | Date | null | undefined, format?: string | undefined, timezone?: string | undefined, locale?: string | undefined): string | null {
        if (moment.isMoment(value)) {
            value = value.toDate();
        } else if (typeof value === 'bigint') {
            value = Number(value);
        }

        if (format === UTC) {
            format = undefined;
            timezone = UTC;
        }

        return super.transform(value, format, timezone, locale);
    }
}
