import { ClAccessDeniedNetworkError, ClBadRequestNetworkError, ClError, ClNetworkError, ClNotFoundNetworkError, ClServerNetworkError } from '@cyberloop/core';

import { FirebaseStorage, StorageError, StorageErrorCode, UploadMetadata, deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

import type { FirebaseApp } from '@firebase/app';
export class ClStorage implements FirebaseStorage {
    private _isDisposed = false;
    /**
     *
     */
    constructor(private readonly storage: FirebaseStorage) { }

    readonly app: FirebaseApp = this.storage.app;
    readonly maxUploadRetryTime: number = this.storage.maxUploadRetryTime;
    readonly maxOperationRetryTime: number = this.storage.maxOperationRetryTime;

    static create(app: FirebaseApp): ClStorage {
        const storage = getStorage(app);
        return new ClStorage(storage);
    }

    getDownloadURL(path: string) {
        try {
            return getDownloadURL(this.ref(path));

        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    uploadBytes(path: string, data: Blob | Uint8Array | ArrayBuffer, metadata?: UploadMetadata) {
        try {
            return uploadBytes(this.ref(path), data, metadata);

        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    deleteObject(path: string) {
        try {
            return deleteObject(this.ref(path));

        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    dispose() {
        this._isDisposed = true;
    }

    private ref(path: string) {
        return ref(this.storage, path);
    }

    private handleError(error: unknown) {
        console.error(error);

        if (error instanceof ClError) {
            return error;
        }

        if (error instanceof StorageError) {
            switch (error.code as StorageErrorCode) {
                case StorageErrorCode.UNAUTHENTICATED:
                case StorageErrorCode.UNAUTHORIZED:
                case StorageErrorCode.UNAUTHORIZED_APP:
                    return new ClAccessDeniedNetworkError(error.message);

                case StorageErrorCode.INVALID_ARGUMENT:
                case StorageErrorCode.INVALID_ARGUMENT_COUNT:
                case StorageErrorCode.INVALID_CHECKSUM:
                case StorageErrorCode.INVALID_DEFAULT_BUCKET:
                case StorageErrorCode.INVALID_EVENT_NAME:
                case StorageErrorCode.INVALID_FORMAT:
                case StorageErrorCode.INVALID_ROOT_OPERATION:
                case StorageErrorCode.INVALID_URL:
                case StorageErrorCode.SERVER_FILE_WRONG_SIZE:
                case StorageErrorCode.NO_DOWNLOAD_URL:
                    return new ClBadRequestNetworkError(error.message);

                case StorageErrorCode.PROJECT_NOT_FOUND:
                case StorageErrorCode.BUCKET_NOT_FOUND:
                case StorageErrorCode.OBJECT_NOT_FOUND:
                    return new ClNotFoundNetworkError(error.message);

                case StorageErrorCode.INTERNAL_ERROR:
                case StorageErrorCode.QUOTA_EXCEEDED:
                case StorageErrorCode.UNSUPPORTED_ENVIRONMENT:
                case StorageErrorCode.APP_DELETED:
                    return new ClServerNetworkError(error.message);

                default:
                    return new ClNetworkError(error.message);
            }
        }

        return new ClNetworkError('Unknown error');
    }
}
