import { ID } from '@cyberloop/core';
import { CreateForecastEquipmentAndPersonnelEvent, CreateForecastEvent, Forecast, ForecastEquipmentAndPersonnelEvent, ForecastEvent, UpdateForecastEquipmentAndPersonnelEvent, UpdateForecastEvent } from '@cyberloop/web/wells/model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { FORECAST_FEATURE } from './forecast.state';

/**
 * Actions for forecast used internally
 * @internal
 * */
export const ForecastActions = createActionGroup({
    source: FORECAST_FEATURE,
    events: {
        //#region Forecast
        'Load forecast': emptyProps(),
        'Load forecast success': props<{ forecast: Forecast | null }>(),
        'Load forecast failure': props<{ error: unknown }>(),
        'Unwatch': emptyProps(),
        //#endregion Forecast

        //#region Event
        'Load event list': props<{ forecastId: ID }>(),
        'Load event list success': props<{ eventList: ForecastEvent[] }>(),
        'Load event list failure': props<{ error: unknown }>(),

        'Create event': props<{ createEvent: CreateForecastEvent }>(),
        'Create event success': props<{ createdEvent: ForecastEvent }>(),
        'Create event failure': props<{ error: unknown }>(),

        'Update event': props<{ eventId: ID, updateEvent: UpdateForecastEvent }>(),
        'Update event success': props<{ updatedEvent: ForecastEvent }>(),
        'Update event failure': props<{ error: unknown }>(),

        'Expand to next event': props<{ eventId: ID }>(),
        'Expand to next event success': props<{ updatedEvent: ForecastEvent }>(),
        'Expand to next event failure': props<{ error: unknown }>(),

        'Try delete event': props<{ eventId: ID }>(),
        'Delete event': props<{ eventId: ID }>(),
        'Delete event success': props<{ deletedEvent: ForecastEvent }>(),
        'Delete event failure': props<{ error: unknown }>(),

        'Set editable event ID': props<{ eventId: ID | null }>(),
        //#endregion Event

        //#region Import from Well plan
        'Try import from Well plan': emptyProps(),
        'Import from Well plan': emptyProps(),
        'Import from Well plan success': props<{ forecast: Forecast }>(),
        'Import from Well plan failure': props<{ error: unknown }>(),
        //#endregion Import from Well plan

        //#region Export
        'Export': emptyProps(),
        'Export success': emptyProps(),
        'Export failure': props<{ error: unknown }>(),
        //#endregion Export

        //#region Equipment And Personnel event
        'Load Equipment And Personnel event list': props<{ forecastId: ID }>(),
        'Load Equipment And Personnel event list success': props<{ equipmentAndPersonnelEventList: ForecastEquipmentAndPersonnelEvent[] }>(),
        'Load Equipment And Personnel event list failure': props<{ error: unknown }>(),

        'Create Equipment And Personnel event': props<{ createEquipmentAndPersonnelEvent: CreateForecastEquipmentAndPersonnelEvent }>(),
        'Create Equipment And Personnel event success': props<{ createdEquipmentAndPersonnelEvent: ForecastEquipmentAndPersonnelEvent }>(),
        'Create Equipment And Personnel event failure': props<{ error: unknown }>(),

        'Update Equipment And Personnel event': props<{ equipmentAndPersonnelEventId: ID, updateEquipmentAndPersonnelEvent: UpdateForecastEquipmentAndPersonnelEvent }>(),
        'Update Equipment And Personnel event success': props<{ updatedEquipmentAndPersonnelEvent: ForecastEquipmentAndPersonnelEvent }>(),
        'Update Equipment And Personnel event failure': props<{ error: unknown }>()
        //#endregion Equipment And Personnel event
    }
});


/**
 * Actions for forecast
 * */
export const PublicForecastActions = {
    loadForecast: ForecastActions.loadForecast,
    unwatch: ForecastActions.unwatch,
    createEvent: ForecastActions.createEvent,
    updateEvent: ForecastActions.updateEvent,
    expandToNextEvent: ForecastActions.expandToNextEvent,
    tryDeleteEvent: ForecastActions.tryDeleteEvent,
    setEditableEventId: ForecastActions.setEditableEventId,
    tryImportFromWellPlan: ForecastActions.tryImportFromWellPlan,
    createEquipmentAndPersonnelEvent: ForecastActions.createEquipmentAndPersonnelEvent,
    updateEquipmentAndPersonnelEvent: ForecastActions.updateEquipmentAndPersonnelEvent
};
