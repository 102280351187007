import { Directive, EmbeddedViewRef, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';

import { isNil } from 'lodash';

/**
A directive that displays a template when an ngFor loop has no items to display.
@remarks
This directive is used as an attribute on an element containing an ngFor directive.
@example
html
<div *ngFor="let item of items; empty emptyState">
{{ item }}
</div>
<ng-template #emptyState>No items to display</ng-template>
@publicApi
*/
@Directive({
    selector: '[ngFor][ngForOf][ngForEmpty]',
    standalone: true
})
export class NgForEmptyDirective implements OnChanges {
    private ref?: EmbeddedViewRef<object>;

    /**
    Creates an instance of NgForEmptyDirective.
    @param vcr - The view container reference to create the embedded view in.
    */
    constructor(private readonly vcr: ViewContainerRef) { }

    /**
    The iterable expression to update the view with when there are no items to display.
    */
    @Input()
    ngForOf?: unknown[] | null;

    /**
    The template to display when there are no items to display.
    */
    @Input()
    ngForEmpty: TemplateRef<object> | null = null;

    /**
    Lifecycle hook that is called when any data-bound property of the directive changes.
    @remarks
    This method checks if the ngForEmpty template should be displayed and creates the
    embedded view if necessary.
    */
    ngOnChanges() {
        this.ref?.destroy();

        if (!isNil(this.ngForEmpty) && this.ngForOf?.length === 0) {
            this.ref = this.vcr.createEmbeddedView(this.ngForEmpty);
        }
    }
}
