import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, ClNumericInputComponent, UnitsSelectors, WellKnownUnitGroupIds, WellKnownUnitIds } from '@cyberloop/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { combineLatest, map, shareReplay } from 'rxjs';

import { ClTimeRangeSelectorComponent } from '../../../time-range-selector/time-range-selector.component';
import { KpiWidgetSettingsComponent } from '../../widget-settings/kpi-widget-settings.component';

import type { ConnectionTimeWidgetSettings, WidgetSettingsTimeRange } from '@cyberloop/web/wells/model';
import type { PopupContent } from '@cyberloop/core';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        FormsModule,
        ReactiveFormsModule,
        KpiWidgetSettingsComponent,
        ClTimeRangeSelectorComponent,
        ClButtonToggleComponent,
        ClNumericInputComponent
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class SettingsComponent implements PopupContent<ConnectionTimeWidgetSettings> {

    private _closeFn?: (result: ConnectionTimeWidgetSettings | null) => void;

    constructor(
        private readonly store: Store
    ) { }

    readonly form = new FormGroup({
        timeRange: new FormControl<WidgetSettingsTimeRange | null>(null, [ Validators.required ]),
        yAxisMin: new FormControl<number | null>(null),
        yAxisMax: new FormControl<number | null>(null),
        target: new FormControl<number | null>(null),
        units: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ])
    });

    readonly unitsMap$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Time)).pipe(
        map(group => group.units),
        shareReplay(1)
    );

    readonly units$ = this.unitsMap$.pipe(
        map(units => Object.values(units))
    );

    readonly selectedUnit$ = combineLatest([
        this.unitsMap$,
        this.form.valueChanges.pipe(
            map(value => value.units)
        )
    ]).pipe(
        map(([units, unitId]) => unitId ? units[unitId] : null)
    );

    setData(data: ConnectionTimeWidgetSettings): void {
        if (data.timeRange) {
            this.form.controls.timeRange.setValue(data.timeRange);
        }

        if (data.timeUnitId) {
            this.form.controls.units.setValue(data.timeUnitId as WellKnownUnitIds);
        }

        if (!isNil(data.yMin)) {
            this.form.controls.yAxisMin.setValue(data.yMin);
        }
        if (!isNil(data.yMax)) {
            this.form.controls.yAxisMax.setValue(data.yMax);
        }
        if (!isNil(data.target)) {
            this.form.controls.target.setValue(data.target);
        }
    }

    setClose(fn: (result: ConnectionTimeWidgetSettings | null) => void): void {
        this._closeFn = fn;
    }

    onCancel(): void {
        this._closeFn?.(null);
    }

    onSubmit(): void {
        // Compose settings

        const result: ConnectionTimeWidgetSettings = {};

        if (this.form.controls.timeRange.value) {
            result.timeRange = this.form.controls.timeRange.value;
        }

        if (this.form.controls.units.value) {
            result.timeUnitId = this.form.controls.units.value;
        }

        if (!isNil(this.form.controls.yAxisMin.value) && String(this.form.controls.yAxisMin.value).length > 0) {
            result.yMin = parseInt(String(this.form.controls.yAxisMin.value), 10);
        }
        if (!isNil(this.form.controls.yAxisMax.value) && String(this.form.controls.yAxisMax.value).length > 0) {
            result.yMax = parseInt(String(this.form.controls.yAxisMax.value), 10);
        }

        if (!isNil(this.form.controls.target.value) && String(this.form.controls.target.value).length > 0) {
            result.target = parseInt(String(this.form.controls.target.value), 10);
        }

        this._closeFn?.(result);
    }

}
