import { ID } from '@cyberloop/core';
import { Forecast, ForecastEquipmentAndPersonnelEvent, ForecastEvent } from '@cyberloop/web/wells/model';

export type LoadingState = 'NOT_LOADED' | 'LOADED' | 'LOADING';

export const FORECAST_FEATURE = 'FORECAST';

/**
 * State for forecast
 * */
export interface ForecastState {
    forecast: Forecast | null;
    forecastLoadingState: LoadingState;
    forecastError?: unknown;

    eventList: ForecastEvent[];
    eventListLoadingState: LoadingState;
    eventListError?: unknown;

    createEventLoadingState: LoadingState;
    createEventError?: unknown;

    updateEventLoadingState: LoadingState;
    updateEventError?: unknown;

    expandToNextEventLoadingState: LoadingState;
    expandToNextEventError?: unknown;

    deleteEventLoadingState: LoadingState;
    deleteEventError?: unknown;

    editableEventId: ID | null;

    importFromWellPlanLoadingState: LoadingState;
    importFromWellPlanError?: unknown;

    exportLoadingState: LoadingState;
    exportError?: unknown;

    equipmentAndPersonnelEventList: ForecastEquipmentAndPersonnelEvent[];
    equipmentAndPersonnelEventListLoadingState: LoadingState;
    equipmentAndPersonnelEventListError?: unknown;

    createEquipmentAndPersonnelEventLoadingState: LoadingState;
    createEquipmentAndPersonnelEventError?: unknown;

    updateEquipmentAndPersonnelEventLoadingState: LoadingState;
    updateEquipmentAndPersonnelEventError?: unknown;
}

export function getDefaultForecastState(): ForecastState {
    return {
        forecast: null,
        forecastLoadingState: 'NOT_LOADED',

        eventList: [],
        eventListLoadingState: 'NOT_LOADED',

        createEventLoadingState: 'NOT_LOADED',
        updateEventLoadingState: 'NOT_LOADED',
        expandToNextEventLoadingState: 'NOT_LOADED',
        deleteEventLoadingState: 'NOT_LOADED',

        editableEventId: null,

        importFromWellPlanLoadingState: 'NOT_LOADED',
        exportLoadingState: 'NOT_LOADED',

        equipmentAndPersonnelEventList: [],
        equipmentAndPersonnelEventListLoadingState: 'NOT_LOADED',

        createEquipmentAndPersonnelEventLoadingState: 'NOT_LOADED',
        updateEquipmentAndPersonnelEventLoadingState: 'NOT_LOADED'
    };
}
