import type { PlanningTndChartSettings } from "@cyberloop/web/planning/shared/model";
import { planningTndChartDefaultSettings } from '@cyberloop/web/planning/shared/model';

export const PLANNING_ENGINEERING_TND_FEATURE = 'Planning / Engineering / T&D';

/**
 * State for Engineering / T&D
 * */
export interface PlanningEngineeringTndState {
    error?: Error;

    /** Settings of the Weights chart */
    weightsSettings: PlanningTndChartSettings;
    /** Settings of the Torque chart */
    torqueSettings: PlanningTndChartSettings;
}

export function getDefaultPlanningEngineeringTndState(): PlanningEngineeringTndState {
    return {
        weightsSettings: planningTndChartDefaultSettings,
        torqueSettings: planningTndChartDefaultSettings
    };
}
