<div class="chart-container" [class.skeleton]="skeleton">
  <ng-container *ngIf="!skeleton">
    <section class="legend">
      <div class="item">
        <div class="point accent-4"></div> Planned
      </div>
      <div class="item">
        <div class="point prim"></div> Real
      </div>
    </section>
    <section class="chart">
      <cyberloop-dd-chart-3d [activatedSeries]="activatedSeries" [data]="data"></cyberloop-dd-chart-3d>
    </section>
    <section class="right-col" scrollShadow>
      <div class="label-container">
        <label>Wellbores</label>
        <hr>
      </div>
      
      <div class="wellbores">
        <cl-checkbox-group class="sections-selector" [selected]="selectedSections" (selectedChange)="sectionSelect.emit($event)"
              [list]="sections ?? []" [cutCorners]="true" [colors]="seriesColors"></cl-checkbox-group>
      </div>

      <div class="plan-container">
        <label>Current wellplan</label>
        <cl-selector [options]="wellPlansOptions" (valueChange)="planSelect.emit($event)" [value]="selectedWellPlan"></cl-selector>
      </div>
    </section>
  </ng-container>
</div>