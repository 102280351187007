import type { Type } from '@angular/core';

import { PopupContent } from '@cyberloop/core';

export type WidgetSettingsAdditionalConfig<TData = never> = {
    data?: TData,
    maxWidth?: string,
    maxHeight?: string,
}

export abstract class WidgetSettingsHandler {

    abstract showSettings<T, T2 = never>(widgetId: string, component: Type<PopupContent<T>>, defaultSettings?: T, additional?: WidgetSettingsAdditionalConfig<T2>): Promise<void>;

}
