import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Injector, Input } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { interval } from 'rxjs';
import { map } from 'rxjs/operators';

/** @internal */
const MAX_STEP = 5;

/** @internal */
const selector = "pseudo-loader";

/** This component shows "pseudo" loader that uses non-linear fill scenario to show that something happens */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: selector,
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        MatProgressBarModule
    ],
    template: `<div><p *ngIf="text">{{ text }}</p><mat-progress-bar [value]="value$ | async"></mat-progress-bar></div>
`,
    styleUrls: ['./pseudo-loader.component.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PseudoLoader {
    private readonly _updInterval = 150;

    private _value = 0;

    /** Text to show above progressbar */
    @Input() text: string | undefined;

    /** @internal */
    readonly value$ = interval(this._updInterval).pipe(
        map(() => {
            if (this._value < 40) {
                this._value += Math.random() * MAX_STEP;
            } else if (this._value < 60) {
                this._value += (Math.random() * MAX_STEP) / 2;
            } else if (this._value < 87) {
                this._value += (Math.random() * MAX_STEP) / 8;
            } else if (this._value < 95) {
                this._value += (Math.random() * MAX_STEP) / 64;
            } else if (this._value < 99.9) {
                this._value += (Math.random() * MAX_STEP) / 256;
            } else {
                this._value = Math.random() * 80;
            }

            return this._value;
        })
    );

    /** @internal */
    static register(injector: Injector) {
        const el = createCustomElement(PseudoLoader, { injector });
        customElements.define(selector, el);
    }
}
