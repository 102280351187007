import { TndChart, TndRealm } from '@cyberloop/web/tnd/model';
import { Observable } from 'rxjs';

import type { TndChartModel, TndChartModelDataset, TndChartModelWithData } from '@cyberloop/web/tnd/model';

/**
 * Provides Model data for T&D chart data
 */
export abstract class TndModelProvider {

    /**
     * Provides a list of models
     */
    abstract list(realm: TndRealm, realmId: string, kind: TndChart): Observable<TndChartModel[]>;

    /**
     * Provides a model by ID
     */
    abstract get(id: string): Observable<TndChartModel | undefined>;

    /**
     * Provides an active model by ID
     */
    abstract getActive(realm: TndRealm, realmId: string, kind: TndChart): Observable<TndChartModel | undefined>;

    /**
     * Provides the model's data by model ID
     */
    abstract getData(id: string): Observable<TndChartModelDataset[]>;

    /**
     * Creates a new model
     */
    abstract createModel(realm: TndRealm, realmId: string, data: Omit<TndChartModelWithData, 'id'>): Observable<string | false>;

    /**
     * Updates the existing model
     */
    abstract updateModel(realm: TndRealm, realmId: string, data: TndChartModelWithData): Observable<boolean>;

    /**
     * Drops the existing model
     */
    abstract deleteModel(id: string): Observable<boolean>;

    /**
     * Makes the model active
     */
    abstract activateModel(id: string, wellId?: string): Observable<boolean>;

    /**
     * Deactivates the active model
     */
    abstract deactivateModel(id: string): Observable<boolean>;

}