import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, ClChecboxComponent, UnitsSelectors, WellKnownUnitGroupIds, WellKnownUnitIds } from '@cyberloop/core';
import { PlanningSelectors } from '@cyberloop/web/planning/shared/data';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { KpiWidgetSettingsComponent } from '../../widget-settings/kpi-widget-settings.component';

import type { AdditionalData } from '../models';
import type { PopupContent } from '@cyberloop/core';
import type { DaysVsDepthWidgetSettings } from '@cyberloop/web/wells/model';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        KpiWidgetSettingsComponent,
        FormsModule,
        ReactiveFormsModule,
        ClButtonToggleComponent,
        ClChecboxComponent
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements PopupContent<DaysVsDepthWidgetSettings> {

    private _closeFn?: (result: DaysVsDepthWidgetSettings | null) => void;
    private _data?: DaysVsDepthWidgetSettings & { additionalData: AdditionalData };

    constructor(
        private readonly store: Store
    ) { }

    readonly form = new FormGroup({
        units: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ]),
        showPlan: new FormControl<boolean | null>(false),
        showPlanLimit: new FormControl<boolean | null>(false)
    });

    readonly units$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Length)).pipe(
        map(group => Object.values(group.units))
    );

    readonly plan$ = this.store.select(PlanningSelectors.planning);
    readonly havePlan$ = this.plan$.pipe(map(Boolean));
    readonly planName$ = this.plan$.pipe(map(plan => plan?.name ?? 'N/A'));

    showPlan = false;
    showPlanLimit = false;

    setData(data: DaysVsDepthWidgetSettings & { additionalData: AdditionalData }): void {
        this._data = data;

        if (data.depthUnitId) {
            this.form.controls.units.setValue(data.depthUnitId as WellKnownUnitIds);
        }
        if (typeof data.showPlan !== 'undefined') {
            this.showPlan = data.showPlan;
        }
        if (typeof data.showPlanLimit !== 'undefined') {
            this.showPlanLimit = data.showPlanLimit;
        }
    }

    setClose(fn: (result: DaysVsDepthWidgetSettings | null) => void): void {
        this._closeFn = fn;
    }

    onCancel(): void {
        this._closeFn?.(null);
    }

    onSubmit(): void {
        // Compose settings

        const result: DaysVsDepthWidgetSettings = {};

        if (this.form.controls.units.value) {
            result.depthUnitId = this.form.controls.units.value;
        }

        result.showPlan = this.showPlan;
        result.showPlanLimit = this.showPlanLimit;

        this._closeFn?.(result);
    }

}
