import { createAction, props } from '@ngrx/store';

import { DD_FEATURE } from './dd.state';

const setSelectedSections = createAction(`[${DD_FEATURE}] Set selected sections`, props<{ wellID?: string, sections: number[] | null }>());
const setWellPlan = createAction(`[${DD_FEATURE}] Set well plan`, props<{ wellID?: string, wellPlanId: string | null }>());

/**
 * Actions for directional drilling 
 * */
export const PublicDirectionalDrillingActions = {
    setSelectedSections,
    setWellPlan
};

/**
 * @internal
 * Actions for directional drilling used internally
 * */
export const DirectionalDrillingActions = {
    ...PublicDirectionalDrillingActions
};
