import { PlatformModule } from '@angular/cdk/platform';
import { AsyncPipe, DOCUMENT, NgIf } from '@angular/common';
import { Component, Inject, forwardRef } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { CoreActions, CoreSelectors } from '@cyberloop/core';
import { AppSelectors, RecentItemsDirective } from '@cyberloop/web/app/data';
import { Applications, NavigationItem } from '@cyberloop/web/app/model';
import { AppComponent } from '@cyberloop/web/app/ui';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';

@Component({
    selector: 'app-container',
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        AppComponent,
        PlatformModule,
        RouterOutlet,
        forwardRef(() => RecentItemsDirective)
    ],
    template: `<cl-root
        [appName]="appName$|async"
        [userAvatar]="avatar$|async"
        [displayName]="displayName$|async"
        [login]="login$|async"
        [navigationItems]="navigationItems$ | async"
        [recentItems]="recentItems$ | async"
        (navigationRequest)="handleNavigationRequest($event)"
        (logout)="logout()"
    ><router-outlet></router-outlet></cl-root>`,
})
export class AppContainerComponent {
    constructor(
        iconRegistry: MatIconRegistry,
        private readonly router: Router,
        private readonly activatedRoot: ActivatedRoute,
        private readonly store: Store,
        @Inject(DOCUMENT) doc: Document
    ) {
        iconRegistry.setDefaultFontSetClass('material-icons-outlined');

    }

    readonly appName$ = this.router.events.pipe(
        filter(x => x instanceof NavigationEnd),
        map(() => this.activatedRoot.firstChild?.routeConfig),
        map(x => !x || x.path === Applications.Dashboard || typeof x.title !== 'string' ? '' : x.title)
    );

    readonly user$ = this.store.select(CoreSelectors.auth.currentUser);
    readonly displayName$ = this.user$.pipe(map(x => x?.displayName ?? '-'));
    readonly login$ = this.user$.pipe(map(x => x?.email ?? '-'));
    readonly avatar$ = this.user$.pipe(map(x => x?.photoURL || null));

    readonly isAuthenticated = this.store.select(CoreSelectors.auth.isLoggedIn);

    readonly navigationItems$ = this.store.select(AppSelectors.apps);

    readonly recentItems$ = this.store.select(AppSelectors.recentItems);

    logout() {
        this.store.dispatch(CoreActions.auth.logout());
    }

    handleNavigationRequest(link: string) {
        this.router.navigateByUrl(link);
    }
}
