<header class="header">
    <div class="row">
        <div class="block">
            <div class="label">well</div>
            <div class="value">{{wellName|noData}}</div>
        </div>
        <div class="block">
            <div class="label">Section</div>
            <button class="section" [matMenuTriggerFor]="sectionsMenu" #sectionsMenuTrigger="matMenuTrigger">
                <span class="value">{{sectionName|noData}}</span>
                <div class="expand">
                    <icon name="arrow__down"></icon>
                </div>
                <mat-menu #sectionsMenu="matMenu" class="sections-menu-panel">
                    <cyberloop-sections-info [sections]="sections" [current]="sectionId"
                        (selectSection)="selectSection.emit($event); sectionsMenuTrigger.closeMenu()"></cyberloop-sections-info>
                </mat-menu>
            </button>
        </div>
        <ul class="nav">
            <li *ngFor="let item of navigationItems" routerLinkActive="active">
                <a [routerLink]="item.link" class="nav-item">
                    <svg class="icon" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <use [attr.href]="'assets/nav.svg#'+item.icon" fill="none" />
                    </svg>
                    <span>{{item.text}}</span>
                </a>
            </li>
        </ul>

        <button class="btn-go-back cl-icon-button outlined" (click)="toListOfWells.emit()">
            <icon class="icon">arrow__up</icon>
        </button>
    </div>

    <div class="row" *ngIf="activatedRoute?.items?.length">
        <ul class="subnav">
            <li *ngFor="let item of activatedRoute?.items" routerLinkActive="active">
                <a [routerLink]="activatedRoute?.link + '/' + item.link" class="subnav-item">{{ item.text }}</a>
            </li>
        </ul>
    </div>
    <div class="row" *ngIf="kpiIsActivated">
        <ul class="subnav kpi">
            <ng-container *ngIf="dashboards">
                <li *ngFor="let item of dashboards; let i = index" [class.active]="item.id === activeDashboard">
                    <button class="subnav-item" (click)="selectDashboard.emit(item.id)">{{ item.title }}</button>
                    <button class="dots" mat-icon-button [matMenuTriggerFor]="menu">
                        <icon name="dots__horizontal"></icon>
                    </button>
                    <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="editDashboard.emit(item.id)">
                            <icon name="edit"></icon>
                            Edit name
                        </button>
                        <button mat-menu-item (click)="deleteDashboard.emit(item.id)" *ngIf="countOfDashboards > 1">
                            <icon name="delete"></icon>
                            Delete
                        </button>
                    </mat-menu>
                </li>
                <li>
                    <button class="subnav-item" (click)="addDashboard.emit()">Add dashboard</button>
                </li>
            </ng-container>

            <li class="add-widget">
                <button (click)="addWidget.emit()">
                    <icon name="plus"></icon> add widget
                </button>
            </li>
        </ul>
    </div>
</header>

<main class="container">
    <router-outlet></router-outlet>
</main>