import { TndChart } from '@cyberloop/web/tnd/model';
import { wellsTndChartDefaultSettings } from '@cyberloop/web/wells/model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PLANNING_ENGINEERING_TND_FEATURE } from './tnd.state';

import type { PlanningEngineeringTndState } from './tnd.state';

const feature = createFeatureSelector<PlanningEngineeringTndState>(PLANNING_ENGINEERING_TND_FEATURE);

// Charts settings

/** @internal */
const weightsChartSettings = createSelector(feature, state => state.weightsSettings ?? wellsTndChartDefaultSettings);

/** @internal */
const torqueChartSettings = createSelector(feature, state => state.torqueSettings ?? wellsTndChartDefaultSettings);

/** @internal */
const chartSettings = (type: TndChart) =>
    createSelector(feature, state => type === TndChart.Torque ? state.torqueSettings : state.weightsSettings);


/** @internal */
const weightsChartSelectedModelId = createSelector(weightsChartSettings, settings => settings.selectedModelId);
/** @internal */
const torqueChartSelectedModelId = createSelector(torqueChartSettings, settings => settings.selectedModelId);
/** @internal */
const selectedModelId = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return torqueChartSelectedModelId;

        default:
            return weightsChartSelectedModelId;
    }
};

/**
 * Selectors for Wells T&D
 * */
export const TndSelectors = {
    weightsChartSettings,
    torqueChartSettings,
    chartSettings,

    weightsChartSelectedModelId,
    torqueChartSelectedModelId,
    selectedModelId
};

/**
 * Public Selectors for Wells T&D
 * */
export const PublicTndSelectors = {
    weightsChartSettings,
    torqueChartSettings,
    chartSettings,

    weightsChartSelectedModelId,
    torqueChartSelectedModelId,
    selectedModelId
};