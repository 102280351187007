import { cloneDeep } from 'lodash';

import { Section } from '../models';

/** @internal Section tree model */
export interface SectionTree {
    /** section id */
    id: number,
    /** from depth */
    from: number;
    /** to depth */
    to: number | undefined;
    /** start time */
    startTime: Date;
    /** end time */
    endTime: Date | undefined;
    /** parent section id */
    parentId: number | undefined;
}

/**
 * Filtered surveys according sections tree
 * @param data array of surveys
 * @param sections all sections in well
 * @param sectionId last child
 * @param isSurvey flag survey or slidesheet
 * @returns filtered array of surveys
 */
export async function filteredBySectionTree<T extends { sectionID: string, md: number, hd: number, number: number }>(data: T[], sections: Section[], sectionId: number, isSurvey = true): Promise<T[]> {
    const sectionTree: SectionTree[] | undefined = buildSectionTree(sections, sectionId);
    if (!sectionTree) {
        return data;
    }

    let next: SectionTree | null = null;
    const allSurveys: T[] = [];
    for (const section of sectionTree) {
        if (!section || !Number.isFinite(section.from)) {
            console.warn(`Have wrong section range depth from/to ${section.from}/${section.to}`);
            continue;
        }

        const key = isSurvey ? 'md' : 'hd';
        const surveys: T[] = cloneDeep(data).filter(x => +x?.['sectionID'] === +section.id);
        const to = next && Number.isFinite(next?.from) ? next.from : section.to;
        const from = section.from;

        let partOfSurveys: T[] | null = null;
        if (!to) {
            partOfSurveys = surveys.filter(x => x[key] >= from || x?.['number'] === 0);
        } else {
            partOfSurveys = surveys.filter(x => x[key] >= from && x?.[key] <= to || x?.['number'] === 0);
        }

        allSurveys.unshift(...partOfSurveys);
        next = section;
    }

    return allSurveys;
}

/**
 * Return well depth range base on last child section include all parent
 * @param sections all sections in well
 * @param sectionId last child
 * @returns
 */
export function depthRangeIncludesParents(sections: Section[] | undefined, sectionId: number): { from: number, to: number | undefined } | undefined {
    if (!sections) {
        return;
    }
    const sectionTree = buildSectionTree(sections, sectionId);

    if (!sectionTree) {
        return;
    }

    return { from: sectionTree[sectionTree?.length - 1].from, to: sectionTree[0].to }
}

/**
 * Returns section tree - from leaf to the root
 * @param sections all sections in well
 * @param section last child as a Section
 */
export function buildSectionTree(sections: Section[], section: Section): SectionTree[] | undefined;
/**
 * Return section tree - from leaf to the root
 * @param sections all sections in well
 * @param sectionId last child as a Section ID
 */
export function buildSectionTree(sections: Section[], sectionId: number): SectionTree[] | undefined;
/**
 * Return section tree - from leaf to the root
 * @param sections all sections in well
 * @param sectionId last child as a Section or it's ID
 */
export function buildSectionTree(sections: Section[], sectionOrId: Section | number): SectionTree[] | undefined {
    const sectionId = typeof sectionOrId === 'number' ? sectionOrId : sectionOrId.id;

    let section: Section | undefined = sections.find((x) => x.id === sectionId);
    let nextSectionStart: number | null = null;
    let nextSectionStartTime: Date | null = null;
    const sectionTree: SectionTree[] = [];
    while (section) {
        sectionTree.push({
            id: section.id,
            from: section.from,
            to: nextSectionStart ?? section.to,
            startTime: section.startTime,
            endTime: nextSectionStartTime ?? section.endTime,
            parentId: section.parent
        });
        nextSectionStart = section.from;
        nextSectionStartTime = section.startTime;
        section = sections.find((x) => x.id === section?.parent);
    }

    return sectionTree;
}