export { WidgetsSelectorComponent } from './lib/kpi/widgets-selector/widgets-selector.component';
export { AssetDailyMetricsWidgetComponent } from './lib/kpi/widgets/asset-daily-metrics-widget/asset-daily-metrics-widget.component';
export { BitBottomWidgetComponent } from './lib/kpi/widgets/bit-bottom-widget/bit-bottom-widget.component';
export { ConnectionTimeWidgetComponent } from './lib/kpi/widgets/connection-time-widget/connection-time-widget.component';
export { DaysVsDepthWidgetComponent } from './lib/kpi/widgets/days-vs-depth-widget/days-vs-depth-widget.component';
export { RigActivityWidgetComponent } from './lib/kpi/widgets/rig-activity-widget/rig-activity-widget.component';
export { RigActivityPerHourWidgetComponent } from './lib/kpi/widgets/rig-state-per-hour-widget/rig-state-per-hour-widget.component';
export { RigStateWidgetComponent } from './lib/kpi/widgets/rig-state-widget/rig-state-widget.component';
export { SlideRotaryWidgetComponent } from './lib/kpi/widgets/slide-rotary-widget/slide-rotary-widget.component';
export { SlideVsRotaryWidgetComponent } from './lib/kpi/widgets/slide-vs-rotary-widget/slide-vs-rotary-widget.component';
export { TrippingSpeedWidgetComponent } from './lib/kpi/widgets/tripping-speed-widget/tripping-speed-widget.component';
export { CasingRunningWidgetComponent } from './lib/kpi/widgets/casing-running-widget/casing-running-widget.component';
export { RateOfAdvanceWidgetComponent } from './lib/kpi/widgets/rate-of-advance-widget/rate-of-advance-widget.component';
export { RopWidgetComponent } from './lib/kpi/widgets/rop-widget/rop-widget.component';
export { CustomVsTimeWidgetComponent } from './lib/kpi/widgets/custom-vs-time/custom-vs-time-widget.component';
export { KpiWidgetComponent } from './lib/kpi/widgets/widget/kpi-widget.component';

export { SetPlanComponent } from './lib/set-plan/set-plan.component';
export { SingleWellComponent } from './lib/single-well/single-well.component';
export { WellCardSkeletonComponent } from './lib/well-card-skeleton/well-card-skeleton.component';
export { WellCardComponent } from './lib/well-card/well-card.component';
export { WellChartDialogComponent } from './lib/well-chart-dialog/well-chart-dialog.component';
export { WellsPageTopBarComponent } from './lib/wells-page-top-bar/wells-page-top-bar.component';
export { WellsPageTopBarSkeletonComponent } from './lib/wells-page-top-bar-skeleton/wells-page-top-bar-skeleton.component';
export { DdDashboardComponent } from './lib/dd/dd-dashboard/dd-dashboard.component';
export { DdDashboardSkeletonComponent } from './lib/dd/dd-dashboard-skeleton/dd-dashboard-skeleton.component';
export { DdChartsComponent } from './lib/dd/dd-charts/dd-charts.component';
export { DdChartsSkeletonComponent } from './lib/dd/dd-charts-skeleton/dd-charts-skeleton.component';
export { DdCharts3dComponent } from './lib/dd/dd-charts-3d/dd-charts-3d.component';
export { DdWellplanComponent } from './lib/dd/dd-wellplan/dd-wellplan.component';

export { WorkspaceSelectorComponent } from './lib/workspace-selector/workspace-selector.component';
export { CardsCarouselComponent } from './lib/kpi/cards-carousel/cards-carousel.component';

