<cyberloop-kpi-widget heading="Rig State per Hour" [size]="size" class="widget" (settingsRequested)="onSettings()" (deleteRequested)="onDelete()">
    <ul class="chart-custom-legend" widget-header *ngIf="canShowData$ | async">
        <li class="wde">
            <icon name="kpi__rig_state_per_hour_legend_line" class="marker"></icon>
            Well Depth
        </li>
        <li class="bde">
            <icon name="kpi__rig_state_per_hour_legend_line_notched" class="marker"></icon>
            Bit Depth
        </li>
    </ul>
    <div class="chart-container" widget-body [class.loading]="dataLoading$ | async">
        <cyberloop-chart
            [options]="options"
            class="chart"
            [class.hidden]="!(canShowData$ | async)"
        ></cyberloop-chart>
        <div class="placeholder graphic" *ngIf="dataLoading$ | async">
            <div *ngFor="let column of placeholderColumns" class="column">
                <span [style.top]="column[0] + '%'" [style.height]="column[1] + '%'"></span>
            </div>
        </div>
        <div class="placeholder text" *ngIf="textPlaceholder$ | async">
            <p>{{ textPlaceholder$ | async }}</p>
        </div>
    </div>
</cyberloop-kpi-widget>