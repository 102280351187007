/* eslint-disable no-prototype-builtins */
import { isEqual, isObject } from 'lodash';

/** @internal */
type AnyObject = Record<string, unknown>;

/** @internal */
type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends Record<string, unknown> | AnyObject[] ? DeepPartial<T[P]> : T[P];
};


/**
 * Calculate the deep difference between two objects.
 * @param obj1 The original object.
 * @param obj2 The changed object.
 * @returns A DeepPartial object with differences between the two input objects.
 */
export function deepDiff<T>(obj1: DeepPartial<T>, obj2: DeepPartial<T>): DeepPartial<T> {
    return internalDeepDiff(obj1, obj2);
}

/**
 * Internal function to calculate the deep difference between two objects.
 * @internal
 * @param obj1 The original object.
 * @param obj2 The changed object.
 * @param visited A Set of visited objects for circular reference handling.
 * @returns A DeepPartial object with differences between the two input objects.
 */
function internalDeepDiff<T>(
    obj1: DeepPartial<T>,
    obj2: DeepPartial<T>,
    visited: Set<unknown> = new Set()
): DeepPartial<T> {
    if (visited.has(obj1)) {
        return {};
    }

    visited.add(obj1);

    const result = {} as DeepPartial<T>;

    for (const key in obj1) {
        if (!obj2.hasOwnProperty(key)) {
            (result as AnyObject)[key] = undefined;
        } else {
            const value1 = (obj1 as AnyObject)[key];
            const value2 = (obj2 as AnyObject)[key];

            if (Array.isArray(value1) && Array.isArray(value2)) {
                if (!isEqual(value1, value2)) {
                    (result as AnyObject)[key] = value2;
                }
            } else if (isObject(value1) && isObject(value2)) {
                const diff = internalDeepDiff(value1, value2, visited);
                if (Object.keys(diff).length > 0) {
                    (result as AnyObject)[key] = diff;
                }
            } else if (!isEqual(value1, value2)) {
                (result as AnyObject)[key] = value2;
            }
        }
    }

    for (const key in obj2) {
        if (!obj1.hasOwnProperty(key)) {
            (result as AnyObject)[key] = (obj2 as AnyObject)[key];
        }
    }

    return result;
}