import { createReducer, on } from '@ngrx/store';

import { FilesActions } from './files.actions';
import { FilesState, adapter, initialState } from './files.state';

export const filesReducer = createReducer<FilesState>(initialState,
    //#region Load list
    on(
        FilesActions.loadWellFileList,
        FilesActions.loadPlanningFileList,
        (state): FilesState => ({
            ...state,
            listLoading: true
        })
    ),
    on(FilesActions.loadListSuccess, (state, { list }): FilesState => (
        adapter.upsertMany<FilesState>(list, {
            ...state,
            listLoading: false
        })
    )),
    on(FilesActions.loadListFailure, (state): FilesState => ({
        ...state,
        listLoading: false
    })),
    //#endregion Load list

    //#region Load by IDs
    on(FilesActions.loadListByIds, (state): FilesState => ({
        ...state,
        listByIDsLoading: true
    })),
    on(FilesActions.loadListByIdsSuccess, (state, { list }): FilesState => (
        adapter.upsertMany<FilesState>(list, {
            ...state,
            listByIDsLoading: false
        })
    )),
    on(FilesActions.loadListByIdsFailure, (state): FilesState => ({
        ...state,
        listByIDsLoading: false
    })),
    //#endregion Load by IDs

    //#region Download
    on(FilesActions.download, (state): FilesState => ({
        ...state,
        downloadLoading: true
    })),
    on(
        FilesActions.downloadSuccess,
        FilesActions.downloadFailure,
        (state): FilesState => ({
            ...state,
            downloadLoading: false
        })
    ),
    //#endregion Download

    //#region Upload
    on(
        FilesActions.uploadWellFile,
        FilesActions.uploadWellMeterageFile,
        FilesActions.uploadPlanningFile,
        FilesActions.uploadPlanningStageFile,
        (state): FilesState => ({
            ...state,
            uploadLoading: true
        })
    ),
    on(
        FilesActions.uploadSuccess,
        (state, { meta }): FilesState =>
            adapter.addOne(meta, {
                ...state,
                uploadLoading: false
            })
    ),
    on(FilesActions.uploadFailure, (state): FilesState => ({
        ...state,
        uploadLoading: false
    })),
    //#endregion Upload

    //#region Update
    on(FilesActions.update, (state): FilesState => ({
        ...state,
        updateLoading: true
    })),
    on(FilesActions.updateSuccess, (state, { meta }): FilesState =>
        adapter.upsertOne<FilesState>(meta, {
            ...state,
            updateLoading: false
        })
    ),
    on(FilesActions.updateFailure, (state): FilesState => ({
        ...state,
        updateLoading: false
    })),
    //#endregion Update

    //#region Delete
    on(FilesActions.delete, (state): FilesState => ({
        ...state,
        deleteLoading: true
    })),
    on(FilesActions.deleteSuccess, (state, { meta }): FilesState =>
        adapter.removeOne<FilesState>(meta.id, {
            ...state,
            deleteLoading: false
        })
    ),
    on(FilesActions.deleteFailure, (state): FilesState => ({
        ...state,
        deleteLoading: false
    }))
    //#endregion Delete
);
