import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs';

import { ThemeName } from '../../models/themes';
import { CoreActions } from '../../state/core.actions';
import { CoreSelectors } from '../../state/core.selectors';

/** This component provides theme switch UI */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'theme-switcher',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './theme-switcher.component.html',
    styleUrls: ['./theme-switcher.component.scss']
})
export class ThemeSwitcherComponent {
    /** @internal */
    constructor(private readonly store: Store) {

    }

    /** @internal */
    readonly THEME_DARK = ThemeName.Dark;
    /** @internal */
    readonly THEME_LIGHT = ThemeName.Light;

    /** @internal */
    readonly theme$ = this.store.select(CoreSelectors.theme).pipe(
        debounceTime(100),
        distinctUntilChanged(),
        shareReplay(1)
    );

    /** @internal */
    readonly isLightTheme$ = this.theme$.pipe(
        map(x => x === ThemeName.Light)
    );

    /** Set current theme */
    setTheme(themeName: ThemeName) {
        this.store.dispatch(CoreActions.setTheme({ theme: themeName }));
    }
}
