import { TndChart } from '@cyberloop/web/tnd/model';
import { wellsTndChartDefaultSettings } from '@cyberloop/web/wells/model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { WELLS_ENGINEERING_TND_FEATURE } from './tnd.state';

import type { WellsEngineeringTndState } from './tnd.state';

const feature = createFeatureSelector<WellsEngineeringTndState>(WELLS_ENGINEERING_TND_FEATURE);

// Charts settings

/** @internal */
const weightsChartSettings = createSelector(feature, state => state.weightsSettings ?? wellsTndChartDefaultSettings);

/** @internal */
const torqueChartSettings = createSelector(feature, state => state.torqueSettings ?? wellsTndChartDefaultSettings);

/** @internal */
const chartSettings = (type: TndChart) =>
    createSelector(feature, state => type === TndChart.Torque ? state.torqueSettings : state.weightsSettings);

/** @internal */
const weightsChartSelectedSectionId = createSelector(weightsChartSettings, settings => settings.selectedSectionId);
/** @internal */
const torqueChartSelectedSectionId = createSelector(torqueChartSettings, settings => settings.selectedSectionId);
/** @internal */
const selectedSectionId = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return torqueChartSelectedModelId;

        default:
            return weightsChartSelectedModelId;
    }
};

/** @internal */
const weightsChartSelectedModelId = createSelector(weightsChartSettings, settings => settings.selectedModelId);
/** @internal */
const torqueChartSelectedModelId = createSelector(torqueChartSettings, settings => settings.selectedModelId);
/** @internal */
const selectedModelId = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return torqueChartSelectedModelId;

        default:
            return weightsChartSelectedModelId;
    }
};

/** @internal */
const weightsChartActiveModelId = createSelector(weightsChartSettings, settings => settings.activeModelId);
/** @internal */
const torqueChartActiveModelId = createSelector(torqueChartSettings, settings => settings.activeModelId);
/** @internal */
const activeModelId = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return torqueChartActiveModelId;

        default:
            return weightsChartActiveModelId;
    }
};

/**
 * Selectors for Wells T&D
 * */
export const TndSelectors = {
    weightsChartSettings,
    torqueChartSettings,
    chartSettings,

    weightsChartSelectedSectionId,
    torqueChartSelectedSectionId,
    selectedSectionId,

    weightsChartSelectedModelId,
    torqueChartSelectedModelId,
    selectedModelId,

    weightsChartActiveModelId,
    torqueChartActiveModelId,
    activeModelId
};

/**
 * Public Selectors for Wells T&D
 * */
export const PublicTndSelectors = {
    weightsChartSettings,
    torqueChartSettings,
    chartSettings,

    weightsChartSelectedSectionId,
    torqueChartSelectedSectionId,
    selectedSectionId,

    weightsChartSelectedModelId,
    torqueChartSelectedModelId,
    selectedModelId,

    weightsChartActiveModelId,
    torqueChartActiveModelId,
    activeModelId
};