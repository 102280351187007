import { isFunction, isObject } from 'lodash';

/**
 * Freeze object recursivly
 * @param obj Object to be freezed
 * @returns obj
 * @description !NB! This method freezes all objects inside by ref
 */
export function deepFreeze<T extends object>(obj: T): Readonly<T> {
    return deepFreezeInternal(obj);
}

/** @internal */
function deepFreezeInternal<T extends object>(obj: T, processedObjects = new WeakSet<object>()): Readonly<T> {
    if (!obj) {
        return obj;
    }

    // Check if the object is already frozen (processed) or in the processedObjects set, then return it
    if (processedObjects.has(obj)) {
        return obj as Readonly<T>;
    }

    // Add the current object to the processedObjects set
    processedObjects.add(obj);

    // Get the property names of the object
    const propNames = Object.getOwnPropertyNames(obj);

    // Helper function to ensure the key is a valid property of the object
    function isKeyOf<Q extends object, K extends keyof Q>(key: keyof any, obj: Q): key is K {
        return key in obj;
    }

    // Iterate through the property names and freeze the properties
    propNames.forEach((name) => {
        if (isKeyOf(name, obj)) {
            const prop = obj[name];

            // If the property is an object (not null) and not already frozen, recursively call deepFreeze
            if (isObject(prop) &&
                !isFunction(prop)
            ) {
                deepFreezeInternal(prop, processedObjects);
            }
        }
    });

    // Finally, freeze the object itself and return it as Readonly
    return Object.freeze(obj) as Readonly<T>;
}