import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, UnitsSelectors, WellKnownUnitGroupIds, WellKnownUnitIds } from '@cyberloop/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { KpiWidgetSettingsComponent } from '../../widget-settings/kpi-widget-settings.component';

import type { PopupContent } from '@cyberloop/core';
import type { AssetDailyMetricsWidgetSettings } from '@cyberloop/web/wells/model';

@Component({
    standalone: true,
    imports: [
        NgFor,
        AsyncPipe,
        KpiWidgetSettingsComponent,
        ClButtonToggleComponent,
        FormsModule,
        ReactiveFormsModule
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements PopupContent<AssetDailyMetricsWidgetSettings> {

    private _closeFn?: (result: AssetDailyMetricsWidgetSettings | null) => void;
    private _data?: AssetDailyMetricsWidgetSettings;

    constructor(
        private readonly store: Store
    ) { }

    readonly form = new FormGroup({
        ropUnits: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ]),
        distanceUnits: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ]),
        timeUnits: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ])
    });

    readonly ropUnits$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Speed)).pipe(
        map(group => Object.values(group.units))
    );
    readonly distanceUnits$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Length)).pipe(
        map(group => Object.values(group.units))
    );
    readonly timeUnits$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Time)).pipe(
        map(group => Object.values(group.units))
    );

    setData(data: AssetDailyMetricsWidgetSettings): void {
        this._data = data;

        if (data.rotationUnitId) {
            this.form.controls.ropUnits.setValue(data.rotationUnitId as WellKnownUnitIds);
        }
        if (data.distanceUnitId) {
            this.form.controls.distanceUnits.setValue(data.distanceUnitId as WellKnownUnitIds);
        }
        if (data.timeUnitId) {
            this.form.controls.timeUnits.setValue(data.timeUnitId as WellKnownUnitIds);
        }
    }

    setClose(fn: (result: AssetDailyMetricsWidgetSettings | null) => void): void {
        this._closeFn = fn;
    }

    onCancel(): void {
        this._closeFn?.(null);
    }

    onSubmit(): void {
        // Compose settings

        const result: AssetDailyMetricsWidgetSettings = {};

        if (this.form.controls.ropUnits.value) {
            result.rotationUnitId = this.form.controls.ropUnits.value;
        }
        if (this.form.controls.distanceUnits.value) {
            result.distanceUnitId = this.form.controls.distanceUnits.value;
        }
        if (this.form.controls.timeUnits.value) {
            result.timeUnitId = this.form.controls.timeUnits.value;
        }

        this._closeFn?.(result);
    }

}
