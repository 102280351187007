import { Injectable } from '@angular/core';

import { ChartEx, Range } from '@cyberloop/core';
import { ChartSyncHelper } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { Subject, debounceTime } from 'rxjs';

import { DrillingActions } from '../state/drilling';

type ChartWithTooltipPosition = ChartEx & { isTooltipReversed?: boolean };

@Injectable()
export class ChartSyncHelperService extends ChartSyncHelper {
    private readonly _mouseWheelRequestSubj = new Subject<number>();
    private _panStarting = false;
    private _isLive = true;
    private _range: Range | null = null;

    private _panInProcess = false;
    private readonly _charts: ChartWithTooltipPosition[] = [];

    private _tooltipsPoints: { chart: ChartEx, points: Highcharts.Point[] }[] = [];
    private _crosshairs: Record<number, Highcharts.PlotLineOrBand | undefined> = {};

    constructor(private readonly store: Store) {
        super();
        this._mouseWheelRequestSubj.pipe(
            debounceTime(200)
        ).subscribe((x) => {
            this.store.dispatch(DrillingActions.zoomViewport({ zoomIn: x > 0 }));
        });
    }

    public registerChart(chart: ChartEx): void {
        if (!this._charts.includes(chart)) {
            this._charts.push(chart);
            this.setListeners(chart.container);
        }
    }

    public unregisterChart(chart: ChartEx): void {
        if (chart) {
            this.removeListeners(chart.container);
            const idx = this._charts.indexOf(chart);
            this._charts.splice(idx, 1);
        }
    }


    public setViewport(source: ChartEx, range: Range) {
        this._range = range;
        this.syncPan(source, range);
    }

    public syncTooltips(src: ChartEx | undefined, val: number): void {
        if (this._panInProcess) {
            return;
        }

        for (const chart of this._charts) {
            if (!chart || chart === src || !chart.series) {
                continue;
            }

            const points: Highcharts.Point[] = [];

            for (const s of chart.series) {
                const p = this.searchPoint(s, val);

                if (p) {
                    points.push(p);
                }
            }

            if (points.length) {
                this._tooltipsPoints.push({ chart, points });
                chart.tooltip.refresh(points);
            }

            this.drawCrosshair(chart, val);
        }
    }

    public hideAllTooltips(): void {
        for (const chart of this._charts) {
            chart?.tooltip?.hide();
        }

        this.clearAllLines();
    }

    public getTooltipPositioner(isMaster: boolean) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const pThis = this;
        const fn = function (this: Highcharts.Tooltip, width: number, height: number, point: Highcharts.TooltipPositionerPointObject) {
            const chart = this.chart as ChartWithTooltipPosition;
            const isVertical = chart.inverted;

            if (isVertical) {
                let newY;
                if (isMaster) {
                    const needReverse = height + point.plotY > chart.plotHeight;
                    newY = needReverse ? point.plotY - height : point.plotY;
                    for (const c of pThis._charts) {
                        c.isTooltipReversed = needReverse;
                    }
                }
                else {
                    newY = chart.isTooltipReversed ? point.plotY - height : point.plotY;
                }
    
                return { x: chart.chartWidth - width, y: newY };
            } 
            else {
                let newX;
                if (isMaster) {
                    const needReverse = width + point.plotX > chart.plotWidth;
                    newX = needReverse ? point.plotX - width : point.plotX;
                    for (const c of pThis._charts) {
                        c.isTooltipReversed = needReverse;
                    }
                }
                else {
                    newX = chart.isTooltipReversed ? point.plotX - width : point.plotX;
                }
       
                return { x: newX, y: isMaster ? 0 : chart.chartHeight - height };
            }
        };

        return fn;
    }

    public panInProcess(status: boolean) {
        this._panInProcess = status;
        for (const chart of this._charts) {
            try {
                chart.update({ tooltip: { enabled: !status } }, false);
            }
            catch(e) {
                if (e instanceof Error) {
                    console.warn(e.message);
                }
            }
            
        }
    }

    private syncPan(source: ChartEx, range: Range) {
        for (const chart of this._charts) {
            if (chart === source || chart.syncInProcess) {
                continue;
            }

            chart.syncInProcess = true;
            const axis = chart?.xAxis?.[0];
            if (axis) {
                axis?.setExtremes(range.from, range.to, true, false);
            }
            chart.syncInProcess = false;
        }
    }

    private drawCrosshair(chart: ChartEx, value: number) {
        const idx = this._charts.indexOf(chart);
        if (idx < 0) {
            throw new Error('chart must be registered');
        }

        delete this._crosshairs[idx];
        const pl = chart.xAxis[0].addPlotLine({
            width: 1,
            value: value,
            acrossPanes: true
        });

        this._crosshairs[idx] = pl;
    }

    private clearAllLines() {
        for (const chart of this._charts) {
            if ((chart.xAxis?.[0] as any)?.plotLinesAndBands?.length) {
                for (const line of (chart.xAxis[0] as any).plotLinesAndBands) {
                    try {
                        chart.xAxis[0].removePlotLine(line.id);
                        chart.redraw(false);
                    }
                    catch(e) {
                        if (e instanceof Error) {
                            console.warn(e.message);
                        }
                    }
                }
            }
        }
    }

    private searchPoint(s: Highcharts.Series, x: number): Highcharts.Point | null {
        if (!s.data || !s.data.length) {
            return null;
        }

        let i = 0;
        let j = s.data.length - 1;

        let first = s.data[i];
        const last = s.data[j];

        if (typeof first === 'undefined' || first === null) {
            for (let idx = 0; idx < s.data.length; idx++) {
                if (s.data[idx]?.x) {
                    i = idx;
                    first = s.data[idx];
                    break;
                }
            }
        }

        if (!first || !last) {
            return null;
        }

        const min = first.x;
        const max = last.x;
        if (min > x || max < x) {
            return null;
        }

        let n = -1;

        while (i < j) {
            const m = Math.floor((j + i) / 2);
            if (m === n) {
                break;
            }
            n = m;

            const p = s.data[m];
            if (!p) {
                continue;
            }

            if (p.x == x) {
                return s.data[m];
            }

            if (p.x < x) {
                i = m;
            }
            else {
                j = m;
            }
        }

        // return i == j && s.data[i].x == x ? s.data[i] : null;
        return s.data[i];
    }

    /** MOVE TO SYNC CHART */
    private setListeners(container: HTMLElement) {
        if (!container) {
            return;
        }
        container.addEventListener('mousedown', this.mouseDownEvent.bind(this));
        container.addEventListener('mousemove', this.mouseMoveEvent.bind(this));
        container.addEventListener('mouseup', this.mouseUpEvent.bind(this));
        container.addEventListener('wheel', this.mouseWheelEvent.bind(this));
    }

    private removeListeners(container: HTMLElement) {
        if (!container) {
            return;
        }
        container.removeEventListener('mousedown', this.mouseDownEvent.bind(this));
        container.removeEventListener('mousemove', this.mouseMoveEvent.bind(this));
        container.removeEventListener('mouseup', this.mouseUpEvent.bind(this));
        container.addEventListener('wheel', this.mouseWheelEvent.bind(this));
    }

    private mouseDownEvent(ev: MouseEvent) {
        this._panStarting = true;
        this.panInProcess(true);
    }

    private mouseUpEvent(ev: MouseEvent) {
        if (this._panStarting && this._range) {
            this.store.dispatch(DrillingActions.panChanged({ range: this._range }));
            this.panInProcess(false);
        }
        this._panStarting = false;
        this._isLive = true;
    }

    private mouseMoveEvent(ev: MouseEvent) {
        if (this._panStarting && this._isLive !== false) {
            this.store.dispatch(DrillingActions.setLive({ live: false }));
            this._isLive = false;
        }
    }

    private mouseWheelEvent(ev: WheelEvent) {
        if (ev.altKey) {
            ev.preventDefault();
            this._mouseWheelRequestSubj.next(ev.deltaY);
        }
    }
}