import { Timestamp } from 'firebase/firestore';

import { WellMeta } from './well-meta.model';

export type RecentItemMeta<TCustomData = never> = {
    /** Text to be show */
    text: string;
    /** */
    spritePath: SpritePath;
    /** Icon to be shown in. Format of data in this field could depend on project */
    icon: string;

    /** Any custom data to be attached to recent item */
    customData?: TCustomData;
}

/** Item to be shown in Recent items panel */
export type RecentItem<T = never> = RecentItemMeta<T> & {
    /** Time when recent item data was generated */
    time: Date;
    /** Link to redirect to on recent item click */
    link: string;
};

export enum SpritePath {
    ICONS = '/assets/icons-sprite.svg',
    NAV = '/assets/nav.svg'
}

export type RecentItemWithWellId = RecentItem<{ wellId: string }>;
export type RecentItemWithWellMeta = RecentItem<WellMeta>;
export type RecentItemWithWellAndSection = RecentItem<{wellName?: string, sectionName?: string}>;
type RecentItemFromFirestore = Omit<RecentItem<any>, 'time'> & {
    time: Timestamp;
}

export type RecentItemsSettings = {
    items: RecentItemFromFirestore[];
}

export const defaultRecentItemsSettings: RecentItemsSettings = Object.freeze({
    items: []
});

export interface IRecentItemComponent {
    getComponentRecentInfo(): RecentItemMeta<any> | Promise<RecentItemMeta<any>>;
}

export const implementsIRecentItemComponent = (obj: any): obj is IRecentItemComponent => {
    return 'getComponentRecentInfo' in obj && typeof obj.getComponentRecentInfo === 'function';
};