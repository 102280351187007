<div class="element well skeleton-section">
    <span class="element-label skeleton"></span>
    <span class="element-text skeleton"></span>
</div>

<div class="divider skeleton"></div>

<div class="element rig skeleton-section">
    <span class="element-label skeleton"></span>
    <span class="element-text skeleton"></span>
</div>

<div class="divider skeleton"></div>

<div class="element start-date skeleton-section">
    <span class="element-label skeleton"></span>
    <time class="element-text skeleton"></time>
</div>

<div class="element duration skeleton-section">
    <span class="element-label skeleton"></span>
    <time class="element-text skeleton"></time>
</div>

<div class="divider skeleton"></div>

<div class="element description skeleton-section">
    <span class="element-label skeleton"></span>
    <span class="element-text skeleton"></span>
</div>

<div class="expand-block"></div>
