import { Injectable } from '@angular/core';

import { LiveDataProviderService } from '@cyberloop/core';
import { CasingRunningWidgetData, CustomVsTimeWidgetData, RateOfAdvanceWidgetData, RopWidgetData, SlideVsRotaryWidgetData, TrippingSpeedWidgetData, WidgetDataProvider, WidgetType } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { KpiDataService } from './kpi-data.service';
import { getAssetDailyMetricsWidgetData } from './kpi-widget-data/asset-daily-metrics-widget-data';
import { getBitBottomWidgetData } from './kpi-widget-data/bit-on-off-bottom-widget-data';
import { getCasingRunningWidgetData } from './kpi-widget-data/casing-running-widget-data';
import { getConnectionTimeWidgetData } from './kpi-widget-data/connection-time-widget-data';
import { getCustomVsTimeWidgetData } from './kpi-widget-data/custom-vs-time-widget-data';
import { getDaysVsDepthWidgetData } from './kpi-widget-data/days-vs-depth-widget-data';
import { getRateOfAdvanceWidgetData } from './kpi-widget-data/rate-of-advance-widget-data';
import { getRigActivityWidgetData } from './kpi-widget-data/rig-activity-widget-data';
import { getRigStatePerHourWidgetData } from './kpi-widget-data/rig-state-per-hour-widget-data';
import { getRigStateWidgetData } from './kpi-widget-data/rig-state-widget-data';
import { getRopWidgetData } from './kpi-widget-data/rop-widget-data';
import { getSlideRotaryWidgetData } from './kpi-widget-data/slide-rotary-widget-data';
import { getSlideVsRotaryWidgetData } from './kpi-widget-data/slide-vs-rotary-widget-data';
import { getTrippingSpeedWidgetData } from './kpi-widget-data/tripping-speed-widget-data';

import type { AssetDailyMetricsWidgetData, BitBottomWidgetData, ConnectionTimeWidgetData, DaysVsDepthWidgetData, RigActivityWidgetData, RigStatePerHourWidgetData, RigStateWidgetData, SlideRotaryWidgetData, WidgetData } from '@cyberloop/web/wells/model';
type WidgetDataFetcherFunc<T extends WidgetData> = (this: { store: Store; data: KpiDataService; }, id: string) => Observable<T>;

const WIDGET_DATA_FETCHER_MAP: Record<WidgetType, WidgetDataFetcherFunc<WidgetData>> = {
    [WidgetType.AssetDailyMetrics]: getAssetDailyMetricsWidgetData,
    [WidgetType.BitBottom]: getBitBottomWidgetData,
    [WidgetType.ConnectionTime]: getConnectionTimeWidgetData,
    [WidgetType.RigState]: getRigStateWidgetData,
    [WidgetType.RigActivity]: getRigActivityWidgetData,
    [WidgetType.RigStatePerHour]: getRigStatePerHourWidgetData,
    [WidgetType.DaysVsDepth]: getDaysVsDepthWidgetData,
    [WidgetType.SlideRotary]: getSlideRotaryWidgetData,
    [WidgetType.SlideVsRotary]: getSlideVsRotaryWidgetData,
    [WidgetType.TrippingSpeed]: getTrippingSpeedWidgetData,
    [WidgetType.CasingRunning]: getCasingRunningWidgetData,
    [WidgetType.RateOfAdvance]: getRateOfAdvanceWidgetData,
    [WidgetType.ROP]: getRopWidgetData,
    [WidgetType.CustomVsTime]: getCustomVsTimeWidgetData
} as any;

@Injectable()
export class KpiWidgetDataService extends WidgetDataProvider {

    constructor(
        readonly store: Store,
        readonly data: KpiDataService,
        readonly liveData: LiveDataProviderService
    ) {
        super();
    }

    getData(id: string, type: WidgetType.AssetDailyMetrics): Observable<AssetDailyMetricsWidgetData>;
    getData(id: string, type: WidgetType.BitBottom): Observable<BitBottomWidgetData>;
    getData(id: string, type: WidgetType.ConnectionTime): Observable<ConnectionTimeWidgetData>;
    getData(id: string, type: WidgetType.DaysVsDepth): Observable<DaysVsDepthWidgetData>;
    getData(id: string, type: WidgetType.RigActivity): Observable<RigActivityWidgetData>;
    getData(id: string, type: WidgetType.RigState): Observable<RigStateWidgetData>;
    getData(id: string, type: WidgetType.RigStatePerHour): Observable<RigStatePerHourWidgetData>;
    getData(id: string, type: WidgetType.SlideRotary): Observable<SlideRotaryWidgetData>;
    getData(id: string, type: WidgetType.SlideVsRotary): Observable<SlideVsRotaryWidgetData>;
    getData(id: string, type: WidgetType.TrippingSpeed): Observable<TrippingSpeedWidgetData>;
    getData(id: string, type: WidgetType.CasingRunning): Observable<CasingRunningWidgetData>;
    getData(id: string, type: WidgetType.RateOfAdvance): Observable<RateOfAdvanceWidgetData>;
    getData(id: string, type: WidgetType.ROP): Observable<RopWidgetData>;
    getData(id: string, type: WidgetType.CustomVsTime): Observable<CustomVsTimeWidgetData>;
    getData(id: string, type: WidgetType): Observable<WidgetData> {
        if (WIDGET_DATA_FETCHER_MAP[type]) {
            return WIDGET_DATA_FETCHER_MAP[type].call(this, id);
        }

        throw new Error(`Unsupported widget type ${type}`);
    }

}
