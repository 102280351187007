import { ID, TemplateRepositryService } from '@cyberloop/core';
import { CreatePlanningTemplate, PlanningTemplate } from '@cyberloop/web/planning/shared/model';

import type { Observable } from 'rxjs';

/** Base class for planning template provider. Used as Injection token */
export abstract class PlanningTemplateProviderService implements TemplateRepositryService<PlanningTemplate> {
    /** Watch list of planning templates */
    abstract watchAll(): Observable<PlanningTemplate[]>;

    /** Get planning template by ID */
    abstract get(id: ID): Observable<PlanningTemplate | null>;

    /** Create planning template */
    abstract create(template: CreatePlanningTemplate): Observable<PlanningTemplate>;

    /** Delete planning template by ID */
    abstract delete(templateId: ID): Observable<PlanningTemplate>;
}
