import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { ClSelectorComponent, ClSelectorOptions, CoreActions, CoreSelectors, UnitDescriptor, UnitGroup, UnitGroups, UnitsPreset } from '@cyberloop/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { filter, firstValueFrom } from 'rxjs';

import { PopupContent } from '../popup-host/popup-host.component';
import { PseudoLoader } from '../pseudo-loader/pseudo-loader.component';

/**
 * @internal
 * Unit selector popup component
 */
@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        ClSelectorComponent,
        PseudoLoader
    ],
    templateUrl: './units-selector.component.html',
    styleUrls: ['./units-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitsSelectorComponent implements PopupContent<void> {
    /** @private */
    private _presets: UnitsPreset | undefined;
    /** @private */
    private _unitsPreset: Record<UnitGroup['id'], UnitDescriptor['id']> = {};
    /** @private */
    private _groups: UnitGroups = {};
    /** @private */
    private _unitsOptions: Record<number, ClSelectorOptions[]> = {}
    /** @private */
    private _unitsLoaded = false;

    /** @private */
    constructor(
        private readonly _store: Store,
        private readonly _ref: ChangeDetectorRef
    ) { }

    /** @internal */
    get groups(): UnitGroups {
        return this._groups;
    }
    /** @internal */
    get groupKeys(): (keyof UnitGroups)[] {
        return Object.keys(this._unitsOptions).map(x => Number(x));
    }
    /** @internal */
    // get blockNumbers(): number {
    //     return Object.keys(this._groups).map(x => Number(x));
    // }
    /** @internal */
    get unitsOptions() {
        return this._unitsOptions;
    }
    /** @internal */
    get presets() {
        return this._unitsPreset;
    }
    /** @internal */
    get unitsLoaded() {
        return this._unitsLoaded;
    }

    /** @internal */
    async setData?() {
        const presets = await firstValueFrom(this._store.select(CoreSelectors.units.userPreset).pipe(
            filter(Boolean)
        ));
        this._groups = await firstValueFrom(this._store.select(CoreSelectors.units.unitGroups));

        this._unitsLoaded = true;
        this._ref.markForCheck();

        this._presets = cloneDeep(presets)
        this._unitsPreset = this._presets?.['mappings'] ?? {};

        this._unitsOptions = Object.values(this._groups)
            .filter(x => Object.keys(x.units).length > 1)
            .reduce((obj, val) => ({
                ...obj,
                [val.id]: Object.keys(val.units).map(id => ({ id, name: val.units[id].name }))
            }), {});
    }

    /** @internal */
    public close: () => void = () => null;
    /** @internal */
    public setClose(fn: () => void): void {
        this.close = fn;
    }
    /** @internal */
    public cancel() {
        this.close?.();
    }
    /** @internal */
    public saveAndClose() {
        const preset: UnitsPreset = {
            id: this._presets?.id ?? '',
            name: this._presets?.name ?? '',
            mappings: this._unitsPreset
        }

        this._store.dispatch(CoreActions.units.updateUserUnitsPreset({ preset }));

        this.close?.();
    }
}
