import { createReducer, on } from '@ngrx/store';
import { isNil } from 'lodash';

import { PlanningActions } from './planning.actions';
import { PlanningState, adapter, initialState } from './planning.state';

export const planningReducer = createReducer<PlanningState>(initialState,
    //#region List
    on(PlanningActions.loadList, (state): PlanningState => ({
        ...state,
        listLoadingState: 'LOADING',
        listError: undefined
    })),
    on(PlanningActions.loadListSuccess, (state, { list }): PlanningState => (
        adapter.setAll<PlanningState>(list, {
            ...state,
            listLoadingState: 'LOADED'
        })
    )),
    on(PlanningActions.loadListFailure, (state, { error }): PlanningState => ({
        ...state,
        listLoadingState: 'NOT_LOADED',
        listError: error
    })),
    //#endregion List

    //#region Planning
    //#region Load
    on(
        PlanningActions.loadPlanningByRouteParam,
        PlanningActions.loadPlanningByCurrentWell,
        (state): PlanningState => ({
            ...state,
            planningId: undefined,
            planningLoadingState: 'LOADING',
            planningLoadError: undefined
        })
    ),
    on(
        PlanningActions.loadPlanningSuccess,
        PlanningActions.loadWellPlanSuccess,
        (state, { planning }): PlanningState => {
            const newState: PlanningState = {
                ...state,
                planningLoadingState: 'LOADED',
                planningId: undefined
            };

            if (isNil(planning)) {
                return newState;
            }

            return adapter.upsertOne<PlanningState>(planning, {
                ...newState,
                planningId: planning.id
            });
        }
    ),
    on(PlanningActions.loadPlanningFailure, (state, { error }): PlanningState => ({
        ...state,
        planningLoadingState: 'NOT_LOADED',
        planningLoadError: error
    })),
    //#endregion Load

    //#region Add Planning
    on(PlanningActions.createPlanning, (state): PlanningState => ({
        ...state,
        planningAddLoadingState: 'LOADING',
        planningAddError: undefined
    })),
    on(PlanningActions.createPlanningSuccess, (state, { planning }): PlanningState =>
        adapter.addOne<PlanningState>(planning, {
            ...state,
            planningAddLoadingState: 'LOADED',
            planningAddError: undefined
        })
    ),
    on(PlanningActions.createPlanningFailure, (state, { error }): PlanningState => ({
        ...state,
        planningAddLoadingState: 'NOT_LOADED',
        planningAddError: error
    })),
    //#endregion Add Planning

    //#region Update Planning
    on(PlanningActions.updatePlanning, (state): PlanningState => ({
        ...state,
        planningUpdateLoadingState: 'LOADING',
        planningUpdateError: undefined
    })),
    on(PlanningActions.updatePlanningSuccess, (state, { planning }): PlanningState =>
        adapter.upsertOne<PlanningState>(planning, {
            ...state,
            planningUpdateLoadingState: 'LOADED',
            planningUpdateError: undefined
        })
    ),
    on(PlanningActions.updatePlanningFailure, (state, { error }): PlanningState => ({
        ...state,
        planningUpdateLoadingState: 'NOT_LOADED',
        planningUpdateError: error
    })),
    //#endregion Update Planning
    //#endregion Planning

    //#region Version
    on(PlanningActions.selectVersion, (state): PlanningState => ({
        ...state,
        selectVersionLoading: true,
        selectVersionError: undefined
    })),
    on(PlanningActions.selectVersionSuccess, (state, { planning }): PlanningState =>
        adapter.upsertOne<PlanningState>(planning, {
            ...state,
            selectVersionLoading: false
        })
    ),
    on(PlanningActions.selectVersionFailure, (state, { error }): PlanningState => ({
        ...state,
        selectVersionLoading: false,
        selectVersionError: error
    }))
    //#endregion Version
);

