import { Observable } from 'rxjs';

import { Asset } from '../../models';
import { AlarmsSettings } from '../../models/alarms';

/**
 * This service provides data for tags
 */
export abstract class GlobalAlarmSettingsService {
    /**
     * Get data for multiple tags for specific view port
     * @param wellId
     * @param ids 
     */
    abstract get(ids: Asset['name'][]): Observable<AlarmsSettings>;
}