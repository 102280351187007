/** Enum of tag value requested from db */
export enum TagValueType {
    /** start value */
    StartValue = 'startValue',
    /** average value */
    AverageValue = 'avgValue',
    /** minimum value */
    MinimumValue = 'minValue',
    /** maximum value */
    MaximumValue = 'maxValue',
    /** value record start time */
    StartTime = 'startTime',
    /** value record start depth */
    StartDepth = 'startDepth'
}

/** Tag value object */
export interface TagValue {
    /** value record start depth */
    [TagValueType.StartDepth]: number;
    /** value record time */
    [TagValueType.StartTime]: number;
    /** start value */
    [TagValueType.StartValue]: number;
    /** average value */
    [TagValueType.AverageValue]: number;
    /** maximum value */
    [TagValueType.MaximumValue]: number;
    /** miminum value */
    [TagValueType.MinimumValue]: number;
}

/** Tags value object */
export type TagsValue = {
    /** Tag id */
    name: string;
} & {
    /** Tag value for each section */
    [section: string]: TagValue[]
}