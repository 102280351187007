import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, Injector, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { DayPilot } from 'daypilot-pro-angular';
import * as moment from 'moment';
import { ToastrModule } from 'ngx-toastr';

import { IconComponent } from './components';
import { PseudoLoader } from './components/pseudo-loader/pseudo-loader.component';
import { APP_NAME } from './injection-tokens';
import { getAuthProviders } from './state/auth/auth-state.providers';
import { CoreEffects } from './state/core.effects';
import { coreReducer } from './state/core.reducer';
import { CORE_FEATURE } from './state/core.state';
import { UnitsProviders } from './state/units/units-state.providers';

/** Get providers that are used by this library */
export function getCoreProviders(appName: string): EnvironmentProviders[] {
    return [
        provideEffects([CoreEffects]),
        provideState(CORE_FEATURE, coreReducer),
        importProvidersFrom(MatDialogModule),
        importProvidersFrom(ToastrModule.forRoot({
            enableHtml: true,
            newestOnTop: true,
        })),
        makeEnvironmentProviders([
            { provide: APP_NAME, useValue: appName },
            { provide: APP_INITIALIZER, useFactory: (injector: Injector) => () => initializeCore(injector), multi: true, deps: [Injector] },
            { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'dd.MM.YYYY HH:mm' } },
        ]),
        ...getAuthProviders(),
        ...UnitsProviders,
    ];
}

/** initialize all core components */
async function initializeCore(injector: Injector) {
    initializeWebComponents(injector);
}

/** Initialize WebComponents provided by this library */
async function initializeWebComponents(injector: Injector) {
    IconComponent.register(injector);
    PseudoLoader.register(injector);
}

/**
 * Updates the configuration of the moment library for the English language.
 * Start week with Monday
 */
moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

/**
 * Start week with Monday
 */
DayPilot.Locale.find('en-us').weekStarts = 1;
