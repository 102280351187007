import { TndChart } from '@cyberloop/web/tnd/model';
import { WellsTndChartSettings } from '@cyberloop/web/wells/model';
import { createActionGroup, props } from '@ngrx/store';

import { WELLS_ENGINEERING_TND_FEATURE } from './tnd.state';

const TndActionsInternal = createActionGroup({
    source: WELLS_ENGINEERING_TND_FEATURE,
    events: {
        'Set weights chart settings': props<{ settings: WellsTndChartSettings }>(),
        'Set torque chart settings': props<{ settings: WellsTndChartSettings }>(),

        'Update weights chart settings': props<{ settings: WellsTndChartSettings }>(),
        'Update torque chart settings': props<{ settings: WellsTndChartSettings }>(),

        'Select section for weights chart': props<{ sectionId?: string }>(),
        'Select section for torque chart': props<{ sectionId?: string }>(),

        'Select model for weights chart': props<{ modelId?: string }>(),
        'Select model for torque chart': props<{ modelId?: string }>(),

        'Set active model for weights chart': props<{ modelId?: string }>(),
        'Set active model for torque chart': props<{ modelId?: string }>(),

        'Set error': props<{ error?: Error }>(),
    }
});

/** Sets the selected section ID for T&D chart by type */
const selectSection = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return TndActionsInternal.selectSectionForTorqueChart;

        default:
            return TndActionsInternal.selectSectionForWeightsChart;
    }
};

/** Sets the selected model ID for T&D chart by type */
const selectModel = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return TndActionsInternal.selectModelForTorqueChart;

        default:
            return TndActionsInternal.selectModelForWeightsChart;
    }
};

/** Sets an active model ID for T&D chart by type */
const setActiveModel = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return TndActionsInternal.setActiveModelForTorqueChart;

        default:
            return TndActionsInternal.setActiveModelForWeightsChart;
    }
};


/**
 * @internal
 * Actions for Wells T&D used internally
 * */
export const TndActions = {
    ...TndActionsInternal,
    selectSection,
    selectModel,
    setActiveModel
};

/**
 * Public actions for Wells T&D
 * */
export const PublicTndActions = {
    updateWeightsChartSettings: TndActions.updateWeightsChartSettings,
    updateTorqueChartSettings: TndActions.updateTorqueChartSettings,

    selectSectionForWeightsChart: TndActions.selectSectionForWeightsChart,
    selectSectionForTorqueChart: TndActions.selectSectionForTorqueChart,
    selectSection,

    selectModelForWeightsChart: TndActions.selectModelForWeightsChart,
    selectModelForTorqueChart: TndActions.selectModelForTorqueChart,
    selectModel,

    setActiveModelForWeightsChart: TndActions.setActiveModelForWeightsChart,
    setActiveModelForTorqueChart: TndActions.setActiveModelForTorqueChart,
    setActiveModel
};
