import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FILES_TAG_FEATURE, FilesTagState, adapter } from './tag.state';

const feature = createFeatureSelector<FilesTagState>(FILES_TAG_FEATURE);
const { selectAll } = adapter.getSelectors();

const list = createSelector(feature, selectAll);
const listLoading = createSelector(feature, state => state.listLoading);
const createLoading = createSelector(feature, state => state.createLoading);

/**
 * Selectors for files tag
 * */
export const FilesTagSelectors = {
    list,
    listLoading,
    createLoading
};
