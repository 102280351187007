import { Injectable } from '@angular/core';

import { Functions, httpsCallable } from 'firebase/functions';

import { SsoRootApplication } from './sso-root-app';

@Injectable()
export class SsoRootFunctions {
    private readonly _functions: Functions;
    /**
     *
     */
    constructor(app: SsoRootApplication) {
        this._functions = app.getFunctions();
    }

    async call<T, TRes = any>(methodName: string, payload: T) {
        const result = await httpsCallable<T, TRes>(this._functions, methodName)(payload);
        return result.data;
    }
}