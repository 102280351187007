import { Injectable } from '@angular/core';

import { uuid } from '@cyberloop/core';
import { DashboardSettings } from '@cyberloop/web/wells/model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep, isNil } from 'lodash';
import { first, switchMap } from 'rxjs';

import { KpiActions } from './kpi.actions';
import { KpiSelectors } from './kpi.selectors';

@Injectable()
export class KpiDashboardsEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store
    ) { }

    readonly onAddDashboard$ = createEffect(() => this.actions$.pipe(
        ofType(KpiActions.addDashboard),

        switchMap(() => {
            const newDashboard: DashboardSettings = { title: 'New dashboard', id: uuid() };

            return this.store.select(KpiSelectors.dashboards).pipe(
                first(),
                switchMap(dashboards => {
                    const lastDashboards = dashboards ?? [];
                    return [
                        KpiActions.setDashboards({ dashboards: [...lastDashboards, newDashboard] }),
                        KpiActions.setActiveDashboard({ dashboardId: newDashboard.id })
                    ];
                })
            );
        })
    ));

    readonly onUpdateDashboardName$ = createEffect(() => this.actions$.pipe(
        ofType(KpiActions.updateDashboardName),

        switchMap(({ dashboardId, name }) => {
            return this.store.select(KpiSelectors.dashboards).pipe(
                first(),
                switchMap(dashboards => {
                    const lastDashboards = cloneDeep(dashboards) ?? [];
                    const dashboard = lastDashboards.find(item => item.id == dashboardId);
                    if(isNil(dashboard)) {
                        return [];
                    }
                    dashboard.title = name;
                    
                    return [
                        KpiActions.setDashboards({ dashboards: [...lastDashboards] })
                    ];
                })
            );
        })
    ));

    readonly onDeleteDashboard$ = createEffect(() => this.actions$.pipe(
        ofType(KpiActions.deleteDashboard),

        switchMap(({ dashboardId }) => {
            return this.store.select(KpiSelectors.dashboards).pipe(
                first(),
                switchMap(dashboards => {
                    const lastDashboards = dashboards ? [...dashboards] : [];

                    if (!lastDashboards.length) {
                        return [];
                    }

                    const dashboardIndex = lastDashboards.findIndex(item => item.id === dashboardId);

                    if (dashboardIndex < 0) {
                        return [];
                    }

                    // Delete it
                    lastDashboards.splice(dashboardIndex, 1);

                    //Delete widgets on dashboard
                    return this.store.select(KpiSelectors.widgets).pipe(
                        first(),
                        switchMap((widgets) => {
                            const widgetsIds = widgets.filter(item => item.tadId === dashboardId).map(item => item.id);

                            return this.store.select(KpiSelectors.activeDashboard).pipe(
                                first(),
                                switchMap((activeDashboard) => {
                                    if(activeDashboard === dashboardId) {
                                        return [
                                            KpiActions.setActiveDashboard({ dashboardId: lastDashboards[0]?.id ?? '' }),
                                            KpiActions.setDashboards({ dashboards: lastDashboards }),
                                            KpiActions.deleteWidgets({ widgetsIds })
                                        ];
                                    }
                                    return [
                                        KpiActions.setDashboards({ dashboards: lastDashboards }),
                                        KpiActions.deleteWidgets({ widgetsIds })
                                    ];
                                })
                            );
                        })
                    );
                })
            );
        })
    ));

}