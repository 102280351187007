import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent, NgForEmptyDirective, PopupContent } from '@cyberloop/core';
import { Planning } from '@cyberloop/web/planning/shared/model';
import { SetPlanPopupData, SetPlanPopupResult } from '@cyberloop/web/wells/model';
import { range } from 'lodash';
import { EMPTY, Observable } from 'rxjs';

@Component({
    selector: 'cyberloop-set-plan',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgForEmptyDirective,
        AsyncPipe,
        IconComponent
    ],
    templateUrl: './set-plan.component.html',
    styleUrls: ['./set-plan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetPlanComponent implements PopupContent<SetPlanPopupResult | undefined> {
    readonly skeletonCount = range(6);

    planningList$: Observable<Planning[]> = EMPTY;
    planningListLoading$: Observable<boolean> = EMPTY;
    planningListLoaded$: Observable<boolean> = EMPTY;

    close: (result?: SetPlanPopupResult) => void = () => null;

    setData(data: SetPlanPopupData): void {
        this.planningList$ = data.planningList$;
        this.planningListLoading$ = data.planningListLoading$;
        this.planningListLoaded$ = data.planningListLoaded$;
    }

    setClose(fn: (result?: SetPlanPopupResult) => void): void {
        this.close = fn;
    }

    onSelect(planning: Planning) {
        this.close({ planningId: planning.id });
    }

    create() {
        this.close({});
    }
}
