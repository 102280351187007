import type { Observable } from 'rxjs';

import type { Assets } from '../models';

/** Base class for well information provider. Used as Injection token */
export abstract class AssetsInfoProviderService {
    /**
     * Watch list of assets for the rig
     * @param rigName Name of the rig to get assets for
     */
    abstract watchAssets(rigName: string): Observable<Assets>;
}