import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiRigStateHour } from '@cyberloop/web/wells/model';
type KpiRigStateHoursInput = {
    rig: string,
    since: string,
    until: string
}

type KpiRigStateHours = {
    rig: {
        rigStateHours: KpiRigStateHour[]
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiRigStateHoursQuery extends GraphQLQuery<KpiRigStateHours, KpiRigStateHoursInput> {
    override readonly document = `
        query GetRigStateHours($rig: String!, $since: DateTime!, $until: DateTime!) {
            rig(name: $rig) {
                ... on Rig {
                    rigStateHours(since: $since, until: $until) {
                        hour
                        durations {
                            state
                            duration
                        }
                    }
                }
            }
        }
    `;
}