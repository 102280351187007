// batched-data.service.ts
import { Injectable } from '@angular/core';

import { Points, Range, TagDataProviderService } from '@cyberloop/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { bufferTime, filter, map, mergeMap, shareReplay, takeUntil, tap } from 'rxjs/operators';

interface TagRequest {
    wellId: string;
    tagId: string;
    range: Range;
}

/**
 * The BatchedDataService handles batching of multiple tag requests within a specific time period.
 * Requests are grouped by the provided range parameter and data is fetched using the TagDataProviderService.
 */
@Injectable({
    providedIn: 'root'
})
export class BatchedDataRequestService {
    private readonly _requestStreams = new Map<string, BehaviorSubject<TagRequest>>();
    private readonly _dataStreams = new Map<string, Observable<Record<string, Points>>>();
    private readonly _cleanupStream = new BehaviorSubject<string | null>(null);

    public debouncePeriod = 200;

    constructor(private readonly tagDataProviderService: TagDataProviderService) { }

    /**
     * Returns an Observable of the data for the given tagId and range.
     * @param {string} tagId - The tag ID to fetch data for.
     * @param {Range} range - The range of the data to be fetched.
     * @returns {Observable<Points>} - The Observable of the fetched data.
     */
    getDataFor(wellId: string, tagId: string, range: Range, sectionId?: string): Observable<Points> {
        const requestStream = this.getRequestStream(wellId, range, sectionId);
        requestStream.next({ wellId, tagId, range });

        const key = this.getStreamKey(wellId, range, sectionId);
        const data$ = this._dataStreams.get(key) as Observable<Record<string, Points>>;

        return data$.pipe(
            filter((data) => !!data && !!data[tagId]),
            map((data) => data[tagId]),
            map(ppts => ppts.sort((a, b) => a.x - b.x)),
            takeUntil(this._cleanupStream.pipe(filter((cleanupKey) => cleanupKey === key)))
        );
    }

    private getStreamKey(wellId: string, range: Range, sectionId?: string): string {
        return sectionId ? `${wellId}::${range.from}-${range.to}-${sectionId}` : `${wellId}::${range.from}-${range.to}`;
    }

    private getRequestStream(wellId: string, range: Range, sectionId?: string): Subject<TagRequest> {
        const key = this.getStreamKey(wellId, range, sectionId);
        if (!this._requestStreams.has(key)) {
            const newStream = new BehaviorSubject<TagRequest>(null as any);
            this._requestStreams.set(key, newStream);
            this.initializeDataStream(wellId, newStream, range, sectionId);
        }
        return this._requestStreams.get(key) as BehaviorSubject<TagRequest>;
    }

    private initializeDataStream(wellId: string, requestStream: BehaviorSubject<TagRequest>, range: Range, sectionId?: string) {
        const key = this.getStreamKey(wellId, range, sectionId);
        const data$ = requestStream.pipe(
            bufferTime(this.debouncePeriod),
            mergeMap((tagRequests: TagRequest[]) => {
                if (tagRequests.length === 0) {
                    return [];
                }
                const uniqueTagIds = Array.from(new Set(tagRequests.map((req) => req.tagId)));
                if (sectionId) {
                    return this.tagDataProviderService.getDepth(wellId, sectionId, uniqueTagIds, range);
                } 
                else {
                    return this.tagDataProviderService.get(wellId, uniqueTagIds, range);
                }

            }),
            shareReplay(1),
            takeUntil(this._cleanupStream.pipe(filter((cleanupKey) => cleanupKey === key)))
        );

        this._dataStreams.set(key, data$);
    }
}