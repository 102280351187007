import { Injectable } from '@angular/core';

import { ClError, ID, RigActivity, uuid } from '@cyberloop/core';
import { FilesProviderService } from '@cyberloop/web/files/data';
import { FileMeta, PlanningStageFileMeta, UpdateFileMeta, WellMeterageFileMeta } from '@cyberloop/web/files/model';
import { Meterage, MeterageItem } from '@cyberloop/web/wells/model';
import { cloneDeep, isNil, isUndefined } from 'lodash';
import { EMPTY, Observable, finalize, of, shareReplay, switchMap } from 'rxjs';

import { FirestoreDataConverter, QueryFilterConstraint, or, orderBy, where } from 'firebase/firestore';
import * as moment from 'moment';

import { DBFileMeta, DBUpdateFileMeta } from '../models/files';
import { DBPlanning, DBPlanningVersion } from '../models/planning';
import { METERAGES_COLLECTION_NAME } from './firebase-meterage-data.service';
import { PLANNING_COLLECTION_NAME } from './firebase-planning-provider.service';
import { PLANNING_VERSIONS_COLLECTION_NAME } from './firebase-planning-version-provider.service';
import { ClApplicationManager } from './internals/client-app/cl-app-manager';

import type { ClApplication } from './internals/client-app/cl-application';

const FILES_COLLECTION_NAME = 'files';
const FILES_STORAGE_NAME = 'files';

@Injectable({
    providedIn: 'root'
})
export class FirebaseFilesProviderLinkService extends FilesProviderService {
    private readonly _metaConverter: FirestoreDataConverter<FileMeta> = {
        toFirestore: (meta: FileMeta) => meta,
        fromFirestore: (snapshot, options): FileMeta => {
            const dbMeta = snapshot.data(options) as DBFileMeta;

            return {
                ...dbMeta,
                createdAt: moment(dbMeta.createdAt),
                updatedAt: moment(dbMeta.updatedAt)
            };
        }
    };

    private readonly _clApp$ = this.appMgr.tenantApp$.pipe(
        switchMap(x => isNil(x) ? EMPTY : of(x)),
        shareReplay(1)
    );

    private _listByWellIdObservables: Record<ID, Observable<FileMeta[]>> = {};
    private _listByPlanningIdObservables: Record<ID, Observable<FileMeta[]>> = {};
    private _listByIdsObservables: Record<string, Observable<FileMeta[]>> = {};

    constructor(private readonly appMgr: ClApplicationManager) {
        super();
    }

    watchWellFiles(wellId: ID, planningId?: ID) {
        const filters: QueryFilterConstraint[] = [where('wellId', '==', wellId)];

        if (!isNil(planningId)) {
            filters.push(where('planningId', '==', planningId));
        }

        return this._listByWellIdObservables[wellId] ??= this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().watchCollection<FileMeta>(
                FILES_COLLECTION_NAME,
                this._metaConverter,
                [orderBy('createdAt', 'desc')],
                or(...filters)
            )),
            finalize(() => delete this._listByWellIdObservables[wellId]),
            shareReplay(1)
        );
    }

    watchPlanningFiles(planningId: ID) {
        return this._listByPlanningIdObservables[planningId] ??= this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().watchCollection<FileMeta>(
                FILES_COLLECTION_NAME,
                this._metaConverter,
                [
                    where('planningId', '==', planningId),
                    orderBy('createdAt', 'desc')
                ]
            )),
            finalize(() => delete this._listByPlanningIdObservables[planningId]),
            shareReplay(1)
        );
    }

    watchByIDs(ids: ID[]) {
        const id = ids.join();

        if (!ids.length) {
            return of([]);
        }

        return this._listByIdsObservables[id] ??= this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().watchCollection<FileMeta>(
                FILES_COLLECTION_NAME,
                this._metaConverter,
                [
                    where('id', 'in', ids),
                    orderBy('createdAt', 'desc')
                ]
            )),
            finalize(() => delete this._listByIdsObservables[id]),
            shareReplay(1)
        );
    }

    getDownloadLink(id: ID) {
        return this._clApp$.pipe(
            switchMap(clApp => {
                const storage = clApp.getStorage();
                const path = this.getStoragePath(id);
                return storage.getDownloadURL(path);

            }),
            shareReplay(1)
        );
    }

    createWellFile(file: File, wellId: ID) {
        return this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().runTransaction(async (transaction) => {
                const fileMeta = this.generateFileMeta(clApp, file);

                transaction.set<DBFileMeta>(
                    this.getPath(FILES_COLLECTION_NAME, fileMeta.id),
                    {
                        ...fileMeta,
                        wellId
                    }
                );
                await this.uploadFile(clApp, fileMeta.id, file);

                return fileMeta.id;
            })),
            switchMap((id) => this.get(id) as Observable<FileMeta>),
            shareReplay(1)
        );
    }

    createWellMeterageFile(file: File, wellId: string, itemId: number, itemList: MeterageItem[]) {
        return this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().runTransaction(async (transaction) => {
                const itemListToUpdate = cloneDeep(itemList);
                const item = itemListToUpdate.find(x => x.id === itemId);

                if (isNil(item)) {
                    throw new ClError(`Meterage item with id ${itemId} not found`);
                }

                const fileMeta = this.generateFileMeta(clApp, file);
                item.ncr.push(fileMeta.id);

                const meteragePath = this.getPath(METERAGES_COLLECTION_NAME, wellId);
                transaction.set<Meterage>(
                    meteragePath,
                    {
                        id: wellId,
                        itemList: itemListToUpdate,
                        updatedAt: moment.now()
                    }
                );

                transaction.set<DBFileMeta>(
                    this.getPath(FILES_COLLECTION_NAME, fileMeta.id),
                    {
                        ...fileMeta,
                        wellId,
                        meterageItemId: itemId
                    }
                );
                await this.uploadFile(clApp, fileMeta.id, file);

                return fileMeta.id;
            })),
            switchMap((id) => this.get(id) as Observable<FileMeta>),
            shareReplay(1)
        );
    }

    createPlanningFile(file: File, planningId: ID) {
        return this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().runTransaction(async (transaction) => {
                const fileMeta = this.generateFileMeta(clApp, file);

                transaction.set<DBFileMeta>(
                    this.getPath(FILES_COLLECTION_NAME, fileMeta.id),
                    {
                        ...fileMeta,
                        planningId
                    }
                );
                await this.uploadFile(clApp, fileMeta.id, file);

                return fileMeta.id;
            })),
            switchMap((id) => this.get(id) as Observable<FileMeta>),
            shareReplay(1)
        );
    }

    createPlanningStageFile(file: File, planningId: ID, stageId: ID, activityId: RigActivity) {
        return this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().runTransaction(async (transaction) => {
                const planningPath = this.getPath(PLANNING_COLLECTION_NAME, planningId);
                const planning = await transaction.get<DBPlanning>(planningPath);

                if (isNil(planning)) {
                    throw new ClError(`Planning with id ${planningId} not found`);
                }

                const versionPath = this.getPath(PLANNING_COLLECTION_NAME, planning.id, PLANNING_VERSIONS_COLLECTION_NAME, planning.activeVersionId);
                const version = await transaction.get<DBPlanningVersion>(versionPath);

                if (isNil(version)) {
                    throw new ClError(`Planning version with id ${planning.activeVersionId} not found`);
                }

                const stage = version.stages.find(x => x.id === stageId);

                if (isNil(stage)) {
                    throw new ClError(`Stage with id ${stageId} not found`);
                }

                const fileMeta = this.generateFileMeta(clApp, file);
                stage.files.push(fileMeta.id);

                const now = moment().valueOf();

                transaction.update<DBPlanning>(planningPath, {
                    updatedAt: now
                });

                transaction.update<DBPlanningVersion>(versionPath, {
                    stages: version.stages,
                    updatedAt: now
                });

                transaction.set<DBFileMeta>(
                    this.getPath(FILES_COLLECTION_NAME, fileMeta.id),
                    {
                        ...fileMeta,
                        activityId,
                        planningId,
                        stageId
                    }
                );
                await this.uploadFile(clApp, fileMeta.id, file);

                return fileMeta.id;
            })),
            switchMap((id) => this.get(id) as Observable<FileMeta>),
            shareReplay(1)
        );
    }

    update(id: ID, update: UpdateFileMeta) {
        const metaToUpdate: DBUpdateFileMeta = {
            tagIds: update.tagIds,
            updatedAt: moment().valueOf()
        };

        if (!isUndefined(update.activityId)) {
            metaToUpdate['activityId'] = update.activityId;
        }

        return this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().update<DBUpdateFileMeta>(this.getPath(FILES_COLLECTION_NAME, id), metaToUpdate)),
            switchMap(() => this.get(id) as Observable<FileMeta>),
            shareReplay(1)
        );
    }

    delete(id: ID) {
        return this.get(id).pipe(
            switchMap(meta => {
                if (isNil(meta)) {
                    throw new Error('File not found');
                }

                const { wellId, meterageItemId, planningId, stageId } = meta;

                if (!isNil(wellId) && !isNil(meterageItemId)) {
                    return this.deleteWellMeterageFile(meta as WellMeterageFileMeta);
                }

                if (!isNil(planningId) && !isNil(stageId)) {
                    return this.deletePlanningStageFile(meta as PlanningStageFileMeta);
                }

                return this.deleteMetaFile(meta);
            }),
            shareReplay(1)
        );
    }

    private get(id: ID) {
        return this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().get<FileMeta>(
                this.getPath(FILES_COLLECTION_NAME, id),
                this._metaConverter
            )),
            shareReplay(1)
        );
    }

    private generateFileMeta(clApp: ClApplication, file: File): Omit<DBFileMeta, 'wellId' | 'meterageItemId' | 'planningId' | 'stageId'> {
        const id = uuid();
        const now = moment().valueOf();

        return {
            id,
            name: file.name,
            createdBy: this.getUserEmail(clApp),
            createdAt: now,
            updatedAt: now,
            tagIds: [],
            activityId: null
        };
    }

    private async uploadFile(clApp: ClApplication, id: ID, file: File) {
        const storage = clApp.getStorage();
        const path = this.getStoragePath(id);
        await storage.uploadBytes(
            path,
            file,
            { contentDisposition: `attachment; filename="${file.name}"` }
        );
    }

    private deleteWellMeterageFile(meta: WellMeterageFileMeta) {
        return this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().runTransaction(async (transaction) => {
                const { id, wellId, meterageItemId } = meta;

                const meteragePath = this.getPath(METERAGES_COLLECTION_NAME, wellId);
                const meterage = await transaction.get<Meterage>(meteragePath);

                if (isNil(meterage)) {
                    throw new ClError(`Meterage with Well id ${wellId} not found`);
                }

                const item = meterage.itemList.find(x => x.id === meterageItemId);

                if (isNil(item)) {
                    throw new ClError(`Meterage with id ${meterageItemId} not found`);
                }

                item.ncr = item.ncr.filter(x => x !== meta.id);

                transaction.update<Pick<Meterage, 'itemList' | 'updatedAt'>>(
                    meteragePath,
                    {
                        itemList: meterage.itemList,
                        updatedAt: moment.now()
                    }
                );

                transaction.delete(this.getPath(FILES_COLLECTION_NAME, id));
                await this.deleteStorageFile(clApp, id);

                return meta;
            })),
            shareReplay(1)
        );
    }

    private deletePlanningStageFile(meta: PlanningStageFileMeta) {
        return this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().runTransaction(async (transaction) => {
                const { id, planningId, stageId } = meta;

                const planningPath = this.getPath(PLANNING_COLLECTION_NAME, planningId);
                const planning = await transaction.get<DBPlanning>(planningPath);

                if (isNil(planning)) {
                    throw new ClError(`Planning with id ${planningId} not found`);
                }

                const versionPath = this.getPath(PLANNING_COLLECTION_NAME, planning.id, PLANNING_VERSIONS_COLLECTION_NAME, planning.activeVersionId);
                const version = await transaction.get<DBPlanningVersion>(versionPath);

                if (isNil(version)) {
                    throw new ClError(`Planning version with id ${planning.activeVersionId} not found`);
                }

                const stage = version.stages.find(x => x.id === stageId);

                if (isNil(stage)) {
                    throw new ClError(`Stage with id ${stageId} not found`);
                }

                stage.files = stage.files.filter(x => x !== meta.id);

                const now = moment().valueOf();

                transaction.update<DBPlanning>(planningPath, {
                    updatedAt: now
                });

                transaction.update<DBPlanningVersion>(versionPath, {
                    stages: version.stages,
                    updatedAt: now
                });

                transaction.delete(this.getPath(FILES_COLLECTION_NAME, id));
                await this.deleteStorageFile(clApp, id);

                return meta;
            })),
            shareReplay(1)
        );
    }

    private deleteMetaFile(meta: FileMeta) {
        return this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().runTransaction(async (transaction) => {
                const { id } = meta;
                transaction.delete(this.getPath(FILES_COLLECTION_NAME, id));
                await this.deleteStorageFile(clApp, id);
                return meta;
            })),
            shareReplay(1)
        );
    }

    private async deleteStorageFile(clApp: ClApplication, id: ID) {
        const storage = clApp.getStorage();
        const path = this.getStoragePath(id);
        await storage.deleteObject(path);
    }

    private getUserEmail(clApp: ClApplication) {
        const email = clApp?.getAuth().currentUser?.email;

        if (isNil(email)) {
            throw new ClError('User email not found');
        }

        return email;
    }

    private getStoragePath(id: ID) {
        return [FILES_STORAGE_NAME, id].join('/');
    }

    private getPath(...parts: string[]) {
        return parts.join('/');
    }
}
