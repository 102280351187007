<form [formGroup]="form" (submit)="onSubmit()" class="form">
    <fieldset class="fieldset">
        <cl-input [formControlName]="Controls.login" markTouchedOn="blur"
            type="email" placeholder="E-mail"></cl-input>
        <cl-form-errors [control]="loginControl"></cl-form-errors>
    </fieldset>

    <fieldset class="fieldset">
        <cl-input [formControlName]="Controls.password" markTouchedOn="blur" type="password"
            placeholder="Password"></cl-input>
        <cl-form-errors [control]="passwordControl"></cl-form-errors>
    </fieldset>

    <fieldset *ngIf="tenantListLoaded && canSelectTenant" class="fieldset">
        <cl-selector [formControlName]="Controls.tenant" [options]="listOfTenants ?? []"
            placeholder="Pick tenant"></cl-selector>
        <cl-form-errors [control]="tenantIdControl"></cl-form-errors>
    </fieldset>

    <button type="submit" class="cl-primary-button signint-button">
        Sign in
    </button>

    <div class="forgot-password">
        Forgot password? <span (click)="restorePassword.emit()" class="recover-link">Recover here</span>
    </div>

    <pseudo-loader *ngIf="loading || listOfTenantsBackgroundLoading && canSelectTenant"></pseudo-loader>

    <div class="error">{{ error }}</div>
</form>
