<div class="container">
    <div class="title" [class.clip]="clip" aria-hidden="true">{{ title }}</div>

    <div *ngIf="value2; else elseBlock" class="value" [attr.aria-label]="title">
        <span>{{ value |number:format |noData }}</span>&nbsp;/&nbsp;<span>{{ value2 |number:format |noData}}</span>
        <span class="tag" *ngIf="label">{{ label }}</span>
    </div>
    
    <ng-template #elseBlock>
        <div class="value" [attr.aria-label]="title">
            <span>{{ value |number:format |noData }}</span>
            <span class="tag" *ngIf="label">{{ label }}</span>
        </div>
    </ng-template>    
</div>
