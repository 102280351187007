<form [formGroup]="form" (submit)="onSubmit()" class="form">
    <fieldset class="fieldset">
        <cl-input [formControlName]="Controls.newPassword" type="password" placeholder="New"></cl-input>
    </fieldset>

    <fieldset class="fieldset">
        <cl-input [formControlName]="Controls.newPassword2" type="password" placeholder="Confirm"></cl-input>
    </fieldset>

    <button type="submit" [disabled]="form.disabled" class="cl-primary-button set-button">Set</button>

    <pseudo-loader *ngIf="loading"></pseudo-loader>

    <cl-form-errors [control]="form"></cl-form-errors>
    <div *ngIf="error" class="error">{{ error }}</div>

    <button (click)="backToLogin.emit()" type="button" class="cl-button back-button">
        <icon name="logout"></icon>
        Back to login
    </button>
</form>