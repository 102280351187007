import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
Add validation error(s) to a form control.
@param {AbstractControl} control - The form control to which the error(s) will be added.
@param {ValidationErrors} errors - The error(s) to add to the form control.
@param {boolean} [emitEvent] - Optional parameter to indicate whether to emit the valueChanges event after setting the errors. Defaults to false.
@returns {void}
*/
export function addFormControlError(control: AbstractControl, errors: ValidationErrors, emitEvent?: boolean) {
    control.setErrors({
        ...control.errors,
        ...errors
    }, {
        emitEvent
    });
}

/**
Remove a validation error from a form control.
@param {AbstractControl} control - The form control from which the error will be removed.
@param {string} errorName - The name of the error to remove.
@param {boolean} [emitEvent] - Optional parameter to indicate whether to emit the valueChanges event after removing the error. Defaults to false.
@returns {void}
*/
export function removeFormControlError(control: AbstractControl, errorName: string, emitEvent?: boolean) {
    if (control?.errors && control?.errors[errorName]) {
        delete control.errors[errorName];
        if (Object.keys(control.errors).length === 0) {
            control.setErrors(null, { emitEvent });
        }
    }
}
