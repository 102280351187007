import { NgClass, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

import { isEqual } from 'lodash';
import { Subject, distinctUntilChanged, share, takeUntil, tap } from 'rxjs';

/** Options interface */
export type ClSelectorOptions = {
    /** Key (id) */
    id: number | string,
    /** Text */
    name: string
}
/** @internal */
type ChangeMethod = (x: unknown) => void;
/** @internal */
type TouchMethod = () => void;

/** Cusotm selector */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cl-selector',
    standalone: true,
    imports: [
        MatSelectModule,
        NgIf,
        NgForOf,
        NgClass,
        FormsModule,
        ReactiveFormsModule
    ],
    templateUrl: './cl-selector.component.html',
    styleUrls: ['./cl-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ClSelectorComponent), multi: true }
    ]
})
export class ClSelectorComponent implements ControlValueAccessor, OnInit {
    /** @private */
    private _validators: ValidatorFn[] = [];
    /** @private */
    private _registerOnChange: ChangeMethod | undefined;
    /** @private */
    private _onTouched: TouchMethod | undefined;
    /** @private */
    private _destroy$ = new Subject();

    /** @internal */
    get selected() {
        return this.ctrl.value;
    }
    /** Select options */
    @Input() options: ClSelectorOptions[] | null = [];
    /** Multiple select */
    @Input() multiple = false;
    /** Placeholder */
    @Input() placeholder = 'Pick';
    /** Default value name */
    @Input() defaultValueName = '';
    /** Validators to be attached to the input */
    @Input()
    public set validators(value: ValidatorFn[] | ValidatorFn | null | undefined) {
        this.ctrl.removeValidators(this._validators);
        if (!value) {
            value = [];
        }
        if (!Array.isArray(value)) {
            value = [value];
        }
        this._validators = value;
        this.ctrl.addValidators(this._validators);
    }
    /** @internal */
    public get validators(): ValidatorFn[] {
        return this._validators;
    }
    /** @internal */
    @Input() set value(value: any) {
        if (value) {
            this.ctrl.setValue(value);
        }
    }

    /** @internal */
    readonly ctrl = new UntypedFormControl(null, { updateOn: 'change' });
    /** @internal */
    @Output() valueChange = this.ctrl.valueChanges.pipe(
        distinctUntilChanged((a, b) => isEqual(a, b)),
        tap(newValue => {
            this._onTouched?.();
            this._registerOnChange?.(newValue);
        }),
        share()
    );

    /** @internal */
    ngOnInit() {
        this.valueChange
            .pipe(takeUntil(this._destroy$))
            .subscribe();
    }

    /** @internal */
    writeValue(obj: any): void {
        this.ctrl.patchValue(obj, { emitEvent: false });
    }

    /** @internal */
    registerOnChange(fn: any): void {
        this._registerOnChange = fn;
    }

    /** @internal */
    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    /** @internal */
    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.ctrl.disable({ emitEvent: false });
        }
        else {
            this.ctrl.enable({ emitEvent: false });
        }
    }
}
