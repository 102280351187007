import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';

import { AuthActions } from '../../../../state/auth/auth.actions';
import { AuthSelectors } from '../../../../state/auth/auth.selectors';
import { AuthRoutes } from '../../../routing';
import { RestorePassword, RestorePasswordUIComponent } from '../../../ui/restore-password/restore-password.component';

/** @internal */
@Component({
    standalone: true,
    imports: [
        AsyncPipe,
        RestorePasswordUIComponent
    ],
    template: `<cyberloop-restore-password
        (tryRestorePassword)="tryRestorePassword($event)"
        (backToLogin)="backToLogin()"
        (loadTenantList)="loadTenantList($event)"
        [loading]="loading$ | async"
        [error]="error$ | async"
        [listOfTenants]="listOfTenants$|async"
    ></cyberloop-restore-password>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestorePasswordComponent {
    constructor(
        private readonly store: Store,
        private readonly router: Router,
    ) { }

    readonly loading$ = combineLatest([
        this.store.select(AuthSelectors.restorePasswordLoading),
        this.store.select(AuthSelectors.listOfTenantsLoading)
    ]).pipe(
        map(([restorePasswordLoading, listOfTenantsLoading]) => restorePasswordLoading || listOfTenantsLoading)
    );
    readonly error$ = this.store.select(AuthSelectors.restorePasswordError);
    readonly listOfTenants$ = this.store.select(AuthSelectors.listOfTenants);

    tryRestorePassword({ login, tenantId }: RestorePassword) {
        this.store.dispatch(AuthActions.tryRestorePassword({ login, tenantId }));
    }

    loadTenantList(login: string) {
        this.store.dispatch(AuthActions.loadTenantList({ login }));
    }

    backToLogin() {
        this.router.navigate([AuthRoutes.Root, AuthRoutes.SignIn]);
    }
}
