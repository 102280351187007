import { AsyncPipe, CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

import { ClChartComponent, Deg, FormatNumberPipe, FromSiPipe, NoDataPipe, ScalableContent, SimpleChangesOf, Trace, Traces, UnitDescriptor } from '@cyberloop/core';
import { Toolface, TracesServiceProvider } from '@cyberloop/web/wells/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash';
import { Observable, map, startWith } from 'rxjs';

import { WellCardTextComponent } from '../../well-card-text/well-card-text.component';
import { DdTraceComponent } from '../dd-trace/dd-trace.component';
import { gaugeHcOptions, seriesTarget } from './gauge-hc-options';

const MAX_TRACES_IN_COL = 7;
const FLOW_RATE_TRACE_ID = 'FLR';
const TARGET_TRACE_ID = 'TTF';

@UntilDestroy()
@Component({
    selector: 'cyberloop-dd-gauge',
    standalone: true,
    imports: [
        CommonModule,
        ClChartComponent,
        WellCardTextComponent,
        ScalableContent,
        NoDataPipe,
        FormatNumberPipe,
        DdTraceComponent,
        AsyncPipe,
        FromSiPipe
    ],
    templateUrl: './dd-gauge.component.html',
    styleUrls: ['./dd-gauge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DdGaugeComponent implements AfterViewInit, OnChanges {

    private readonly _halfOfArea: number = 15;
    private _initialized = false;
    private _targetInitialized = false;

    constructor(
        private readonly tracesService: TracesServiceProvider
    ) { }

    @Input() tfArray: Toolface[] = [];
    @Input() inc: number | null = null;
    @Input() azi: number | null = null;
    @Input() traces: Traces = [];
    @Input() angleUnit: UnitDescriptor | null | undefined = null;

    @Output() addTrace = new EventEmitter<number>();
    @Output() removeTrace = new EventEmitter<string>();

    @ViewChild(ClChartComponent, {static: true}) chart!: ClChartComponent;

    ngAfterViewInit(): void {
        if(!this.chart) {
            return;
        }

        if(!this._initialized && this.tfArray.length) {
            this.initSeries();
        }

        this.tracesService.getTraceValue(TARGET_TRACE_ID).pipe(
            startWith(0), //Default target value
            untilDestroyed(this)
        ).subscribe((value) => {
            this.setTargetSeries(Deg.fromRad(value));
        });
    }

    ngOnChanges(changes: SimpleChangesOf<DdGaugeComponent>): void {
        if(!this._initialized && this.tfArray.length) {
            this.initSeries();
        }

        if(!this._initialized) {
            return;
        }

        if(!isEqual(changes.tfArray?.currentValue, changes.tfArray?.previousValue)) {
            this.updateTfSeries();
        }   
    }

    updateTfSeries() {
        for (let index = 1; index <= this.tfArray.length; index++) {
            this.chart.series[index].points[0].update(this.tfArray[index - 1]?.value ?? 0, true, true);
        }
    }

    getTraceValue(trace: Trace): Observable<number> {
        return this.tracesService.getTraceValue(trace.id);
    }

    getPumpsIsOn() {
        return this.tracesService.getTraceValue(FLOW_RATE_TRACE_ID).pipe(
            map((value) => value > 0)
        );
    }

    setTargetSeries(value: number) {
        if(!this.chart) {
            return;
        }
        if(!this._targetInitialized) {
            this.chart.series[0].setData([[value]]); //Set target series value
            this._targetInitialized = true;
        }
        this.chart.series[0].points[0].update(value, true, true);
        this.createPlotbands(value);
    }

    onAddTrace(index: number, addToRightColumn = false) {
        this.addTrace.emit(index + (addToRightColumn ? MAX_TRACES_IN_COL : 0));
    }

    onRemoveTrace(id: string) {
        this.removeTrace.emit(id);
    }

    initSeries() {
        if(this._initialized || this.chart.series.length === 0) {
            return;
        }

        for (let index = 1; index <= this.tfArray.length; index++) {
            this.chart.series[index].setData([[this.tfArray[index - 1]?.value ?? 0]]);
        }
        this._initialized = true;
    }

    get currentValue(): Toolface {
        const value = this.tfArray[0]?.value ?? null;
        const sourceName = this.tfArray[0]?.sourceName ?? null;

        return {value, sourceName};
    }

    getOptions(): Highcharts.Options {
        return gaugeHcOptions;
    }

    get leftColumnOfTraces() {
        return this.traces.slice(0, MAX_TRACES_IN_COL);
    }

    get showAddTraceInLeftColumn() {
        return this.leftColumnOfTraces.length < MAX_TRACES_IN_COL;
    }

    get rightColumnOfTraces() {
        return this.traces.slice(MAX_TRACES_IN_COL, 2 * MAX_TRACES_IN_COL);
    }

    get showAddTraceInRightColumn() {
        return this.leftColumnOfTraces.length >= MAX_TRACES_IN_COL && this.rightColumnOfTraces.length < MAX_TRACES_IN_COL;
    }

    private normalizeAngel(angel: number): number {
        let data: number = angel;
        while (data < 0 || data > 360) {
            data = data < 0 ? data + 360 : data - 360;
        }
        return data || 0;
    }

    private createPlotbands(targetData: number) {
        this.createPlotbandLeft(targetData);
        this.createPlotbandRight(targetData);
    }

    private createPlotbandLeft(targetData: number) {
        this.createPlotband('plotLeft', targetData, (from, to) => {

            if (from < 0 || to > 360) {
                return {
                    from: from < 0 ? this.normalizeAngel(from) : from,
                    to: from < 0 ? 359.999999 : to
                };
            }

            return { from, to };
        });
    }

    private createPlotbandRight(targetData: number) {
        this.createPlotband('plotRight', targetData, (from, to) => {

            if (from < 0 || to > 360) {
                return {
                    from: to > 360 ? 0 : from,
                    to: to > 360 ? this.normalizeAngel(to) : to
                };
            }

            return { from: 0, to: 0 };
        });
    }

    private createPlotband(id: string, targetData: number, rangeSetter: (from: number, to: number) => { from: number, to: number }) {
        if (!this.chart) {
            return;
        }

        this.chart.yAxis?.[0]?.removePlotBand(id);

        const res = this.chart.series.find(x => x.name === seriesTarget);

        if (res && !res.visible) {
            return;
        }

        const plotBand: any = {
            id,
            className: null,
            color: {
                radialGradient: {
                    cx: 0.5,
                    cy: 0.5,
                    r: 0.5
                },
                stops: [
                    [0.0, 'rgb(99,189,239, .3)'],
                    [0.7, 'rgb(99,189,239, .3)'],
                    [1, 'rgb(99,189,239, .3)']
                ]
            },
            borderWidth: 0,
            from: 0,
            to: 0,
            innerRadius: '35%',
            outerRadius: '105%'
        };

        const from = targetData - this._halfOfArea;
        const to = targetData + this._halfOfArea;
        const range = rangeSetter(from, to);

        plotBand.from = range.from;
        plotBand.to = range.to;

        this.chart?.yAxis?.[0]?.addPlotBand(plotBand);
    }
}
