<div class="container">
    <div class="buttons">
        <button class="button" (click)="onLoadTemplate()">
            <icon name="drill__templates"></icon>
            <span>load template</span>
        </button>
        <button class="button" (click)="onSaveToTemplate()">
            <icon name="drill__templates"></icon>
            <span>save template</span>
        </button>
        <div class="free-space"></div>
        <button class="button" (click)="onCreate()">
            <icon name="plus"></icon>
            <span>add new</span>
        </button>
    </div>

    <cyberloop-workspaces-popup--list class="list" 
        [options]="wss"
        [selected]="selectedId" 
        [canDelete]="canDelete"
        (selectItem)="onSelect($event)"
        (editItem)="onEdit($event)"
        (deleteItem)="onDelete($event)">
    </cyberloop-workspaces-popup--list>
</div>