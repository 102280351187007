import { Route } from '@angular/router';

import { AuthGuard, PermissionAccessGuard, PermissionIDs, RouteAccess } from '@cyberloop/core';
import { Applications } from '@cyberloop/web/app/model';

export const appRoutes: Route[] = [
    {
        path: '',
        canActivateChild: [AuthGuard, PermissionAccessGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: Applications.Dashboard },
            {
                path: Applications.Dashboard,
                loadChildren: () => import('@cyberloop/web/dashboard/feature').then(m => m.dashboardRoutes),
                title: 'Dashboard'
            },
            {
                path: Applications.Wells,
                loadChildren: () => import('@cyberloop/web/wells/feature').then(m => m.wellsRoutes),
                title: 'Wells',
                data: {
                    permissions: [PermissionIDs.Wells]
                } as RouteAccess
            },
            {
                path:
                    Applications.Rigs,
                loadChildren: () => import('@cyberloop/web/rigs/feature').then(m => m.rigsRoutes),
                title: 'Rigs'
            },
            {
                path:
                    Applications.Assets,
                loadChildren: () => import('@cyberloop/web/assets/feature').then(m => m.assetsRoutes),
                title: 'Assets'
            },
            {
                path: Applications.Planning,
                loadChildren: () => import('@cyberloop/web/planning/list/feature-list').then(m => m.planningListRoutes),
                title: 'Planning',
                data: {
                    permissions: [PermissionIDs.Planning]
                } as RouteAccess
            },
            {
                path: Applications.GanttChart,
                loadChildren: () => import('@cyberloop/web/gantt-chart/feature').then(m => m.ganttChartRoutes),
                title: 'Gantt Chart',
                data: {
                    permissions: [PermissionIDs.GanttChart]
                } as RouteAccess
            },
            {
                path: Applications.Meterage,
                loadChildren: () => import('@cyberloop/web/meterage/feature').then(m => m.meterageRoutes),
                title: 'Meterage',
                data: {
                    permissions: [PermissionIDs.Meterage]
                } as RouteAccess
            }
        ]
    }

];
