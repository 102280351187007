import { wellsTndChartDefaultSettings } from '@cyberloop/web/wells/model';

import type { WellsTndChartSettings } from '@cyberloop/web/wells/model';

export const WELLS_ENGINEERING_TND_FEATURE = 'Wells / Engineering / T&D';

/**
 * State for Engineering / T&D
 * */
export interface WellsEngineeringTndState {
    error?: Error;

    /** Settings of the Weights chart */
    weightsSettings: WellsTndChartSettings;
    /** Settings of the Torque chart */
    torqueSettings: WellsTndChartSettings;
}

export function getDefaultWellsEngineeringTndState(): WellsEngineeringTndState {
    return {
        weightsSettings: wellsTndChartDefaultSettings,
        torqueSettings: wellsTndChartDefaultSettings
    };
}
