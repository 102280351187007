import { Injectable } from '@angular/core';

import { WellKnownUnitIds } from '@cyberloop/core';
import { TndModelProvider } from '@cyberloop/web/tnd/data';
import { TndChart, TndRealm, isTndChartTorqueModel, isTndChartWeightsModel } from '@cyberloop/web/tnd/model';
import { Observable, filter, map, of } from 'rxjs';

import { TorqueAndDragModel, TorqueAndDragStoreModelResult, TorqueAndDragTorqueModel, TorqueAndDragWeightsModel } from '../internals/tnd';
import { TorqueAndDragActivateModelMutation } from '../queries/tnd/activate-model.mutation';
import { TorqueAndDragDeactivateModelMutation } from '../queries/tnd/deactivate-model.mutation';
import { TorqueAndDragDeleteModelMutation } from '../queries/tnd/delete-model.mutation';
import { TndModelDataQuery, TndModelDataResult } from '../queries/tnd/model-data.query';
import { TorqueAndDragModelsListInPlanQuery, TorqueAndDragModelsListInPlanResult } from '../queries/tnd/models-list-in-plan.query';
import { TorqueAndDragModelsListInWellQuery, TorqueAndDragModelsListInWellResult } from '../queries/tnd/models-list-in-well.query';
import { TndSingleModelQuery } from '../queries/tnd/single-model.query';
import { TorqueAndDragStoreTorqueModelMutation } from '../queries/tnd/store-torque-model.mutation';
import { TorqueAndDragStoreWeightsModelMutation } from '../queries/tnd/store-weights-model.mutation';

import type { TndChartModel, TndChartModelDataset, TndChartTorqueModelWithData, TndChartWeightsModelWithData, TndChartModelWithData, TndChartTorqueModel, TndChartWeightsModel } from '@cyberloop/web/tnd/model';
import type { MutationResult, QueryResult } from '../internals/cl-gql/models';

@Injectable({ providedIn: 'root' })
export class TndModelProviderServiceGraphQL extends TndModelProvider {

    constructor(
        private readonly tndModelsListInWellQuery: TorqueAndDragModelsListInWellQuery,
        private readonly tndModelsListInPlanQuery: TorqueAndDragModelsListInPlanQuery,
        private readonly tndSingleModelQuery: TndSingleModelQuery,
        private readonly tndModelDataQuery: TndModelDataQuery,
        private readonly tndStoreWeightsModelMutation: TorqueAndDragStoreWeightsModelMutation,
        private readonly tndStoreTorqueModelMutation: TorqueAndDragStoreTorqueModelMutation,
        private readonly tndDeleteModelMutation: TorqueAndDragDeleteModelMutation,
        private readonly tndActivateModelMutation: TorqueAndDragActivateModelMutation,
        private readonly tndDeactivateModelMutation: TorqueAndDragDeactivateModelMutation
    ) {
        super();
    }

    list(realm: TndRealm, realmId: string, kind: TndChart): Observable<TndChartModel[]> {
        switch (realm) {

            case TndRealm.Plan:
                return this.listInPlan(realmId, kind);

            default:
                return this.listInWell(realmId, kind);
        }
    }

    get(id: string): Observable<TndChartModel | undefined> {
        return this.tndSingleModelQuery.fetch({ modelId: id }).pipe(
            filter(x => !x.loading),
            map(x => this.handleQueryErrors(x)),
            map(result => {
                if (!result) {
                    return undefined;
                }

                const model = result.torqndrag.model;

                return this.mapModel(model);
            })
        );
    }

    getActive(realm: TndRealm, realmId: string, kind: TndChart): Observable<TndChartModel | undefined> {
        if (realm !== TndRealm.Well) {
            return of(undefined);
        }

        return this.listInWell(realmId, kind, true).pipe(
            map(items => items?.[0]?? undefined)
        );
    }

    getData(id: string): Observable<TndChartModelDataset[]> {
        return this.tndModelDataQuery.fetch({
            modelId: id
        }).pipe(
            filter(x => !x.loading),
            map(x => this.handleQueryErrors(x)),
            map((x: TndModelDataResult | null | undefined) => x?.torqndrag.model.dataSets ?? [])
        );
    }

    createModel(realm: TndRealm, realmId: string, data: Omit<TndChartTorqueModelWithData, 'id'>): Observable<string | false>;
    createModel(realm: TndRealm, realmId: string, data: Omit<TndChartWeightsModelWithData, 'id'>): Observable<string | false>;
    createModel(realm: TndRealm, realmId: string, data: Omit<TndChartModelWithData, 'id'>): Observable<string | false> {
        let mutation: Observable<MutationResult<TorqueAndDragStoreModelResult | null | undefined>>;

        if (isTndChartTorqueModel(data)) {
            mutation = this.tndStoreTorqueModelMutation.mutate({
                ownerKind: realm === TndRealm.Plan ? 'PLAN' : 'WELL',
                ownerId: realmId,
                modelName: data.name,
                valueUnitId: data.valueUnitId,
                depthUnitId: data.depthUnitId,
                startDepth: data.startDepth,
                endDepth: data.endDepth,
                highTorqueAlarmLine: data.highTorqueAlarmLine,
                dataSets: data.dataSets
            });
        }
        else if (isTndChartWeightsModel(data)) {
            mutation = this.tndStoreWeightsModelMutation.mutate({
                ownerKind: realm === TndRealm.Plan ? 'PLAN' : 'WELL',
                ownerId: realmId,
                modelName: data.name,
                valueUnitId: data.valueUnitId,
                depthUnitId: data.depthUnitId,
                startDepth: data.startDepth,
                endDepth: data.endDepth,
                pushAlarmLine: data.pushAlarmLine,
                pullAlarmLine: data.pullAlarmLine,
                dataSets: data.dataSets
            });
        }
        else {
            // This is not possible
            mutation = of({} as any);
        }

        return mutation.pipe(
            filter(x => !x.loading),
            map(x => this.handleQueryErrors(x)),
            map(result => result?.torqndrag.storeModel.entityId ?? false)
        );
    }

    updateModel(realm: TndRealm, realmId: string, data: TndChartTorqueModelWithData): Observable<boolean>;
    updateModel(realm: TndRealm, realmId: string, data: TndChartWeightsModelWithData): Observable<boolean>;
    updateModel(realm: TndRealm, realmId: string, data: TndChartModelWithData): Observable<boolean> {
        let mutation: Observable<MutationResult<TorqueAndDragStoreModelResult | null>>;

        if (isTndChartTorqueModel(data)) {
            mutation = this.tndStoreTorqueModelMutation.mutate({
                ownerKind: realm === TndRealm.Plan ? 'PLAN' : 'WELL',
                ownerId: realmId,
                modelId: data.id,
                modelName: data.name,
                valueUnitId: data.valueUnitId,
                depthUnitId: data.depthUnitId,
                startDepth: data.startDepth,
                endDepth: data.endDepth,
                highTorqueAlarmLine: data.highTorqueAlarmLine,
                dataSets: data.dataSets
            });
        }
        else if (isTndChartWeightsModel(data)) {
            mutation = this.tndStoreWeightsModelMutation.mutate({
                ownerKind: realm === TndRealm.Plan ? 'PLAN' : 'WELL',
                ownerId: realmId,
                modelId: data.id,
                modelName: data.name,
                valueUnitId: data.valueUnitId,
                depthUnitId: data.depthUnitId,
                startDepth: data.startDepth,
                endDepth: data.endDepth,
                pushAlarmLine: data.pushAlarmLine,
                pullAlarmLine: data.pullAlarmLine,
                dataSets: data.dataSets
            });
        }
        else {
            // This is not possible
            mutation = of({} as any);
        }

        return mutation.pipe(
            filter(x => !x.loading),
            map(x => this.handleQueryErrors(x)),
            map(result => result?.torqndrag.storeModel.succeeded ?? false)
        );
    }

    deleteModel(id: string): Observable<boolean> {
        return this.tndDeleteModelMutation.mutate({ modelId: id }).pipe(
            filter(x => !x.loading),
            map(x => this.handleQueryErrors(x)),
            map(result => result?.torqndrag.dropModel.succeeded ?? false)
        );
    }

    activateModel(id: string, wellId?: string): Observable<boolean> {
        return this.tndActivateModelMutation.mutate({ modelId: id, wellId }).pipe(
            filter(x => !x.loading),
            map(x => this.handleQueryErrors(x)),
            map(result => result?.torqndrag.activateModel.succeeded ?? false)
        );
    }

    deactivateModel(id: string): Observable<boolean> {
        return this.tndDeactivateModelMutation.mutate({ modelId: id }).pipe(
            filter(x => !x.loading),
            map(x => this.handleQueryErrors(x)),
            map(result => result?.torqndrag.deactivateModel.succeeded ?? false)
        );
    }

    private listInWell(wellId: string, kind: TndChart, activeOnly = false): Observable<TndChartModel[]> {
        return this.tndModelsListInWellQuery.fetch({
            wellId,
            kind: kind === TndChart.Torque ? 'TORQUE' : 'DRAG',
            activeOnly
        }).pipe(
            filter(x => !x.loading),
            map(x => this.handleQueryErrors(x)),
            map((x: TorqueAndDragModelsListInWellResult | null | undefined) => x?.well.torqndrag.models.edges ?? []),
            map((items: (TorqueAndDragWeightsModel | TorqueAndDragTorqueModel)[]) => items.map(this.mapModels))
        );
    }

    private listInPlan(planId: string, kind: TndChart): Observable<TndChartModel[]> {
        return this.tndModelsListInPlanQuery.fetch({
            planId,
            kind: kind === TndChart.Torque ? 'TORQUE' : 'DRAG'
        }).pipe(
            filter(x => !x.loading),
            map(x => this.handleQueryErrors(x)),
            map((x: TorqueAndDragModelsListInPlanResult | null | undefined) => x?.planning.models.edges ?? []),
            map((items: (TorqueAndDragWeightsModel | TorqueAndDragTorqueModel)[]) => items.map(this.mapModels))
        );
    }

    private mapModels(input: TorqueAndDragWeightsModel | TorqueAndDragTorqueModel): TndChartModel {
        switch (input.kind) {

            case 'TORQUE':
                const tm: TndChartTorqueModel = {
                    id: input.id,
                    kind: TndChart.Torque,
                    name: input.name,
                    valueUnitId: input.valueUnitId as WellKnownUnitIds,
                    depthUnitId: input.depthUnitId as WellKnownUnitIds,
                    startDepth: input.startDepth,
                    endDepth: input.endDepth,
                    highTorqueAlarmLine: input.highTorqueAlarmLine
                };
                return tm;

            default:
                const wm: TndChartWeightsModel = {
                    id: input.id,
                    kind: TndChart.Weights,
                    name: input.name,
                    valueUnitId: input.valueUnitId as WellKnownUnitIds,
                    depthUnitId: input.depthUnitId as WellKnownUnitIds,
                    startDepth: input.startDepth,
                    endDepth: input.endDepth,
                    pushAlarmLine: input.pushAlarmLine,
                    pullAlarmLine: input.pullAlarmLine,
                };
                return wm;
        }
    }

    private handleQueryErrors<TRes, TInput>(response: QueryResult<TRes>): TRes | null | undefined;
    private handleQueryErrors<TRes, TInput>(response: MutationResult<TRes>): TRes | null | undefined;
    private handleQueryErrors<TRes, TInput>(response: QueryResult<TRes> | MutationResult<TRes>): TRes | null | undefined {
        if ((response as any).error) {
            throw new Error((response as QueryResult<TRes>).error?.message);
        }
        else if (response.errors) {
            throw new Error(response.errors.map(err => err.message).join('; '));
        }

        return response.data;
    }

    private mapModel(model: TorqueAndDragModel): TndChartTorqueModel | TndChartWeightsModel {
        switch (model.kind) {

            case 'TORQUE':
                const tm: TndChartTorqueModel = {
                    id: model.id,
                    kind: TndChart.Torque,
                    name: model.name,
                    valueUnitId: model.valueUnitId as WellKnownUnitIds,
                    depthUnitId: model.depthUnitId as WellKnownUnitIds,
                    startDepth: model.startDepth,
                    endDepth: model.endDepth,
                    highTorqueAlarmLine: (model as TorqueAndDragTorqueModel).highTorqueAlarmLine
                };
                return tm;

            default:
                const wm: TndChartWeightsModel = {
                    id: model.id,
                    kind: TndChart.Weights,
                    name: model.name,
                    valueUnitId: model.valueUnitId as WellKnownUnitIds,
                    depthUnitId: model.depthUnitId as WellKnownUnitIds,
                    startDepth: model.startDepth,
                    endDepth: model.endDepth,
                    pushAlarmLine: (model as TorqueAndDragWeightsModel).pushAlarmLine,
                    pullAlarmLine: (model as TorqueAndDragWeightsModel).pullAlarmLine,
                };
                return wm;
        }
    }

}
