import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, PopupContent, UnitsSelectors, WellKnownUnitGroupIds, WellKnownUnitIds } from '@cyberloop/core';
import { SlideRotaryWidgetSettings, WidgetSettingsTimeRange } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { ClTimeRangeSelectorComponent } from '../../../time-range-selector/time-range-selector.component';
import { KpiWidgetSettingsComponent } from '../../widget-settings/kpi-widget-settings.component';

@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        FormsModule,
        ReactiveFormsModule,
        KpiWidgetSettingsComponent,
        ClTimeRangeSelectorComponent,
        ClButtonToggleComponent
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements PopupContent<SlideRotaryWidgetSettings> {

    private _closeFn?: (result: SlideRotaryWidgetSettings | null) => void;

    constructor(
        private readonly store: Store
    ) {}

    readonly form = new FormGroup({
        timeRange: new FormControl<WidgetSettingsTimeRange | null>(null, [ Validators.required ]),
        units: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ])
    });

    readonly units$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Time)).pipe(
        map(group => Object.values(group.units))
    );

    setData(data: SlideRotaryWidgetSettings): void {
        if (data.timeRange) {
            this.form.controls.timeRange.setValue(data.timeRange);
        }

        if (data.timeUnitId) {
            this.form.controls.units.setValue(data.timeUnitId as WellKnownUnitIds);
        }
    }

    setClose(fn: (result: SlideRotaryWidgetSettings | null) => void): void {
        this._closeFn = fn;
    }

    onCancel(): void {
        this._closeFn?.(null);
    }

    onSubmit(): void {
        // Compose settings

        const result: SlideRotaryWidgetSettings = {};

        if (this.form.controls.timeRange.value) {
            result.timeRange = this.form.controls.timeRange.value;
        }

        if (this.form.controls.units.value) {
            result.timeUnitId = this.form.controls.units.value;
        }

        this._closeFn?.(result);
    }

}
