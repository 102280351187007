/**
 * Returns the start times of each N-hour slice within a specified time interval.
 *
 * @param {number} from - The start timestamp of the time interval, in seconds or milliseconds.
 * @param {number} to - The end timestamp of the time interval, in seconds or milliseconds.
 * @param {number} [hoursPerSlice=3] - The number of seconds per slice (default: 3hours).
 * @returns {number[]} An array of Unix timestamps (in seconds) representing the start times of each N-hour slice.
 * @example
 * const from = 1680790095;
 * const to = 1680818895;
 * console.log(getTimeSlices(from, to));
 * // Output: [ 1680782400, 1680793200, 1680804000, 1680814800 ]
 */
export function getTimeSlices(from: number, to: number, secondsPerSlice: number): number[] {
    const toSeconds = (x: number) => {
        if (x > 1e13) {
            x = Math.floor(x / 1000);
        }
        // Ensure both from and to are in seconds
        if (x > 1e10) {
            x = Math.floor(x / 1000);
        }

        return x;
    }

    from = toSeconds(from);
    to = toSeconds(to);

    if (from > to) {
        return [];
    }

    const starts: number[] = [];

    // Find the start of the first slice
    let currentStart = Math.floor(from / secondsPerSlice) * secondsPerSlice;

    // Add the start times for all slices in the time interval
    while (currentStart < to) {
        starts.push(currentStart);
        currentStart += secondsPerSlice;
    }

    return starts;
}