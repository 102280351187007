import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { AuthTokenProviderService } from '@cyberloop/links/firebase';
import { EMPTY, Observable, map, switchMap } from 'rxjs';

import { SubscriptionClient } from 'subscriptions-transport-ws';

import { GraphQLLinkOptions, OPTIONS_TOKEN } from '../options.token';
import { GraphQLClient } from './client';
import { MutationOptions, MutationResult, QueryOptions, QueryResult, SubscriptionOptions } from './models';

@Injectable()
export class GraphQLHttpClient extends GraphQLClient {
    private readonly _ws$: Observable<SubscriptionClient | null>;
    /**
     *
     */
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(OPTIONS_TOKEN) private readonly options: GraphQLLinkOptions
    ) {
        super();

        this._ws$ = AuthTokenProviderService.lastToken$.pipe(
            map(token => {
                if (!token) {
                    return null;
                }

                const url = this.appendQueryParam(options.wsUrl, 'x-auth', token);
                const cli = new SubscriptionClient(url, {
                    reconnect: true,
                    connectionParams: {
                        keepAlive: 5000 // 5 seconds
                    }
                });

                return cli;
            })
        );
    }

    public override query<T, V = void>(options: QueryOptions<V>): Observable<QueryResult<T>> {
        return this.doHttpRequest({
            query: options.query,
            variables: options.variables
        });
    }

    public override mutate<T, V = void>(options: MutationOptions<V>): Observable<MutationResult<T>> {
        return this.doHttpRequest({
            query: options.query,
            variables: options.variables
        });
    }

    public override subscribe<T, V = void>(options: SubscriptionOptions<V>): Observable<QueryResult<T>> {
        return this._ws$.pipe(
            switchMap(x => {
                if (!x) {
                    return EMPTY;
                }

                return new Observable<QueryResult<T>>(subscr => {
                    return x.request({
                        query: options.query,
                        variables: options.variables ?? undefined
                    }).subscribe({
                        next: (res) => subscr.next(res as any),
                        complete: () => subscr.complete(),
                        error: (err) => subscr.error(err)
                    });
                });
            })
        );
    }

    private doHttpRequest(body: {
        query: string,
        variables: unknown
    }): Observable<any> {
        return AuthTokenProviderService.lastToken$.pipe(
            switchMap(token => {
                if (!token) {
                    return EMPTY;
                }

                return this.httpClient.post(this.options.httpUrl, body, {
                    reportProgress: true,
                    responseType: 'json',
                    //withCredentials: true,
                    headers: {
                        ['Cache-Control']: 'no-cache, max-age=0',
                        ['X-Auth']: token
                    }
                });
            })
            //tap(x => console.warn('RESPONSE:', x))
        );
    }

    private appendQueryParam(url: string, key: string, value: string): string {
        const urlObj = new URL(url);
        const params = new URLSearchParams(urlObj.search);
        params.append(key, value);
        urlObj.search = params.toString();
        return urlObj.toString();
    }
}
