import { Injectable } from '@angular/core';

import { AuthTokenProviderService } from '@cyberloop/links/firebase';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { combineLatest, distinctUntilChanged, filter, firstValueFrom, map, shareReplay } from 'rxjs';

import jwt_decode from 'jwt-decode';

import { PermissionIDs, Rig, Well } from '../../models';
import { AccessLevel, L1AccessLevel, L3AccessLevel, UserAccess, UserAccessToken } from '../../models/access';
import { CoreSelectors } from '../../state/core.selectors';
import { permissions } from './permissions';

/**
 * Access service
 */
@Injectable({
    providedIn: 'root'
})
export class AccessService {
    /** @internal */
    private readonly rigList$ = this.store.select(CoreSelectors.allRigs);
    /** @internal */
    private readonly wellList$ = combineLatest([
        this.store.select(CoreSelectors.allWells),
        this.store.select(CoreSelectors.wellsAreLoaded),
    ]).pipe(
        filter(([_, loaded]) => loaded),
        map(([list]) => list),
    );

    /** @internal */
    constructor(
        private readonly store: Store
    ) { }

    /** @internal */
    readonly userAccess$ = AuthTokenProviderService.lastToken$.pipe(
        filter(Boolean),
        distinctUntilChanged(),
        map((token): UserAccess => {
            const access = jwt_decode<UserAccessToken>(token);

            return {
                level: access.lv,
                levelSettings: access.ls,
                permissions: access.pm
            } as UserAccess;
        }),
        shareReplay(1)
    );

    /** check access denied by permission list */
    async isDeniedAccess(ids: PermissionIDs[]): Promise<boolean> {
        for (let index = 0; index < ids.length; index++) {
            const id = ids[index];

            if (await this.hasAccess(id) === false) {
                return true;
            }
        }

        return false;
    }

    /** check access by permission */
    async hasAccess(id: PermissionIDs): Promise<boolean> {
        const hasAccess = await this.getUserAccessByPermission(id);

        if (!isNil(hasAccess)) {
            return hasAccess;
        }

        const permission = permissions.find(permission => permission.id === id);

        if (permission && !isNil(permission?.parentId)) {
            return this.hasAccess(permission.parentId);
        }

        return false;
    }

    //#region Rig
    /** filter Rig list  */
    async filterRigs(rigList: Rig[]): Promise<Rig[]> {
        return await this.asyncFilter<Rig>(
            rigList,
            async well => await this.hasRigAccess(well.id, rigList)
        );
    }

    /** check Rig access */
    async hasRigAccess(rigId: string, rigList?: Rig[]) {
        if (isNil(rigList)) {
            rigList = await firstValueFrom(this.rigList$);
        }

        const rig = rigList.find(x => x.id === rigId);

        if (isNil(rig)) {
            console.error(`Rig with ID '${rigId}' not found`);
            return false;
        }

        const userAccess = await firstValueFrom(this.userAccess$);

        if (userAccess.level === AccessLevel.L0) {
            return true;
        }

        if (userAccess.level === AccessLevel.L1) {
            return userAccess.levelSettings.operatorList.includes(rig.operator);
        }

        return userAccess.levelSettings.rigList?.includes(rigId) ?? false;
    }
    //#endregion Rig

    //#region Well
    /** filter Well list  */
    async filterWells(rigList: Rig[], wellList: Well[]): Promise<Well[]> {
        return await this.asyncFilter<Well>(
            wellList,
            async well => await this.hasWellAccess(well.id, rigList, wellList)
        );
    }

    /** check Well access */
    async hasWellAccess(wellId: string, rigList?: Rig[], wellList?: Well[]) {
        if (isNil(wellList)) {
            wellList = await firstValueFrom(this.wellList$);
        }

        const well = wellList.find(x => x.id === wellId);

        if (isNil(well)) {
            console.error(`Well with ID '${wellId}' not found`);
            return false;
        }

        const userAccess = await firstValueFrom(this.userAccess$);

        if (userAccess.level === AccessLevel.L0) {
            return true;
        }

        if (userAccess.level === AccessLevel.L1) {
            return this.hasL1Access(well.rig, rigList);
        }

        if (userAccess.level === AccessLevel.L2) {
            return userAccess.levelSettings.rigList.includes(well.rig);
        }

        if (userAccess.level === AccessLevel.L3) {
            return this.hasL3Access(well.rig, well.startTime.valueOf());
        }

        if (userAccess.level === AccessLevel.L4) {
            if (!userAccess.levelSettings.rigList.includes(well.rig)) {
                return false;
            }

            return well.releaseTime === undefined;
        }

        return false;
    }
    //#endregion Well

    /** @internal */
    async asyncFilter<T>(arr: T[], predicate: (value: T, index: number, array: T[]) => Promise<boolean>) {
        const results = await Promise.all(arr.map(predicate));
        return arr.filter((_v, index) => results[index]);
    }

    /** @internal */
    async hasL1Access(rigId: string, rigList?: Rig[]) {
        if (isNil(rigList)) {
            rigList = await firstValueFrom(this.rigList$);
        }

        const rig = rigList.find(x => x.id === rigId);

        if (isNil(rig)) {
            console.error(`Rig with ID '${rigId}' not found`);
            return false;
        }

        const userAccess = await firstValueFrom(this.userAccess$) as UserAccess<L1AccessLevel>;
        return userAccess.levelSettings.operatorList.includes(rig.operator);
    }

    /** @internal */
    async hasL3Access(rigId: string, startTime: number) {
        const { levelSettings } = await firstValueFrom(this.userAccess$) as UserAccess<L3AccessLevel>;

        if (!levelSettings.rigList.includes(rigId)) {
            return false;
        }

        let accessStart = true;
        let accessEnd = true;

        if (!isNil(levelSettings.startDate)) {
            accessStart = startTime >= levelSettings.startDate;
        }

        if (!isNil(levelSettings.endDate)) {
            accessEnd = startTime <= levelSettings.endDate;
        }

        return accessStart && accessEnd;
    }

    /** @internal */
    private async getUserAccessByPermission(id: PermissionIDs): Promise<boolean | undefined> {
        const userAccess = await firstValueFrom(this.userAccess$);

        if (userAccess.level === AccessLevel.L0) {
            return true;
        }

        return userAccess.permissions?.[id];
    }
}
