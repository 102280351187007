<header>
    <h3 class="title">Range picker</h3>

    <button (click)="onCancel()" class="close cl-icon-button">
        <icon name="close"></icon>
    </button>
</header>

<div class="content">
    <div class="chart-wrapper">

        <!-- Chart -->
        <cyberloop-well-chart #chart [bde]="bde$ | async" [wde]="wde$ | async" [sections]="sections$ | async" class="chart"></cyberloop-well-chart>

        <!-- Draggable elements -->
        <div
            class="selection-controls"
            [style.left.px]="chart.plotAreaX$ | async"
            [style.top.px]="chart.plotAreaY$ | async"
            [style.width.px]="chart.plotAreaWidth$ | async"
            [style.height.px]="chart.plotAreaHeight$ | async"
        >
            <!-- The selection rect container. It's moved out of handles
                    selection area to achieve selection border radius -->
            <div class="selection-area selection-rect-area">
                <div
                    class="selection-rect"
                    [style.left.px]="selectionX$ | async"
                    [style.width.px]="selectionWidth$ | async"
                ></div>
            </div>

            <!-- The boundary area for selection handles. They cannot move out of it -->
            <div class="selection-area selection-handles-area">

                <!-- Handles -->

                <div
                    #selectionStart
                    class="handle"
                    cdkDrag
                    cdkDragBoundary=".selection-handles-area"
                    cdkDragLockAxis="x"
                    (cdkDragMoved)="onHandleMoved()"
                    (cdkDragReleased)="onHandleReleased()"
                ></div>

                <div
                    #selectionEnd
                    class="handle"
                    cdkDrag
                    cdkDragBoundary=".selection-handles-area"
                    cdkDragLockAxis="x"
                    (cdkDragMoved)="onHandleMoved()"
                    (cdkDragReleased)="onHandleReleased()"
                ></div>

            </div>
        </div>

    </div>

    <!-- Inputs -->
    <div class="inputs">
        <h4>Select date range</h4>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <fieldset>
                <label>From</label>
                <mat-form-field class="input-date">
                    <input type="date" matInput formControlName="startDate" />
                </mat-form-field>
                <mat-form-field class="input-time">
                    <input type="text" matInput formControlName="startTime" min="0" max="23" step="1" pattern="[0-9]{2}" />
                    <span matSuffix class="suffix">h</span>
                </mat-form-field>
            </fieldset>
            <fieldset>
                <label>To</label>
                <mat-form-field class="input-date">
                    <input type="date" matInput formControlName="endDate" />
                </mat-form-field>
                <mat-form-field class="input-time">
                    <input type="text" matInput formControlName="endTime" min="0" max="23" step="1" pattern="[0-9]{2}" />
                    <span matSuffix class="suffix">h</span>
                </mat-form-field>
            </fieldset>

            <hr>

            <section class="actions">
                <button type="button" class="cl-flat-button" (click)="onCancel()">Cancel</button>
                <button type="submit" class="cl-primary-button">Apply</button>
            </section>
        </form>
    </div>

</div>
