import { Injectable } from '@angular/core';

import { AccessService, CoreSelectors, NotificationService } from '@cyberloop/core';
import { WellsActions } from '@cyberloop/web/wells/data';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { isNil } from 'lodash';
import { catchError, combineLatest, filter, first, from, map, switchMap, takeUntil, tap } from 'rxjs';

import { PlanningDataService } from '../services/planning-data.service';
import { PlanningService } from '../services/planning.service';
import { PlanningActions } from './planning.actions';
import { PLANNING_FEATURE } from './planning.state';
import { PlanningVersionActions } from './version/version.actions';

const initAction = createAction(`[${PLANNING_FEATURE}] Initialize planning`);

@Injectable()
export class PlanningEffects implements OnInitEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly planningDataService: PlanningDataService,
        private readonly planningService: PlanningService,
        private readonly notificationService: NotificationService,
        private readonly accessService: AccessService
    ) { }

    //#region Common
    readonly handleError$ = createEffect(() => this.actions$.pipe(
        ofType(
            PlanningActions.loadListFailure,
            PlanningActions.createPlanningFailure,
            PlanningActions.updatePlanningFailure,
            PlanningActions.loadPlanningFailure,
            PlanningActions.selectVersionFailure,
            PlanningActions.setWellidFailure
        ),
        tap(({ error }) => this.notificationService.error(error))
    ), { dispatch: false });
    //#endregion Common

    //#region List
    readonly loadList$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.loadList),
        switchMap(() => combineLatest([
            this.store.select(CoreSelectors.allRigs),
            this.planningDataService.watchAll()
        ]).pipe(
            takeUntil(this.actions$.pipe(ofType(PlanningActions.unwatchList))),
            switchMap(([rigList, planningList]) => this.planningService.filterPlanning(rigList, planningList)),
            map((list) => PlanningActions.loadListSuccess({ list })),
            catchError(error => [PlanningActions.loadListFailure({ error })])
        ))
    ));
    //#endregion List

    //#region Planning
    //#region Load
    readonly loadPlanningByRouteParam$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.loadPlanningByRouteParam),
        switchMap(() => this.planningService.planningRouteId$.pipe(
            first(),
            filter(Boolean),
            switchMap((planningId) => this.planningDataService.watch(planningId).pipe(
                takeUntil(this.actions$.pipe(ofType(PlanningActions.unwatchPlanning))),
                map(planning => PlanningActions.loadPlanningSuccess({ planning })),
                catchError(error => [PlanningActions.loadPlanningFailure({ error })])
            ))
        ))
    ));

    readonly loadPlanningByCurrentWell$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.loadPlanningByCurrentWell),
        switchMap(({ showSetPopup }) => this.store.select(CoreSelectors.currentWellId).pipe(
            first(),
            filter(Boolean),
            switchMap((wellId) => this.planningDataService.watchByWellId(wellId).pipe(
                takeUntil(this.actions$.pipe(ofType(PlanningActions.unwatchPlanning))),
                map(planning => PlanningActions.loadWellPlanSuccess({ planning, showSetPopup })),
                catchError(error => [PlanningActions.loadPlanningFailure({ error })])
            ))
        ))
    ));

    readonly openSetPlanPopup$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.loadWellPlanSuccess),
        filter(({ planning, showSetPopup }) => isNil(planning) && (showSetPopup ?? false)),
        map(() => WellsActions.openSetPlanPopup())
    ));
    //#endregion Load

    //#region Create
    readonly createPlanning$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.createPlanning),
        switchMap(({ createPlanning }) => this.planningDataService.create(createPlanning).pipe(
            map((planning) => PlanningActions.createPlanningSuccess({ planning })),
            catchError(error => [PlanningActions.createPlanningFailure({ error })])
        ))
    ));

    readonly createPlanningSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.createPlanningSuccess),
        tap(({ planning }) => this.notificationService.info(`Planning '${planning.name}' created`))
    ), { dispatch: false });
    //#endregion Create

    //#region Update
    readonly updatePlanning$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.updatePlanning),
        switchMap(({ planningId, updatePlanning }) => this.planningDataService.update(planningId, updatePlanning).pipe(
            map((planning) => PlanningActions.updatePlanningSuccess({ planning })),
            catchError(error => [PlanningActions.updatePlanningFailure({ error })])
        ))
    ));

    readonly updatePlanningSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.updatePlanningSuccess),
        tap(({ planning }) => this.notificationService.info(`Planning '${planning.name}' updated`))
    ), { dispatch: false });
    //#endregion Update
    //#endregion Planning

    //#region Select version
    readonly selectVersion$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.selectVersion),
        switchMap(({ version }) => this.planningDataService.selectVersion(version).pipe(
            map((planning) => PlanningActions.selectVersionSuccess({ planning, version })),
            catchError(error => [PlanningActions.selectVersionFailure({ error })])
        ))
    ));

    readonly selectVersionSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.selectVersionSuccess),
        tap(({ version }) => this.notificationService.info(`Version '${version.name}' restored`))
    ), { dispatch: false });
    //#endregion Select version

    //#region Popups
    readonly openSaveAsPopup$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.openSaveAsPopup),
        switchMap(() => from(this.planningService.openSaveAsPopup()))
    ), { dispatch: false });

    readonly openAddPlanningPopup$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.openAddPlanningPopup),
        switchMap(() => from(this.planningService.openAddPlanningPopup()).pipe(
            filter(Boolean),
            map((createPlanning) => PlanningActions.createPlanning({ createPlanning }))
        ))
    ));

    readonly openEditPlanningPopup$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.openEditPlanningPopup),
        switchMap(({ planning }) => from(this.planningService.openEditPlanningPopup(planning)).pipe(
            filter(Boolean),
            map((updatePlanning) => PlanningActions.updatePlanning({ planningId: planning.id, updatePlanning }))
        ))
    ));
    //#endregion Popups

    //#region Set Well ID
    readonly setWellid$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.setWellid),
        switchMap(({ planningId, wellId }) => this.planningDataService.setWellId(planningId, wellId).pipe(
            map((planning) => PlanningActions.setWellidSuccess({ planning })),
            catchError(error => [PlanningActions.setWellidFailure({ error })])
        ))
    ));

    readonly setWellidSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.setWellidSuccess),
        tap(({ planning }) => this.notificationService.info(`Planning '${planning.name}' set`))
    ), { dispatch: false });
    //#endregion Set Well ID

    readonly loadVersionListByRouteParam$ = createEffect(() => this.actions$.pipe(
        ofType(PlanningActions.openSaveAsPopup),
        map(() => PlanningVersionActions.loadList())
    ));

    ngrxOnInitEffects(): Action {
        return initAction();
    }
}
