import { Component, Input } from '@angular/core';

/** @internal */
@Component({
    selector: 'cyberloop-animated-logo',
    standalone: true,
    imports: [],
    templateUrl: './animated-logo.component.html',
    styleUrls: ['./animated-logo.component.scss']
})
export class AnimatedLogoComponent {
    @Input() name = '';
}
