import { provideRouter } from '@angular/router';

import { appRoutes } from '@cyberloop/web/app/entry';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { AppEffects } from './app.effects';
import { appReducer } from './app.reducer';
import { APP_FEATURE, ROUTER_FEATURE } from './app.state';

export const AppProviders = [
    provideEffects([AppEffects]),
    provideStore({
        [APP_FEATURE]: appReducer,
        [ROUTER_FEATURE]: routerReducer
    }),
    provideRouter(
        appRoutes
    ),
    provideRouterStore(),
    provideStoreDevtools({
        maxAge: 30
    })
];
