
import { Injectable } from '@angular/core';

import { AccessLevel, AccessService, ConfirmationDialogService, CoreSelectors, ID, NotificationService, Point, PopupService, Rig, RigActivity, UnitDescriptor, UnitsConverterService, UnitsSelectors, WellKnownUnitGroupIds } from '@cyberloop/core';
import { FilesActions, FilesSelectors, FilesService } from '@cyberloop/web/files/data';
import { PlanningPopupComponent } from '@cyberloop/web/planning/list/ui-list';
import { CreatePlanning, Planning, PlanningPopupData, PlanningPopupResult, PlanningStage, PlanningVersion, SaveAsAction, SaveAsDialogData, SaveAsDialogResult, UpdatePlanning } from '@cyberloop/web/planning/shared/model';
import { SaveAsComponent } from '@cyberloop/web/planning/single/ui-single';
import { Store } from '@ngrx/store';
import { isEqual, isNil } from 'lodash';
import { Observable, combineLatest, distinctUntilChanged, filter, firstValueFrom, map, shareReplay } from 'rxjs';

import { PlanningActions } from '../state/planning.actions';
import { PlanningSelectors } from '../state/planning.selectors';
import { PlanningVersionActions } from '../state/version/version.actions';
import { PlanningVersionSelectors } from '../state/version/version.selectors';

@Injectable({ providedIn: 'root' })
export class PlanningService {
    constructor(
        private readonly store: Store,
        private readonly popupService: PopupService,
        private readonly confirmationDialogService: ConfirmationDialogService,
        private readonly unitsConverterService: UnitsConverterService,
        private readonly notificationService: NotificationService,
        private readonly filesService: FilesService,
        private readonly accessService: AccessService
    ) { }

    readonly depthUnit$ = this.store.select(UnitsSelectors.getUserUnit(WellKnownUnitGroupIds.Length)).pipe(shareReplay(1));
    readonly depthUnitLabel$ = this.depthUnit$.pipe(map(unit => unit?.label), shareReplay(1));

    readonly rigList$ = this.store.select(CoreSelectors.allRigs).pipe(shareReplay(1));

    readonly list$ = this.store.select(PlanningSelectors.list).pipe(shareReplay(1));
    readonly listLoading$ = this.store.select(PlanningSelectors.listLoading).pipe(shareReplay(1));
    readonly listLoaded$ = this.store.select(PlanningSelectors.listLoaded).pipe(shareReplay(1));

    readonly planningAddLoading$ = this.store.select(PlanningSelectors.planningAddLoading).pipe(shareReplay(1));
    readonly planningUpdateLoading$ = this.store.select(PlanningSelectors.planningUpdateLoading).pipe(shareReplay(1));

    readonly planningRouteId$ = this.store.select(PlanningSelectors.planningRouteId).pipe(shareReplay(1));

    readonly planning$ = this.store.select(PlanningSelectors.planning).pipe(shareReplay(1));
    readonly planningId$ = this.store.select(PlanningSelectors.planningId).pipe(filter(Boolean), shareReplay(1));
    readonly planningName$ = this.store.select(PlanningSelectors.planningName).pipe(shareReplay(1));
    readonly planningLoading$ = this.store.select(PlanningSelectors.planningLoading).pipe(shareReplay(1));
    readonly planningLoaded$ = this.store.select(PlanningSelectors.planningLoaded).pipe(shareReplay(1));

    readonly editedStages$ = this.store.select(PlanningVersionSelectors.editedStages).pipe(distinctUntilChanged((a, b) => isEqual(a, b)), shareReplay(1));
    readonly isStagesEdited$ = this.store.select(PlanningVersionSelectors.isStagesEdited).pipe(shareReplay(1));

    readonly versionList$ = this.store.select(PlanningVersionSelectors.list).pipe(shareReplay(1));
    readonly versionListLoading$ = this.store.select(PlanningVersionSelectors.listLoading).pipe(shareReplay(1));
    readonly versionListLoaded$ = this.store.select(PlanningVersionSelectors.listLoaded).pipe(shareReplay(1));

    readonly version$ = this.store.select(PlanningVersionSelectors.version).pipe(distinctUntilChanged((a, b) => isEqual(a, b)), shareReplay(1));
    readonly versionId$ = this.store.select(PlanningVersionSelectors.versionId).pipe(shareReplay(1));
    readonly versionName$ = this.store.select(PlanningVersionSelectors.versionName).pipe(shareReplay(1));

    readonly pageLoading$ = combineLatest([
        this.store.select(PlanningSelectors.planningLoading),
        this.store.select(PlanningVersionSelectors.versionLoading)
    ]).pipe(
        map(([planningLoading, versionLoading]) => planningLoading || versionLoading),
        shareReplay(1)
    );
    readonly pageLoaded$ = combineLatest([
        this.store.select(PlanningSelectors.planningLoaded),
        this.store.select(PlanningVersionSelectors.versionLoaded)
    ]).pipe(
        map(([planningLoaded, versionLoaded]) => planningLoaded && versionLoaded),
        shareReplay(1)
    );

    readonly actionLoading$ = combineLatest([
        this.store.select(PlanningSelectors.actionLoading),
        this.store.select(PlanningVersionSelectors.actionLoading),
        this.store.select(FilesSelectors.loading)
    ]).pipe(
        map(([planningLoading, versionLoading, filesLoading]) => planningLoading || versionLoading || filesLoading),
        shareReplay(1)
    );

    readonly chartData$: Observable<Point[][]> = combineLatest([
        this.depthUnit$.pipe(filter(Boolean)),
        this.editedStages$
    ]).pipe(
        map(([depthUnit, editedStages]) => this.mapChartData(depthUnit, editedStages)),
        shareReplay(1)
    );

    async openAddPlanningPopup(): Promise<CreatePlanning | undefined> {
        const result = await this.popupService.showForResult<PlanningPopupResult | undefined, PlanningPopupData>(PlanningPopupComponent, {
            data: {
                rigList$: this.rigList$
            }
        });

        if (isNil(result)) {
            return;
        }

        if (!isNil(result.rigId)) {
            return {
                ...result,
                rigName: await this.getRigName(result.rigId)
            };
        }

        return result;
    }

    async openEditPlanningPopup(planning: Planning): Promise<UpdatePlanning | undefined> {
        const result = await this.popupService.showForResult<PlanningPopupResult | undefined, PlanningPopupData>(PlanningPopupComponent, {
            data: {
                rigList$: this.rigList$,
                planning
            }
        });

        if (isNil(result)) {
            return;
        }

        if (!isNil(result.rigId)) {
            return {
                ...result,
                rigName: await this.getRigName(result.rigId)
            };
        }

        return result;
    }

    async openSaveAsPopup() {
        const activeVersion = await firstValueFrom(this.version$);

        const result = await this.popupService.showForResult<SaveAsDialogResult | undefined, SaveAsDialogData>(SaveAsComponent, {
            data: {
                activeVersion,
                versionList$: this.versionList$
            }
        });

        if (isNil(result)) {
            return;
        }

        switch (result.action) {
            case SaveAsAction.Current:
                this.store.dispatch(PlanningVersionActions.updateVersion({
                    saveAsResult: {
                        name: result.name,
                        description: result.description
                    }
                }));
                break;

            case SaveAsAction.Replace:
                this.store.dispatch(PlanningVersionActions.replaceVersion({
                    replaceVersionId: result.versionId ?? '',
                    saveAsResult: {
                        name: result.name,
                        description: result.description
                    }
                }));
                break;

            case SaveAsAction.New:
                this.store.dispatch(PlanningVersionActions.createVersion({
                    saveAsResult: {
                        name: result.name,
                        description: result.description
                    }
                }));
                break;
        }
    }

    async attachFile(id: ID, activity: RigActivity) {

        if (await firstValueFrom(this.isStagesEdited$)) {
            this.notificationService.warn('Please save changes before attaching files');
            return;
        }

        const file = await this.filesService.open();

        if (file) {
            const planningId = await firstValueFrom(this.planningId$);

            if (!isNil(planningId)) {
                this.store.dispatch(FilesActions.uploadPlanningStageFile({
                    file,
                    planningId,
                    stageId: id,
                    activityId: activity
                }));
            }
        }
    }

    async deleteFile(fileId: ID) {
        if (await firstValueFrom(this.isStagesEdited$)) {
            this.notificationService.warn('Please save changes before deleting files');
            return;
        }

        this.store.dispatch(FilesActions.tryDelete({ id: fileId }));
    }

    async restoreVersion(version: PlanningVersion) {
        if (await this.confirmationDialogService.show(`Do you really want to restore '${version.name}' version?`)) {
            this.store.dispatch(PlanningActions.selectVersion({ version }));
        }
    }

    async deleteVersion(version: PlanningVersion) {
        if (await this.confirmationDialogService.show(`Do you really want to delete '${version.name}' version?`)) {
            this.store.dispatch(PlanningVersionActions.deleteVersion({ version }));
        }
    }

    async filterPlanning(rigList: Rig[], planningList: Planning[]): Promise<Planning[]> {
        return await this.accessService.asyncFilter<Planning>(
            planningList,
            async planning => await this.hasPlanningAccess(planning.id, planningList, rigList)
        );
    }

    async hasPlanningAccess(planningId: string, planningList?: Planning[], rigList?: Rig[]) {
        if (isNil(planningList)) {
            planningList = await firstValueFrom(this.list$);
        }

        const planning = planningList.find(x => x.id === planningId);

        if (isNil(planning)) {
            console.error(`Planning with ID '${planningId}' not found`);
            return false;
        }

        const userAccess = await firstValueFrom(this.accessService.userAccess$);

        if (userAccess.level === AccessLevel.L0) {
            return true;
        }

        if (isNil(planning.rigId)) {
            return false;
        }

        if (userAccess.level === AccessLevel.L1) {
            return this.accessService.hasL1Access(planning.rigId, rigList);
        }

        if (userAccess.level === AccessLevel.L2) {
            return userAccess.levelSettings.rigList.includes(planning.rigId);
        }

        if (isNil(planning.plannedStartDate)) {
            return false;
        }

        if (userAccess.level === AccessLevel.L3) {
            return this.accessService.hasL3Access(planning.rigId, planning.plannedStartDate.valueOf());
        }

        return false;
    }

    private async getRigName(rigId: ID): Promise<string> {
        const rig = await firstValueFrom(this.store.select(CoreSelectors.selectRig(rigId)));

        if (isNil(rig)) {
            throw new Error('Rig not found');
        }

        return rig.name;
    }

    mapChartData(depthUnit: UnitDescriptor, stages?: PlanningStage[]) {
        if (!stages?.length) {
            return [];
        }

        const filteredStages = stages?.filter(stage => !isNil(stage.endDepth) && !isNil(stage.estTimeCumulative) && !isNil(stage.techLimitCumulative)) ?? [];

        if (!filteredStages?.length) {
            return [];
        }

        const firstPoint: Point = { x: 0, y: filteredStages.at(0)?.startDepth ?? 0 };
        const series1: Point[] = [firstPoint];
        const series2: Point[] = [firstPoint];

        filteredStages.forEach(stage => {
            const depth = this.unitsConverterService.convertToUnit(stage.endDepth, depthUnit);
            series1.push({ x: stage.estTimeCumulative ?? Number.MIN_SAFE_INTEGER, y: depth });
            series2.push({ x: stage.techLimitCumulative ?? Number.MIN_SAFE_INTEGER, y: depth });
        });

        return [series1, series2];
    }
}
