import type { ID } from '@cyberloop/core';
import { Observable } from 'rxjs';

import { PlanningVersion } from './version';

export enum SaveAsAction {
    Current = 'current',
    New = 'new',
    Replace = 'replace'
}

export interface SaveAsDialogData {
    activeVersion?: PlanningVersion;
    versionList$: Observable<Pick<PlanningVersion, 'id' | 'name'>[]>;
}

export interface SaveAsResult {
    name: string;
    description: string;
}

export interface SaveAsDialogResult extends SaveAsResult {
    action: SaveAsAction;
    versionId?: ID | null;
}
