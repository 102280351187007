import { WellKnownUnitIds } from '@cyberloop/core';
import { TndChart } from '@cyberloop/web/tnd/model';
import { createAction, createActionGroup, props } from '@ngrx/store';

import { TND_FEATURE } from './tnd.state';

import type { TndChartSettings } from '@cyberloop/web/tnd/model';


const TndActionsInternal = createActionGroup({
    source: TND_FEATURE,
    events: {
        'Set weights chart settings': props<{ settings: TndChartSettings }>(),
        'Set torque chart settings': props<{ settings: TndChartSettings }>(),

        'Update weights chart settings': props<{ settings: TndChartSettings }>(),
        'Update torque chart settings': props<{ settings: TndChartSettings }>(),

        'Update X unit ID for weights chart': props<{ unitId?: WellKnownUnitIds }>(),
        'Update Y unit ID for weights chart': props<{ unitId?: WellKnownUnitIds }>(),
        'Update X unit ID for torque chart': props<{ unitId?: WellKnownUnitIds }>(),
        'Update Y unit ID for torque chart': props<{ unitId?: WellKnownUnitIds }>(),

        'Set error': props<{ error?: Error }>(),
    }
});

/** Updates T&D chart settings epending on type */
const updateChartSettings = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return TndActionsInternal.updateTorqueChartSettings;

        default:
            return TndActionsInternal.updateWeightsChartSettings;
    }
};

const updateXUnitId = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return TndActionsInternal.updateXUnitIdForTorqueChart;

        default:
            return TndActionsInternal.updateXUnitIdForWeightsChart;
    }
};

const updateYUnitId = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return TndActionsInternal.updateYUnitIdForTorqueChart;

        default:
            return TndActionsInternal.updateYUnitIdForWeightsChart;
    }
};

/**
 * @internal
 * Actions for T&D used internally
 */
export const TndActions = {
    ...TndActionsInternal,

    updateChartSettings,
    updateXUnitId,
    updateYUnitId
};

/**
 * Public actions for T&D
 */
export const PublicTndActions = {
    updateWeightsChartSettings: TndActions.updateWeightsChartSettings,
    updateTorqueChartSettings: TndActions.updateTorqueChartSettings,
    updateChartSettings,

    setXUnitIdForWeightsChart: TndActions.updateXUnitIdForWeightsChart,
    setXUnitIdForTorqueChart: TndActions.updateXUnitIdForTorqueChart,
    updateXUnitId,

    setYUnitIdForWeightsChart: TndActions.updateYUnitIdForWeightsChart,
    setYUnitIdForTorqueChart: TndActions.updateYUnitIdForTorqueChart,
    updateYUnitId
};
