import { ExportDataColumn, Well } from '@cyberloop/core';

export type WriterParameters = {
    data: Record<string, number>[], 
    columns: ExportDataColumn[], 
    precision: number, 
    from?: number, 
    to?: number, 
    step?: number, 
    byDepth?: boolean, 
    well?: Well
}

export abstract class WriterBase {
    public abstract writeToString(params: WriterParameters): string;
    abstract get fileExt(): string;
    abstract get mime(): string;
} 