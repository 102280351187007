import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FormatNumberPipe, FromSiPipe, IconComponent, NoDataPipe, Points, RigActivity, RigStatusComponent, UnitDescriptor } from '@cyberloop/core';
import { WellDataObject } from '@cyberloop/web/app/model';
import { isNil } from 'lodash';

import { WellCardChartComponent } from '../well-card-chart/well-card-chart.component';
import { WellCardTextComponent } from '../well-card-text/well-card-text.component';
import { WellChartDialogComponent } from '../well-chart-dialog/well-chart-dialog.component';

@Component({
    selector: 'cyberloop-well-card',
    standalone: true,
    imports: [
        CommonModule,
        WellCardTextComponent,
        WellCardChartComponent,
        MatTooltipModule,
        MatRippleModule,
        NoDataPipe,
        IconComponent,
        WellChartDialogComponent,
        RigStatusComponent,
        FromSiPipe,
        FormatNumberPipe
    ],
    templateUrl: './well-card.component.html',
    styleUrls: ['./well-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WellCardComponent {

    @Input() well: WellDataObject | null | undefined;
    @Input() wellName = '';
    @Input() wellId = '';
    @Input() rigName = '';

    @Input() sectionName = '';

    @Input() rigActivity: RigActivity | null = RigActivity.BOPTesting;
    
    @Input() startTime: Date | undefined | null = null;
    @Input() suspendTime: Date | undefined | null = null;
    @Input() releaseTime: Date | undefined | null = null;

    @Input() currentDrilled: number | null = 0;
    @Input() currentBitDepth: number | null = 0;
    @Input() currentOnBtm: number | null = 0;
    @Input() currentRotary: number | null = 0;
    @Input() currentSlide: number | null = 0;
    @Input() currentWellDepth: number | null = 0;
    @Input() currentSinceSpud: number | null = 0;

    @Input() current: Points | null = [];
    @Input() planned: Points | null = [];

    @Input() dailyDrilled: number | undefined | null = 0;
    @Input() dailySlide: number | undefined | null = 0;
    @Input() dailyRop: number | undefined | null = 0;
    @Input() dailyOnBtm: number | undefined | null = 0;
    @Input() dailyRotary: number | undefined | null = 0;

    @Input() depthUnit: UnitDescriptor | null | undefined = null;
    @Input() depthUnitLabel = '';
    @Input() ropUnit: UnitDescriptor | null | undefined = null;
    @Input() ropUnitLabel = '';
    @Input() sectionsCount = 0;

    @Input() navigatedFromMe = false;

    @Output() readonly navigateToWell = new EventEmitter<void>();

    @Input() favorite = false;
    @Output() readonly toggleFavorite = new EventEmitter<boolean>();
    @Output() readonly openChartPopup = new EventEmitter<void>();

    get randomDelay() {
        return Math.ceil(750 + Math.random() * 750);
    }

    getFormat(value: number | null) { 
        let result = '1.0-2';
        if(isNil(value) || value > 1000) {
            result = '1.0-0';
        }
        
        return result;
    }

    getRigActivityName(state: RigActivity | null) {
        return RigActivity.getName(state ?? RigActivity.Unknown);
    }
    getRigActivityId(state: RigActivity | null) {
        return RigActivity.getId(state ?? RigActivity.Unknown);
    }

    openChartDialog() {
        this.openChartPopup.emit();
    }

    clickFavorite() {
        this.toggleFavorite.emit();
        this.favorite = !this.favorite;
    }
}
