import { Pipe, PipeTransform } from '@angular/core';

import { isNil } from 'lodash';

import { UnitsConverterService } from '../services/units-converter.service';

import type { Unit } from '../models/units';

/**
 * Show value in specific unit
 */
@Pipe({
    name: 'fromSi',
    standalone: true
})
export class FromSiPipe implements PipeTransform {
    /** @internal */
    constructor(private readonly unitsConverter: UnitsConverterService) { }

    /** @internal */
    transform(value: number | string | null | undefined, unit?: Unit | null): number | null {
        if (isNil(value)) {
            return null;
        }

        if (typeof value === 'string') {
            value = Number(value);
        }

        if (!unit) {
            return value;
        }

        return this.unitsConverter.convertToUnit(value, unit) ?? undefined;
    }
}
