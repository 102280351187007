import { createAction, props } from '@ngrx/store';

import { LoginRequest } from '../../models/login-request';
import { TenantInfo } from '../../models/tenant';
import { User } from '../../models/user';
import { AUTH_FEATURE } from './auth.state';

/** @internal */
const initialized = createAction(`[${AUTH_FEATURE}] Initialized`);
/** @internal */
const tryLogin = createAction(`[${AUTH_FEATURE}] Try login`, props<LoginRequest>());
/** @internal */
const logout = createAction(`[${AUTH_FEATURE}] Logout`);
/** @internal */
const setUser = createAction(`[${AUTH_FEATURE}] User logged in`, props<{ user: User }>());
/** @internal */
const setListOfTenants = createAction(`[${AUTH_FEATURE}] Set list of tenants`, props<{ tenants: TenantInfo[] }>());
/** @internal */
const setLoginError = createAction(`[${AUTH_FEATURE}] Login failed with ewrror`, props<{ err: any }>());

//#region Load tenant list
/** @internal */
const loadTenantList = createAction(`[${AUTH_FEATURE}] Load tenant list`, props<{ login: string, background?: boolean }>());
/** @internal */
const loadTenantListSuccess = createAction(`[${AUTH_FEATURE}] Load tenant list success`, props<{ listOfTenants: TenantInfo[] }>());
/** @internal */
const loadTenantListFailure = createAction(`[${AUTH_FEATURE}] Load tenant list failure`, props<{ error: any }>());
//#endregion Load tenant list

//#region Restore password
/** @internal */
const tryRestorePassword = createAction(`[${AUTH_FEATURE}] Try restore password`, props<{ login: string, tenantId: string }>());
/** @internal */
const restorePassword = createAction(`[${AUTH_FEATURE}] Restore password`, props<{ login: string, tenantId: string }>());
/** @internal */
const restorePasswordSuccess = createAction(`[${AUTH_FEATURE}] Restore password success`);
/** @internal */
const restorePasswordFailure = createAction(`[${AUTH_FEATURE}] Restore password failure`, props<{ error: any }>());
//#endregion Restore password

//#region Set new password
/** @internal */
const trySetNewPassword = createAction(`[${AUTH_FEATURE}] Try set new password`, props<{ code: string, password: string }>());
/** @internal */
const setNewPassword = createAction(`[${AUTH_FEATURE}] Set new password`, props<{ code: string, password: string }>());
/** @internal */
const setNewPasswordSuccess = createAction(`[${AUTH_FEATURE}] Set new password success`);
/** @internal */
const setNewPasswordFailure = createAction(`[${AUTH_FEATURE}] Set new password failure`, props<{ error: any }>());
//#endregion Set new password

/**
 * Actions for auth
 * */
export const PublicAuthActions = {
    /** logout action */
    logout
};

/**
 * @internal
 * Actions for auth used internally
 * */
export const AuthActions = {
    ...PublicAuthActions,
    tryLogin,
    initialized,
    setUser,
    setListOfTenants,
    setLoginError,
    tryRestorePassword,
    restorePassword,
    restorePasswordSuccess,
    restorePasswordFailure,
    trySetNewPassword,
    setNewPassword,
    setNewPasswordSuccess,
    setNewPasswordFailure,
    loadTenantList,
    loadTenantListSuccess,
    loadTenantListFailure
};
