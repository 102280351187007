<cyberloop-kpi-widget-settings class="settings" (close)="onCancel()">

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <fieldset>
            <legend>Select section</legend>
            <cl-selector formControlName="sectionId" [options]="sections$ | async" placeholder="Select a section..."></cl-selector>
        </fieldset>

        <!-- Axis settings -->

        <fieldset>
            <legend>Target</legend>
            <cl-numeric-input formControlName="target"></cl-numeric-input>
        </fieldset>

        <section class="actions">
            <button type="button" class="cl-flat-button" (click)="onCancel()">Cancel</button>
            <button type="submit" class="cl-primary-button">Apply</button>
        </section>

    </form>

</cyberloop-kpi-widget-settings>