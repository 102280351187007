import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

import { isNil } from 'lodash';

/**
 * Fix number pipe
 */
@Pipe({ name: 'formatNumber', standalone: true })
export class FormatNumberPipe extends DecimalPipe implements PipeTransform {
    /** @internal */
    constructor(@Inject(LOCALE_ID) locale: string,) {
        super(locale);
    }

    /** @internal */
    override transform(value: null | undefined, digitsInfo?: string, locale?: string): null;
    override transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
    override transform(value: number | null | undefined, digitsInfo?: string, locale?: string): null;
    override transform(value: string | null | undefined, digitsInfo?: string, locale?: string): null;
    override transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null {
        if (isNil(value)) {
            return null;
        }

        if (isNil(digitsInfo)) {
            digitsInfo = '1.0-2';
        }

        let strValue = super.transform(value, digitsInfo, locale);

        if (strValue) {
            strValue = strValue.replace(',', '');
        }

        // TODO assume that there are some cases where we should remove odd numbers after the decimal point

        return strValue;
    }
}
