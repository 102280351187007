import { Observable } from 'rxjs';

import { WellKnownParams } from '../models';

import type { RangePickerData } from "../models/range-picker-data";

/**
 * Provides data to the `ClRangePickerComponent`.
 */
export abstract class RangePickerDataService {

    /**
     * Get data for the picker's chart - some tags and/or sections.
     * @param tags Tags to fetch. Range picker uses WDE, BDE, but we may want to fetch any of them, or both, or none.
     * @param needSections Do we need to fetch sections?
     */
    abstract getData(tags: WellKnownParams[], needSections?: boolean): Observable<RangePickerData | undefined>;

}
