import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'cyberloop-green-arrows',
    standalone: true,
    template: `
    <svg width="38" height="34" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_9114_13714)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7071 10.2929C11.3166 9.90237 10.6834 9.90237 10.2929 10.2929C9.90237 10.6834 9.90237 11.3166 10.2929 11.7071L14.5355 15.9497C14.9261 16.3403 14.9261 16.9734 14.5355 17.364L10.2929 21.6066C9.90237 21.9971 9.90237 22.6303 10.2929 23.0208C10.6834 23.4113 11.3166 23.4113 11.7071 23.0208L17.364 17.364C17.7545 16.9734 17.7545 16.3403 17.364 15.9497L11.7071 10.2929Z" fill="url(#paint0_linear_9114_13714)" shape-rendering="crispEdges"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7784 10.2929C16.3879 9.90237 15.7547 9.90237 15.3642 10.2929C14.9737 10.6834 14.9737 11.3166 15.3642 11.7071L19.6068 15.9497C19.9973 16.3403 19.9973 16.9734 19.6068 17.364L15.3642 21.6066C14.9737 21.9971 14.9737 22.6303 15.3642 23.0208C15.7547 23.4113 16.3879 23.4113 16.7784 23.0208L22.4353 17.364C22.8258 16.9734 22.8258 16.3403 22.4353 15.9497L16.7784 10.2929Z" fill="url(#paint1_linear_9114_13714)" shape-rendering="crispEdges"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.7784 10.2929C21.3879 9.90237 20.7547 9.90237 20.3642 10.2929C19.9737 10.6834 19.9737 11.3166 20.3642 11.7071L24.6068 15.9497C24.9973 16.3403 24.9973 16.9734 24.6068 17.364L20.3642 21.6066C19.9737 21.9971 19.9737 22.6303 20.3642 23.0208C20.7547 23.4113 21.3879 23.4113 21.7784 23.0208L27.4352 17.364C27.8258 16.9734 27.8258 16.3403 27.4352 15.9497L21.7784 10.2929Z" fill="url(#paint2_linear_9114_13714)" shape-rendering="crispEdges"/>
        </g>
        <defs>
            <filter id="filter0_d_9114_13714" x="0" y="0" width="37.728" height="33.3135" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="5"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.8 0 0 0 0 0.415686 0 0 0 0.5 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9114_13714"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9114_13714" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_9114_13714" x1="27.9287" y1="16.5859" x2="9.92871" y2="16.5859" gradientUnits="userSpaceOnUse">
                <stop stop-color="#22B692"/>
                <stop offset="1" stop-color="#22B692" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_9114_13714" x1="27.9287" y1="16.5859" x2="9.92871" y2="16.5859" gradientUnits="userSpaceOnUse">
                <stop stop-color="#22B692"/>
                <stop offset="1" stop-color="#22B692" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint2_linear_9114_13714" x1="27.9287" y1="16.5859" x2="9.92871" y2="16.5859" gradientUnits="userSpaceOnUse">
                <stop stop-color="#22B692"/>
                <stop offset="1" stop-color="#22B692" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>
    `,
    styles: [`:host{display: flex; align-items: center;}`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GreenArrowsComponent {

}
