<div class="container">
  <span class="filters-title">show</span>
  <cl-checkbox-group [list]="filters" [selected]="selectedTypeFilters" (selectedChange)="onToggleTypeFilter($event)"></cl-checkbox-group>

  <cl-dropdown class="button-with-dropdown rig-dropdown" [current]="activeFilters?.rig" [items]="rigs"
    (currentChange)="onSelectedRig($event)">
    <icon name="rig"></icon>
    <span class="dropdown-title">{{activeFilters?.rig}}</span>
  </cl-dropdown>

  <cl-dropdown class="button-with-dropdown">
    <icon name="calendar"></icon>
    <span class="dropdown-title">time frame</span>
  </cl-dropdown>
</div>