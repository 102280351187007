import { createReducer, on } from '@ngrx/store';

import { TndActions } from './tnd.actions';
import { getDefaultWellsEngineeringTndState } from './tnd.state';

import type { WellsEngineeringTndState } from './tnd.state';

export const wellsEngineeringTndReducer = createReducer<WellsEngineeringTndState>(getDefaultWellsEngineeringTndState(),

    on(TndActions.setWeightsChartSettings, (state, { settings }): WellsEngineeringTndState => {
        return ({
            ...state,
            weightsSettings: settings
        });
    }),
    on(TndActions.setTorqueChartSettings, (state, { settings }): WellsEngineeringTndState => {
        return ({
            ...state,
            torqueSettings: settings
        });
    }),



    on(TndActions.selectSectionForWeightsChart, (state, { sectionId }): WellsEngineeringTndState => {
        return ({
            ...state,
            weightsSettings: {
                ...state.weightsSettings,
                selectedSectionId: sectionId
            }
        });
    }),
    on(TndActions.selectModelForWeightsChart, (state, { modelId }): WellsEngineeringTndState => {
        return ({
            ...state,
            weightsSettings: {
                ...state.weightsSettings,
                selectedModelId: modelId
            }
        });
    }),



    on(TndActions.selectSectionForTorqueChart, (state, { sectionId }): WellsEngineeringTndState => {
        return ({
            ...state,
            torqueSettings: {
                ...state.torqueSettings,
                selectedSectionId: sectionId
            }
        });
    }),
    on(TndActions.selectModelForTorqueChart, (state, { modelId }): WellsEngineeringTndState => {
        return ({
            ...state,
            torqueSettings: {
                ...state.torqueSettings,
                selectedModelId: modelId
            }
        });
    }),



    on(TndActions.setError, (state, { error }): WellsEngineeringTndState => ({
        ...state,
        error
    }))

);
