import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { TndEffects } from './tnd.effects';
import { wellsEngineeringTndReducer } from './tnd.reducer';
import { WELLS_ENGINEERING_TND_FEATURE } from './tnd.state';

export const TndStateProviders = [
    provideEffects([TndEffects]),
    provideState(WELLS_ENGINEERING_TND_FEATURE, wellsEngineeringTndReducer)
];