import { Injectable } from '@angular/core';

import { ClError, ID, uuid } from '@cyberloop/core';
import { PlanningTemplateProviderService } from '@cyberloop/web/planning/shared/data';
import { CreatePlanningTemplate, PlanningTemplate } from '@cyberloop/web/planning/shared/model';
import { isNil } from 'lodash';
import { EMPTY, Observable, finalize, from, shareReplay, switchMap } from 'rxjs';

import { FirestoreDataConverter, or, orderBy, where } from 'firebase/firestore';
import * as moment from 'moment';

import { DBCreatePlanningTemplate, DBPlanningTemplate } from '../models/planning';
import { ClApplicationManager } from './internals/client-app/cl-app-manager';

import type { ClApplication } from './internals/client-app/cl-application';

const PLANNING_TEMPLATES_COLLECTION_NAME = 'planning-templates';

@Injectable({
    providedIn: 'root'
})
export class FirebasePlanningTemplateProviderLinkService extends PlanningTemplateProviderService {
    private readonly _converter: FirestoreDataConverter<PlanningTemplate> = {
        toFirestore: (data: PlanningTemplate) => data,
        fromFirestore: (snapshot, options): PlanningTemplate => {
            const data = snapshot.data(options) as DBPlanningTemplate;

            return {
                ...data,
                createdAt: moment(data.createdAt),
                updatedAt: moment(data.updatedAt)
            };
        }
    };

    private _listObservable: Observable<PlanningTemplate[]> | undefined;

    constructor(private readonly appMgr: ClApplicationManager) {
        super();
    }

    watchAll() {
        return this._listObservable ??= this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().watchCollection<PlanningTemplate>(
                    PLANNING_TEMPLATES_COLLECTION_NAME,
                    this._converter,
                    [
                        orderBy('createdAt', 'desc')
                    ],
                    or(
                        where('access', '==', 'public'),
                        where('author', '==', this.getUserEmail(x))
                    )
                ) ?? EMPTY
            ),
            finalize(() => this._listObservable = undefined),
            shareReplay(1)
        );
    }

    get(templateId: ID) {
        return this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().get<PlanningTemplate>(
                    this.getPath(PLANNING_TEMPLATES_COLLECTION_NAME, templateId),
                    this._converter
                ) ?? EMPTY
            ),
            shareReplay(1)
        );
    }

    create(createTemplate: CreatePlanningTemplate) {
        const id = uuid();

        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x =>
                    from(
                        x?.getFirestore().set<DBCreatePlanningTemplate>(
                            this.getPath(PLANNING_TEMPLATES_COLLECTION_NAME, id),
                            {
                                ...createTemplate,
                                id,
                                author: this.getUserEmail(x),
                                createdAt: moment.now(),
                                updatedAt: moment.now()
                            }
                        ) ?? EMPTY
                    )
                ),
                switchMap(() => this.get(id) as Observable<PlanningTemplate>),
                shareReplay(1)
            );
    }

    delete(templateId: ID) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    const template = await transaction.get<PlanningTemplate>(
                        this.getPath(PLANNING_TEMPLATES_COLLECTION_NAME, templateId),
                        this._converter
                    );

                    if (isNil(template)) {
                        throw new Error('Template not found');
                    }

                    await transaction.delete(
                        this.getPath(PLANNING_TEMPLATES_COLLECTION_NAME, templateId)
                    );

                    return template;
                }) ?? EMPTY),
                shareReplay(1)
            );
    }

    private getUserEmail(x?: ClApplication) {
        const email = x?.getAuth().currentUser?.email;

        if (isNil(email)) {
            throw new ClError('User email not found');
        }

        return email;
    }

    private getPath(...args: string[]) {
        return args.join('/');
    }
}
