<div class="overlay" *ngIf="errorInfo$|async; let errInfo">
    <div class="container">
        <svg class="icon" viewBox="0 0 209 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" [attr.d]="errInfo.path" fill="currentColor" />
        </svg>
        <div class="title">
            {{errInfo.title}}
        </div>
        <div class="text">
            {{errInfo.text}}
        </div>
    </div>
</div>