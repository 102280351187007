import { ID } from '@cyberloop/core';
import { CreatePlanning, Planning, PlanningVersion, UpdatePlanning } from '@cyberloop/web/planning/shared/model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PLANNING_FEATURE } from './planning.state';

/**
 * Actions for planning used internally
 * @internal
 * */
export const PlanningActions = createActionGroup({
    source: PLANNING_FEATURE,
    events: {
        'Load list': emptyProps(),
        'Load list success': props<{ list: Planning[] }>(),
        'Load list failure': props<{ error: Error }>(),
        'Unwatch list': emptyProps(),

        'Load planning by route param': emptyProps(),
        'Load planning by current Well': props<{ showSetPopup?: boolean }>(),
        'Load planning success': props<{ planning: Planning | null }>(),
        'Load Well plan success': props<{ planning: Planning | null, showSetPopup?: boolean }>(),
        'Load planning failure': props<{ error: Error }>(),
        'Unwatch planning': emptyProps(),

        'Create planning': props<{ createPlanning: CreatePlanning }>(),
        'Create planning success': props<{ planning: Planning }>(),
        'Create planning failure': props<{ error: Error }>(),

        'Update planning': props<{ planningId: ID, updatePlanning: UpdatePlanning }>(),
        'Update planning success': props<{ planning: Planning }>(),
        'Update planning failure': props<{ error: Error }>(),

        'Select version': props<{ version: PlanningVersion }>(),
        'Select version success': props<{ planning: Planning, version: PlanningVersion }>(),
        'Select version failure': props<{ error: Error }>(),

        'Open add planning popup': emptyProps(),
        'Open edit planning popup': props<{ planning: Planning }>(),
        'Open save as popup': emptyProps(),

        'Set wellId': props<{ wellId: ID, planningId: ID }>(),
        'Set wellId success': props<{ planning: Planning }>(),
        'Set wellId failure': props<{ error: Error }>()
    }
});

/**
 * Actions for planning
 * */
export const PublicPlanningActions = {
    loadList: PlanningActions.loadList,
    unwatchList: PlanningActions.unwatchList,
    loadPlanningByRouteParam: PlanningActions.loadPlanningByRouteParam,
    loadPlanningByCurrentWell: PlanningActions.loadPlanningByCurrentWell,
    unwatchPlanning: PlanningActions.unwatchPlanning,
    openAddPlanningPopup: PlanningActions.openAddPlanningPopup,
    openEditPlanningPopup: PlanningActions.openEditPlanningPopup,
    openSaveAsPopup: PlanningActions.openSaveAsPopup,
    selectVersion: PlanningActions.selectVersion,
    setWellid: PlanningActions.setWellid,
    setWellidSuccess: PlanningActions.setWellidSuccess
};
