import { FileMeta } from '@cyberloop/web/files/model';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const FILES_FEATURE = 'FILES';

/**
 * State for files
 * */
export interface FilesState extends EntityState<FileMeta> {
    listLoading: boolean;
    listByIDsLoading: boolean;
    downloadLoading: boolean;
    uploadLoading: boolean;
    updateLoading: boolean;
    deleteLoading: boolean;
}

export const adapter = createEntityAdapter<FileMeta>({
    sortComparer: (a, b) => b.createdAt.valueOf() - a.createdAt.valueOf()
});

export const initialState: FilesState = adapter.getInitialState({
    listLoading: false,
    listByIDsLoading: false,
    downloadLoading: false,
    uploadLoading: false,
    updateLoading: false,
    deleteLoading: false
});
