import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DDState, DD_FEATURE } from './dd.state';

const feature = createFeatureSelector<DDState>(DD_FEATURE);


const sectionsByWellID = (wellID: string) => createSelector(feature, state => state.sections[wellID]);
const wellPlanIdByWellID = (wellID: string) => createSelector(feature, state => state.wellPlanId[wellID]);

/**
 * Selectors for directional drilling
 * */
export const DirectionalDrillingSelectors = {
    sectionsByWellID,
    wellPlanIdByWellID
};
