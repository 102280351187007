import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';

import { ClButtonToggleComponent, ClChartComponent, CompactNumberFormatter, DurationPipe, UnitsConverterService, UnitsSelectors, WellKnownUnitGroupIds } from '@cyberloop/core';
import { KpiActions, KpiSelectors } from '@cyberloop/web/wells/data';
import { SlideVsRotaryWidgetSettingsMode, WidgetDataProvider, WidgetSettingsHandler, WidgetSize, WidgetType, slideVsRotaryDefaultSettings } from '@cyberloop/web/wells/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest, filter, map, switchMap, tap } from 'rxjs';

import * as moment from 'moment';

import { KpiWidgetComponent } from '../widget/kpi-widget.component';
import { SettingsComponent } from './settings/settings.component';

import type { Entity, UnitDescriptor } from '@cyberloop/core';
import type { SimpleChangesOf } from '@cyberloop/core';
import type { KpiWidget, SlideVsRotaryWidgetSettings, SlideVsRotaryWidgetData } from '@cyberloop/web/wells/model';

const SLIDE_SERIES_ID = 'slide';
const ROTARY_SERIES_ID = 'rotary';

const X_LABELS_MAX_COUNT = 10;
const Y_TICKS_COUNT = 5; // 5 ticks

@Component({
    selector: 'cyberloop-slide-vs-rotary-widget',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        KpiWidgetComponent,
        ClChartComponent,
        ClButtonToggleComponent
    ],
    templateUrl: './slide-vs-rotary-widget.component.html',
    styleUrls: ['./slide-vs-rotary-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class SlideVsRotaryWidgetComponent implements KpiWidget, OnChanges {

    private readonly _id$ = new BehaviorSubject<string>('-');
    private readonly _dataLoading$ = new BehaviorSubject<boolean>(true);

    private readonly _durationPipe = new DurationPipe();

    private _settings: SlideVsRotaryWidgetSettings = slideVsRotaryDefaultSettings;
    private _data?: SlideVsRotaryWidgetData;
    private _selectedMode?: SlideVsRotaryWidgetSettingsMode;
    private _lengthUnit?: UnitDescriptor;
    private _timeUnit?: UnitDescriptor;

    constructor(
        private readonly store: Store,
        private readonly settings: WidgetSettingsHandler,
        private readonly data: WidgetDataProvider,
        private readonly unitsConverter: UnitsConverterService
    ) {
        const idPipe = this._id$.pipe(
            untilDestroyed(this),
            filter(Boolean),
            tap(() => this._dataLoading$.next(true))
        );

        this.selectedMode$ = combineLatest([ idPipe, this._dataLoading$.pipe(filter(x => !x)) ]).pipe(
            switchMap(([id]) => this.store.select(KpiSelectors.widgetSettings<SlideVsRotaryWidgetSettings>(id))),
            map(widgetSettings => widgetSettings?.mode ?? slideVsRotaryDefaultSettings.mode)
        );

        combineLatest([
            // Get widget data
            idPipe.pipe(
                switchMap(id => this.data.getData(id, WidgetType.SlideVsRotary))
            ),
            // Get widget settings (for units conversion)
            idPipe.pipe(
                switchMap(id => this.store.select(KpiSelectors.widgetSettings<SlideVsRotaryWidgetSettings>(id))),
                tap(widgetSettings => {
                    this._settings = widgetSettings ?? slideVsRotaryDefaultSettings;
                    this._selectedMode = this._settings.mode;
                }),
                map(widgetSettings => ({
                    length: widgetSettings?.lengthUnitId ?? slideVsRotaryDefaultSettings.lengthUnitId,
                    time: widgetSettings?.timeUnitId ?? slideVsRotaryDefaultSettings.timeUnitId
                })),
                switchMap(({ length, time }) => combineLatest([
                    this.store.select(UnitsSelectors.getUnit(WellKnownUnitGroupIds.Length, length)),
                    this.store.select(UnitsSelectors.getUnit(WellKnownUnitGroupIds.Time, time))
                ]))
            )
        ]).pipe(
            untilDestroyed(this)
        ).subscribe(([widgetData, [lengthUnit, timeUnit]]) => {
            if (!this.chart) {
                return;
            }

            this._data = widgetData;
            this._lengthUnit = lengthUnit;
            this._timeUnit = timeUnit;

            this.updateData();

            this._dataLoading$.next(false);
        });
    }

    readonly modes: Entity<SlideVsRotaryWidgetSettingsMode>[] = [
        { id: SlideVsRotaryWidgetSettingsMode.Time, name: 'Time' },
        { id: SlideVsRotaryWidgetSettingsMode.Length, name: 'Length' }
    ];

    readonly selectedMode$: Observable<SlideVsRotaryWidgetSettingsMode | undefined>;

    readonly options = this.getOptions();
    readonly dataLoading$ = this._dataLoading$.asObservable();

    readonly placeholderColumns = new Array(10).fill(0).map(() => Math.floor(Math.random() * 9) + 1);

    @Input() id = '-';
    @Input() size = WidgetSize.Tiny;

    @ViewChild(ClChartComponent, { static: true }) chart?: ClChartComponent;

    ngOnChanges(changes: SimpleChangesOf<SlideVsRotaryWidgetComponent>): void {
        if (changes.id?.currentValue) {
            this._id$.next(changes.id.currentValue);
        }
    }

    onModeChange(id: SlideVsRotaryWidgetSettingsMode): void {
        this.store.dispatch(KpiActions.updateWidgetSettings({
            widgetId: this._id$.value,
            settings: {
                ...this._settings,
                mode: id
            }
        }));
    }

    async onSettings(): Promise<void> {
        await this.settings.showSettings<SlideVsRotaryWidgetSettings>(
            this.id,
            SettingsComponent,
            slideVsRotaryDefaultSettings
        );
    }

    onDelete(): void {
        this.store.dispatch(KpiActions.deleteWidget({ widgetId: this.id }));
    }

    private getOptions(): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        return {
            chart: {
                type: 'column',
                marginBottom: 80,
                marginLeft: 67,

                events: {
                    // load: function () {
                    //     this.title.attr({
                    //         text: self.getTitleHtml()
                    //     });
                    // },
                    render: function () {
                        // A hack to set the plot area radius
                        (this as any).plotBackground.attr({ rx: 6, ry: 6 });
                    }
                }
            },
            xAxis: {
                categories: [],
                // minPadding: 0,
                // maxPadding: 0,
                title: {
                    y: 25,
                    text: 'Pipes'
                },
                labels: {
                    y: 3,
                    autoRotation: undefined,
                    allowOverlap: true,
                    formatter: function (ctx: Highcharts.AxisLabelsFormatterContextObject) {
                        const labelsStep = self.getLabelsStep(ctx.axis);

                        if (labelsStep > 1 && (ctx.pos + 1) % labelsStep !== 0) {
                            return '';
                        }

                        const m = moment(parseInt(String(ctx.value), 10));

                        return `<span class="date">${m.format('D.MM')}</span>`;
                    },
                    style: {
                        textOverflow: 'none'
                    }
                }
            },
            yAxis: {
                id: 'main',
                // endOnTick: false,
                title: {
                    x: -2,
                    useHTML: true,
                    text: ''
                },
                labels: {
                    x: -8,
                    formatter: function () {
                        let value = 0;
                        let format = '';
                        let res = '';

                        switch (self._selectedMode) {

                            case SlideVsRotaryWidgetSettingsMode.Length:
                                res = CompactNumberFormatter.format(this.pos);
                                break;

                            case SlideVsRotaryWidgetSettingsMode.Time:
                                value = this.pos * 1000;
                                format = 'mm:ss';

                                if (self._timeUnit?.name.toLocaleLowerCase() === 'minutes') {
                                    value *= 60;
                                }
                                if (self._timeUnit?.name.toLocaleLowerCase() === 'hours') {
                                    format = 'HH:mm';
                                    value *= 3600;
                                }

                                res = self._durationPipe.transform(value, format) ?? '00:00';

                                if (res === '00') {
                                    res += ':00';
                                }
                                else if (res.indexOf(':') < 0) {
                                    res = `00:${res}`;
                                }
                                break;

                            default:
                                break;
                        }

                        return res;
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            legend: {
                enabled: true,
                floating: false,
                align: 'center',
                verticalAlign: 'bottom',
                x: 0,
                y: 25,
                itemMarginTop: 5,
                itemMarginBottom: 5,
                alignColumns: false,
                symbolWidth: 8,
                symbolHeight: 8,
                symbolPadding: 10
            },
            tooltip: {
                valueDecimals: 2,
                padding: 0,
                useHTML: true,
                formatter: function () {
                    const color = this.point.colorIndex;
                    const title = this.point.series.name;
                    const value = this.point.y?.toFixed(2).replace(/\.00$/, '');
                    return `<span class="dot highcharts-color-${color}"></span><span class="title">${title}</span><span class="value">${value}</span>`;
                }
            },
            series: [
                {
                    id: SLIDE_SERIES_ID,
                    name: 'Slide',
                    type: 'column',
                    colorIndex: 1,
                    data: []
                },
                {
                    id: ROTARY_SERIES_ID,
                    name: 'Rotary',
                    type: 'column',
                    colorIndex: 2,
                    data: []
                }
            ]
        };
    }

    private updateData(): void {
        if (!this._data || !this.chart) {
            return;
        }

        // Prepare the data for each series

        // Categories represented as an array of timestamps
        const categories: string[] = [];

        const slideData: number[] = [];
        const rotaryData: number[] = [];
        let maxTimeSpan = 0;

        for (const item of this._data.metrics) {
            categories.push(item.startTime.getTime().toString());

            const slide = this.getConvertedValue(item.slideDrilled);
            const rotary = this.getConvertedValue(item.rotaryDrilled);

            slideData.push(slide);
            rotaryData.push(rotary);

            const total = slide + rotary;
            if (total > maxTimeSpan) {
                maxTimeSpan = total;
            }
        }

        // Set categories

        this.chart.xAxis[0].setCategories(categories, false);

        // Set Y title

        this.chart.yAxis[0].setTitle({
            text: this.getTitleText()
        }, false);

        // Set Y axis options

        const min = 0;
        const max = maxTimeSpan * 1.15;

        const tickPositions: number[] = [
            min
        ];

        for (let i = 1; i < (Y_TICKS_COUNT - 1); i++) {
            tickPositions.push((max - min) * i / (Y_TICKS_COUNT - 1) + min);
        }

        tickPositions.push(max);

        // This will also update the chart
        this.chart.update({
            yAxis: [{
                id: 'main',
                tickPositions
            }]
        });

        // Set data

        this.chart.setSeriesData(SLIDE_SERIES_ID, slideData);
        this.chart.setSeriesData(ROTARY_SERIES_ID, rotaryData);
    }

    private getConvertedValue(source: number): number {
        const units = this._selectedMode === SlideVsRotaryWidgetSettingsMode.Time
            ? this._timeUnit
            : this._lengthUnit;

        return units
            ? this.unitsConverter.convertToUnit(source, units)
            : source;
    }

    private getTitleText(): string {
        let res = '';

        switch (this._selectedMode) {

            case SlideVsRotaryWidgetSettingsMode.Length:
                res = 'Length';

                if (this._lengthUnit) {
                    res += ` <span class="faded">${this._lengthUnit.name}</span>`;
                }
                break;

            case SlideVsRotaryWidgetSettingsMode.Time:
                res = 'Time';

                if (this._timeUnit) {
                    res += ` <span class="faded">${this._timeUnit.name}</span>`;
                }
                break;

            default:
                break;
        }

        return res;
    }

    private getLabelsStep(axis: Highcharts.Axis): number {
        const extremes = axis.getExtremes();
        return Math.round(extremes.max / X_LABELS_MAX_COUNT);
    }

}
