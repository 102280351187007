import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ForecastEffects } from './forecast.effects';
import { forecastReducer } from './forecast.reducer';
import { FORECAST_FEATURE } from './forecast.state';

export const ForecastProviders = [
    provideEffects([ForecastEffects]),
    provideState(FORECAST_FEATURE, forecastReducer)
];
