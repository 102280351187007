
import { AppSelectors } from '@cyberloop/web/app/data';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PLANNING_FEATURE, PlanningState, adapter } from './planning.state';

const feature = createFeatureSelector<PlanningState>(PLANNING_FEATURE);
const { selectAll } = adapter.getSelectors();
const planningRouteId = AppSelectors.routeNestedParam('planningId');

const list = createSelector(feature, selectAll);
const listLoading = createSelector(feature, state => state.listLoadingState === 'LOADING');
const listLoaded = createSelector(feature, state => state.listLoadingState === 'LOADED');

const planningAddLoading = createSelector(feature, state => state.planningAddLoadingState === 'LOADING');
const planningUpdateLoading = createSelector(feature, state => state.planningUpdateLoadingState === 'LOADING');
const actionLoading = createSelector(
    planningAddLoading,
    planningUpdateLoading,
    (isAddLoading, isUpdateLoading) => isAddLoading || isUpdateLoading
);

const planningId = createSelector(feature, planningRouteId, (state, routeId) => routeId ?? state.planningId);
const planning = createSelector(list, planningId, (planningList, id) => planningList.find(x => x.id === id));
const planningName = createSelector(planning, plan => plan?.name);
const planningLoading = createSelector(feature, state => state.planningLoadingState === 'LOADING');
const planningLoaded = createSelector(feature, state => state.planningLoadingState === 'LOADED');

const selectVersionLoading = createSelector(feature, state => state.selectVersionLoading);

/**
 * Selectors for planning
 * */
export const PlanningSelectors = {
    planningRouteId,

    list,
    listLoading,
    listLoaded,

    planningAddLoading,
    planningUpdateLoading,
    actionLoading,

    planningId,
    planning,
    planningName,
    planningLoading,
    planningLoaded,

    selectVersionLoading
};
