<cyberloop-kpi-widget-settings class="settings" (close)="onCancel()">

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <cyberloop-time-range-selector
            formControlName="timeRange"
            class="range-selector"
        ></cyberloop-time-range-selector>

        <h4>Tags setup</h4>

        <div class="tags-table">

            <div class="tag-row" *ngFor="let i of tagIndexes">

                <!-- Tag name -->
                <fieldset>
                    <legend>Select {{ getNumberLabel(i) }} tag</legend>
                    <cl-selector [formControlName]="'tag' + (i+1)" [options]="tags" placeholder="Select a tag..." (valueChange)="onTagChange(i, $event)"></cl-selector>
                </fieldset>

                <!-- Tag units -->
                <fieldset>
                    <legend>Select units</legend>
                    <cl-selector [formControlName]="'tag' + (i+1) + 'Units'" [options]="tagUnits$[i] | async" placeholder="Units..."></cl-selector>
                </fieldset>

                <!-- Tag min -->
                <fieldset>
                    <legend>Min</legend>
                    <cl-numeric-input [formControlName]="'tag' + (i+1) + 'Min'" [unit]="selectedUnits$[i] | async" placeholder="0"></cl-numeric-input>
                </fieldset>

                <!-- Tag max -->
                <fieldset>
                    <legend>Max</legend>
                    <cl-numeric-input [formControlName]="'tag' + (i+1) + 'Max'" [unit]="selectedUnits$[i] | async" placeholder="0"></cl-numeric-input>
                </fieldset>
            </div>

        </div>

        <section class="actions">
            <button type="button" class="cl-flat-button" (click)="onCancel()">Cancel</button>
            <button type="submit" class="cl-primary-button">Apply</button>
        </section>

    </form>

</cyberloop-kpi-widget-settings>