<cyberloop-side-menu class="menu" [recentItems]="recentItems" [navigationItems]="navigationItems"
    (navigationRequest)="navigationRequest.emit($event)" #sidemenu></cyberloop-side-menu>

<div class="block" (click)="toggleSidebar()" data--tutorial-id="show-side-panel" data--tutorial-order="33"
    data--tutorial-md-src="assets/docs/test-doc.md" data--tutorial-clickable>
    <cyberloop-animated-menu-icon [activated]="sidemenu.showPanel"></cyberloop-animated-menu-icon>

    <div class="divider"></div>

    <svg class="logo" viewBox="0 0 130 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.42478 14.9998C2.09244 14.9998 0 12.9217 0 7.5966C0 2.27146 2.09244 0.193359 7.42478 0.193359C9.65222 0.193359 10.7997 0.258299 12.3521 0.583003L12.8246 0.647944L12.4196 2.85593L11.9471 2.79099C10.4622 2.66111 9.51722 2.59617 7.42478 2.59617C3.91489 2.59617 2.90241 3.70016 2.90241 7.46672C2.90241 11.2982 3.91489 12.3373 7.42478 12.3373C9.58472 12.3373 10.9347 12.2723 12.2846 12.1425L12.7571 12.0775L13.0946 14.2855L12.6221 14.3504C11.1372 14.87 9.65222 14.9998 7.42478 14.9998Z"
            fill="currentColor" />
        <path
            d="M20.046 14.8734V9.4833L13.7012 0.391602H16.8736L20.9235 6.23627C21.126 6.49603 21.2609 6.82073 21.4634 7.0805C21.5984 6.82073 21.8009 6.56097 21.9359 6.30121V6.23627L25.9183 0.391602H29.1582L22.8134 9.4833V14.8084H20.046V14.8734Z"
            fill="currentColor" />
        <path
            d="M37.192 14.8714C34.2896 14.8714 32.7372 14.8714 30.7122 14.7415L30.2397 14.6766V0.194823L30.7122 0.129882C32.5347 4.99256e-07 33.9521 0 36.4496 0C40.6344 0 42.9294 0.649408 42.9294 4.02633C42.9294 5.51996 42.3894 6.55901 41.3769 7.07854C42.7944 7.66301 43.4694 8.89688 43.4694 10.7802C43.4019 14.1571 41.1069 14.8714 37.192 14.8714ZM33.0072 12.3387C34.0196 12.3387 35.4371 12.3387 36.9221 12.3387C40.2295 12.3387 40.5669 11.8192 40.5669 10.4555C40.5669 8.83194 40.027 8.31242 36.7196 8.24747H33.0072V12.3387ZM36.7871 6.03949C39.8245 6.03949 40.162 5.45502 40.162 4.28609C40.162 3.05221 39.8245 2.53269 36.3821 2.53269H33.0747V6.10443L36.7871 6.03949Z"
            fill="currentColor" />
        <path
            d="M76.8778 14.87C74.4478 14.87 72.9629 13.701 72.9629 11.8177V0.193359H75.7978V11.1034V11.6229C75.7978 11.8827 75.7978 12.4672 77.2153 12.4672H84.5051L84.64 14.805L84.1001 14.87C81.7376 14.87 79.3077 14.87 76.8778 14.87Z"
            fill="currentColor" />
        <path
            d="M92.403 14.868C87.1381 14.868 85.1807 12.8548 85.1807 7.46477C85.1807 2.07469 87.1381 0.0615234 92.403 0.0615234C97.7353 0.0615234 99.6252 2.00975 99.6252 7.46477C99.6252 12.9198 97.7353 14.868 92.403 14.868ZM92.268 2.52927C88.8931 2.52927 87.9481 3.63326 87.9481 7.39983C87.9481 11.1664 88.9606 12.2704 92.3355 12.2704C95.7779 12.2704 96.7228 11.1664 96.7228 7.39983C96.7228 3.63326 95.7104 2.52927 92.3355 2.52927H92.268Z"
            fill="currentColor" />
        <path
            d="M108.065 14.6083C102.867 14.6083 100.978 12.66 100.978 7.33489C100.978 2.00975 102.935 0.0615234 108.065 0.0615234C113.33 0.0615234 115.152 1.94481 115.152 7.33489C115.152 12.725 113.262 14.6083 108.065 14.6083ZM107.93 2.52927C104.622 2.52927 103.677 3.56833 103.677 7.33489C103.677 11.1015 104.622 12.1405 107.997 12.1405C111.372 12.1405 112.317 11.1015 112.317 7.33489C112.317 3.56833 111.372 2.52927 107.997 2.52927H107.93Z"
            fill="currentColor" />
        <path
            d="M62.3011 9.54287C62.0986 9.54287 61.4911 9.67275 61.3561 10.8417C61.2886 11.4911 61.3561 13.5692 61.3561 14.2835V14.8031H58.1162V14.2835C58.1162 14.1537 58.1162 14.0238 58.1162 13.8939C58.1162 12.2054 58.1162 10.3222 58.5212 9.34805C58.6562 9.08829 59.0612 8.17912 59.5337 7.85441C60.2086 7.26995 60.8836 6.94524 61.8286 6.94524H67.4309C68.6459 6.75042 69.0509 6.23089 69.0509 4.73726C69.0509 3.24362 68.5109 2.52927 65.7435 2.52927C64.191 2.52927 62.3686 2.52927 61.4236 2.52927H58.1162V0.0615234H58.5887C60.8161 0.0615234 62.5036 0.0615234 65.2035 0.0615234C68.3759 0.0615234 71.6158 0.64599 71.6158 4.73726C71.6158 5.77631 71.5483 6.8803 71.0083 7.72453C70.5358 8.43888 69.8609 9.02335 68.9159 9.28311C68.9834 9.41299 68.9834 9.54287 69.0509 9.67275L71.0083 14.868H68.1059L66.0135 10.0624C65.946 9.99746 65.811 9.73769 65.6085 9.54287C65.541 9.54287 65.541 9.54287 65.4735 9.54287C65.4735 9.54287 63.1786 9.47793 62.3686 9.54287C62.5036 9.54287 62.3686 9.54287 62.3011 9.54287Z"
            fill="currentColor" />
        <path
            d="M116.365 14.8084V14.159C116.365 12.3407 116.365 10.5224 116.77 9.61318C116.838 9.41836 117.31 8.24943 117.783 7.92472C118.39 7.47014 119.065 7.01555 120.078 7.01555H125.68C126.895 6.82073 127.232 6.3012 127.232 4.80757C127.232 3.31393 126.692 2.4697 123.857 2.4697C122.238 2.4697 120.145 2.4697 119.335 2.4697H115.825V0.326658H116.298C118.323 0.196776 120.415 0.131836 123.25 0.131836C126.152 0.131836 130 0.651361 130 4.80757C130 6.23626 129.865 7.14543 129.46 7.79484C128.852 8.76895 127.907 9.41836 126.76 9.54824C126.422 9.61318 125.882 9.61318 125.41 9.67812C125.14 9.67812 124.87 9.67812 124.667 9.74306H123.992C123.317 9.74306 121.428 9.74306 120.955 9.67812C120.82 9.74306 120.753 9.74306 120.618 9.74306C119.875 9.808 119.673 10.7821 119.673 11.1068C119.605 11.6913 119.605 13.4447 119.673 14.2889V14.8084H116.365C116.433 14.8084 116.365 14.8084 116.365 14.8084Z"
            fill="currentColor" />
        <path
            d="M51.3005 14.868C47.3181 14.868 44.8882 14.2186 44.8882 10.8417C44.9557 9.28311 44.9557 7.91935 46.4406 7.20501C45.4282 6.68548 44.9557 5.64643 44.9557 4.08785C44.9557 0.710931 47.1831 0.0615234 51.3005 0.0615234C52.718 0.0615234 55.1479 0.0615234 55.9579 0.0615234H56.4303V2.52927H51.368C48.0606 2.52927 47.7231 2.98386 47.7231 4.28267C47.7231 5.51655 48.2631 6.10101 51.368 6.10101H56.4303V8.37394H51.368C48.0606 8.37394 47.7231 8.9584 47.7231 10.517C47.7231 11.8158 48.0606 12.3353 51.3005 12.3353C52.178 12.3353 53.1229 12.3353 54.0004 12.3353C54.7429 12.3353 55.4179 12.3353 55.8904 12.3353H56.4303V14.7381H55.9579C54.8779 14.8031 53.1229 14.868 51.3005 14.868Z"
            fill="currentColor" />
    </svg>
</div>