<h1 class="title">
    Successful upload
</h1>

<div [formGroup]="form" class="content">
    <div class="file">
        <div *ngVar="data?.meta; let meta" class="file-info">
            <span class="file-name">{{ meta.name }}</span>
            <time class="file-date">{{ meta.updatedAt | datetime }}</time>
        </div>
        <icon name="check_circle"></icon>
    </div>

    <span class="subtitle">Link to</span>

    <fieldset class="fieldset">
        <label class="label">Activity</label>
        <cl-selector [formControlName]="Controls.Activity" [options]="data?.activityList ?? []"></cl-selector>
    </fieldset>

    <fieldset class="fieldset tags-fieldset">
        <label class="label">Tags</label>
        <cl-selector [formControlName]="Controls.Tags" [options]="(data?.tagList$ | async) ?? []"
            [multiple]="true"></cl-selector>
    </fieldset>
</div>

<div class="actions">
    <button (click)="onSave()" [disabled]="form.invalid" class="cl-primary-button">
        Apply
    </button>
    <button (click)="onCancel()" class="action cl-flat-button">
        Cancel
    </button>
</div>

<button (click)="close()" class="cl-icon-button close">
    <icon name="close"></icon>
</button>