import { Injectable } from '@angular/core';

import { ClError, ID, RigActivity, uuid } from '@cyberloop/core';
import { PlanningProviderService, PlanningVersionProviderService } from '@cyberloop/web/planning/shared/data';
import { ForecastProviderService } from '@cyberloop/web/wells/data';
import { CreateForecastEquipmentAndPersonnelEvent, CreateForecastEvent, Forecast, ForecastEquipmentAndPersonnelEvent, ForecastEvent, UpdateForecastEquipmentAndPersonnelEvent, UpdateForecastEvent } from '@cyberloop/web/wells/model';
import { isNil } from 'lodash';
import { EMPTY, Observable, finalize, firstValueFrom, map, shareReplay, switchMap } from 'rxjs';

import { FirestoreDataConverter, limit, orderBy, where } from 'firebase/firestore';
import * as moment from 'moment';

import { IClTransaction } from '../models/transaction';
import { ClApplicationManager } from './internals/client-app/cl-app-manager';

import type { Moment } from 'moment';
import type { DBCreateForecast, DBCreateForecastEquipmentAndPersonnelEvent, DBCreateForecastEvent, DBForecast, DBForecastEquipmentAndPersonnelEvent, DBForecastEvent, DBForecastEventColor, DBUpdateForecast, DBUpdateForecastEquipmentAndPersonnelEvent, DBUpdateForecastEvent } from '../models/forecast';

const FORECAST_COLLECTION_NAME = 'forecast';
const FORECAST_EVENTS_COLLECTION_NAME = 'events';
const FORECAST_EQUIPMENT_AND_PERSONNEL_COLLECTION_NAME = 'equipment-and-personnel';

export const DEFAULT_EVENT_COLOR: DBForecastEventColor = 'color-1';
export const DEFAULT_EVENT_NPT_COLOR: DBForecastEventColor = 'color-4';

@Injectable({
    providedIn: 'root'
})
export class FirebaseForecastProviderLinkService extends ForecastProviderService {
    private readonly _forecastConverter: FirestoreDataConverter<Forecast> = {
        toFirestore: (data: Forecast) => data,
        fromFirestore: (snapshot, options): Forecast => {
            const data = snapshot.data(options) as DBForecast;

            return {
                ...data,
                createdAt: moment(data.createdAt),
                updatedAt: moment(data.updatedAt)
            };
        }
    };

    private readonly _eventConverter: FirestoreDataConverter<ForecastEvent> = {
        toFirestore: (data: ForecastEvent) => data,
        fromFirestore: (snapshot, options): ForecastEvent => {
            const data = snapshot.data(options) as DBForecastEvent;

            return {
                ...data,
                startDate: moment(data.startDate),
                endDate: moment(data.endDate),
                createdAt: moment(data.createdAt),
                updatedAt: moment(data.updatedAt)
            };
        }
    };

    private readonly _equipmentAndPersonnelConverter: FirestoreDataConverter<ForecastEquipmentAndPersonnelEvent> = {
        toFirestore: (data: ForecastEquipmentAndPersonnelEvent) => data,
        fromFirestore: (snapshot, options): ForecastEquipmentAndPersonnelEvent => {
            const data = snapshot.data(options) as DBForecastEquipmentAndPersonnelEvent;

            return {
                ...data,
                date: moment(data.date),
                createdAt: moment(data.createdAt),
                updatedAt: moment(data.updatedAt)
            };
        }
    };

    private _forecastByWellIdObservables: Record<ID, Observable<Forecast | null> | undefined> = {};
    private _eventsObservables: Record<ID, Observable<ForecastEvent[]> | undefined> = {};
    private _equipmentAndPersonnelObservables: Record<ID, Observable<ForecastEquipmentAndPersonnelEvent[]> | undefined> = {};

    constructor(
        private readonly appMgr: ClApplicationManager,
        private readonly planningProviderService: PlanningProviderService,
        private readonly planningVersionProviderService: PlanningVersionProviderService
    ) {
        super();
    }

    //#region Forecast

    watchByWellId(wellId: ID) {
        return this._forecastByWellIdObservables[wellId] ??= this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().watchCollection<Forecast>(
                    FORECAST_COLLECTION_NAME,
                    this._forecastConverter,
                    [
                        where('wellId', '==', wellId),
                        limit(1)
                    ]
                ) ?? EMPTY),
                map(list => {
                    if (list?.length) {
                        return list.at(0) as Forecast;
                    }

                    return null;
                }),
                finalize(() => delete this._forecastByWellIdObservables[wellId]),
                shareReplay(1)
            );
    }


    get(forecastId: ID) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().get<Forecast>(
                    this.getPath(this.getForecastPath(forecastId)),
                    this._forecastConverter
                ) ?? EMPTY),
                shareReplay(1)
            );
    }

    importFromWellPlanByWellID(wellId: ID) {
        const forecastId = uuid();

        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    const planning = await firstValueFrom(this.planningProviderService.watchByWellId(wellId));

                    if (isNil(planning)) {
                        throw new ClError('Planning not found');
                    }

                    const plannedStartDate = planning.plannedStartDate;

                    if (isNil(plannedStartDate)) {
                        throw new ClError('Planned start date not set');
                    }

                    const version = await firstValueFrom(this.planningVersionProviderService.get(planning.id, planning.activeVersionId));

                    if (isNil(version)) {
                        throw new ClError('Version not found');
                    }

                    const forecastListToDelete = await x.getFirestore().getCollection(
                        FORECAST_COLLECTION_NAME,
                        this._forecastConverter,
                        [
                            where('wellId', '==', wellId),
                            limit(1)
                        ]
                    );

                    if (forecastListToDelete.length) {
                        for (let index = 0; index < forecastListToDelete.length; index++) {
                            const forecastToDelete = forecastListToDelete[index];

                            transaction.delete(this.getForecastPath(forecastToDelete.id));

                            const eventListToDelete = await x.getFirestore().getCollection(
                                this.getEventsPath(forecastToDelete.id),
                                this._eventConverter
                            );

                            eventListToDelete.forEach(eventToDelete =>
                                transaction.delete(this.getPath(this.getEventsPath(forecastToDelete.id), eventToDelete.id))
                            );
                        }
                    }

                    await transaction.set<DBCreateForecast>(
                        this.getForecastPath(forecastId),
                        {
                            id: forecastId,
                            wellId,
                            createdAt: moment.now(),
                            updatedAt: moment.now()
                        }
                    );

                    const date = plannedStartDate.clone();

                    for (let index = 0; index < version.stages.length; index++) {
                        const stage = version.stages[index];
                        const addHours = stage.estTimeHours ?? 0;
                        const startDate = date.valueOf();
                        const endDate = date.clone().add(addHours, 'hours').valueOf();

                        if (endDate - startDate > 0) {
                            const eventId = uuid();

                            transaction.set<DBCreateForecastEvent>(
                                this.getPath(this.getEventsPath(forecastId), eventId),
                                {
                                    id: eventId,
                                    description: `${RigActivity.getName(stage.activity)} / ${stage.wellPhase} / ${stage.comment}`,
                                    startDate,
                                    endDate,
                                    createdAt: moment.now(),
                                    updatedAt: moment.now(),
                                    isNpt: false,
                                    color: DEFAULT_EVENT_COLOR
                                }
                            );
                        }

                        date.add(addHours, 'hours');
                    }
                }) ?? EMPTY),
                switchMap(() => this.get(forecastId) as Observable<Forecast>),
                shareReplay(1)
            );
    }

    //#endregion Forecast

    //#region Event

    watchAllEvents(forecastId: ID) {
        return this._eventsObservables[forecastId] ??= this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().watchCollection<ForecastEvent>(
                    this.getEventsPath(forecastId),
                    this._eventConverter
                ) ?? EMPTY),
                finalize(() => delete this._eventsObservables[forecastId]),
                shareReplay(1)
            );
    }

    getEvent(forecastId: ID, eventId: ID) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().get<ForecastEvent>(
                    this.getPath(this.getEventsPath(forecastId), eventId),
                    this._eventConverter
                ) ?? EMPTY),
                shareReplay(1)
            );
    }

    createEvent(forecastId: ID, createEvent: CreateForecastEvent) {
        const eventId = uuid();

        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    const eventList = await x.getFirestore().getCollection<ForecastEvent>(
                        this.getEventsPath(forecastId),
                        this._eventConverter,
                        [orderBy('startDate', 'asc')]
                    );

                    const lastEvent = eventList.at(-1);
                    let startDate = createEvent.startDate;

                    if (!createEvent.isNpt && lastEvent?.endDate && lastEvent.endDate.valueOf() < startDate.valueOf()) {
                        startDate = lastEvent.endDate;
                    }

                    await transaction.set<DBCreateForecastEvent>(
                        this.getPath(this.getEventsPath(forecastId), eventId),
                        {
                            ...createEvent,
                            id: eventId,
                            startDate: startDate.valueOf(),
                            endDate: createEvent.endDate.valueOf(),
                            description: '',
                            isNpt: createEvent.isNpt,
                            color: createEvent.isNpt ? DEFAULT_EVENT_NPT_COLOR : DEFAULT_EVENT_COLOR,
                            createdAt: moment.now(),
                            updatedAt: moment.now()
                        }
                    );

                    if (!createEvent.isNpt) {
                        const listToUpdate = await this.moveEvents(eventList, startDate, startDate, startDate, createEvent.endDate);
                        await this.updateEventsDate(transaction, forecastId, listToUpdate);
                    }

                    await transaction.update<DBUpdateForecast>(
                        this.getForecastPath(forecastId),
                        {
                            updatedAt: moment.now()
                        }
                    );
                }) ?? EMPTY),
                switchMap(() => this.getEvent(forecastId, eventId) as Observable<ForecastEvent>),
                shareReplay(1)
            );
    }

    updateEvent(forecastId: ID, eventId: ID, updateEvent: UpdateForecastEvent) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    const event = await transaction.get<ForecastEvent>(
                        this.getPath(this.getEventsPath(forecastId), eventId),
                        this._eventConverter
                    );

                    if (isNil(event)) {
                        throw new ClError('Event not found');
                    }

                    await transaction.update<DBUpdateForecastEvent>(
                        this.getPath(this.getEventsPath(forecastId), eventId),
                        {
                            ...updateEvent,
                            startDate: updateEvent.startDate?.valueOf(),
                            endDate: updateEvent.endDate?.valueOf(),
                            updatedAt: moment.now()
                        }
                    );

                    if (!updateEvent.isNpt && !isNil(updateEvent.startDate) && !isNil(updateEvent.endDate)) {
                        const eventList = await x.getFirestore().getCollection<ForecastEvent>(
                            this.getEventsPath(forecastId),
                            this._eventConverter
                        );
                        const listToUpdate = await this.moveEvents(eventList, event.startDate, event.endDate, event.startDate, updateEvent.endDate);
                        await this.updateEventsDate(transaction, forecastId, listToUpdate);
                    }

                    await transaction.update<DBUpdateForecast>(
                        this.getForecastPath(forecastId),
                        {
                            updatedAt: moment.now()
                        }
                    );
                }) ?? EMPTY),
                switchMap(() => this.getEvent(forecastId, eventId) as Observable<ForecastEvent>),
                shareReplay(1)
            );
    }

    expandToNextEvent(forecastId: ID, eventId: ID) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    const event = await transaction.get<ForecastEvent>(
                        this.getPath(this.getEventsPath(forecastId), eventId),
                        this._eventConverter
                    );

                    if (isNil(event)) {
                        throw new ClError('Event not found');
                    }

                    const eventList = await x.getFirestore().getCollection<ForecastEvent>(
                        this.getEventsPath(forecastId),
                        this._eventConverter
                    );
                    const belowEventList = eventList.filter(e => !e.isNpt && e.startDate >= event.endDate);

                    if (belowEventList.length < 1) {
                        return;
                    }

                    const belowDurationList = belowEventList.map(e => e.startDate.diff(event.endDate).valueOf());
                    const minIndex = belowDurationList.indexOf(Math.min(...belowDurationList));
                    const nextEvent = belowEventList[minIndex];

                    await transaction.update<DBUpdateForecastEvent>(
                        this.getPath(this.getEventsPath(forecastId), eventId),
                        {
                            endDate: nextEvent.startDate.valueOf(),
                            updatedAt: moment.now()
                        }
                    );

                    await transaction.update<DBUpdateForecast>(
                        this.getForecastPath(forecastId),
                        {
                            updatedAt: moment.now()
                        }
                    );
                }) ?? EMPTY),
                switchMap(() => this.getEvent(forecastId, eventId) as Observable<ForecastEvent>),
                shareReplay(1)
            );
    }

    deleteEvent(forecastId: ID, eventId: ID): Observable<ForecastEvent> {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    const event = await transaction.get<ForecastEvent>(
                        this.getPath(this.getEventsPath(forecastId), eventId),
                        this._eventConverter
                    );

                    if (isNil(event)) {
                        throw new ClError('Event not found');
                    }

                    await transaction.delete(
                        this.getPath(this.getEventsPath(forecastId), eventId)
                    );

                    const eventList = await x.getFirestore().getCollection<ForecastEvent>(
                        this.getEventsPath(forecastId),
                        this._eventConverter
                    );
                    const listToUpdate = await this.moveEvents(eventList, event.startDate, event.endDate, event.startDate, event.startDate);
                    await this.updateEventsDate(transaction, forecastId, listToUpdate);

                    await transaction.update<DBUpdateForecast>(
                        this.getForecastPath(forecastId),
                        {
                            updatedAt: moment.now()
                        }
                    );

                    return event;
                }) ?? EMPTY),
                shareReplay(1)
            );
    }

    private async updateEventsDate(transaction: IClTransaction, forecastId: ID, listToUpdate: Record<ID, Pick<ForecastEvent, 'startDate' | 'endDate'>>) {
        await Promise.all(Object.entries(listToUpdate).map(([id, updateEvent]) => {
            return transaction.update<DBUpdateForecastEvent>(
                this.getPath(this.getEventsPath(forecastId), id),
                {
                    startDate: updateEvent.startDate.valueOf(),
                    endDate: updateEvent.endDate.valueOf(),
                    updatedAt: moment.now()
                }

            );
        }));
    }

    private moveEvents(eventList: ForecastEvent[], oldStart: Moment, oldEnd: Moment, newStart: Moment, newEnd: Moment) {
        const updateEventList: Record<ID, Pick<ForecastEvent, 'startDate' | 'endDate'>> = {};

        const oldDurationMinutes = oldEnd.diff(oldStart, 'minutes');
        const newDurationMinutes = newEnd.diff(newStart, 'minutes');
        const diffMinutes = newDurationMinutes - oldDurationMinutes;

        eventList.forEach(event => {
            if (event.isNpt) {
                return;
            }

            if (event.startDate >= oldEnd && oldStart === newStart) {
                updateEventList[event.id] = {
                    startDate: event.startDate.add(diffMinutes, 'minutes'),
                    endDate: event.endDate.add(diffMinutes, 'minutes')
                };
            }
            else if (event.endDate <= oldStart && oldEnd === newEnd) {
                updateEventList[event.id] = {
                    startDate: event.startDate.add(diffMinutes * -1, 'minutes'),
                    endDate: event.endDate.add(diffMinutes * -1, 'minutes')
                };
            }
        });

        return updateEventList;
    }

    //#endregion Event

    //#region Equipment and Personnel
    watchAllEquipmentAndPersonnelEvents(forecastId: ID) {
        return this._equipmentAndPersonnelObservables[forecastId] ??= this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().watchCollection<ForecastEquipmentAndPersonnelEvent>(
                    this.getEquipmentAndPersonnelPath(forecastId),
                    this._equipmentAndPersonnelConverter
                ) ?? EMPTY),
                finalize(() => delete this._equipmentAndPersonnelObservables[forecastId]),
                shareReplay(1)
            );
    }

    getEquipmentAndPersonnelEvent(forecastId: ID, equipmentAndPersonnelId: ID) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().get<ForecastEquipmentAndPersonnelEvent>(
                    this.getPath(this.getEquipmentAndPersonnelPath(forecastId), equipmentAndPersonnelId),
                    this._equipmentAndPersonnelConverter
                ) ?? EMPTY),
                shareReplay(1)
            );
    }

    createEquipmentAndPersonnelEvent(forecastId: ID, createEquipmentAndPersonnel: CreateForecastEquipmentAndPersonnelEvent) {
        const equipmentAndPersonnelId = uuid();

        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().set<DBCreateForecastEquipmentAndPersonnelEvent>(
                    this.getPath(this.getEquipmentAndPersonnelPath(forecastId), equipmentAndPersonnelId),
                    {
                        ...createEquipmentAndPersonnel,
                        id: equipmentAndPersonnelId,
                        date: createEquipmentAndPersonnel.date.valueOf(),
                        createdAt: moment.now(),
                        updatedAt: moment.now()
                    }
                ) ?? EMPTY),
                switchMap(() => this.getEquipmentAndPersonnelEvent(forecastId, equipmentAndPersonnelId) as Observable<ForecastEquipmentAndPersonnelEvent>),
                shareReplay(1)
            );
    }

    updateEquipmentAndPersonnelEvent(forecastId: ID, equipmentAndPersonnelId: ID, updateEquipmentAndPersonnel: UpdateForecastEquipmentAndPersonnelEvent) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().update<DBUpdateForecastEquipmentAndPersonnelEvent>(
                    this.getPath(this.getEquipmentAndPersonnelPath(forecastId), equipmentAndPersonnelId),
                    {
                        ...updateEquipmentAndPersonnel,
                        updatedAt: moment.now()
                    }
                ) ?? EMPTY),
                switchMap(() => this.getEquipmentAndPersonnelEvent(forecastId, equipmentAndPersonnelId) as Observable<ForecastEquipmentAndPersonnelEvent>),
                shareReplay(1)
            );
    }
    //#endregion Equipment and Personnel

    private getForecastPath(id: ID) {
        return this.getPath(FORECAST_COLLECTION_NAME, id);
    }

    private getEventsPath(id: ID) {
        return this.getPath(this.getForecastPath(id), FORECAST_EVENTS_COLLECTION_NAME);
    }

    private getEquipmentAndPersonnelPath(id: ID) {
        return this.getPath(this.getForecastPath(id), FORECAST_EQUIPMENT_AND_PERSONNEL_COLLECTION_NAME);
    }

    private getPath(...args: string[]) {
        return args.join('/');
    }
}
