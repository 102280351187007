import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';

import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { isEqual } from 'lodash';
import { combineLatest, first, map, of, switchMap, tap } from 'rxjs';

import { ThemeName } from '../models/themes';
import { AccessService, AssetsInfoProviderService } from '../services';
import { RigInfoProviderService } from '../services/abstractions/rig-info-provider.service';
import { WellInfoProviderService } from '../services/abstractions/well-info-provider.service';
import { haltApplication } from '../utils';
import { CoreActions } from './core.actions';
import { CoreSelectors } from './core.selectors';
import { CORE_FEATURE } from './core.state';

/** @internal */
const initAction = createAction(`[${CORE_FEATURE}] Initialize core`);

/** @internal */
const themeStorage = 'theme';

/** @internal */
@Injectable()
export class CoreEffects implements OnInitEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly accessService: AccessService,
        @Inject(DOCUMENT) private readonly doc: Document,
        @Optional() private rip: RigInfoProviderService,
        @Optional() private wip: WellInfoProviderService,
        @Optional() private aip: AssetsInfoProviderService,
    ) {
        if (!rip) {
            haltApplication("RigInfoProviderService was not defined. Please register this service first");
        }

        if (!wip) {
            haltApplication("WellInfoProviderService was not defined. Please register this service first");
        }

        if (!aip) {
            haltApplication("AssetsInfoProviderService was not defined. Please register this service first");
        }
    }



    readonly onInitAction$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(() => [CoreActions.setTheme({ theme: localStorage.getItem(themeStorage) as ThemeName })])
    ));

    readonly listenForRigMeta = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(() => this.rip.watchRigs()),
        switchMap(rigs => this.accessService.filterRigs(rigs)),
        map(rigs => CoreActions.setRigMeta({ rigs }))
    ));

    readonly listenForWellMeta = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(() => combineLatest([
            this.rip.watchRigs(),
            this.wip.watchWells()
        ]).pipe(
            switchMap(([rigs, wells]) => this.accessService.filterWells(rigs, wells)),
            map(wells => CoreActions.setWellMeta({ wells }))
        )),
    ));

    readonly listenForAssetsWellMeta = createEffect(() => this.store.select(CoreSelectors.currentWell).pipe(
        switchMap((currentWell) => currentWell?.rig ? this.aip.watchAssets(currentWell.rig) : of([])),
        switchMap(newAssets => this.store.select(CoreSelectors.currentAssets).pipe(
            first(),
            switchMap(currentAssets => {
                if (isEqual(currentAssets, newAssets)) {
                    return [];
                }

                return [CoreActions.setCurrentAssets({ assets: newAssets })];
            })
        ))
    ));

    readonly onSetTheme = createEffect(() => this.actions$.pipe(
        ofType(CoreActions.setTheme),
        map(x => x.theme ?? ThemeName.Dark),
        tap(x => {
            this.doc.body.setAttribute('theme', x);
            localStorage.setItem(themeStorage, x);
        })
    ), { dispatch: false });

    ngrxOnInitEffects(): Action {
        return initAction();
    }
}
