<div class="head">
    <span class="col skeleton">version</span>
    <span class="col skeleton"></span>
    <span class="col skeleton">published</span>
    <span class="col skeleton">changed by</span>
    <span class="col comment skeleton">comment</span>
</div>
<div class="content">
    <ng-container *ngFor="let i of skeletonCount">
        <span class="col version skeleton">
            lorem
        </span>
        <span class="col active">
        </span>
        <time class="col skeleton">lorem</time>
        <span class="col skeleton"><span class="text-overflow">lorem</span></span>
        <span class="col comment skeleton">lorem</span>
        <div class="col actions skeleton-frame">
        </div>
    </ng-container>
</div>
