<cyberloop-kpi-widget-settings class="settings" (close)="onCancel()">

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <!-- Units -->
        <fieldset>
            <legend>Select units</legend>
            <cl-button-toggle
                formControlName="units"
                selectionType="radio"
                [list]="(units$ | async) ?? []"
                class="units-selector"
            ></cl-button-toggle>
        </fieldset>

        <section class="actions">
            <button type="button" class="cl-flat-button" (click)="onCancel()">Cancel</button>
            <button type="submit" class="cl-primary-button">Apply</button>
        </section>

    </form>

</cyberloop-kpi-widget-settings>