import { uuid } from '@cyberloop/core';
import { FirebaseApp, FirebaseOptions, deleteApp, initializeApp } from '@firebase/app';

import { ClAuth } from './cl-auth';
import { ClDatabase } from './cl-database';
import { ClFirestore } from './cl-firestore';
import { ClStorage } from './cl-storage';

export class ClApplication {
    private _firestore: ClFirestore | undefined;
    private _auth: ClAuth | undefined;
    private _rtdb: ClDatabase | undefined;
    private _storage: ClStorage | undefined;
    /**
     *
     */
    private constructor(private app: FirebaseApp | undefined) {
    }

    get options() {
        return this.app?.options;
    }

    static initializeApp(options: FirebaseOptions) {
        const app = initializeApp(options, uuid());
        return new ClApplication(app);
    }

    getFirestore(): ClFirestore {
        return this._firestore ??= this.app ? ClFirestore.create(this.app) : null as any;
    }

    getAuth(): ClAuth {
        return this._auth ??= this.app ? ClAuth.create(this.app) : null as any;
    }

    getDatabase(): ClDatabase {
        return this._rtdb ??= this.app ? ClDatabase.create(this.app) : null as any;
    }

    getStorage(): ClStorage {
        return this._storage ??= this.app ? ClStorage.create(this.app) : null as any;
    }

    async dispose() {
        if (this._firestore) {
            this._firestore.dispose();
            this._firestore = undefined;
        }
        if (this._rtdb) {
            this._rtdb.dispose();
            this._rtdb = undefined;
        }
        if (this._storage) {
            this._storage.dispose();
            this._storage = undefined;
        }
        if (this._auth) {
            this._auth.dispose();
            this._auth = undefined;
        }

        const app = this.app;
        if (app) {
            this.app = undefined;
            await deleteApp(app);
        }
    }
}
