import { Injectable } from '@angular/core';

import { GraphQLMutation } from '../../internals/cl-gql';

type TorqueAndDragDeleteModelResult = {
    torqndrag: {
        dropModel: {
            succeeded: boolean
        }
    }
}

type TorqueAndDragDeleteModelInput = {
    modelId: string
}

@Injectable({
    providedIn: 'root'
})
export class TorqueAndDragDeleteModelMutation extends GraphQLMutation<TorqueAndDragDeleteModelResult, TorqueAndDragDeleteModelInput> {
    override readonly document = `
        mutation DeleteTorqueAndDragModel($modelId: ID!) {
            torqndrag {
                dropModel(id: $modelId) {
                    ... on MutationResult {
                        succeeded
                    }
                }
            }
        }
    `;
}