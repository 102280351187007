import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClInputComponent, ClSelectorComponent, FormErrorsComponent, IconComponent, PseudoLoader } from '../../../components';
import { SimpleChangesOf, TenantInfo } from '../../../models';

/** @internal */
enum Controls {
    login = 'login',
    tenant = 'tenantId'
}

/** @internal */
export interface RestorePassword {
    login: string;
    tenantId: string
}

/** @internal */
@Component({
    selector: 'cyberloop-restore-password',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        ReactiveFormsModule,
        ClInputComponent,
        FormErrorsComponent,
        PseudoLoader,
        IconComponent,
        ClSelectorComponent,
    ],
    templateUrl: './restore-password.component.html',
    styleUrls: ['./restore-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestorePasswordUIComponent implements OnChanges {
    constructor(private readonly fb: FormBuilder) { }

    @Input() loading: boolean | null = false;
    @Input() error?: string | null;
    @Input() listOfTenants?: TenantInfo[] | null;
    @Output() readonly tryRestorePassword = new EventEmitter<RestorePassword>();
    @Output() readonly loadTenantList = new EventEmitter<string>();
    @Output() readonly backToLogin = new EventEmitter();

    readonly Controls = Controls;

    readonly form = this.fb.group({
        [Controls.login]: ['', [Validators.required, Validators.email]],
        [Controls.tenant]: [
            null,
            {
                updateOn: 'change',
                validators: [Validators.required]
            }
        ],
    }, {
        updateOn: 'blur'
    });

    get loginControl() {
        return this.form.get(Controls.login) as FormControl;
    }

    get tenantIdControl() {
        return this.form.get(Controls.tenant) as FormControl;
    }

    get tenantListLoaded() {
        return Boolean(this.listOfTenants);
    }

    ngOnChanges(changes: SimpleChangesOf<RestorePasswordUIComponent>): void {
        const loadingChanged = changes.loading;
        if (loadingChanged) {
            loadingChanged.currentValue ? this.form.disable() : this.form.enable();
        }
    }

    onSubmit() {
        if (this.loading) {
            return;
        }

        if (this.loginControl.invalid) {
            this.loginControl.markAllAsTouched();
            return;
        }

        const login = this.loginControl.value;

        if (!this.listOfTenants?.length) {
            this.loadTenantList.emit(login);
            return;
        }

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.tryRestorePassword.emit({
            login: login,
            tenantId: this.tenantIdControl.value
        });
    }

    @HostListener('document:keydown.escape', ['$event'])
    resetForm() {
        if (this.form.enabled) {
            this.form.reset();
        }
    }
}
