import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiWellTagDepthHistoryItem } from '@cyberloop/web/wells/model';
type KpiWellTagDepthHistoryInput = {
    wellId: string,
    sectionId: number,
    tags: string[],
    from: number,
    to: number,
    step: number
}

type KpiWellTagDepthHistory = {
    well: {
        depthHistory: KpiWellTagDepthHistoryItem[]
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiWellTagDepthHistoryQuery extends GraphQLQuery<KpiWellTagDepthHistory, KpiWellTagDepthHistoryInput> {
    override readonly document = `
        query GetWellTagDepthHistory($wellId: ID!, $sectionId: Int!, $tags: [TagName!]!, $from: Float!, $to: Float!, $step: Float!) {
            well (id: $wellId) {
                ...on Well {
                    depthHistory (sectionNo: $sectionId, only: $tags, from: $from, to: $to, step: $step) {
                        tagId
                        tagName
                        intervals {
                            startTime
                            startDepth
                            startOfInterval
                            minDepth
                            maxDepth
                            startValue
                            avgValue
                            minValue
                            maxValue
                        }
                    }
                }
            }
        }
    `;
}