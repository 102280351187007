import { ID } from '@cyberloop/core';
import { PlanningStage, PlanningVersion, SaveAsResult } from '@cyberloop/web/planning/shared/model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { VERSION_FEATURE } from './version.state';

/**
 * Actions for version used internally
 * @internal
 * */
export const PlanningVersionActions = createActionGroup({
    source: VERSION_FEATURE,
    events: {
        'Load list': emptyProps(),
        'Load list success': props<{ list: PlanningVersion[] }>(),
        'Load list failure': props<{ error: Error }>(),

        'Load version': emptyProps(),
        'Load version success': props<{ version: PlanningVersion | null }>(),
        'Load version failure': props<{ error: Error }>(),

        'Create version': props<{ saveAsResult: SaveAsResult }>(),
        'Create version success': props<{ version: PlanningVersion }>(),
        'Create version failure': props<{ error: Error }>(),

        'Update version': props<{ saveAsResult: SaveAsResult }>(),
        'Update version success': props<{ version: PlanningVersion }>(),
        'Update version failure': props<{ error: Error }>(),

        'Replace version': props<{ replaceVersionId: ID, saveAsResult: SaveAsResult }>(),
        'Replace version success': props<{ version: PlanningVersion }>(),
        'Replace version failure': props<{ error: Error }>(),

        'Delete version': props<{ version: PlanningVersion }>(),
        'Delete version success': props<{ version: PlanningVersion }>(),
        'Delete version failure': props<{ error: Error }>(),

        'Set stages': props<{ stages: PlanningStage[] }>(),
        'Update stages': props<{ stages: PlanningStage[] }>(),
        'Reset stages': emptyProps()
    }
});

/**
 * Actions for version
 * */
export const PublicPlanningVersionActions = {
    loadList: PlanningVersionActions.loadList,
    deleteVersion: PlanningVersionActions.deleteVersion,
    resetStages: PlanningVersionActions.resetStages,
    updateStages: PlanningVersionActions.updateStages
};
