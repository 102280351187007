import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { TorqueAndDragModelWithData } from '../../internals/tnd';

export type TndModelDataResult = {
    torqndrag: {
        model: TorqueAndDragModelWithData
    }
}

type TndModelDataInput = {
    modelId: string
}

@Injectable({
    providedIn: 'root'
})
export class TndModelDataQuery extends GraphQLQuery<TndModelDataResult, TndModelDataInput> {
    override readonly document = `
        query GetTorqueAndDragModelData($modelId: ID!) {
            torqndrag {
                model(id: $modelId) {
                    ... on TorqndragModel {
                        id
                        kind
                        name
                        valueUnitId
                        depthUnitId
                        startDepth
                        endDepth
                        pushAlarmLine
                        pullAlarmLine
                        highTorqueAlarmLine
                        dataSets {
                            name
                            data {
                                depth
                                value
                            }
                        }
                    }
                }
            }
        }
    `;
}
