<h1 class="title">
    Save template
</h1>

<div [formGroup]="form" class="content">
    <cl-button-toggle [formControlName]="Controls.Access" [list]="templateAccessList" class=""></cl-button-toggle>

    <fieldset class="fieldset">
        <cl-input [formControlName]="Controls.Name" class="name" placeholder="Template name"></cl-input>
        <cl-form-errors [control]="nameControl"></cl-form-errors>
    </fieldset>
    <textarea [formControlName]="Controls.Description" class="textarea" placeholder="What changed"></textarea>
</div>

<div class="actions">
    <button (click)="onSave()" class="cl-primary-button">
        Apply
    </button>
</div>

<button (click)="close()" class="close cl-icon-button">
    <icon name="close"></icon>
</button>