import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { uuid } from '../../utils';

/** @internal */
type ChangeMethod = (x: unknown) => void;
/** @internal */
type TouchMethod = () => void;

/** Cusotm checkbox */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cl-checkbox',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule
    ],
    templateUrl: './cl-checkbox.component.html',
    styleUrls: ['./cl-checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ClChecboxComponent), multi: true }
    ]
})
export class ClChecboxComponent implements ControlValueAccessor {
    /** @private */
    private _checked = false;
    /** @private */
    private _isDisabled = false;
    /** @private */
    private _registerOnChange: ChangeMethod | undefined;
    /** @private */
    private _onTouched: TouchMethod | undefined;

    /** @internal */
    constructor(private readonly ref: ChangeDetectorRef) { }

    /** @private */
    get isDisabled() {
        return this._isDisabled;
    }
    /** @private */
    get checked() {
        return this._checked;
    }
    /** Current checkbox value */
    @Input() set checked(value: boolean) {
        this._checked = value;
    }

    /** id */
    @Input() id: string = '_' + uuid();
    /** Checked */
    @Input() set disabled(value: boolean) {
        this._isDisabled = value;
    }

    /** @internal */
    @Output() readonly checkedChange = new EventEmitter<boolean>();

    /** @internal */
    writeValue(obj: boolean): void {
        this.checked = Boolean(obj);
        this.ref.markForCheck();
    }

    /** @internal */
    registerOnChange(fn: any): void {
        this._registerOnChange = fn;
    }

    /** @internal */
    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    /** @internal */
    setDisabledState?(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
        this.ref.markForCheck();
    }

    /** @internal */
    public onChange() {
        this._checked = !this._checked;
        this._registerOnChange?.(this._checked);
        this.checkedChange.emit(this._checked);
    }
}
