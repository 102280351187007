<span class="left">
    <span class="title">{{ name | noData }}</span>

    <button *ngIf="!readonly" (click)="edit.emit()" [disabled]="disableActions"
        class="cl-icon-button outlined edit-button">
        <icon name="settings"></icon>
    </button>
</span>

<div class="right">
    <div class="updated-time">
        <i>Updated:</i>
        <time>{{ updatedDate | datetime }}</time>
    </div>
    <div class="actions">
        <button (click)="onAction(PlanningHeadActions.VersionHistory)" [class.active]="versionHistoryVisible"
            class="action-button cl-flat-button">
            version history
        </button>
        <ng-container *ngIf="!readonly">
            <button (click)="onAction(PlanningHeadActions.LoadTemplate)" [disabled]="disableActions"
                class="action-button cl-flat-button">
                load template
            </button>
            <button (click)="onAction(PlanningHeadActions.SaveAsTemplate)" [disabled]="disableActions"
                class="action-button cl-flat-button">
                save as template
            </button>
            <button (click)="onAction(PlanningHeadActions.Save)" [disabled]="disableActions" [class.active]="canSave"
                class="action-button cl-flat-button">
                save
            </button>
        </ng-container>
    </div>
</div>
