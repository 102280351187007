import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'cyberloop-dd-dashboard-card',
    standalone: true,
    templateUrl: './dd-dashboard-card.component.html',
    styleUrls: ['./dd-dashboard-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DdDashboardCardComponent {

    constructor() { }

    @Input() title = '';
    @Input() color = '';
    @Input() distance: number | null = null;
    @Input() direction: number | null = null;

}
