import { uuid } from '@cyberloop/core';
import { ChartAsset, Mode, Orientation } from '@cyberloop/web/wells/model';

export type DrillingChartSettings = {
    orientation: Orientation;
    mode: Mode
    selectedDepthWsId: string;
    selectedTimeWsId: string;
    depthWs: DrillingWorkspace[];
    timeWs: DrillingWorkspace[];
}

export type DrillingWorkspace = {
    id: string;
    charts: Record<number, (ChartAsset | null)>[];
    name: string;
    chartsToShow: number;
}

export const defaultTimeRange = 15 * 60 * 1000;
export const defaultDepthRange = 15;

export const defaultWs = (name: string) => ({
    charts: Array(10).fill(0).map(() => ({ 0: null, 1: null, 2: null })),
    name: name ?? 'Default',
    id: uuid(),
    chartsToShow: 1
});

export const defaultDrillingChartSettings: DrillingChartSettings = Object.freeze({
    orientation: Orientation.Vertical,
    mode: Mode.Time,
    selectedDepthWsId: '',
    selectedTimeWsId: '',
    depthWs: [defaultWs('Default depth ws')],
    timeWs: [defaultWs('Default time ws')]
});


