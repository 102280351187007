import type { Points } from '@cyberloop/core';

import * as moment from 'moment';

export type TagHistoryInterval = {
    startTime: string,
    minTime: string,
    maxTime: string,
    startValue: number,
    avgValue: number,
    minValue: number,
    maxValue: number
};

export function getPointsFromTagHistoryData(step: number, data: TagHistoryInterval[]): Points {
    return data.map(item => ({
        x: moment(item.startTime).valueOf(),
        y: item.avgValue
    }));
}
