import { Section } from '@cyberloop/core';
import { Survey } from '@cyberloop/web/wells/model';

import type { Observable } from 'rxjs';

/** Base class for well information provider. Used as Injection token */
export abstract class SurveyDataProviderSerivce {
    /** Get survey data */
    abstract getSurveyData(wellID: string, sectionID: string): Observable<Survey[]>;

    /** Get survey data for tree */
    abstract getSurveyDataTree(wellID: string, sections: Section[], currentSectionId: number): Observable<Survey[]>;

    /** Watch survey data by section */
    abstract watchSurveyData(wellID: string, sectionID?: string): Observable<Survey[] | undefined>;

    /** Watch survey data for tree */
    abstract watchSurveyDataTree(wellID: string, sections: Section[], currentSectionId: number): Observable<Survey[] | undefined>;
}