<div class="head">
    <span class="col skeleton">
        activity
    </span>

    <span class="col skeleton">
        Well phase
    </span>

    <!-- Est. Time -->

    <span class="col skeleton">
        Hours
    </span>

    <span class="col skeleton">
        Days
    </span>

    <span class="col skeleton">
        Cumulative
    </span>

    <!-- Depth -->

    <span class="col skeleton">
        Start depth
    </span>

    <span class="col skeleton">
        End depth
    </span>

    <span class="col skeleton">
        Interval
    </span>

    <!-- TECHNICAL LIMIT -->

    <span class="col skeleton">
        Hours
    </span>

    <span class="col skeleton">
        Days
    </span>

    <span class="col skeleton">
        Cumulative
    </span>

    <span class="col skeleton">
        Comment
    </span>

    <span class="col skeleton">
    </span>

    <span class="col toggle skeleton-frame">
    </span>
</div>

<div class="content">
    <ng-container *ngFor="let i of skeletonCount">
        <span class="col activity skeleton">
            lorem
        </span>

        <span class="col skeleton">
            lorem
        </span>

        <!-- Est. Time -->

        <span class="col skeleton">
            lorem
        </span>

        <span class="col skeleton">
            lorem

        </span>

        <span class="col skeleton">
            lorem
        </span>

        <!-- Depth -->

        <span class="col skeleton">
            lorem
        </span>

        <span class="col skeleton">
            lorem
        </span>

        <span class="col skeleton">
            lorem

        </span>

        <!-- Tech limit -->

        <span class="col skeleton">
            lorem
        </span>

        <span class="col skeleton">
            lorem
        </span>

        <span class="col skeleton">
            lorem
        </span>

        <span class="col comment skeleton">
            lorem
        </span>

        <span class="col attach skeleton-frame">
            lorem
        </span>

        <span class="col menu skeleton-frame">
        </span>
    </ng-container>
</div>

<div class="footer skeleton">
    lorem
</div>
