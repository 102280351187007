
import { CoreSelectors } from '@cyberloop/core';
import { SlideVsRotaryWidgetSettingsMode, getMomentsFromSection, slideVsRotaryDefaultSettings } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { EMPTY, Observable, combineLatest, distinctUntilChanged, map, switchMap } from 'rxjs';

import { KpiSelectors } from '../../state/kpi/kpi.selectors';
import { KpiDataService } from '../kpi-data.service';

import type { KpiDrillingMetricsPerJointMetersItem, KpiDrillingMetricsPerJointTimeItem } from '@cyberloop/web/wells/model';
import type { SlideVsRotaryWidgetData, SlideVsRotaryWidgetSettings } from '@cyberloop/web/wells/model';

export function getSlideVsRotaryWidgetData(this: { store: Store, data: KpiDataService }, id: string): Observable<SlideVsRotaryWidgetData> {
    return combineLatest([
        this.store.select(CoreSelectors.currentWell),
        this.store.select(KpiSelectors.widgetSettings<SlideVsRotaryWidgetSettings>(id))
    ]).pipe(
        // Custom distinct function that prevents triggering query when things
        // needed for it have no changes
        distinctUntilChanged((prev, curr) =>
            prev[0]?.id === curr[0]?.id &&
            isEqual(prev[1], curr[1])
        ),
        switchMap(([well, settings]) => {
            if (!well) {
                return EMPTY;
            }

            const mode = settings?.mode ?? slideVsRotaryDefaultSettings.mode;

            const timeRangeObservable = getMomentsFromSection(well, settings?.sectionId);

            return timeRangeObservable.pipe(
                switchMap(timeRange => {
                    const since = timeRange.since.clone().startOf('second').toDate();
                    const until = timeRange.until.clone().startOf('second').toDate();

                    return mode === SlideVsRotaryWidgetSettingsMode.Length
                        ? this.data.watchDrillingMetricsPerJointMeters(
                            well.rig,
                            since,
                            until
                        )
                        : this.data.watchDrillingMetricsPerJointTime(
                            well.rig,
                            since,
                            until
                        );
                }),
                map(rows => ({
                    metrics: rows.map(item => mode === SlideVsRotaryWidgetSettingsMode.Length
                        ? ({
                            startTime: new Date(item.startTime),
                            rotaryDrilled: (item as KpiDrillingMetricsPerJointMetersItem).rotaryMetersDrilled,
                            slideDrilled: (item as KpiDrillingMetricsPerJointMetersItem).slideMetersDrilled
                        })
                        : ({
                            startTime: new Date(item.startTime),
                            rotaryDrilled: (item as KpiDrillingMetricsPerJointTimeItem).rotaryTimeDrilled,
                            slideDrilled: (item as KpiDrillingMetricsPerJointTimeItem).slideTimeDrilled
                        })
                    )
                }))
            );
        })
    );
}
