<cyberloop-kpi-widget heading="ROP" [size]="size" class="widget" (settingsRequested)="onSettings()" (deleteRequested)="onDelete()">
    <div widget-header class="widget-header">
        <div class="column">
            <h4>Target <span class="units">{{ropUnit}}</span></h4>
            <div class="value target" [class.skeleton]="dataLoading$ | async">{{target$ | async}}</div>
        </div>
        <div class="column">
            <h4>Average <span class="units">{{ropUnit}}</span></h4>
            <div class="value average" [class.skeleton]="dataLoading$ | async">
                {{average$ | async}}
                <icon name="arrow__double__up" class="direction"></icon>
            </div>
        </div>
    </div>
    <div class="chart-container" widget-body [class.loading]="dataLoading$ | async">
        <cyberloop-chart
            [options]="options"
            class="chart"
        ></cyberloop-chart>
        <div class="placeholder" *ngIf="dataLoading$ | async">
            <svg viewBox="0 0 638 278" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="paint0_linear_569_1638" x1="318.502" y1="14" x2="318.502" y2="278" gradientUnits="userSpaceOnUse">
                        <stop stop-color="var(--cl-bg-panel-sec)"/>
                        <stop offset="1" stop-color="var(--cl-bg-panel-sec)" stop-opacity="0"/>
                    </linearGradient>
                </defs>
                <rect x="0.00244141" width="637" height="278"/>
                <path d="M0.00244141 172.4L20.1915 137.264C24.0554 130.539 33.6037 130.125 38.0359 136.489C42.5731 143.004 52.4042 142.381 56.0826 135.345L82.4858 84.8456C86.8756 76.4495 98.2641 74.9485 104.679 81.9205C111.135 88.9368 122.608 87.3642 126.938 78.8695L141.386 50.523C148.952 35.6791 170.288 36.0521 177.331 51.1514L197.106 93.5498C203.086 106.37 220.396 108.561 229.381 97.6351L234.585 91.3074C240.76 83.799 252.571 84.927 257.213 93.4682C262.005 102.284 274.334 103.133 280.288 95.0562L300.381 67.799C306.251 59.8366 318.523 61.1417 322.586 70.1605L323.162 71.4384C326.195 78.1688 334.602 80.4807 340.649 76.2473C346.924 71.8548 355.656 74.5285 358.396 81.6813L374.195 122.925C377.48 131.5 388.758 133.41 394.683 126.395C400.276 119.773 410.818 121.032 414.695 128.784L436.177 171.75C439.175 177.745 447.321 178.731 451.662 173.624C455.628 168.958 462.933 169.301 466.444 174.317L468.666 177.49C471.207 181.121 476.389 181.628 479.587 178.559C482.895 175.383 488.286 176.053 490.716 179.942L506.931 205.885C509.705 210.323 516.19 210.256 518.872 205.762C521.216 201.832 526.639 201.174 529.856 204.428L550.496 225.311C554.206 229.065 560.372 228.691 563.602 224.517L564.472 223.394C567.524 219.449 573.481 219.449 576.533 223.394C579.855 227.687 586.472 227.233 589.176 222.526L597.696 207.698C604.491 195.872 621.142 194.79 629.41 205.638L637.002 215.6V272C637.002 275.314 634.316 278 631.002 278H6.00244C2.68873 278 0.00244141 275.314 0.00244141 272V172.4Z" fill="url(#paint0_linear_569_1638)" fill-opacity="0.4"/>
            </svg>
        </div>
    </div>
</cyberloop-kpi-widget>
