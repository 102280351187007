/**
 * Access level model
 */
export enum AccessLevel {
    L0 = 'L0',
    L1 = 'L1',
    L2 = 'L2',
    L3 = 'L3',
    L4 = 'L4'
}

/**
 * Access level settings model
 */
export type AccessLevelSettings = {
    operatorList: string[];
    rigList: string[];
    startDate: number | null;
    endDate: number | null;
};

/**
 * Access level 0 model
 */
export type L0AccessLevel = {
    level: AccessLevel.L0;
};

/**
 * Access level 1 model
 */
export type L1AccessLevel = {
    level: AccessLevel.L1;
    levelSettings: Pick<AccessLevelSettings, 'operatorList'>;
};

/**
 * Access level 2 model
 */
export type L2AccessLevel = {
    level: AccessLevel.L2;
    levelSettings: Pick<AccessLevelSettings, 'rigList'>;
};

/**
 * Access level 3 model
 */
export type L3AccessLevel = {
    level: AccessLevel.L3;
    levelSettings: Pick<AccessLevelSettings, 'rigList' | 'startDate' | 'endDate'>;
};

/**
 * Access level 4 model
 */
export type L4AccessLevel = {
    level: AccessLevel.L4;
    levelSettings: Pick<AccessLevelSettings, 'rigList'>;
};

/**
 * Access levels model
 */
export type AccessLevels = L0AccessLevel | L1AccessLevel | L2AccessLevel | L3AccessLevel | L4AccessLevel;
