import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';


import { AuthRoutes } from '../../../routing';
import { RestorePasswordRequestedUIComponent } from '../../../ui/restore-password-requested/restore-password-requested.component';

/** @internal */
@Component({
    standalone: true,
    imports: [
        RestorePasswordRequestedUIComponent
    ],
    template: `<cyberloop-restore-password-requested
        (backToLogin)="backToLogin()"
    ></cyberloop-restore-password-requested>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestorePasswordRequestedComponent {
    constructor(private readonly router: Router) { }

    backToLogin() {
        this.router.navigate([AuthRoutes.Root, AuthRoutes.SignIn]);
    }
}
