import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiRopSumItem } from '@cyberloop/web/wells/model';
type KpiRopSumsInput = {
    rig: string,
    since: string,
    until: string
}

type KpiRopSums = {
    rig: {
        rigKPIRopSums: KpiRopSumItem
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiRopSumsQuery extends GraphQLQuery<KpiRopSums, KpiRopSumsInput> {
    override readonly document = `
        query GetKpiRopSums($rig: String!, $since: DateTime!, $until: DateTime!) {
            rig(name: $rig) {
                ... on Rig {
                    rigKPIRopSums(since: $since, until: $until) {
                        ...on KPIRopSums {
                            avgValueRotaryDrilling
                            avgValueSlideDrilling
                        }
                    }
                }
            }
        }
    `;
}