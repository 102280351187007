<h1 *ngIf="data?.title" class="title">
    {{ data?.title }}
</h1>

<div class="content">
    <fieldset class="fieldset">
        <ng-container [ngSwitch]="data?.type">
            <ng-container *ngSwitchCase="PromptDialogControlType.Number">
                <cl-numeric-input [formControl]="formControl"
                    [placeholder]="data?.placeholder ?? ''"></cl-numeric-input>
            </ng-container>
            <ng-container *ngSwitchCase="PromptDialogControlType.Date">
                <cl-datepicker [formControl]="formControl" [placeholder]="data?.placeholder ?? ''"></cl-datepicker>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <cl-input [formControl]="formControl" [placeholder]="data?.placeholder ?? ''"></cl-input>
            </ng-container>
        </ng-container>

        <cl-form-errors [control]="formControl"></cl-form-errors>
    </fieldset>
</div>

<div class="actions">
    <button (click)="onSave()" class="cl-primary-button">
        {{ data?.saveText ?? 'Save' }}
    </button>
</div>

<button (click)="close()" class="close cl-icon-button">
    <icon name="close"></icon>
</button>