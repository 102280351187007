import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClInputComponent, ClSelectorComponent, FormErrorsComponent, SimpleChangesOf } from '@cyberloop/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { debounceTime, distinct, distinctUntilChanged, filter, firstValueFrom, map, share, switchMap, tap } from 'rxjs';

import { PseudoLoader } from '../../../components/pseudo-loader/pseudo-loader.component';
import { LoginRequest } from '../../../models/login-request';
import { TenantInfo } from '../../../models/tenant';

enum Controls {
    login = 'login',
    password = 'password',
    tenant = 'tenantId'
}

/** @internal */
@Component({
    selector: 'cyberloop-signin',
    standalone: true,
    imports: [
        NgIf,
        ClInputComponent,
        FormsModule,
        ReactiveFormsModule,
        ClSelectorComponent,
        PseudoLoader,
        FormErrorsComponent
    ],
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class SigninUIComponent implements OnChanges {
    private _listOfTenants: TenantInfo[] = [];


    @Input() loading: boolean | null = false;
    @Input() error: string | null = null;
    @Input() listOfTenantsBackgroundLoading?: boolean | null = false;
    @Output() readonly tryLogin = new EventEmitter<LoginRequest>();

    @Output() readonly restorePassword = new EventEmitter();

    readonly Controls = Controls;
    readonly form = new FormGroup({
        [Controls.login]: new FormControl<string>('', {
            validators: [Validators.required, Validators.email],
            updateOn: 'blur'
        }),
        [Controls.password]: new FormControl<string>('', [Validators.required, Validators.minLength(6), Validators.maxLength(32)]),
        [Controls.tenant]: new FormControl<string | null>(null, [Validators.required])
    }, {
        updateOn: 'change'
    });

    @Output() readonly loadTenantList = this.form.controls.login.valueChanges.pipe(
        debounceTime(500),
        filter(() => this.form.controls.login.valid),
        map(x => (x || '').toLocaleLowerCase()),
        distinctUntilChanged(),
        map(login => ({ login, background: false })),
        tap(x => console.log('@@ loadTenantList request', x)),
        share()
    );

    @Input() set listOfTenants(value: TenantInfo[] | null | undefined) {
        this._listOfTenants = value ?? [];

        if (this._listOfTenants.length === 1) {
            const tenant = this._listOfTenants.at(0);
            this.tenantIdControl.setValue(tenant?.id);
        }
    }

    get listOfTenants() {
        return this._listOfTenants;
    }

    get canSelectTenant() {
        return this._listOfTenants.length > 1; // 'tl' in this.activatedRoute.snapshot.queryParams;
    }

    get tenantListLoaded() {
        return Boolean(this.listOfTenants);
    }

    get loginControl() {
        return this.form.get(Controls.login) as FormControl;
    }

    get passwordControl() {
        return this.form.get(Controls.password) as FormControl;
    }

    get tenantIdControl() {
        return this.form.get(Controls.tenant) as FormControl;
    }

    ngOnChanges(changes: SimpleChangesOf<SigninUIComponent>): void {
        const loadingChanged = changes.loading;
        if (loadingChanged) {
            if (loadingChanged.currentValue) {
                if (this._listOfTenants.length) {
                    this.form.disable();
                }
            }
            else {
                this.form.enable();
            }
        }
    }

    onSubmit(): void {
        if (this.loading) {
            return;
        }

        if (this.loginControl.invalid || this.passwordControl.invalid) {
            this.loginControl.markAllAsTouched();
            this.passwordControl.markAllAsTouched();
            return;
        }

        const login = this.loginControl.value;

        if (!this.listOfTenants?.length) {
            return;
        }

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.tryLogin.emit({
            login,
            password: this.passwordControl.value,
            tenantId: this.tenantIdControl.value
        });
    }

    @HostListener('document:keydown.escape', ['$event'])
    resetForm() {
        if (this.form.enabled) {
            this.form.reset();
        }
    }
}
