import type { Points, SectionInput } from "@cyberloop/core";
import { Injectable } from '@angular/core';

import { CoreSelectors, RangePickerDataService, WellKnownParams } from '@cyberloop/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, filter, map, of, switchMap } from 'rxjs';

import * as moment from 'moment';

import { KpiWellTagTimeHistoryQuery } from '../queries/kpi/well-tag-time-history.query';

import type { RangePickerData } from "libs/core/src/lib/models/range-picker-data";
import type { KpiWellTagTimeHistoryItemInterval } from "@cyberloop/web/wells/model";

const REFRESH_INTERVAL = 3600 * 1000;

@Injectable({ providedIn: 'root' })
export class RangePickerDataServiceGraphQL extends RangePickerDataService {

    constructor(
        private readonly store: Store,
        private readonly wellTagTimeHistoryQuery: KpiWellTagTimeHistoryQuery
    ) {
        super();
    }

    getData(tags: WellKnownParams[], needSections?: boolean | undefined): Observable<RangePickerData | undefined> {
        if (tags.length <= 0 && !needSections) {
            return of(undefined);
        }

        const step = 3600;

        return this.store.select(CoreSelectors.currentWell).pipe(
            switchMap(well => {
                if (!well) {
                    return EMPTY;
                }

                const since = well.startTime;
                const until = well.releaseTime ?? well.suspendTime ?? new Date();

                if (tags.length <= 0) {
                    return of(undefined);
                }

                return this.wellTagTimeHistoryQuery.watch({
                    wellId: well.id,
                    tags,
                    since: since.toISOString(),
                    until: until.toISOString(),
                    step
                }, {
                    pollInterval: REFRESH_INTERVAL
                }).pipe(
                    filter(x => !x?.loading),
                    map(response => response!),
                    map(response => {
                        if (response.error) {
                            throw new Error(response.error.message);
                        }
                        else if (response.errors) {
                            throw new Error(response.errors.map(err => err.message).join('; '));
                        }

                        return response.data.well.timeHistory;
                    }),
                    map(historyData => {
                        let wde: Points | undefined = undefined;
                        let bde: Points | undefined = undefined;

                        if (historyData) {

                            for (const item of historyData) {
                                switch (item.tagName) {

                                    case WellKnownParams.WDE:
                                        wde = this.getPointsFromTagHistoryData(step, item.intervals);
                                        break;

                                    case WellKnownParams.BDE:
                                        bde = this.getPointsFromTagHistoryData(step, item.intervals);
                                        break;

                                    default:
                                        break;
                                }
                            }
                        }

                        let sections: SectionInput[] | undefined = undefined;

                        if (needSections) {
                            sections = [];

                            for (let i = 0; i < well.sections.length; i++) {
                                const section = well.sections[i];

                                let startTime = section.startTime;

                                // Fix bad start times

                                if (startTime.getFullYear() < 1970) {
                                    // Make it the same as well start time
                                    startTime = well.startTime;
                                }

                                sections.push({
                                    id: section.id.toString(),
                                    name: section.name,
                                    value: startTime.getTime()
                                });
                            }
                        }

                        const result: RangePickerData = {
                            wde,
                            bde,
                            sections
                        };

                        return result;
                    })
                );
            })
        );
    }

    private getPointsFromTagHistoryData(step: number, data: KpiWellTagTimeHistoryItemInterval[]): Points {
        return data.map(item => ({
            x: moment(item.startTime).valueOf(),
            y: item.avgValue
        }));
    }

}
