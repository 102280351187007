import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { IconComponent } from '@cyberloop/core';

@Component({
    selector: 'cyberloop-list-item-skeleton',
    standalone: true,
    imports: [IconComponent],
    templateUrl: './list-item-skeleton.component.html',
    styleUrls: ['./list-item-skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemSkeletonComponent {
    @HostBinding('class.skeleton-bg') private readonly _skeleton = true;

}
