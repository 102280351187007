import { isEqual } from 'lodash';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, finalize, map, share } from 'rxjs';

/**
 * Initialize resize observer for specific element
 * @param element Element to observe
 * @param opts Resize observation options
 * @returns Observable of ResizeObserverEntry
 */
function observeResize(element: HTMLElement, opts?: ResizeObserverOptions): Observable<ResizeObserverEntry>;
/**
 * Initialize resize observer for multiple elements
 * @param element Elements to observe
 * @param opts Resize observation options
 * @returns Observable of array of ResizeObserverEntry
 */
function observeResize(elements: HTMLElement[], opts?: ResizeObserverOptions): Observable<ResizeObserverEntry[]>;
/** @private */
function observeResize(elements: HTMLElement[] | HTMLElement, opts?: ResizeObserverOptions): unknown {
    if (!Array.isArray(elements)) {
        elements = [elements];
    }
    else {
        elements = [...elements as HTMLElement[]];
    }

    const currSizes: ResizeObserverEntry[] = elements.map(el => {
        const item: ResizeObserverEntry = {
            target: el,
            devicePixelContentBoxSize: [],
            contentBoxSize: [],
            borderBoxSize: [],
            contentRect: el.getBoundingClientRect()
        };
        return item;
    })

    const subj = new BehaviorSubject<ResizeObserverEntry[]>(currSizes);
    const observer = new ResizeObserver((entries => {
        subj.next(entries);
    }));

    let data = subj.pipe(
        finalize(() => {
            for (const element of elements as HTMLElement[]) {
                observer.unobserve(element);
            }

            observer.disconnect();
        }),
        debounceTime(100),
        distinctUntilChanged((a, b) => isEqual(a, b)),
    );


    if (elements.length === 1) {
        data = data.pipe(
            map(items => items[0]),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;
    }

    for (const element of elements) {
        observer.observe(element, opts);
    }

    return data.pipe(share());
}

export {
    observeResize
};