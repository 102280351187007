import { Component, Type, ViewContainerRef } from '@angular/core';

/** Base interface for popup */
export interface PopupContent<TRes, TInput = unknown> {
    /**
     * This method is used to set custom data on creation of popup
     * @param data Custom data
     */
    setData?(data: TInput): void;
    /**
     * Set close function. You will use this function to close you popup
     * @param fn Function that closes the popup
     */
    setClose(fn: (result: TRes | null) => void): void;
}

/** @internal */
@Component({
    template: '<!-- popup host -->',
    styleUrls: ['./popup-host.component.scss'],
    standalone: true,
    imports: [
    ]
})
export class PopupHostComponent {
    /**
     *
     */
    constructor(private readonly vcr: ViewContainerRef) {

    }

    attachView<TRes>(
        component: Type<PopupContent<TRes>>,
        data: unknown,
        onClose: (resultValue: unknown) => void
    ) {
        const vcr = this.vcr;
        if (!vcr) {
            throw new Error('Unexpected');
        }

        vcr.clear();
        const componentRef = vcr.createComponent(component, {

        });

        componentRef.instance.setClose(onClose);

        if (componentRef.instance.setData) {
            componentRef.instance.setData(data);
        }

        const el = vcr.element.nativeElement as HTMLElement;
        el.appendChild(componentRef.location.nativeElement);
    }
}
