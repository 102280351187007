import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { SimpleChangesOf } from '../../models';

/** @internal */
interface Size {
    width: number,
    height: number
}

/** This component helps keep inner content bto be scaled no matter what size oarent is */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'scalable-content',
    standalone: true,
    templateUrl: './scalable-content.component.html',
    styleUrls: ['./scalable-content.component.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ScalableContent implements AfterViewInit, OnDestroy, OnChanges {

    private _resizer: ResizeObserver | undefined = new ResizeObserver(() => {
        this.applySize();
    });

    private _subs: Subscription | undefined;

    /** @internal */
    constructor(
        private readonly vcr: ViewContainerRef
    ) {
    }

    /** Mode of zoom to be applied */
    @Input() mode: 'fit' | 'cover' | 'fill' = 'fit';

    /** @internal */
    @ViewChild('scalableArea') scalableArea: ElementRef<HTMLElement> | undefined;

    /** @internal */
    ngAfterViewInit(): void {
        const scalableArea = this.scalableArea?.nativeElement;
        if (!scalableArea) {
            throw new Error('scalableArea was not initialized');
        }

        const container = this.vcr.element.nativeElement;
        if (!container) {
            throw new Error('container was not initialized');
        }

        if (this._resizer) {
            this._resizer.observe(container);
            this._resizer.observe(scalableArea);
        }
    }

    /** @internal */
    ngOnChanges(changes: SimpleChangesOf<ScalableContent>): void {
        const modeChanges = changes.mode;
        if (modeChanges) {
            this.applySize();
        }
    }

    /** @internal */
    ngOnDestroy(): void {
        if (this._resizer) {
            this._resizer.disconnect();
            this._resizer = undefined;
        }

        if (this._subs) {
            this._subs.unsubscribe();
            this._subs = undefined;
        }
    }

    private applySize() {
        const scalableArea = this.scalableArea?.nativeElement as HTMLElement;
        if (!scalableArea) {
            return;
        }
        const container = this.vcr.element.nativeElement;
        const containerSize: Size = { width: container.clientWidth, height: container.clientHeight };

        switch (this.mode) {
            case 'fit':
                this.fitContents(scalableArea, containerSize);
                break;
            case 'cover':
                this.coverContents(scalableArea, containerSize);
                break;
            case 'fill':
                this.fillContents(scalableArea, containerSize);
                break;
        }
    }

    private fillContents(scalableArea: HTMLElement, containerSize: Size) {
        // containerSize - x
        // scalableAreaSize - 1
        this.applyScale(scalableArea, (scalableAreaSize) => ([
            containerSize.width / scalableAreaSize.width,
            containerSize.height / scalableAreaSize.height
        ]));
    }

    private coverContents(scalableArea: HTMLElement, containerSize: Size) {
        this.applyScale(scalableArea, (scalableAreaSize) => {
            const ratioWidth = containerSize.width / scalableAreaSize.width;
            const ratioHeight = containerSize.height / scalableAreaSize.height;

            return ([
                Math.max(ratioWidth, ratioHeight),
                Math.max(ratioWidth, ratioHeight)
            ]);
        });
    }
    private fitContents(scalableArea: HTMLElement, containerSize: Size) {
        this.applyScale(scalableArea, (scalableAreaSize) => {
            const ratioWidth = containerSize.width / scalableAreaSize.width;
            const ratioHeight = containerSize.height / scalableAreaSize.height;

            return ([
                Math.min(ratioWidth, ratioHeight),
                Math.min(ratioWidth, ratioHeight)
            ]);
        });
    }

    private applyScale(
        scalableArea: HTMLElement,
        calc: (scalableAreaSize: Size) => [ratioWidth: number, ratioHeight: number]) {
        const scalableAreaSize: Size = { width: scalableArea.clientWidth, height: scalableArea.clientWidth };
        const [ratioWidth, ratioHeight] = calc(scalableAreaSize);
        scalableArea.style.transform = `scale(${ratioWidth}, ${ratioHeight})`;
    }
}
