import { ID } from '@cyberloop/core';
import { CreateForecastEquipmentAndPersonnelEvent, CreateForecastEvent, Forecast, ForecastEquipmentAndPersonnelEvent, ForecastEvent, UpdateForecastEquipmentAndPersonnelEvent, UpdateForecastEvent } from '@cyberloop/web/wells/model';
import { Observable } from 'rxjs';

/** Base class for Forecast provider. Used as Injection token */
export abstract class ForecastProviderService {
    //#region Forecast

    /** Watch Forecast by Well ID */
    abstract watchByWellId(wellId: ID): Observable<Forecast | null>;

    /** Import Forecast from Planning by Well ID */
    abstract importFromWellPlanByWellID(wellId: ID): Observable<Forecast>;

    //#endregion Forecast

    //#region Event

    /** Watch Forecast events */
    abstract watchAllEvents(forecastId: ID): Observable<ForecastEvent[]>;

    /** Get Forecast event */
    abstract getEvent(forecastId: ID, eventId: ID): Observable<ForecastEvent | null>;

    /** Create Forecast event */
    abstract createEvent(forecastId: ID, createEvent: CreateForecastEvent): Observable<ForecastEvent>;

    /** Update Forecast event */
    abstract updateEvent(forecastId: ID, eventId: ID, updateEvent: UpdateForecastEvent): Observable<ForecastEvent>;

    /** Expand to next Forecast event */
    abstract expandToNextEvent(forecastId: ID, eventId: ID): Observable<ForecastEvent>;

    /** Delete Forecast event */
    abstract deleteEvent(forecastId: ID, eventId: ID): Observable<ForecastEvent>;

    //#endregion Event

    //#region Equipment and Personnel

    /** Watch Forecast Equipment and Personnel events */
    abstract watchAllEquipmentAndPersonnelEvents(forecastId: ID): Observable<ForecastEquipmentAndPersonnelEvent[]>;

    /** Get Forecast Equipment and Personnel event */
    abstract getEquipmentAndPersonnelEvent(forecastId: ID, equipmentAndPersonnelId: ID): Observable<ForecastEquipmentAndPersonnelEvent | null>;

    /** Create Forecast Equipment and Personnel event */
    abstract createEquipmentAndPersonnelEvent(forecastId: ID, createEquipmentAndPersonnel: CreateForecastEquipmentAndPersonnelEvent): Observable<ForecastEquipmentAndPersonnelEvent>;

    /** Update Forecast Equipment and Personnel event */
    abstract updateEquipmentAndPersonnelEvent(forecastId: ID, equipmentAndPersonnelId: ID, updateEquipmentAndPersonnel: UpdateForecastEquipmentAndPersonnelEvent): Observable<ForecastEquipmentAndPersonnelEvent>;

    //#endregion Equipment and Personnel
}
