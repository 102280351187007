import { Injectable, Type } from '@angular/core';

import { Moment } from 'moment';

import { PopupContent, PromptComponent } from '../components';
import { PromptDialogControlType, PromptDialogData } from '../models';
import { PopupService } from './popup.service';

type numberPromptDialogData = Omit<PromptDialogData, 'type'> & { type?: PromptDialogControlType.Number }
type stringPromptDialogData = Omit<PromptDialogData, 'type'> & { type?: PromptDialogControlType.Text }
type datePromptDialogData = Omit<PromptDialogData, 'type'> & { type?: PromptDialogControlType.Date }

/** Confirm any operation you need */
@Injectable({ providedIn: 'root' })
export class PromptDialogService {

    /** @private @internal */
    constructor(private readonly popup: PopupService) { }

    /**
     * Show confirmation dialog
     * @param data prompt dialog data
     * @returns Promise that resolves with input data or false
     */
    async show<T>(data: PromptDialogData): Promise<T> {
        return await this.popup.showForResult<T, PromptDialogData>(PromptComponent as Type<PopupContent<T, PromptDialogData>>, { data });
    }

    /**
     * Prompts the user to enter a number.
     * @param {numberPromptDialogData} data - The object containing prompt dialog data.
     * @returns {Promise<number>} A promise that resolves to the number entered by the user.
     */
    async promptNumber(data: numberPromptDialogData): Promise<number>;
    /**
     * Prompts the user to enter a number.
     * @param {string} title - The title of the prompt dialog.
     * @param {number} [value] - The default value to pre-fill in the prompt dialog.
     * @param {string} [placeholder] - The placeholder text to display in the prompt dialog.
     * @returns {Promise<number>} A promise that resolves to the number entered by the user.
     */
    async promptNumber(title: string, value?: number, placeholder?: string): Promise<number>;
    /**
     * Prompts the user to enter a number.
     * @param {numberPromptDialogData | string} titleOrData - The title of the prompt dialog or an object containing prompt dialog data.
     * @param {number} [value] - The default value to pre-fill in the prompt dialog.
     * @param {string} [placeholder] - The placeholder text to display in the prompt dialog.
     * @returns {Promise<number>} A promise that resolves to the number entered by the user.
     * @internal
     */
    async promptNumber(titleOrData: string | numberPromptDialogData, value?: number, placeholder?: string): Promise<number> {
        let data: PromptDialogData;
        if(typeof titleOrData === 'string') {
            data = {
                title: titleOrData,
                value,
                placeholder,
                type: PromptDialogControlType.Number
            };
        } else {
            data = {
                ...titleOrData,
                type: PromptDialogControlType.Number
            };
        }
        
        return await this.show<number>(data);
    }

    /**
     * Prompts the user to enter a text.
     * @param {stringPromptDialogData} data - The object containing prompt dialog data.
     * @returns {Promise<string>} A promise that resolves to the text entered by the user.
     */
    async promptString(data: stringPromptDialogData): Promise<string>;
    /**
     * Prompts the user to enter a text.
     * @param {string} title - The title of the prompt dialog.
     * @param {string} [value] - The default value to pre-fill in the prompt dialog.
     * @param {string} [placeholder] - The placeholder text to display in the prompt dialog.
     * @returns {Promise<string>} A promise that resolves to the text entered by the user.
     */
    async promptString(title: string, value?: string, placeholder?: string): Promise<string>;
    /**
     * Prompts the user to enter a text.
     * @param {stringPromptDialogData | string} titleOrData - The title of the prompt dialog or an object containing prompt dialog data.
     * @param {string} [value] - The default value to pre-fill in the prompt dialog.
     * @param {string} [placeholder] - The placeholder text to display in the prompt dialog.
     * @returns {Promise<string>} A promise that resolves to the text entered by the user.
     * @internal
     */
    async promptString(titleOrData: string | stringPromptDialogData, value?: string, placeholder?: string): Promise<string> {
        let data: PromptDialogData;
        if(typeof titleOrData === 'string') {
            data = {
                title: titleOrData,
                value,
                placeholder,
                type: PromptDialogControlType.Text
            };
        } else {
            data = {
                ...titleOrData,
                type: PromptDialogControlType.Text
            };
        }
        
        return await this.show<string>(data);
    }

    /**
     * Prompts the user to enter a date.
     * @param {datePromptDialogData} data - The object containing prompt dialog data.
     * @returns {Promise<Moment>} A promise that resolves to the date entered by the user.
     */
    async promptDate(data: datePromptDialogData): Promise<Moment>;
    /**
     * Prompts the user to enter a date.
     * @param {string} title - The title of the prompt dialog.
     * @param {Moment} [value] - The default value to pre-fill in the prompt dialog.
     * @param {string} [placeholder] - The placeholder text to display in the prompt dialog.
     * @returns {Promise<Moment>} A promise that resolves to the date entered by the user.
     */
    async promptDate(title: string, value?: Moment, placeholder?: string): Promise<Moment>;
    /**
     * Prompts the user to enter a date.
     * @param {datePromptDialogData | string} titleOrData - The title of the prompt dialog or an object containing prompt dialog data.
     * @param {Moment} [value] - The default value to pre-fill in the prompt dialog.
     * @param {string} [placeholder] - The placeholder text to display in the prompt dialog.
     * @returns {Promise<Moment>} A promise that resolves to the date entered by the user.
     * @internal
     */
    async promptDate(titleOrData: string | datePromptDialogData, value?: Moment, placeholder?: string): Promise<Moment> {
        let data: PromptDialogData;
        if(typeof titleOrData === 'string') {
            data = {
                title: titleOrData,
                value,
                placeholder,
                type: PromptDialogControlType.Date
            };
        } else {
            data = {
                ...titleOrData,
                type: PromptDialogControlType.Date
            };
        }
        
        return await this.show<Moment>(data);
    }
    
}