
/**
 * Settings object of a T&D chart in the Planning section
 */
export type PlanningTndChartSettings = {
    selectedModelId?: string,
};

/**
 * Default settings of a T&D chart in the Planning section
 */
export const planningTndChartDefaultSettings = {};
