import { TenantInfo, User } from '../../models';

/** @internal */
export const AUTH_FEATURE = 'AUTH';

/** @internal */
export type LoadingState = 'NOT_LOADED' | 'LOADED' | 'LOADING';

/**
 * @internal
 * State for auth
 * */
export interface AuthState {
    loginState: 'NOT_LOGGED_IN' | 'LOGGING_IN' | 'LOGGED_IN';
    isInitialized: boolean;
    loginError?: any;
    user?: User;
    listOfTenants?: TenantInfo[];
    listOfTenantsState: LoadingState;
    listOfTenantsBackgroundLoading?: boolean;
    listOfTenantsError?: any;
    restorePasswordState: 'INITIAL' | 'PROCESS';
    restorePasswordError?: any;
    setNewPasswordState: 'INITIAL' | 'PROCESS';
    setNewPasswordError?: any;
}

/** @internal */
export function getDefaultAuthState(): AuthState {
    return {
        loginState: 'NOT_LOGGED_IN',
        isInitialized: false,
        listOfTenantsState: 'NOT_LOADED',
        restorePasswordState: 'INITIAL',
        setNewPasswordState: 'INITIAL'
    };
}
