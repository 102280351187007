import { APP_INITIALIZER, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';

import { authRouting, getCoreProviders, haltApplication } from '@cyberloop/core';
import { getFirebaseProviders } from '@cyberloop/links/firebase';
import { getGraphQLProviders } from '@cyberloop/links/graphql';
import { WebWorkerHostService } from '@cyberloop/web-workers';
import { AppContainerComponent, AppProviders, appRoutes } from '@cyberloop/web/app/entry';

async function main() {
    try {
        //const ww1 = new Worker(new URL('./app/ww1.worker', import.meta.url));

        await bootstrapApplication(AppContainerComponent, {
            providers: [
                AppProviders,
                getCoreProviders(
                    'Portal'
                ),

                // makeEnvironmentProviders([
                //     {
                //         provide: APP_INITIALIZER, useFactory: (wwhs: WebWorkerHostService) => {
                //             return async () => {
                //                 const proxy = await wwhs.getProxy<{
                //                     helloWorld(me: string): Promise<string>
                //                 }>(ww1);

                //                 const msg = await proxy.helloWorld('SteveX!');

                //                 console.warn('@@ RPC: ', msg);
                //             };
                //         }, deps: [WebWorkerHostService], multi: true
                //     }
                // ]),

                provideRouter([
                    ...appRoutes,
                    ...authRouting,
                ], withEnabledBlockingInitialNavigation(), withInMemoryScrolling({
                    scrollPositionRestoration: 'enabled'
                })),
                importProvidersFrom(BrowserAnimationsModule),

                getGraphQLProviders({
                    httpUrl: 'https://api.transit.cyberloop.ai/graphql',
                    wsUrl: 'wss://api.transit.cyberloop.ai/graphql'
                }),
                getFirebaseProviders({
                    apiKey: 'AIzaSyA4GaKbEH4tSE9JyeSEO_lbb2QN4WchJkQ',
                    authDomain: 'sso-test-526c8.firebaseapp.com',
                    databaseURL: 'https://sso-test-526c8-default-rtdb.firebaseio.com',
                    projectId: 'sso-test-526c8',
                    storageBucket: 'sso-test-526c8.appspot.com',
                    messagingSenderId: '873715603772',
                    appId: '1:873715603772:web:32c31c2a833ab84fe22639',
                    measurementId: 'G-C0ESW3SYE9'
                }),

            ],
        });

        const attrName = 'can-delete';
        const item = document.querySelector('[kamikadze]') as HTMLElement;
        if (item) {
            if (!item.hasAttribute(attrName)) {
                new MutationObserver((_, o) => {
                    if (item.hasAttribute(attrName)) {
                        item.remove();
                        o.disconnect();
                    }
                }).observe(item, {
                    attributes: true,
                    attributeFilter: [attrName]
                })
            }
            else {
                item.remove();
            }
        }
    }
    catch (err) {
        console.error(err);
        haltApplication(err);
    }
}


main();
