import { TemplateRepositryService } from '@cyberloop/core';
import { Observable } from 'rxjs';

import { ExportTemplate } from './export-template.service';

export abstract class DrillingExportTemplatesProvideService implements TemplateRepositryService<ExportTemplate> {
    abstract watchAll(): Observable<ExportTemplate[]>;

    abstract get(id: string): Observable<ExportTemplate | null>;

    abstract create(template: Omit<ExportTemplate, 'id' | 'author' | 'createdAt' | 'updatedAt'>): Observable<ExportTemplate>;

    abstract delete(templateId: string): Observable<ExportTemplate>;
}
