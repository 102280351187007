import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { uuid } from '@cyberloop/core';

import type { ControlValueAccessor } from '@angular/forms';
import type { Entity } from '@cyberloop/core';

/**
 * Represents a type for an item identifier that can be a string or a number.
 * @typedef {(any)} ItemID
*/
type ItemID = any;

/**
 * Represents a function type that is called when the control's value changes in the UI.
 * @callback ChangeMethod
 * @param {ItemID[]} value - The new value of the control.
 * @returns {void}
*/
type ChangeMethod = (x: ItemID[]) => void;

/**
 * Represents a function type that is called when the control is touched.
 * @callback TouchMethod
 * @returns {void}
 */
type TouchMethod = () => void;

/**
 * Checkbox group component.
 * @class
 * @implements {ControlValueAccessor}
*/
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cl-checkbox-group',
    standalone: true,
    imports: [
        NgFor,
        NgIf
    ],
    templateUrl: './cl-checkbox-group.component.html',
    styleUrls: ['./cl-checkbox-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ClCheckboxGroupComponent), multi: true }
    ]
})
export class ClCheckboxGroupComponent implements ControlValueAccessor {
    /**
     * Private property for registering change method.
     * @type {ChangeMethod|undefined}
     * @private
     * @internal
    */
    private _registerOnChange: ChangeMethod | undefined;

    /**
     * Private property for registering touch method.
     * @type {TouchMethod|undefined}
     * @private
     * @internal
    */
    private _onTouched: TouchMethod | undefined;

    /**
     * Creates an instance of ClCheckboxGroupComponent.
     * @param changeDetectorRef Change detector reference.
     * @internal
    */
    constructor(private readonly changeDetectorRef: ChangeDetectorRef) { }

    /**
     * Emits an event whenever the selected values changes.
     * @type {ItemID[]}
     * @readonly
     */
    @Output() readonly selectedChange = new EventEmitter<ItemID[]>();

    /**
     * Checkbox group name
     * @internal
     */
    readonly name: string = uuid();

    /**
     * List of items to be displayed.
     * @type {Entity<ItemID>[]}
     * @default []
     * @input
    */
    @Input() list: Entity<ItemID>[] = [];

    /**
     * Indicates whether the component is disabled or not.
     * @type {boolean}
     * @default false
    */
    @Input() disabled = false;

    /**
     * List of selected items.
     * @type {ItemID[]}
     * @default []
    */
    @Input() selected: ItemID[] = [];

    /**
     * Allow only one option to be selected.
     * @type {boolean}
     * @default false
    */
    @Input() radio = false;

    /**
     * Cut the button top right corner.
     * @type {boolean}
     * @default false
    */
    @Input() cutCorners = false;

    /**
     * Colors list for triangles near the top right corner
     * @type {string[]}
     * @default []
    */
    @Input() colors: string[] = [];

    /**
     * Selects an item.
     * @param value Value of the item to be selected.
    */
    select(value: ItemID, event: Event) {
        if (this.disabled) {
            return;
        }

        if (this.selected.includes(value)) {
            if(this.radio) {
                //Prevent uncheck checkbox
                (event.target as HTMLInputElement).checked = true;
                return;
            }
            this.selected = this.selected.filter(x => x !== value);
        }
        else {
            if(this.radio) {
                this.selected = [value];
            } else {
                this.selected = [...this.selected, value];
            }
        }

        this._onTouched?.();
        this._registerOnChange?.(this.selected);
        this.selectedChange.emit(this.selected);
    }

    /**
     * Writes a new value to the element.
     * @param value Value to be written.
     * @internal
    */
    writeValue(value: ItemID[]): void {
        this.selected = value ?? [];
    }

    /**
     * Registers a callback function to be invoked when the control's value changes in the UI.
     * @param fn Function to be registered.
     * @internal
    */
    registerOnChange(fn: ChangeMethod): void {
        this._registerOnChange = fn;
    }

    /**
     * Registers a callback function to be invoked when the control is touched.
     * @param fn Function to be registered.
     * @internal
    */
    registerOnTouched(fn: TouchMethod): void {
        this._onTouched = fn;
    }

    /**
     * Sets the disabled state of the control.
     * @param disabled Boolean value indicating whether the control should be disabled or not.
     * @internal
    */
    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
        this.changeDetectorRef.markForCheck();
    }
}
