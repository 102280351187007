import { Injectable } from '@angular/core';

import { Points, WellDephtPointsProvider } from '@cyberloop/core';
import { NaviDataProvider } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DrillingSelectors } from '../../state/drilling';

@Injectable({
    providedIn: 'root'
})
export class NaviDataProviderService extends NaviDataProvider {
    constructor(
        private readonly wdp: WellDephtPointsProvider,
        private readonly store: Store
    ) {
        super();
    }

    override getFor(wellId: string, tagIds: string[], unitGroup: number, unitId?: string | undefined): Record<string, Observable<Points>> {
        return this.wdp.getFor(wellId, tagIds, unitGroup, unitId, this.store.select(DrillingSelectors.wellViewport), this.store.select(DrillingSelectors.isTime));
    }
    override reset(): void {
        return this.wdp.reset();
    }
}

