import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import { ClChartComponent, NgVarDirective, RigActivity, UnitsConverterService, UnitsSelectors, WellKnownUnitGroupIds } from '@cyberloop/core';
import { KpiActions, KpiSelectors } from '@cyberloop/web/wells/data';
import { WidgetDataProvider, WidgetSettingsHandler, WidgetSize, WidgetType, getMomentsFromTimeRange, rigActivityDefaultSettings } from '@cyberloop/web/wells/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, filter, firstValueFrom, map, switchMap, tap } from 'rxjs';

import * as Highcharts from 'highcharts';

import { formatTooltipWithRigData } from '../../common/widget-tooltip-formatter';
import { KpiWidgetComponent } from '../widget/kpi-widget.component';
import { SettingsComponent } from './settings/settings.component';

import type { UnitDescriptor, SimpleChangesOf } from '@cyberloop/core';
import type { RigActivityWidgetSettings, KpiRigActivityData, KpiWidget } from '@cyberloop/web/wells/model';

type TableItem = { className: string; activity: string; value: number; percent: number, unit: string };

const placeholders: TableItem[] = [
    { className: '', activity: 'WWWWWWWW', value: 99999, percent: 9999, unit: '' },
    { className: '', activity: 'WWWWW', value: 9999999, percent: 9999, unit: '' },
    { className: '', activity: 'WWWWWWW', value: 999999, percent: 9999, unit: '' },
    { className: '', activity: 'WWWW', value: 9999999, percent: 9999, unit: '' },
    { className: '', activity: 'WWWWWW', value: 9999, percent: 9999, unit: '' },
    { className: '', activity: 'WWWWWW', value: 9999999, percent: 9999, unit: '' },
    { className: '', activity: 'WWWWWWW', value: 999999, percent: 9999, unit: '' },
    { className: '', activity: 'WWWWW', value: 99999, percent: 9999, unit: '' },
    { className: '', activity: 'WWWWWWW', value: 999999, percent: 9999, unit: '' },
    { className: '', activity: 'WWWW', value: 9999999, percent: 9999, unit: '' },
    { className: '', activity: 'WWWWWW', value: 9999, percent: 9999, unit: '' }
];

@Component({
    selector: 'cyberloop-rig-activity-widget',
    standalone: true,
    imports: [
        NgIf,
        NgVarDirective,
        AsyncPipe,
        DecimalPipe,
        MatTableModule,
        KpiWidgetComponent,
        ClChartComponent
    ],
    templateUrl: './rig-activity-widget.component.html',
    styleUrls: ['./rig-activity-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class RigActivityWidgetComponent implements KpiWidget, AfterViewInit, OnChanges {

    private readonly _id$ = new BehaviorSubject<string>('-');
    private readonly _dataLoading$ = new BehaviorSubject<boolean>(true);

    private readonly _tableRows$ = new BehaviorSubject<TableItem[]>([]);

    private _timeRangeMoments?: { since: moment.Moment, until: moment.Moment };
    private _rigActivityData: KpiRigActivityData[] = [];
    private _valuesSum = 0;
    private _timeUnit?: UnitDescriptor;

    constructor(
        private readonly store: Store,
        private readonly settings: WidgetSettingsHandler,
        private readonly data: WidgetDataProvider,
        private readonly unitsConverter: UnitsConverterService
    ) {
        const idPipe = this._id$.pipe(
            untilDestroyed(this),
            filter(Boolean),
            tap(() => {
                this._dataLoading$.next(true);
                this._tableRows$.next(placeholders);
            })
        );

        combineLatest([
            // Get widget data
            idPipe.pipe(
                switchMap(id => this.data.getData(id, WidgetType.RigActivity))
            ),
            // Get widget settings (for units conversion)
            idPipe.pipe(
                switchMap(id => this.store.select(KpiSelectors.widgetSettings<RigActivityWidgetSettings>(id))),
                tap(async widgetSettings => {
                    const timeRange = widgetSettings?.timeRange ?? rigActivityDefaultSettings.timeRange;
                    this._timeRangeMoments = await firstValueFrom(getMomentsFromTimeRange(this.store, timeRange));
                }),
                map(widgetSettings => widgetSettings?.timeUnitId ?? rigActivityDefaultSettings.timeUnitId),
                switchMap(timeUnitId => this.store.select(
                    UnitsSelectors.getUnit(WellKnownUnitGroupIds.Time, timeUnitId)
                ))
            )
        ]).pipe(
            untilDestroyed(this)
        ).subscribe(([widgetData, unit]) => {
            if (!this.chart) {
                return;
            }

            const hasData = widgetData.data?.length > 0;
            const dataIsDifferent = hasData || !hasData && this._rigActivityData.length > 0;
            const unitsAreDifferent = unit?.id !== this._timeUnit?.id;

            if (!hasData && !dataIsDifferent && !unitsAreDifferent) {
                return;
            }

            this._timeUnit = unit;
            this._rigActivityData = widgetData.data;

            // TODO: Don't make any updates in there was no actual changes
            this.updateData();

            this._dataLoading$.next(false);
        });
    }

    readonly options = this.getChartOptions();
    readonly dataLoading$ = this._dataLoading$.asObservable();
    // Table-related
    readonly displayedColumns: string[] = ['activity', 'value', 'percent'];
    readonly tableRows$ = this._tableRows$.asObservable();

    get timeUnit() {
        return this._timeUnit?.label ?? '';
    }

    @Input() id = '-';
    @Input() size = WidgetSize.Medium;

    get valuesSum() {
        return this._valuesSum;
    }

    // Chart-related
    @ViewChild(ClChartComponent, { static: true }) chart?: ClChartComponent;

    ngAfterViewInit(): void {
        if (!this.chart) {
            throw new Error('<cyberloop-chart> component should be present');
        }
    }

    ngOnChanges(changes: SimpleChangesOf<RigActivityWidgetComponent>): void {
        if (changes.id?.currentValue) {
            this._id$.next(changes.id.currentValue);
        }
    }

    async onSettings(): Promise<void> {
        await this.settings.showSettings<RigActivityWidgetSettings>(
            this.id,
            SettingsComponent,
            rigActivityDefaultSettings
        );
    }

    onDelete(): void {
        this.store.dispatch(KpiActions.deleteWidget({ widgetId: this.id }));
    }

    private updateData(): void {
        // Clear current data
        const tableRows: TableItem[] = [];

        const data = this._rigActivityData.concat([]);

        // First calculate values sum
        this._valuesSum = data.reduce((sum, item) => {
            return sum + this.getConvertedValue(item.duration);
        }, 0);

        const range = this._timeRangeMoments
            ? this.getConvertedValue(this._timeRangeMoments.until.diff(this._timeRangeMoments.since, 's'))
            : 0;

        if (this._valuesSum < range) {
            // Add unknown activity

            const unknownIdx = data.findIndex(item => item.activity === RigActivity.Unknown);

            let item: KpiRigActivityData;

            if (unknownIdx >= 0) {
                item = data[unknownIdx];
            }
            else {
                item = {
                    activity: RigActivity.Unknown,
                    duration: 0
                };
            }

            item.duration += this.getSiValue(range - this._valuesSum);
            this._valuesSum += (range - this._valuesSum);

            if (unknownIdx <= 0) {
                data.unshift(item);
            }
        }

        const chartPoints: Highcharts.PointOptionsObject[] = [];

        // Then build items and chart data
        for (const item of data) {
            const duration = this.getConvertedValue(item.duration);

            // Add table row
            tableRows.push({
                className: `ra-${RigActivity.getId(item.activity)}`,
                activity: RigActivity.getName(item.activity),
                value: duration,
                unit: this._timeUnit?.label ?? '',
                percent: duration / this._valuesSum * 100
            });

            // Add chart value
            chartPoints.push({
                id: RigActivity.getId(item.activity),
                name: RigActivity.getName(item.activity),
                y: duration,
                custom: {
                    unit: this._timeUnit?.label
                },
                className: `ra-${RigActivity.getId(item.activity)}`
            });
        }

        this.chart?.setSeriesData('main', chartPoints);
        this._tableRows$.next(tableRows);
    }

    private getChartOptions(): Highcharts.Options {
        return {
            chart: {
                type: 'pie',
                margin: 0,
                spacing: [0, 0, 0, 0]
            },
            plotOptions: {
                pie: {
                    size: '100%',
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                valueDecimals: 2,
                padding: 0,
                useHTML: true,
                formatter: function () {
                    return formatTooltipWithRigData.call(this, 'rig-activity');
                }
            },
            series: [{
                id: 'main',
                name: 'Main',
                type: 'pie',
                colorByPoint: true,
                data: []
            }]
        };
    }

    private getConvertedValue(source: number): number {
        return this._timeUnit
            ? this.unitsConverter.convertToUnit(source, this._timeUnit)
            : source;
    }
    private getSiValue(converted: number): number {
        return this._timeUnit
            ? this.unitsConverter.convertToSi(converted, this._timeUnit)
            : converted;
    }

}
