<header>
  {{ title }}
</header>
<main>
  <section>
    <div class="title">12 hours</div>
    <div class="value-row">
      <span class="value">{{ value12 | formatNumber | noData }}</span>
      <span class="unit-label">{{ unitLabel }}</span>
    </div>
  </section>
  <div class="divider"></div>
  <section>
    <div class="title">24 hours</div>
    <div class="value-row">
      <span class="value">{{ value24 | formatNumber | noData }}</span>
      <span class="unit-label">{{ unitLabel }}</span>
    </div>
  </section>
</main>