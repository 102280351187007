import { Injectable } from '@angular/core';

import { CoreSelectors, LiveData, LiveDataProviderService, UnitDescriptor, UnitGroup, UnitsConverterService } from '@cyberloop/core';
import { AssetsValueProvider } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, map, switchMap } from 'rxjs';

@Injectable()
export class AssetsValueProviderService extends AssetsValueProvider {
    /**
     *
     */
    constructor(
        private readonly liveData: LiveDataProviderService,
        private readonly store: Store,
        private readonly unitConverter: UnitsConverterService
    ) {
        super();
    }
    
    getValue(assetId: string, unitGroup: UnitGroup['id'], unitId?: UnitDescriptor['id']): Observable<LiveData> {
        return this.store.select(CoreSelectors.currentWell).pipe(
            switchMap(well => this.store.select(CoreSelectors.units.getUnit(unitGroup, unitId)).pipe(
                map(unit => ({ well, unit }))
            )),
            switchMap(({ well, unit }) => {
                if (!well) {
                    return EMPTY;
                }
                return this.liveData.getForRigAndTag(well.rig, assetId).pipe(
                    map(asset => unit ? ({ ...asset, value: this.unitConverter.convertToUnit(asset.value, unit) }) : asset)
                );
            })
        );
    }

}