import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'cyberloop-dd-dashboard-skeleton',
    standalone: true,
    templateUrl: './dd-dashboard-skeleton.component.html',
    styleUrls: ['../dd-dashboard/dd-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DdDashboardSkeletonComponent {

}
