<div class="logo">
    <svg width="348" height="41">
        <path
            d="M32.3468 5.28702 C27.6764 4.4835 17.3712 3.41883 13.5142 4.38306 C8.69309 5.58835 4.32393 8.90287 4.02261 19.2985 C3.72129 29.6941 6.40828 34.4569 13.5142 36.3232C19.4804 37.89 27.5257 36.3734 32.9495 35.4192"
            class="logo-letter-c" fill="none" />
        <path d="M 76 -2 L 59 25 L 59 41 L 59 25 L 41 -2" class="logo-letter-y" fill="none"></path>
        <path
            d="M 86 0 L 86 37 L 104 37 A 8 8, 0, 0, 0, 113, 31 L 113 27 A 8 8, 0, 0, 0, 104 20 L 86 20 L 104 20 A 8 8, 0, 0, 0, 113,13 L 113 10 A 8 8, 0, 0, 0, 104, 4 L 86 4"
            class="logo-letter-b" fill="none"></path>
        <path
            d="M 152 37 L 132 37 A 8 8, 0, 0, 1, 125,31 L 125 27 A 8 8, 0, 0, 1, 134, 20 L 152 20 L 134 20 A 8 8, 0, 0, 1, 125,13 L 125 10 A 8 8, 0, 0, 1, 134, 4 L 152 4"
            class="logo-letter-e" fill="none"></path>

        <path
            d="M 155 4 L 181 4 A 8 8, 0, 0, 1, 189, 11 L 189, 16 A 8 8, 0, 0, 1, 181, 23 L 166 23 A 8 8, 0, 0, 0, 160, 29 L 160 41"
            class="logo-letter-r-1" fill="none"></path>

        <path d="M 155 4 L 181 4 A 8 8, 0, 0, 1, 189, 11 L 189, 16 A 8 8, 0, 0, 1, 181, 23 L 179 23 L 186 42"
            class="logo-letter-r-2" fill="none"></path>
        <path d="M 200 0 L 200 34 A 4 4, 0, 0, 0, 204, 37 L 226 37" class="logo-letter-l" fill="none"></path>
        <path
            d="M 241 37 L 250 37 A 12 12, 0, 0, 0, 262, 27 L 262 15 A 12 12, 0, 0, 0, 250, 4 L 241 4 A 12 12, 0, 0, 0, 232, 15 L 232 27 A 12 12, 0, 0, 0, 242, 37"
            class="logo-letter-o-1" fill="none"></path>
        <path
            d="M 282 37 L 291 37 A 12 12, 0, 0, 0, 303, 27 L 303 15 A 12 12, 0, 0, 0, 291, 4 L 282 4 A 12 12, 0, 0, 0, 273, 15 L 273 27 A 12 12, 0, 0, 0, 283, 37"
            class="logo-letter-o-2" fill="none"></path>
        <path
            d="M 309 4 L 335 4 A 8 8, 0, 0, 1, 343, 11 L 343, 16 A 8 8, 0, 0, 1, 335, 23 L 322 23 A 8 8, 0, 0, 0, 315, 29 L 315 41"
            class="logo-letter-p" fill="none"></path>
    </svg>
</div>

<span class="name">{{name}}</span>