import { UnitDescriptor, WellKnownUnitGroupIds } from '@cyberloop/core';

import { AssetDailyMetricsWidgetDataItemKey } from './widget-data';

export type AssetMetric = {
    title: string;
    tag: AssetDailyMetricsWidgetDataItemKey;
}

export type AssetMetricWithUnit = AssetMetric & {
    unitGroupId: WellKnownUnitGroupIds;
}

export type AssetMetricWithData = AssetMetric & {
    value12: number | null,
    value24: number | null,
    unit: UnitDescriptor | undefined,
    unitLabel: string | undefined
}

export const AssetMetrics: AssetMetricWithUnit[] = [
    {
        title: 'Meters drilled',
        tag: 'metersDrilledTotal',
        unitGroupId: WellKnownUnitGroupIds.Length
    },
    {
        title: 'Meters drilled in Rotary',
        tag: 'metersDrilledRotary',
        unitGroupId: WellKnownUnitGroupIds.Length
    },
    {
        title: 'Meters drilled in Slide',
        tag: 'metersDrilledSlide',
        unitGroupId: WellKnownUnitGroupIds.Length
    },
    {
        title: '% in Slide mode (meters)',
        tag: 'slideMetersPercent',
        unitGroupId: WellKnownUnitGroupIds.Percentage
    },
    {
        title: '% in Slide mode (time)',
        tag: 'slideTimePercent',
        unitGroupId: WellKnownUnitGroupIds.Percentage
    },
    {
        title: 'On Bottom % (time)',
        tag: 'onBottomTimePercent',
        unitGroupId: WellKnownUnitGroupIds.Percentage
    },
    {
        title: 'Avg ROP rotary',
        tag: 'ropRotaryAvg',
        unitGroupId: WellKnownUnitGroupIds.Speed
    },
    {
        title: 'Avg ROP slide',
        tag: 'ropSlideAvg',
        unitGroupId: WellKnownUnitGroupIds.Speed
    },
    {
        title: 'Weight to Weight time',
        tag: 'w2wTime',
        unitGroupId: WellKnownUnitGroupIds.Time
    },
    {
        title: 'Slips to Slips time',
        tag: 's2sTime',
        unitGroupId: WellKnownUnitGroupIds.Time
    }
];