<cyberloop-kpi-widget heading="Slide vs Rotary" [size]="size" class="widget" (settingsRequested)="onSettings()" (deleteRequested)="onDelete()">
    <div widget-header>
        <cl-button-toggle
            [list]="modes"
            [disabled]="(dataLoading$ | async) ?? true"
            [selected]="selectedMode$ | async"
            (selectedChange)="onModeChange($event)"
            class="range-selector"
        ></cl-button-toggle>
    </div>
    <div widget-body class="chart-container" [class.loading]="dataLoading$ | async">
        <cyberloop-chart
            [options]="options"
            class="chart"
        ></cyberloop-chart>
        <div class="placeholder" *ngIf="dataLoading$ | async">
            <div *ngFor="let column of placeholderColumns" class="column" [style.height]="(column * 10) + '%'"></div>
        </div>
    </div>
</cyberloop-kpi-widget>
