import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { AssetsInfoProviderService, ExportDataService, GlobalAlarmSettingsService, LiveDataProviderService, RangePickerDataService, ReportingProviderService, RigInfoProviderService, TagDataProviderService, WellInfoProviderService } from '@cyberloop/core';
import { TndDataProvider, TndModelProvider } from '@cyberloop/web/tnd/data';
import { KpiDataService, SurveyDataProviderSerivce, TFArrayProviderSerivce, WellPlansProviderSerivce } from '@cyberloop/web/wells/data';

import { getGqlProviders } from './internals/gql-providers';
import { GraphQLLinkOptions } from './internals/options.token';
import { AssetsInfoProviderServiceImpl } from './services/assets-info-provider.service.impl';
import { ExportDataServiceImpl } from './services/export-data-provider.service.imp';
import { GlobalAlarmSettingsServiceImpl } from './services/global-alarm-settings-provider.service.impl';
import { KpiDataServiceGraphQL } from './services/kpi-data.service.imp';
import { LiveDataProviderServiceImpl } from './services/live-data-provider.service.impl';
import { RangePickerDataServiceGraphQL } from './services/range-picker-data.service.impl';
import { ReportingProviderServiceImpl } from './services/reporting-provider.service.impl';
import { RigInfoProviderServiceImpl } from './services/rig-info-provider.service.impl';
import { SurveyDataProviderServiceImpl } from './services/survey-data-provider.service.impl';
import { TagDataProviderServiceImpl } from './services/tag-data-provider.service.impl';
import { TFArrayProviderServiceImpl } from './services/tf-array-provider.service.impl';
import { TndDataProviderServiceGraphQL } from './services/tnd-data-provider.service.impl';
import { TndModelProviderServiceGraphQL } from './services/tnd-model-provider.service.impl';
import { WellInfoProviderServiceImpl } from './services/well-info-provider.service.impl';
import { WellPlansProviderSerivceImpl } from './services/well-plans-provider.service.impl';

export function getProviders(opts: GraphQLLinkOptions): EnvironmentProviders[] {
    return [
        ...getGqlProviders(opts),
        makeEnvironmentProviders([
            { provide: ExportDataService, useClass: ExportDataServiceImpl },
            { provide: LiveDataProviderService, useClass: LiveDataProviderServiceImpl },
            { provide: RigInfoProviderService, useClass: RigInfoProviderServiceImpl },
            { provide: WellInfoProviderService, useClass: WellInfoProviderServiceImpl },
            { provide: AssetsInfoProviderService, useClass: AssetsInfoProviderServiceImpl },
            { provide: TagDataProviderService, useClass: TagDataProviderServiceImpl },
            { provide: GlobalAlarmSettingsService, useClass: GlobalAlarmSettingsServiceImpl },
            { provide: TndDataProvider, useClass: TndDataProviderServiceGraphQL },
            { provide: TndModelProvider, useClass: TndModelProviderServiceGraphQL },
            { provide: KpiDataService, useClass: KpiDataServiceGraphQL },
            { provide: ReportingProviderService, useClass: ReportingProviderServiceImpl },
            { provide: SurveyDataProviderSerivce, useClass: SurveyDataProviderServiceImpl },
            { provide: TFArrayProviderSerivce, useClass: TFArrayProviderServiceImpl },
            { provide: WellPlansProviderSerivce, useClass: WellPlansProviderSerivceImpl },
            { provide: RangePickerDataService, useClass: RangePickerDataServiceGraphQL }
        ])
    ];
}
