import type { SubNavigationItem } from '@cyberloop/web/app/model';

export enum SinglePageNavigation {
    Drilling = 'drilling',
    Dd = 'dd',
    Forecast = 'forecast',
    Co2 = 'co2',
    Files = 'files',
    Risk = 'risk',
    Meterage = 'meterage',
    Engineering = 'engineering',
    Kpi = 'kpi',
    Plan = 'plan'
}

export namespace SinglePageNavigation {
    export function getTitle(item: SinglePageNavigation): string {
        switch (item) {
            case SinglePageNavigation.Drilling: return 'Drilling';
            case SinglePageNavigation.Dd: return 'Directional Drilling';
            case SinglePageNavigation.Forecast: return 'Forecast';
            case SinglePageNavigation.Co2: return 'CO2';
            case SinglePageNavigation.Files: return 'Files';
            case SinglePageNavigation.Risk: return 'Risk Register';
            case SinglePageNavigation.Meterage: return 'Meterage';
            case SinglePageNavigation.Engineering: return 'Engineering';
            case SinglePageNavigation.Kpi: return 'Kpi';
            case SinglePageNavigation.Plan: return 'Plan';
        }
    }

    export function getSubnavigation(item: SinglePageNavigation): SubNavigationItem[] {
        switch (item) {
            case SinglePageNavigation.Dd: 
                return Object.values(DdPageNavigation).map(ddItem => ({link: ddItem.toString(), text: DdPageNavigationFunctions.getTitle(ddItem)}));
            default: return [];
        }
    }
}

export enum DdPageNavigation {
    Dashboard = 'dashboard',
    Charts = 'charts',
    Charts3D = '3d-charts',
    //DataLogs = 'data-logs',
    SlideSheet = 'slidesheet',
    WellPlan = 'wellplan',
    //ImageLog = 'image - log',
    //Reports = 'reports',
}

export namespace DdPageNavigationFunctions {
    export function getTitle(item: DdPageNavigation): string {
        switch (item) {
            case DdPageNavigation.Charts3D: return 'Charts 3D';
            case DdPageNavigation.SlideSheet: return 'Slide sheet';
            case DdPageNavigation.WellPlan: return 'Well plan';
            default: return item;
        }
    }
}

export enum EngineeringPageNavigation {
    TnD = 'tnd'
}
