import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ClCheckboxGroupComponent, ClSelectorComponent, ClSelectorOptions, IconComponent, Point, ScrollShadowDirective, Section } from '@cyberloop/core';

import { DdChartComponent } from '../dd-chart/dd-chart.component';

@Component({
    selector: 'cyberloop-dd-charts',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        ClCheckboxGroupComponent,
        IconComponent,
        DdChartComponent,
        MatTooltipModule,
        ClSelectorComponent,
        ScrollShadowDirective
    ],
    templateUrl: './dd-charts.component.html',
    styleUrls: ['./dd-charts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DdChartsComponent {

    constructor() { }

    @Input() sections: Section[] | null = [];
    @Input() chartTypes: any[] = [];
    @Input() selectedChartType = '';
    @Input() wellPlansOptions: ClSelectorOptions[] = [];
    @Input() seriesColors: string[] = [];
    @Input() selectedSections: number[] = [];
    @Input() selectedWellPlan: string | null | undefined = null;
    @Input() skeleton: boolean | null = null;

    @Input() topChartData: Point[][] | null = null;
    @Input() topChartXAxisTitle = '';
    @Input() topChartYAxisTitle = '';

    @Input() bottomChartData: Point[][] | null = null;
    @Input() bottomChartXAxisTitle = '';
    @Input() bottomChartYAxisTitle = '';

    @Output() sectionSelect = new EventEmitter<number[]>();
    @Output() typeSelect = new EventEmitter<any[]>();
    @Output() planSelect = new EventEmitter<string>();

    get indexesOfSelectedSections() {
        const arrayOfIndexes = this.sections?.reduce((acc: number[], section, index) => {
            if(this.selectedSections.includes(section.id)) {
                acc.push(index + 1); //+1 because series[0] is well plan, series[1] is first section data series
            }
            return acc;
        }, []);

        return arrayOfIndexes ?? null;
    }
}
