import { Injectable } from '@angular/core';

import { UnitGroup, Well } from '@cyberloop/core';

import { GraphQLQuery } from '../internals/cl-gql';

export type TagItemResponse = {
    id: string;
    name: string;
    minValue: number;
    maxValue: number;
    description: string;
    unitGroupId: UnitGroup['id']
    depthTracked: boolean;
};

type QueryResponse = {
    rigs: {
        name: string;
        wells: {
            id: Well['id'];
            name: string;
            startedAt: string;
            spuddedAt: string | null;
            releasedAt: string | null;
            suspendedAt: string | null;
            location: {
                latitude: number;
                longitude: number;
            }
            sections: {
                name: string;
                number: number;
                parent: {
                    number: number
                };
                startedAt: string;
                startDepth: number;
                endedAt: string | null;
                endDepth: number | null
            }[]
            stats: {
                today: {
                    rotary: number;
                    slide: number;
                    onBottom: number;
                    bitDepth: number;
                    holeDepth: number;
                    averageROP: number;
                    rigActivity: number;
                }
                overall: {
                    rotary: number;
                    slide: number;
                    onBottom: number;
                }
            }
        }[],
        tags: TagItemResponse[]
    }[]
}

@Injectable({
    providedIn: 'root'
})
export class RigsMetaQuery extends GraphQLQuery<QueryResponse> {
    override readonly document = `{
    rigs {
        name
        wells {
            id
            name
            startedAt
            spuddedAt
            releasedAt
            suspendedAt
            location {
                latitude
                longitude
            }
            sections {
                name
                number
                parent {
                    number
                }
                startedAt
                startDepth
                endedAt
                endDepth
            }
            stats {
                overall {
                  rotary
                  slide
                  onBottom
                }
                today {
                  rotary
                  slide
                  onBottom
                  bitDepth
                  holeDepth
                  averageROP
                  rigActivity
                }
            }
        }
        tags {
            id
            name
            minValue
            maxValue
            description
            unitGroupId
            depthTracked
        }
    }
}`;
}
