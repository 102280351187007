import { Injectable } from '@angular/core';

import { ID } from '@cyberloop/core';
import { MeterageDataService } from '@cyberloop/web/app/data';
import { Meterage, MeterageItem } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { CoreSelectors } from 'libs/core/src/lib/state/core.selectors';
import { isNil } from 'lodash';
import { Observable, first, firstValueFrom, map, switchMap } from 'rxjs';

import * as moment from 'moment';

@Injectable({
    // This should be the Singleton
    providedIn: 'root'
})
export class MeterageService {
    constructor(
        private readonly store: Store,
        private readonly _dataService: MeterageDataService
    ) { }

    getMeterage(id: string, watch = false): Observable<Meterage> {
        let watchResult = this._dataService.watchById(id);

        if (!watch) {
            watchResult = watchResult.pipe(first());
        }

        return watchResult.pipe(
            switchMap(async (meterage) => {
                const sections = await firstValueFrom(this.store.select(CoreSelectors.sectionsOfWell(id)));

                if (isNil(meterage)) {
                    meterage = {
                        id,
                        itemList: [],
                        updatedAt: moment.now()
                    };
                }

                if (Array.isArray(sections)) {
                    const itemList = meterage.itemList;
                    const runLenght = itemList[0]?.runs?.length || 0;

                    sections.filter(Boolean).forEach(section => {
                        const item = itemList.find((d) => d.id === section.id);

                        if (!item) {
                            itemList.push({
                                id: section.id,
                                section: section.name,
                                runs: new Array(runLenght).fill(undefined),
                                ncr: [],
                                process: '',
                                reason: '',
                                resolution: '',
                                unplanned: false,
                                group: null
                            });
                        }
                    });
                }

                return meterage;
            })
        );
    }

    addRun(id: string) {
        return this.getMeterage(id).pipe(
            switchMap((meterage) => {
                meterage.itemList.forEach(item => item.runs.push(null));
                return this.setData(id, meterage.itemList).pipe(
                    map(() => meterage)
                );
            })
        );
    }

    removeLastRun(id: ID) {
        return this.getMeterage(id).pipe(
            switchMap((meterage) => {
                meterage.itemList.forEach(item => item.runs.pop());
                return this.setData(id, meterage.itemList);
            })
        );
    }

    updateMeterage(id: ID, itemList: MeterageItem[]) {
        return this.setData(id, itemList);
    }

    private setData(wellId: ID, itemList: MeterageItem[]) {
        return this._dataService.saveMeterage(wellId, itemList).pipe(first());
    }

}
