import { Injectable } from '@angular/core';

import { AssetId, CoreSelectors, DataCompressorService, LiveData, LiveDataProviderService, Points, Range, RigState, getDepthSlices, getTimeSlices } from '@cyberloop/core';
import { PSEUDO_ASSET } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { isEqual, isNil } from 'lodash';
import { BehaviorSubject, EMPTY, Observable, Subscription, combineLatest, debounceTime, distinctUntilChanged, filter, from, map, of, shareReplay, startWith, switchMap } from 'rxjs';

import { DrillingActions, DrillingSelectors } from '../../state/drilling';
import { BatchedDataRequestService } from '../batched-data-request.service';
import { IndexedDbProviderService } from './indexedDb.provider.service';

@Injectable({
    providedIn: 'root'
})
export class PointsStorageService {
    private readonly _storages: Record<AssetId, Storage> = {};
    private readonly _tagsSubj = new BehaviorSubject<string[]>([PSEUDO_ASSET]);
    /**
     *
     */
    // TODO add well id to ctr
    constructor(
        private readonly batch: BatchedDataRequestService,
        private readonly compressor: DataCompressorService,
        private readonly liveData: LiveDataProviderService,
        private readonly store: Store,
        private readonly indexedDb: IndexedDbProviderService
    ) {

    }

    forTagAndWell(tagId: AssetId, wellId: string, isLive: boolean, sectionId: string | undefined): Storage {
        tagId = tagId.toUpperCase();
        const storageKey = this.getStorageKey(wellId, tagId, sectionId);

        return this._storages[storageKey] ??= (() => {
            this._tagsSubj.next([...this._tagsSubj.value, tagId]); // FIXME Need to pass wellId
            return new Storage(wellId, tagId, this.batch, this.compressor, this.liveData, this.store, this.indexedDb);
        })();
    }

    resetCache() {
        Object.keys(this._storages).forEach(key => {
            for (const sub of this._storages[key].sub) {
                sub.unsubscribe();
            }
            delete this._storages[key];
        });
        console.warn('Cache reseted ', this._storages);
    }

    resetCurrentTags() {
        this._tagsSubj.next([PSEUDO_ASSET]);
        console.warn('Current tags reseted ', this._tagsSubj.value);
    }

    private getStorageKey(wellId: string, tagId: string, sectionId: string | undefined) {
        return wellId + '-' + tagId + (sectionId ? ' - ' + sectionId : '');
    }
}

const secondsPerBatch = 3 * 60 * 60; // 3hours
const metersPerBatch = 100;

class Storage {
    private readonly _storage = new Map<number, Observable<Points>>();
    private readonly _liveDepthSubj = new BehaviorSubject<Points>([]);
    private readonly _liveTimeSubj = new BehaviorSubject<Points>([]);
    private readonly _liveSubj = new BehaviorSubject<Points>([]);
    private _timeOut: ReturnType<typeof setTimeout> | undefined;
    private _emergencyTimeOut: ReturnType<typeof setTimeout> | undefined;
    private _liveSub = false;
    private _sub: Subscription[] = Array(4);
    private _isWellLive = false;
    private _syncronized = false;

    // private readonly _newPoints = new BehaviorSubject<Points>([]);
    /**
     * @internal()
     */
    constructor(
        private readonly wellId: string,
        private readonly tagId: AssetId,
        private readonly batch: BatchedDataRequestService,
        private readonly compressor: DataCompressorService,
        private readonly liveData: LiveDataProviderService,
        private readonly store: Store,
        private readonly indexedDb: IndexedDbProviderService
    ) {
        this.store.select(DrillingSelectors.liveSubscriptionsStatus).pipe(
            distinctUntilChanged()
        ).subscribe(status => {
            this._liveSub = status;
        });

        this._sub[0] = this.subToLive();

        this._sub[1] = this._liveTimeSubj.pipe(
            switchMap(ppts => combineLatest([
                this.store.select(DrillingSelectors.live),
                this.store.select(DrillingSelectors.isTime)
            ]).pipe(map(([live, isTime]) => ({ live, isTime, ppts }))
            ))
        ).subscribe(({ live, isTime, ppts }) => {
            if (this._liveSub && live && isTime) {
                this._liveSubj.next([...ppts]);
                this.store.dispatch(DrillingActions.changeLiveViewport({ reset: false }));
            }
        });

        this._sub[2] = this._liveDepthSubj.pipe(
            switchMap(() => combineLatest([
                this.store.select(DrillingSelectors.liveSection),
                this.store.select(DrillingSelectors.isTime)
            ]).pipe(map(([live, isTime]) => ({ live, isTime }))
            ))
        ).subscribe(({ live, isTime }) => {
            if (this._liveSub && live && !isTime) {
                this._liveSubj.next([...this._liveDepthSubj.value]);
                this.store.dispatch(DrillingActions.changeLiveViewport({ reset: false }));
            }
        });

        this._sub[3] = this.store.select(DrillingSelectors.chartsLoading).pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(loading => {
            this._liveSub = !loading;
            if (!loading) {
                this._timeOut && clearTimeout(this._timeOut);
                this._emergencyTimeOut && clearTimeout(this._emergencyTimeOut);
            }
        });
    }

    get storage() {
        return this._storage;
    }

    get sub() {
        return this._sub;
    }

    /**
     * Get data for range
     * @param range range to get data for
     * @returns Points
     */
    getFor(range: Range | undefined, navi: Partial<Range> | undefined, sectionId?: string): Observable<Points> {
        // console.warn('@@ SLICES', getTimeSlices(range.from, range.to, secondsPerBatch).map(x =>
        //     moment.unix(x).toISOString()
        // ));
        if (!range) {
            return EMPTY;
        }

        let req;

        if (sectionId) {
            const duration = 2 * (range.to - range.from);
            const depthPieces = getDepthSlices(range.from - duration, range.to, metersPerBatch);
            const lastIdx = depthPieces.length - 1;

            req = combineLatest(
                depthPieces.map((piece, idx) => (this._storage.get(piece) ?? of(undefined as any))
                    .pipe(
                        switchMap(existing => {
                            if (!existing) {
                                return from(this.indexedDb.get(this.wellId, this.tagId, piece));
                            }

                            return of(existing);
                        }),
                        switchMap(existing => {
                            let data: Observable<Points>;
                            if (existing) {
                                data = of(existing as Points);
                                if (!this._storage.has(piece)) {
                                    this._storage.set(piece, data);
                                }
                            }
                            else {
                                data = this.buildDataFetcherFor(this.wellId, piece, sectionId);
                                this._storage.set(piece, data as Observable<Points>);
                                if (/*this._isWellLive && */lastIdx !== idx) { // FIXME Should works only for live well
                                    this.indexedDb.set(this.wellId, this.tagId, piece, data as Observable<Points>);
                                }
                            }

                            return data;
                        })
                    ))
            );
        }
        else {
            const duration = 3 * (range.to - range.from);
            const timePieces = getTimeSlices(range.from - duration, range.to, secondsPerBatch);
            const lastIdx = timePieces.length - 1;

            req = combineLatest(
                timePieces.map((piece, idx) => (this._storage.get(piece) ?? of(undefined as any))
                    .pipe(
                        switchMap(existing => {
                            if (!existing) {
                                return from(this.indexedDb.get(this.wellId, this.tagId, piece));
                            }

                            return of(existing);
                        }),
                        switchMap(existing => {
                            let data: Observable<Points>;
                            if (existing) {
                                data = of(existing as Points);
                                if (!this._storage.has(piece)) {
                                    this._storage.set(piece, data);
                                }
                            }
                            else {
                                data = this.buildDataFetcherFor(this.wellId, piece, sectionId);
                                this._storage.set(piece, data as Observable<Points>);
                                if (/*this._isWellLive && */lastIdx !== idx) { // FIXME Should works only for live well
                                    this.indexedDb.set(this.wellId, this.tagId, piece, data as Observable<Points>);
                                }
                            }

                            return data;
                        })
                    ))
            );
        }

        return req.pipe(
            map(ppts => {
                // console.warn('ALL data got REQUEST for tag ', this.tagId, ', points:', ppts);
                const points: Points = [];
                ppts.forEach(pts => points.push(...pts));

                const duration = range.to - range.from;
                const filtered = points.filter(x => x.x >= (range.from - duration * 1.2) && x.x <= (range.to + duration * 1.2));
                const compressed = this.compressor.processPoints(filtered, 900);

                if (compressed[0]?.x !== range.from && compressed.length === 0) {
                    compressed.unshift({ x: range.from, y: compressed[0]?.y });
                    compressed.push({ x: range.to, y: compressed[compressed.length - 1]?.y });
                }
                // if (compressed[compressed.length - 1]?.x !== range.to) {
                //     compressed.push({ x: range.to, y: compressed[compressed.length - 1]?.y }); // FIXME Hump
                // }

                if (compressed.length === 2) {
                    compressed.splice(1, 0, { x: range.from + (range.to - range.from) / 10, y: null });
                }
                else {
                    this.setLoading(-1);
                }

                if (navi && !isNil(navi?.from)) {
                    compressed.unshift({ x: navi.from - (sectionId ? 0 : 1000), y: null });
                    // compressed.push({ x: navi.to ?? Date.now(), y: compressed[compressed.length - 1]?.y }); // FIXME Hump
                }
                else {
                    console.warn('Pan disabled');
                }

                // 🩼 Need to add live to cache
                const liveTime = this._liveTimeSubj.value.filter(x => x.x > range.from && x.x < range.to);
                const liveDepth = this._liveDepthSubj.value.filter(x => x.x > range.from && x.x < range.to);

                return [...compressed, ...liveTime, ...liveDepth];
            }),
            map(ppts => [...ppts/*...this._liveSubj.value*/].sort((a, b) => a.x - b.x))
        );
    }

    private subToLive(): Subscription {
        return this.store.select(CoreSelectors.getWellById(this.wellId)).pipe(
            filter(Boolean),
            switchMap(well => {
                if (!well?.rig || well.releaseTime || well.suspendTime) {
                    this._isWellLive = false;
                    return EMPTY;
                }

                this._isWellLive = true;

                return this.liveData.getForRigAndTag(well.rig, this.tagId).pipe(
                    distinctUntilChanged(isEqual)
                );
            }),
            map(point => {
                this.addNewPoints(point);
            })
        ).subscribe();
    }

    /**
     *
     * @param wellId
     * @param from Start of interval in SECONDS
     * @returns
     */
    private buildDataFetcherFor(wellId: string, from: number, sectionId?: string): Observable<Points> {
        let to;

        if (sectionId) {
            to = from + metersPerBatch;
        }
        else {
            to = (from + secondsPerBatch) * 1000;
            from *= 1000;
        }

        const defaultRange = [
            { x: from, y: null },
            { x: to, y: null }
        ];

        if (this.tagId === PSEUDO_ASSET) {
            return of(defaultRange);
        }

        return this.batch.getDataFor(wellId, this.tagId, { from, to }, sectionId).pipe(
            startWith(defaultRange),
            shareReplay(1)
        );
    }

    private addNewPoints(point?: LiveData): void {
        if (!point) {
            return;
        }

        const time = new Date(point.time).getTime();
        const depth = point.bitDepth;
        const value = point.value;
        const rst = point.rigState;
        const valueDepth = { x: depth, y: value };
        const valueTime = { x: time, y: value };

        const lastPointDepth = this._liveDepthSubj.value[this._liveDepthSubj.value.length - 1];
        const lastPointTime = this._liveTimeSubj.value[this._liveDepthSubj.value.length - 1];

        if ((rst === RigState.DrillingSlideDrilling || rst === RigState.DrillingRotaryDrilling) &&
            (!lastPointDepth || depth > lastPointDepth.x)) {
            const oldValue = this._liveDepthSubj.value;
            this._liveDepthSubj.next([...oldValue, valueDepth]);
        }

        if (!lastPointTime || valueTime.x > lastPointTime.x) {
            const oldValue = this._liveTimeSubj.value;
            this._liveTimeSubj.next([...oldValue, valueTime]);
        }
    }

    private setLoading(num: number) {
        if (this._liveSub) {
            return;
        }

        this.store.dispatch(DrillingActions.changeChartsLoading({ num }));

        this._timeOut && clearTimeout(this._timeOut);
        this._timeOut = setTimeout(() => {
            this.store.dispatch(DrillingActions.changeChartsLoading({ num: -1 }));
        }, 15000);

        this._emergencyTimeOut && clearTimeout(this._emergencyTimeOut);
        this._emergencyTimeOut = setTimeout(() => {
            this.store.dispatch(DrillingActions.resetChartsLoading());
        }, 20000);
    }
}
