import { Observable } from 'rxjs';

import { GraphQLCore } from './core';
import { SubscriptionOptions, SubscriptionResult } from './models';

export abstract class GraphQLSubscription<TResult = never, TVariables = never>
    extends GraphQLCore {

    subscribe(variables: TVariables): Observable<SubscriptionResult<TResult>> {
        const o: SubscriptionOptions<TVariables> = {
            query: this.document,
            variables
        } as any;

        return this.cli.subscribe(o);
    }
}
