import { Injectable } from '@angular/core';

import { RigActivity, RigState } from '@cyberloop/core';

import { GraphQLSubscription } from '../internals/cl-gql';

export type LiveDataPackage = {
    tagValues: {
        time: string;
        bitDepth: number;
        holeDepth: number;
        rigState: RigState;
        rigActivity: RigActivity;
        values: {
            tagName: string;
            value: number;
        }[]
    }
};

@Injectable({
    providedIn: 'root'
})
export class LiveDataSubscription extends GraphQLSubscription<LiveDataPackage, { rigName: string }> {
    override document = `subscription liveData($rigName: String!) {
    tagValues(rigName: $rigName) {
        time
        bitDepth
        holeDepth
        rigState
        rigActivity
        values {
            tagName
            value
        }
    }
}`;
}