import { ID } from '@cyberloop/core';
import { Planning } from '@cyberloop/web/planning/shared/model';
import { EntityState, createEntityAdapter } from '@ngrx/entity';


export type LoadingState = 'NOT_LOADED' | 'LOADED' | 'LOADING';

export const PLANNING_FEATURE = 'PLANNING';

/**
 * State for planning
 * */
export interface PlanningState extends EntityState<Planning> {
    listLoadingState: LoadingState;
    listError?: Error;

    planningAddLoadingState: LoadingState;
    planningAddError?: Error;

    planningUpdateLoadingState: LoadingState;
    planningUpdateError?: Error;

    planningId?: ID;
    planningLoadingState: LoadingState;
    planningLoadError?: Error;

    selectVersionLoading: boolean;
    selectVersionError?: Error;
}

export const adapter = createEntityAdapter<Planning>({
    sortComparer: (a, b) => b.updatedAt.valueOf() - a.updatedAt.valueOf()
});

export const initialState: PlanningState = adapter.getInitialState({
    listLoadingState: 'NOT_LOADED',
    planningAddLoadingState: 'NOT_LOADED',
    planningUpdateLoadingState: 'NOT_LOADED',
    planningLoadingState: 'NOT_LOADED',
    selectVersionLoading: false
});
