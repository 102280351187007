import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { IconComponent, NoDataPipe, Section } from '@cyberloop/core';
import { RecentItemsDirective } from '@cyberloop/web/app/data';
import { NavigationItem } from '@cyberloop/web/app/model';
import { DashboardSettings } from '@cyberloop/web/wells/model';

import { SectionsInfoComponent } from '../sections-info/sections-info.component';

@Component({
    selector: 'cyberloop-single-well',
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        NgFor,
        RouterModule,
        IconComponent,
        NoDataPipe,
        RecentItemsDirective,
        IconComponent,
        MatMenuModule,
        SectionsInfoComponent
    ],
    templateUrl: './single-well.component.html',
    styleUrls: ['./single-well.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleWellComponent {

    @Input() wellName: string | null = null;

    @Input() navigationItems: NavigationItem[] = [];
    @Input() activatedRoute: NavigationItem | undefined;

    @Input() sectionId: number | null | undefined;
    @Input() sectionName: string | null | undefined;
    @Input() sections: Section[] | null | undefined;

    @Input() isWellActive: boolean | null | undefined;

    @Input() dashboards: DashboardSettings[] | null = [];
    @Input() activeDashboard: string | null | undefined;

    @Output() readonly toListOfWells = new EventEmitter<void>();
    @Output() readonly selectSection = new EventEmitter<number>();

    @Output() readonly selectDashboard = new EventEmitter<string>();
    @Output() readonly addDashboard = new EventEmitter<void>();

    @Output() readonly editDashboard = new EventEmitter<string>();
    @Output() readonly deleteDashboard = new EventEmitter<string>();
    @Output() readonly addWidget = new EventEmitter<void>();

    displaySectionsBlock = false;

    showSectionsBlock() {
        this.displaySectionsBlock = true;
    }

    get kpiIsActivated() {
        return this.activatedRoute?.link === 'kpi';
    }

    get countOfDashboards() {
        return this.dashboards?.length ?? 0;
    }
}
