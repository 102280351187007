import { WellPlan } from '@cyberloop/web/wells/model';

import type { Observable } from 'rxjs';

/** Base class for well information provider. Used as Injection token */
export abstract class WellPlansProviderSerivce {
    /** Get survey data */
    abstract getWellPlans(wellID: string): Observable<WellPlan[]>;

    /** Watch survey data by section */
    abstract watchWellPlans(wellID: string): Observable<WellPlan[] | undefined>;
}