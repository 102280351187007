import { Injectable } from '@angular/core';

import { WellPlan } from '@cyberloop/web/wells/model';

import { GraphQLQuery } from '../internals/cl-gql';

type QueryRequest = {
    wellID: string
}

type QueryResponse = {
    wellPlans: WellPlan[];
}

@Injectable({
    providedIn: 'root'
})
export class WellPlansQuery extends GraphQLQuery<QueryResponse, QueryRequest> {
    override readonly document = `query wellplanQuery($wellID: String!) {
        wellPlans(wellId: $wellID) {
          id
          wellId
          name
          vsa
          vsaManually
          data {
            md
            ns
            ew
            dls
            inc
            azi
            tvd
            vsec
            hOffset
            thl
          }
          targets {
            comment
            inc
            azi
            tvd
            ns
            ew
            dls
            vsa
          }
        }
    }`;
}