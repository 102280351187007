import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';

import { Observable } from 'rxjs';

/** Options for Hotkey */
export type Options = {
    /** Element this hotkey must be used */
    element?: Node;
    /** Hotkey definition */
    keys: string;
};

/** This service handles hotkeys */
@Injectable({
    providedIn: 'root'
})
export class HotkeysService {
    /** @internal */
    constructor(
        private eventManager: EventManager,
        @Inject(DOCUMENT) private document: Document
    ) { }

    /** Add global shortcut by definition */
    addShortcut(keys: string): Observable<KeyboardEvent>;
    /** Add shortcut by options */
    addShortcut(options: Partial<Options>): Observable<KeyboardEvent>;
    /** @internal */
    addShortcut(optionsOrKeys: string | Partial<Options>): Observable<KeyboardEvent> {
        if (typeof optionsOrKeys === 'string') {
            optionsOrKeys = { keys: optionsOrKeys };
        }
        const merged = { ...this.getDefaults(), ...optionsOrKeys };
        const event = `keydown.${merged.keys}`;

        return new Observable(observer => {
            const handler = (e: KeyboardEvent) => {
                e.preventDefault();
                observer.next(e);
            };

            if (!merged?.element) {
                return;
            }

            const dispose = this.eventManager.addEventListener(
                merged.element as HTMLElement, event, handler
            );

            return () => {
                dispose();
            };
        });
    }

    /** @private */
    private getDefaults(): Partial<Options> {
        return {
            element: this.document
        };
    }
}