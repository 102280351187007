<cyberloop-kpi-widget-settings class="settings" (close)="onCancel()">

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <div class="content">

            <!-- Section -->
            <div class="fields-group">
                <fieldset>
                    <legend>Select section</legend>
                    <cl-selector formControlName="sectionId" [options]="sections$ | async" placeholder="Select a section..."></cl-selector>
                </fieldset>

                <fieldset>
                    <cl-checkbox formControlName="includeParent">Include parent</cl-checkbox>
                </fieldset>
            </div>

            <!-- Depth interval -->
            <h4>Interval</h4>
            <div class="fields-group">
                <fieldset>
                    <legend>Min depth <span class="units">{{(selectedDepthUnits$ | async)?.label}}</span></legend>
                    <cl-numeric-input formControlName="minDepth" placeholder="0"></cl-numeric-input>
                </fieldset>

                <fieldset>
                    <legend>Max depth <span class="units">{{(selectedDepthUnits$ | async)?.label}}</span></legend>
                    <cl-numeric-input formControlName="maxDepth" placeholder="0"></cl-numeric-input>
                </fieldset>
            </div>

            <h4>ROP</h4>

            <fieldset>
                <legend>Select units</legend>
                <cl-button-toggle
                    formControlName="ropUnits"
                    selectionType="radio"
                    [list]="(ropUnits$ | async) ?? []"
                    class="units-selector"
                ></cl-button-toggle>
            </fieldset>

            <h4>Other</h4>
            <div class="fields-group other">
                <fieldset>
                    <legend>Select depth units</legend>
                    <cl-button-toggle
                        formControlName="depthUnits"
                        selectionType="radio"
                        [list]="(depthUnits$ | async) ?? []"
                        class="units-selector"
                    ></cl-button-toggle>
                </fieldset>
                <fieldset>
                    <legend>Target ROP</legend>
                    <cl-numeric-input formControlName="targetRop" placeholder="0" [unit]="selectedRopUnits$ | async"></cl-numeric-input>
                </fieldset>
            </div>

        </div>

        <section class="actions">
            <button type="button" class="cl-flat-button" (click)="onCancel()">Cancel</button>
            <button type="submit" class="cl-primary-button">Apply</button>
        </section>

    </form>

</cyberloop-kpi-widget-settings>