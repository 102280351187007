import { createAction, props } from '@ngrx/store';

import { UNITS_FEATURE } from './units.state';

import type { UnitGroups, UnitsPreset, UnitsPresets } from '../../models';

/** @internal */
const setUnitGroups = createAction(`[${UNITS_FEATURE}] Set unit groups`, props<{ groups: UnitGroups }>());
/** @internal */
const setUnitsPresets = createAction(`[${UNITS_FEATURE}] Set units presets`, props<{ presets: UnitsPresets }>());

/** @internal */
const setUserUnitsPreset = createAction(`[${UNITS_FEATURE}] Set user unit presets`, props<{ preset: UnitsPreset }>());
/** @internal */
const updateUserUnitsPreset = createAction(`[${UNITS_FEATURE}] Update user unit presets`, props<{ preset: UnitsPreset }>());
/** @internal */
const destroyUserUnitPreset = createAction(`[${UNITS_FEATURE}] Destroy user's unit preset`);
/** @internal */
const loadUserUnitPreset = createAction(`[${UNITS_FEATURE}] Load user's unit preset`);
/** @internal */
const onStartLoading = createAction(`[${UNITS_FEATURE}] Set flag that user's unit preset is loading`);

/**
 * @internal
 * Actions for units used internally
 * */
export const UnitsActions = {
    setUnitGroups,
    setUnitsPresets,
    setUserUnitsPreset,
    updateUserUnitsPreset,
    destroyUserUnitPreset,
    loadUserUnitPreset,
    onStartLoading
};

/**
 * Actions for units
 * */
export const PublicUnitsActions = {
    /** Set user-specific units preset */
    setUserUnitsPreset,
    updateUserUnitsPreset
};
