<cyberloop-min-size-component></cyberloop-min-size-component>

<nav class="header">
    <cyberloop-side-menu-trigger [navigationItems]="navigationItems" [recentItems]="recentItems"
        (navigationRequest)="navigationRequest.emit($event)"></cyberloop-side-menu-trigger>
    <div class="app-name" *ngIf="appName">{{appName}}</div>

    <div class="space">

    </div>

    <div class="time">
        {{time$|async}}
    </div>

    <div class="divider"></div>

    <cyberloop-user-menu-trigger [displayName]="displayName" [login]="login" [userAvatar]="userAvatar"
        (logout)="logout.emit()"></cyberloop-user-menu-trigger>
</nav>


<div class="outlet-container" scrollShadow>
    <ng-content></ng-content>
</div>