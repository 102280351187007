import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { MeterageEffects } from './meterage.effects';
import { meterageReducer } from './meterage.reducer';
import { METERAGE_FEATURE } from './meterage.state';

export const MeteragesProviders = [
    provideEffects([MeterageEffects]),
    provideState(METERAGE_FEATURE, meterageReducer)
];