<h1 class="title">
    {{ isEdit ? 'Edit' : 'New' }} well
</h1>

<div [formGroup]="form" class="content">
    <fieldset class="fieldset">
        <label class="label">Name</label>
        <cl-input [formControlName]="Controls.Name" placeholder="Type"></cl-input>
        <cl-form-errors [control]="nameControl"></cl-form-errors>
    </fieldset>

    <fieldset *ngIf="!hasWellId" class="fieldset">
        <label class="label">Select Rig</label>
        <cl-selector [formControlName]="Controls.RigId" [options]="rigList$ | async"></cl-selector>
        <cl-form-errors [control]="rigIdControl"></cl-form-errors>
    </fieldset>

    <fieldset class="fieldset">
        <label class="label">Planned start</label>
        <cl-datepicker [formControlName]="Controls.PlannedStartDate" class="datepicker"></cl-datepicker>
    </fieldset>

    <fieldset class="fieldset">
        <label class="label">Description</label>
        <textarea [formControlName]="Controls.Description" placeholder="Type" class="textarea"></textarea>
    </fieldset>
</div>

<div class="actions">
    <button (click)="close()" class="cl-outlined-button action-button">
        <span class="label">Cancel</span>
    </button>

    <button (click)="save()" class="cl-primary-button action-button">
        <span class="label">Save</span>
    </button>
</div>

<button (click)="close()" class="cl-icon-button close">
    <icon name="close"></icon>
</button>