import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FormatNumberPipe, Section } from '@cyberloop/core';

import { GreenArrowsComponent } from './green-arrows/green-arrows.component';

@Component({
    selector: 'cyberloop-sections-info',
    standalone: true,
    imports: [
        CommonModule,
        FormatNumberPipe,
        GreenArrowsComponent
    ],
    templateUrl: './sections-info.component.html',
    styleUrls: ['./sections-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionsInfoComponent {

    @Input() sections: Section[] | null | undefined = [];
    @Input() current: number | null | undefined;

    @Output() readonly selectSection = new EventEmitter<number>();

    getSectionWithID(id: number) {
        return this.sections?.find(s => s.id === id);
    }
}
