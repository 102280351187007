import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiRigStateSLideRotaryHour } from '@cyberloop/web/wells/model';
type KpiRigStateSLideRotaryHoursInput = {
    rig: string,
    since: string,
    until: string
}

type KpiRigStateSLideRotaryHours = {
    rig: {
        rigStateSlideRotaryHours: KpiRigStateSLideRotaryHour[]
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiRigStateSlideRotaryHoursQuery extends GraphQLQuery<KpiRigStateSLideRotaryHours, KpiRigStateSLideRotaryHoursInput> {
    override readonly document = `
        query GetRigStateRotarySlideHours($rig: String!, $since: DateTime!, $until: DateTime!) {
            rig(name: $rig) {
                ... on Rig {
                    rigStateSlideRotaryHours(since: $since, until: $until) {
                        hour
                        durationRotary
                        durationSlide
                    }
                }
            }
        }
    `;
}