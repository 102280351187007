import type { ID } from '@cyberloop/core';

import type { Observable } from 'rxjs';
import { Template } from '../../models/templates';

/** Base class for templates provider. Used as Injection token */
export abstract class TemplateRepositryService<T extends Template = any> {
    /** Watch list of templates */
    abstract watchAll(): Observable<T[]>;

    /** Get template by ID */
    abstract get(id: ID): Observable<T | null>;

    /** Create template */
    abstract create(template: Omit<T, 'id' | 'author' | 'createdAt' | 'updatedAt'>): Observable<T>;

    /** Delete template by ID */
    abstract delete(templateId: ID): Observable<T>;
}
