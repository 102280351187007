import type { Well } from '@cyberloop/core';
import { Observable, map, of, timer } from 'rxjs';

const DEPTH_RANGE_REFRESH_INTERVAL = 30000;

export function getDepthRangeObservable(
    well: Well,
    from?: number,
    to?: number
): Observable<{ from: number, to?: number }> {
    if (from && to) {
        // This interval is ready
        return of({
            from,
            to
        });
    }

    const sections = well.sections.concat([]);

    // Sort sections ASC
    sections.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

    if (!from) {
        from = sections[0].from;
    }

    if (well.releaseTime || well.suspendTime) {
        // Well is finished

        to = sections[sections.length - 1]?.to;

        return of({
            from,
            to
        });
    }
    else {
        // Well is active, we should update the value

        return timer(0, DEPTH_RANGE_REFRESH_INTERVAL).pipe(
            map(() => {
                from = Number(from); // This is not needed, just to calm the eslint
                to = sections[sections.length - 1]?.to;

                return {
                    from,
                    to
                };
            })
        );
    }
}
