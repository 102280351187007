import { Injectable } from '@angular/core';

import { ID, TemplateRepositryService } from '@cyberloop/core';
import { CreatePlanningTemplate, PlanningTemplate } from '@cyberloop/web/planning/shared/model';
import { Observable } from 'rxjs';

import { PlanningTemplateProviderService } from './abstractions/planning-template-provider.service';

@Injectable({ providedIn: 'root' })
export class PlanningTemplateDataService extends TemplateRepositryService<PlanningTemplate> {
    constructor(private readonly providerService: PlanningTemplateProviderService) {
        super();
    }

    override watchAll(): Observable<PlanningTemplate[]> {
        return this.providerService.watchAll();
    }

    override get(id: ID): Observable<PlanningTemplate | null> {
        return this.providerService.get(id);
    }

    override create(template: CreatePlanningTemplate): Observable<PlanningTemplate> {
        return this.providerService.create(template);
    }

    override delete(templateId: ID): Observable<PlanningTemplate> {
        return this.providerService.delete(templateId);
    }
}
