import { Pipe, PipeTransform } from '@angular/core';

/**
 * Return number in specific format
 * @example 
 * 5000 => 5k, 9800000 => 9,8m
 */
@Pipe({
    name: 'shorten',
    standalone: true
})
export class ShortenPipe implements PipeTransform {
    /**
     * @internal 
     */
    transform(value: number | null | undefined): string | null {
        if (typeof value === 'number') {
            if (Math.abs(value) > 1e6) {
                return (value / 1e6).toFixed(1) + 'm';
            }
            else if (Math.abs(value) > 1e3) {
                return (value / 1e3).toFixed(1) + 'k';
            } 
            else {
                return value.toFixed(1);
            }
        }

        return null;
    }

}
