import { Pipe, PipeTransform } from '@angular/core';

/** This pipe shows placeholder if there is no data passed in (null or undefined) */
@Pipe({
    name: 'noData',
    standalone: true
})
export class NoDataPipe implements PipeTransform {
    /** @internal */
    transform(value: unknown | undefined, noDataPlaceholder = '-'): unknown | string {
        return typeof value === 'undefined' || value === null ? noDataPlaceholder : value;
    }
}
