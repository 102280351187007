import { Injectable } from '@angular/core';

import { ClError, ID, uuid } from '@cyberloop/core';
import { PlanningVersionProviderService } from '@cyberloop/web/planning/shared/data';
import { CreatePlanningVersion, PlanningStage, PlanningVersion } from '@cyberloop/web/planning/shared/model';
import { isNil } from 'lodash';
import { EMPTY, Observable, finalize, shareReplay, switchMap } from 'rxjs';

import { FirestoreDataConverter, orderBy } from 'firebase/firestore';
import * as moment from 'moment';

import { DBCreatePlanningVersion, DBPlanningVersion, DBUpdatePlannigActiveVersionIdAndDuration, DBUpdatePlanningDuration, DBUpdatePlanningVersion } from '../models/planning';
import { PLANNING_COLLECTION_NAME } from './firebase-planning-provider.service';
import { ClApplicationManager } from './internals/client-app/cl-app-manager';

import type { ClApplication } from './internals/client-app/cl-application';
export const PLANNING_VERSIONS_COLLECTION_NAME = 'versions';

@Injectable({
    providedIn: 'root'
})
export class FirebasePlanningVersionProviderLinkService extends PlanningVersionProviderService {
    private readonly _сonverter: FirestoreDataConverter<PlanningVersion> = {
        toFirestore: (data: PlanningVersion) => data,
        fromFirestore: (snapshot, options): PlanningVersion => {
            const data = snapshot.data(options) as DBPlanningVersion;

            return {
                ...data,
                createdAt: moment(data.createdAt),
                updatedAt: moment(data.updatedAt)
            };
        }
    };

    private _listObservables: Record<ID, Observable<PlanningVersion[]> | undefined> = {};
    private _versionObservables: Record<ID, Observable<PlanningVersion | null> | undefined> = {};

    constructor(private readonly appMgr: ClApplicationManager) {
        super();
    }

    watchAll(planningId: ID) {
        return this._listObservables[planningId] ??= this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().watchCollection<PlanningVersion>(
                    this.getPath(PLANNING_COLLECTION_NAME, planningId, PLANNING_VERSIONS_COLLECTION_NAME),
                    this._сonverter,
                    [orderBy('createdAt', 'desc')]
                ) ?? EMPTY
            ),
            finalize(() => delete this._listObservables[planningId]),
            shareReplay(1)
        );
    }

    watch(planningId: ID, versionId: ID) {
        return this._versionObservables[versionId] ??= this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().watch<PlanningVersion>(
                    this.getPath(PLANNING_COLLECTION_NAME, planningId, PLANNING_VERSIONS_COLLECTION_NAME, versionId),
                    this._сonverter
                ) ?? EMPTY
            ),
            finalize(() => delete this._versionObservables[versionId]),
            shareReplay(1)
        );
    }

    get(planningId: ID, versionId: ID) {
        return this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().get<PlanningVersion>(
                    this.getPath(PLANNING_COLLECTION_NAME, planningId, PLANNING_VERSIONS_COLLECTION_NAME, versionId),
                    this._сonverter
                ) ?? EMPTY
            ),
            shareReplay(1)
        );
    }

    create(planningId: ID, createVersion: CreatePlanningVersion) {
        const versionId = uuid();

        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    await transaction.set<DBCreatePlanningVersion>(
                        this.getPath(PLANNING_COLLECTION_NAME, planningId, PLANNING_VERSIONS_COLLECTION_NAME, versionId),
                        {
                            ...createVersion,
                            id: versionId,
                            changedBy: this.getUserEmail(x),
                            createdAt: moment.now(),
                            updatedAt: moment.now()
                        }
                    );

                    await transaction.update<DBUpdatePlannigActiveVersionIdAndDuration>(
                        this.getPath(PLANNING_COLLECTION_NAME, planningId),
                        {
                            activeVersionId: versionId,
                            duration: this.calcDuration(createVersion.stages),
                            updatedAt: moment.now()
                        }
                    );
                }) ?? EMPTY),
                switchMap(() => this.get(planningId, versionId) as Observable<PlanningVersion>),
                shareReplay(1)
            );
    }

    update(planningId: ID, version: PlanningVersion) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    const duration = this.calcDuration(version.stages);

                    await transaction.update<DBUpdatePlanningVersion>(
                        this.getPath(PLANNING_COLLECTION_NAME, planningId, PLANNING_VERSIONS_COLLECTION_NAME, version.id),
                        {
                            name: version.name,
                            description: version.description,
                            updatedAt: moment.now(),
                            changedBy: this.getUserEmail(x),
                            stages: version.stages
                        }
                    );

                    await transaction.update<DBUpdatePlanningDuration>(
                        this.getPath(PLANNING_COLLECTION_NAME, planningId),
                        {
                            duration,
                            updatedAt: moment.now()
                        }
                    );
                }) ?? EMPTY),
                switchMap(() => this.get(planningId, version.id) as Observable<PlanningVersion>),
                shareReplay(1)
            );
    }

    replace(planningId: ID, version: PlanningVersion) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    const duration = this.calcDuration(version.stages);

                    await transaction.update<DBUpdatePlanningVersion>(
                        this.getPath(PLANNING_COLLECTION_NAME, planningId, PLANNING_VERSIONS_COLLECTION_NAME, version.id),
                        {
                            name: version.name,
                            description: version.description,
                            updatedAt: moment.now(),
                            changedBy: this.getUserEmail(x),
                            stages: version.stages
                        }
                    );

                    await transaction.update<DBUpdatePlannigActiveVersionIdAndDuration>(
                        this.getPath(PLANNING_COLLECTION_NAME, planningId),
                        {
                            activeVersionId: version.id,
                            duration,
                            updatedAt: moment.now()
                        }
                    );
                }) ?? EMPTY),
                switchMap(() => this.get(planningId, version.id) as Observable<PlanningVersion>),
                shareReplay(1)
            );
    }

    delete(planningId: ID, versionId: ID) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    const version = await transaction.get<PlanningVersion>(
                        this.getPath(PLANNING_COLLECTION_NAME, planningId, PLANNING_VERSIONS_COLLECTION_NAME, versionId),
                        this._сonverter
                    );

                    if (isNil(version)) {
                        throw new Error('Version not found');
                    }

                    await transaction.delete(
                        this.getPath(PLANNING_COLLECTION_NAME, planningId, PLANNING_VERSIONS_COLLECTION_NAME, versionId)
                    );

                    return version;
                }) ?? EMPTY),
                shareReplay(1)
            );
    }

    private calcDuration(stages: PlanningStage[]) {
        return (stages.at(-1)?.techLimitCumulative ?? 0) * 24 * 60 * 60 * 1000;
    }

    private getUserEmail(x?: ClApplication) {
        const email = x?.getAuth().currentUser?.email;

        if (isNil(email)) {
            throw new ClError('User email not found');
        }

        return email;
    }

    private getPath(...args: string[]) {
        return args.join('/');
    }
}
