import { Injectable } from '@angular/core';

import { CoreSelectors, Section, SectionTree, buildSectionTree } from '@cyberloop/core';
import { TndDataProvider } from '@cyberloop/web/tnd/data';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { EMPTY, Observable, distinctUntilChanged, filter, map, switchMap, timer } from 'rxjs';

import * as moment from 'moment';

import { QueryResult } from '../internals/cl-gql/models';
import { TorqueAndDragQuery } from '../queries/tnd/data.query';

import type { TndItem } from '@cyberloop/web/tnd/model';

const POLLING_INTERVAL = 60;
const REFRESH_INTERVAL_MIN = 1;

@Injectable({ providedIn: 'root' })
export class TndDataProviderServiceGraphQL extends TndDataProvider {

    constructor(
        private readonly store: Store,
        private readonly torqueAndDragQuery: TorqueAndDragQuery
    ) {
        super();
    }

    watch(wellId: string, sectionId: number, refreshIntervalSec?: number): Observable<TndItem[]> {
        refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec, POLLING_INTERVAL);

        return this.store.select(CoreSelectors.getWellById(wellId)).pipe(
            filter(Boolean),
            // map(w => w.sections)
            switchMap(well => {
                const section = well.sections.find(item => item.id === sectionId);

                if (!section) {
                    console.error(`Section ${sectionId} wasn't found in well ${wellId}`);
                    return EMPTY;
                }

                const startTime = well.startTime;

                let endTime = section.endTime;
                const isSectionActive = !endTime;

                if (!endTime) {
                    endTime = new Date();
                }

                refreshIntervalSec = this.assertRefreshInterval(refreshIntervalSec, POLLING_INTERVAL);

                let dataOb$: Observable<TndItem[]>;

                if (isSectionActive) {
                    dataOb$ = timer(0, refreshIntervalSec * 1000).pipe(
                        // NOTE: Section is active, so we should always use the
                        // current time to fetch data.
                        // Therefore we'll refresh it ourselves by `timer()`.
                        switchMap(() => this.fetchData(wellId, startTime, endTime as Date, well.sections, section))
                    );
                }
                else {
                    dataOb$ = this.watchData(wellId, startTime, endTime as Date, well.sections, section, refreshIntervalSec);
                }

                return dataOb$;
            })
        );
    }

    // --

    /**
     * Provides a generic observable with T&D data without splitting it into
     * Weights & Torque
     */
    private fetchData(wellId: string, since: Date, until: Date, sections: Section[], section: Section): Observable<TndItem[]> {
        return this.torqueAndDragQuery.fetch({
            wellId,
            since: since.toISOString(),
            until: until.toISOString()
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(data => data?.well.torqndrag.entries.edges ?? []),
            map(data => this.filterDataBySectionTree(data, sections, section))
        );
    }

    /**
     * Provides a generic observable with T&D data without splitting it into
     * Weights & Torque
     */
    private watchData(wellId: string, since: Date, until: Date, sections: Section[], section: Section, refreshIntervalSec: number): Observable<TndItem[]> {
        return this.torqueAndDragQuery.watch({
            wellId,
            since: since.toISOString(),
            until: until.toISOString()
        }, {
            pollInterval: refreshIntervalSec * 1000
        }).pipe(
            filter(x => !x.loading),
            map(this.handleQueryErrors),
            map(data => data?.well.torqndrag.entries.edges ?? []),
            map(data => this.filterDataBySectionTree(data, sections, section)),
            distinctUntilChanged((a, b) => isEqual(a, b))
        );
    }

    private handleQueryErrors<T>(response: QueryResult<T>): QueryResult<T>['data'] {
        if (response.error) {
            throw new Error(response.error.message);
        }
        else if (response.errors) {
            throw new Error(response.errors.map(err => err.message).join('; '));
        }

        return response.data;
    }

    private assertRefreshInterval(interval?: number, defaultInterval = POLLING_INTERVAL): number {
        if (typeof interval !== 'number' || interval < REFRESH_INTERVAL_MIN) {
            interval = defaultInterval;
        }

        return interval;
    }

    /**
    * Filtered surveys according sections tree
    * @param data array of surveys
    * @param sections all sections in well
    * @param sectionId last child
    * @param isSurvey flag survey or slidesheet
    * @returns filtered array of surveys
    */
    private filterDataBySectionTree(data: TndItem[], sections: Section[], section: Section): TndItem[] {
        // NOTE: This is from leaf to root (section with id === sectionId is
        // the first element of the array)
        const sectionTree: SectionTree[] | undefined = buildSectionTree(sections, section);

        if (!sectionTree) {
            return data;
        }

        const result: TndItem[] = [];

        // Obtain portions of data for every section in the tree

        // Loop through sections from root to leaf
        for (const sec of sectionTree) {
            const sectionData: TndItem[] = [];

            // Loop through data from previous end point
            for (const item of data) {
                const m = moment(item.dtime);

                if (
                    m.isSameOrAfter(sec.startTime) &&
                    (!sec.endTime || m.isSameOrBefore(sec.endTime)) &&
                    item.depth >= sec.from &&
                    (!sec.to || item.depth <= sec.to)
                ) {
                    result.push(item);
                    sectionData.push(item);
                }
            }
        }

        return result;
    }

}
