/**
 * Represents a generic error.
 * @extends Error
 */
export class ClError extends Error {
    /**
     * Creates a new instance of ClError.
     * @param {string} message - The error message.
     */
    constructor(message: string) {
        super(message);
    }

    /**
    * The name of the error.
    * @type {string}
    * @override
    */
    override name = 'Unknown';
}

/**
 * Represents a generic network error.
 * @extends ClError
 */
export class ClNetworkError extends ClError {
    /**
     * Creates a new instance of ClNetworkError.
     * @param {string} message - The error message.
     */
    constructor(message: string) {
        super(message);
    }

    /**
    * The name of the network error.
    * @type {string}
    * @override
    */
    override name = 'Unknown';
}

/**
 * Represents an unauthorized network error.
 * @extends ClNetworkError
 */
export class ClUnauthorizedNetworkError extends ClNetworkError {
    /**
     * Creates a new instance of ClUnauthorizedNetworkError.
     * @param {string} message - The error message.
     */
    constructor(message: string) {
        super(message);
    }

    /**
     * The name of the network error.
     * @type {string}
     * @override
     */
    override name = 'Unauthorized';
}

/**
 * Represents an access denied network error.
 * @extends ClNetworkError
 */
export class ClAccessDeniedNetworkError extends ClNetworkError {
    /**
     * Creates a new instance of ClAccessDeniedNetworkError.
     * @param {string} message - The error message.
     */
    constructor(message: string) {
        super(message);
    }

    /**
     * The name of the network error.
     * @type {string}
     * @override
     */
    override name = 'Access denied';
}

/**
 * Represents a bad request network error.
 * @extends ClNetworkError
 */
export class ClBadRequestNetworkError extends ClNetworkError {
    /**
     * Creates a new instance of ClBadRequestNetworkError.
     * @param {string} message - The error message.
     */
    constructor(message: string) {
        super(message);
    }

    /**
     * The name of the network error.
     * @type {string}
     * @override
     */
    override name = 'Bad request';
}

/**
 * Represents a not found network error.
 * @extends ClNetworkError
 */
export class ClNotFoundNetworkError extends ClNetworkError {
    /**
     * Creates a new instance of ClNotFoundNetworkError.
     * @param {string} message - The error message.
     */
    constructor(message: string) {
        super(message);
    }

    /**
     * The name of the network error.
     * @type {string}
     * @override
     */
    override name = 'Not found';
}

/**
 * Represents a server error network error.
 * @extends ClNetworkError
 */
export class ClServerNetworkError extends ClNetworkError {
    /**
     * Creates a new instance of ClServerNetworkError.
     * @param {string} message - The error message.
     */
    constructor(message: string) {
        super(message);
    }

    /**
     * The name of the network error.
     * @type {string}
     * @override
     */
    override name = 'Server error';
}
