import 'moment-duration-format';

import { Pipe, PipeTransform } from '@angular/core';

import { isNil } from 'lodash';

import * as moment from 'moment';

/**
 * A pipe that transforms a moment duration or a number or a bigint representing milliseconds
 * into a formatted duration string.
 * @remarks
 * This pipe uses Moment.js library to perform the transformation.

 * @param value - The value to transform. Can be a moment duration, a number, a bigint, null or undefined.
 * @param format - The format string to use for formatting the duration. Defaults to 'HH:mm'.
 * @returns The formatted duration string, or null if the value is null or undefined.
*/
@Pipe({ name: 'duration', standalone: true })
export class DurationPipe implements PipeTransform {
    transform(value: moment.Duration | number | bigint | null | undefined, format: string = 'HH:mm'): string | null {
        if (isNil(value)) {
            return null
        }

        if (typeof value === 'bigint') {
            value = Number(value);
        }

        if (typeof value === 'number') {
            value = moment.duration(value);
        }

        return value.format(format)
    }
}
