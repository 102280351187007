import { Injectable } from '@angular/core';

import { TFArrayProviderSerivce } from '@cyberloop/web/wells/data';
import { Toolface } from '@cyberloop/web/wells/model';
import { Observable, map } from 'rxjs';

import { TFArrayDataQuery } from '../queries/tfarray.query';
import { TFArrayDataSubscription } from '../subscriptions/tfarray.subscription';

@Injectable({
    providedIn: 'root'
})
export class TFArrayProviderServiceImpl extends TFArrayProviderSerivce {

    private readonly _tfSubscriptions: Record<string, Observable<Toolface[]>> = {};
    
    constructor(
        private readonly _tfQuery: TFArrayDataQuery,
        private readonly _tfSubscription: TFArrayDataSubscription
    ) {
        super();
    }

    getTFArrayData(rigName: string): Observable<Toolface[]> {
        return this._tfQuery.fetch({rigName}).pipe(
            map((response) => response.data.rig.toolface)
        );
    }

    watchTFArrayData(rigName: string): Observable<Toolface[]> {
        return this._tfSubscriptions[rigName] ??= this._tfSubscription.subscribe({rigName}).pipe(
            map((response) => response?.data?.toolface ?? [])
        );
    }

}