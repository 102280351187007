import { ID } from '@cyberloop/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PlanningSelectors } from '../planning.selectors';
import { VERSION_FEATURE, VersionState, adapter } from './version.state';

const feature = createFeatureSelector<VersionState>(VERSION_FEATURE);
const { selectAll } = adapter.getSelectors();

const all = createSelector(feature, selectAll);
const list = createSelector(all, PlanningSelectors.planningId, (state, planningId) => state.filter(x => x.planningId === planningId));
const listLoading = createSelector(feature, state => state.listLoadingState === 'LOADING');
const listLoaded = createSelector(feature, state => state.listLoadingState === 'LOADED');

const selectVersion = (versionId: ID) => createSelector(all, (state) => state.find(x => x.id === versionId));

const version = createSelector(list, PlanningSelectors.planning, (state, planning) => state.find(x => x.id === planning?.activeVersionId));
const versionId = createSelector(version, state => state?.id);
const versionName = createSelector(version, state => state?.name);
const versionStages = createSelector(version, state => state?.stages);
const versionLoading = createSelector(feature, state => state.versionLoadingState === 'LOADING');
const versionLoaded = createSelector(feature, state => state.versionLoadingState === 'LOADED');

const editedStages = createSelector(feature, version, (state, versionState) => state.editedStages ?? versionState?.stages);
const stagesUpdatedAt = createSelector(feature, state => state.stagesUpdatedAt);
const isStagesEdited = createSelector(stagesUpdatedAt, updatedAt => Boolean(updatedAt));

const createLoading = createSelector(feature, state => state.createLoading);
const updateLoading = createSelector(feature, state => state.updateLoading);
const replaceLoading = createSelector(feature, state => state.replaceLoading);
const deleteLoading = createSelector(feature, state => state.deleteLoading);
const actionLoading = createSelector(
    createLoading,
    updateLoading,
    replaceLoading,
    deleteLoading,
    (isCreateLoading, isUpdateLoading, isReplaceLoading, isDeleteLoading) => isCreateLoading || isUpdateLoading || isReplaceLoading || isDeleteLoading
);

/**
 * Selectors for version
 * */
export const PlanningVersionSelectors = {
    list,
    listLoading,
    listLoaded,

    selectVersion,

    version,
    versionId,
    versionName,
    versionStages,
    versionLoading,
    versionLoaded,

    editedStages,
    stagesUpdatedAt,
    isStagesEdited,

    createLoading,
    updateLoading,
    replaceLoading,
    deleteLoading,
    actionLoading
};
