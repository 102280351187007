<header class="header">
    <button (click)="previousMonth()" [class.hidden]="currentView !== CalendarView.Day" class="prev-month">
        <icon name="arrow__left"></icon>
    </button>

    <div class="current">
        <button (click)="changeView(CalendarView.Month)" class="current-month">{{currentMonth | datetime:
            'MMMM'}}</button>
        <button (click)="changeView(CalendarView.Year)" class="current-year">{{currentMonth | datetime:
            'YYYY'}}</button>
    </div>

    <button (click)="nextMonth()" [class.hidden]="currentView !== CalendarView.Day" class="next-month">
        <icon name="arrow__right"></icon>
    </button>
</header>

<ng-container [ngSwitch]="currentView">
    <ng-container *ngSwitchCase="CalendarView.Year">
        <div class="years">
            <div *ngFor="let year of years" (click)="selectYear(year)" class="cell">
                {{ year | datetime: 'yyyy' }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="CalendarView.Month">
        <div class="months">
            <div *ngFor="let month of months" (click)="selectMonth(month)" class="cell">
                {{ month | datetime: 'MMMM' }}
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="CalendarView.Day">
        <div class="days">
            <div class="row">
                <div class="cell weekday" *ngFor="let weekday of weekdays">
                    {{weekday}}
                </div>
            </div>
            <div class="row" *ngFor="let week of weeks">
                <ng-container *ngFor="let day of week">
                    <div *ngIf="day; else emptyCell" class="cell day" [ngClass]="{
                            'disabled': isDisabled(day),
                            'selected': isDateSelected(day),
                            'start': isDateStart(day),
                            'end': isDateEnd(day) || (!range && isDateStart(day)),
                            'between': isDateBetween(day)
                          }" (click)="selectDay(day)">
                        {{day | datetime: 'd' }}
                    </div>
                    <ng-template #emptyCell>
                        <div class="cell empty"></div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-container>

<footer *ngIf="(startDate && !range) || (startDate && endDate)" class="footer">
    <button (click)="clear()" class="cl-button">Clear</button>
</footer>