import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { APP_NAME, AuthRoutes } from '@cyberloop/core';
import { Subscription, filter, switchMap } from 'rxjs';

import { AuthService } from '../../../services/auth.service';
import { AuthComponent } from '../../ui/auth/auth.component';

/** @internal */
@Component({
    standalone: true,
    imports: [
        CommonModule,
        AuthComponent
    ],
    template: '<cyberloop-ui-auth [appName]="appName"></cyberloop-ui-auth>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthPageComponent implements OnDestroy {
    private _routerEventsSubscr: Subscription | undefined;
    /**
     *
     */
    constructor(
        sso: AuthService,
        @Inject(APP_NAME) public readonly appName: string,
        router: Router
    ) {
        this._routerEventsSubscr = router.events.pipe(
            filter(x => x instanceof NavigationEnd && x.urlAfterRedirects === `/${AuthRoutes.Root}`),
            switchMap(() => router.navigate([AuthRoutes.Root, AuthRoutes.SignIn]))
        ).subscribe();
    }

    ngOnDestroy(): void {
        if (this._routerEventsSubscr) {
            this._routerEventsSubscr.unsubscribe();
            this._routerEventsSubscr = undefined;
        }
    }
}
