import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { range } from 'lodash';
import { EMPTY, Observable, ReplaySubject, combineLatest, debounceTime, map, shareReplay, startWith, switchMap } from 'rxjs';

import { NgForEmptyDirective } from '../../directives';
import { Entity, LoadTemplateDialogData, LoadTemplateDialogResult, Template, TemplateAccess } from '../../models';
import { DatetimePipe } from '../../pipes';
import { ClCheckboxGroupComponent } from '../cl-checkbox-group/cl-checkbox-group.component';
import { ClInputComponent } from '../cl-input/cl-input.component';
import { IconComponent } from '../icon/icon.component';
import { PopupContent } from '../popup-host/popup-host.component';

/** Popup saved template component */
@UntilDestroy()
@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgForEmptyDirective,
        AsyncPipe,
        DatetimePipe,
        ReactiveFormsModule,
        IconComponent,
        ClCheckboxGroupComponent,
        ClInputComponent
    ],
    templateUrl: './load-template.component.html',
    styleUrls: ['./load-template.component.scss'],
    providers: [DatetimePipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadTemplateComponent<T extends Template = any> implements PopupContent<LoadTemplateDialogResult<T> | undefined>, OnInit {
    /** @private */
    private _data!: LoadTemplateDialogData<T>;
    /** @private */
    private _data$ = new ReplaySubject<Observable<T[]>>(1);

    /** @internal */
    constructor(private readonly datetimePipe: DatetimePipe) { }

    /** @internal */
    readonly skeletonCount = range(5);
    /** @internal */
    readonly LoadTemplateFilter = TemplateAccess;
    /** @internal */
    readonly filterItems: Entity<TemplateAccess>[] = [
        {
            id: TemplateAccess.Public,
            name: TemplateAccess.Public
        },
        {
            id: TemplateAccess.Personal,
            name: TemplateAccess.Personal
        }
    ];

    /** @internal */
    readonly selectedFiltersControl = new FormControl<TemplateAccess[]>([]);
    /** @internal */
    readonly searchControl = new FormControl('');

    /** @internal */
    readonly search$ = this.searchControl.valueChanges.pipe(startWith(''), shareReplay(1));
    /** @internal */
    readonly selectedFilters$ = this.selectedFiltersControl.valueChanges.pipe(startWith([] as TemplateAccess[]), shareReplay(1));
    /** @internal */
    readonly isSelectedPublic$ = this.selectedFilters$.pipe(map((filters) => filters?.includes(TemplateAccess.Public)));
    /** @internal */
    readonly isSelectedPersonal$ = this.selectedFilters$.pipe(map((filters) => filters?.includes(TemplateAccess.Personal)));

    /** @internal */
    readonly list$: Observable<T[]> = this._data$.pipe(switchMap((data$) =>
        combineLatest([data$, this.search$, this.selectedFilters$]).pipe(
            debounceTime(300),
            map(([list, searchValue, selectedFilters]) =>
                list.filter(template => this.filterByAccess(selectedFilters, template))
                    .filter((template => this.filterByText(template, searchValue))))
        ))
    );

    /** @internal */
    listLoading$: Observable<boolean> = EMPTY;
    /** @internal */
    listLoaded$: Observable<boolean> = EMPTY;

    /** @internal */
    ngOnInit(): void {
        this.list$.pipe(untilDestroyed(this)).subscribe(); // Keeps filter until data loaded
    }

    /** @internal */
    close: (result?: LoadTemplateDialogResult<T>) => void = () => null;

    /** @internal */
    setData(data: LoadTemplateDialogData<T>): void {
        this._data = data;
        this.listLoading$ = this._data.listLoading$;
        this.listLoaded$ = this._data.listLoaded$;
        this._data$.next(this._data.list$);
    }

    /** @internal */
    setClose(fn: (result?: LoadTemplateDialogResult<T>) => void): void {
        this.close = fn;
    }

    /** @internal */
    onSelect(template: T) {
        this.close({ upload: template });
    }

    /** @internal */
    onDelete(template: T) {
        if (!confirm('Do you really want to delete this template?')) {
            return;
        }

        this.close({ delete: template });
    }

    /** @private */
    private filterByAccess(selectedFilters: TemplateAccess[] | null, template: T) {
        if (!selectedFilters?.length || (selectedFilters.includes(TemplateAccess.Public) && selectedFilters.includes(TemplateAccess.Personal))) {
            return true;
        }

        if (selectedFilters.includes(TemplateAccess.Public) && template.access === TemplateAccess.Public) {
            return true;
        }

        if (selectedFilters.includes(TemplateAccess.Personal) && template.access === TemplateAccess.Personal) {
            return true;
        }

        return false;
    }

    /** @private */
    private filterByText(template: T, searchValue: string | null) {
        searchValue ??= '';

        if (template.name.toLowerCase().includes(searchValue.toLowerCase())) {
            return true;
        }

        if (template.description.toLowerCase().includes(searchValue.toLowerCase())) {
            return true;
        }

        if (template.author.toLowerCase().includes(searchValue.toLowerCase())) {
            return true;
        }

        if (this.datetimePipe.transform(template.updatedAt)?.includes(searchValue.toLowerCase())) {
            return true;
        }

        return false;
    }
}
