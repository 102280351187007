import { Injectable, Type } from '@angular/core';

import { PopupContent, PopupService, deepMerge } from '@cyberloop/core';
import { WidgetSettingsAdditionalConfig, WidgetSettingsHandler } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { firstValueFrom } from 'rxjs';

import { KpiActions } from '../state/kpi/kpi.actions';
import { KpiSelectors } from '../state/kpi/kpi.selectors';

@Injectable()
export class KpiWidgetSettingsService extends WidgetSettingsHandler {

    constructor(
        private readonly store: Store,
        private readonly popup: PopupService
    ) {
        super();
    }

    async showSettings<T, T2 = never>(widgetId: string, component: Type<PopupContent<T>>, defaultSettings?: T, additional?: WidgetSettingsAdditionalConfig<T2>): Promise<void> {
        // Get data from state
        const settings = await firstValueFrom(this.store.select(KpiSelectors.widgetSettings(widgetId)));
        const mergedSettings = deepMerge({}, [defaultSettings, settings]);

        // Show popup
        const newSettings = await this.popup.showForResult<T>(component, {
            data: { ...mergedSettings as any, ...additional?.data },
            overlaySettings: {
                width: '90vw',
                maxWidth: additional?.maxWidth ?? '23.75rem',
                maxHeight: additional?.maxHeight ?? '95vh'
            }
        });

        if (isNil(newSettings) || newSettings === false) {
            // Canceled
            return;
        }

        // Store data to state
        this.store.dispatch(KpiActions.updateWidgetSettings({ widgetId, settings: newSettings as any }));
    }

}
