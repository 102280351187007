
export enum WidgetType {
    Unknown = 'unknown',
    BitBottom = 'bit-bottom',
    ConnectionTime = 'connection-time',
    RigActivity = 'rig-activity',
    RigState = 'rig-state',
    AssetDailyMetrics = 'asset-daily-metrics',
    RigStatePerHour = 'rig-state-per-hour',
    DaysVsDepth = 'days-vs-depth',
    SlideRotary = 'slide-rotary',
    SlideVsRotary = 'slide-vs-rotary',
    TrippingSpeed = 'tripping-speed',
    CasingRunning = 'casing-running',
    RateOfAdvance = 'rate-of-advance',
    ROP = 'rop',
    CustomVsTime = 'custom-vs-time',
}
