<div (click)="onClick()" [class.disabled]="isDisabled" cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="wrap">
    <span *ngIf="value; else placeholderTemplate" class="value">{{ value | datetime: format }}</span>
    <ng-template #placeholderTemplate>
        <span class="placeholder">{{ placeholder }}</span>
    </ng-template>

    <icon name="calendar"></icon>
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="calendarVisible"
    (backdropClick)="calendarVisible = false" [cdkConnectedOverlayHasBackdrop]="true">
    <cyberloop-calendar (dateChange)="dateChange($event)" [startDate]="value" class="calendar"></cyberloop-calendar>
</ng-template>