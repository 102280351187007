import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'cyberloop-dd-charts-skeleton',
    standalone: true,
    templateUrl: './dd-charts-skeleton.component.html',
    styleUrls: ['../dd-charts/dd-charts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DdChartsSkeletonComponent {

    constructor() { }

}
