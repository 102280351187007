import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { map, skipWhile, tap, timer } from 'rxjs';

import { HotKeyDirective } from '../../directives';

/** Options of confirmation dialog */
export type DialogData = {
    /** Text */
    text?: string;
    /** Title */
    title?: string;
    /** Text for YES button */
    yesText?: string;
    /** Text for NO button */
    noText?: string;
    /** Autoclose timeout */
    timoutSec?: number;
    /** Default value for autoclose. Also useed to set focus and highlight button */
    defaultValue?: boolean
}

/** @internal */
@Component({
    standalone: true,
    imports: [
        MatDialogModule,
        AsyncPipe,
        NgIf,
        HotKeyDirective
    ],
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent {
    private _timer = -1;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private readonly ref: MatDialogRef<ConfirmationDialogComponent>
    ) {
        const timeout = data.timoutSec;

        this.defaultValue = data.defaultValue ?? false;

        if (typeof timeout === 'number' && Number.isFinite(timeout) && timeout > 0) {
            const timeout = data.timoutSec;
            if (typeof timeout === 'number' && Number.isFinite(timeout) && timeout > 0) {
                this._timer = timeout;
            }
        }
    }

    readonly defaultValue: boolean;

    get showTimeout() { return this._timer >= 0; }

    readonly timeLeft$ = timer(0, 1000).pipe(
        skipWhile(() => !this.showTimeout),
        map(() => {
            if (this._timer <= 0) {
                this.ref.close(this.defaultValue);
                return '';
            }

            return this._timer;
        }),
        tap(() => this._timer--),
    );
}
