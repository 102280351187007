<div class="head" #head>
    <span class="col">
        activity
    </span>

    <span class="col">
        Well phase
    </span>

    <!-- Est. Time -->

    <span class="col">
        Hours
    </span>

    <span class="col">
        Days
    </span>

    <span class="col">
        Cumulative
    </span>

    <!-- Depth -->

    <span class="col">
        Start depth <span class="unit">{{ depthUnitLabel }}</span>
    </span>

    <span class="col">
        End depth <span class="unit">{{ depthUnitLabel }}</span>
    </span>

    <span class="col">
        Interval <span class="unit">{{ depthUnitLabel }}</span>
    </span>

    <!-- TECHNICAL LIMIT -->

    <span class="col">
        Hours
    </span>

    <span class="col">
        Days
    </span>

    <span class="col">
        Cumulative
    </span>

    <span class="col">
        Comment
    </span>

    <span class="col">
    </span>

    <span class="col toggle">
        <button (click)="onToggle()" class="icon-button">
            <icon [name]="expanded ? 'arrow__down' : 'arrow__up'"></icon>
        </button>
    </span>
</div>

<div *syncScroll="[head]; dir: 'x'" [class.expanded]="expanded" class="content">
    <ng-container
        *ngFor="let stageGroup of stageArrayControls; last as last; index as index; empty: noData; trackBy: trackByStageControl"
        [formGroup]="stageGroup">
        <ng-container *ngVar="stageGroup.value; let stage">
            <span [ngClass]="RigActivity.getClassName(stage[StageControls.Activity])" class="col activity">
                {{ RigActivity.getName(stage[StageControls.Activity]) }}
            </span>

            <span class="col">
                <cl-input [formControl]="stageGroup.controls[StageControls.WellPhase]" [readonly]="disabled"
                    class="input"></cl-input>
            </span>

            <!-- Est. Time -->

            <span class="col">
                <cl-numeric-input [formControl]="stageGroup.controls[StageControls.EstTimeHours]" [readonly]="disabled"
                    class="input"></cl-numeric-input>
            </span>

            <span class="col">
                <cl-numeric-input [formControl]="stageGroup.controls[StageControls.EstTimeDays]" [readonly]="disabled"
                    class="input"></cl-numeric-input>

            </span>

            <span class="col">
                <cl-numeric-input [formControl]="stageGroup.controls[StageControls.EstTimeCumulative]"
                    [readonly]="disabled" class="input"></cl-numeric-input>
            </span>

            <!-- Depth -->

            <span class="col">
                <cl-numeric-input [formControl]="stageGroup.controls[StageControls.StartDepth]" [unit]="depthUnit"
                    [readonly]="disabled" class="input"></cl-numeric-input>
            </span>

            <span class="col">
                <cl-numeric-input [formControl]="stageGroup.controls[StageControls.EndDepth]" [unit]="depthUnit"
                    [readonly]="disabled" class="input"></cl-numeric-input>
            </span>

            <span class="col">
                <cl-numeric-input [formControl]="stageGroup.controls[StageControls.IntervalLength]" [unit]="depthUnit"
                    [readonly]="disabled" class="input"></cl-numeric-input>

            </span>

            <!-- Tech limit -->

            <span class="col">
                <cl-numeric-input [formControl]="stageGroup.controls[StageControls.TechLimitHours]"
                    [readonly]="disabled" class="input"></cl-numeric-input>
            </span>

            <span class="col">
                <cl-numeric-input [formControl]="stageGroup.controls[StageControls.TechLimitDays]" [readonly]="disabled"
                    class="input"></cl-numeric-input>
            </span>

            <span class="col">
                <cl-numeric-input [formControl]="stageGroup.controls[StageControls.TechLimitCumulative]"
                    [readonly]="disabled" class="input"></cl-numeric-input>
            </span>

            <span class="col comment">
                <cl-input [formControl]="stageGroup.controls[StageControls.Comment]" [readonly]="disabled"
                    class="input"></cl-input>
            </span>

            <span class="col attach">
                <cl-attach-files [fileList]="stageFileMap?.[stage.id]" (add)="onAddFile(stage.id, stage.activity)"
                    (download)="onDownloadFile($event)" (delete)="onDeleteFile($event)" [disabled]="disabled">
                </cl-attach-files>
            </span>

            <span class="col menu">
                <button [matMenuTriggerFor]="rowMenu" #rowMenuTrigger="matMenuTrigger"
                    [class.opened]="rowMenuTrigger?.menuOpen" [disabled]="disabled" class="row-menu-button icon-button">
                    <icon name="dots__vertical"></icon>
                </button>

                <mat-menu #rowMenu="matMenu" xPosition="before" class="planning-stages-menu">
                    <button mat-menu-item (click)="onInsertAboveStage(index)">
                        <icon name="insert__above"></icon>
                        Insert above
                    </button>
                    <button mat-menu-item (click)="onInsertBelowStage(index)">
                        <icon name="insert__below"></icon>
                        Insert below
                    </button>
                    <button mat-menu-item (click)="onCopyPasteAboveStage(index)">
                        <icon name="insert__above"></icon>
                        copy paste above
                    </button>
                    <button mat-menu-item (click)="onCopyPasteBelowStage(index)">
                        <icon name="insert__below"></icon>
                        copy paste below
                    </button>
                    <button mat-menu-item (click)="onEditStage(index)">
                        <icon name="edit"></icon>
                        Edit activity
                    </button>
                    <button mat-menu-item (click)="onDeleteStage(index)">
                        <icon name="delete"></icon>
                        Delete
                    </button>
                </mat-menu>
            </span>

            <div *ngIf="!last" class="divider"></div>
        </ng-container>
    </ng-container>
    <ng-template #noData>
        <div class="no-data">No data</div>
    </ng-template>
</div>

<div *ngIf="!readonly" class="footer">
    <button (click)="onAddStage()" [disabled]="disableActions" class="add-button">
        <icon name="plus"></icon>
        <span class="text">add stage</span>
    </button>
</div>
