import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'cyberloop-animated-menu-icon',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './animated-menu-icon.component.html',
    styleUrls: ['./animated-menu-icon.component.scss']
})
export class AnimatedMenuIconComponent {
    @Input() activated  = false;
}
