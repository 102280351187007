<div class="chart-container" [class.skeleton]="skeleton">
  <ng-container *ngIf="!skeleton">
    <section class="left-col">
      <div class="legend">
        <div class="item">
          <div class="point accent-4"></div> Planned
        </div>
        <div class="item">
          <div class="point prim"></div> Real
        </div>
      </div>
  
      <div class="buttons">
        <button matTooltip="Refresh charts" matTooltipPosition="below"><icon name="dd__circle"></icon></button>
        <button matTooltip="Inspect all projection" matTooltipPosition="below"><icon name="dd__hide"></icon></button>
        <button matTooltip="Inspect pdp projection only" matTooltipPosition="below"><icon name="dd__zoom"></icon></button>
        <button matTooltip="Reset zoom" matTooltipPosition="below"><icon name="dd__full"></icon></button>
        <button><icon name="plus"></icon></button>
      </div>
    </section>
    <section class="charts">
      <div class="chart">
        <cyberloop-dd-chart 
          [xAxisTitle]="topChartXAxisTitle" 
          [yAxisTitle]="topChartYAxisTitle"
          [data]="topChartData"
          [activatedSeries]="indexesOfSelectedSections"
        ></cyberloop-dd-chart>
      </div>
      <div class="chart">
        <cyberloop-dd-chart 
          [xAxisTitle]="bottomChartXAxisTitle" 
          [yAxisTitle]="bottomChartYAxisTitle"
          [data]="bottomChartData"
          [activatedSeries]="indexesOfSelectedSections"
        ></cyberloop-dd-chart>
      </div>
    </section>
    <section class="right-col" scrollShadow>
      <div class="type-selector">
        <cl-checkbox-group [selected]="[selectedChartType]" (selectedChange)="typeSelect.emit($event)" 
          [list]="chartTypes" class="button-toggle" [radio]="true"></cl-checkbox-group>
      </div>
      
      <div class="label-container">
        <label>Wellbores</label>
        <hr>
      </div>
      
      <div class="wellbores">
        <cl-checkbox-group [selected]="selectedSections" (selectedChange)="sectionSelect.emit($event)"
              [list]="sections ?? []" [cutCorners]="true" [colors]="seriesColors"></cl-checkbox-group>
      </div>
  
      <div class="plan-container">
        <label>Current wellplan</label>
        <cl-selector [options]="wellPlansOptions" (valueChange)="planSelect.emit($event)" [value]="selectedWellPlan"></cl-selector>
      </div>
    </section>
  </ng-container>
</div>
