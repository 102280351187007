import { Range } from '@cyberloop/core';
import { DrillingWorkspace, Mode, Orientation, defaultDrillingChartSettings } from '@cyberloop/web/wells/model';

export const DRILLING_FEATURE = 'DRILLING';


/**
 * State for drilling
 * */
export interface DrillingState {
    orientation: Orientation;
    mode: Mode;
    selectedDepthWsId: string,
    selectedTimeWsId: string,
    depthWs: DrillingWorkspace[],
    timeWs: DrillingWorkspace[],

    time: Range | undefined;
    depth: Range | undefined;

    naviTime: Range | undefined;
    naviDepth: Range | undefined;

    settingsLoaded: boolean;
    exportLoading: boolean;
    chartsLoading: number;
    liveSubscriptionStatus: boolean;

    live: boolean;

    sectionId: string | null;

    lastLiveWellDepth: number | undefined;
}

export function getDefaultDrillingState(): DrillingState {
    return {
        ...defaultDrillingChartSettings,
        time: undefined,
        depth: undefined,
        naviTime: undefined,
        naviDepth: undefined,

        settingsLoaded: false,
        exportLoading: false,
        chartsLoading: 0,

        live: false,
        sectionId: null,

        lastLiveWellDepth: undefined,
        liveSubscriptionStatus: false
    };
}            
