export type WellPlanData = {
    md: number;
    inc: number;
    azi: number;
    tvd: number;
    ns: number;
    ew: number;
    dls: number;
    vsec: number;
    hOffset: number;
    thl: number;
}

export enum WellPlanDataFields {
    md = 'md',
    inc = 'inc',
    azi = 'azi',
    tvd = 'tvd',
    ns = 'ns',
    ew = 'ew',
    dls = 'dls',
    vsec = 'vsec',
    hOffset = 'hOffset',
    thl = 'thl'
}  

export type WellPlanTarget = {
    md: number;
    inc: number;
    azi: number;
    tvd: number;
    ns: number;
    ew: number;
    dls: number;
    vsa: number;
    comment: string | null;
}

export type WellPlan = {
    id: string;
    wellId: number;
    name: string;
    vsa: number | null;
    vsaManually: boolean | null;
    data: WellPlanData[] | null;
    targets: WellPlanTarget[] | null;
}

export enum WellPlanDataType {
    Plan = 'plan',
    Targets = 'targets'
}

export type WellPlanTable = WellPlanData | WellPlanTarget;