import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNil } from 'lodash';

import { UnitDescriptor, UnitGroup } from '../../models';
import { UNITS_FEATURE, UnitsState } from './units.state';

/** @internal */
const feature = createFeatureSelector<UnitsState>(UNITS_FEATURE);

/** @internal */
const unitGroups = createSelector(feature, state => state.groups);

/** @internal */
const unitsPresets = createSelector(feature, state => state.presets);

/** @internal */
const userPresetIsLoaded = createSelector(feature, state => state.userPresetLoadingState === 'LOADED');

/** @internal */
const userPresetIsLoading = createSelector(feature, state => state.userPresetLoadingState === 'LOADING');

/** @internal */
const siUnitsPreset = createSelector(unitsPresets, x => x['d5bd5da5-c2da-4c9d-bc56-19008b5d5c23'] ?? {});

/** @internal */
const userPreset = createSelector(feature, userPresetIsLoaded, (state, loaded) => loaded ? state.userPreset : undefined);

/** @internal */
const getUnitGroup = (groupId: UnitGroup['id']) => createSelector(unitGroups, groups => groups[groupId]);

/** @internal */
const getUnit = (groupId: UnitGroup['id'], unitId?: UnitDescriptor['id']) =>
    createSelector(unitGroups, groups => {
        if (isNil(groups) || isNil(unitId)) {
            return undefined;
        }

        return groups[groupId]?.units?.[unitId];
    });

/** @internal */
const getUserUnit = (groupId: UnitGroup['id'], unitId?: UnitDescriptor['id']) =>
    createSelector(userPreset, unitGroups, (preset, groups) => {
        if (isNil(preset)) {
            return undefined;
        }

        const selectedUnitId = preset?.mappings?.[groupId];
        return groups[groupId]?.units?.[unitId ?? selectedUnitId];
    });

/** @internal */
const getUserUnitLabel = (groupId: UnitGroup['id'], unitId?: UnitDescriptor['id']) =>
    createSelector(getUserUnit(groupId, unitId), state => state?.label);

/**
 * Selectors for units
 * */
export const UnitsSelectors = {
    /** Unit groups */
    unitGroups,
    /** Units presets */
    unitsPresets,
    /** Preset of current user */
    userPreset,
    /** True if user preset was loaded */
    userPresetIsLoaded,
    /** True if user preset is loading */
    userPresetIsLoading,
    /** Si units preset */
    siUnitsPreset,
    /** Get unit group by it's id */
    getUnitGroup,
    /** Get unit descriptor by group id and unit id */
    getUnit,
    /** Get user preset unit by unit group id */
    getUserUnit,
    /** Get user preset unit label by unit group id */
    getUserUnitLabel
};
