import { Injectable } from '@angular/core';

import { GraphQLMutation } from '../../internals/cl-gql';

import type { TorqueAndDragStoreModelResult } from '../../internals/tnd';
import type { TndChartModelDataset } from '@cyberloop/web/tnd/model';

type TorqueAndDragStoreModelInput = {
    ownerKind: 'WELL' | 'PLAN',
    ownerId: string,
    modelId?: string,
    modelName: string,
    valueUnitId?: string,
    depthUnitId: string,
    startDepth: number,
    endDepth: number,
    highTorqueAlarmLine?: string,
    dataSets: TndChartModelDataset[],
}

@Injectable({
    providedIn: 'root'
})
export class TorqueAndDragStoreTorqueModelMutation extends GraphQLMutation<TorqueAndDragStoreModelResult, TorqueAndDragStoreModelInput> {
    override readonly document = `
        mutation StoreTndDragModel(
            $ownerKind: TorqndragModelOwnerKind!,
            $ownerId: ID!,
            $modelId: ID,
            $modelName: String!,
            $valueUnitId: ID,
            $depthUnitId: ID!,
            $startDepth: Float,
            $endDepth: Float,
            $highTorqueAlarmLine: String,
            $dataSets: [TorqndragModelDataSetInput!]!
        ) {
            torqndrag {
                storeModel(
                    input: {
                        ownerKind: $ownerKind
                        ownerId: $ownerId
                        kind: TORQUE
                        id: $modelId
                        name: $modelName
                        valueUnitId: $valueUnitId
                        depthUnitId: $depthUnitId
                        startDepth: $startDepth
                        endDepth: $endDepth
                        highTorqueAlarmLine: $highTorqueAlarmLine
                        dataSets: $dataSets
                    }
                ) {
                    ... on MutationResult {
                        succeeded
                        entityId
                    }
                }
            }
        }
    `;
}