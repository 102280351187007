import { Directive, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { IRecentItemComponent, implementsIRecentItemComponent } from '@cyberloop/web/app/model';
import { Subject, filter, switchMap, takeUntil } from 'rxjs';

import { RecentItemsService } from '../services/recent-items.service';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'router-outlet',
    standalone: true
})
export class RecentItemsDirective implements OnDestroy {

    private readonly _isDestroyed$ = new Subject<void>();

    constructor(
      private readonly _routerOutlet: RouterOutlet,
      private readonly _recentItems: RecentItemsService
    ) { 
        this._routerOutlet.activateEvents.pipe(
            filter(component => implementsIRecentItemComponent(component)),
            switchMap(async (component: IRecentItemComponent) => await component.getComponentRecentInfo()),
            takeUntil(this._isDestroyed$)
        ).subscribe((result) => {
            this._recentItems.pushItem(result);
        });
    }

    ngOnDestroy(): void {
        this._isDestroyed$.complete();
    }

}
