import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'block-with-heading',
    standalone: true,
    templateUrl: './block-with-heading.component.html',
    styleUrls: ['./block-with-heading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockWithHeadingComponent {

  @Input() heading = 'Block';

}
