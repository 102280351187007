
import { CoreSelectors } from '@cyberloop/core';
import { getMomentsFromSection } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { EMPTY, Observable, combineLatest, distinctUntilChanged, map, switchMap } from 'rxjs';

import { KpiSelectors } from '../../state/kpi/kpi.selectors';
import { KpiDataService } from '../kpi-data.service';

import type { TrippingSpeedWidgetData, TrippingSpeedWidgetSettings } from '@cyberloop/web/wells/model';

export function getTrippingSpeedWidgetData(this: { store: Store, data: KpiDataService }, id: string): Observable<TrippingSpeedWidgetData> {
    return combineLatest([
        this.store.select(CoreSelectors.currentWell),
        this.store.select(KpiSelectors.widgetSettings<TrippingSpeedWidgetSettings>(id))
    ]).pipe(
        // Custom distinct function that prevents triggering query when things
        // needed for it have no changes
        distinctUntilChanged((prev, curr) =>
            prev[0]?.id === curr[0]?.id &&
            isEqual(prev[1], curr[1])
        ),
        switchMap(([well, settings]) => {
            if (!well) {
                return EMPTY;
            }

            const timeRangeObservable = getMomentsFromSection(well, settings?.sectionId);

            return timeRangeObservable.pipe(
                switchMap(timeRange => this.data.watchRigRateOfAdvance(
                    well.rig,
                    timeRange.since.clone().startOf('second').toDate(),
                    timeRange.until.clone().startOf('second').toDate()
                )),
                map(items => items.map(item => ({
                    startTime: new Date(item.startTime),
                    trippingIn: item.trippingIn,
                    trippingOut: item.trippingOut
                }))),
                map(items => ({ items }))
            );
        })
    );
}
