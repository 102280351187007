import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

/** Type of notification */
export enum NotificationType {
    /** Information */
    Info = 'info',
    /** Warning */
    Warning = 'warning',
    /** Error */
    Error = 'error'
}

/** @internal */
function isGroup(s: string | undefined): boolean {
    return Object.values(NotificationType).includes(s as NotificationType);
}

/** Use this service to show notifications */
@Injectable({ providedIn: 'root' })
export class NotificationService {
    /** @internal */
    constructor(private readonly toastr: ToastrService) { }

    /**
     * Show information notification
     * @param msg Message
     * @param title Title
     */
    public info(msg: string, title?: string) {
        this.show(msg, title, NotificationType.Info);
    }

    /**
     * Show warning notification
     * @param msg Message or error
     * @param title Title
     */
    public warn(msg: string | Error, title?: string) {
        this.show(msg, title, NotificationType.Warning);
    }

    /**
     * Show Error notification
     * @param msg Message or error
     * @param title Title
     */
    public error(msg: string | Error, title?: string) {
        this.show(msg, title, NotificationType.Error);
    }

    /**
     * Show notification
     * @param msg Message of error
     * @param group Type of notification
     */
    public show(msg: string | Error, group: NotificationType): void;
    /**
     * Show notification
     * @param msg Message of error
     * @param title Title
     * @param group Type of notification
     */
    public show(msg: string | Error, title?: string, group?: NotificationType): void;
    /** @internal */
    public show(msg: string | Error, titleOrSnackGroup?: string | NotificationType, group?: NotificationType): void {
        let title = isGroup(titleOrSnackGroup) ? undefined : titleOrSnackGroup;
        if (!group && !title && titleOrSnackGroup) {
            group = titleOrSnackGroup as unknown as NotificationType;
            titleOrSnackGroup = undefined;
        }

        if (msg instanceof Error) {
            if (msg.name === 'TimeoutError') {
                msg = 'Loading timeout';
                if (!group) {
                    group = NotificationType.Warning;
                }
            }
            else {
                msg = msg.message || 'Something went wrong';
                if (!group) {
                    group = NotificationType.Error;
                }
            }
        }

        if (typeof title !== 'string') {
            title = undefined;
        }

        switch (group) {
            case NotificationType.Info:
                this.toastr.info(msg, title);
                break;
            case NotificationType.Warning:
                this.toastr.warning(msg, title, {
                    progressAnimation: 'decreasing',
                    progressBar: true
                });
                break;
            case NotificationType.Error:
                this.toastr.error(msg, title, {
                    tapToDismiss: true,
                    disableTimeOut: true
                });
                break;
            default:
                console.warn('NotifyService', { msg, title: titleOrSnackGroup, group });
                break;
        }
    }
}
