import { Injectable } from '@angular/core';

import { Toolface } from '@cyberloop/web/wells/model';

import { GraphQLSubscription } from '../internals/cl-gql';

type QueryRequest = {
    rigName: string
}

type QueryResponse = {
    toolface?: Toolface[];
}

@Injectable({
    providedIn: 'root'
})
export class TFArrayDataSubscription extends GraphQLSubscription<QueryResponse, QueryRequest> {
    override readonly document = `subscription tfsubscription($rigName: String!) {
        toolface(rigName: $rigName) {
          sourceName
          value
        }
    }`;
}