import { TndChart, tndChartDefaultSettings } from '@cyberloop/web/tnd/model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TND_FEATURE, TndState } from './tnd.state';

/** @internal */
const feature = createFeatureSelector<TndState>(TND_FEATURE);

// T&D data loading state
// const dataLoading = createSelector(feature, state => state.dataState === 'LOADING');
// const dataLoaded = createSelector(feature, state => state.dataState === 'LOADED');
// const dataNotLoaded = createSelector(feature, state => state.dataState === 'NOT_LOADED');

// Charts settings

/** @internal */
const weightsChartSettings = createSelector(feature, state => state.weightsSettings ?? tndChartDefaultSettings);

/** @internal */
const torqueChartSettings = createSelector(feature, state => state.torqueSettings ?? tndChartDefaultSettings);

/** @internal */
const chartSettings = (type: TndChart) =>
    createSelector(feature, state => type === TndChart.Torque ? state.torqueSettings : state.weightsSettings);


/** @internal */
const weightsChartXUnitId = createSelector(weightsChartSettings, settings => settings.xUnitId);
/** @internal */
const torqueChartXUnitId = createSelector(torqueChartSettings, settings => settings.xUnitId);
/** @internal */
const xUnitId = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return torqueChartXUnitId;

        default:
            return weightsChartXUnitId;
    }
};


/** @internal */
const weightsChartYUnitId = createSelector(weightsChartSettings, settings => settings.yUnitId);
/** @internal */
const torqueChartYUnitId = createSelector(torqueChartSettings, settings => settings.yUnitId);
/** @internal */
const yUnitId = (type: TndChart) => {
    switch (type) {

        case TndChart.Torque:
            return torqueChartYUnitId;

        default:
            return weightsChartYUnitId;
    }
};


/**
 * Selectors for T&D
 * */
export const TndSelectors = {
    // dataLoading,
    // dataLoaded,
    // dataNotLoaded,

    weightsChartSettings,
    torqueChartSettings,
    chartSettings,

    weightsChartXUnitId,
    torqueChartXUnitId,
    xUnitId,

    weightsChartYUnitId,
    torqueChartYUnitId,
    yUnitId
};

/**
 * Public Selectors for Wells T&D
 * */
export const PublicTndSelectors = {
    // dataLoading,
    // dataLoaded,

    weightsChartSettings,
    torqueChartSettings,
    chartSettings,

    weightsChartXUnitId,
    torqueChartXUnitId,
    xUnitId,

    weightsChartYUnitId,
    torqueChartYUnitId,
    yUnitId
};