import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClDatepickerComponent, ClInputComponent, ClNumericInputComponent, ConfirmationDialogService, FormErrorsComponent, PopupContent } from '@cyberloop/core';
import { isNil } from 'lodash';

import { PromptDialogControlType, PromptDialogData, ValueType } from '../../models';
import { IconComponent } from '../icon/icon.component';

/** @internal */
@Component({
    standalone: true,
    imports: [
        NgIf,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault,
        ReactiveFormsModule,
        IconComponent,
        ClInputComponent,
        ClNumericInputComponent,
        ClDatepickerComponent,
        FormErrorsComponent
    ],
    templateUrl: './prompt.component.html',
    styleUrls: ['./prompt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromptComponent implements PopupContent<ValueType | undefined> {
    private _close!: (result?: ValueType) => void;

    constructor(
        private readonly confirmationDialogService: ConfirmationDialogService
    ) { }

    readonly PromptDialogControlType = PromptDialogControlType;

    readonly formControl = new FormControl<ValueType>(null);
    data?: PromptDialogData = undefined;


    setData(data: PromptDialogData): void {
        this.data = data;
        if (!isNil(data.value)) {
            this.formControl.setValue(data.value);
        }

        if (data.required) {
            this.formControl.addValidators(Validators.required);
        }
    }

    setClose(fn: (result?: ValueType) => void): void {
        this._close = fn;
    }

    onSave() {
        if (this.formControl.invalid) {
            this.formControl.markAllAsTouched();
            return;
        }

        this._close(this.formControl.value);
    }

    async close() {
        if (this.formControl.dirty) {
            if (!await this.confirmationDialogService.show('You have unsaved changes. Do you really want to close?')) {
                return;
            }
        }

        this._close();
    }
}
