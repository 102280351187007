import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { VersionEffects } from './version.effects';
import { versionReducer } from './version.reducer';
import { VERSION_FEATURE } from './version.state';

export const PlanningVersionProviders = [
    provideEffects([VersionEffects]),
    provideState(VERSION_FEATURE, versionReducer)
];
