import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';

import { LoginRequest } from '../../../../models/login-request';
import { AuthActions } from '../../../../state/auth/auth.actions';
import { AuthSelectors } from '../../../../state/auth/auth.selectors';
import { AuthRoutes } from '../../../routing';
import { SigninUIComponent } from '../../../ui/signin/signin.component';

/** @internal */
@Component({
    standalone: true,
    template: `<cyberloop-signin
    (tryLogin)="tryLogin($event)"
    (loadTenantList)="loadTenantList($event)"
    (restorePassword)="restorePassword()"
    [loading]="loading$|async"
    [listOfTenants]="listOfTenants$|async"
    [listOfTenantsBackgroundLoading]="listOfTenantsBackgroundLoading$|async"
    [error]="error$|async"
></cyberloop-signin>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        SigninUIComponent
    ]
})
export class SigninComponent {
    /**
     *
     */
    constructor(
        private readonly store: Store,
        private readonly router: Router,
    ) { }
    readonly loading$ = combineLatest([
        this.store.select(AuthSelectors.isLoggingIn),
        this.store.select(AuthSelectors.listOfTenantsLoading),
        this.store.select(AuthSelectors.listOfTenantsBackgroundLoading),
    ]).pipe(
        map(([isLoggingIn, listOfTenantsLoading, listOfTenantsBackgroundLoading]) => isLoggingIn || (listOfTenantsLoading && !listOfTenantsBackgroundLoading))
    );
    readonly listOfTenantsBackgroundLoading$ = this.store.select(AuthSelectors.listOfTenantsBackgroundLoading);
    readonly error$ = this.store.select(AuthSelectors.loginErrors);
    readonly listOfTenants$ = this.store.select(AuthSelectors.listOfTenants);

    tryLogin(req: LoginRequest) {
        this.store.dispatch(AuthActions.tryLogin(req))
    }

    loadTenantList({ login, background }: { login: string, background?: boolean }) {
        this.store.dispatch(AuthActions.loadTenantList({ login, background }));
    }

    restorePassword() {
        this.router.navigate([AuthRoutes.Root, AuthRoutes.ResetPassword]);
    }
}
