import { Directive, ElementRef, HostListener, OnDestroy, Renderer2 } from '@angular/core';

/** @internal */
const CLASS_TO_ADD = 'scroll-shadow--host';

/** @internal */
const CLASSES = `
div.${CLASS_TO_ADD} {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000;

    --overflow-shadow--size--internal: var(--overflow-shadow--size, 2rem);
    --overflow-shadow--color--internal: var(--overflow-shadow--color, currentBackground);
}

div.${CLASS_TO_ADD}::before,
div.${CLASS_TO_ADD}::after {
    content: "";
    position: absolute;
    inset: 0;

    --gradient-shadow--start: 0;
    --gradient-shadow--end: 100%;

    background: linear-gradient(var(--gradient-shadow--direction),
        var(--overflow-shadow--color--internal) 0%,
        transparent var(--gradient-shadow--start),
        transparent var(--gradient-shadow--end),
        var(--overflow-shadow--color--internal) 100%
    );

    opacity: 0;

    transition: opacity 1s;
}

div.${CLASS_TO_ADD}::after {
    --gradient-shadow--direction: 90deg;
}
div.${CLASS_TO_ADD}::before {
    --gradient-shadow--direction: 180deg;
}

div.${CLASS_TO_ADD}.shadow-top:not(.shadow-bottom)::before {
    --gradient-shadow--start: calc(100% - calc(100% - var(--overflow-shadow--size--internal)));
    opacity: 1;
}
div.${CLASS_TO_ADD}.shadow-bottom:not(.shadow-top)::before {
    --gradient-shadow--end: calc(100% - var(--overflow-shadow--size--internal));
    opacity: 1;
}
div.${CLASS_TO_ADD}.shadow-top.shadow-bottom::before {
    --gradient-shadow--start: calc(100% - calc(100% - var(--overflow-shadow--size--internal)));
    --gradient-shadow--end: calc(100% - var(--overflow-shadow--size--internal));
    opacity: 1;
}

div.${CLASS_TO_ADD}.shadow-left:not(.shadow-right)::after {
    --gradient-shadow--start: calc(100% - calc(100% - var(--overflow-shadow--size--internal)));
    opacity: 1;
}
div.${CLASS_TO_ADD}.shadow-right:not(.shadow-left)::after {
    --gradient-shadow--end: calc(100% - var(--overflow-shadow--size--internal));
    opacity: 1;
}
div.${CLASS_TO_ADD}.shadow-left.shadow-right::after {
    --gradient-shadow--start: calc(100% - calc(100% - var(--overflow-shadow--size--internal)));
    --gradient-shadow--end: calc(100% - var(--overflow-shadow--size--internal));
    opacity: 1;
}
`;


/** This directive addes shadow dirung scroll if any */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[scrollShadow]',
    standalone: true
})
export class ScrollShadowDirective implements OnDestroy {
    /** @private */
    private _disposed = false;
    /** @private */
    private _position: string | undefined;
    /** @private */
    private _shadowContainer: HTMLDivElement | undefined;

    /** @internal */
    constructor(private el: ElementRef<HTMLElement>, private renderer: Renderer2) {
        addRef();

        const position = window.getComputedStyle(el.nativeElement).position;
        if (!position || position === 'initial' || position === 'unset') {
            this._position = position;
            el.nativeElement.style.position = 'relative';
        }

        this._shadowContainer = document.createElement('div');
        this._shadowContainer.classList.add(CLASS_TO_ADD);

        this.el.nativeElement.append(this._shadowContainer);
        setTimeout(() => {
            el.nativeElement.scrollBy(0, 1);
            el.nativeElement.scrollBy(0, -1);
        });
    }

    /** @internal */
    ngOnDestroy(): void {
        if (this._disposed) {
            return;
        }
        this._disposed = true;
        removeRef();

        if (typeof this._position !== 'undefined') {
            this.el.nativeElement.style.position = this._position;
        }
        this._shadowContainer?.remove();
        this._shadowContainer = undefined;
    }


    /** @internal */
    @HostListener('scroll')
    onScroll() {
        const div = this._shadowContainer;
        if (!div) {
            return;
        }

        const target = this.el.nativeElement;

        div.style.left = `${target.scrollLeft}px`;
        div.style.top = `${target.scrollTop}px`;

        // Show left shadow if content overflows on left side
        if (target.scrollLeft > 0) {
            this.renderer.addClass(this._shadowContainer, 'shadow-left');
        } else {
            this.renderer.removeClass(this._shadowContainer, 'shadow-left');
        }

        // Show right shadow if content overflows on right side
        if (target.scrollWidth - target.clientWidth - target.scrollLeft > 0) {
            this.renderer.addClass(this._shadowContainer, 'shadow-right');
        } else {
            this.renderer.removeClass(this._shadowContainer, 'shadow-right');
        }

        // Show top shadow if content overflows on top side
        if (target.scrollTop > 0) {
            this.renderer.addClass(this._shadowContainer, 'shadow-top');
        } else {
            this.renderer.removeClass(this._shadowContainer, 'shadow-top');
        }

        // Show bottom shadow if content overflows on bottom side
        if (target.scrollHeight - target.clientHeight - target.scrollTop > 0) {
            this.renderer.addClass(this._shadowContainer, 'shadow-bottom');
        } else {
            this.renderer.removeClass(this._shadowContainer, 'shadow-bottom');
        }
    }

}

/** @internal */
let ref = 0;
/** @internal */
let styleBlock: HTMLStyleElement | undefined;
/** @internal */
function addRef() {
    ref++;
    if (!styleBlock) {
        styleBlock = document.createElement('style');
        styleBlock.id = "SCROLL-SHADOW";
        styleBlock.textContent = CLASSES;
        document.body.append(styleBlock);
    }
}

/** @internal */
function removeRef() {
    if (ref <= 0) {
        ref = 0;
        return;
    }

    ref--;
    if (ref === 0) {
        styleBlock?.remove();
        styleBlock = undefined;
    }
}
