import { Injectable } from '@angular/core';

import { ClError, ID, uuid } from '@cyberloop/core';
import { PlanningProviderService } from '@cyberloop/web/planning/shared/data';
import { CreatePlanning, InitPlanningVersion, Planning, PlanningVersion, UpdatePlanning } from '@cyberloop/web/planning/shared/model';
import { isNil } from 'lodash';
import { EMPTY, Observable, finalize, map, shareReplay, switchMap } from 'rxjs';

import { FirestoreDataConverter, limit, orderBy, where } from 'firebase/firestore';
import * as moment from 'moment';

import { DBCreatePlanning, DBCreatePlanningVersion, DBPlanning, DBSetActiveVersion, DBSetWellID, DBUpdatePlanning } from '../models/planning';
import { PLANNING_VERSIONS_COLLECTION_NAME } from './firebase-planning-version-provider.service';
import { ClApplicationManager } from './internals/client-app/cl-app-manager';

import type { ClApplication } from './internals/client-app/cl-application';

export const PLANNING_COLLECTION_NAME = 'planning';

@Injectable({
    providedIn: 'root'
})
export class FirebasePlanningProviderLinkService extends PlanningProviderService {
    private readonly _converter: FirestoreDataConverter<Planning> = {
        toFirestore: (data: Planning) => data,
        fromFirestore: (snapshot, options): Planning => {
            const data = snapshot.data(options) as DBPlanning;

            return {
                ...data,
                plannedStartDate: isNil(data.plannedStartDate) ? null : moment(data.plannedStartDate),
                createdAt: moment(data.createdAt),
                updatedAt: moment(data.updatedAt),
                duration: isNil(data.duration) ? moment.duration(0) : moment.duration(data.duration)
            };
        }
    };

    private _listObservable: Observable<Planning[]> | undefined;
    private _planningObservables: Record<ID, Observable<Planning | null> | undefined> = {};
    private _planningByWellIdObservables: Record<ID, Observable<Planning | null> | undefined> = {};

    constructor(private readonly appMgr: ClApplicationManager) {
        super();
    }

    watchAll() {
        return this._listObservable ??= this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().watchCollection<Planning>(
                    PLANNING_COLLECTION_NAME,
                    this._converter,
                    [orderBy('createdAt', 'desc')]
                ) ?? EMPTY
            ),
            finalize(() => this._listObservable = undefined),
            shareReplay(1)
        );
    }

    watch(planningId: ID) {
        return this._planningObservables[planningId] ??= this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().watch<Planning>(
                    this.getPath(PLANNING_COLLECTION_NAME, planningId),
                    this._converter
                ) ?? EMPTY
            ),
            finalize(() => delete this._planningObservables[planningId]),
            shareReplay(1)
        );
    }

    get(planningId: ID) {
        return this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().get<Planning>(
                    this.getPath(PLANNING_COLLECTION_NAME, planningId),
                    this._converter
                ) ?? EMPTY
            ),
            shareReplay(1)
        );
    }

    watchByWellId(wellId: ID) {
        return this._planningByWellIdObservables[wellId] ??= this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().watchCollection<Planning>(
                    PLANNING_COLLECTION_NAME,
                    this._converter,
                    [
                        where('wellId', '==', wellId),
                        limit(1)
                    ]
                ) ?? EMPTY
            ),
            map(list => {
                if (list?.length) {
                    return list.at(0) as Planning;
                }

                return null;
            }),
            finalize(() => delete this._planningObservables[wellId]),
            shareReplay(1)
        );
    }

    create(createPlanning: CreatePlanning, initVersion: InitPlanningVersion) {
        const planningId = uuid();
        const versionId = uuid();

        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    await transaction.set<DBCreatePlanning>(
                        this.getPath(PLANNING_COLLECTION_NAME, planningId),
                        {
                            ...createPlanning,
                            id: planningId,
                            activeVersionId: versionId,
                            plannedStartDate: isNil(createPlanning.plannedStartDate) ? null : createPlanning.plannedStartDate?.valueOf(),
                            createdAt: moment.now(),
                            updatedAt: moment.now()
                        }
                    );

                    await transaction.set<DBCreatePlanningVersion>(
                        this.getPath(PLANNING_COLLECTION_NAME, planningId, PLANNING_VERSIONS_COLLECTION_NAME, versionId),
                        {
                            ...initVersion,
                            id: versionId,
                            planningId,
                            changedBy: this.getUserEmail(x),
                            createdAt: moment.now(),
                            updatedAt: moment.now()
                        }
                    );
                }) ?? EMPTY),
                switchMap(() => this.get(planningId) as Observable<Planning>),
                shareReplay(1)
            );
    }

    update(planningId: ID, updatePlanning: UpdatePlanning) {
        return this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().update<DBUpdatePlanning>(
                    this.getPath(PLANNING_COLLECTION_NAME, planningId),
                    {
                        ...updatePlanning,
                        rigName: isNil(updatePlanning.rigId) ? '' : updatePlanning.rigName,
                        plannedStartDate: isNil(updatePlanning.plannedStartDate) ? null : updatePlanning.plannedStartDate?.valueOf(),
                        updatedAt: moment.now()
                    }
                ) ?? EMPTY
            ),
            switchMap(() => this.get(planningId) as Observable<Planning>),
            shareReplay(1)
        );
    }

    setActiveVersion(planningId: ID, version: PlanningVersion) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x =>
                    x?.getFirestore().update<DBSetActiveVersion>(
                        this.getPath(PLANNING_COLLECTION_NAME, planningId),
                        {
                            activeVersionId: version.id,
                            updatedAt: moment.now()
                        }
                    ) ?? EMPTY
                ),
                switchMap(() => this.get(planningId) as Observable<Planning>),
                shareReplay(1)
            );
    }

    setWellId(planningId: ID, wellId: ID) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x =>
                    x?.getFirestore().runTransaction(async (transaction) => {
                        const planningList = await x.getFirestore().getCollection(
                            PLANNING_COLLECTION_NAME,
                            this._converter,
                            [
                                where('wellId', '==', wellId),
                                limit(1)
                            ]
                        );

                        if (planningList.length) {
                            await Promise.all(
                                planningList.map(p => transaction.update<DBSetWellID>(
                                    this.getPath(PLANNING_COLLECTION_NAME, p.id),
                                    {
                                        wellId: null,
                                        updatedAt: moment.now()
                                    }
                                ))
                            );
                        }

                        await transaction.update<DBSetWellID>(
                            this.getPath(PLANNING_COLLECTION_NAME, planningId),
                            {
                                wellId: wellId,
                                updatedAt: moment.now()
                            }
                        );
                    }
                    ) ?? EMPTY
                ),
                switchMap(() => this.get(planningId) as Observable<Planning>),
                shareReplay(1)
            );
    }

    private getUserEmail(x?: ClApplication) {
        const email = x?.getAuth().currentUser?.email;

        if (isNil(email)) {
            throw new ClError('User email not found');
        }

        return email;
    }

    private getPath(...args: string[]) {
        return args.join('/');
    }
}
