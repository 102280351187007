<form [formGroup]="form" (submit)="onSubmit()" class="form">
    <fieldset class="fieldset">
        <cl-input [formControlName]="Controls.login" markTouchedOn="blur" type="email" placeholder="E-mail"></cl-input>
        <cl-form-errors [control]="loginControl"></cl-form-errors>
    </fieldset>

    <fieldset *ngIf="tenantListLoaded" class="fieldset">
        <cl-selector [formControlName]="Controls.tenant" [options]="listOfTenants ?? []"
            placeholder="Pick tenant"></cl-selector>
        <cl-form-errors [control]="tenantIdControl"></cl-form-errors>
    </fieldset>

    <button type="submit" [disabled]="form.disabled" class="cl-primary-button send-button">Send restore link</button>

    <pseudo-loader *ngIf="loading"></pseudo-loader>

    <div *ngIf="error" class="error">{{ error }}</div>

    <button (click)="backToLogin.emit()" type="button" class="cl-button back-button">
        <icon name="logout"></icon>
        Back to login
    </button>
</form>