/**
 * Permission ids enum
 */
export enum PermissionIDs {
    Wells = 10000,

    Drilling = 10100,
    SendNotesToRig = 10101,

    Export = 10200,
    ExportData = 10201,

    Reports = 10300,
    ViewTourReport = 10301,
    ViewDDreports = 10302,
    ReportsDownload = 10303,

    DirectionalDrilling = 10400,
    DirectionalDrillingExport = 10401,

    Forecast = 10500,
    ForecastEdit = 10501,
    ForecastExport = 10502,

    Meterage = 10600,
    MeterageEdit = 10601,
    MeterageExport = 10602,

    KPI = 10700,
    KPIDownload = 10701,

    Engineering = 10800,
    EngineeringAddEditModels = 10801,
    EngineeringExport = 10802,

    Planning = 20000,

    PlanningView = 20100,
    PlanningEdit = 20101,

    PlanningFiles = 20200,
    PlanningFilesEdit = 20201,

    RiskRegister = 20300,
    RiskRegisterEdit = 20301,

    GanttChart = 30000,
    GanttChartView = 30100,
    GanttChartEdit = 30101,
}
