import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ClWellChartComponent, ClWellChartLegendComponent, UnitsSelectors, WellKnownUnitGroupIds } from '@cyberloop/core';
import { PlanningActions } from '@cyberloop/web/planning/shared/data';
import { KpiActions, KpiSelectors } from '@cyberloop/web/wells/data';
import { WidgetDataProvider, WidgetSettingsHandler, WidgetSize, WidgetType, daysVsDepthDefaultSettings } from '@cyberloop/web/wells/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, filter, map, switchMap, tap } from 'rxjs';

import { KpiWidgetComponent } from '../widget/kpi-widget.component';
import { SettingsComponent } from './settings/settings.component';

import type { UnitDescriptor } from '@cyberloop/core';
import type { Points, SimpleChangesOf } from '@cyberloop/core';
import type { KpiWidget } from '@cyberloop/web/wells/model';
import type { AdditionalData } from './models';
import type { DaysVsDepthWidgetSettings } from '@cyberloop/web/wells/model';
import type { SectionInput } from '@cyberloop/core';

@Component({
    selector: 'cyberloop-days-vs-depth-widget',
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        KpiWidgetComponent,
        ClWellChartComponent,
        ClWellChartLegendComponent
    ],
    templateUrl: './days-vs-depth-widget.component.html',
    styleUrls: ['./days-vs-depth-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class DaysVsDepthWidgetComponent implements KpiWidget, OnInit, OnDestroy, OnChanges {

    private readonly _id$ = new BehaviorSubject<string>('-');
    private readonly _dataLoading$ = new BehaviorSubject<boolean>(true);

    private readonly _wde$ = new BehaviorSubject<Points>([]);
    private readonly _bde$ = new BehaviorSubject<Points>([]);

    private readonly _plan$ = new BehaviorSubject<Points>([]);
    private readonly _planLimit$ = new BehaviorSubject<Points>([]);

    private readonly _sections$ = new BehaviorSubject<SectionInput[]>([]);
    private readonly _depthUnit$ = new BehaviorSubject<UnitDescriptor | null>(null);

    constructor(
        private readonly store: Store,
        private readonly settings: WidgetSettingsHandler,
        private readonly data: WidgetDataProvider
    ) {
        const idPipe = this._id$.pipe(
            untilDestroyed(this),
            filter(Boolean),
            tap(() => this._dataLoading$.next(true))
        );

        combineLatest([
            // Get widget data
            idPipe.pipe(
                switchMap(id => this.data.getData(id, WidgetType.DaysVsDepth))
            ),
            // Get widget settings (for units conversion)
            idPipe.pipe(
                switchMap(id => this.store.select(KpiSelectors.widgetSettings<DaysVsDepthWidgetSettings>(id))),
                switchMap(widgetSettings => this.store.select(UnitsSelectors.getUnit(
                    WellKnownUnitGroupIds.Length,
                    widgetSettings?.depthUnitId ?? daysVsDepthDefaultSettings.depthUnitId
                )))
            )
        ]).subscribe(([widgetData, unit]) => {
            this._wde$.next(widgetData.wde);
            this._bde$.next(widgetData.bde);

            this._plan$.next(widgetData.plan);
            this._planLimit$.next(widgetData.planLimit);

            this._sections$.next(widgetData.sections);
            this._depthUnit$.next(unit ?? null);

            this._dataLoading$.next(false);
        });
    }

    readonly dataLoading$ = this._dataLoading$.asObservable();

    readonly wde$ = this._wde$.asObservable();
    readonly bde$ = this._bde$.asObservable();

    readonly plan$ = this._plan$.asObservable();
    readonly hasPlan$ = this.plan$.pipe(
        map(data => data && data.length > 0)
    );
    readonly planLimit$ = this._planLimit$.asObservable();
    readonly hasPlanLimit$ = this.plan$.pipe(
        map(data => data && data.length > 0)
    );

    readonly sections$ = this._sections$.asObservable();
    readonly depthUnit$ = this._depthUnit$.asObservable();

    @Input() id = '-';
    @Input() size = WidgetSize.Medium;

    @ViewChild(ClWellChartComponent, { static: true }) chart?: ClWellChartComponent;
    @ViewChild(ClWellChartLegendComponent) legend?: ClWellChartLegendComponent;

    ngOnInit(): void {
        this.store.dispatch(PlanningActions.loadPlanningByCurrentWell({}));
    }

    ngOnDestroy(): void {
        this.store.dispatch(PlanningActions.unwatchPlanning());
    }

    ngOnChanges(changes: SimpleChangesOf<DaysVsDepthWidgetComponent>): void {
        if (changes.id) {
            this._id$.next(changes.id.currentValue);
        }
    }

    async onSettings(): Promise<void> {
        await this.settings.showSettings<DaysVsDepthWidgetSettings, AdditionalData>(
            this.id,
            SettingsComponent,
            daysVsDepthDefaultSettings,
            {
                data: {
                    // Need this to draw range picker
                    bde: this._bde$.value,
                    wde: this._wde$.value,
                    sections: this._sections$.value
                }
            }
        );
    }

    onDelete(): void {
        this.store.dispatch(KpiActions.deleteWidget({ widgetId: this.id }));
    }

}
