<cyberloop-kpi-widget heading="Asset Daily Metrics" [size]="size" class="widget" (settingsRequested)="onSettings()" (deleteRequested)="onDelete()">
    <!-- Table -->
    <div widget-body class="table-container" [class.loading]="dataLoading$ | async">

        <div class="table-content" *ngVar="displayedColumns as columns">
            <!-- <div class="signal-item signal-item-top"></div> -->

            <table *ngIf="tableRows$ | async as rows" #table mat-table [dataSource]="rows">

                <!-- Activity Column -->
                <ng-container matColumnDef="metric">
                    <th mat-header-cell *matHeaderCellDef>Metric</th>
                    <td mat-cell *matCellDef="let element" [attr.data-text]="element.metric">{{element.metric}}</td>
                </ng-container>

                <!-- 12h Column -->
                <ng-container matColumnDef="value12h">
                    <th mat-header-cell *matHeaderCellDef>12 hours</th>
                    <td mat-cell *matCellDef="let element" [attr.data-text]="element.value12h">{{element.value12h}}</td>
                </ng-container>

                <!-- 24h Column -->
                <ng-container matColumnDef="value24h">
                    <th mat-header-cell *matHeaderCellDef>24 hours</th>
                    <td mat-cell *matCellDef="let element" [attr.data-text]="element.value24h">{{element.value24h}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columns"></tr>
            </table>

            <!-- <div class="signal-item signal-item-bottom"></div> -->
        </div>

        <div *ngIf="textPlaceholder$ | async" class="placeholder">
            <p>{{ textPlaceholder$ | async }}</p>
        </div>
    </div>
</cyberloop-kpi-widget>
