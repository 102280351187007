import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ViewChild, forwardRef } from '@angular/core';
import { AsyncValidatorFn, ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidatorFn } from '@angular/forms';

import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { FormControlAccessorDirective } from '../../directives/form-control-accessor.directive';

/** @internal */
type ChangeMethod = (x: unknown) => void;
/** @internal */
type TouchMethod = () => void;

/** Custom input control */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cl-input',
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        FormsModule,
        ReactiveFormsModule,
        FormControlAccessorDirective
    ],
    templateUrl: './cl-input.component.html',
    styleUrls: ['./cl-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ClInputComponent), multi: true }
    ]
})
export class ClInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    /** @private */
    private readonly _forceText$ = new BehaviorSubject(false);
    /** @private */
    private readonly _isDestroyed$ = new Subject<void>();

    /** @private */
    private _validators: ValidatorFn[] = [];
    /** @private */
    private _asyncValidators: AsyncValidatorFn[] = [];
    /** @private */
    private _registerOnChange: ChangeMethod | undefined;
    /** @private */
    private _onTouched: TouchMethod | undefined;
    /** @private */
    private _input: FormControlAccessorDirective<HTMLInputElement> | undefined;
    /** @private */
    private _readonly = false;
    /** @private */
    private markTouchebCb;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        this.markTouchebCb = () => {
            this._onTouched?.();
            this.changeDetectorRef.markForCheck();
        };
    }

    /** Type of the input */
    @Input() type: 'text' | 'email' | 'password' = 'text';
    /** Placeholder to be shown if no value provided */
    @Input() placeholder: number | string | null | undefined;
    /** Alignment field */
    @Input() alignment: 'center' | 'start' | 'end' = 'start';
    /** Readonly */
    @Input() set readonly(value: boolean | null | undefined) {
        this._readonly = value ?? false;
    }
    /** @internal */
    get readonly() {
        return this._readonly;
    }
    /** Mark the control touched */
    @Input() markTouchedOn: 'focus' | 'blur' = 'focus';
    /** Validators to be attached to the input */
    @Input()
    public set validators(value: ValidatorFn[] | ValidatorFn | null | undefined) {
        this.ctrl.removeValidators(this._validators);
        if (!value) {
            value = [];
        }
        if (!Array.isArray(value)) {
            value = [value];
        }
        this._validators = value;
        this.ctrl.addValidators(this._validators);
    }
    /** @internal */
    public get validators(): ValidatorFn[] {
        return this._validators;
    }

    /** Async validators to be attached to the input */
    @Input()
    public set asyncValidators(value: AsyncValidatorFn[] | AsyncValidatorFn | null | undefined) {
        this.ctrl.removeAsyncValidators(this._asyncValidators);
        if (!value) {
            value = [];
        }
        if (!Array.isArray(value)) {
            value = [value];
        }
        this._asyncValidators = value;
        this.ctrl.addAsyncValidators(this._asyncValidators);
    }
    /** @internal */
    public get asyncValidators(): AsyncValidatorFn[] {
        return this._asyncValidators;
    }

    /** @internal */
    readonly ctrl = new FormControl<string | number | null>(null, { updateOn: 'change' });

    /** @internal */
    readonly forceText$ = this._forceText$;

    @ViewChild(FormControlAccessorDirective)
    public set input(value: FormControlAccessorDirective<HTMLInputElement> | undefined) {
        this._input = value;

        if (value) {
            value.el.nativeElement.addEventListener(this.markTouchedOn, this.markTouchebCb);
        }
    }
    /** @internal */
    public get input(): FormControlAccessorDirective<HTMLInputElement> | undefined {
        return this._input;
    }

    /** @internal */
    ngAfterViewInit(): void {
        this.ctrl.valueChanges.pipe(
            takeUntil(this._isDestroyed$),
        ).subscribe((x) => this._registerOnChange?.(x));
    }

    /** @internal */
    ngOnDestroy(): void {
        this._isDestroyed$.next();
        this._input?.el.nativeElement.removeEventListener(this.markTouchedOn, this.markTouchebCb);
    }

    /** @internal */
    writeValue(obj: string | number | null): void {
        let str = obj;

        if (typeof str === 'number') {
            str = String(obj);
        }

        this.ctrl.patchValue(str, { emitEvent: false });
    }

    /** @internal */
    registerOnChange(fn: ChangeMethod): void {
        this._registerOnChange = fn;
    }
    /** @internal */
    registerOnTouched(fn: TouchMethod): void {
        this._onTouched = fn;
    }
    /** @internal */
    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.ctrl.disable({ emitEvent: false });
        }
        else {
            this.ctrl.enable({ emitEvent: false });
        }
    }

    /** @internal */
    togglePasswordVisibility() {
        this._forceText$.next(!this._forceText$.value);
    }
}
