import { Injectable } from '@angular/core';

import { Survey } from '@cyberloop/web/wells/model';

import { GraphQLQuery } from '../internals/cl-gql';

type QueryRequest = {
    wellID: string
}

type QueryResponse = {
    surveys: Survey[];
}

@Injectable({
    providedIn: 'root'
})
export class SurveyDataQuery extends GraphQLQuery<QueryResponse, QueryRequest> {
    override readonly document = `query survey($wellID: String!) {
        surveys(wellId: $wellID) {
            vsec,
            hOffset,
            thl,
            ew,
            tvd,
            tvdss,
            md,
            hd,
            dls,
            inc,
            incCor,
            aziCor,
            ns,
            ID,
            sectionID,
            selected,
            number
        }
    }`;
}