
import { Injectable } from '@angular/core';

import { Asset, Assets, AssetsInfoProviderService } from '@cyberloop/core';
import { isEqual } from 'lodash';
import { Observable, distinctUntilChanged, map, shareReplay } from 'rxjs';

import { TagItemResponse } from '../queries/rigs-meta.query';
import { RigInfoProviderServiceImpl } from './rig-info-provider.service.impl';

@Injectable({
    providedIn: 'root'
})
export class AssetsInfoProviderServiceImpl extends AssetsInfoProviderService {
    /**
     *
     */
    constructor(
        private readonly rip: RigInfoProviderServiceImpl
    ) {
        super();
    }

    watchAssets(rigName: string): Observable<Assets> {
        return this.rip.rigs$.pipe(
            map(rigs => rigs.find(rig => rig.name === rigName)?.tags?.map(getAsset) ?? []),
            distinctUntilChanged((a, b) => isEqual(a, b)),
            shareReplay(1)
        );
    }
}

function getAsset(item: TagItemResponse): Asset {
    return {
        name: item.name,
        depthTracked: item.depthTracked,
        description: item.description,
        unitGroupId: item.unitGroupId,
        minValue: item.minValue,
        maxValue: item.maxValue
    };
}
