import { createReducer, on } from '@ngrx/store';

import { ThemeName } from '../models/themes';
import { CoreActions } from './core.actions';
import { CoreState, getDefaultCoreState } from './core.state';

/** @internal */
export const coreReducer = createReducer<CoreState>(getDefaultCoreState(),
    on(CoreActions.setTheme, (state, { theme }) => ({
        ...state,
        theme: theme ?? ThemeName.Dark
    })),
    on(CoreActions.setRigMeta, (state, { rigs }) => ({
        ...state,
        rigs,
        rigsAreLoaded: true
    })),
    on(CoreActions.setWellMeta, (state, { wells }) => ({
        ...state,
        wells,
        wellsAreLoaded: true
    })),
    on(CoreActions.setCurrentWell, (state, { wellId }) => ({
        ...state,
        currentWell: wellId
    })),
    on(CoreActions.setCurrentSection, (state, { sectionId }) => ({
        ...state,
        currentSection: sectionId
    })),
    on(CoreActions.setCurrentAssets, (state, { assets }) => ({
        ...state,
        currentAssets: assets
    }))
);

