import { AfterViewInit, Directive, ElementRef, HostBinding, OnDestroy, Renderer2 } from '@angular/core';

import { observeIntersection } from '@cyberloop/core';
import { Subscription } from 'rxjs';

/**
 * This is used only for accessing carousel items inside of the CarouselComponent
 */
@Directive({
    selector: '[cyberloopVerticalScroll]',
    standalone: true
})
export class VerticalScrollDirective implements AfterViewInit, OnDestroy {

    private readonly _parent!: HTMLElement;
    private readonly _mutationObserver?: MutationObserver;
    private _container!: HTMLElement;
    private _topSignalElem!: HTMLDivElement;
    private _bottomSignalElem!: HTMLDivElement;

    private _intersectionSub?: Subscription;

    constructor(
        private readonly renderer: Renderer2,
        public readonly element: ElementRef<HTMLElement>
    ) {
        this._parent = this.element.nativeElement.parentNode as HTMLElement;

        this._topSignalElem = this.renderer.createElement('div');
        this._topSignalElem.classList.add('signal', 'signal-top');

        this._parent.insertBefore(this._topSignalElem, this.element.nativeElement);

        this._bottomSignalElem = this.renderer.createElement('div');
        this._bottomSignalElem.classList.add('signal', 'signal-bottom');
        this._bottomSignalElem.style.marginBlockEnd = '1px';

        if (this.element.nativeElement.nextElementSibling) {
            this._parent.insertBefore(this._bottomSignalElem, this.element.nativeElement.nextElementSibling);
        }
        else {
            this._parent.appendChild(this._bottomSignalElem);
        }

        // Add mutation observer
        this._mutationObserver = new MutationObserver((mutationList) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {

                    // Some nodes added - should re-insert the last signal element
                    this._parent.appendChild(this._bottomSignalElem);

                    break;
                }
            }
        });

        // Start observing
        this._mutationObserver.observe(this._parent, {
            childList: true
        });
    }

    @HostBinding('class.can-scroll-y') readonly elementHasScroll = true;

    ngAfterViewInit(): void {
        const container = this.findOutletContainer();
        if (!container) {
            throw new Error('Scrollable page has no .outlet-container ancestor');
        }

        this._container = container;

        // Add intersection observer
        this._intersectionSub?.unsubscribe();
        this._intersectionSub = observeIntersection([ this._topSignalElem, this._bottomSignalElem ], { root: this._container })
            .subscribe(this.onIntersection.bind(this));
    }

    ngOnDestroy(): void {
        this._intersectionSub?.unsubscribe();
        this._mutationObserver?.disconnect();

        this._topSignalElem.remove();
        this._bottomSignalElem.remove();

        this._container.classList.remove('top-fade', 'bottom-fade');
    }

    private findOutletContainer(): HTMLElement | null {
        let parent: HTMLElement | null = this.element.nativeElement;

        while (parent && !parent.classList.contains('outlet-container')) {
            parent = parent.parentElement;
        }

        return parent;
    }

    private onIntersection(entries: IntersectionObserverEntry[]): void {
        for (const entry of entries) {
            switch (entry.target) {

                case this._topSignalElem:
                    if (entry.isIntersecting) {
                        this._container.classList.remove('top-fade');
                    }
                    else {
                        this._container.classList.add('top-fade');
                    }
                    break;

                case this._bottomSignalElem:
                    if (entry.isIntersecting) {
                        this._container.classList.remove('bottom-fade');
                    }
                    else {
                        this._container.classList.add('bottom-fade');
                    }
                    break;

                default:
                    break;
            }
        }
    }

}
