import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '@cyberloop/core';
import { BlockWithHeadingComponent } from '@cyberloop/web/app/ui';
import { WidgetSize, WidgetType } from '@cyberloop/web/wells/model';

import type { PopupContent } from '@cyberloop/core';

export type WidgetsSelectorResult = { type: WidgetType, size: WidgetSize };

@Component({
    selector: 'cyberloop-widgets-selector',
    standalone: true,
    imports: [
        NgFor,
        BlockWithHeadingComponent,
        IconComponent
    ],
    templateUrl: './widgets-selector.component.html',
    styleUrls: ['./widgets-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetsSelectorComponent implements PopupContent<WidgetsSelectorResult> {

    // Dialog props

    private _closeFn?: (result: WidgetsSelectorResult | null) => void;

    readonly widgetOptions = [
        { type: WidgetType.AssetDailyMetrics, size: WidgetSize.Medium, name: 'Asset Daily Metrics' },
        { type: WidgetType.BitBottom, size: WidgetSize.Tiny, name: 'Bit ON / OFF Bottom' },
        { type: WidgetType.ConnectionTime, size: WidgetSize.Medium, name: 'Connection Time' },
        { type: WidgetType.DaysVsDepth, size: WidgetSize.Medium, name: 'Days vs Depth' },
        { type: WidgetType.RigActivity, size: WidgetSize.Medium, name: 'Rig Activity' },
        { type: WidgetType.RigStatePerHour, size: WidgetSize.Medium, name: 'Rig State Per Hour' },
        { type: WidgetType.RigState, size: WidgetSize.Medium, name: 'Rig State' },
        { type: WidgetType.SlideRotary, size: WidgetSize.Tiny, name: 'Slide / Rotary' },
        { type: WidgetType.SlideVsRotary, size: WidgetSize.Medium, name: 'Slide vs Rotary' },
        { type: WidgetType.TrippingSpeed, size: WidgetSize.Medium, name: 'Tripping speed' },
        { type: WidgetType.CasingRunning, size: WidgetSize.Medium, name: 'Casing running' },
        { type: WidgetType.RateOfAdvance, size: WidgetSize.Medium, name: 'Rate of advance' },
        { type: WidgetType.ROP, size: WidgetSize.Medium, name: 'ROP' },
        { type: WidgetType.CustomVsTime, size: WidgetSize.Medium, name: 'Custom vs Time' }
    ];

    setData?(data: unknown): void {
        // WidgetSelector accepts no data
        data;
    }

    setClose(fn: (result: WidgetsSelectorResult | null) => void): void {
        this._closeFn = fn;
    }

    onCancel(): void {
        this._closeFn?.(null);
    }

    onSelect(item: WidgetsSelectorResult): void {
        this._closeFn?.(item);
    }

}
