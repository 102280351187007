import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ClSelectorOptions, IconComponent } from '@cyberloop/core';

import { ListOfWorkspacesComponent } from './list/list.component';

@Component({
    selector: 'cyberloop-drilling-workspace-selector',
    standalone: true,
    imports: [
        IconComponent,
        ListOfWorkspacesComponent,
        AsyncPipe
    ],
    templateUrl: './workspace-selector.component.html',
    styleUrls: ['./workspace-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceSelectorComponent {
    @Input() wss: ClSelectorOptions[] | null = [];
    @Input() selectedId: string | null = null;
    @Input() canDelete: boolean | null = null;

    @Output() readonly selectWs = new EventEmitter<ClSelectorOptions>();
    @Output() readonly createWs = new EventEmitter<void>();
    @Output() readonly editWs = new EventEmitter<ClSelectorOptions>();
    @Output() readonly deleteWs = new EventEmitter<ClSelectorOptions>();
    @Output() readonly saveToTemplate = new EventEmitter<void>();
    @Output() readonly loadTemplate = new EventEmitter<void>();

    //#region workspaces
    public onSelect(value: ClSelectorOptions) {
        this.selectWs.emit(value);
    }

    public onCreate() {
        this.createWs.emit();
    }

    public onEdit(value: ClSelectorOptions) {
        this.editWs.emit(value);
    }

    public onDelete(value: ClSelectorOptions) {
        this.deleteWs.emit(value);
    }
    //#endregion workspaces

    //#region Templates
    public onSaveToTemplate() {
        this.saveToTemplate.emit();
    }

    public onLoadTemplate() {
        this.loadTemplate.emit();
    }
    //#endregion
}
