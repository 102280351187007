
import { CoreSelectors } from '@cyberloop/core';
import { getMomentsFromTimeRange, slideRotaryDefaultSettings } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { EMPTY, Observable, combineLatest, distinctUntilChanged, map, switchMap } from 'rxjs';

import { KpiSelectors } from '../../state/kpi/kpi.selectors';
import { KpiDataService } from '../kpi-data.service';

import type { SlideRotaryWidgetData, SlideRotaryWidgetSettings } from '@cyberloop/web/wells/model';

export function getSlideRotaryWidgetData(this: { store: Store, data: KpiDataService }, id: string): Observable<SlideRotaryWidgetData> {
    return combineLatest([
        this.store.select(CoreSelectors.currentWell),
        this.store.select(KpiSelectors.widgetSettings<SlideRotaryWidgetSettings>(id))
    ]).pipe(
        // Custom distinct function that prevents triggering query when things
        // needed for it have no changes
        distinctUntilChanged((prev, curr) =>
            prev[0]?.id === curr[0]?.id &&
            isEqual(prev[1]?.timeRange, curr[1]?.timeRange)
        ),
        switchMap(([well, settings]) => {
            if (!well) {
                return EMPTY;
            }

            const timeRangeObservable = getMomentsFromTimeRange(
                this.store,
                settings?.timeRange ?? slideRotaryDefaultSettings.timeRange,
                well
            );

            return timeRangeObservable.pipe(
                switchMap(timeRange => this.data.watchRigStateRotarySlideHours(
                    well.rig,
                    timeRange.since.clone().startOf('second').toDate(),
                    timeRange.until.clone().startOf('second').toDate()
                )),
                map(rows => ({
                    slide: rows.reduce((total, row) => row.durationSlide + total, 0),
                    rotary: rows.reduce((total, row) => row.durationRotary + total, 0)
                }))
            );
        })
    );
}
