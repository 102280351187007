import { FirebaseApp, FirebaseError } from '@firebase/app';
import { isEqual, isNil } from 'lodash';
import { EMPTY, Observable, OperatorFunction, catchError, debounceTime, distinctUntilChanged, filter, map, of, share, shareReplay, switchMap, takeWhile, tap } from 'rxjs';

import { Auth, AuthSettings, Config, EmulatorConfig, ErrorFn, NextOrObserver, Persistence, Unsubscribe, User, getAuth, signInWithCustomToken } from 'firebase/auth';

export class ClAuth implements Auth {
    private _isDisposed = false;
    private constructor(private readonly _auth: Auth) {
    }

    private readonly _authStateChanged$ = of(this._auth).pipe(
        switchMap(auth => auth ? new Observable<User | null>(s => {
            s.next(auth.currentUser);

            return auth.onAuthStateChanged(
                x => s.next(x),
                err => s.error(err)
            );
        }) : of(null)),
        takeWhile(() => !this._isDisposed),
        share()
    );
    private readonly _onIdTokenChanged$ = of(this._auth).pipe(
        switchMap(auth => auth ? new Observable<User | null>(s => {
            s.next(auth.currentUser);

            return auth.onIdTokenChanged(
                x => s.next(x),
                err => s.error(err)
            );
        }) : of(null)),
        takeWhile(() => !this._isDisposed),
        share()
    );
    private readonly _beforeAuthStateChanged$ = of(this._auth).pipe(
        switchMap(auth => auth ? new Observable<User | null>(s => {
            s.next(auth.currentUser);

            return auth.beforeAuthStateChanged(
                x => s.next(x)
            );
        }) : EMPTY),
        takeWhile(() => !this._isDisposed),
        share()
    );

    get app(): FirebaseApp { return this._auth.app; }
    get name(): string { return this._auth.name; }
    get config(): Config { return this._auth.config; }
    get languageCode(): string | null { return this._auth.languageCode; }
    get tenantId(): string | null { return this._auth.tenantId; }
    get settings(): AuthSettings { return this._auth.settings; }
    get currentUser(): User | null { return this._auth.currentUser; }
    get emulatorConfig(): EmulatorConfig | null { return this._auth.emulatorConfig; }

    readonly currentUser$: Observable<User | null> = this._authStateChanged$.pipe(
        debounceTime(500),
        distinctUntilChanged((a, b) => isEqual(a?.toJSON(), b?.toJSON())),
        takeWhile(() => !this._isDisposed),
        shareReplay(1)
    );

    readonly loggedIn$ = this.currentUser$.pipe(
        filter(x => !isNil(x)),
        map(() => { }),
        takeWhile(() => !this._isDisposed),
        share()
    );

    readonly loggedOut$ = this.currentUser$.pipe(
        filter(isNil),
        map(() => { }),
        takeWhile(() => !this._isDisposed),
        share()
    );

    static create(app: FirebaseApp): ClAuth {
        const auth = getAuth(app);
        return new ClAuth(auth);
    }

    dispose() {
        this._isDisposed = true;
    }

    setPersistence(persistence: Persistence): Promise<void> {
        this.ensureNotDisposed();
        return this._auth.setPersistence(persistence);
    }

    onAuthStateChanged(nextOrObserver: NextOrObserver<User | null>, error?: ErrorFn | undefined): Unsubscribe {
        this.ensureNotDisposed();
        const subscription = this._authStateChanged$.pipe(
            handleNext(nextOrObserver),
            catchError(err => {
                if (typeof error === 'function') {
                    error(err);
                }
                else {
                    console.warn('[onAuthStateChanged]', err);
                }

                return EMPTY;
            }),
            takeWhile(() => !this._isDisposed)
        ).subscribe();

        return subscription.unsubscribe.bind(subscription);
    }
    beforeAuthStateChanged(callback: (user: User | null) => void | Promise<void>): Unsubscribe {
        this.ensureNotDisposed();
        const subscription = this._beforeAuthStateChanged$.pipe(
            switchMap(async x => await callback(x)),
            catchError(err => {
                console.warn('[beforeAuthStateChanged]', err);
                return EMPTY;
            }),
            takeWhile(() => !this._isDisposed)
        ).subscribe();

        return subscription.unsubscribe.bind(subscription);
    }
    onIdTokenChanged(nextOrObserver: NextOrObserver<User | null>, error?: ErrorFn | undefined): Unsubscribe {
        this.ensureNotDisposed();
        const subscription = this._onIdTokenChanged$.pipe(
            handleNext(nextOrObserver),
            catchError(err => {
                if (typeof error === 'function') {
                    error(err);
                }
                else {
                    console.warn('[onIdTokenChanged]', err);
                }

                return EMPTY;
            }),
            takeWhile(() => !this._isDisposed)
        ).subscribe();

        return subscription.unsubscribe.bind(subscription);
    }
    updateCurrentUser(user: User | null): Promise<void> {
        this.ensureNotDisposed();
        return this._auth.updateCurrentUser(user);
    }
    useDeviceLanguage(): void {
        this.ensureNotDisposed();
        return this._auth.useDeviceLanguage();
    }
    signOut(): Promise<void> {
        this.ensureNotDisposed();
        return this._auth.signOut();
    }

    async getUserByToken(token: string) {
        this.ensureNotDisposed();

        if (!token) {
            return null;
        }

        try {
            const auth = this._auth;
            if (!auth) {
                return null;
            }

            const credentials = await signInWithCustomToken(auth, token);

            const user = credentials?.user;
            return user;
        }
        catch (e) {
            if (e instanceof FirebaseError) {
                if (e.code === 'auth/invalid-custom-token') {
                    throw new Error('getUserByToken failed: Auth token is invalid or expired');
                }
                else {
                    throw new Error('getUserByToken: FirebaseError. More: :' + e.message);
                }
            }
            else {
                throw new Error('getUserByToken: Error' + e);
            }
        }
    }

    private ensureNotDisposed() {
        if (this._isDisposed) {
            throw new Error('Object disposed');
        }
    }
}

function handleNext(handler: NextOrObserver<User | null>): OperatorFunction<User | null, void> {
    return o => {
        if (typeof handler === 'function') {
            return o.pipe(
                map(x => {
                    handler(x);
                })
            );
        }

        return o.pipe(
            tap(handler.next),
            catchError(err => {
                if (handler.error) {
                    handler.error(err);
                }
                return EMPTY;
            }),
            map(() => { })
        );
    };

}