import { RigState, WellKnownParams } from '@cyberloop/core';
import { Observable } from 'rxjs';

import type { Section, Well } from '@cyberloop/core';
import type { KpiDrillingMetricsItem, KpiDrillingMetricsPerJointMetersItem, KpiDrillingMetricsPerJointTimeItem, KpiRigActivityDurationItem, KpiRigBitOnBitOffBottomHour, KpiRigConnection, KpiRigConnectionSumsItem, KpiRigRateOfAdvanceItem, KpiRigStateDuration, KpiRigStateHour, KpiRigStateSLideRotaryHour, KpiRopSumItem, KpiWellTagDepthHistoryItem, KpiWellTagTimeHistoryItem } from '@cyberloop/web/wells/model';

/**
 * Provides some KPI data used by KPI widgets.
 */
export abstract class KpiDataService {

    /**
     * Returns tag values aggregated by time
     * ```
     * {
     *   minValue: number
     *   maxValue: number
     *   avgValue: number
     * }[]
     * ```
     */
    abstract watchTagTimeHistory(well: Well, tags: WellKnownParams[], step: number, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiWellTagTimeHistoryItem[]>;

    /**
     * Returns tag values aggregated by time
     * ```
     * {
     *   minValue: number
     *   maxValue: number
     *   avgValue: number
     * }[]
     * ```
     */
    abstract watchTagTimeHistoryByWellId(wellId: string, tags: WellKnownParams[], step: number, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiWellTagTimeHistoryItem[]>;

    /**
     * Returns tag values aggregated by depth
     */
    abstract watchTagDepthHistory(well: Well, section: Section, tags: WellKnownParams[], step: number, from: number, to: number, refreshIntervalSec?: number): Observable<KpiWellTagDepthHistoryItem[]>;

    /**
     * Returns rotary and slide drilling metrics for the provided time range
     * ```
     * {
     *   rotaryDrilled: number
     *   slideDrilled: number
     * }
     * ```
     */
    abstract watchDrillingMetrics(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiDrillingMetricsItem>;

    /**
     * Returns rotary and slide meters grouped by connections during the provided time range
     * ```
     * {
     *   startTime: string
     *   rotaryMetersDrilled: number
     *   slideMetersDrilled: number
     * }[]
     * ```
     */
    abstract watchDrillingMetricsPerJointMeters(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiDrillingMetricsPerJointMetersItem[]>;

    /**
     * Returns rotary and slide durations grouped by connections during the provided time range
     * ```
     * {
     *   startTime: string
     *   rotaryTimeDrilled: number
     *   slideTimeDrilled: number
     * }[]
     * ```
     */
    abstract watchDrillingMetricsPerJointTime(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiDrillingMetricsPerJointTimeItem[]>;

    /**
     * Returns bit on/off bottom hours
     * ```
     * {
     *   hour: Date - Hour that the provided data item relates to
     *   durationDrilling: number - Duration in seconds, how long drilling took place
     *   durationNoDrilling: number - Duration in seconds, how long non-drilling took place
     * }[]
     * ```
     */
    abstract watchBitOnOffBottomHours(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigBitOnBitOffBottomHour[]>;

    /**
     * Returns rotary and slide durations grouped by hour during the provided time range
     * ```
     * {
     *   hour: string
     *   durationRotary: number
     *   durationSlide: number
     * }[]
     * ```
     */
    abstract watchRigStateRotarySlideHours(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigStateSLideRotaryHour[]>;

    /**
     * Returns Rig State hours
     * ```
     * {
     *   hour: Date - Hour that the provided data item relates to
     *   durations: {
     *     state: RigState - The rig state
     *     duration: number - How long (in seconds) this state persist during the hour
     *   }[]
     * }[]
     * ```
     */
    abstract watchRigStateHours(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigStateHour[]>;

    /**
     * Returns durations for rig states during the provided time range
     * ```
     * {
     *   state: RigState
     *   duration: number
     * }[]
     * ```
     */
    abstract watchRigStateDurations(rig: string, since: Date, until?: Date, only?: RigState[], refreshIntervalSec?: number): Observable<KpiRigStateDuration[]>;

    /**
     * Returns Rig Activity hours
     * ```
     * {
     *   hour: Date - Hour that the provided data item relates to
     *   durations: {
     *     activity: RigActivity - The rig activity
     *     duration: number - How long (in seconds) this activity persist during the hour
     *   }[]
     * }[]
     * ```
     */
    abstract watchRigActivityDurations(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigActivityDurationItem[]>;

    /**
     * Returns connection records grouped by some random amount of time (it
     * depends on how data appears in the system).
     * ```
     * {
     *   startTime: string - The start time of the provided interval
     *   s2s: number - Slips to Slips time
     *   s2w: number - Slips to Weight time
     *   w2s: number - Weight to Slips time
     * }[]
     * ```
     */
    abstract watchRigConnections(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigConnection[]>;

    /**
     * Returns sums of connection values for the provided time range
     * ```
     * {
     *   sumS2S: number
     *   sumS2W: number
     *   sumW2S: number
     * }
     * ```
     */
    abstract watchRigConnectionSums(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigConnectionSumsItem>;

    /**
     * Returns ROP average values for the provided time range
     * ```
     * {
     *   avgValueRotaryDrilling: number
     *   avgValueSlideDrilling: number
     * }
     * ```
     */
    abstract watchRopSums(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRopSumItem>;

    abstract watchRigRateOfAdvance(rig: string, since: Date, until?: Date, refreshIntervalSec?: number): Observable<KpiRigRateOfAdvanceItem[]>;

}
