<div class="container" aria-hidden="true">
    <div class="favorite"></div>
    <div class="well-section skeleton-section">
        <div class="title skeleton"></div>
        <div class="value skeleton"></div>
    </div>
    <div class="rig-section">
        <div class="left-panel skeleton-section">
            <div class="title skeleton"></div>
            <div class="value skeleton"></div>
        </div>
        <div class="right-panel">
            <div class="well-status skeleton">
            </div>
        </div>
    </div>
    <div class="field drilled skeleton"></div>
    <div class="field bde skeleton"></div>
    <div class="field wde skeleton"></div>
    <div class="field spud skeleton"></div>
    <div class="chart-section">
        <div class="skeleton"></div>
    </div>
    <div class="daily-section skeleton-section">
        <div class="title skeleton"></div>
        <div class="value skeleton"></div>
    </div>
    <div class="field daily-drilled skeleton"></div>
    <div class="field daily-slide skeleton"></div>
    <div class="field daily-rop skeleton"></div>
    <div class="field daily-btm skeleton"></div>
    <div class="expand-section">
        <div class="btn-expand">
        </div>
    </div>
</div>
