import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ClCheckboxGroupComponent, ClSelectorComponent, ClSelectorOptions, HighchartsOptions, Point, ScrollShadowDirective, Section } from '@cyberloop/core';

import { DdChart3DComponent } from '../dd-chart-3d/dd-chart-3d.component';

@Component({
    selector: 'cyberloop-dd-charts-3d',
    standalone: true,
    imports: [
        NgIf,
        DdChart3DComponent,
        ClCheckboxGroupComponent,
        ClSelectorComponent,
        ScrollShadowDirective
    ],
    templateUrl: './dd-charts-3d.component.html',
    styleUrls: ['./dd-charts-3d.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DdCharts3dComponent {

    @Input() sections: Section[] | null = [];
    @Input() data: Point[][] | null = null;
    @Input() wellPlansOptions: ClSelectorOptions[] = [];
    @Input() seriesColors: string[] = [];
    @Input() selectedSections: number[] = [];
    @Input() selectedWellPlan: string | null | undefined = null;
    @Input() skeleton: boolean | null = null;

    @Output() sectionSelect = new EventEmitter<number[]>();
    @Output() planSelect = new EventEmitter<string>();

    get activatedSeries(): number[] | null {
        const arrayOfIndexes = this.sections?.reduce((acc: number[], section, index) => {
            if(this.selectedSections.includes(section.id)) {
                acc.push(index + 1); //+1 because series[0] is well plan, series[1] is first section data series
            }
            return acc;
        }, []);

        return arrayOfIndexes ?? null;
    }

}