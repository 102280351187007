import { Injectable } from '@angular/core';

import { GraphQLMutation } from '../../internals/cl-gql';

type TorqueAndDragDeactivateModelResult = {
    torqndrag: {
        deactivateModel: {
            succeeded: boolean
        }
    }
}

type TorqueAndDragDeactivateModelInput = {
    modelId: string
}

@Injectable({
    providedIn: 'root'
})
export class TorqueAndDragDeactivateModelMutation extends GraphQLMutation<TorqueAndDragDeactivateModelResult, TorqueAndDragDeactivateModelInput> {
    override readonly document = `
        mutation DeactivateTorqueAndDragModel($modelId: ID!) {
            torqndrag {
                deactivateModel(id: $modelId) {
                    ... on MutationResult {
                        succeeded
                    }
                }
            }
        }
    `;
}