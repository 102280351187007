/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep, isObject } from 'lodash';

/**
 * Deeply merges multiple objects into a target object.
 * @param {T1} target - The target object to merge into.
 * @param {T1[]} sources - The sources array containing objects to merge from.
 * @param {boolean} [concatArrays=false] - Specifies whether to merge arrays by concatenating them (true) or replacing them entirely (false).
 * @returns {T1} - The merged object.
 */
export function deepMerge<T1>(target: T1, sources: T1[], concatArrays?: boolean): T1;
/**
 * Deeply merges multiple objects into a target object.
 * @param {T1} target - The target object to merge into.
 * @param {(T1 | T2)[]} sources - The sources array containing objects to merge from.
 * @param {boolean} [concatArrays=false] - Specifies whether to merge arrays by concatenating them (true) or replacing them entirely (false).
 * @returns {T1 & T2} - The merged object.
 */
export function deepMerge<T1, T2>(target: T1, sources: (T1 | T2)[], concatArrays?: boolean): T1 & T2;
/**
 * Deeply merges multiple objects into a target object.
 * @param {T1} target - The target object to merge into.
 * @param {(T1 | T2 | T3)[]} sources - The sources array containing objects to merge from.
 * @param {boolean} [concatArrays=false] - Specifies whether to merge arrays by concatenating them (true) or replacing them entirely (false).
 * @returns {T1 & T2 & T3} - The merged object.
 */
export function deepMerge<T1, T2, T3>(target: T1, sources: (T1 | T2 | T3)[], concatArrays?: boolean): T1 & T2 & T3;
/**
 * Deeply merges multiple objects into a target object.
 * @param {T1} target - The target object to merge into.
 * @param {(T1 | T2 | T3 | T4)[]} sources - The sources array containing objects to merge from.
 * @param {boolean} [concatArrays=false] - Specifies whether to merge arrays by concatenating them (true) or replacing them entirely (false).
 * @returns {T1 & T2 & T3 & T4} - The merged object.
 */
export function deepMerge<T1, T2, T3, T4>(target: T1, sources: (T1 | T2 | T3 | T4)[], concatArrays?: boolean): T1 & T2 & T3 & T4;
/**
 * Deeply merges multiple objects into a target object.
 * @param {T1} target - The target object to merge into.
 * @param {(T1 | T2 | T3 | T4 | T5)[]} sources - The sources array containing objects to merge from.
 * @param {boolean} [concatArrays=false] - Specifies whether to merge arrays by concatenating them (true) or replacing them entirely (false).
 * @returns {T1 & T2 & T3 & T4 & T5} - The merged object.
 */
export function deepMerge<T1, T2, T3, T4, T5>(target: T1, sources: (T1 | T2 | T3 | T4 | T5)[], concatArrays?: boolean): T1 & T2 & T3 & T4 & T5;
/**
 * Deeply merges multiple objects into a target object.
 * @param {object} target - The target object to merge into.
 * @param {any[]} sources - The sources array containing objects to merge from.
 * @param {boolean} [concatArrays=false] - Specifies whether to merge arrays by concatenating them (true) or replacing them entirely (false).
 * @returns {object} - The merged object.
 */
export function deepMerge(target: any, sources: any[], concatArrays: boolean = false): any {
    if (!sources.length) {
        return cloneDeep(target);
    }

    let merged: any = Array.isArray(target) ? [...target] : { ...target };

    for (const source of sources) {
        if (Array.isArray(source)) {
            if (!Array.isArray(merged)) {
                merged = [];
            }
            if (concatArrays) {
              merged = target.concat(source);
            } else {
              merged = [...source];
            }
            continue;
        }

        if (!isObject(source)) {
            continue;
        }

        for (const key in source) {
            if (!isObject((source as any)[key])) {
                if (Array.isArray(merged[key]) && Array.isArray((source as any)[key])) {
                    merged[key] = [...merged[key], ...(source as any)[key]];
                } else {
                    merged[key] = (source as any)[key];
                }
                continue;
            }

            if (isObject(merged[key])) {
                merged[key] = deepMerge(merged[key] ?? {}, [(source as any)[key]], concatArrays);
            } else {
                merged[key] = cloneDeep((source as any)[key]);
            }
        }
    }

    return merged;
}
