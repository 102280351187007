<!-- Title -->
<header>
    <h3>{{heading}}</h3>
    <ng-content select="[block-header-content]"></ng-content>
</header>

<!-- Content -->
<section>
    <ng-content select="[block-main-content]"></ng-content>
</section>
