import { Injectable } from '@angular/core';

import { ID, uuid } from '@cyberloop/core';
import { FilesTagProviderService } from '@cyberloop/web/files/data';
import { FileTag } from '@cyberloop/web/files/model';
import { isNil } from 'lodash';
import { EMPTY, Observable, finalize, from, of, shareReplay, switchMap } from 'rxjs';

import { FirestoreDataConverter } from 'firebase/firestore';
import * as moment from 'moment';

import { DBFileTag } from '../models/files';
import { ClApplicationManager } from './internals/client-app/cl-app-manager';

const FILES_TAGS_COLLECTION_NAME = 'files-tags';

@Injectable({
    providedIn: 'root'
})
export class FirebaseFilesTagProviderLinkService extends FilesTagProviderService {
    private readonly _tagConverter: FirestoreDataConverter<FileTag> = {
        toFirestore: (meta: FileTag) => meta,
        fromFirestore: (snapshot, options): FileTag => {
            const dbTag = snapshot.data(options) as DBFileTag;

            return {
                ...dbTag,
                createdAt: moment(dbTag.createdAt)
            };
        }
    };

    private readonly _clApp$ = this.appMgr.tenantApp$.pipe(
        switchMap(x => isNil(x) ? EMPTY : of(x)),
        shareReplay(1)
    );

    private _listObservable: Observable<FileTag[]> | undefined;

    constructor(private readonly appMgr: ClApplicationManager) {
        super();
    }

    watchAll() {
        return this._listObservable ??= this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().watchCollection<FileTag>(
                FILES_TAGS_COLLECTION_NAME,
                this._tagConverter
            )),
            finalize(() => this._listObservable = undefined),
            shareReplay(1)
        );
    }

    create(name: string) {
        const id = uuid();

        return this._clApp$.pipe(
            switchMap(clApp => from(clApp.getFirestore().set<DBFileTag>(
                this.getPath(FILES_TAGS_COLLECTION_NAME, id),
                {
                    id,
                    name,
                    createdAt: moment().valueOf()
                }
            ))),
            switchMap(() => this.get(id) as Observable<FileTag>),
            shareReplay(1)
        );
    }

    private get(id: ID) {
        return this._clApp$.pipe(
            switchMap(clApp => clApp.getFirestore().get<FileTag>(
                this.getPath(FILES_TAGS_COLLECTION_NAME, id),
                this._tagConverter
            )),
            shareReplay(1)
        );
    }

    private getPath(...parts: string[]) {
        return parts.join('/');
    }
}
