<ng-container *ngIf="control?.invalid && control?.touched">
    <span *ngFor="let error of errors | keyvalue" class="error">
        <ng-container [ngSwitch]="error.key">
            <ng-container *ngSwitchCase="'required'">Required field</ng-container>
            <ng-container *ngSwitchCase="'min'">
                Should be more or equal to {{ error.value.min }}
            </ng-container>
            <ng-container *ngSwitchCase="'max'">
                Should be less or equal to {{ error.value.max }}
            </ng-container>
            <ng-container *ngSwitchCase="'minlength'">
                Minimum length should be more or equal to {{ error.value.requiredLength }}
            </ng-container>
            <ng-container *ngSwitchCase="'maxlength'">
                Maximum length should be less or equal to {{ error.value.requiredLength }}
            </ng-container>
            <ng-container *ngSwitchCase="'email'">Invalid e-mail</ng-container>
            <ng-container *ngSwitchDefault>{{ error.value }}</ng-container>
        </ng-container>
    </span>
</ng-container>