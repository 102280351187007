import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNil, sortBy } from 'lodash';

import { ID, WellKnownParams } from '../models';
import { Section } from '../models/section';
import { AuthSelectors } from './auth/auth.selectors';
import { CORE_FEATURE, CoreState } from './core.state';
import { UnitsSelectors } from './units/units.selectors';

/** @internal */
const feature = createFeatureSelector<CoreState>(CORE_FEATURE);

/** Select theme */
const theme = createSelector(feature, state => state.theme);

/** Select all known rigs */
const allRigs = createSelector(feature, state => state.rigs);

/** Get rigs loading state */
const rigsAreLoading = createSelector(feature, state => !state.rigsAreLoaded);

/** Select rig by Id */
const selectRig = (rigId: ID) => createSelector(feature, state => state.rigs.find(x => x.id === rigId));

/** Select all known wells */
const allWells = createSelector(feature, state => state.wells);

/** Get wells loaded state */
const wellsAreLoaded = createSelector(feature, state => state.wellsAreLoaded);

/** Get wells loading state */
const wellsAreLoading = createSelector(feature, state => !state.wellsAreLoaded);

/**
 * Select well by id
 * @param wellId Id of the well to find
 * @returns Selector that returns Well of undefined
 */
const getWellById = (wellId: string) => createSelector(allWells, state => state.find(x => x.id === wellId));
/**
 * Select sections of the well by Id
 * @param wellId Id of the well to find
 * @returns Selector that returns Array of Sections or undefined
 */
const sectionsOfWell = (wellId: string) => createSelector(allWells, state => {
    const sections = [...state.find(x => x.id === wellId)?.sections ?? []];
    const sorted = sortBy(sections, x => x.startTime);


    return sorted;
});

/**
 * Check if well with specific id exists
 * @param wellId Id of the well to check for existance
 * @returns Selector that returns true if well exists or false otherwise
 */
const wellExists = (wellId: string) => createSelector(allWells, state => !!state.find(x => x.id === wellId));

/** Selects Id of currently selected well */
const currentWellId = createSelector(feature, state => state.currentWell || undefined);
/** Selects currently selected well */
const currentWell = createSelector(currentWellId, allWells, (id, wells) => id ? (wells ?? []).find(well => well.id === id) : undefined);

/** If specific well is active or not (released or suspended) */
const isWellActive = (wellId: string) => createSelector(getWellById(wellId), well => well && !well.releaseTime && !well.suspendTime);

/** If current well is active or not (released or suspended) */
const isCurrentWellActive = createSelector(currentWell, well => well && !well.releaseTime && !well.suspendTime);

/** Selects Sections of currently selected well */
const sectionsOfCurrentWell = createSelector(feature, state => {
    if (!state.currentWell || !state.wells) {
        return [];
    }

    const sections = [...state.wells.find(well => well.id === state.currentWell)?.sections ?? []];
    const sorted = sortBy(sections, x => x.startTime);

    return sorted;
});

/** Selects id of currently selected section */
const currentSectionIdFIeld = createSelector(feature, x => x.currentSection || undefined);
/** Selects currently selected section */
const currentSection = createSelector(currentSectionIdFIeld, sectionsOfCurrentWell, (currentId, sections) => {
    console.warn('@@', { sections, currentId });
    let active: Section | undefined;
    let last: Section | undefined;
    for (const section of sections) {
        if (section.id === currentId) {
            return section;
        }

        if (isNil(section.endTime)) {
            active = section;
        }

        if (!last || !section.endTime || last.endTime && last.endTime.getTime() < section.endTime.getTime()) {
            last = section;
        }
    }

    return active || last || undefined;
});

/** Selects currently selected section */
const currentSectionTree = createSelector(currentSection, sectionsOfCurrentWell, (selectedSection, sections) => {
    console.warn('@@', { sections, selectedSection });

    let currentSection = sections.find(s => s.id === selectedSection?.id);
    const result: Section[] = currentSection ? [currentSection] : [];

    while (currentSection) {
        currentSection = sections.find(s => s.id === currentSection?.parent);
        if (currentSection) {
            result.push(currentSection);
        }
    }

    return result;
});

/** Selects assets available in current scope */
const currentAssets = createSelector(feature, state => state.currentAssets);

/** Selects unit group ID of the provided tag */
const tagUnitGroupId = (tag: WellKnownParams) => createSelector(currentAssets, assets => {
    const asset = assets.find(item => item.name === tag);
    return asset?.unitGroupId;
});

/** Selects unit group of the provided tag */
const tagUnitGroup = (tag: WellKnownParams) => createSelector(tagUnitGroupId(tag), UnitsSelectors.unitGroups, (unitGroupId, unitGroups) => {
    return unitGroupId ? unitGroups[unitGroupId] : undefined;
});

/** Selects suitable units for the provided tag */
const tagUnits = (tag: WellKnownParams) => createSelector(tagUnitGroup(tag), unitGroup => {
    return unitGroup?.units ?? {};
});

/**
 * Select well time range by id
 * @param wellId Id of the well
 * @returns Selector that returns well range or undefined
 */
const getWellTimeRangeById = (wellId: string) => createSelector(allWells, wells => {
    const well = wells.find(x => x.id === wellId);
    if (!well) {
        return undefined;
    }

    return { from: well.startTime.getTime(), to: well.releaseTime?.getTime() };
});
// /**
//  * Select section depth range by well and section Ids
//  * @param wellId Id of the well to find
//  * @param sectionId Id of the section
//  * @returns Selector that returns section range or undefined
//  */
// const getSectionDepthRange = (wellId?: string, sectionId?: string) => createSelector(allWells, state => {
//     if (!wellId || !sectionId) {
//         return undefined;
//     }
//     const sections = state.find(x => x.id === wellId)?.sections;
//     const section = sections?.find(x => x.id.toString() === sectionId);
//     if (!section) {
//         return undefined;
//     }

//     return { from: section.from, to: section.to};
// });


/**
 * Selectors for core
 * */
export const CoreSelectors = {
    theme,
    allRigs,
    rigsAreLoading,
    selectRig,
    allWells,
    getWellById,
    sectionsOfWell,
    wellsAreLoaded,
    wellsAreLoading,
    wellExists,
    currentWell,
    currentWellId,
    sectionsOfCurrentWell,
    currentSection,
    currentSectionTree,
    currentAssets,
    tagUnitGroupId,
    tagUnitGroup,
    tagUnits,
    getWellTimeRangeById,
    // getSectionDepthRange,

    isWellActive,
    isCurrentWellActive,

    units: UnitsSelectors,
    auth: AuthSelectors,
};
