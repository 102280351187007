<carousel heading="Recent files" class="carousel">
    <ng-container *ngFor="let file of files; odd as odd">
        <div *ngVar="file.activityId; let activityId" carousel-item
            [ngClass]="isNil(activityId) ? null : RigActivity.getClassName(activityId)"
            [class.has-activity]="!isNil(activityId)" class="card">
            <div class="head">
                <span class="extension">
                    {{ file.extension }}
                </span>
            </div>

            <div class="footer">
                <div class="meta">
                    <span class="name">{{ file.name }}</span>
                    <time class="date">{{ file.createdAt | datetime }}</time>
                </div>

                <button (click)="onDownload(file.id)" [disabled]="disableActions" class="action-button cl-icon-button">
                    <icon name="download"></icon>
                </button>

                <ng-container *ngIf="!readonly">
                    <div class="divider"></div>

                    <button (click)="onDelete(file.id)" [disabled]="disableActions"
                        class="action-button cl-icon-button">
                        <icon name="delete"></icon>
                    </button>
                </ng-container>
            </div>
        </div>
    </ng-container>
</carousel>
