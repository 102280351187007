import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { KPI_FEATURE } from './kpi.state';

import type { AssetMetric, DashboardSettings, Widget, WidgetSettings } from '@cyberloop/web/wells/model';

export const KPIActionsGroup = createActionGroup({
    source: KPI_FEATURE,
    events: {
        'Set widgets': props<{ widgets: Widget[] }>(),
        'Add widget': props<{ widget: Pick<Widget, 'type' | 'size'> & Partial<Pick<Widget, 'id'>> }>(),
        'Update widgets': props<{ widgets: Widget[] }>(),
        'Delete widget': props<{ widgetId: string }>(),
        'Delete widgets': props<{ widgetsIds: string[] }>(),

        'Set widget settings': props<{ widgetId: string, settings: WidgetSettings }>(),
        'Set all widgets settings': props<{ settings: Record<string, WidgetSettings> }>(),
        'Update widget settings': props<{ widgetId: string, settings: WidgetSettings }>(),
        'Delete widget settings': props<{ widgetId: string }>(),

        'Set dashboards': props<{ dashboards: DashboardSettings[] }>(),
        'Set active dashboard': props<{ dashboardId: string | undefined }>(),
        'Add dashboard': emptyProps(),
        'Delete dashboard': props<{ dashboardId: string }>(),
        'Update dashboard name': props<{ dashboardId: string, name: string }>(),

        'Set selected metrics': props<{ metrics: AssetMetric[] }>(),

        'Set error': props<{ error?: Error }>(),
        'Update settings': emptyProps()
    }
});


/**
 * @internal
 * Actions for KPI used internally
 * */
export const KpiActions = {
    setWidgets: KPIActionsGroup.setWidgets,
    setAllWidgetsSettings: KPIActionsGroup.setAllWidgetsSettings,
    setWidgetSettings: KPIActionsGroup.setWidgetSettings,
    setError: KPIActionsGroup.setError,

    updateWidgets: KPIActionsGroup.updateWidgets,

    addWidget: KPIActionsGroup.addWidget,
    deleteWidget: KPIActionsGroup.deleteWidget,
    updateWidgetSettings: KPIActionsGroup.updateWidgetSettings,
    deleteWidgetSettings: KPIActionsGroup.deleteWidgetSettings,
    setDashboards: KPIActionsGroup.setDashboards,

    setActiveDashboard: KPIActionsGroup.setActiveDashboard,
    addDashboard: KPIActionsGroup.addDashboard,
    deleteDashboard: KPIActionsGroup.deleteDashboard,
    deleteWidgets: KPIActionsGroup.deleteWidgets,
    updateSettings: KPIActionsGroup.updateSettings,
    updateDashboardName: KPIActionsGroup.updateDashboardName,

    setSelectedMetrics: KPIActionsGroup.setSelectedMetrics
};

/**
 * Public actions for KPI
 * */
export const PublicKpiActions = {
    addWidget: KPIActionsGroup.addWidget,
    deleteWidget: KPIActionsGroup.deleteWidget,
    updateWidgetSettings: KPIActionsGroup.updateWidgetSettings,
    deleteWidgetSettings: KPIActionsGroup.deleteWidgetSettings,
    
    setDashboards: KPIActionsGroup.setDashboards,
    setActiveDashboard: KPIActionsGroup.setActiveDashboard,
    addDashboard: KPIActionsGroup.addDashboard,
    deleteDashboard: KPIActionsGroup.deleteDashboard,
    deleteWidgets: KPIActionsGroup.deleteWidgets,
    updateDashboardName: KPIActionsGroup.updateDashboardName,

    setSelectedMetrics: KPIActionsGroup.setSelectedMetrics
};
