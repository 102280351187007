import { Inject, Injectable } from '@angular/core';

import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { Functions, getFunctions } from 'firebase/functions';

import { ROOT_FB_CONFIG_TOKEN } from '../../../tokens';

const SSO_APP = 'sso:app';

@Injectable()
export class SsoRootApplication implements FirebaseApp {
    private readonly _app: FirebaseApp;

    /**
     *
     */
    constructor(@Inject(ROOT_FB_CONFIG_TOKEN) appConfig: FirebaseOptions) {
        this._app = initializeApp(appConfig, SSO_APP);
    }

    get name(): string { return this._app.name; }
    get options(): FirebaseOptions { return this._app.options; }
    get automaticDataCollectionEnabled(): boolean { return this._app.automaticDataCollectionEnabled; }

    getFunctions(): Functions {
        return getFunctions(this._app);
    }

}