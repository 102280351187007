import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { TndModelKind, TorqueAndDragTorqueModel, TorqueAndDragWeightsModel } from '../../internals/tnd';

export type TorqueAndDragModelsListInPlanResult = {
    planning: {
        models: {
            total: number,
            edges: (TorqueAndDragWeightsModel | TorqueAndDragTorqueModel)[]
        }
    }
}

type TorqueAndDragModelsListInPlanInput = {
    planId: string,
    kind: TndModelKind
}

@Injectable({
    providedIn: 'root'
})
export class TorqueAndDragModelsListInPlanQuery extends GraphQLQuery<TorqueAndDragModelsListInPlanResult, TorqueAndDragModelsListInPlanInput> {
    override readonly document = `
        query GetTorqueAndDragModelsInPlan($planId: ID!, $kind: TorqndragModelKind!) {
            planning(id: $planId) {
                ... on Planning {
                    models(kind: $kind) {
                        total
                        edges {
                            id
                            name
                            valueUnitId
                            depthUnitId
                            startDepth
                            endDepth
                            pushAlarmLine
                            pullAlarmLine
                            highTorqueAlarmLine
                        }
                    }
                }
            }
        }
    `;
}
