import { ID } from '@cyberloop/core';
import { Meterage, MeterageItem } from '@cyberloop/web/wells/model';
import { Observable } from 'rxjs';

/** Base class for Meterage provider. Used as Injection token */
export abstract class MeterageDataService {
    /** Watch Meterage list */
    abstract watchAll(): Observable<Meterage[]>;

    /** Watch Meterage by Well ID */
    abstract watchById(id: ID): Observable<Meterage | null>;

    /** Save MeterageData */
    abstract saveMeterage(id: ID, itemList: MeterageItem[]): Observable<Meterage>
}
