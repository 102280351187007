import { Points, UnitDescriptor, UnitGroup } from '@cyberloop/core';

import type { Observable } from 'rxjs';

/** Provides data for navigator viewport */
export abstract class WellDephtPointsProvider {
    /**
     * Get BDE and WDE points for well plus what pass as tags array
     * @param wellId well id string
     * @param tags array of tags ids
     * @param unitGroup unit group 
     * @param unitId unit id
     * @param viewport viewport observable { from: number, to?: number }, if not providet use well id start/suspend time
     * @param isTime flag if point x is time and y is value, otherwise x is value and y is always null
     * @returns record { tagId: Observable<Points> }
     */
    abstract getFor(wellId: string, tagIds: string[], unitGrou: UnitGroup['id'], unitId?: UnitDescriptor['id'], viewport?: Observable<{ from?: number, to?: number } | undefined>, isTime?: Observable<boolean>): Record<string, Observable<Points>>;

    /**
     * Reset cache
     */
    abstract reset(): void;
}