import { FileTag } from '@cyberloop/web/files/model';

import type { Observable } from 'rxjs';

/** Base class for files tag provider. Used as Injection token */
export abstract class FilesTagProviderService {
    /** Watch list of tags */
    abstract watchAll(): Observable<FileTag[]>;

    /** Create tag */
    abstract create(name: string): Observable<FileTag>;
}
