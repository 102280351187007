import { Range } from '@cyberloop/core';
import { ChartAsset, DrillingChartSettings, DrillingWorkspace, Mode, Orientation } from '@cyberloop/web/wells/model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DRILLING_FEATURE } from './drilling.state';

/**
 * Actions for drilling used internally
 * @internal
 * */
export const DrillingActions = createActionGroup({
    source: DRILLING_FEATURE,
    events: {
        'Roll back settings': props<{ settings: DrillingChartSettings }>(),
        'Set settings': props<{ settings: DrillingChartSettings }>(),
        'Set mode': props<{ mode: Mode }>(),
        'Set orientation': props<{ orientation: Orientation }>(),
        'Set chart to show': props<{ chartsToShow: number }>(),
        'Set workspace id': props<{ id: string }>(),
        'Edit asset': props<{ assetIdx: number, chartIdx: number, asset: ChartAsset | null }>(),
        'Live toggle': emptyProps(),
        'Set live': props<{ live: boolean }>(),
        'Set time viewport': props<{ range: Range | undefined }>(),
        'Set depth viewport': props<{ range: Range | undefined }>(),
        'Pan changed viewport': props<{ range: Range | undefined }>(),
        'Set time well viewport': props<{ range: Range | undefined }>(),
        'Set depth well viewport': props<{ range: Range | undefined }>(),
        'Set live viewport': props<{ reset: boolean }>(),
        'Zoom viewport': props<{ zoomIn: boolean }>(),
        'Change section id': props<{ sectionId: string | null }>(),
        'Set last live wde depth': props<{ depth: number }>(),
        'Set export loading status': props<{ exportLoading: boolean }>(),
        'Set charts loading': props<{ num: number }>(),
        'Reset charts loading': emptyProps(),
        'Set live subscription status': props<{ liveSubscriptionStatus: boolean }>(),
        'Create workspace': props<{ ws: DrillingWorkspace }>(),
        'Edit workspace': props<{ id: string, name: string }>(),
        'Delete workspace': props<{ id: string }>()
    }
});

/**
 * Public actions for drilling 
 * */
export const PublicDrillingActions = {
    setMode: DrillingActions.setMode,
    setOrientation: DrillingActions.setOrientation,
    setChartToShow: DrillingActions.setChartToShow,
    setWorkspaceId: DrillingActions.setWorkspaceId,
    editAsset: DrillingActions.editAsset,
    liveToggle: DrillingActions.liveToggle,

    panChanged: DrillingActions.panChangedViewport, 
    
    changeTimeViewport: DrillingActions.setTimeViewport,
    changeDepthViewport: DrillingActions.setDepthViewport,

    changeTimeWellViewport: DrillingActions.setTimeWellViewport,
    changeDepthWellViewport: DrillingActions.setDepthWellViewport,

    changeLiveViewport: DrillingActions.setLiveViewport,
    setLive: DrillingActions.setLive,
    zoomViewport: DrillingActions.zoomViewport,
    changeSectionId: DrillingActions.changeSectionId,
    setLastLiveWDEDepth: DrillingActions.setLastLiveWdeDepth,

    changeExportLoadingStatus: DrillingActions.setExportLoadingStatus,
    changeChartsLoading: DrillingActions.setChartsLoading,
    resetChartsLoading: DrillingActions.resetChartsLoading,

    changeLiveSubscriptionStatus: DrillingActions.setLiveSubscriptionStatus,

    createWorkspace: DrillingActions.createWorkspace,
    editWorkspace: DrillingActions.editWorkspace,
    deleteWorkspace: DrillingActions.deleteWorkspace
};