import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { TndEffects } from './tnd.effects';
import { tndReducer } from './tnd.reducer';
import { TND_FEATURE } from './tnd.state';

/** T&D providers */
export const TndProviders = [
    provideEffects([TndEffects]),
    provideState(TND_FEATURE, tndReducer)
];