<input class="toggle-checkbox" type="checkbox" [checked]="isOpen" />
<button class="button" [matMenuTriggerFor]="menu" (click)="onOpen(true)">
    <ng-content></ng-content>
    <div class="expand-border">
        <icon name="arrow__down"></icon>
    </div>
</button>
<mat-menu #menu="matMenu" (closed)="onOpen(false)">
    <button mat-menu-item 
        *ngFor="let item of items" 
        [ngClass]="{'selected': item.id === current }"
        (click)="onSelect(item)">
        {{ item.name }}
    </button>
</mat-menu>
