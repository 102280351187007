import { Points, UnitDescriptor, UnitGroup } from 'libs/core/src/lib/models';

import type { Observable } from 'rxjs';

/** Provides data for current viewport */
export abstract class NaviDataProvider {
    /**
     * Get points for current viewport
     * @param wellId Well identifier
     * @param tagId Tag to get data for
     */
    abstract getFor(wellId: string, tagIds: string[], unitGroup: UnitGroup['id'], unitId?: UnitDescriptor['id']): Record<string, Observable<Points>>;

    /**
     * Reset cache
     */
    abstract reset(): void;
}