
/**
 * Class for performing mathematical and trigonometric operations with degrees.
 */
export class Deg {
    /**
     * The most precise value of PI.
     * @private
     */
    private static readonly _pi = 4 * Math.atan(1); // most precise value of PI. IDK why, but it is
    /**
     * The value of PI divided by 180.
     * @private
     */
    private static readonly _pi180 = this._pi / 180;
    /**
     * The value of 180 divided by PI.
     * @private
     */
    private static readonly _180pi = 180 / this._pi;

    /**
     * Converts degrees to radians.
     * @param {number} deg - The angle in degrees.
     * @returns {number} The angle in radians.
     */
    public static toRad(deg: number) {
        return (deg * this._pi180);
    }

    /**
     * Converts radians to degrees.
     * @param {number} rad - The angle in radians.
     * @returns {number} The angle in degrees.
     */
    public static fromRad(rad: number) {
        return (rad * this._180pi);
    }

    /**
     * Calculates the sine of an angle in degrees.
     * @param {number} deg - The angle in degrees.
     * @returns {number} The sine of the angle.
     */
    public static sin(deg: number) {
        return Math.sin(this.toRad(deg));
    }

    /**
     * Calculates the cosine of an angle in degrees.
     * @param {number} deg - The angle in degrees.
     * @returns {number} The cosine of the angle.
     */
    public static cos(deg: number) {
        return Math.cos(this.toRad(deg));
    }

    /**
     * Calculates the tangent of an angle in degrees.
     * @param {number} deg - The angle in degrees.
     * @returns {number} The tangent of the angle.
     */
    public static tg(deg: number) {
        return Math.tan(this.toRad(deg));
    }

    /**
     * Calculates the arc cosine of a value and returns the angle in degrees.
     * @param {number} value - The value for which to calculate the arc cosine.
     * @returns {number} The angle in degrees.
     */
    public static acos(value: number) {
        return this.fromRad(Math.acos(value));
    }

    /**
     * Calculates the arc sine of a value and returns the angle in degrees.
     * @param {number} value - The value for which to calculate the arc sine.
     * @returns {number} The angle in degrees.
     */
    public static asin(value: number) {
        return this.fromRad(Math.asin(value));
    }

    /**
     * Calculates the arc tangent of a value and returns the angle in degrees.
     * @param {number} value - The value for which to calculate the arc tangent.
     * @returns {number} The angle in degrees.
     */
    public static atg(value: number) {
        return this.fromRad(Math.atan(value));
    }

    /**
     * Normalizes an angle within the specified range.
     * @param {number} value - The original angle in degrees.
     * @param {number} min - The minimum value of the angle in degrees.
     * @param {number} max - The maximum value of the angle in degrees.
     * @returns {number} The normalized angle within the specified range.
     */
    public normalizeAngle(value: number, min: number, max: number): number {
        const turn = max - min;
        while (value < min) {
            value += turn;
        }

        while (value > max) {
            value -= turn;
        }

        return value;
    }
}
