import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ClWellChartSeries } from '../../../models';

/** Legend component to display along with the Well Chart */
@Component({
  selector: 'cyberloop-well-chart-legend',
  standalone: true,
  imports: [],
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClWellChartLegendComponent {

  private readonly _seriesVisibility: Record<ClWellChartSeries, boolean> = {
    [ClWellChartSeries.WDE]: true,
    [ClWellChartSeries.BDE]: true,
    [ClWellChartSeries.Plan]: true,
    [ClWellChartSeries.PlanLimit]: true
  };

  /** @internal */
  readonly ClWellChartSeries = ClWellChartSeries;

  /** To show the Plan item or not */
  @Input() showPlan: boolean | null = false;
  /** To show the Plan tech limit item or not */
  @Input() showPlanLimit: boolean | null = false;

  /** Emits when mouse is over a series element */
  @Output() readonly seriesOver = new EventEmitter<ClWellChartSeries>();
  /** Emits when mouse is out of a series element */
  @Output() readonly seriesOut = new EventEmitter<ClWellChartSeries>();
  /** Emits when mouse is clicked on a series element */
  @Output() readonly seriesVisibility = new EventEmitter<{ series: ClWellChartSeries, visible: boolean }>();

  /** @internal */
  onMouseOver(series: ClWellChartSeries): void {
    this.seriesOver.emit(series);
  }
  /** @internal */
  onMouseOut(series: ClWellChartSeries): void {
    this.seriesOut.emit(series);
  }
  /** @internal */
  onClick(series: ClWellChartSeries): void {
    this._seriesVisibility[series] = !this._seriesVisibility[series];
    this.seriesVisibility.emit({ series, visible: this._seriesVisibility[series] });
  }

  /** @internal */
  isSeriesVisible(series: ClWellChartSeries): boolean {
    return this._seriesVisibility[series];
  }

}
