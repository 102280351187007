import { Rig } from '@cyberloop/core';

export const WELLS_FEATURE = 'WELLS';

/**
 * State for wells
 * */
export interface WellsState {
    wellId: string | null;
    sectionId: string | null;
    rigs: Rig[];
}

export function getDefaultWellsState(): WellsState {
    return {
        wellId: null,
        sectionId: null,
        rigs: []
    };
}            
