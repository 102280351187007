import { Injectable } from '@angular/core';

import { CoreSelectors, LiveDataProviderService } from '@cyberloop/core';
import { TracesServiceProvider } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, distinctUntilChanged, finalize, map, shareReplay, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TracesService extends TracesServiceProvider {
    
    private readonly _cache: Record<string, Observable<number> | undefined> = {};

    constructor(
        private readonly _store: Store,
        private readonly _liveData: LiveDataProviderService
    ) {
        super();
    }
    
    getTraceValue(tagName: string): Observable<number> {
        return this._cache[tagName] ??= this._store.select(CoreSelectors.currentWell).pipe(
            switchMap(x => {
                if (!x?.rig || x.releaseTime || x.suspendTime) {
                    return EMPTY;
                }

                return this._liveData.getForRigAndTag(x.rig, tagName);
            }),
            map(x => x.value),
            distinctUntilChanged((a, b) => a === b),
            finalize(() => {
                this._cache[tagName] = undefined;
            }),
            shareReplay(1)
        );
    }


}