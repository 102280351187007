import { Injectable } from '@angular/core';

import { RigActivity, RigState, Well, WellKnownParams } from '@cyberloop/core';
import { isNil } from 'lodash';
import { Observable, map, of, timer } from 'rxjs';

import * as moment from 'moment';

import { WellDataService } from './well-data.service';

import type { WellDataObject, WellMeta } from '@cyberloop/web/app/model';
@Injectable({
    // This should be the Singleton
    providedIn: 'root'
})
export class WellObjectExtenderService {

    constructor(
        private readonly wellDataService: WellDataService
    ) {
        //
    }

    getWellWithData(well: Well): WellDataObject {
        // TODO: Add observables to well meta
        const aWell: WellMeta = {
            ...well,
            sectionName: well.sections.length > 0 ? well.sections[well.sections.length - 1].name : 'N/A'
        };

        return {
            rig: well.rig,
            id: well.id,
            name: well.name,
            startTime: well.startTime,
            suspendTime: well.suspendTime,
            spudTime: well.spudTime ?? undefined,
            sectionName: aWell.sectionName,
            releaseTime: well.releaseTime ?? undefined,
            favorite: false,

            rigStatus$: this.wellDataService.getAssetValue$(well.id, WellKnownParams.RST).pipe(
                map(item => item as RigState)
            ),
            rigActivity$: of(well.stats.today.rigActivity as RigActivity),
            currentBitDepth$: of(well.stats.today.bitDepth),
            currentWellDepth$: of(well.stats.today.holeDepth),

            // TODO: Find out which tag should it be
            currentRotary$: of(100 * well.stats.overall.rotary / (well.stats.overall.rotary + well.stats.overall.slide)),
            currentSlide$: of(100 * well.stats.overall.slide / (well.stats.overall.rotary + well.stats.overall.slide)),
            currentOnBtm$: this.getSinceSpud(aWell, 'seconds').pipe(
                map((secondsFromStart) => {
                    if(isNil(secondsFromStart) || secondsFromStart === 0) {
                        return null;
                    }
                    return 100 * (well.stats.overall.onBottom / secondsFromStart);
                })
            ),
            currentDrilled$: of(well.stats.overall.rotary + well.stats.overall.slide),
            currentSinceSpud$: this.getSinceSpud(aWell),

            // TODO: Implement this values
            dailyDrilled$: of(well.stats.today.rotary + well.stats.today.slide),
            dailyRop$: of(well.stats.today.averageROP),
            dailyRotary$: of(100 * well.stats.today.rotary / (well.stats.today.rotary + well.stats.today.slide)),
            dailySlide$: of(100 * well.stats.today.slide / (well.stats.today.rotary + well.stats.today.slide)),
            dailyOnBtm$: of(100 * (well.stats.today.onBottom / 3600 / 24)),

            // TODO: Get from @cyberloop/core state
            depthUnitLabel: 'm',
            ropUnitLabel: 'm/h',

            chart: {
                currentValues$: this.wellDataService.getWellCurrentPoints(well.id),
                plannedValues$: this.wellDataService.getWellCurrentPoints(well.id)
            },

            sections: well.sections
        };
    }


    private getDailyValue(wellMeta: WellMeta, tagName: WellKnownParams): Observable<number | null> {
        if (wellMeta.releaseTime) {
            return of(null);
        }

        return this.wellDataService.getAssetValue$(wellMeta.id, tagName);
    }

    private getSinceSpud(wellMeta: WellMeta, type: moment.unitOfTime.Diff = 'days'): Observable<number | null> {
        if (!wellMeta.spudTime) {
            return of(null);
        }

        if (wellMeta.releaseTime) {
            return of(moment(wellMeta.releaseTime).diff(wellMeta.spudTime, type));
        }

        return timer(0, 60000).pipe(
            map(() => moment.utc().diff(wellMeta.spudTime, type))
        );
    }

}