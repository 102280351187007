import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet, TitleStrategy } from '@angular/router';

import { filter, map, startWith } from 'rxjs';

import { AnimatedLogoComponent } from '../animated-logo/animated-logo.component';

/** @internal */
@Component({
    selector: 'cyberloop-ui-auth',
    standalone: true,
    imports: [
        AsyncPipe,
        RouterOutlet,
        AnimatedLogoComponent
    ],
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthComponent {
    /**
     *
     */
    constructor(
        private readonly title: TitleStrategy,
        private readonly router: Router
    ) {
    }

    @Input() appName = '';

    readonly title$ = this.router.events.pipe(
        filter(x => x instanceof NavigationEnd),
        startWith(null),
        map(() => this.title.buildTitle(this.router.routerState.snapshot))
    );
}
