import { Observable } from 'rxjs';

import { Moment } from 'moment';

import { ID } from './entity';

/** Template base model */
export interface Template {
    /** Template id */
    id: ID;
    /** Template author - email */
    author: string;
    /** Create at date */
    createdAt: Moment;
    /** Updated at date */
    updatedAt: Moment;
    /** Template name */
    name: string;
    /** Template description */
    description: string;
    /** Template access type */
    access: TemplateAccess;
}

/** Create template model */
export interface CreateTemplate {
    /** Template name */
    name: string;
    /** Template description */
    description: string;
    /** TEmplate access type */
    access: TemplateAccess;
}

/** Template enum access type */
export enum TemplateAccess {
    /** Presonal access */
    Personal = 'personal',
    /** Public access */
    Public = 'public'
}

/** Load template dialog data model */
export interface LoadTemplateDialogData<T extends Template> {
    /** List of templates */
    list$: Observable<T[]>;
    /** Template loading status */
    listLoading$: Observable<boolean>;
    /** Template loaded status */
    listLoaded$: Observable<boolean>;
}

/** Load template dialog result model */
export interface LoadTemplateDialogResult<T extends Template> {
    /** Template to upload */
    upload?: T;
    /** Template to delet */
    delete?: T;
}

/** Save template dialog data */
export type SaveTemplateDialogData<T extends Template> = Pick<T, 'name' | 'description' | 'access'>;