import { ID } from '@cyberloop/core';
import { CreatePlanning, InitPlanningVersion, Planning, PlanningVersion, UpdatePlanning } from '@cyberloop/web/planning/shared/model';

import type { Observable } from 'rxjs';

/** Base class for planning provider. Used as Injection token */
export abstract class PlanningProviderService {
    /** Watch list of plannings */
    abstract watchAll(): Observable<Planning[]>;

    /** Watch planning by ID */
    abstract watch(id: ID): Observable<Planning | null>;

    /** Get planning by ID */
    abstract get(id: ID): Observable<Planning | null>;

    /** Watch planning by Well ID */
    abstract watchByWellId(wellId: ID): Observable<Planning | null>;

    /** Create planning */
    abstract create(data: CreatePlanning, version: InitPlanningVersion): Observable<Planning>;

    /** Update planning */
    abstract update(planningId: ID, data: UpdatePlanning): Observable<Planning>;

    /** Select planning version */
    abstract setActiveVersion(planningId: ID, version: PlanningVersion): Observable<Planning>;

    /** Set Well ID */
    abstract setWellId(planningId: ID, wellId: ID): Observable<Planning>;
}
