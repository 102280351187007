import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { TorqueAndDragModel } from '../../internals/tnd';

export type TndSingleModelResult = {
    torqndrag: {
        model: TorqueAndDragModel
    }
}

type TndSingleModelInput = {
    modelId: string
}

@Injectable({
    providedIn: 'root'
})
export class TndSingleModelQuery extends GraphQLQuery<TndSingleModelResult, TndSingleModelInput> {
    override readonly document = `
        query GetTorqueAndDragSingleModel($modelId: ID!) {
            torqndrag {
                model(id: $modelId) {
                    ... on TorqndragModel {
                        id
                        kind
                        name
                        valueUnitId
                        depthUnitId
                        startDepth
                        endDepth
                        pushAlarmLine
                        pullAlarmLine
                        highTorqueAlarmLine
                    }
                }
            }
        }
    `;
}
