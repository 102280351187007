import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';

import { ClChartComponent, CompactNumberFormatter, IconComponent, RigState, UnitsConverterService, UnitsSelectors, WellKnownUnitGroupIds } from '@cyberloop/core';
import { KpiActions, KpiSelectors } from '@cyberloop/web/wells/data';
import { WidgetDataProvider, WidgetSettingsHandler, WidgetSize, WidgetType, rigStatePerHourDefaultSettings } from '@cyberloop/web/wells/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, filter, map, switchMap, tap } from 'rxjs';

import { PointOptionsType } from 'highcharts';
import * as moment from 'moment';

import { KpiWidgetComponent } from '../widget/kpi-widget.component';
import { SettingsComponent } from './settings/settings.component';

import type { Points, SimpleChangesOf, UnitDescriptor } from '@cyberloop/core';
import type { KpiRigStateSnapshot, KpiWidget, RigStatePerHourWidgetSettings } from '@cyberloop/web/wells/model';

const BDE_SERIES_ID = 'bde';
const WDE_SERIES_ID = 'wde';

@Component({
    selector: 'cyberloop-rig-state-per-hour-widget',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        KpiWidgetComponent,
        ClChartComponent,
        IconComponent
    ],
    templateUrl: './rig-state-per-hour-widget.component.html',
    styleUrls: ['./rig-state-per-hour-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class RigActivityPerHourWidgetComponent implements KpiWidget, AfterViewInit, OnChanges {

    private readonly _id$ = new BehaviorSubject<string>('-');
    private readonly _dataLoading$ = new BehaviorSubject<boolean>(true);
    private readonly _textPlaceholder$ = new BehaviorSubject<string | undefined>(undefined);

    private _hoursData?: Record<number, KpiRigStateSnapshot>;
    private _depthUnit?: UnitDescriptor;

    constructor(
        private readonly store: Store,
        private readonly settings: WidgetSettingsHandler,
        private readonly data: WidgetDataProvider,
        private readonly unitsConverter: UnitsConverterService
    ) {
        const idPipe = this._id$.pipe(
            untilDestroyed(this),
            filter(Boolean),
            tap(() => this._dataLoading$.next(true))
        );

        combineLatest([
            // Get widget data
            idPipe.pipe(
                switchMap(id => this.data.getData(id, WidgetType.RigStatePerHour))
            ),
            // Get widget settings (for units conversion)
            idPipe.pipe(
                switchMap(id => this.store.select(KpiSelectors.widgetSettings<RigStatePerHourWidgetSettings>(id))),
                map(widgetSettings => widgetSettings?.depthUnitId ?? rigStatePerHourDefaultSettings.depthUnitId),
                switchMap(depthUnitId => this.store.select(
                    UnitsSelectors.getUnit(WellKnownUnitGroupIds.Length, depthUnitId)
                ))
            )
        ]).pipe(
            untilDestroyed(this)
        ).subscribe(([widgetData, unit]) => {
            if (!this.chart) {
                return;
            }

            if (typeof widgetData === 'string') {
                this._dataLoading$.next(false);
                this._textPlaceholder$.next(widgetData);
                return;
            }

            const wde = widgetData.wde;
            const bde = widgetData.bde;

            const hasData = Object.values(widgetData.data)?.length > 0 || wde.length > 0 || bde.length > 0;
            const dataIsDifferent = hasData || !hasData && Object.values(this._hoursData ?? {}).length > 0;
            const unitsAreDifferent = unit?.id !== this._depthUnit?.id;

            if (!hasData && !dataIsDifferent && !unitsAreDifferent) {
                return;
            }

            this._hoursData = widgetData.data;
            this._depthUnit = unit;

            const rigStates = Object.keys(this._hoursData ?? {});

            if (this.data && rigStates.length > 0) {
                const chartData = this.calculateChartData(this._hoursData);
                this.setChartData(chartData);
            }

            if (wde?.length) {
                this.chart.setSeriesData(WDE_SERIES_ID, this.getConvertedPoints(wde));
            }

            if (bde?.length) {
                this.chart.setSeriesData(BDE_SERIES_ID, this.getConvertedPoints(bde));
            }

            const now = moment();

            this.chart.update({
                xAxis: [{
                    id: 'depth',
                    max: now.startOf('hour').valueOf() - 30 * 60 * 1000,
                    min: now.subtract(24, 'hours').startOf('hour').valueOf() - 30 * 60 * 1000
                }]
            });

            this._dataLoading$.next(false);
        });
    }

    readonly options = this.getOptions();

    readonly dataLoading$ = this._dataLoading$.asObservable();
    readonly textPlaceholder$ = this._textPlaceholder$.asObservable();
    readonly canShowData$ = combineLatest([
        this.dataLoading$,
        this.textPlaceholder$
    ]).pipe(
        map(([loading, placeholder]) => !loading && !placeholder)
    );

    readonly placeholderColumns = new Array(24).fill(0).map(() => ([
        Math.floor(Math.random() * 80) + 10, // Position
        Math.random() * 10 + 5 // Size
    ]));

    @Input() id = '-';
    @Input() size = WidgetSize.Medium;

    @ViewChild(ClChartComponent, { static: true }) chart?: ClChartComponent;

    ngAfterViewInit(): void {
        if (!this.chart) {
            throw new Error('<cyberloop-chart> component should be present');
        }
    }

    ngOnChanges(changes: SimpleChangesOf<RigActivityPerHourWidgetComponent>): void {
        if (changes.id?.currentValue) {
            this._id$.next(changes.id.currentValue);
        }
    }

    async onSettings(): Promise<void> {
        await this.settings.showSettings<RigStatePerHourWidgetSettings>(
            this.id,
            SettingsComponent,
            rigStatePerHourDefaultSettings
        );
    }

    onDelete(): void {
        this.store.dispatch(KpiActions.deleteWidget({ widgetId: this.id }));
    }

    private getOptions(): Highcharts.Options {
        const hours: string[] = [];

        const now = moment();
        const dayAgo = now.clone().startOf('hour').subtract(23, 'hours');

        for (let i = 0; i < 24; i++) {
            const m = dayAgo.clone().add(i, 'hours');
            hours.push(m.format('HH'));
        }

        const rigStates = Object.values(RigState)
            .map(item => parseInt(String(item), 10))
            .filter(item => !isNaN(item)) as RigState[];

        return {
            chart: {
                type: 'column',
                spacingLeft: 0,
                marginRight: 68,
                marginBottom: 45,
                marginLeft: 35,

                events: {
                    render: function () {
                        // A hack to set the plot area radius
                        (this as any).plotBackground.attr({ rx: 6, ry: 6 });
                    }
                }
            },
            xAxis: [
                // For columns (hours)
                {
                    id: 'hours',
                    categories: hours,
                    tickInterval: 1,
                    labels: {
                        step: 1,
                        allowOverlap: true,
                        autoRotation: undefined,
                        formatter: function () {
                            const value = String(this.value);
                            return value.length < 2 ? '0' + value : value;
                        }
                    },
                    title: {
                        text: 'Hours',
                        margin: 2
                    }
                },
                // For Depth
                {
                    id: 'depth',
                    type: 'datetime',
                    // visible: false,
                    min: moment.utc().startOf('day').valueOf(),
                    max: moment.utc().startOf('day').add(1, 'day').valueOf()
                }
            ],
            yAxis: [
                // For columns (hours)
                {
                    min: -2.5,
                    max: 62.5,
                    startOnTick: false,
                    endOnTick: false,
                    tickInterval: 5,
                    offset: -5,
                    title: {
                        text: ''
                    }
                },
                // For Depth
                {
                    opposite: true,
                    reversed: true,
                    startOnTick: false,
                    endOnTick: false,
                    offset: -5,
                    title: {
                        text: 'Depth'
                    },
                    labels: {
                        enabled: true,
                        formatter: function () {
                            return CompactNumberFormatter.format(parseFloat(String(this.value)));
                        }
                    }
                }
            ],
            // legend: {
            //     reversed: true
            // },
            plotOptions: {
                column: {
                    stacking: 'normal'
                    // dataLabels: {
                    //     enabled: true
                    // }
                },
                line: {
                    marker: {
                        enabled: false,
                        symbol: 'circle'
                    }
                }
            },
            tooltip: {
                padding: 0,
                useHTML: true,
                formatter: function () {
                    const key = this.point.options.id;
                    const title = this.series.name ?? 'N/A';

                    let value = (
                        this.point.options.value
                            ? this.point.options.value.toFixed(2)
                            : this.y?.toFixed(2)
                    )?.replace(/\.00$/, '') ?? '';

                    if (this.point.options.custom?.['unit']) {
                        value += ' ' + this.point.options.custom['unit'];
                    }

                    return `<span class="dot rs-${key}"></span><span class="title">${title}</span><span class="value">${value}</span>`;
                }
            },
            series: [
                ...rigStates.map(state => ({
                    yAxis: 0,
                    xAxis: 0,
                    type: 'column',
                    id: RigState.getId(state),
                    name: RigState.getName(state),
                    data: []
                } as Highcharts.SeriesOptionsType)),

                {
                    yAxis: 1,
                    xAxis: 1,
                    id: WDE_SERIES_ID,
                    name: 'WDE',
                    type: 'line',
                    colorIndex: 2,
                    data: []
                },
                {
                    yAxis: 1,
                    xAxis: 1,
                    id: BDE_SERIES_ID,
                    name: 'BDE',
                    type: 'line',
                    colorIndex: 1,
                    data: []
                }
            ]
        };
    }

    private calculateChartData(data: Record<number, KpiRigStateSnapshot>): Record<RigState, Highcharts.PointOptionsType[]> {
        // A list of rig states
        const rigStates = Object.values(RigState)
            .map(item => parseInt(String(item), 10))
            .filter(item => !isNaN(item)) as RigState[];

        // For values normalization
        const rigStatesValuesSum: Record<number, number> = {};

        // Data for the chart - an array of values for each hour grouped by
        // RigState
        const chartData: Record<RigState, Highcharts.PointOptionsType[]> = {} as any;

        const now = moment();
        const nowHourStart = now.clone().startOf('hour').unix();

        const dayAgo = now.clone().startOf('hour').subtract(23, 'hours');

        // Activities are bar segments inside of a category
        for (const state of rigStates) {

            // Create an array for this category (will be used by the
            // corresponding series)
            chartData[state] = [];

            // Hours are categories
            for (let hour = 0; hour < 24; hour++) {
                const m = dayAgo.clone().add(hour, 'hours');
                const hourTs = m.unix();

                const hourData = data[hourTs];

                if (typeof rigStatesValuesSum[hourTs] === 'undefined') {
                    // Calculate values sum for this hour
                    rigStatesValuesSum[hourTs] = Object.values(hourData ?? {})
                        .reduce((sum, value) => value + sum, 0);
                }

                // The value should be normalized (from 0 to 60), so all
                // columns will be of the same height

                const multiplier = nowHourStart === hourTs
                    ? now.minutes()
                    : 60;

                const value = rigStatesValuesSum[hourTs] > 0 && hourData[state] > 0
                    ? hourData[state] / rigStatesValuesSum[hourTs] * multiplier
                    : 0;

                chartData[state].push({
                    id: RigState.getId(state),
                    name: RigState.getName(state),
                    y: value,
                    value: hourData?.[state] ?? 0,
                    className: `rs-${RigState.getId(state)}`,
                    custom: { h: hourTs }
                });
            }
        }

        return chartData;
    }

    private setChartData(chartData: Record<RigState, Highcharts.PointOptionsType[]>) {
        const chart = this.chart;

        if (!chart) {
            return;
        }

        for (const key in chartData) {
            const rigState = key as any as RigState;
            chart.setSeriesData(RigState.getId(rigState), chartData[rigState]);
        }
    }

    private getConvertedValue(source: number): number {
        return this._depthUnit && source !== null
            ? this.unitsConverter.convertToUnit(source, this._depthUnit)
            : source;
    }

    private getConvertedPoints(source: Points): PointOptionsType[] {
        return source.map(item => ({
            ...item,
            y: item.y ? this.getConvertedValue(item.y) : item.y,
            custom: {
                unit: this._depthUnit?.label
            }
        }));
    }

}
