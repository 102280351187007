import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { range } from 'lodash';

@Component({
    selector: 'cyberloop-planning-version-history-skeleton',
    standalone: true,
    imports: [NgFor],
    templateUrl: './planning-version-history-skeleton.component.html',
    styleUrls: ['./planning-version-history-skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningVersionHistorySkeletonComponent {
    readonly skeletonCount = range(5);
}
