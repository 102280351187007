import { Pipe, PipeTransform } from '@angular/core';

/**
 * Return trace color by value
 * @example 
 * 88 => var(--trace-88)
 */
@Pipe({
    name: 'traceColor',
    standalone: true
})
export class TraceColorPipe implements PipeTransform {
    /**
     * @internal 
     */
    transform(value: number | null | undefined): string {
        if (!value) {
            return 'var(--cl-text-enable-prim)';
        } 
        else if (value > 1000) {
            return `var(--cl-accent-${(Math.floor(value - 1000))})`;
        } 
        else if (value > 0 && value < 20) {
            return `var(--trace-${value})`;
        } 
        else {
            return 'var(--cl-text-enable-prim)';
        }
    }

}
