import { CoreSelectors } from '@cyberloop/core';
import { Mode } from '@cyberloop/web/wells/model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DRILLING_FEATURE, DrillingState } from './drilling.state';

const feature = createFeatureSelector<DrillingState>(DRILLING_FEATURE);

const sectionId = createSelector(feature, state => state.sectionId);

const orientation = createSelector(feature, state => state.orientation);
const mode = createSelector(feature, state => state.mode);
const isTime = createSelector(feature, (state) => state.mode === Mode.Time);

const timeWellRange = createSelector(CoreSelectors.currentWell, well => {
    const range = well ? { from: well.startTime.getTime(), to: well?.releaseTime?.getTime() ??  well?.suspendTime?.getTime() ?? Date.now() } : undefined;
    return range;
});

const wellViewport = createSelector(feature, timeWellRange, isTime, (state, timeRange, timeMode) => timeMode ? timeRange : state.naviDepth);

const viewport = createSelector(feature, state => state.mode === Mode.Depth ? state.depth : state.time);

const workspaces = createSelector(feature, state => state.mode === Mode.Depth ? state.depthWs : state.timeWs);
const workspaceId = createSelector(feature, state => state.mode === Mode.Depth ? state.selectedDepthWsId : state.selectedTimeWsId);
const workspace = createSelector(workspaces, workspaceId, (wss, id) => wss.find(x => x.id === id) ?? wss[0]);
const charts = createSelector(workspace, ws => ws?.charts ?? []);
const chartsToShow = createSelector(workspace, ws => ws?.chartsToShow ?? 0);

const live = createSelector(feature, state => state.live);
const liveSection = createSelector(live, sectionId, CoreSelectors.currentWell, (isLive, sectionID, well) => {
    const section = well?.sections.find(x => x.id.toString() === sectionID);
    if (!section) {
        return false;
    }

    return isLive && !section.endTime && !section.to;
});
const canBeLive = CoreSelectors.isCurrentWellActive;

const chartsLoading = createSelector(feature, state => Boolean(state.chartsLoading));
const exportLoading = createSelector(feature, state => state.exportLoading);
const settingsLoaded = createSelector(feature, state => state.settingsLoaded);
const settings = createSelector(feature, state => ({
    orientation: state.orientation,
    mode: state.mode,
    selectedDepthWsId: state.selectedDepthWsId,
    selectedTimeWsId: state.selectedTimeWsId,
    depthWs: state.depthWs,
    timeWs: state.timeWs
}));

const lastLiveWellDepth = createSelector(feature, state => state.lastLiveWellDepth);
const liveSubscriptionsStatus = createSelector(feature, state => state.liveSubscriptionStatus);

/**
 * Selectors for drilling
 * */
export const DrillingSelectors = {
    orientation,
    mode,
    viewport,
    wellViewport,
    workspaces,
    workspace,
    workspaceId,
    charts,
    chartsToShow,
    settings,

    live,
    liveSection,
    canBeLive, 
    
    settingsLoaded,
    exportLoading,
    chartsLoading,
    sectionId,
    isTime,
    lastLiveWellDepth,
    liveSubscriptionsStatus
};
