<div class="head">
    <h1 class="title">
        Set plan
    </h1>
</div>

<div class="content">
    <ng-container *ngIf="planningListLoaded$ | async">
        <div *ngFor="let planning of planningList$ | async; empty noData" (click)="onSelect(planning)" class="card">
            <span class="name">{{ planning.name }}</span>
        </div>
        <ng-template #noData>
            <div class="no-data">No data</div>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="planningListLoading$ | async">
        <div *ngFor="let i of skeletonCount" class="card skeleton"></div>
    </ng-container>
</div>

<div class="actions">
    <button (click)="close()" class="cl-flat-button action-button">Cancel</button>
    <button (click)="create()" class="cl-flat-button action-button">Create new</button>
</div>


<button (click)="close()" class="close cl-icon-button">
    <icon name="close"></icon>
</button>