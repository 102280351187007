import { AsyncPipe, NgIf, formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnChanges, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { ClChartComponent, IconComponent, UnitsConverterService, UnitsSelectors, WellKnownUnitGroupIds } from '@cyberloop/core';
import { KpiActions, KpiSelectors } from '@cyberloop/web/wells/data';
import { RopWidgetSettings, WidgetDataProvider, WidgetSettingsHandler, WidgetSize, WidgetType, ropDefaultSettings } from '@cyberloop/web/wells/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest, filter, map, of, switchMap, tap } from 'rxjs';

import { KpiWidgetComponent } from '../widget/kpi-widget.component';
import { SettingsComponent } from './settings/settings.component';

import type { UnitDescriptor } from '@cyberloop/core';
import type { SimpleChangesOf } from '@cyberloop/core';
import type { KpiWidget } from '@cyberloop/web/wells/model';

const SERIES_ID = 'rop';
const PLOT_LINE_AVERAGE_ID = 'average';
const PLOT_LINE_TARGET_ID = 'target';

@Component({
    selector: 'cyberloop-rop-widget',
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        KpiWidgetComponent,
        MatFormFieldModule,
        ClChartComponent,
        MatSelectModule,
        IconComponent
    ],
    templateUrl: './rop-widget.component.html',
    styleUrls: ['./rop-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class RopWidgetComponent implements KpiWidget, OnChanges {

    private readonly _id$ = new BehaviorSubject<string>('-');
    private readonly _dataLoading$ = new BehaviorSubject<boolean>(true);

    private readonly _average$ = new BehaviorSubject<string>('-');

    private _ropUnit?: UnitDescriptor;
    private _depthUnit?: UnitDescriptor;

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string,
        private readonly store: Store,
        private readonly settings: WidgetSettingsHandler,
        private readonly data: WidgetDataProvider,
        private readonly unitsConverter: UnitsConverterService
    ) {
        const idPipe = this._id$.pipe(
            untilDestroyed(this),
            filter(Boolean),
            tap(() => this._dataLoading$.next(true))
        );

        this.target$ = idPipe.pipe(
            switchMap(id => this.store.select(KpiSelectors.widgetSettings<RopWidgetSettings>(id))),
            switchMap(widgetSettings => {
                return this.store.select(UnitsSelectors.getUnit(
                    WellKnownUnitGroupIds.Speed,
                    widgetSettings?.ropUnitId ?? ropDefaultSettings.ropUnitId
                )).pipe(
                    map(unitId => widgetSettings?.target
                        ? formatNumber(this.getConvertedRopValue(widgetSettings?.target, unitId), this.locale, '1.0-2')
                        : 'N/A'
                    )
                );
            })
        );

        combineLatest([
            // Get widget data
            idPipe.pipe(
                switchMap(id => this.data.getData(id, WidgetType.ROP))
            ),
            // Get widget settings (for units conversion)
            idPipe.pipe(
                switchMap(id => this.store.select(KpiSelectors.widgetSettings<RopWidgetSettings>(id))),
                switchMap(widgetSettings => {
                    return combineLatest([
                        of(widgetSettings),
                        this.store.select(UnitsSelectors.getUnit(
                            WellKnownUnitGroupIds.Speed,
                            widgetSettings?.ropUnitId ?? ropDefaultSettings.ropUnitId
                        )),
                        this.store.select(UnitsSelectors.getUnit(
                            WellKnownUnitGroupIds.Length,
                            widgetSettings?.depthUnitId ?? ropDefaultSettings.depthUnitId
                        ))
                    ]);
                })
            )
        ]).subscribe(([widgetData, [widgetSettings, ropUnit, depthUnit]]) => {
            this._ropUnit = ropUnit;
            this._depthUnit = depthUnit;

            // Set data
            this.chart?.setSeriesData(SERIES_ID, widgetData.rop.map(item => ({
                x: item.x,
                y: item.y ? this.getConvertedRopValue(item.y) : item.y
            })));

            // Calc average

            let total = 0;
            for (const item of widgetData.rop) {
                total += item.y ?? 0;
            }

            const average = widgetData.rop.length > 0 ? this.getConvertedRopValue(total / widgetData.rop.length) : 0;

            this._average$.next(
                total > 0
                    ? formatNumber(average, this.locale, '1.0-2')
                    : 'N/A'
            );

            // Set axes title
            this.chart?.xAxis[0].setTitle({
                text: this.getXTitleText()
            }, false);
            this.chart?.yAxis[0].setTitle({
                text: this.getYTitleText()
            }, false);

            // Update plot lines

            const plotLines: Highcharts.YAxisPlotLinesOptions[] = [
                {
                    id: PLOT_LINE_AVERAGE_ID,
                    value: average,
                    className: 'line-average',
                    zIndex: 4
                }
            ];

            if (typeof widgetSettings?.target !== 'undefined') {
                plotLines.push({
                    id: PLOT_LINE_TARGET_ID,
                    value: this.getConvertedRopValue(widgetSettings?.target),
                    className: 'line-target',
                    zIndex: 4
                });
            }

            this.chart?.update({
                yAxis: [{
                    id: 'main',
                    plotLines
                }]
            });

            this._dataLoading$.next(false);
        });
    }

    readonly options = this.getOptions();
    readonly dataLoading$ = this._dataLoading$.asObservable();

    readonly average$ = this._average$.asObservable();
    readonly target$: Observable<string>;

    get ropUnit() {
        return this._ropUnit?.label ?? '';
    }

    @Input() id = '-';
    @Input() size = WidgetSize.Medium;

    @ViewChild(ClChartComponent, { static: true }) chart?: ClChartComponent;

    ngOnChanges(changes: SimpleChangesOf<RopWidgetComponent>): void {
        if (changes.id) {
            this._id$.next(changes.id.currentValue);
        }
    }

    async onSettings(): Promise<void> {
        await this.settings.showSettings<RopWidgetSettings>(
            this.id,
            SettingsComponent,
            ropDefaultSettings,
            { maxWidth: '520px' }
        );
    }

    onDelete(): void {
        this.store.dispatch(KpiActions.deleteWidget({ widgetId: this.id }));
    }

    private getOptions(): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        return {
            // Area gradient
            defs: {
                chartGradientWde: {
                    tagName: 'linearGradient',
                    attributes: {
                        id: 'chart-gradient-rop',
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    children: [
                        {
                            tagName: 'stop',
                            attributes: {
                                offset: 0
                            }
                        },
                        {
                            tagName: 'stop',
                            attributes: {
                                offset: 1
                            }
                        }
                    ]
                }
            },

            chart: {
                type: 'areaspline',
                marginLeft: 69,
                marginRight: 0,
                marginBottom: 59,
                styledMode: true,
                animation: false,

                events: {
                    render: function () {
                        // A hack to set the plot area radius
                        (this as any).plotBackground.attr({ rx: 6, ry: 6 });
                    }
                }
            },
            tooltip: {
                valueDecimals: 2,
                padding: 0,
                useHTML: true,
                formatter: function () {
                    const title = this.series.name;
                    let value = this.point.y?.toFixed(2).replace(/\.00$/, '');

                    if (self._ropUnit) {
                        value += ' ' + self._ropUnit.label;
                    }

                    return `<span class="dot highcharts-color-${this.colorIndex}"></span><span class="title">${title}</span><span class="value">${value}</span>`;
                }
            },
            xAxis: {
                offset: 2,
                ordinal: false,
                title: {
                    text: this.getXTitleText(),
                    margin: 5,
                    useHTML: true
                },
                tickLength: 0,
                tickWidth: 0,
                minPadding: 0,
                maxPadding: 0,
                labels: {
                    formatter: function () {
                        return formatNumber(self.getConvertedDepthValue(this.pos), self.locale, '1.0-2');
                    }
                }
            },
            yAxis: {
                id: 'main',
                min: 0,
                offset: -4,
                title: {
                    text: this.getYTitleText(),
                    useHTML: true
                }
            },
            plotOptions: {
                areaspline: {
                    animation: false,
                    clip: true,
                    marker: {
                        enabled: false
                    }
                }
            },
            series: [
                {
                    id: SERIES_ID,
                    name: 'ROP',
                    type: 'areaspline',
                    colorIndex: 1,
                    data: []
                }
            ]
        };
    }

    // --

    private getConvertedRopValue(source: number, ropUnit?: UnitDescriptor): number {
        if (!ropUnit) {
            ropUnit = this._ropUnit;
        }

        return ropUnit
            ? this.unitsConverter.convertToUnit(source, ropUnit)
            : source;
    }
    private getConvertedDepthValue(source: number): number {
        return this._depthUnit
            ? this.unitsConverter.convertToUnit(source, this._depthUnit)
            : source;
    }

    private getXTitleText(): string {
        let res = 'Depth';

        if (this._depthUnit) {
            res += ` <span class="faded">${this._depthUnit.name}</span>`;
        }

        return res;
    }
    private getYTitleText(): string {
        let res = 'ROP';

        if (this._ropUnit) {
            res += ` <span class="faded">${this._ropUnit.label}</span>`;
        }

        return res;
    }

}
