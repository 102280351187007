import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, UnitsSelectors, WellKnownUnitGroupIds, WellKnownUnitIds } from '@cyberloop/core';
import { RateOfAdvanceWidgetSettings, rateOfAdvanceDefaultSettings } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { ClTimeRangeSelectorComponent } from '../../../time-range-selector/time-range-selector.component';
import { KpiWidgetSettingsComponent } from '../../widget-settings/kpi-widget-settings.component';

import type { PopupContent } from '@cyberloop/core';
@Component({
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        FormsModule,
        ReactiveFormsModule,
        KpiWidgetSettingsComponent,
        ClTimeRangeSelectorComponent,
        ClButtonToggleComponent
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements PopupContent<RateOfAdvanceWidgetSettings> {

    private _closeFn?: (result: RateOfAdvanceWidgetSettings | null) => void;
    private _settings?: RateOfAdvanceWidgetSettings;

    constructor(
        private readonly store: Store
    ) {}

    readonly form = new FormGroup({
        units: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ])
    });

    readonly units$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Length)).pipe(
        map(group => Object.values(group.units))
    );

    setData(data: RateOfAdvanceWidgetSettings): void {
        this._settings = data;

        if (data.distanceUnitId) {
            this.form.controls.units.setValue(data.distanceUnitId as WellKnownUnitIds);
        }
    }

    setClose(fn: (result: RateOfAdvanceWidgetSettings | null) => void): void {
        this._closeFn = fn;
    }

    onCancel(): void {
        this._closeFn?.(null);
    }

    onSubmit(): void {
        // Compose settings

        const result: RateOfAdvanceWidgetSettings = {
            aggregationTimeSpan: this._settings?.aggregationTimeSpan ?? rateOfAdvanceDefaultSettings.aggregationTimeSpan
        };

        if (this.form.controls.units.value) {
            result.distanceUnitId = this.form.controls.units.value;
        }

        this._closeFn?.(result);
    }

}
