import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../internals/cl-gql';

type TagDataQueryDto = {
    well: {
        timeHistory: {
            tagName: string;
            intervals: {
                x: string,
                y: number | null
            }[]
        }[]
    }
}

type TagDataQueryVariablesDto = {
    wellId: string;
    since: string;
    until: string;
    tags: string[];
    step: number;
}

@Injectable({
    providedIn: 'root'
})
export class TagTimeDataQuery extends GraphQLQuery<TagDataQueryDto, TagDataQueryVariablesDto>{
    override readonly document = `query tagTimeData($wellId:ID!, $since: DateTime!, $until: DateTime!, $tags:[TagName!]!, $step:Int!) {
    well(id: $wellId) {
        ...on Well {
            timeHistory(step: $step, since: $since, until: $until, only: $tags) {
                tagName
                intervals {
                    x:startTime
                    y:startValue
                }
            }
        }
    }
}`;
}