import { Injectable, Type } from '@angular/core';

import { ConfirmationDialogService, PopupService, PromptDialogService, RigActivity } from '@cyberloop/core';
import { FileMeta, FileUploadedDialogData, FileUploadedDialogResult } from '@cyberloop/web/files/model';
import { FileUploadedComponent } from '@cyberloop/web/files/ui';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';

import { FilesTagSelectors } from '../state/tag/tag.selectors';

@Injectable({ providedIn: 'root' })
export class FilesService {
    constructor(
        private readonly store: Store,
        private readonly popupService: PopupService,
        private readonly confirmationDialogService: ConfirmationDialogService,
        private readonly promptDialogService: PromptDialogService
    ) { }

    //#region File

    async open(options?: OpenFilePickerOptions) {
        try {
            const [fileHandle] = await window.showOpenFilePicker(options);
            const file = await fileHandle.getFile();
            return file;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }

    async save(data: FileSystemWriteChunkType, options?: SaveFilePickerOptions) {
        const fileHandle = await window.showSaveFilePicker(options);
        const fileStream = await fileHandle.createWritable();
        await fileStream.write(data);
        await fileStream.close();
    }

    download(link: string, filename?: string) {
        const element = document.createElement('a');
        element.href = link;

        if (!isNil(filename)) {
            element.download = filename;
        }

        element.click();
    }

    //#endregion File

    //#region Popup

    openAddTagPopup() {
        return this.promptDialogService.promptString({
            title: 'Add Tag',
            saveText: 'Add',
            placeholder: 'Tag name',
            required: true
        });
    }

    openEditMetaPopup(meta: FileMeta) {
        return this.popupService.showForResult<FileUploadedDialogResult | undefined, FileUploadedDialogData>(FileUploadedComponent, {
            data: {
                meta,
                activityList: RigActivity.list,
                tagList$: this.store.select(FilesTagSelectors.list)
            }
        });
    }

    async confirmDelete(meta: FileMeta) {
        return this.confirmationDialogService.show(`Do you really want to delete "${meta.name}"?`);
    }

    //#endregion Popup
}
