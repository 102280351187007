import { createReducer, on } from '@ngrx/store';

import { WellsActions } from './wells.actions';
import { WellsState, getDefaultWellsState } from './wells.state';

export const wellsReducer = createReducer<WellsState>(
    getDefaultWellsState(),

    
    on(WellsActions.setRigs, (state, { rigs }): WellsState => ({
        ...state,
        rigs
    }))
);
