<div class="element well">
    <span class="element-label">Well</span>
    <span class="element-text">{{ well }}</span>
</div>

<div class="divider"></div>

<div class="element rig">
    <span class="element-label">Rig</span>
    <span class="element-text">{{ rig | noData }}</span>
</div>

<div class="divider"></div>

<div class="element start-date">
    <span class="element-label">Planned start</span>
    <time class="element-text">{{ plannedStartDate | datetime: "HH:mm dd.MM.YYYY":'UTC' | noData }}</time>
</div>

<div class="element duration">
    <span class="element-label">Duration</span>
    <time class="element-text">
        {{ duration | duration: "D" }} days
    </time>
</div>

<div class="divider"></div>

<div class="element description">
    <span class="element-label">Description</span>
    <span class="element-text">{{ description | noData }}</span>
</div>

<div class="expand-block">
    <button (click)="expand.emit()" class="expand-button cl-icon-button outlined">
        <icon name="arrow__rounded__down"></icon>
    </button>
</div>
