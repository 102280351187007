import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconComponent } from '@cyberloop/core';
import { BlockWithHeadingComponent } from '@cyberloop/web/app/ui';

@Component({
    selector: 'cyberloop-kpi-widget-settings',
    standalone: true,
    imports: [
        NgIf,
        BlockWithHeadingComponent,
        IconComponent
    ],
    templateUrl: './kpi-widget-settings.component.html',
    styleUrls: ['./kpi-widget-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiWidgetSettingsComponent {

    @Input() heading = 'Set up chart';

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly close = new EventEmitter<void>();

}
