import { Directive, EventEmitter, HostBinding, Output } from '@angular/core';

/** @internal */
let currentFullscreenElement: FullscreenDirective | undefined = undefined;

/**
 * When attached to an element, expands this element to full screen (on demand).
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[fullscreen]',
  exportAs: 'fullScreen',
  standalone: true
})
export class FullscreenDirective {

  /** @internal */
  @HostBinding('class.fullscreen') private _isFullscreen = false;

  /**
   * Emits when the element is expanded to full screen or collapsed back to normal size.
   */
  @Output() readonly fullscreenChange = new EventEmitter<boolean>();

  /**
   * Is element expanded to full screen or not
   */
  get isFullScreen() {
    return this._isFullscreen;
  }

  /**
   * Tells the component to enter full screen mode
   * @throws An error when you try to make a component full screen when another component is already expanded.
   */
  enterFullscreen(): void {
    if (currentFullscreenElement && currentFullscreenElement !== this) {
      console.error(currentFullscreenElement);
      throw new Error('Another element is already expanded on full screen');
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    currentFullscreenElement = this;

    this._isFullscreen = true;
    this.fullscreenChange.emit(this._isFullscreen);
  }

  /**
   * Tells the component to collapse back to normal size
   */
  exitFullscreen(): void {
    if (currentFullscreenElement && currentFullscreenElement !== this) {
      console.warn('Another element is expanded on full screen', currentFullscreenElement);
      return;
    }

    currentFullscreenElement = undefined;
    this._isFullscreen = false;
    this.fullscreenChange.emit(this._isFullscreen);
  }

  /**
   * Toggles the component's full screen mode
   */
  toggleFullscreen(): void {
    if (this._isFullscreen) {
      this.exitFullscreen();
    }
    else {
      this.enterFullscreen();
    }
  }

}
