import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';

import { ClButtonToggleComponent, ClChartComponent, UnitsConverterService, UnitsSelectors, WellKnownUnitGroupIds } from '@cyberloop/core';
import { KpiActions, KpiSelectors } from '@cyberloop/web/wells/data';
import { WidgetDataProvider, WidgetSettingsHandler, WidgetSize, WidgetType, rateOfAdvanceDefaultSettings } from '@cyberloop/web/wells/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest, filter, map, switchMap, tap } from 'rxjs';

import * as moment from 'moment';

import { formatTooltipWithDateAndTime } from '../../common/widget-tooltip-formatter';
import { KpiWidgetComponent } from '../widget/kpi-widget.component';
import { SettingsComponent } from './settings/settings.component';

import type { Entity, SimpleChangesOf, UnitDescriptor } from '@cyberloop/core';
import type { KpiWidget, RateOfAdvanceWidgetData, RateOfAdvanceWidgetSettings } from '@cyberloop/web/wells/model';

const TRIPPING_IN_SERIES_ID = 'tripping-in';
const TRIPPING_OUT_SERIES_ID = 'tripping-out';
const DRILLING_SERIES_ID = 'drilling';
const CASING_RUNNING_SERIES_ID = 'casing-running';

const X_LABELS_MAX_COUNT = 10;
const Y_TICKS_COUNT = 5; // 5 ticks

const HOUR = 3600 * 1000;
const HOURS_6 = 6 * HOUR;
const HOURS_12 = 12 * HOUR;
const HOURS_24 = 24 * HOUR;

@Component({
    selector: 'cyberloop-rate-of-advance-widget',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        KpiWidgetComponent,
        ClChartComponent,
        ClButtonToggleComponent
    ],
    templateUrl: './rate-of-advance-widget.component.html',
    styleUrls: ['./rate-of-advance-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class RateOfAdvanceWidgetComponent implements KpiWidget, OnChanges {

    private readonly _id$ = new BehaviorSubject<string>('-');
    private readonly _dataLoading$ = new BehaviorSubject<boolean>(true);

    private _settings: RateOfAdvanceWidgetSettings = rateOfAdvanceDefaultSettings;
    private _selectedAggregationTimeSpan = 0;
    private _data?: RateOfAdvanceWidgetData;
    private _timeUnit?: UnitDescriptor;

    constructor(
        private readonly store: Store,
        private readonly settings: WidgetSettingsHandler,
        private readonly data: WidgetDataProvider,
        private readonly unitsConverter: UnitsConverterService
    ) {
        const idPipe = this._id$.pipe(
            untilDestroyed(this),
            filter(Boolean),
            tap(() => this._dataLoading$.next(true))
        );

        this.selectedAggregationTimeSpan$ = combineLatest([idPipe, this._dataLoading$.pipe(filter(x => !x))]).pipe(
            switchMap(([id]) => this.store.select(KpiSelectors.widgetSettings<RateOfAdvanceWidgetSettings>(id))),
            map(widgetSettings => {
                const timeSpan =
                    widgetSettings?.aggregationTimeSpan ?? rateOfAdvanceDefaultSettings.aggregationTimeSpan;

                return this.timeSpans.find(item => item['value'] === timeSpan)?.id;
            })
        );

        combineLatest([
            // Get widget data
            idPipe.pipe(
                switchMap(id => this.data.getData(id, WidgetType.RateOfAdvance))
            ),
            // Get widget settings (for units conversion)
            idPipe.pipe(
                switchMap(id => this.store.select(KpiSelectors.widgetSettings<RateOfAdvanceWidgetSettings>(id))),
                tap(widgetSettings => {
                    this._settings = widgetSettings ?? rateOfAdvanceDefaultSettings;

                    const timeSpan =
                        widgetSettings?.aggregationTimeSpan ?? rateOfAdvanceDefaultSettings.aggregationTimeSpan;

                    this._selectedAggregationTimeSpan = timeSpan;
                }),
                switchMap(widgetSettings => {
                    const unitId =
                        widgetSettings?.distanceUnitId ?? rateOfAdvanceDefaultSettings.distanceUnitId;

                    return this.store.select(UnitsSelectors.getUnit(WellKnownUnitGroupIds.Length, unitId)).pipe(
                        map(unit => ({ widgetSettings, unit }))
                    );
                })
            )
        ]).pipe(
            untilDestroyed(this)
        ).subscribe(([widgetData, { widgetSettings, unit }]) => {
            if (!this.chart) {
                return;
            }

            this._data = widgetData;
            this._timeUnit = unit;

            this.updateData(widgetSettings ?? rateOfAdvanceDefaultSettings);

            this._dataLoading$.next(false);
        });
    }

    readonly options = this.getOptions();
    readonly dataLoading$ = this._dataLoading$.asObservable();

    readonly timeSpans: Entity[] = [
        { id: '24h', name: '24h', value: HOURS_24 },
        { id: '12h', name: '12h', value: HOURS_12 },
        { id: '6h', name: '6h', value: HOURS_6 },
        { id: '1h', name: '1h', value: HOUR }
    ];

    readonly selectedAggregationTimeSpan$: Observable<string | undefined>;

    readonly placeholderColumns = new Array(10).fill(0).map(() => Math.floor(Math.random() * 9) + 1);

    @Input() id = '-';
    @Input() size = WidgetSize.Tiny;

    @ViewChild(ClChartComponent, { static: true }) chart?: ClChartComponent;

    ngOnChanges(changes: SimpleChangesOf<RateOfAdvanceWidgetComponent>): void {
        if (changes.id?.currentValue) {
            this._id$.next(changes.id.currentValue);
        }
    }

    onTimeSpanChange(itemId: string): void {
        const timeSpan = this.timeSpans.find(item => item.id === itemId);

        if (!timeSpan) {
            throw new Error('Unsupported time span ' + itemId);
        }

        this.store.dispatch(KpiActions.updateWidgetSettings({
            widgetId: this._id$.value,
            settings: {
                ...this._settings,
                aggregationTimeSpan: Number(timeSpan['value'])
            }
        }));
    }

    async onSettings(): Promise<void> {
        await this.settings.showSettings<Omit<RateOfAdvanceWidgetSettings, 'aggregationTimeSpan'>>(
            this.id,
            SettingsComponent,
            rateOfAdvanceDefaultSettings
        );
    }

    onDelete(): void {
        this.store.dispatch(KpiActions.deleteWidget({ widgetId: this.id }));
    }

    private getOptions(): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        return {
            chart: {
                type: 'column',
                marginBottom: 80,
                marginLeft: 64,

                zooming: {
                    type: 'xy'
                },

                events: {
                    // load: function () {
                    //     this.title.attr({
                    //         text: self.getTitleHtml()
                    //     });
                    // },
                    render: function () {
                        // A hack to set the plot area radius
                        (this as any).plotBackground.attr({ rx: 6, ry: 6 });
                    }
                }
            },
            xAxis: {
                categories: [],
                // minPadding: 0,
                // maxPadding: 0,
                title: {
                    y: 25,
                    text: ''
                },
                labels: {
                    y: 3,
                    autoRotation: undefined,
                    allowOverlap: true,
                    formatter: function (ctx: Highcharts.AxisLabelsFormatterContextObject) {
                        const labelsStep = self.getLabelsStep(ctx.axis);

                        if (labelsStep > 1 && (ctx.pos + 1) % labelsStep !== 0) {
                            return '';
                        }

                        const m = moment(parseInt(String(ctx.value), 10));

                        if (self._selectedAggregationTimeSpan >= HOURS_24) {
                            return `<span class="date">${m.format('DD')}</span>`;
                        }
                        else {
                            return `<span class="time">${m.format('HH:mm')}</span><br><span class="date">${m.format('DD.MM')}</span>`;
                        }
                    },
                    style: {
                        textOverflow: 'none'
                    }
                }
            },
            yAxis: {
                id: 'main',
                min: 0,
                maxPadding: 0,
                minPadding: 0,
                startOnTick: false,
                endOnTick: false,
                title: {
                    x: -2,
                    useHTML: true,
                    text: ''
                },
                labels: {
                    x: -8,
                    formatter: function () {
                        return Math.floor(this.pos) + '';
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            legend: {
                enabled: true,
                floating: false,
                align: 'center',
                verticalAlign: 'bottom',
                x: 0,
                y: 25,
                itemMarginTop: 5,
                itemMarginBottom: 5,
                alignColumns: false,
                symbolWidth: 8,
                symbolHeight: 8,
                symbolPadding: 10
            },
            tooltip: {
                valueDecimals: 2,
                padding: 0,
                useHTML: true,
                formatter: formatTooltipWithDateAndTime
            },
            series: [
                {
                    id: TRIPPING_IN_SERIES_ID,
                    name: 'RIH',
                    type: 'column',
                    colorIndex: 1,
                    data: []
                },
                {
                    id: TRIPPING_OUT_SERIES_ID,
                    name: 'POOH',
                    type: 'column',
                    colorIndex: 2,
                    data: []
                },
                {
                    id: DRILLING_SERIES_ID,
                    name: 'Drilling',
                    type: 'column',
                    colorIndex: 3,
                    data: []
                },
                {
                    id: CASING_RUNNING_SERIES_ID,
                    name: 'Casing running',
                    type: 'column',
                    colorIndex: 4,
                    data: []
                }
            ]
        };
    }

    private getLabelsStep(axis: Highcharts.Axis): number {
        const extremes = axis.getExtremes();
        return Math.round(extremes.max / X_LABELS_MAX_COUNT);
    }

    private updateData(settings: RateOfAdvanceWidgetSettings): void {
        if (!this._data || !this.chart) {
            return;
        }

        const aggregationTimeSpan =
            settings?.aggregationTimeSpan ?? rateOfAdvanceDefaultSettings.aggregationTimeSpan;

        // Check if it's a multiplier of a hour

        const hours = aggregationTimeSpan / (3600 * 1000);
        const fraction = hours - Math.floor(hours);

        if (fraction !== 0) {
            throw new Error(`Invalid aggregation time span: It should be a multiple of one hour, but got ${aggregationTimeSpan} which is ${hours} hours`);
        }

        // Prepare the data for each series

        // Categories represented as an array of timestamps
        const categories: string[] = [];

        // Data grouped by `aggregationTimeSpan`
        const trippingInData: Record<number, number> = {};
        const trippingOutData: Record<number, number> = {};
        const drillingData: Record<number, number> = {};
        const casingRunningData: Record<number, number> = {};

        const totalData: Record<number, number> = {};

        // Each item represents 1 hour of data
        for (const item of this._data.metrics) {
            const hour = Math.floor(item.startTime.getTime() / aggregationTimeSpan) * aggregationTimeSpan;
            const hourString = hour.toString();

            // Add category

            if (!categories.includes(hourString)) {
                categories.push(hourString);
                totalData[hour] = item.trippingIn + item.trippingOut + item.drilling + item.casingRunning;
            }

            // Set starting values

            if (!trippingInData[hour]) {
                trippingInData[hour] = 0;
            }
            if (!trippingOutData[hour]) {
                trippingOutData[hour] = 0;
            }
            if (!drillingData[hour]) {
                drillingData[hour] = 0;
            }
            if (!casingRunningData[hour]) {
                casingRunningData[hour] = 0;
            }

            // Aggregate values

            trippingInData[hour] += item.trippingIn;
            trippingOutData[hour] += item.trippingOut;
            drillingData[hour] += item.drilling;
            casingRunningData[hour] += item.casingRunning;
        }

        // Set categories

        this.chart.xAxis[0].setCategories(categories, false);

        const isOneDay = this._selectedAggregationTimeSpan < HOURS_24;

        this.chart.xAxis[0].setTitle({
            text: isOneDay ? '' : 'Days'
        }, false);

        // Set Y title

        this.chart.yAxis[0].setTitle({
            text: this.getTitleText()
        }, false);

        // Set Y axis options

        this.chart.redraw();

        // Set data

        this.chart.setSeriesData(TRIPPING_IN_SERIES_ID, Object.values(trippingInData));
        this.chart.setSeriesData(TRIPPING_OUT_SERIES_ID, Object.values(trippingOutData));
        this.chart.setSeriesData(DRILLING_SERIES_ID, Object.values(drillingData));
        this.chart.setSeriesData(CASING_RUNNING_SERIES_ID, Object.values(casingRunningData));
    }

    private getTitleText(): string {
        let res = 'Length';

        if (this._timeUnit) {
            res += ` <span class="faded">${this._timeUnit.name}</span>`;
        }

        return res;
    }

}
