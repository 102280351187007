import { Injectable } from '@angular/core';

import { Template, TemplateRepositryService } from '@cyberloop/core';
import { DrillingWorkspace } from '@cyberloop/web/wells/model';
import { Observable } from 'rxjs';

import { DrillingWorkspacesTemplatesProvideService } from './drilling-workspaces-templates.provider.service';

export interface WorkspacesTemplate extends Template {
    data?: DrillingWorkspace
}

@Injectable()
export class WorkspacesTemplateService extends TemplateRepositryService<WorkspacesTemplate> {

    constructor(private readonly provider: DrillingWorkspacesTemplatesProvideService) {
        super();
    }

    override watchAll(): Observable<WorkspacesTemplate[]> {
        return this.provider.watchAll();
    }
    override get(id: string): Observable<WorkspacesTemplate | null> {
        return this.provider.get(id);
    }
    override create(template: Omit<WorkspacesTemplate, 'id' | 'author' | 'createdAt' | 'updatedAt'>): Observable<WorkspacesTemplate> {
        return this.provider.create(template);
    }
    override delete(templateId: string): Observable<WorkspacesTemplate> {
        return this.provider.delete(templateId);
    }

}