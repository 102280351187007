import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { IconComponent } from '../../../components';

/** @internal */
@Component({
    selector: 'cyberloop-restore-password-requested',
    standalone: true,
    imports: [
        IconComponent
    ],
    templateUrl: './restore-password-requested.component.html',
    styleUrls: ['./restore-password-requested.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestorePasswordRequestedUIComponent {
    @Output() readonly backToLogin = new EventEmitter();
}
