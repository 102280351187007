import { DecimalPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NoDataPipe } from '@cyberloop/core';

@Component({
    selector: 'cyberloop-well-card-text',
    standalone: true,
    imports: [
        NgIf,
        NoDataPipe,
        DecimalPipe
    ],
    templateUrl: './well-card-text.component.html',
    styleUrls: ['./well-card-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WellCardTextComponent {
    @Input() title = '';
    @Input() value: number | null | undefined;
    @Input() value2: number | null | undefined;
    @Input() label: string | undefined;

    @Input() format = '1.0-2';
    @Input() clip = false;
}
