import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/** Set contextual variable */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ngVar]',
    standalone: true
})
export class NgVarDirective {
    /** @private */
    private _context: {
        $implicit: unknown;
        ngVar: unknown;
    } = {
            $implicit: null,
            ngVar: null
        };

    /** @private */
    private _hasView = false;

    /** @internal */
    constructor(
        private readonly templateRef: TemplateRef<unknown>,
        private readonly vcRef: ViewContainerRef
    ) { }

    /** Context to set */
    @Input('ngVar') set var(context: unknown) {
        this._context.$implicit = this._context.ngVar = context;

        if (!this._hasView) {
            this.vcRef.createEmbeddedView(this.templateRef, this._context);
            this._hasView = true;
        }
    }
}
