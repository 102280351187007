import type { NavigationItem, RecentItem, WellDataObject, WellMeta } from '@cyberloop/web/app/model';
import { WellsFilters, defaultWellsFilters } from '@cyberloop/web/wells/model';

export const APP_FEATURE = 'APP';
export const ROUTER_FEATURE = 'router';

export type LoadingState = 'NOT_LOADED' | 'LOADED' | 'LOADING';

/**
 * State for app
 * */
export interface AppState {
    appsLoadingState: LoadingState;
    appsLoadError?: Error;
    apps: NavigationItem[];

    recentItemsLoadingState: LoadingState;
    recentItemsLoadError?: Error;
    recentItems: RecentItem<any>[];

    wellMetaLoadingState: LoadingState;
    wellMeta: WellMeta[];
    wellMetaLoadError?: Error;

    wellsFilters: WellsFilters;
    wellsData: WellDataObject[];
    wellsDataIsLoaded: boolean;
    favorites: string[];
    favoritesAreLoaded: boolean;
}

export function getDefaultAppState(): AppState {
    return {
        appsLoadingState: 'NOT_LOADED',
        apps: [],

        recentItemsLoadingState: 'NOT_LOADED',
        recentItems: [],

        wellMetaLoadingState: 'NOT_LOADED',
        wellMeta: [],

        wellsFilters: defaultWellsFilters,
        wellsData: [],
        wellsDataIsLoaded: false,
        favorites: [],
        favoritesAreLoaded: false
    };
}
