import { Injectable } from '@angular/core';

import { CoreSelectors } from '@cyberloop/core';
import { TndChartDataProvider, TndDataProvider, TndModelProvider } from '@cyberloop/web/tnd/data';
import { TndChart } from '@cyberloop/web/tnd/model';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, map, of, switchMap } from 'rxjs';

import type { TndChartDataWithModel, TndTorqueChartData, TndTorqueChartDataWithModel, TndWeightsChartData, TndWeightsChartDataWithModel } from '@cyberloop/web/tnd/model';

/**
 * Provides a data for T&D chart by it's type
 */
@Injectable({ providedIn: 'root' })
export class TndChartDataProviderService extends TndChartDataProvider {

    constructor(
        private readonly store: Store,
        private readonly dataProvider: TndDataProvider,
        private readonly modelProvider: TndModelProvider
    ) {
        super();
    }

    /**
     * Provides for T&D Weights chart
     */
    watchChartData(realmId: string, type: TndChart.Weights, sectionId?: number, modelId?: string): Observable<TndWeightsChartDataWithModel>;
    /**
     * Provides for T&D Torque chart
     */
    watchChartData(realmId: string, type: TndChart.Torque, sectionId?: number, modelId?: string): Observable<TndTorqueChartDataWithModel>;
    /**
     * Provides for T&D charts depending on type
     */
    watchChartData(realmId: string, type: TndChart, sectionId?: number, modelId?: string): Observable<TndChartDataWithModel>;
    watchChartData(realmId: string, type: TndChart, sectionId?: number, modelId?: string): Observable<TndChartDataWithModel> {
        return combineLatest([
            this.store.select(CoreSelectors.getWellById(realmId)),
            sectionId
                ? this.store.select(CoreSelectors.sectionsOfWell(realmId)).pipe(
                    map(sections => sections?.find(item => item.id === sectionId))
                )
                : this.store.select(CoreSelectors.currentSection)
        ]).pipe(

            // Fetch the data
            switchMap(([well, section]) => {
                if (!well || !section) {
                    return EMPTY;
                }

                return this.dataProvider.watch(well.id, section.id);
            }),

            // Map it
            map(items => {
                items.sort((a, b) => a.depth - b.depth);

                if (type === TndChart.Torque) {
                    // Get Torque chart data

                    const result: TndTorqueChartData = {
                        onBottomTorque: [],
                        offBottomTorque: []
                    };

                    for (const item of items) {
                        result.onBottomTorque.push({ x: item.onBottomTorque, y: item.depth });
                        result.offBottomTorque.push({ x: item.offBottomTorque, y: item.depth });
                    }

                    return result;
                }
                else {
                    // Get Weights chart data

                    const result: TndWeightsChartData = {
                        pickUp: [],
                        slackOff: [],
                        rotary: []
                    };

                    for (const item of items) {
                        result.pickUp.push({ x: item.pickUp, y: item.depth });
                        result.slackOff.push({ x: item.slackOff, y: item.depth });
                        result.rotary.push({ x: item.rotary, y: item.depth });
                    }

                    return result;
                }
            }),

            // And extend it with model
            // switchMap(data => this.modelProvider.modify(type, data))
            switchMap(data => {
                if (modelId) {
                    return this.modelProvider.getData(modelId).pipe(
                        map(model => ({
                            ...data,
                            model
                        }))
                    );
                }
                else {
                    return of(data);
                }
            })
        );
    }

}
