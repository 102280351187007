import { Injectable } from '@angular/core';

import { FirebaseOptions } from '@firebase/app';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, shareReplay } from 'rxjs';

import { ClApplication } from './cl-application';

@Injectable({
    providedIn: 'root'
})
export class ClApplicationManager {
    private readonly _app$ = new BehaviorSubject<ClApplication | undefined>(undefined);

    readonly tenantApp$: Observable<ClApplication | undefined> = this._app$.pipe(
        distinctUntilChanged(),
        debounceTime(50),
        shareReplay(1)
    );


    async initializeApp(options: FirebaseOptions): Promise<ClApplication> {
        await this.destroyApp();

        const app = ClApplication.initializeApp(options);
        this._app$.next(app);
        return app;
    }

    async destroyApp() {
        const app = this._app$.value;
        if (app) {
            this._app$.next(undefined);
            await app.dispose();
        }
    }

    getApp(): ClApplication {
        return this._app$.value as any;
    }
}