import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AUTH_FEATURE, AuthState } from './auth.state';

/** @internal */
const feature = createFeatureSelector<AuthState>(AUTH_FEATURE);

/** @internal */
const loginState = createSelector(feature, state => state.loginState);
/** @internal */
const isLoggedIn = createSelector(loginState, state => state === 'LOGGED_IN');
/** @internal */
const isLoggingIn = createSelector(loginState, state => state === 'LOGGING_IN');
/** @internal */
const isLoggedOut = createSelector(loginState, state => state === 'NOT_LOGGED_IN');
/** @internal */
const isInitialized = createSelector(feature, state => state.isInitialized);
/** @internal */
const currentUser = createSelector(feature, state => state.user ?? null);
/** @internal */
const loginErrors = createSelector(feature, state => state.loginError ?? null);

/** @internal */
const restorePasswordError = createSelector(feature, state => state.restorePasswordError ?? null);
/** @internal */
const restorePasswordLoading = createSelector(feature, state => state.restorePasswordState === 'PROCESS');

/** @internal */
const newPasswordError = createSelector(feature, state => state.setNewPasswordError ?? null);
/** @internal */
const newPasswordLoading = createSelector(feature, state => state.setNewPasswordState === 'PROCESS');

/** @internal */
const listOfTenants = createSelector(feature, state => state.listOfTenants);
/** @internal */
const listOfTenantsLoading = createSelector(feature, state => state.listOfTenantsState === 'LOADING');
/** @internal */
const listOfTenantsBackgroundLoading = createSelector(feature, state => state.listOfTenantsBackgroundLoading);
/**
 * Selectors for auth
 * */
export const AuthSelectors = {
    /** Check if auth module is initialized */
    isInitialized,
    /** Get login state */
    loginState,
    /** is user logged in or not */
    isLoggedIn,
    /** is logging in in process */
    isLoggingIn,
    /** is user logged out */
    isLoggedOut,
    /** current user */
    currentUser,
    /** error got during user login */
    loginErrors,
    /** error got during user restore password */
    restorePasswordError,
    /** is restoring password in process */
    restorePasswordLoading,
    /** error got during user set new password */
    newPasswordError,
    /** is setting new password in process */
    newPasswordLoading,
    /** list of tenants available for user */
    listOfTenants,
    /** list of tenants is loading */
    listOfTenantsLoading,
    /** list of tenants is background loading */
    listOfTenantsBackgroundLoading
};
