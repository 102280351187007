export const seriesTarget = 'Target';
export const seriesCurrent = 'Curent';

export const gaugeHcOptions: Highcharts.Options = {
    chart: {
        styledMode: true,
        type: 'gauge',
        renderTo: 'gauge-container',
        height: 490,
        width: 490,
        plotBackgroundColor: undefined,
        plotBackgroundImage: undefined,
        plotBorderWidth: 0,
        plotShadow: false,
        margin: [20,20,20,20],
        spacing: [0, 0, 0, 0]
    },
    responsive: {  
        rules: [{  
            condition: {  
                maxWidth: Infinity  
            },  
            chartOptions: {  
                yAxis: {  
                    minorTicks: true
                }  
            }  
        }]  
    },
    legend: {
        enabled: true,
        align: 'left',
        verticalAlign: 'top',
        layout: 'vertical',
        floating: true,
        symbolRadius: 0,
        x: -9,
        y: -9,
        itemStyle: {
            color: 'var(--cl-text-enable-prim)',
            fontSize: 'px2rem(16)',
            fontFamily: 'Roboto',
            fontWeight: '400'
        }
    },
    pane: {
        startAngle: 0,
        endAngle: 360,
        size: '85%',
        background: [
            {
                borderColor: 'transparent',
                backgroundColor: 'var(--cl-bg-panel-fourth)',
                outerRadius: '117%',
                innerRadius: '105%'
            },
            {
                borderColor: 'transparent',
                backgroundColor: 'var(--cl-bg-panel-fourth)',
                outerRadius: '35%',
                innerRadius: '0%'
            },
            {
                borderColor: 'var(--cl-divider)',
                backgroundColor: 'transparent',
                outerRadius: '33%',
                innerRadius: '0%'
            },
            {
                borderColor: 'var(--cl-bg-panel-sec)',
                backgroundColor: undefined,
                outerRadius: '28%',
                innerRadius: '28%'
            }
        ]
    },
    yAxis: [{
        min: 0,
        max: 360,
        showFirstLabel: false,
        minorTicks: true,
        minorTickInterval: 1,
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: 'var(--cl-text-disable-prim)',
        tickPixelInterval: 40,
        tickWidth: 1,
        tickPosition: 'inside',
        tickLength: 20,
        tickColor: 'var(--cl-text-disable-prim)',
        lineColor: 'transparent',
        labels: {
            distance: -35,
            step: 2,
            style: {
                fontFamily: 'Roboto',
                fontSize: 'px2rem(16)',
                fontWeight: '500',
                color: 'var(--cl-text-enable-sec)'
            }
        }
    }, {
        min: 0,
        max: 360,
        showFirstLabel: false,
        minorTickLength: 0,
        tickInterval: 5,
        tickWidth: 0,
        tickPosition: 'inside',
        tickLength: 2,
        tickColor: 'red',
        lineColor: 'transparent',
        offset: -15, // approx. 65% of gauge radius
        labels: {
            enabled: false
        }
    }],
    plotOptions: {
        gauge: {
            dataLabels: {
                enabled: false
            },
            pivot: {
                backgroundColor: 'transparent'
            },
            showInLegend: true
        },
        series: {
            animation: false
        }
    },
    series: [
        {
            name: seriesTarget,
            color: 'var(--cl-accent-1)',
            marker: {
                symbol: 'circle'
            },
            dial: {
                radius: '102%',
                backgroundColor: 'var(--cl-accent-1)',
                baseWidth: 24,
                topWidth: 2,
                baseLength: '115%',
                rearLength: '-115%'
            }
        },
        {
            name: seriesCurrent,
            color: 'var(--cl-accent-5)',
            marker: {
                symbol: 'circle'
            },
            dial: {
                radius: '98%',
                backgroundColor: 'var(--cl-accent-5)',
                baseWidth: 18,
                topWidth: 1,
                baseLength: '86%',
                rearLength: '-86%'
            }
        },
        {
            dial: {
                radius: '85%',
                backgroundColor: 'var(--cl-accent-3)',
                baseWidth: 10,
                topWidth: 1,
                baseLength: '84%',
                rearLength: '-84%'
            },
            showInLegend: false
        },
        {
            dial: {
                radius: '72%',
                backgroundColor: 'var(--cl-accent-3)',
                baseWidth: 10,
                topWidth: 1,
                baseLength: '80%',
                rearLength: '-80%'
            },
            showInLegend: false
        },
        {
            dial: {
                radius: '59%',
                backgroundColor: 'var(--cl-accent-3)',
                baseWidth: 10,
                topWidth: 1,
                baseLength: '74%',
                rearLength: '-74%'
            },
            showInLegend: false
        },
        {
            dial: {
                radius: '44%',
                backgroundColor: 'var(--cl-accent-3)',
                baseWidth: 10,
                topWidth: 1,
                baseLength: '65%',
                rearLength: '-65%'
            },
            showInLegend: false
        }
    ] as any
};