
import { CoreSelectors, LiveDataProviderService, Points, WellKnownParams } from '@cyberloop/core';
import { getDepthRangeObservable } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { isEqual, isNil } from 'lodash';
import { EMPTY, Observable, combineLatest, distinctUntilChanged, first, map, switchMap } from 'rxjs';

import { KpiSelectors } from '../../state/kpi/kpi.selectors';
import { KpiDataService } from '../kpi-data.service';

import type { RopWidgetData, RopWidgetSettings } from '@cyberloop/web/wells/model';
const VISIBLE_POINTS = 15;

export function getRopWidgetData(this: { store: Store, data: KpiDataService, liveData: LiveDataProviderService }, id: string): Observable<RopWidgetData> {
    return combineLatest([
        this.store.select(CoreSelectors.currentWell),
        this.store.select(KpiSelectors.widgetSettings<RopWidgetSettings>(id))
    ]).pipe(
        // Custom distinct function that prevents triggering query when things
        // needed for it have no changes
        distinctUntilChanged((prev, curr) =>
            prev[0]?.id === curr[0]?.id &&
            isEqual(prev[1], curr[1])
        ),
        switchMap(([well, settings]) => {
            if (!well) {
                return EMPTY;
            }

            const depthRangeObservable = getDepthRangeObservable(well, settings?.minDepth, settings?.maxDepth);

            return combineLatest([
                depthRangeObservable,
                this.store.select(CoreSelectors.currentSection),
                this.liveData.getForRigAndTag(well.rig, WellKnownParams.WDE).pipe(first())
            ]).pipe(
                switchMap(([depthRange, section, wde]) => {
                    if (!section) {
                        return EMPTY;
                    }

                    if (isNil(depthRange.to)) {
                        depthRange.to = wde.value;
                    }

                    const step = Math.round((depthRange.to - depthRange.from) / VISIBLE_POINTS);

                    return this.data.watchTagDepthHistory(
                        well, section, [WellKnownParams.ROP], step, depthRange.from, depthRange.to
                    );
                }),
                map(widgetData => {
                    const rop: Points = [];

                    for (const item of widgetData) {
                        if (item.tagName === WellKnownParams.ROP) {
                            for (const interval of item.intervals) {
                                rop.push({
                                    x: interval.startDepth,
                                    y: interval.avgValue
                                });
                            }

                            break;
                        }
                    }

                    return {
                        rop
                    };
                })
            );
        })
    );
}
