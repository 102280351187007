import { FileTag } from '@cyberloop/web/files/model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { FILES_TAG_FEATURE } from './tag.state';

/**
 * Actions for files tag used internally
 * @internal
 * */
export const FilesTagActions = createActionGroup({
    source: FILES_TAG_FEATURE,
    events: {
        'Load list': emptyProps(),
        'Load list success': props<{ list: FileTag[] }>(),
        'Load list failure': props<{ error: Error }>(),
        'Unwatch list': emptyProps(),

        'Open Create popup': emptyProps(),
        'Create': props<{ name: string }>(),
        'Create success': props<{ tag: FileTag }>(),
        'Create failure': props<{ error: Error }>()
    }
});

/**
 * Actions for files tag
 * */
export const PublicFilesTagActions = {
    loadList: FilesTagActions.loadList,
    unwatchList: FilesTagActions.unwatchList,
    openCreatePopup: FilesTagActions.openCreatePopup
};
