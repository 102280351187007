
import { CoreSelectors, RigState, Well } from '@cyberloop/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, map, of, switchMap, timer } from 'rxjs';

import * as moment from 'moment';

import { KpiDataService } from '../kpi-data.service';

import type { AssetDailyMetricsWidgetData, AssetDailyMetricsWidgetDataItem, KpiDrillingMetricsItem, KpiRigConnectionSumsItem, KpiRigStateDuration, KpiRopSumItem } from '@cyberloop/web/wells/model';

type TimeRange = {
    since: moment.Moment;
    until: moment.Moment;
};

type Data = {
    timeRangeSeconds: number;
    drillingMetrics: KpiDrillingMetricsItem;
    rigStateDurations: KpiRigStateDuration[];
    ropSums: KpiRopSumItem;
    rigConnectionSums: KpiRigConnectionSumsItem;
};

function getDataObservablesFromTimeRange(this: { store: Store, data: KpiDataService }, well: Well, timeRange: TimeRange): Observable<Data> {
    const since = timeRange.since.clone().startOf('second').toDate();
    const until = timeRange.until.clone().startOf('second').toDate();

    return combineLatest([
        of(timeRange.until.diff(timeRange.since, 'seconds')),
        this.data.watchDrillingMetrics(
            well.rig,
            since,
            until
        ),
        this.data.watchRigStateDurations(
            well.rig,
            since,
            until,
            [RigState.DrillingRotaryDrilling, RigState.DrillingSlideDrilling]
        ),
        this.data.watchRopSums(
            well.rig,
            since,
            until
        ),
        this.data.watchRigConnectionSums(
            well.rig,
            since,
            until
        )
    ]).pipe(
        map(([timeRangeSeconds, drillingMetrics, rigStateDurations, ropSums, rigConnectionSums]) => ({
            timeRangeSeconds, drillingMetrics, rigStateDurations, ropSums, rigConnectionSums
        }))
    );
}

function getEdgeDates(well: Well, hours: number) {
    const until = moment(well.releaseTime ?? well.suspendTime ?? new Date());
    let since = until.clone().subtract(hours, 'hours');

    if (since.isBefore(well.startTime)) {
        // Prevent from getting data from previous well
        since = moment(well.startTime);
    }

    return { since, until };
}

/**
 * Computes the data item for Asset Daily Metrics widget. This data item can
 * relate to some of the time intervals: 12h, 24, section.
 */
function getWidgetDataForTimeRange(
    timeRangeSeconds: number,
    drillingMetrics: KpiDrillingMetricsItem,
    rigStateDurations: KpiRigStateDuration[],
    ropSums: KpiRopSumItem,
    rigConnectionSums: KpiRigConnectionSumsItem
): AssetDailyMetricsWidgetDataItem {
    const metersDrilledTotal = drillingMetrics.rotaryDrilled + drillingMetrics.slideDrilled;

    /** In seconds */
    let slideDrillingDuration = 0;
    /** In seconds */
    let onBottomDuration = 0;

    for (const item of rigStateDurations) {
        if (item.state === RigState.DrillingRotaryDrilling) {
            onBottomDuration += item.duration;
        }
        else if (item.state === RigState.DrillingSlideDrilling) {
            slideDrillingDuration += item.duration;
            onBottomDuration += item.duration;
        }
    }

    return {
        metersDrilledRotary: drillingMetrics.rotaryDrilled,
        metersDrilledSlide: drillingMetrics.slideDrilled,
        metersDrilledTotal,

        slideMetersPercent: metersDrilledTotal > 0
            ? drillingMetrics.slideDrilled / metersDrilledTotal * 100
            : 0,
        slideTimePercent: slideDrillingDuration / timeRangeSeconds * 100,

        onBottomTimePercent: onBottomDuration / timeRangeSeconds * 100,

        ropRotaryAvg: ropSums.avgValueRotaryDrilling,
        ropSlideAvg: ropSums.avgValueSlideDrilling,

        s2sTime: rigConnectionSums.sumS2S,
        w2wTime: rigConnectionSums.sumS2S + rigConnectionSums.sumS2W + rigConnectionSums.sumW2S
    };
}

export function getAssetDailyMetricsWidgetData(this: { store: Store, data: KpiDataService }): Observable<AssetDailyMetricsWidgetData> {
    return this.store.select(CoreSelectors.currentWell).pipe(
        switchMap(well => {
            if (!well) {
                return EMPTY;
            }

            // When was the well ending time
            const borderTime = moment(well.releaseTime ?? well.suspendTime ?? new Date());
            const is12hValid = moment().subtract(12, 'hours').isBefore(borderTime);
            const is24hValid = moment().subtract(24, 'hours').isBefore(borderTime);

            // Time range observables for each of the desired time intervals

            let data12HObservable: Observable<Data | false>;
            let data24HObservable: Observable<Data | false>;

            if (is12hValid) {
                const timeRange12hObservable = timer(0, 30000).pipe(
                    map(() => getEdgeDates(well, 12))
                );

                data12HObservable = timeRange12hObservable.pipe(
                    switchMap(timeRange => getDataObservablesFromTimeRange.call(this, well, timeRange))
                );
            }
            else {
                data12HObservable = of(false);
            }

            if (is24hValid) {
                const timeRange24hObservable = timer(0, 30000).pipe(
                    map(() => getEdgeDates(well, 24))
                );

                data24HObservable = timeRange24hObservable.pipe(
                    switchMap(timeRange => getDataObservablesFromTimeRange.call(this, well, timeRange))
                );
            }
            else {
                data24HObservable = of(false);
            }

            return combineLatest([
                data12HObservable,
                data24HObservable
            ]).pipe(
                map(([data12h, data24h]) => {
                    if (!data12h && !data24h) {
                        return 'The well is closed more than 24 hours ago';
                    }

                    return {
                        '12h': data12h
                            ? getWidgetDataForTimeRange(data12h.timeRangeSeconds, data12h.drillingMetrics, data12h.rigStateDurations, data12h.ropSums, data12h.rigConnectionSums)
                            : undefined,
                        '24h': data24h
                            ? getWidgetDataForTimeRange(data24h.timeRangeSeconds, data24h.drillingMetrics, data24h.rigStateDurations, data24h.ropSums, data24h.rigConnectionSums)
                            : undefined
                    } as AssetDailyMetricsWidgetData;
                })
            );
        })
    );
}
