import type { Observable } from 'rxjs';
import { DepthExportQueryInput, TagsValue, TimeExportQueryInput } from '../../models';

/** Export data abstract service  */
export abstract class ExportDataService {

    /** get time export data by value */
    abstract getExportDataByTime(input: TimeExportQueryInput): Observable<TagsValue[]>;

    /** get depth export data by value */
    abstract getExportDataByDepth(input: DepthExportQueryInput): Observable<TagsValue[]>;
}

