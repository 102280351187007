import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';

import { isNil } from 'lodash';

import { RouteAccess } from '../../models/access';
import { AccessService } from '../../services';
import { NotificationService } from '../../services/notification.service';

/**
 * Use this guard to check if user has permission access
 */
@Injectable({
    providedIn: 'root'
})
export class PermissionAccessGuard implements CanActivateChild {
    /** @internal */
    constructor(
        private readonly accessService: AccessService,
        private readonly notificationService: NotificationService
    ) { }

    /** @internal */
    async canActivateChild(route: ActivatedRouteSnapshot) {
        const { permissions } = route.data as RouteAccess;

        if (!isNil(permissions) && permissions.length) {
            const accessDeny = await this.accessService.isDeniedAccess(permissions);

            if (accessDeny) {
                this.notificationService.error('You do not have access to this page');
                return false;
            }
        }

        return true;
    }
}
