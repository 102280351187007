import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiRigConnection } from '@cyberloop/web/wells/model';
type KpiRigConnectionsInput = {
    rig: string,
    since: string,
    until: string
}

type KpiRigConnections = {
    rig: {
        rigKPIConnections: KpiRigConnection[]
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiRigConnectionsQuery extends GraphQLQuery<KpiRigConnections, KpiRigConnectionsInput> {
    override readonly document = `
        query GetRigKpiConnectionsHours($rig: String!, $since: DateTime!, $until: DateTime!) {
            rig(name: $rig) {
                ... on Rig {
                    rigKPIConnections(since: $since, until: $until) {
                        startTime
                        s2s
                        s2w
                        w2s
                    }
                }
            }
        }
    `;
}