import { Observable } from 'rxjs';

import { Asset, Range } from '../../models';
import { Points } from '../../models/point.model';

/**
 * This service provides data for tags
 */
export abstract class TagDataProviderService {
    /**
     * Get data for multiple tags for specific view port
     * @param wellId
     * @param tagsToFetchAtOnce 
     * @param vp view port
     */
    abstract get(wellId:string, tagsToFetchAtOnce: Asset['name'][], vp: Range, step?: number): Observable<Record<Asset['name'], Points>>;

    /**
     * Get data for multiple tags for specific view port
     * @param wellId
     * @param sectionId
     * @param tagsToFetchAtOnce 
     * @param vp view port
     */
    abstract getDepth(wellId:string, sectionId: string, tagsToFetchAtOnce: Asset['name'][], vp: Range, step?: number): Observable<Record<Asset['name'], Points>>;
}