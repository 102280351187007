import { Injectable } from '@angular/core';

import { AuthService, ClError, TenantInfo, User } from '@cyberloop/core';
import { cloneDeep } from 'lodash';
import { EMPTY, Observable, Subject, share, switchMap } from 'rxjs';

import { Unsubscribe, getAuth } from 'firebase/auth';

import { ConfirmPasswordResetRequest, PasswordResetResponse, SendPasswordResetEmailRequest } from '../../models/reset-password.js';
import { SigninRequest, SigninResponse } from '../../models/signin-response';
import { GetTenantListRequest, GetTenantListResponse } from '../../models/tenant-list.js';
import { AuthTokenProviderService } from './auth-token.provider.service';
import { ClApplicationManager } from './client-app/cl-app-manager';
import { ClApplication } from './client-app/cl-application';
import { ClAuth } from './client-app/cl-auth';
import { FirebaseDataStorageService } from './firebase-data-provider.service';
import { SsoRootFunctions } from './sso-root/sso-root-functions';

import type { FirebaseOptions } from 'firebase/app';
@Injectable({
    providedIn: 'root'
})
export class SsoAuthService extends AuthService {
    private _unsubscribeTokenRefresh: Unsubscribe | undefined;
    private readonly _tokenChanged$ = new Subject<string | null>();
    /**
     *
     */
    constructor(
        private readonly storage: FirebaseDataStorageService,
        private readonly func: SsoRootFunctions,
        private readonly appMgr: ClApplicationManager,
        private readonly authToken: AuthTokenProviderService
    ) {
        super();
    }

    override readonly currentUser$: Observable<User | null> = this.appMgr.tenantApp$.pipe(
        switchMap(app => app?.getAuth().currentUser$ ?? EMPTY)
    );

    override readonly tokenChanged$: Observable<string | null> = this._tokenChanged$.pipe(
        share()
    );


    override async login(login: string, password: string, tenant?: string): Promise<User | TenantInfo[] | null> {
        const result = await this.func.call<SigninRequest, SigninResponse>('getSignInInfo', {
            login,
            password,
            tid: tenant
        });

        const tokenResponse = result;

        if (!tokenResponse.success) {
            throw new Error(tokenResponse.error);
        }

        const data = tokenResponse.payload;
        if (!data) {
            throw new Error('Response is invalid');
        }

        if (Array.isArray(data)) {
            return data.map(x => ({ id: x.id, name: x.name }));
        }

        const tenantApp = await this.initTenantApp(data.dbConfig);

        const auth = tenantApp.getAuth();

        const user = await auth.getUserByToken(data.token);
        if (user) {
            console.warn('login', { user });
            this.storage.setFirebaseData(data.token, tenantApp.options);

            this.initTokenRefresh(auth);

            return this.getUserObject(user);
        }

        return null;
    }

    override async logout() {
        try {
            const auth = getAuth();
            await auth.signOut();
        }
        catch { /* do nothing */ }

        this.storage.clear();

        this.removeClientApp();

        window.location.reload();
    }


    async tryAutologin(): Promise<User | null> {

        const dbConfig = this.storage.getFirebaseConfig();

        if (dbConfig) {
            const app = await this.appMgr.initializeApp(dbConfig);

            const token = this.storage.getToken();
            if (token) {
                const user = await this.restoreUser(token, app);
                if (user) {
                    return user;
                }
            }

            this.storage.clear();
        }

        return null;
    }

    async getTenantList(login: string): Promise<TenantInfo[]> {
        const response = await this.func.call<GetTenantListRequest, GetTenantListResponse>('getTenantList', {
            login
        });

        if (response.success) {
            return response.payload;
        }

        throw new ClError(response.error);
    }

    async sendPasswordResetEmailByLoginAndTenantId(login: string, tenantId: string, link?: string): Promise<void> {
        const response = await this.func.call<SendPasswordResetEmailRequest, PasswordResetResponse>('sendPasswordResetEmailByLoginAndTenantId', {
            login,
            tenantId,
            link
        });

        if (response.success) {
            return;
        }

        throw new ClError(response.error);
    }

    async confirmPasswordReset(code: string, newPassword: string): Promise<void> {
        const response = await this.func.call<ConfirmPasswordResetRequest, PasswordResetResponse>('confirmPasswordReset', {
            code,
            newPassword
        });

        if (response.success) {
            return;
        }

        throw new ClError(response.error);
    }

    private async initTenantApp(dbConfig: FirebaseOptions) {
        await this.removeClientApp();
        return this.appMgr.initializeApp(dbConfig);
    }

    private async removeClientApp() {
        try {
            this._unsubscribeTokenRefresh?.();
            this._unsubscribeTokenRefresh = undefined;

            await this.appMgr.destroyApp();
        }
        catch {
            // no app yet
        }
    }

    private async restoreUser(token: string, app: ClApplication) {
        if (!token) {
            return false;
        }

        const auth = app.getAuth();
        let user: User = auth.currentUser as any;
        if (!user) {
            let res: (user: User) => void;
            const p = new Promise<User>(resolve => res = resolve);
            // eslint-disable-next-line prefer-const
            let unsubscr: (() => void) | undefined;

            const done = (u: User) => {
                res(u);
                unsubscr?.();
            };

            unsubscr = auth.onAuthStateChanged(done as any);
            setTimeout(() => done(null as any), 2000);

            user = await p;
        }

        if (!user) {
            try {
                user = await auth.getUserByToken(token) as any;
                if (user) {
                    this.storage.setFirebaseData(token, auth.app.options);
                }
            }
            catch (e) {
                console.warn(e);
            }
        }

        this.initTokenRefresh(auth);

        return this.getUserObject(user) ?? false;
    }

    private initTokenRefresh(auth: ClAuth) {
        // Listen for changes in the user's authentication state
        this._unsubscribeTokenRefresh?.();
        this._unsubscribeTokenRefresh = undefined;

        this._unsubscribeTokenRefresh = auth.onIdTokenChanged(async (user) => {
            if (user) {
                // Refresh the user's authentication token
                try {
                    const idToken = await user.getIdToken(/* forceRefresh */ true);
                    this.authToken.authToken = idToken;
                    // Send the refreshed ID token to your server to update the user's session
                    return;
                }
                catch (error) {
                    // Handle error
                    console.warn('Token refresh failed. Error: ', error);
                }
            }

            this.authToken.authToken = null;
            this.logout();
        });
    }

    private getUserObject(user: User) {
        if (!user) {
            return null;
        }

        return {
            uid: user.uid,
            displayName: user.displayName ?? user.email,
            email: user.email,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
            metadata: cloneDeep(user.metadata)
        };
    }
}

