import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, ClSelectorComponent, CoreSelectors, Entity, IconComponent, PopupService, RangePickerComponent } from '@cyberloop/core';
import { WidgetSettingsTimeRangeType, isWidgetSettingsTimeRange, widgetSettingsAllowedTimeRanges } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import * as moment from 'moment';

import type { RangePickerResult } from '@cyberloop/core';
import type { WidgetSettingsTimeRange } from '@cyberloop/web/wells/model';

enum FormControls {
    TimeRangeMode = 'timeRangeMode',
    CustomTimeSpan = 'customTimeSpan',
    CustomSection = 'customSection',
    CustomTimeRange = 'customTimeRange',
}

@Component({
    selector: 'cyberloop-time-range-selector',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        FormsModule,
        ReactiveFormsModule,
        IconComponent,
        ClButtonToggleComponent,
        ClSelectorComponent
    ],
    templateUrl: './time-range-selector.component.html',
    styleUrls: ['./time-range-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ClTimeRangeSelectorComponent
        }
        // {
        //     provide: NG_VALIDATORS,
        //     multi: true,
        //     useExisting: TimeRangeModeSelectorComponent
        // }
    ]
})
export class ClTimeRangeSelectorComponent implements ControlValueAccessor {

    private _customTimeRange?: RangePickerResult;

    private _value: WidgetSettingsTimeRange | null = null;

    private _onChange?: (value: WidgetSettingsTimeRange | null) => void;
    private _onTouched?: () => void;
    private _touched = false;

    constructor(
        private readonly popup: PopupService,
        private readonly store: Store,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) { }

    readonly FormControls = FormControls;

    readonly form = new FormGroup({
        [FormControls.TimeRangeMode]: new FormControl<string | null>(null, [Validators.required]),
        [FormControls.CustomTimeSpan]: new FormControl<number | null>(null),
        [FormControls.CustomSection]: new FormControl<number | null>(null)//,
        // [FormControls.CustomTimeRange]: new FormControl<string | null>(null)
    });

    readonly timeRangeModes: Entity<WidgetSettingsTimeRangeType>[] = [
        { id: WidgetSettingsTimeRangeType.TimeRange, name: 'Time range' },
        { id: WidgetSettingsTimeRangeType.Section, name: 'Section' },
        { id: WidgetSettingsTimeRangeType.Custom, name: 'Custom' }
    ];

    readonly sections$ = this.store.select(CoreSelectors.sectionsOfCurrentWell);

    readonly timeRanges = Object.values(widgetSettingsAllowedTimeRanges).map(item => ({
        id: item.value,
        name: item.label
    }));

    get value() {
        return this._value;
    }

    /** @internal */
    get customTimeRange(): RangePickerResult | undefined {
        return this._customTimeRange;
    }
    /** @internal */
    get customTimeRangeStartDate(): string | undefined {
        return this._customTimeRange?.startDate
            ? moment(this._customTimeRange.startDate).format('DD.MM.YYYY')
            : undefined;
    }
    /** @internal */
    get customTimeRangeStartTime(): string | undefined {
        return this._customTimeRange?.startDate
            ? moment(this._customTimeRange.startDate).format('HH:mm')
            : undefined;
    }
    /** @internal */
    get customTimeRangeEndDate(): string | undefined {
        return this._customTimeRange?.endDate
            ? moment(this._customTimeRange.endDate).format('DD.MM.YYYY')
            : undefined;
    }
    /** @internal */
    get customTimeRangeEndTime(): string | undefined {
        return this._customTimeRange?.endDate
            ? moment(this._customTimeRange.endDate).format('HH:mm')
            : undefined;
    }

    @ViewChild('selectCustomRange', {
        static: true,
        read: ElementRef<HTMLButtonElement>
    }) selectCustomRange?: ElementRef<HTMLButtonElement>;

    // ControlValueAccessor methods

    writeValue(obj: any): void {
        if (!isWidgetSettingsTimeRange(obj)) {
            throw new Error('value should be a WidgetSettingsTimeRange');
        }

        this.form.controls.timeRangeMode.setValue(obj.type);

        switch (obj.type) {

            case WidgetSettingsTimeRangeType.TimeRange:
                this.form.controls.customTimeSpan.setValue(obj.range);
                break;

            case WidgetSettingsTimeRangeType.Section:
                this.form.controls.customSection.setValue(obj.sectionId);
                break;

            case WidgetSettingsTimeRangeType.Custom:
                this._customTimeRange = {
                    startDate: new Date(obj.start),
                    endDate: new Date(obj.end)
                };
                break;

            default:
                throw new Error(`Unsupported time range ${obj}`);
        }

        this._value = obj;
    }

    registerOnChange(fn: (value: WidgetSettingsTimeRange | null) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        }
        else {
            this.form.enable();
        }

        if (this.selectCustomRange) {
            this.selectCustomRange.nativeElement.disabled = isDisabled;
        }
    }

    // Validator methods

    // validate(control: AbstractControl<any, any>): ValidationErrors | null {
    //     if (!control.value) {
    //         return {
    //             required: true
    //         };
    //     }

    //     return null;
    // }

    // Self methods

    isTimeRangeSelectorVisible(): boolean {
        return this.form.controls.timeRangeMode.value === WidgetSettingsTimeRangeType.TimeRange;
    }

    isSectionSelectorVisible(): boolean {
        return this.form.controls.timeRangeMode.value === WidgetSettingsTimeRangeType.Section;
    }

    isCustomTimeSpanVisible(): boolean {
        return this.form.controls.timeRangeMode.value === WidgetSettingsTimeRangeType.Custom;
    }

    // Change handlers

    async onTimeModeChange(): Promise<void> {
        // Show different controls depending on time mode
        this.updateTimeRangeControls();
        // Mark this component as touched
        this.markAsTouched();
        // Update the value from their selected values
        await this.updateValueFromCurrentState();
        // Trigger change
        this._onChange?.(this._value);
    }

    async onControlChange(): Promise<void> {
        // Mark this component as touched
        this.markAsTouched();
        // Update the value from their selected values
        await this.updateValueFromCurrentState();
        // Trigger change
        this._onChange?.(this._value);
    }

    async onCustomTimeRangeChangeClick(): Promise<void> {
        // Get the selected date & time

        let startTime: moment.Moment | undefined = undefined;
        let endTime: moment.Moment | undefined = undefined;

        if (this._value?.type === WidgetSettingsTimeRangeType.Custom) {
            startTime = moment(this._value.start);
            endTime = moment(this._value.end);
        }

        // Show range picker

        const result = await this.popup.showForResult(RangePickerComponent, {
            data: {
                startTime,
                endTime
            },
            overlaySettings: {
                width: '90%',
                maxWidth: '1000px',
                height: 437,
                maxHeight: '95%'
            }
        });

        if (result) {
            this._customTimeRange = result;

            // Mark this component as touched
            this.markAsTouched();
            // Update the value from their selected values
            await this.updateValueFromCurrentState();
            // Trigger change
            this._onChange?.(this._value);

            this.changeDetectorRef.markForCheck();
        }
    }

    private updateTimeRangeControls(): void {

        switch (this.form.controls.timeRangeMode.value) {

            case WidgetSettingsTimeRangeType.TimeRange:
                this.form.controls.customTimeSpan.setValidators([Validators.required]);
                this.form.controls.customSection.setValidators([]);
                // this.form.controls.customTimeRange.setValidators([]);
                break;

            case WidgetSettingsTimeRangeType.Section:
                this.form.controls.customSection.setValidators([Validators.required]);
                this.form.controls.customTimeSpan.setValidators([]);
                // this.form.controls.customTimeRange.setValidators([]);
                break;

            case WidgetSettingsTimeRangeType.Custom:
                // this.form.controls.customTimeRange.setValidators([Validators.required]);
                this.form.controls.customTimeSpan.setValidators([]);
                this.form.controls.customSection.setValidators([]);
                break;

            default:
                break;
        }

        this.form.controls.customTimeSpan.updateValueAndValidity();
        this.form.controls.customSection.updateValueAndValidity();
        // this.form.controls.customTimeRange.updateValueAndValidity();
    }

    private async updateValueFromCurrentState(): Promise<void> {
        let wellId: string | undefined;

        switch (this.form.controls.timeRangeMode.value) {

            case WidgetSettingsTimeRangeType.TimeRange:
                this._value = this.form.controls.customTimeSpan.value
                    ? {
                        type: WidgetSettingsTimeRangeType.TimeRange,
                        range: this.form.controls.customTimeSpan.value
                    }
                    : null;
                break;

            case WidgetSettingsTimeRangeType.Section:
                wellId = await firstValueFrom(this.store.select(CoreSelectors.currentWellId));

                this._value = wellId && this.form.controls.customSection.value
                    ? {
                        type: WidgetSettingsTimeRangeType.Section,
                        wellId,
                        sectionId: this.form.controls.customSection.value
                    }
                    : null;
                break;

            case WidgetSettingsTimeRangeType.Custom:
                this._value = this._customTimeRange
                    ? {
                        type: WidgetSettingsTimeRangeType.Custom,
                        start: this._customTimeRange.startDate.getTime(),
                        end: this._customTimeRange.endDate.getTime()
                    }
                    : null;
                break;

            default:
                break;
        }
    }

    private markAsTouched() {
        if (!this._touched) {
            this._onTouched?.();
            this._touched = true;
        }
    }

}
