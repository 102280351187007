import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'cyberloop-well-card-skeleton',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './well-card-skeleton.component.html',
    styleUrls: ['../well-card/well-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WellCardSkeletonComponent {

}
