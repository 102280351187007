import { Injectable } from '@angular/core';

import { CoreSelectors, NotificationService } from '@cyberloop/core';
import { PlanningSelectors } from '@cyberloop/web/planning/shared/data';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { isNil } from 'lodash';
import { EMPTY, catchError, combineLatest, filter, first, from, map, switchMap, takeUntil, tap } from 'rxjs';

import { FilesDataService } from '../services/files-data.service';
import { FilesService } from '../services/files.service';
import { FilesActions } from './files.actions';
import { FilesSelectors } from './files.selectors';
import { FILES_FEATURE } from './files.state';
import { FilesTagActions } from './tag/tag.actions';

const initAction = createAction(`[${FILES_FEATURE}] Initialize files`);

@Injectable()
export class FilesEffects implements OnInitEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly filesService: FilesService,
        private readonly filesDataService: FilesDataService,
        private readonly notificationService: NotificationService
    ) { }

    //#region Common
    readonly handleError$ = createEffect(() => this.actions$.pipe(
        ofType(
            FilesActions.loadListFailure,
            FilesActions.loadListByIdsFailure,
            FilesActions.uploadFailure,
            FilesActions.updateFailure,
            FilesActions.downloadFailure,
            FilesActions.deleteFailure
        ),
        tap(({ error }) => this.notificationService.error(error))
    ), { dispatch: false });
    //#endregion Common

    //#region List
    readonly watchWellFiles$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.loadWellFileList),
        switchMap(() => this.store.select(PlanningSelectors.planningLoaded).pipe(
            takeUntil(this.actions$.pipe(ofType(FilesActions.unwatchList))),
            filter(Boolean),
            first(),
            switchMap(() => combineLatest([
                this.store.select(CoreSelectors.currentWellId).pipe(first(), filter(Boolean)),
                this.store.select(PlanningSelectors.planningId).pipe(first())
            ]).pipe(
                switchMap(([wellId, planningId]) => this.filesDataService.watchWellFiles(wellId, planningId).pipe(
                    map(list => FilesActions.loadListSuccess({ list })),
                    catchError(error => [FilesActions.loadListFailure({ error })])
                ))
            ))
        ))
    ));

    readonly watchPlanningFiles$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.loadPlanningFileList),
        switchMap(() => this.store.select(PlanningSelectors.planningId).pipe(
            takeUntil(this.actions$.pipe(ofType(FilesActions.unwatchList))),
            first(),
            filter(Boolean),
            switchMap((planningId) => this.filesDataService.watchPlanningFiles(planningId).pipe(
                map(list => FilesActions.loadListSuccess({ list })),
                catchError(error => [FilesActions.loadListFailure({ error })])
            ))
        ))
    ));

    readonly watchByIDs$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.loadListByIds),
        switchMap(({ ids }) => this.filesDataService.watchByIDs(ids).pipe(
            map(list => FilesActions.loadListByIdsSuccess({ list })),
            catchError(error => [FilesActions.loadListByIdsFailure({ error })])
        ))
    ));

    readonly loadTagList$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.loadWellFileList, FilesActions.loadPlanningFileList),
        map(() => FilesTagActions.loadList())
    ));
    //#endregion List

    //#region Upload
    readonly createWellFile$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.uploadWellFile),
        switchMap(({ file, wellId }) => this.filesDataService.createWellFile(file, wellId).pipe(
            map(meta => FilesActions.uploadSuccess({ meta })),
            catchError(error => [FilesActions.uploadFailure({ error })])
        ))
    ));

    readonly createWellMeterageFile$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.uploadWellMeterageFile),
        switchMap(({ file, wellId, itemId, itemList }) => this.filesDataService.createWellMeterageFile(file, wellId, itemId, itemList).pipe(
            map(meta => FilesActions.uploadSuccess({ meta })),
            catchError(error => [FilesActions.uploadFailure({ error })])
        ))
    ));

    readonly createPlanningFile$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.uploadPlanningFile),
        switchMap(({ file, planningId }) => this.filesDataService.createPlanningFile(file, planningId).pipe(
            map(meta => FilesActions.uploadSuccess({ meta })),
            catchError(error => [FilesActions.uploadFailure({ error })])
        ))
    ));

    readonly createPlanningStageFile$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.uploadPlanningStageFile),
        switchMap(({ file, planningId, stageId, activityId }) => this.filesDataService.createPlanningStageFile(file, planningId, stageId, activityId).pipe(
            map(meta => FilesActions.uploadSuccess({ meta })),
            catchError(error => [FilesActions.uploadFailure({ error })])
        ))
    ));

    readonly tryUpdateFileAfterUpload$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.uploadSuccess),
        map(({ meta }) => FilesActions.tryUpdate({ meta }))
    ));

    readonly showFileUploadedSuccessNotification$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.uploadSuccess),
        tap(({ meta }) => this.notificationService.info(`File '${meta.name}' uploaded`))
    ), { dispatch: false });
    //#endregion Upload

    //#region Update
    readonly loadTagListOnUpdateFile$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.tryUpdate),
        map(() => FilesTagActions.loadList())
    ));

    readonly tryUpdateFile$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.tryUpdate),
        switchMap(({ meta }) => from(this.filesService.openEditMetaPopup(meta)).pipe(
            filter(Boolean),
            map(dialogResult => FilesActions.update({ id: meta.id, update: dialogResult }))
        ))
    ));

    readonly updateFile$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.update),
        switchMap(({ id, update }) => from(this.filesDataService.update(id, update)).pipe(
            map(updatedMeta => FilesActions.updateSuccess({ meta: updatedMeta })),
            catchError(error => [FilesActions.updateFailure({ error })])
        ))
    ));

    readonly showFileUpdatedSuccessNotification$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.updateSuccess),
        tap(({ meta }) => this.notificationService.info(`File '${meta.name}' updated`))
    ), { dispatch: false });
    //#endregion Update

    //#region Download
    readonly downloadFile$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.download),
        switchMap(({ id }) => this.filesDataService.getDownloadLink(id).pipe(
            tap(url => this.filesService.download(url)),
            map(() => FilesActions.downloadSuccess())
        )),
        catchError(error => [FilesActions.downloadFailure({ error })])
    ));
    //#endregion Download

    //#region Delete
    readonly tryDeleteFile$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.tryDelete),
        switchMap(({ id }) => this.store.select(FilesSelectors.selectById(id)).pipe(
            first(),
            switchMap(meta => {
                if (isNil(meta)) {
                    this.notificationService.error(`File with id '${id}' not found`);
                    return EMPTY;
                }

                return from(this.filesService.confirmDelete(meta)).pipe(
                    filter(Boolean),
                    map(() => FilesActions.delete({ id }))
                );
            })
        ))
    ));

    readonly deleteFile$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.delete),
        switchMap(({ id }) => this.filesDataService.delete(id).pipe(
            map(result => FilesActions.deleteSuccess({ meta: result })),
            catchError(error => [FilesActions.deleteFailure({ error })])
        ))
    ));

    readonly showFileDeletedSuccessNotification$ = createEffect(() => this.actions$.pipe(
        ofType(FilesActions.deleteSuccess),
        tap(({ meta }) => this.notificationService.info(`File '${meta.name}' deleted`))
    ), { dispatch: false });
    //#endregion Delete

    ngrxOnInitEffects(): Action {
        return initAction();
    }
}
