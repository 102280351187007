<span class="title">
    <span class="title-text skeleton">lorem</span>
</span>

<div class="right">
    <div class="updated-time skeleton-section">
        <i class="skeleton">Lorem:</i>
        <time class="skeleton">00:00 00.00.0000</time>
    </div>
    <div class="actions">
        <button class="action-button cl-flat-button skeleton">
            lorem ipsum
        </button>
        <button class="action-button cl-flat-button skeleton">
            lorem ipsum
        </button>
        <button class="action-button cl-flat-button skeleton">
            lorem ipsum
        </button>
        <button class="action-button cl-flat-button skeleton">
            lorem ipsum
        </button>
    </div>
</div>
