import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, ClNumericInputComponent, ClSelectorComponent, CoreSelectors, UnitDescriptor, UnitsSelectors, WellKnownParams, WellKnownUnitIds } from '@cyberloop/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, combineLatest, firstValueFrom, map, of, shareReplay, switchMap } from 'rxjs';

import { ClTimeRangeSelectorComponent } from '../../../time-range-selector/time-range-selector.component';
import { KpiWidgetSettingsComponent } from '../../widget-settings/kpi-widget-settings.component';

import type { Entity } from '@cyberloop/core';
import type { PopupContent } from '@cyberloop/core';
import type { CustomVsTimeWidgetSettings, WidgetSettingsTimeRange } from '@cyberloop/web/wells/model';


function getUnitsForTag$(store: Store, formValues$: Observable<Record<string, any>>, tagKey: string): Observable<Entity[]> {
    return formValues$.pipe(
        switchMap(data => {
            // This is the Tag
            const tagValue = data[tagKey];

            if (!tagValue) {
                return of([]);
            }

            // Select units for this tag
            return store.select(CoreSelectors.tagUnits(tagValue)).pipe(
                map(units => Object.values(units).map(item => ({
                    id: item.id,
                    name: item.label
                })))
            );
        }),
        shareReplay(1)
    );
}

function getSelectedUnitForTag$(store: Store, formValues$: Observable<Record<string, any>>, tagKey: string): Observable<UnitDescriptor | undefined> {
    return formValues$.pipe(
        switchMap(data => {
            const tagValue = data[tagKey];
            const unitsValue = data[tagKey + 'Units'];

            if (!tagValue || !unitsValue) {
                return of(undefined);
            }

            // Then get it's unit group
            return store.select(CoreSelectors.tagUnitGroupId(tagValue)).pipe(
                switchMap(unitGroupId => {
                    if (!unitGroupId) {
                        return of(undefined);
                    }

                    // And select the unit from this unit group
                    return store.select(UnitsSelectors.getUnit(unitGroupId, unitsValue));
                })
            );
        })
    );
}


@Component({
    standalone: true,
    imports: [
        NgFor,
        AsyncPipe,
        KpiWidgetSettingsComponent,
        FormsModule,
        ReactiveFormsModule,
        ClTimeRangeSelectorComponent,
        ClSelectorComponent,
        ClNumericInputComponent,
        ClButtonToggleComponent
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements PopupContent<CustomVsTimeWidgetSettings>, OnDestroy {

    private readonly _unitsSubscriptions: Subscription[];

    private _closeFn?: (result: CustomVsTimeWidgetSettings | null) => void;
    private _data?: CustomVsTimeWidgetSettings;

    constructor(
        private readonly store: Store
    ) {
        this._unitsSubscriptions = this.tagUnits$.map(item => item.subscribe());
    }

    readonly tagIndexes: number[] = new Array(3).fill(0).map((v, i) => i);

    readonly tags: Entity[] = Object.keys(WellKnownParams).map(key => ({
        id: key,
        name: WellKnownParams[key as WellKnownParams]
    }));

    readonly form = new FormGroup({
        timeRange: new FormControl<WidgetSettingsTimeRange | null>(null, [ Validators.required ]),

        tag1: new FormControl<WellKnownParams | null>(null),
        tag1Units: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ]),
        tag1Min: new FormControl<number | null>(null),
        tag1Max: new FormControl<number | null>(null),

        tag2: new FormControl<WellKnownParams | null>(null),
        tag2Units: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ]),
        tag2Min: new FormControl<number | null>(null),
        tag2Max: new FormControl<number | null>(null),

        tag3: new FormControl<WellKnownParams | null>(null),
        tag3Units: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ]),
        tag3Min: new FormControl<number | null>(null),
        tag3Max: new FormControl<number | null>(null)
    });

    readonly tagUnits$ = [
        getUnitsForTag$(this.store, this.form.valueChanges, 'tag1'),
        getUnitsForTag$(this.store, this.form.valueChanges, 'tag2'),
        getUnitsForTag$(this.store, this.form.valueChanges, 'tag3')
    ];

    readonly selectedUnits$ = [
        getSelectedUnitForTag$(this.store, this.form.valueChanges, 'tag1'),
        getSelectedUnitForTag$(this.store, this.form.valueChanges, 'tag2'),
        getSelectedUnitForTag$(this.store, this.form.valueChanges, 'tag3')
    ];

    ngOnDestroy(): void {
        this._unitsSubscriptions.forEach(item => item.unsubscribe());
    }

    async setData(data: CustomVsTimeWidgetSettings): Promise<void> {
        this._data = data;

        if (data.timeRange) {
            this.form.controls.timeRange.setValue(data.timeRange);
        }

        if (data.tag1) {
            this.form.controls.tag1.setValue(data.tag1);
        }
        if (data.tag1UnitId) {
            this.form.controls.tag1Units.setValue(data.tag1UnitId);
        }
        else if (data.tag1) {
            // Set default units for this tag

            const unit = await this.getUnitForTag(data.tag1);

            if (unit) {
                this.form.controls.tag1Units.setValue(unit.id as WellKnownUnitIds);
            }
        }

        if (data.tag1Min) {
            this.form.controls.tag1Min.setValue(data.tag1Min);
        }
        if (data.tag1Max) {
            this.form.controls.tag1Max.setValue(data.tag1Max);
        }

        if (data.tag2) {
            this.form.controls.tag2.setValue(data.tag2);
        }
        if (data.tag2UnitId) {
            this.form.controls.tag2Units.setValue(data.tag2UnitId);
        }
        else if (data.tag2) {
            // Set default units for this tag

            const unit = await this.getUnitForTag(data.tag2);

            if (unit) {
                this.form.controls.tag2Units.setValue(unit.id as WellKnownUnitIds);
            }
        }

        if (data.tag2Min) {
            this.form.controls.tag2Min.setValue(data.tag2Min);
        }
        if (data.tag2Max) {
            this.form.controls.tag2Max.setValue(data.tag2Max);
        }

        if (data.tag3) {
            this.form.controls.tag3.setValue(data.tag3);
        }
        if (data.tag3UnitId) {
            this.form.controls.tag3Units.setValue(data.tag3UnitId);
        }
        else if (data.tag3) {
            // Set default units for this tag

            const unit = await this.getUnitForTag(data.tag3);

            if (unit) {
                this.form.controls.tag3Units.setValue(unit.id as WellKnownUnitIds);
            }
        }

        if (data.tag3Min) {
            this.form.controls.tag3Min.setValue(data.tag3Min);
        }
        if (data.tag3Max) {
            this.form.controls.tag3Max.setValue(data.tag3Max);
        }
    }

    setClose(fn: (result: CustomVsTimeWidgetSettings | null) => void): void {
        this._closeFn = fn;
    }

    async onTagChange(tagIndex: number, tag: WellKnownParams): Promise<void> {
        if (!tag) {
            return;
        }

        // Set default units for this tag

        const unit = await this.getUnitForTag(tag);

        if (unit) {
            const control = this.form.get('tag' + (tagIndex + 1) + 'Units');
            control?.setValue(unit.id);
            control?.updateValueAndValidity();
        }
    }

    onCancel(): void {
        this._closeFn?.(null);
    }

    onSubmit(): void {
        // Compose settings

        const result: CustomVsTimeWidgetSettings = {};

        if (this.form.controls.timeRange.value) {
            result.timeRange = this.form.controls.timeRange.value;
        }

        if (this.form.controls.tag1.value) {
            result.tag1 = this.form.controls.tag1.value;
        }
        if (this.form.controls.tag1Units.value) {
            result.tag1UnitId = this.form.controls.tag1Units.value;
        }
        if (this.form.controls.tag1Min.value) {
            result.tag1Min = this.form.controls.tag1Min.value;
        }
        if (this.form.controls.tag1Max.value) {
            result.tag1Max = this.form.controls.tag1Max.value;
        }

        if (this.form.controls.tag2.value) {
            result.tag2 = this.form.controls.tag2.value;
        }
        if (this.form.controls.tag2Units.value) {
            result.tag2UnitId = this.form.controls.tag2Units.value;
        }
        if (this.form.controls.tag2Min.value) {
            result.tag2Min = this.form.controls.tag2Min.value;
        }
        if (this.form.controls.tag2Max.value) {
            result.tag2Max = this.form.controls.tag2Max.value;
        }

        if (this.form.controls.tag3.value) {
            result.tag3 = this.form.controls.tag3.value;
        }
        if (this.form.controls.tag3Units.value) {
            result.tag3UnitId = this.form.controls.tag3Units.value;
        }
        if (this.form.controls.tag3Min.value) {
            result.tag3Min = this.form.controls.tag3Min.value;
        }
        if (this.form.controls.tag3Max.value) {
            result.tag3Max = this.form.controls.tag3Max.value;
        }

        this._closeFn?.(result);
    }

    getNumberLabel(i: number): string {
        if (i === 0) {
            return '1st';
        }
        else if (i === 1) {
            return '2nd';
        }
        else {
            return '3rd';
        }
    }

    private getUnitForTag(tag: WellKnownParams): Promise<UnitDescriptor | undefined> {
        return firstValueFrom(this.store.select(CoreSelectors.tagUnitGroupId(tag)).pipe(
            switchMap(unitGroupId => {
                if (!unitGroupId) {
                    return of(undefined);
                }

                // And select the pre-selected unit from this unit group
                return this.store.select(UnitsSelectors.getUserUnit(unitGroupId));
            })
        ));
    }

}
