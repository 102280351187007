import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FromSiPipe } from '@cyberloop/core';
import { CarouselComponent, CarouselItemDirective } from '@cyberloop/web/app/ui';
import { AssetMetricWithData } from '@cyberloop/web/wells/model';

import { KpiCardComponent } from './kpi-card/kpi-card.component';

@Component({
    selector: 'cyberloop-cards-carousel',
    standalone: true,
    imports: [
        CommonModule,
        CarouselComponent,
        CarouselItemDirective,
        KpiCardComponent,
        FromSiPipe
    ],
    templateUrl: './cards-carousel.component.html',
    styleUrls: ['./cards-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardsCarouselComponent {

    readonly skeletons = new Array(7);

    @Input() assetMetrics: AssetMetricWithData[] | null = [];
    @Input() assetMetricsDataIsLoaded: boolean | null = null;

    @Output() dotsClick: EventEmitter<void> = new EventEmitter<void>();
}
