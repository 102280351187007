import { Injectable } from '@angular/core';

import { fromEvent } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';

import { IStorageSrrviceBase } from '../../models/storage.service-base';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService implements IStorageSrrviceBase {
    /**
     *
     */
    constructor() {
        this.onChange$.subscribe(console.info);
    }

    readonly onChange$ = fromEvent(window, 'storage').pipe(
        map((x: any) => {
            const se: StorageEvent = x;
            return {
                key: se.key as string,
                old: se.oldValue as string,
                new: se.newValue as string,
                url: se.url as string,
                area: se.storageArea
            };
        }),
        filter(x => x.new !== x.old),
        share()
    );


    get<T>(key: string): T {
        const value = localStorage.getItem(key);
        if (value) {

            try {
                return JSON.parse(value);
            }
            catch (e) {
                console.warn(e);
            }
        }

        return null as any;
    }


    set<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    exists(key: string) {
        return !!localStorage.getItem(key);
    }

    delete(key: string): void {
        localStorage.removeItem(key);
    }
}