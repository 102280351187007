
export enum SurveyFields {
    tvd = 'tvd',
    tvdss = 'tvdss',
    hd = 'hd',
    md = 'md',
    ns = 'ns',
    ew = 'ew',
    vsec = 'vsec',
    hOffset = 'hOffset',
    thl = 'thl'
}  

export enum ChartType {
    VSEC = 'VSEC',
    HD = 'HD',
    THL = 'THL'
}

export const SeriesColors: string[] = new Array(17).fill('').map((value, index) => {
    return index === 0 ? 'var(--cl-text-enable-prim)': `var(--trace-${index + 1})`;
});