<div class="card-title">{{ title }}</div>
<div class="values">
  <div class="value-container">
    <div class="title">Distance</div>
    <span class="value">{{ distance ?? 'N/A' }}</span>
    <span class="unit">m</span>
  </div>
  <div class="vertical-divider"></div>
  <div class="value-container">
    <div class="title">Direction</div>
    <span class="value">{{ direction ?? 'N/A' }}</span>
    <span class="unit">deg</span>
  </div>
</div>
<div class="indicator" [class]="color"></div>