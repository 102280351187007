<block-with-heading
    [heading]="heading"
    [class.left-fade]="leftEdgeFaded$ | async"
    [class.right-fade]="rightEdgeFaded$ | async"
>

    <ng-container block-header-content>
        <ng-content select="[carousel-header-buttons]"></ng-content>

        <!-- Custom buttons -->
        <nav *ngIf="(arrowsVisible$ | async) || displayArrowsAlways" class="header-buttons">
            <button *ngIf="displayDots" class="button-dots" (click)="dotsClick.emit()">
                <icon name="dots__vertical"></icon>
            </button>
            <button mat-icon-button (click)="onPrevClick()" class="button-left">
                <svg viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99978 0.928932L2.34292 6.58579L0.928711 8L2.34292 9.41421L7.99978 15.0711L9.41399 13.6569L3.75714 8L9.41399 2.34315L7.99978 0.928932Z" fill="currentColor"/>
                </svg>
            </button>
            <button mat-icon-button (click)="onNextClick()" class="button-right">
                <svg viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00022 15.0711L7.65708 9.41421L9.07129 8L7.65708 6.58579L2.00022 0.928932L0.586008 2.34315L6.24286 8L0.586007 13.6569L2.00022 15.0711Z" fill="currentColor"/>
                </svg>
            </button>
        </nav>
    </ng-container>

    <!-- Main content -->
    <section
        block-main-content
        #viewport class="viewport"
    >
        <div class="signal-item signal-item-left"></div>
        <ng-content select="[carousel-item]"></ng-content>
        <div class="signal-item signal-item-right"></div>
    </section>

</block-with-heading>
