import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'cyberloop-planning-chart-skeleton',
    standalone: true,
    imports: [],
    templateUrl: './planning-chart-skeleton.component.html',
    styleUrls: ['./planning-chart-skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningChartSkeletonComponent {

}
