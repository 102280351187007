import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';

import { NavigationItem } from '@cyberloop/web/app/model';

@Component({
    selector: 'cyberloop-menu-items',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatRippleModule
    ],
    templateUrl: './menu-items.component.html',
    styleUrls: ['./menu-items.component.scss']
})
export class MenuItemsComponent {
    @Input() items: NavigationItem[] | null = [];
    @Input() disabled = true;

    @Output() readonly navigated = new EventEmitter<string>();
}
