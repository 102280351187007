import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { AnimatedMenuIconComponent } from '../animated-menu-icon/animated-menu-icon.component';
import { SideMenuComponent } from './side-menu/side-menu.component';

import type { NavigationItem, RecentItemWithWellMeta } from '@cyberloop/web/app/model';

@Component({
    selector: 'cyberloop-side-menu-trigger',
    standalone: true,
    templateUrl: './side-menu-trigger.component.html',
    styleUrls: ['./side-menu-trigger.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        AnimatedMenuIconComponent,
        SideMenuComponent
    ]
})
export class SideMenuTriggerComponent {
    @Input() navigationItems: NavigationItem[] | null = [];
    @Input() recentItems: RecentItemWithWellMeta[] | null = [];

    @Output() readonly navigationRequest = new EventEmitter<string>();

    @ViewChild(SideMenuComponent, { static: true }) sidemenu: SideMenuComponent | undefined;

    toggleSidebar() {
        this.sidemenu?.toggle();
    }

    hideSidebar() {
        this.sidemenu?.hide();
    }
}
