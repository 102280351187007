<div class="themes">
    <div class="theme light" (click)="setTheme(THEME_LIGHT)" >
        <input type="checkbox" [checked]="isLightTheme$|async">
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11 1C11 0.447715 10.5523 0 10 0C9.44771 0 9 0.447715 9 1V3.45167C9 3.78188 9.29737 4.03164 9.62695 4.01123C9.75 4.00391 9.875 4 10 4C10.5257 4 11 3.60411 11 3.07841V1ZM2.92776 2.92868C3.31824 2.53821 3.95132 2.53818 4.34183 2.92862L5.68755 4.27411C6.12491 4.7114 6.0577 5.4349 5.63359 5.88505C5.58689 5.93462 5.54104 5.98499 5.49609 6.03613C5.28089 6.28131 4.89943 6.31447 4.66875 6.08379L2.92776 4.3428C2.53727 3.9523 2.53727 3.31918 2.92776 2.92868ZM0 10C0 9.44771 0.447715 9 1 9H3.51056C3.80962 9 4.03688 9.26908 4.01562 9.56738C4.00586 9.71045 4 9.85449 4 10C4 10.5256 3.60307 11 3.07751 11H1C0.447715 11 0 10.5523 0 10ZM2.9277 17.0708C2.53725 16.6803 2.53727 16.0473 2.92776 15.6568L4.43315 14.1517C4.78703 13.7979 5.37228 13.8507 5.72266 14.208C6.10692 14.6015 6.16666 15.2451 5.77783 15.634L4.34171 17.0707C3.95129 17.4612 3.31818 17.4613 2.9277 17.0708ZM10 20C9.44771 20 9 19.5523 9 19V16.9216C9 16.3959 9.47431 16 10 16C10.5257 16 11 16.3959 11 16.9216V19C11 19.5523 10.5523 20 10 20ZM17.0703 17.0708C16.6798 17.4613 16.0467 17.4613 15.6562 17.0708L14.1868 15.6014C13.8153 15.2298 13.8713 14.6147 14.2429 14.2431C14.6145 13.8715 15.2294 13.8158 15.601 14.1874L17.0703 15.6567C17.4608 16.0472 17.4608 16.6803 17.0703 17.0708ZM20 10C20 10.5523 19.5523 11 19 11H16.9225C16.3969 11 16 10.5256 16 10C16 9.47444 16.3969 9 16.9225 9H19C19.5523 9 20 9.44771 20 10ZM17.0704 2.92877C17.4608 3.31923 17.4609 3.95228 17.0704 4.34277L15.601 5.81244C15.2294 6.1841 14.6144 6.12845 14.2428 5.75684C13.8712 5.3853 13.8152 4.7702 14.1867 4.39863L15.6562 2.92883C16.0467 2.53829 16.6799 2.53826 17.0704 2.92877ZM10 14C12.209 14 14 12.209 14 10C14 7.79102 12.209 6 10 6C7.79102 6 6 7.79102 6 10C6 12.209 7.79102 14 10 14Z"
                fill="currentColor" />
        </svg>
    </div>
    <div class="theme dark" (click)="setTheme(THEME_DARK)" >
        <svg viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1.1993 15.9521C6.78833 15.9521 11.3191 11.4213 11.3191 5.83224C11.3191 4.58226 11.0925 3.38521 10.6781 2.27993C10.3572 1.42402 11.0843 0.458659 11.897 0.877072C15.1596 2.55679 17.391 5.95789 17.391 9.88017C17.391 15.4692 12.8602 20 7.2712 20C4.65068 20 2.26281 19.004 0.465506 17.3698C-0.114002 16.8429 0.416071 15.9521 1.1993 15.9521Z"
                fill="currentColor" />
        </svg>

    </div>
</div>
