import { Component } from '@angular/core';

@Component({
    selector: 'cyberloop-wells-page-top-bar-skeleton',
    standalone: true,
    templateUrl: './wells-page-top-bar-skeleton.component.html',
    styleUrls: ['./../wells-page-top-bar/wells-page-top-bar.component.scss']
})
export class WellsPageTopBarSkeletonComponent {

    constructor() { }

}
