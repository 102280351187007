<form [formGroup]="form">
    <!-- Range mode -->
    <fieldset>
        <cl-button-toggle
            [formControlName]="FormControls.TimeRangeMode"
            [list]="timeRangeModes"
            (selectedChange)="onTimeModeChange()"
            class="range-selector"
        ></cl-button-toggle>
    </fieldset>

    <!-- Time range -->
    <fieldset *ngIf="isTimeRangeSelectorVisible()">
        <legend>Select time range</legend>
        <cl-selector [formControlName]="FormControls.CustomTimeSpan" [options]="timeRanges" placeholder="Pick a time range..." (valueChange)="onControlChange()"></cl-selector>
    </fieldset>

    <!-- Section -->
    <fieldset *ngIf="isSectionSelectorVisible()">
        <legend>Select section</legend>
        <cl-selector [formControlName]="FormControls.CustomSection" [options]="sections$ | async" placeholder="Select a section..." (valueChange)="onControlChange()"></cl-selector>
    </fieldset>

    <!-- Custom time range -->
    <fieldset *ngIf="isCustomTimeSpanVisible()">
        <legend>Selected range</legend>
        <div class="custom-time-range-box">
            <div class="selected-value">
                <ng-container *ngIf="customTimeRange; else: customTimeRangeNoSelection">
                    <div class="column">
                        <span class="date">{{customTimeRangeStartDate}}</span>
                        <span class="time">{{customTimeRangeStartTime}}</span>
                    </div>
                    <span> - </span>
                    <div class="column">
                        <span class="date">{{customTimeRangeEndDate}}</span>
                        <span class="time">{{customTimeRangeEndTime}}</span>
                    </div>
                </ng-container>
                <ng-template #customTimeRangeNoSelection>None</ng-template>
            </div>
            <button #selectCustomRange type="button" class="cl-flat-button" (click)="onCustomTimeRangeChangeClick()">Change</button>
        </div>
    </fieldset>
</form>
