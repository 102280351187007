import { isDevMode } from '@angular/core';

/**
 * Halt application
 * @param error error that lead to all crash
 */
export function haltApplication(error: unknown) {
    setTimeout(() => haltApplicationInternal(error), 1500);
}

/** @internal */
function haltApplicationInternal(error: unknown): void {
    if (!error) {
        error = 'Unknown error. Please reload the page';
    }

    const body = document.body;

    const errorInfo = document.createElement('div');
    errorInfo.className = 'failure';

    const header = 'Application failed';
    const glitchyHeader = document.createElement('div');
    glitchyHeader.className = 'glitchy-header';
    for (let i = 0; i < 3; i++) {
        const glitchyHeaderItem = document.createElement('div');
        glitchyHeaderItem.className = `glitchy-entry`;
        glitchyHeaderItem.textContent = header;

        if (i > 0) {
            glitchyHeaderItem.ariaHidden = "true";
        }

        glitchyHeader.appendChild(glitchyHeaderItem);
    }
    errorInfo.appendChild(glitchyHeader);

    const text = document.createElement('div');
    text.className = 'text';

    if (error instanceof Error) {
        let message = error.message;
        if (isDevMode()) {
            message = `<strong>Name</strong>: ${error.name}<br/>
<strong>Message</strong>: ${error.message}<br/>
<br/>
<strong>Stacktrace</strong>: <br/>
${error.stack?.replace('\n', '<br/>') ?? '&lt;missing&gt;'}`;
        }
        error = message;
    }
    else if (typeof error !== 'string') {
        error = `${error}`;
    }

    text.innerHTML = `${error}`;

    errorInfo.appendChild(text);

    body.replaceChildren(errorInfo);
}

