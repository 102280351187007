import { Injectable } from '@angular/core';

import { ExportDataColumn, ExportDataObject, ExportDataService, PrimaryColumnId, Range, Section, SectionForTimeExport, TagValueType, TagsValue, TimeExportQueryInput, UnitsConverterService, Well, primaryColumData } from '@cyberloop/core';
import { MetaForExport, Mode, RangeMode } from '@cyberloop/web/wells/model';
import { firstValueFrom } from 'rxjs';

import { ExportToFileService } from './export-to-file.service';

@Injectable({ providedIn: 'root' })
export class ExportTimeDataService {

    constructor(
        private readonly _exportData: ExportDataService,
        private readonly _exportToFile: ExportToFileService,
        private readonly _unitConverter: UnitsConverterService
    ) { }

    public async processExport(well: Well, obj: MetaForExport) {
        //#region GET DATA
        const rigName = well.rig;
        const step = (obj.mode === Mode.Time ? obj.timeStep : obj.depthStep) ?? 0;
        const traces = obj.selectedTraces;
        const tags = [...new Set(traces.map(x => x.id)).values()];
        const intervals = [... new Set(obj.selectedTraces.map(x => x.interval)).values()];
        const rawSections = obj.rangeMode === RangeMode.SingleSection && obj.selectedSection ? [obj.selectedSection] : obj.selectedSections;

        if (!rawSections) {
            return;
        }

        const sections = this.getSectionsForExport(rawSections, well, obj.rangeMode, obj.timeRange, obj.preselectTimeRange);

        const input: TimeExportQueryInput = { rigName, step, tags, intervals, sections };

        const query = this._exportData.getExportDataByTime(input);

        const data: TagsValue[] = await firstValueFrom(query);

        if (!data.length) {
            console.warn('No data for export');
            return;
        }
        //#endregion

        //#region MAKE COLUMN
        const columns: ExportDataColumn[] = traces.map(trace => ({
            id: trace.id,
            name: trace.title,
            unit: trace.unit.id,
            // unitGroupId: trace.unit.unitGroup,
            label: trace.unit.label,
            secondId: trace.number ? trace.id + trace.number : trace.id
        }));
        columns.unshift(primaryColumData[PrimaryColumnId.Date]); // IMPORTANT! Primary column must be first in array
        //#endregion

        //#region MAPPED DATA
        const mappedData = new Map<number, Record<string, number>>();
        const byDepth = obj.mode === Mode.Depth;
        const primaryKey = /*byDepth ? PrimaryColumnId.Depth : */PrimaryColumnId.Date;
        const primaryField = /*byDepth ? TagDataValue.Depth : */TagValueType.StartTime;
        const sectionsKeys = sections.map(x => x.name);

        for (const trace of traces) { // tags loop
            const fieldKey = trace.interval;
            const unit = trace.unit;
            const tagKey = trace.id;
            const storeKey = trace.number ? tagKey + trace.number : tagKey;
            const tag = data.find(x => x.name === tagKey);

            for (const section of sectionsKeys) { // section loop

                const tagData = tag?.[section];
                if (!tagData) {
                    continue;
                }

                for (const item of tagData) {
                    const mapKey = item[primaryField];
                    let val = mappedData.get(mapKey);
                    if (!val) {
                        val = { [primaryKey]: new Date(mapKey).getTime() };
                        mappedData.set(mapKey, val);
                    }


                    val[storeKey] = this._unitConverter.convertToUnit(item[fieldKey], unit);

                }
            }
        }

        const mappedValues = [...mappedData.values()];
        //#endregion

        const exportDataObj: ExportDataObject = {
            fileName: 'drilling-test',
            columns: columns,
            data: mappedValues,
            from: Math.min(...sections.map(x => new Date(x.since).getTime())),
            to: Math.max(...sections.map(x => new Date(x.until).getTime())),
            format: obj.fileType,
            byDepth,
            step: step,
            precision: obj.precision,
            well
        };

        this._exportToFile.exportData(exportDataObj);
    }

    private getSectionsForExport(rawSections: (Section | undefined)[], well: Well, rangeMode: RangeMode, timeRange?: Range, preselectTimeRange?: number): SectionForTimeExport[] {
        const sections: SectionForTimeExport[] = [];
        for (const section of rawSections) {
            if (!section) {
                continue;
            }
            const name = 's' + section.id;
            const until = rangeMode === RangeMode.MultipleSections
                ? (section.endTime || new Date()).toISOString()
                : this.getUntil(timeRange?.to, section?.endTime, well?.releaseTime);
            const since = rangeMode === RangeMode.MultipleSections
                ? section.startTime.toISOString()
                : this.getSince(until, well.startTime, section.startTime, timeRange?.from, preselectTimeRange);

            sections.push({ name, until, since });
        }

        return sections;
    }

    /**
     * Get until/to date
     * @param releaseTime well release time
     * @param range time range { from: number, to: number }
     * @returns date ISO string
     */
    private getUntil(to?: number, sectionTo?: Date, releaseTime?: Date): string {
        if (to) {
            return new Date(to).toISOString();
        }
        else if (sectionTo) {
            return sectionTo.toISOString();
        }
        else if (releaseTime) {
            return releaseTime.toISOString();
        }

        return new Date().toISOString();
    }

    /**
     * Get since/from date
     * @param startTime well start time
     * @param range time range { from: number, to: number }
     * @param until date ISO string
     * @returns date ISO string
     */
    private getSince(until: string,  startTime: Date, sectionFrom?: Date, from?: number, presetTime?: number): string {
        if (from) {
            return new Date(from).toISOString();
        }
        else if (typeof presetTime === 'number') {
            if (presetTime === 0) {
                if (sectionFrom) {
                    return sectionFrom.toISOString();
                }
                return startTime.toISOString();
            }
            const since = new Date(until).getTime() - presetTime * 60 * 60 * 1000;
            return new Date(since).toISOString();
        }
        else if (sectionFrom) {
            return sectionFrom.toISOString();
        }

        return startTime.toISOString();
    }
}