import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, ElementRef, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { IconComponent, observeIntersection, observeResize } from '@cyberloop/core';
import { BehaviorSubject, Subscription, combineLatest, map } from 'rxjs';

import { BlockWithHeadingComponent } from '../block-with-heading/block-with-heading.component';
import { CarouselItemDirective } from './carousel-item.directive';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'carousel',
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        MatButtonModule,
        BlockWithHeadingComponent,
        IconComponent
    ],
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements AfterViewInit, OnDestroy {

    private readonly _leftEdgeFaded$ = new BehaviorSubject<boolean>(false);
    private readonly _rightEdgeFaded$ = new BehaviorSubject<boolean>(false);

    /**
     * The element that contains the viewport
     */
    private _container?: HTMLElement;
    /**
     * The main viewport of the carousel
     */
    private _viewport?: HTMLElement;

    /**
     * Elements that help us detect edge intersections
     */
    private _leftSignal?: HTMLElement;
    private _rightSignal?: HTMLElement;

    private _intersectionSub?: Subscription;
    private _resizeSub?: Subscription;

    private _elements?: QueryList<CarouselItemDirective>;

    private _contentItemMinWidth = 0;
    private _contentItemMaxHeight = 0;
    private _pageItems = 0;

    readonly leftEdgeFaded$ = this._leftEdgeFaded$.asObservable();
    readonly rightEdgeFaded$ = this._rightEdgeFaded$.asObservable();
    readonly arrowsVisible$ = combineLatest([ this.leftEdgeFaded$, this.rightEdgeFaded$ ]).pipe(
        map(([ left, right ]) => left || right)
    );

    @Input() heading = 'Carousel';

    @Input() displayArrowsAlways = false;
    @Input() displayDots = false;
    @Output() dotsClick: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('viewport') private set viewport(value: ElementRef<HTMLDivElement>) {
        this._container = value.nativeElement.parentElement ?? undefined;

        this._viewport = value.nativeElement;

        // Get signal elements
        this._leftSignal = this._viewport.querySelector('.signal-item.signal-item-left') as HTMLElement;
        this._rightSignal = this._viewport.querySelector('.signal-item.signal-item-right') as HTMLElement;

        // Add intersection observer
        this._intersectionSub?.unsubscribe();
        this._intersectionSub = observeIntersection([ this._leftSignal, this._rightSignal ], { root: this._viewport })
            .subscribe(this.onIntersection.bind(this));

        this._resizeSub?.unsubscribe();
        this._resizeSub = observeResize([ this._viewport ])
            .subscribe(this.calcSizes.bind(this));
    }

    @ContentChildren(CarouselItemDirective, { descendants: true })
    private set contentChildren(value: QueryList<CarouselItemDirective>) {
        this._elements = value;

        // Calculate the content size
        this.calcSizes();
    }

    ngAfterViewInit(): void {
        this.calcSizes();

        if (this._viewport) {
            this._viewport.scrollLeft = 0;
        }
    }

    ngOnDestroy(): void {
        this._resizeSub?.unsubscribe();
    }

    onPrevClick(): void {
        if (!this._viewport) {
            return;
        }

        this._viewport.scrollLeft -= this._pageItems * this._contentItemMinWidth;
    }

    onNextClick(): void {
        if (!this._viewport) {
            return;
        }

        this._viewport.scrollLeft += this._pageItems * this._contentItemMinWidth;
    }

    private onIntersection(entries: IntersectionObserverEntry[]): void {
        for (const entry of entries) {
            switch (entry.target) {

                case this._leftSignal:
                    this._leftEdgeFaded$.next(!entry.isIntersecting);
                    break;

                case this._rightSignal:
                    this._rightEdgeFaded$.next(!entry.isIntersecting);
                    break;

                default:
                    break;
            }
        }
    }

    private calcSizes(): void {
        if (!this._elements || !this._container || !this._viewport) {
            return;
        }

        this._contentItemMaxHeight = 0;
        this._contentItemMinWidth = 0;

        for (const item of this._elements) {
            const width = item.element.nativeElement.offsetWidth;
            const height = item.element.nativeElement.offsetHeight;

            if (width && (this._contentItemMinWidth <= 0 || width < this._contentItemMinWidth)) {
                this._contentItemMinWidth = width;
            }
            if (height && height > this._contentItemMaxHeight) {
                this._contentItemMaxHeight = height;
            }
        }

        this._pageItems = Math.max(1, Math.floor(this._viewport.offsetWidth / this._contentItemMinWidth));

        const container = this._container.parentElement;
        const containerSection = container?.querySelector('section');

        if (containerSection) {
            this._viewport.style.height = this._contentItemMaxHeight + 'px';
            this._viewport.style.maxHeight = this._contentItemMaxHeight + 'px';
            // That's how we hide the scrollbar
            containerSection.style.height = this._contentItemMaxHeight + 'px';
            containerSection.style.maxHeight = this._contentItemMaxHeight + 'px';
        }
    }

}
