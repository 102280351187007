<h1 class="title">
    Save as
</h1>

<div [formGroup]="form" class="content">
    <cl-button-toggle [formControlName]="Controls.ActionId" [list]="actionList"></cl-button-toggle>

    <fieldset *ngIf="selectedActionId === SaveAsAction.Replace" class="fieldset">
        <label class="label">Select version</label>
        <cl-selector [formControlName]="Controls.VersionId" [options]="versionList$ | async"></cl-selector>
        <cl-form-errors [control]="versionIdControl"></cl-form-errors>
    </fieldset>

    <fieldset class="fieldset">
        <label class="label">Name version</label>
        <cl-input [formControlName]="Controls.Name" placeholder="Type"></cl-input>
        <cl-form-errors [control]="nameControl"></cl-form-errors>
    </fieldset>

    <fieldset class="fieldset">
        <label class="label">Description</label>
        <textarea [formControlName]="Controls.Description" placeholder="Type" class="textarea"></textarea>
    </fieldset>
</div>

<div class="actions">
    <button (click)="close()" class="cl-outlined-button action-button">
        <span class="label">Cancel</span>
    </button>

    <button (click)="save()" class="cl-primary-button action-button">
        <span class="label">Save</span>
    </button>
</div>

<button (click)="close()" class="cl-icon-button close">
    <icon name="close"></icon>
</button>