import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, ClInputComponent, ClSelectorComponent, ConfirmationDialogService, Entity, FormErrorsComponent, IconComponent, PopupContent } from '@cyberloop/core';
import { SaveAsAction, SaveAsDialogData, SaveAsDialogResult } from '@cyberloop/web/planning/shared/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';

enum Controls {
    ActionId = 'action',
    VersionId = 'versionId',
    Name = 'name',
    Description = 'description',
}

@UntilDestroy()
@Component({
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        ReactiveFormsModule,
        IconComponent,
        ClInputComponent,
        ClSelectorComponent,
        ClButtonToggleComponent,
        FormErrorsComponent
    ],
    templateUrl: './save-as.component.html',
    styleUrls: ['./save-as.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveAsComponent implements OnInit, PopupContent<SaveAsDialogResult | undefined> {
    private _data?: SaveAsDialogData;
    private _close!: (result?: SaveAsDialogResult) => void;

    constructor(
        private readonly fb: FormBuilder,
        private readonly confirmationDialogService: ConfirmationDialogService
    ) { }

    readonly Controls = Controls;
    readonly SaveAsAction = SaveAsAction;
    readonly form = this.fb.group({
        [Controls.ActionId]: [SaveAsAction.New],
        [Controls.VersionId]: [null],
        [Controls.Name]: ['', Validators.required],
        [Controls.Description]: ['']
    });

    get nameControl() {
        return this.form.get(Controls.Name) as FormControl;
    }

    get descriptionControl() {
        return this.form.get(Controls.Description) as FormControl;
    }

    get versionIdControl() {
        return this.form.get(Controls.VersionId) as FormControl;
    }

    get selectedActionControl() {
        return this.form.get(Controls.ActionId) as FormControl;
    }

    get selectedActionId() {
        return this.selectedActionControl.value;
    }

    get versionList$() {
        return this._data?.versionList$.pipe(map(versionList => versionList.filter(v => v.id !== this._data?.activeVersion?.id))) ?? null;
    }

    actionList: Entity[] = [
        {
            id: SaveAsAction.Replace,
            name: SaveAsAction.Replace
        }, {
            id: SaveAsAction.New,
            name: SaveAsAction.New
        }
    ];

    ngOnInit(): void {
        this.selectedActionControl?.valueChanges.pipe(untilDestroyed(this)).subscribe(selectedActionId => {
            if (this.versionIdControl.hasValidator(Validators.required)) {
                this.versionIdControl.removeValidators(Validators.required);
            }

            this.versionIdControl.reset();

            switch (selectedActionId) {
                case SaveAsAction.Current:
                    this.setForm();
                    break;

                case SaveAsAction.Replace:
                    this.versionIdControl.addValidators(Validators.required);
                    this.clearForm();
                    break;


                case SaveAsAction.New:
                    this.clearForm();
                    break;
            }
        });
    }

    setData(data: SaveAsDialogData): void {
        this._data = data;
        this.setForm();

        if (this._data?.activeVersion) {
            this.actionList.unshift({
                id: SaveAsAction.Current,
                name: SaveAsAction.Current
            });

            this.selectedActionControl.setValue(SaveAsAction.Current);
        }
    }

    setClose(fn: (result?: SaveAsDialogResult) => void): void {
        this._close = fn;
    }

    save() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this._close(this.form.value as SaveAsDialogResult);
    }

    async close() {
        if (this.form.dirty) {
            if (!await this.confirmationDialogService.show('You have unsaved changes. Do you really want to close?')) {
                return;
            }
        }

        this._close();
    }

    private setForm() {
        this.form.get(Controls.Name)?.setValue(this._data?.activeVersion?.name ?? '');
        this.form.get(Controls.Description)?.setValue(this._data?.activeVersion?.description ?? '');
    }

    private clearForm() {
        this.nameControl.reset();
        this.descriptionControl.reset();
    }
}
