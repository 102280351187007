import { Injectable } from '@angular/core';

import { Section, filteredBySectionTree } from '@cyberloop/core';
import { SurveyDataProviderSerivce } from '@cyberloop/web/wells/data';
import { Survey } from '@cyberloop/web/wells/model';
import { Observable, map, shareReplay, switchMap } from 'rxjs';

import { SurveyDataQuery } from '../queries/survey.query';
import { SurveyDataSubscription } from '../subscriptions/survey.subscription';

@Injectable({
    providedIn: 'root'
})
export class SurveyDataProviderServiceImpl extends SurveyDataProviderSerivce {

    private _surveyObservables: Record<string, Observable<Survey[] | undefined> | undefined> = {};

    constructor(
        private readonly surveyDataQuery: SurveyDataQuery,
        private readonly surveyDataSubscription: SurveyDataSubscription
    ) {
        super();
    }

    getSurveyData(wellID: string, sectionID: string | number): Observable<Survey[]> {
        return this.surveyDataQuery.fetch({wellID}).pipe(
            map((response) => response.data.surveys),
            map((surveys) => surveys.filter(survey => survey.selected && survey.sectionID == sectionID))
        );
    }

    getSurveyDataTree(wellID: string, sections: Section[], sectionID: number): Observable<Survey[]> {
        return this.surveyDataQuery.fetch({wellID}).pipe(
            map((response) => response.data.surveys),
            map((surveys) => surveys.filter(s => s.selected).sort((a, b) => b.number - a.number)),
            switchMap((surveys) => filteredBySectionTree(surveys, sections, sectionID))
        );
    }

    watchSurveyData(wellID: string, sectionID?: string | number): Observable<Survey[] | undefined> {
        return this.surveyDataQuery.watch({wellID}, {pollInterval: 60 * 1000}).pipe(
            map((response) => response.data.surveys),
            map((surveys) => surveys.filter(survey => survey.selected && (sectionID == survey.sectionID || sectionID === undefined)).reverse())
        );
        /* 
        TODO: uncomment on subscription works
        return this._surveyObservables[wellID + sectionID] ??= this.surveyDataSubscription.subscribe({wellID}).pipe(
            map((response) => response?.data?.surveysSubscription),
            map((surveys) => surveys?.filter(survey => survey.selected && survey.sectionID === sectionID))
        ); */
    }

    watchSurveyDataTree(wellID: string, sections: Section[], sectionID: number): Observable<Survey[] | undefined> {
        const query = this._surveyObservables[wellID] ??= this.surveyDataQuery.watch({wellID}, {pollInterval: 60 * 1000}).pipe(
            map((response) => response.data.surveys),
            shareReplay(1)
        );
        return query.pipe(
            map((surveys) => surveys?.filter(s => s.selected) ?? []),
            switchMap((surveys) => filteredBySectionTree(surveys, sections, sectionID))
        );
        /* 
        TODO: uncomment on subscription works
        return this._surveyObservables[wellID + sectionID] ??= this.surveyDataSubscription.subscribe({wellID}).pipe(
            map((response) => response?.data?.surveysSubscription),
            map((surveys) => surveys?.filter(survey => survey.selected && survey.sectionID === sectionID))
        ); */
    }
}
