<h1 class="title">{{ hasSelectedId ? 'Edit' : 'Add' }} Activity</h1>

<div class="content">
    <button *ngFor="let activity of data?.list" (click)="onSelect(activity.id)" class="card cl-flat-button">
        {{ activity.name }}
    </button>
</div>

<button (click)="close()" class="close cl-icon-button">
    <icon name="close"></icon>
</button>