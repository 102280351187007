<cyberloop-kpi-widget-settings class="settings" (close)="onCancel()">

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <cyberloop-time-range-selector
            formControlName="timeRange"
            class="range-selector"
        ></cyberloop-time-range-selector>

        <h4>Input Y axis limits</h4>

        <!-- Axis settings -->
        <div class="fields-group">
            <fieldset>
                <legend>Min</legend>
                <cl-numeric-input formControlName="yAxisMin" [unit]="selectedUnit$ | async"></cl-numeric-input>
            </fieldset>
            <fieldset>
                <legend>Max</legend>
                <cl-numeric-input formControlName="yAxisMax" [unit]="selectedUnit$ | async"></cl-numeric-input>
            </fieldset>
        </div>

        <fieldset>
            <legend>Target</legend>
            <cl-numeric-input formControlName="target" [unit]="selectedUnit$ | async"></cl-numeric-input>
        </fieldset>

        <!-- Units -->
        <fieldset>
            <legend>Select units</legend>
            <cl-button-toggle
                formControlName="units"
                selectionType="radio"
                [list]="(units$ | async) ?? []"
                class="units-selector"
            ></cl-button-toggle>
        </fieldset>

        <section class="actions">
            <button type="button" class="cl-flat-button" (click)="onCancel()">Cancel</button>
            <button type="submit" class="cl-primary-button">Apply</button>
        </section>

    </form>

</cyberloop-kpi-widget-settings>