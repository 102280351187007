<cyberloop-kpi-widget heading="Custom vs Time" [size]="size" class="widget" (settingsRequested)="onSettings()" (deleteRequested)="onDelete()">
    <div class="chart-container" widget-body [class.loading]="dataLoading$ | async">
        <cyberloop-chart
            [options]="options"
            class="chart"
        ></cyberloop-chart>

        <div class="placeholder" *ngIf="dataLoading$ | async">
            // TODO
        </div>
    </div>
</cyberloop-kpi-widget>