import { Assets, Rig, Well } from '../models';
import { ThemeName } from '../models/themes';

/** @internal */
export const CORE_FEATURE = 'CORE';

/**
 * @internal
 * State for core
 * */
export interface CoreState {
    theme: ThemeName;
    rigs: Rig[];
    rigsAreLoaded: boolean;
    wells: Well[];
    wellsAreLoaded: boolean;
    currentWell?: string;
    currentSection?: number;
    currentAssets: Assets;
}

/** @internal */
export function getDefaultCoreState(): CoreState {
    return {
        theme: ThemeName.Dark,
        rigs: [],
        rigsAreLoaded: false,
        wells: [],
        wellsAreLoaded: false,
        currentAssets: []
    };
}
