import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { AuthActions } from '../../../../state/auth/auth.actions';
import { AuthSelectors } from '../../../../state/auth/auth.selectors';
import { AuthRoutes } from '../../../routing';
import { SetNewPasswordUIComponent } from '../../../ui/set-new-password/set-new-password.component';

/** @internal */
@Component({
    standalone: true,
    imports: [
        AsyncPipe,
        SetNewPasswordUIComponent
    ],
    template: `<cyberloop-set-new-password
        (trySetNewPassword)="trySetNewPassword($event)"
        (backToLogin)="backToLogin()"
        [loading]="loading$ | async"
        [error]="error$ | async"
    ></cyberloop-set-new-password>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetNewPasswordComponent {
    constructor(
        private readonly store: Store,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute
    ) { }

    readonly loading$ = this.store.select(AuthSelectors.newPasswordLoading);
    readonly error$ = this.store.select(AuthSelectors.newPasswordError);

    async trySetNewPassword(password: string) {
        const { oobCode: code } = await firstValueFrom(this.activatedRoute.queryParams);
        this.store.dispatch(AuthActions.trySetNewPassword({ code, password }));
    }

    backToLogin() {
        this.router.navigate([AuthRoutes.Root, AuthRoutes.SignIn]);
    }
}
