import { createReducer, on } from '@ngrx/store';

import { KpiActions } from './kpi.actions';
import { KpiState, getDefaultKpiState } from './kpi.state';

export const kpiReducer = createReducer<KpiState>(getDefaultKpiState(),

    on(KpiActions.setWidgets, (state, { widgets }): KpiState => ({
        ...state,
        widgets,
        error: undefined,
        dataState: 'LOADED'
    })),

    on(KpiActions.setAllWidgetsSettings, (state, { settings }): KpiState => {
        return ({
            ...state,
            widgetSettings: settings
        });
    }),

    on(KpiActions.setWidgetSettings, (state, { widgetId, settings }): KpiState => {
        return ({
            ...state,
            widgetSettings: {
                ...state.widgetSettings,
                [widgetId]: settings
            }
        });
    }),

    on(KpiActions.setError, (state, { error }): KpiState => ({
        ...state,
        error,
        dataState: 'NOT_LOADED'
    })),

    on(KpiActions.setDashboards, (state, { dashboards }): KpiState => {
        return ({
            ...state,
            dashboards
        });
    }),

    on(KpiActions.setActiveDashboard, (state, { dashboardId }): KpiState => {
        return ({
            ...state,
            activeDashboard: dashboardId
        });
    }),

    on(KpiActions.setSelectedMetrics, (state, { metrics }): KpiState => {
        return ({
            ...state,
            selectedMetrics: metrics
        });
    })

);
