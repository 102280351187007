import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent, PopupContent, RigActivity } from '@cyberloop/core';
import { PlanningActivityDialogData } from '@cyberloop/web/planning/shared/model';
import { isNil } from 'lodash';

@Component({
    selector: 'cyberloop-planning-activity',
    standalone: true,
    imports: [NgFor, IconComponent],
    templateUrl: './planning-activity.component.html',
    styleUrls: ['./planning-activity.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningActivityComponent implements PopupContent<RigActivity | undefined> {
    constructor(
    ) { }

    data?: PlanningActivityDialogData = undefined;

    close: (result?: RigActivity) => void = () => null;

    setData(data: PlanningActivityDialogData): void {
        this.data = data;
    }

    setClose(fn: (result?: RigActivity) => void): void {
        this.close = fn;
    }

    get hasSelectedId() {
        return !isNil(this.data?.selectedId);
    }

    onSelect(id: RigActivity) {
        this.close(id);
    }
}
