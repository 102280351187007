import { Injectable } from '@angular/core';

import { LiveData, LiveDataProviderService } from '@cyberloop/core';
import { EMPTY, Observable, finalize, map, of, share, switchMap } from 'rxjs';

import { LiveDataSubscription } from '../subscriptions/live-data.subscription';

@Injectable({
    providedIn: 'root'
})
export class LiveDataProviderServiceImpl extends LiveDataProviderService {
    private _latest$: Observable<LiveData[]> | undefined;
    private _latestRig: string | undefined;

    /**
     *
     */
    constructor(
        private readonly subscr: LiveDataSubscription
    ) {
        super();
    }

    getForRig(rigName: string): Observable<LiveData[]> {

        if (this._latestRig !== rigName) {
            this._latest$ = undefined;
            this._latestRig = rigName;
        }

        return this._latest$ ??= this.subscr.subscribe({ rigName }).pipe(
            switchMap(res => {
                if (res.errors) {
                    res.errors.forEach(err => {
                        console.warn('getForRig', { rigName, err });
                    });
                    return EMPTY;
                }

                if (!res.data?.tagValues) {
                    console.warn('getForRig', { rigName, err: 'NO DATA' });
                    return EMPTY;
                }

                return of(res.data.tagValues);
            }),
            map(tagValues => tagValues.values.map(tagValue => {
                const res: LiveData = {
                    bitDepth: tagValues.bitDepth,
                    holeDepth: tagValues.holeDepth,
                    time: new Date(tagValues.time),
                    value: tagValue.value,
                    rigState: tagValues.rigState,
                    rigActivity: tagValues.rigActivity,
                    tagName: tagValue.tagName.toUpperCase()
                };
                return res;
            })),
            finalize(() => {
                this._latestRig = undefined;
                this._latest$ = undefined;
            }),
            share()
        );
    }
}