import { AsyncPipe, NgIf, formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnChanges } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import { NgVarDirective, SimpleChangesOf, UnitDescriptor, UnitsConverterService, UnitsSelectors, WellKnownUnitGroupIds } from '@cyberloop/core';
import { KpiActions, KpiSelectors } from '@cyberloop/web/wells/data';
import { KpiWidget, WidgetDataProvider, WidgetSettingsHandler, WidgetSize, WidgetType, assetDailyMetricsDefaultSettings } from '@cyberloop/web/wells/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, filter, map, switchMap, tap } from 'rxjs';

import { KpiWidgetComponent } from '../widget/kpi-widget.component';
import { SettingsComponent } from './settings/settings.component';

import type { AssetDailyMetricsWidgetDataItem, AssetDailyMetricsWidgetSettings } from '@cyberloop/web/wells/model';

type WidgetDataRow = {
    metric: string,
    value12h: string,
    value24h: string
};

const placeholders: WidgetDataRow[] = [
    { metric: 'WWWWWWWW', value12h: 'WWWW', value24h: 'WWWWW' },
    { metric: 'WWWWW', value12h: 'WWWW', value24h: 'WWWWW' },
    { metric: 'WWWWWWW', value12h: 'WWWW', value24h: 'WWWWW' },
    { metric: 'WWWW', value12h: 'WWWW', value24h: 'WWWWW' },
    { metric: 'WWWWWW', value12h: 'WWWW', value24h: 'WWWWW' },
    { metric: 'WWWWWW', value12h: 'WWWW', value24h: 'WWWWW' },
    { metric: 'WWWWWWW', value12h: 'WWWW', value24h: 'WWWWW' },
    { metric: 'WWWWW', value12h: 'WWWW', value24h: 'WWWWW' }
];

@Component({
    selector: 'cyberloop-asset-daily-metrics-widget',
    standalone: true,
    imports: [
        NgIf,
        NgVarDirective,
        AsyncPipe,
        MatTableModule,
        KpiWidgetComponent
    ],
    templateUrl: './asset-daily-metrics-widget.component.html',
    styleUrls: ['./asset-daily-metrics-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class AssetDailyMetricsWidgetComponent implements KpiWidget, OnChanges {

    private readonly _id$ = new BehaviorSubject<string>('-');
    private readonly _dataLoading$ = new BehaviorSubject<boolean>(true);
    private readonly _textPlaceholder$ = new BehaviorSubject<string | undefined>(undefined);

    private readonly _tableRows$ = new BehaviorSubject<WidgetDataRow[]>([]);

    private _rotationUnit?: UnitDescriptor;
    private _distanceUnit?: UnitDescriptor;
    private _timeUnit?: UnitDescriptor;

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string,
        private readonly store: Store,
        private readonly settings: WidgetSettingsHandler,
        private readonly data: WidgetDataProvider,
        private readonly unitsConverter: UnitsConverterService
    ) {
        const idPipe = this._id$.pipe(
            untilDestroyed(this),
            filter(Boolean),
            tap(() => {
                this._dataLoading$.next(true);
                this._tableRows$.next(placeholders);
            })
        );

        combineLatest([
            // Get widget data
            idPipe.pipe(
                switchMap(id => this.data.getData(id, WidgetType.AssetDailyMetrics))
            ),
            // Get widget settings (for units conversion)
            idPipe.pipe(
                switchMap(id => this.store.select(KpiSelectors.widgetSettings<AssetDailyMetricsWidgetSettings>(id))),
                map(widgetSettings => ({
                    rotationUnitId: widgetSettings?.rotationUnitId ?? assetDailyMetricsDefaultSettings.rotationUnitId,
                    distanceUnitId: widgetSettings?.distanceUnitId ?? assetDailyMetricsDefaultSettings.distanceUnitId,
                    timeUnitId: widgetSettings?.timeUnitId ?? assetDailyMetricsDefaultSettings.timeUnitId
                })),
                switchMap(widgetSettings => {
                    return combineLatest([
                        this.store.select(UnitsSelectors.getUnit(WellKnownUnitGroupIds.Speed, widgetSettings.rotationUnitId)),
                        this.store.select(UnitsSelectors.getUnit(WellKnownUnitGroupIds.Length, widgetSettings.distanceUnitId)),
                        this.store.select(UnitsSelectors.getUnit(WellKnownUnitGroupIds.Time, widgetSettings.timeUnitId))
                    ]).pipe(
                        map(([rotationUnit, distanceUnit, timeUnit]) => ({
                            rotationUnit: rotationUnit as UnitDescriptor,
                            distanceUnit: distanceUnit as UnitDescriptor,
                            timeUnit: timeUnit as UnitDescriptor
                        }))
                    );
                })
            )
        ]).subscribe(([widgetData, widgetSettings]) => {
            this._rotationUnit = widgetSettings.rotationUnit;
            this._distanceUnit = widgetSettings.distanceUnit;
            this._timeUnit = widgetSettings.timeUnit;

            if (typeof widgetData === 'string') {
                this._tableRows$.next([]);
                this._dataLoading$.next(false);
                this._textPlaceholder$.next(widgetData);
                return;
            }

            this._textPlaceholder$.next(undefined);


            const dataToSet: WidgetDataRow[] = [
                {
                    metric: 'Meters drilled',
                    value12h: this.getDistanceValue(widgetData['12h'], 'metersDrilledTotal'),
                    value24h: this.getDistanceValue(widgetData['24h'], 'metersDrilledTotal')
                },
                {
                    metric: 'Meters drilled in Rotary',
                    value12h: this.getDistanceValue(widgetData['12h'], 'metersDrilledRotary'),
                    value24h: this.getDistanceValue(widgetData['24h'], 'metersDrilledRotary')
                },
                {
                    metric: 'Meters drilled in Slide',
                    value12h: this.getDistanceValue(widgetData['12h'], 'metersDrilledSlide'),
                    value24h: this.getDistanceValue(widgetData['24h'], 'metersDrilledSlide')
                },
                {
                    metric: '% in Slide mode (meters)',
                    value12h: this.getPercentValue(widgetData['12h'], 'slideMetersPercent'),
                    value24h: this.getPercentValue(widgetData['24h'], 'slideMetersPercent')
                },
                {
                    metric: '% in Slide mode (time)',
                    value12h: this.getPercentValue(widgetData['12h'], 'slideTimePercent'),
                    value24h: this.getPercentValue(widgetData['24h'], 'slideTimePercent')
                },
                {
                    metric: 'On Bottom % (time)',
                    value12h: this.getPercentValue(widgetData['12h'], 'onBottomTimePercent'),
                    value24h: this.getPercentValue(widgetData['24h'], 'onBottomTimePercent')
                },
                {
                    metric: 'Avg ROP rotary',
                    value12h: this.getRotationValue(widgetData['12h'], 'ropRotaryAvg'),
                    value24h: this.getRotationValue(widgetData['24h'], 'ropRotaryAvg')
                },
                {
                    metric: 'Avg ROP slide',
                    value12h: this.getRotationValue(widgetData['12h'], 'ropSlideAvg'),
                    value24h: this.getRotationValue(widgetData['24h'], 'ropSlideAvg')
                },
                {
                    metric: 'Weight to Weight time',
                    value12h: this.getTimeValue(widgetData['12h'], 'w2wTime'),
                    value24h: this.getTimeValue(widgetData['24h'], 'w2wTime')
                },
                {
                    metric: 'Slips to Slips time',
                    value12h: this.getTimeValue(widgetData['12h'], 's2sTime'),
                    value24h: this.getTimeValue(widgetData['24h'], 's2sTime')
                }
            ];

            this._tableRows$.next(dataToSet);
            this._dataLoading$.next(false);
        });
    }

    @Input() id = '-';
    @Input() size = WidgetSize.Tiny;

    readonly dataLoading$ = this._dataLoading$.asObservable();
    readonly textPlaceholder$ = this._textPlaceholder$.asObservable();
    readonly canShowData$ = combineLatest([
        this.dataLoading$,
        this.textPlaceholder$
    ]).pipe(
        map(([loading, placeholder]) => !loading && !placeholder)
    );

    readonly displayedColumns = [ 'metric', 'value12h', 'value24h' ];
    readonly tableRows$ = this._tableRows$.asObservable();

    ngOnChanges(changes: SimpleChangesOf<AssetDailyMetricsWidgetComponent>): void {
        if (changes.id) {
            this._id$.next(changes.id.currentValue);
        }
    }

    async onSettings(): Promise<void> {
        await this.settings.showSettings<AssetDailyMetricsWidgetSettings>(
            this.id,
            SettingsComponent,
            assetDailyMetricsDefaultSettings,
            { maxWidth: '520px' }
        );
    }

    onDelete(): void {
        this.store.dispatch(KpiActions.deleteWidget({ widgetId: this.id }));
    }

    // --

    private getFormattedNumber(source: number): string {
        return formatNumber(source, this.locale, '1.0-2');
    }

    private getRotationConvertedValue(source: number): string {
        return this.getFormattedNumber(
            this._rotationUnit
                ? this.unitsConverter.convertToUnit(source, this._rotationUnit)
                : source
        );
    }
    private getRotationUnit(): string {
        return this._rotationUnit?.label ?? '';
    }

    private getDistanceConvertedValue(source: number): string {
        return this.getFormattedNumber(
            this._distanceUnit
                ? this.unitsConverter.convertToUnit(source, this._distanceUnit)
                : source
        );
    }
    private getDistanceUnit(): string {
        return this._distanceUnit?.label ?? '';
    }

    private getTimeConvertedValue(source: number): string {
        return this.getFormattedNumber(
            this._timeUnit
                ? this.unitsConverter.convertToUnit(source, this._timeUnit)
                : source
        );
    }
    private getTimeUnit(): string {
        return this._timeUnit?.label ?? '';
    }

    private getDistanceValue(dataItem: AssetDailyMetricsWidgetDataItem | undefined, key: keyof AssetDailyMetricsWidgetDataItem): string {
        if (!dataItem) {
            return '-';
        }

        return this.getDistanceConvertedValue(dataItem[key]) + ' ' + this.getDistanceUnit();
    }
    private getPercentValue(dataItem: AssetDailyMetricsWidgetDataItem | undefined, key: keyof AssetDailyMetricsWidgetDataItem): string {
        if (!dataItem) {
            return '-';
        }

        return this.getFormattedNumber(dataItem[key]) + ' %';
    }
    private getRotationValue(dataItem: AssetDailyMetricsWidgetDataItem | undefined, key: keyof AssetDailyMetricsWidgetDataItem): string {
        if (!dataItem) {
            return '-';
        }

        return this.getRotationConvertedValue(dataItem[key]) + ' ' + this.getRotationUnit();
    }
    private getTimeValue(dataItem: AssetDailyMetricsWidgetDataItem | undefined, key: keyof AssetDailyMetricsWidgetDataItem): string {
        if (!dataItem) {
            return '-';
        }

        return this.getTimeConvertedValue(dataItem[key]) + ' ' + this.getTimeUnit();
    }

}
