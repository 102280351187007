import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';

import { ClSelectorComponent, DatetimePipe, IconComponent, NgVarDirective, PopupContent } from '@cyberloop/core';
import { FileUploadedDialogData, FileUploadedDialogResult } from '@cyberloop/web/files/model';
import { isNil } from 'lodash';

enum Controls {
    Activity = 'activityId',
    Tags = 'tagIds',
}
@Component({
    selector: 'cyberloop-file-uploaded',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgVarDirective,
        AsyncPipe,
        ReactiveFormsModule,
        DatetimePipe,
        IconComponent,
        ClSelectorComponent
    ],
    templateUrl: './file-uploaded.component.html',
    styleUrls: ['./file-uploaded.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadedComponent implements PopupContent<FileUploadedDialogResult | undefined> {
    constructor(
        private readonly fb: FormBuilder
    ) { }

    readonly Controls = Controls;
    readonly form = this.fb.group({
        [Controls.Activity]: [null],
        [Controls.Tags]: [[]]
    });

    readonly activityIdControl = this.form.get(Controls.Activity) as FormControl;

    data?: FileUploadedDialogData;

    close: (result?: FileUploadedDialogResult) => void = () => null;

    setData(data: FileUploadedDialogData): void {
        this.data = data;
        const activityId = data.meta.activityId;

        if (!isNil(activityId)) {
            this.activityIdControl.setValue(activityId);
            this.activityIdControl.disable();
        }
    }

    setClose(fn: (result?: FileUploadedDialogResult) => void): void {
        this.close = fn;
    }

    onSave() {
        const formValue = this.form.value;

        const result: FileUploadedDialogResult = {
            tagIds: formValue[Controls.Tags] ?? []
        };

        if (isNil(this.data?.meta.activityId)) {
            result.activityId = formValue[Controls.Activity] ?? null;
        }

        this.close(result);
    }

    onCancel() {
        this.close();
    }
}
