import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiRigConnectionSumsItem } from '@cyberloop/web/wells/model';
type KpiRigConnectionSumsInput = {
    rig: string,
    since: string,
    until: string
}

type KpiRigConnectionSums = {
    rig: {
        rigKPIConnectionsSums: KpiRigConnectionSumsItem
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiRigConnectionSumsQuery extends GraphQLQuery<KpiRigConnectionSums, KpiRigConnectionSumsInput> {
    override readonly document = `
        query GetRigKpiConnectionSums($rig: String!, $since: DateTime!, $until: DateTime!) {
            rig(name: $rig) {
                ... on Rig {
                    rigKPIConnectionsSums(since: $since, until: $until) {
                        sumS2S
                        sumS2W
                        sumW2S
                    }
                }
            }
        }
    `;
}