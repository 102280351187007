import { Routes } from '@angular/router';

import { AuthPageComponent } from './feature/auth-page/auth-page.component';
import { RestorePasswordRequestedComponent } from './feature/components/restore-password-requested/restore-password-requested.component';
import { RestorePasswordComponent } from './feature/components/restore-password/restore-password.component';
import { SetNewPasswordComponent } from './feature/components/set-new-password/set-new-password.component';
import { SigninComponent } from './feature/components/signin/signin.component';

export enum AuthRoutes {
    Root = 'auth',
    Handler = 'handler',
    SignIn = 'signin',
    ResetPassword = 'reset-password',
    PasswordResetRequested = 'password-reset-requested',
    ChangePassword = 'change-password',
}

/** Auth routes */
export const authRouting: Routes = [{
    path: AuthRoutes.Root, component: AuthPageComponent, children: [
        { path: AuthRoutes.SignIn, component: SigninComponent, title: 'Sign in' },
        { path: AuthRoutes.ResetPassword, component: RestorePasswordComponent, title: 'Reset password' },
        { path: AuthRoutes.PasswordResetRequested, component: RestorePasswordRequestedComponent, title: 'Password reset' },
        { path: AuthRoutes.ChangePassword, component: SetNewPasswordComponent, title: 'New password' },
    ]
}];
