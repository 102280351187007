import { PlanningStage, PlanningVersion } from '@cyberloop/web/planning/shared/model';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { Moment } from 'moment';

import { LoadingState } from '../planning.state';

export const VERSION_FEATURE = 'PLANNING_VERSION';

/**
 * State for version
 * */
export interface VersionState extends EntityState<PlanningVersion> {
    listLoadingState: LoadingState;
    listError?: Error;

    versionLoadingState: LoadingState;
    versionError?: Error;

    createLoading: boolean;
    createError?: Error;

    updateLoading: boolean;
    updateError?: Error;

    replaceLoading: boolean;
    replaceError?: Error;

    deleteLoading: boolean;
    deleteError?: Error;

    editedStages?: PlanningStage[];
    stagesUpdatedAt?: Moment;
}

export const adapter = createEntityAdapter<PlanningVersion>({
    sortComparer: (a, b) => b.updatedAt.valueOf() - a.updatedAt.valueOf()
});

export const initialState: VersionState = adapter.getInitialState({
    listLoadingState: 'NOT_LOADED',
    versionLoadingState: 'NOT_LOADED',
    createLoading: false,
    updateLoading: false,
    replaceLoading: false,
    deleteLoading: false
});
