import { Injectable } from '@angular/core';

import { Toolface } from '@cyberloop/web/wells/model';

import { GraphQLQuery } from '../internals/cl-gql';

type QueryRequest = {
    rigName: string
}

type QueryResponse = {
    rig: {
        toolface: Toolface[];
    }
}

@Injectable({
    providedIn: 'root'
})
export class TFArrayDataQuery extends GraphQLQuery<QueryResponse, QueryRequest> {
    override readonly document = `query tfquery($rigName: String!) {
        rig(name: $rigName) {
          ... on Rig {
            toolface {
              sourceName
              value
            }
          }
        }
    }`;
}