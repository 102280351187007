import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Entity, uuid } from '@cyberloop/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Represents a type for an item identifier that can be a string or a number.
*/
type ItemID = any;

/**
 * Represents a function type that is called when the control's value changes in the UI.
 * @callback ChangeMethod
 * @param {ItemID} value - The new value of the control.
 * @returns {void}
*/
type ChangeMethod = (x: ItemID) => void;

/**
 * Represents a function type that is called when the control is touched.
 * @callback TouchMethod
 * @returns {void}
 */
type TouchMethod = () => void;

/**
 * Angular component for a button toggle element.
 * @class
 * @implements {ControlValueAccessor}
*/
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cl-button-toggle',
    standalone: true,
    imports: [
        NgFor,
        AsyncPipe
    ],
    templateUrl: './cl-button-toggle.component.html',
    styleUrls: ['./cl-button-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ClButtonToggleComponent),
            multi: true,
        },
    ],
})
export class ClButtonToggleComponent implements ControlValueAccessor {
    /**
     * A private property representing the selected item ID (if any).
     * @type {ItemID}
     * @private
     * @internal
    */
    private readonly _selected$ = new BehaviorSubject<ItemID>(undefined);

    /**
     * A private property for registering a change callback function for the selected item in the toggle button.
     * @type {ChangeMethod | undefined}
     * @private
     * @internal
    */
    private _registerOnChange: ChangeMethod | undefined;

    /**
     * A private property for registering a touch callback function for the selected item in the toggle button.
     * @type {TouchMethod | undefined}
     * @private
     * @internal
    */
    private _onTouched: TouchMethod | undefined;

    /**
     * Creates an instance of ClButtonToggleComponent.
     * @constructor
     * @param {ChangeDetectorRef} changeDetectorRef - The Angular change detection reference.
     * @internal
    */
    constructor(private readonly changeDetectorRef: ChangeDetectorRef) { }

    /**
     * Emits an event whenever the selected value changes.
     * @type {ItemID}
     * @readonly
     */
    @Output() readonly selectedChange = new EventEmitter<ItemID>();

    /**
    * Radio group name
    * @internal
    */
    readonly name: string = uuid();

    /**
    * Radio group selected item
    * @internal
    */
    readonly selected$ = this._selected$.asObservable();

    /**
     * List of items to be displayed in the toggle button.
     * @type {Entity<ItemID>[]}
    */
    @Input() list: Entity<ItemID>[] = [];

    /**
     * The currently selected item in the toggle button.
     * @type {ItemID}
    */
    @Input()
    get selected(): ItemID {
        return this._selected$.value;
    }
    set selected(value: ItemID) {
        this._selected$.next(value);
    }

    /**
     * Indicates whether the toggle button is disabled.
     * @type {boolean}
    */
    @Input() disabled = false;

    /**
     * Selects an item in the toggle button.
     * @param {ItemID} value The selected item.
    */
    select(value: ItemID): void {
        if (this.disabled) {
            return;
        }

        this.selected = value;
        this._onTouched?.();
        this._registerOnChange?.(this.selected);
        this.selectedChange.emit(this.selected);
    }

    /**
     * Writes a value to the selected item in the toggle button.
     * @param {ItemID} value The value to write.
     * @internal
    */
    writeValue(value: ItemID): void {
        this.selected = value;
    }

    /**
     * Registers a change callback function for the selected item in the toggle button.
     * @param {ChangeMethod} fn The callback function.
     * @internal
    */
    registerOnChange(fn: ChangeMethod): void {
        this._registerOnChange = fn;
    }

    /**
     * Registers a touch callback function for the selected item in the toggle button.
     * @param {TouchMethod} fn The callback function.
     * @internal
    */
    registerOnTouched(fn: TouchMethod): void {
        this._onTouched = fn;
    }

    /**
     * Sets the disabled state of the toggle button.
     * @param {boolean} disabled The disabled state.
     * @internal
    */
    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
        this.changeDetectorRef.markForCheck();
    }
}
