
import { CoreSelectors, Points, WellKnownParams } from '@cyberloop/core';
import { PlanningVersionSelectors } from '@cyberloop/web/planning/shared/data';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, map, of, switchMap } from 'rxjs';

import * as moment from 'moment';

import { KpiSelectors } from '../../state/kpi/kpi.selectors';
import { KpiDataService } from '../kpi-data.service';
import { getPointsFromTagHistoryData } from './utils';

import type { SectionInput } from '@cyberloop/core';
import type { DaysVsDepthWidgetData, DaysVsDepthWidgetSettings } from '@cyberloop/web/wells/model';
import type { TagHistoryInterval } from './utils';

export function getDaysVsDepthWidgetData(this: { store: Store, data: KpiDataService }, id: string): Observable<DaysVsDepthWidgetData> {
    return combineLatest([
        this.store.select(CoreSelectors.currentWell),
        this.store.select(KpiSelectors.widgetSettings<DaysVsDepthWidgetSettings>(id))
    ]).pipe(
        switchMap(([well, settings]) => {
            if (!well) {
                return EMPTY;
            }

            const since = well.startTime;
            const until = well.releaseTime ?? well.suspendTime ?? new Date();

            const step = 3600;

            return combineLatest([
                this.data.watchTagTimeHistory(
                    well, [ WellKnownParams.WDE, WellKnownParams.BDE ], step, since, until
                ),
                settings?.showPlan || settings?.showPlanLimit
                    ? this.store.select(PlanningVersionSelectors.versionStages)
                    : of([])
            ]).pipe(
                map(([historyData, planData]) => {
                    let wdeData: TagHistoryInterval[] = [];
                    let bdeData: TagHistoryInterval[] = [];

                    for (const item of historyData) {
                        switch (item.tagName) {

                            case WellKnownParams.WDE:
                                wdeData = item.intervals;
                                break;

                            case WellKnownParams.BDE:
                                bdeData = item.intervals;
                                break;

                            default:
                                break;
                        }
                    }

                    const wde: Points = getPointsFromTagHistoryData(step, wdeData);
                    const bde: Points = getPointsFromTagHistoryData(step, bdeData);

                    const sections: SectionInput[] = [];

                    for (let i = 0; i < well.sections.length; i++) {
                        const section = well.sections[i];

                        let startTime = section.startTime;

                        // Fix bad start times

                        if (startTime.getFullYear() < 1970) {
                            // Make it the same as well start time
                            startTime = well.startTime;
                        }
                        if (startTime.getFullYear() < 1970 && wde.length > 0) {
                            // Set it to the first WDE point time
                            startTime = new Date(wde[0].x);
                        }

                        sections.push({
                            id: section.id.toString(),
                            name: section.name,
                            value: startTime.getTime()
                        });
                    }

                    const plan: Points = [];
                    const planLimit: Points = [];

                    if (planData && planData.length > 0) {
                        const startTime = moment(well.startTime);

                        plan.push({
                            x: startTime.valueOf(),
                            y: 0
                        });

                        const startTimeLimit = moment(well.startTime);

                        planLimit.push({
                            x: startTimeLimit.valueOf(),
                            y: 0
                        });

                        for (const item of planData) {
                            startTime.add(item.estTimeHours, 'hours');

                            plan.push({
                                x: startTime.valueOf(),
                                y: item.endDepth
                            });

                            startTimeLimit.add(item.techLimitHours, 'hours');

                            planLimit.push({
                                x: startTimeLimit.valueOf(),
                                y: item.endDepth
                            });
                        }
                    }

                    const data: DaysVsDepthWidgetData = {
                        wde,
                        bde,
                        plan,
                        planLimit,
                        sections
                    };

                    return data;
                })
            );
        })
    );
}
