import { FirebaseApp } from '@firebase/app';
import { Database, getDatabase } from '@firebase/database';

export class ClDatabase {
    private _isDisposed = false;
    
    private constructor(private readonly db: Database) {

    }

    static create(app: FirebaseApp): ClDatabase {
        const db = getDatabase(app);
        return new ClDatabase(db);
    }

    dispose() {
        this._isDisposed = true;
    }
}