import { Moment } from 'moment';

/** Type of data to prompt */
export enum PromptDialogControlType {
    /** Textual data */
    Text = 'text',
    /** Numeric data */
    Number = 'number',
    /** Date data */
    Date = 'date'
}

/** Prompt dialog data */
export type PromptDialogData<T = ValueType> = {
    /** Type of data to get */
    type?: PromptDialogControlType;
    /** Default value */
    value?: T | null;
    /** Title of dialog */
    title?: string;
    /** Placeholder to be shown */
    placeholder?: string;
    /** Text for SAVE button */
    saveText?: string;
    /** Is the value required */
    required?: boolean;
}

/** Prompt value type */
export type ValueType = string | number | Moment | null;
