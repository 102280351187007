import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';

import { Range } from '@cyberloop/core';
import { isEqual, isNil } from 'lodash';
import { Observable, Subject, Subscription, combineLatest, debounceTime, distinctUntilChanged, first, map, of, switchMap, tap } from 'rxjs';

import * as Highcharts from 'highcharts';

import { Point, Points, SimpleChangesOf } from '../../models';
import { ChartEx, HcHelperService, HighchartsOptions, SeriesEx } from '../../services';
import { deepDiff, observeResize } from '../../utils';

/** Wellknowning hc series */
const hcTypes = ['line', 'spline', 'area', 'areaspline', 'scatter', 'variwide'];

/**
 * Wrapper for Chart library
 */
@Component({
    selector: 'cyberloop-chart',
    template: `<div #container></div>`,
    styleUrls: ['./chart.component.scss'],
    standalone: true
})
export class ClChartComponent implements AfterViewInit, OnDestroy, OnChanges {
    /** @private */
    private _container?: ElementRef<HTMLDivElement>;
    /** @private */
    private _resizeSub?: Subscription;
    /** @private */
    private _chart: ChartEx | undefined;
    /** @private */
    private _plotBandSub: Subscription | undefined;

    /**
     * @private
     * We'll use it to manage series data subscriptions. Once we `addSeries()`
     * or `setSeriesData()` with an observable data, we store the subscription
     * here and unsubscribe from it on series `remove()` or another
     * `setSeriesData()` call.
     */
    private readonly _seriesDataSubscriptions: Record<string, Subscription> = {};

    /** @private */
    private readonly _chartRedrawRequest$ = new Subject<void>();
    /** @private */
    private readonly _setExtremesRequest$ = new Subject<{ min: number, max: number }>();
    /** @private */
    private _redrawSubscr: Subscription | undefined;
    /** @private */
    private _setExtremesSubscr: Subscription | undefined;
    /** @private */
    private _isInitialized = false;
    /** @private */
    // eslint-disable-next-line @typescript-eslint/ban-types
    private _handlers: Function[] = [];
    /** @private */
    private _unbindStartEvents?: () => void;

    /** @internal */
    constructor(
        private readonly hc: HcHelperService
    ) {

    }

    /** Options for a chart */
    @Input() options: HighchartsOptions | null = {};

    /** This event is fired when chart is created */
    @Output() readonly loaded = new EventEmitter<ChartEx>();

    /** @internal */
    @ViewChild('container', { static: true })
    private get container(): ElementRef<HTMLDivElement> | undefined {
        return this._container;
    }
    private set container(value: ElementRef<HTMLDivElement> | undefined) {
        this._container = value;

        this._resizeSub?.unsubscribe();

        if (this._container) {
            this._resizeSub = observeResize([this._container.nativeElement])
                .subscribe(this.updateSize.bind(this));
        }
    }

    /**
     * A collection of the X axes in the chart.
     */
    get xAxis() {
        return this._chart?.xAxis ?? [];
    }

    /**
     * A collection of the Y axes in the chart.
     */
    get yAxis() {
        return this._chart?.yAxis ?? [];
    }

    /**
     * All the current series in the chart.
     */
    get series() {
        return this._chart?.series ?? [];
    }

    /**
     * Return chart object.
     */
    get chart() {
        return this._chart;
    }

    /**
     * Return native element
     */
    get chartContainer() {
        return this.container?.nativeElement;
    }

    /** @internal */
    ngAfterViewInit(): void {
        this.createChart();
    }

    reCreateChart(): void {
        this.destroyChart();

        this._isInitialized = false;

        this.createChart();
    }

    /** @internal */
    createChart() {
        const el = this.container?.nativeElement;
        if (!el) {
            throw new Error('Could not find chart container');
        }

        const chart = this._chart = this.hc.create(el, this.options ?? {});
        this.addChartRotation(this._chart, Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);

        if (!chart) {
            return;
        }

        // Let's group all redraw requests and debounce them
        this._redrawSubscr = this._chartRedrawRequest$.pipe(
            debounceTime(100)
        ).subscribe(() => {
            if (chart.isDestroyed) {
                return;
            }

            chart.redraw(false);
        });

        this._setExtremesSubscr = this._setExtremesRequest$.pipe(
            debounceTime(300),
        ).subscribe(({ min, max }) => {
            if (chart.isDestroyed) {
                return;
            }

            try {
                chart?.xAxis[0]?.setExtremes(min, max, true);

                // FIXME Sometimes vary chart doesn't update extremes
                const ext = chart.xAxis[0].getExtremes();
                if (Math.abs(ext.min - min) > 10e-2 || Math.abs(ext.max - max) > 10e-2) {
                    chart.update({ xAxis: { min, max } }, true);
                }
            } catch (e) {
                if (e instanceof Error) {
                    console.warn('setExtremes ', e.message);
                } else {
                    console.warn('setExtremes ', e);
                }
            }

        });

        this._isInitialized = true;

        // Let's inform outer world that chart is ready to go
        this.loaded.emit(this._chart);
    }

    /** @internal */
    ngOnChanges(changes: SimpleChangesOf<ClChartComponent>): void {
        const chart = this._chart;
        if (!chart) {
            return;
        }

        const optionsChanges = changes.options;
        let redrawPending = false;
        if (optionsChanges) {
            if (optionsChanges.previousValue) {
                const changes = deepDiff(optionsChanges.previousValue, optionsChanges.currentValue ?? {});
                if (Object.keys(changes).length) {
                    chart.update(changes, false);
                    redrawPending = true;
                }
            }
        }

        if (redrawPending) {
            this._chartRedrawRequest$.next();
        }
    }

    /** @internal */
    ngOnDestroy(): void {
        this._plotBandSub?.unsubscribe()
        this.unbindAll();
        this.destroyChart();
    }

    /** @internal */
    destroyChart(): void {
        if (this._chart) {
            for (const series of this._chart.series) {
                this.deleteSeriesDataSubscription(series);
            }
            this._chart.destroy();
            this._chart = undefined;
        }

        if (this._redrawSubscr) {
            this._redrawSubscr.unsubscribe();
            this._redrawSubscr = undefined;
        }

        if (this._setExtremesSubscr) {
            this._setExtremesSubscr.unsubscribe();
            this._setExtremesSubscr = undefined;
        }

        if (this._resizeSub) {
            this._resizeSub.unsubscribe();
            this._resizeSub = undefined;
        }
    }

    /**
     * Add axis to chart
     * @param options Axis options
     * @param isX true if series is X axis
     * @param timeout Timeout for promise to be resolved
     * @returns
     */
    addAxis(options: Highcharts.AxisOptions, isX?: boolean, timeout = 5000): Promise<Highcharts.Axis> {
        return this.invokeWhenChartIsReady(chart => {
            const axis = chart.addAxis(options, isX, false, false);
            return axis;
        }, timeout);
    }

    /**
     * Add series and attach data to it
     * @param options Series options
     * @param data Array of points or observable of points to be used
     * @param timeout Timeout for promise to be resolved
     * @returns
     */
    addSeries<T extends Highcharts.SeriesOptionsType>(options: T, data?: Highcharts.PointOptionsType[] | Observable<Highcharts.PointOptionsType[]>, vp?: Observable<Range | undefined>, timeout = 5000): Promise<Highcharts.Series> {
        return this.invokeWhenChartIsReady(chart => {
            const series = chart.addSeries(options, false);
            if (data) {
                if (Array.isArray(data)) {
                    series.setData(data, false);
                }
                else {
                    this.attachData(series, data, vp);
                }
            }
            return series;
        }, timeout);
    }

    /**
     * Remove series
     * @param id Series ID
     * @param timeout Timeout for promise to be resolved
     */
    removeSeries(id: string, timeout = 5000): Promise<void> {
        return this.invokeWhenChartIsReady(chart => {
            const series = chart.get(id);

            if (!series) {
                return;
            }
            else if (!(series instanceof Highcharts.Series)) {
                throw new Error(`You're trying to remove series by ID "${id}", but this ID is taken by a non-series object`);
            }

            series.remove(false, false);

            this._chartRedrawRequest$.next();
        }, timeout);
    }

    /**
     * Remove all X Axes
     * @param timeout Timeout for promise to be resolved
     */
    removeAllXAxes(timeout = 5000): Promise<void> {
        return this.invokeWhenChartIsReady(chart => {
            for (const axis of chart.xAxis) {
                axis.remove(false);
            }

            this._chartRedrawRequest$.next();
        }, timeout);
    }

    /**
     * Remove all Y Axes
     * @param timeout Timeout for promise to be resolved
     */
    removeAllYAxes(timeout = 5000): Promise<void> {
        return this.invokeWhenChartIsReady(chart => {
            for (const axis of chart.yAxis) {
                axis.remove(false);
            }

            this._chartRedrawRequest$.next();
        }, timeout);
    }

    /**
     * Remove all series
     * @param timeout Timeout for promise to be resolved
     */
    removeAllSeries(timeout = 5000): Promise<void> {
        return this.invokeWhenChartIsReady(chart => {
            for (const series of chart.series) {
                series.remove(false, false);
            }

            this._chartRedrawRequest$.next();
        }, timeout);
    }

    /**
     * Allows to set data for an existing series without the need to recreating
     * it. Supports observables.
     * @param id Series ID to set data of
     * @param data The data to set
     * @param updatePoints When this is true, Highcharts will update points instead of replace whenever possible. This occurs a) when the updated data is the same length as the existing data, b) when points are matched by their id's, or c) when points can be matched by X values. This allows updating with animation and performs better. In this case, the original array is not passed by reference. Set false to prevent.
     */
    setSeriesData(id: string, data: Highcharts.PointOptionsType[] | Observable<Highcharts.PointOptionsType[]>, timeout = 5000, updatePoints?: boolean): Promise<void> {
        return this.invokeWhenChartIsReady(chart => {
            const series = chart.get(id) as Highcharts.Series | undefined;

            if (!series) {
                throw new Error(`Series with ID ${id} does not exists`);
            }
            else if (!(series instanceof Highcharts.Series)) {
                throw new Error(`You're trying to set series data by ID "${id}", but this ID is taken by a non-series object`);
            }

            // Delete an existing data subscription (if any)
            this.deleteSeriesDataSubscription(series);

            if (Array.isArray(data)) {
                series.setData(data, false, false, updatePoints);

                this._chartRedrawRequest$.next();
            }
            else {
                // Subscribe to the data
                const subscription = this.createSeriesDataSubscription(series, data);
                this.setSeriesDataSubscription(series, subscription);
            }

        }, timeout);
    }

    /**
     * Returns all plot lines and bands of an Y axis.
     * @param axisId The specific axis ID. If not specified, yAxis[0] will be used.
     * @returns The array of plot lines and bands.
     */
    getYAxisPlotLinesAndBands(axisId?: string): Highcharts.PlotLineOrBand[] {
        let axis: Highcharts.Axis | undefined = undefined;

        if (axisId) {
            axis = this._chart?.get(axisId) as Highcharts.Axis;

            if (!(axis instanceof Highcharts.Axis)) {
                axis = undefined;
            }
        }
        else {
            axis = this._chart && this._chart.yAxis.length > 0
                ? this._chart.yAxis[0]
                : undefined;
        }

        // Dirty hack but idk how to do it other way
        return (axis as any)?.plotLinesAndBands ?? [];
    }

    /**
     * Returns a single plot line or band on Y axis by its ID.
     * @param id The specific line or band ID.
     * @param axisId The specific axis ID. If not specified, yAxis[0] will be used.
     * @returns The plot line or band.
     */
    getYAxisPlotLineOrBand(id: string, axisId?: string): Highcharts.PlotLineOrBand | undefined {
        const linesAndBands = this.getYAxisPlotLinesAndBands(axisId);

        // Another dirty hack
        return linesAndBands.find(item => (item as any).id === id);
    }

    /**
     * Update chart options
     * @param options Partial options to be set
     */
    update(options: HighchartsOptions): void {
        if (options.chart && 'inverted' in options.chart) {
            const { min, max } = this._chart?.xAxis?.[0]?.getExtremes() ?? {};
            setTimeout(() => this._chart?.update({ xAxis: { min, max } }, true));
        }

        this._chart?.update(options, false);
        this._chartRedrawRequest$.next();
    }

    /** redraw chart */
    redraw(): void {
        this._chartRedrawRequest$.next();
    }

    /** unsub series */
    unsubSeries(series: Highcharts.Series) {
        this.deleteSeriesDataSubscription(series);
    }

    /**
     * Updates a point by it's ID. Used mostly to update pie chart segments.
     * @param id
     * @param options
     */
    updatePoint(id: string, options: Highcharts.PointOptionsType): void {
        const point = this._chart?.get(id);

        if (!isNil(point) && !(point instanceof Highcharts.Point)) {
            throw new Error(`You're trying to update a point by ID "${id}", but this ID is taken by a non-point object`);
        }

        if (point) {
            point.update(options, false);
            this._chartRedrawRequest$.next();
        }
    }

    /**
     * Updates the chart size according to the container's size
     */
    updateSize(): void {
        this._chart?.setSize(null, null);

        const am = requestAnimationFrame(() => {
            this._chart?.setSize(null, null);
            cancelAnimationFrame(am);
        });
    }

    addPlotBand(plotBand: Observable<Range | undefined>): void {
        this._plotBandSub = plotBand.pipe(
            distinctUntilChanged()
        ).subscribe(range => {
            if (range) {
                this.chart?.xAxis[0].removePlotBand('naviBand')
                this.chart?.xAxis[0].addPlotBand({
                    id: 'naviBand',
                    from: range.from,
                    to: range.to,
                    zIndex: 10
                });
            }
        });
    }

    /** @private */
    private attachData(series: SeriesEx, data: Observable<Highcharts.PointOptionsType[]>, vp?: Observable<Range | undefined>): void {
        // const self = this;
        // const orig = series.remove;

        const subscription = this.createSeriesDataSubscription(series, data, vp);
        this.setSeriesDataSubscription(series, subscription);

        const hook = series.removeHook;

        series.removeHook = (redraw?: boolean, animation?: (boolean | Partial<Highcharts.AnimationOptionsObject>), withEvent?: boolean, internal?: boolean, ...args: any[]) => {
            hook?.();

            console.warn('SERIES REMOVED ', series.options.id);
            if (!internal) {
                // restore orig function
                // series.removeHook = hook;
                // unsubscribe
                this.deleteSeriesDataSubscription(series);
            }
            // return .call(this, redraw, animation, withEvent);
        }
    }

    /** @private */
    private invokeWhenChartIsReady<T>(fn: (chart: ChartEx) => T, timeout: number) {
        if (this._isInitialized && !this._chart) {
            throw new Error('Chart has been destroyed already');
        }

        return new Promise<T>((resolve, reject) => {
            const timeoutHandler = setTimeout(() => reject('Adding new series took too much time'), timeout);

            const f = (chart: ChartEx) => {
                clearTimeout(timeoutHandler);

                const result = fn(chart);
                this._chartRedrawRequest$.next();
                resolve(result);
            };

            const chart = this._chart;
            if (chart) {
                f(chart);
            }
            else {
                this.loaded.pipe(
                    first()
                ).subscribe(f);
            }
        });
    }

    // --- Utils ---

    private createSeriesDataSubscription(series: Highcharts.Series, data: Observable<Highcharts.PointOptionsType[]>, vp: Observable<Range | undefined> = of(undefined)): Subscription {
        if (!series) {
            throw new Error('Series not exist');
        }

        const sub = data.pipe(
            switchMap(ppts => vp.pipe(
                    map(range => ({ ppts, range })),
                    debounceTime(400),
                    distinctUntilChanged(isEqual),
                    first()
                )
            )
        ).subscribe(({ ppts, range }) => {
            if (!series.chart) {
                sub.unsubscribe();
                return;
            }

            // Live data check
            const oldData = series.data;
            const lastPoint = oldData?.[oldData.length - 1]?.x;
            const firstPoint = ppts[0]?.x ?? ppts[0]?.[0];
            const liveMaybe = ppts.length < 5 && oldData.length > 4;

            if (liveMaybe) {
                if (Array.isArray(oldData) && lastPoint < firstPoint) {
                    for (const point of ppts) {
                        series.addPoint(point, true)
                    }

                    const min = range.from;
                    const max = range.to;

                    if (!isNil(min) && !isNil(max)) {
                        this._setExtremesRequest$.next({ min, max });
                    }

                    return;
                } else {
                    return;
                }
            }

            // console.warn('NEW DATA FOR SERIES', ppts, series.options.id);
            const points = ppts as ([number, number][] | Points);

            if (!this.options?.disableUpdateExtremes && hcTypes.includes(series.type)) {
                const len = ppts.length;
                let first: [number, number] | Point;
                let last: [number, number] | Point;

                if (this.options?.chart?.panning?.enabled) {
                    first = len > 2 ? points[1] : points[0];
                    last = len > 2 ? points[len - 2] : points[len - 1];
                }
                else {
                    first = points[0];
                    last = points[len - 1];
                }

                const min = typeof range?.from === 'number' ? range.from : (Array.isArray(first) ? first[0] : first?.x);
                const max = typeof range?.to === 'number' ? range.to : (Array.isArray(last) ? last[0] : last?.x);

                const xAxis = series.xAxis ?? series?.chart?.xAxis[0];
                const extremes = xAxis?.getExtremes();

                if (Number.isFinite(min) && Number.isFinite(max) &&
                    (extremes?.min !== min || extremes?.max !== max)) {
                    setTimeout(() => {
                        this._setExtremesRequest$.next({ min, max });
                    });
                }

            }

            try {
                // DATASORTING ERROR IF SERIES EMPTY
                series.setData(points, true, false, true);
                // series.setData(points, false, false, true);
                // this._chartRedrawRequest$.next();
            } catch (e) {
                if (e instanceof Error) {
                    console.warn('setData ', series?.options?.id, e.message)
                } else {
                    console.warn('setData ', series?.options?.id, e)
                }
            }
        });

        return sub;
    }

    private getSeriesUniqueId(series: Highcharts.Series): string {
        const parts: string[] = [];

        if (series.userOptions.id) {
            parts.push(series.userOptions.id);
        }

        parts.push(series.type);
        parts.push(series.getName());

        return parts.join('-');
    }

    private getSeriesDataSubscription(series: Highcharts.Series): Subscription | undefined {
        return this._seriesDataSubscriptions[this.getSeriesUniqueId(series)];
    }
    private setSeriesDataSubscription(series: Highcharts.Series, sub: Subscription): void {
        const seriesId = this.getSeriesUniqueId(series);

        if (this._seriesDataSubscriptions[seriesId]) {
            // Unsubscribe previous first
            this._seriesDataSubscriptions[seriesId].unsubscribe();
        }

        this._seriesDataSubscriptions[seriesId] = sub;
    }
    private deleteSeriesDataSubscription(series: Highcharts.Series): void {
        const seriesId = this.getSeriesUniqueId(series);

        if (this._seriesDataSubscriptions[seriesId]) {
            this._seriesDataSubscriptions[seriesId].unsubscribe();
            delete this._seriesDataSubscriptions[seriesId];
        }
    }

    private addChartRotation(chart: Highcharts.Chart, min: number, max: number) {
        // origin - https://www.highcharts.com/demo/3d-scatter-draggable
        this.unbindAll();

        const H = Highcharts;
        const dragStart = (eStart: any) => {
            eStart = chart.pointer.normalize(eStart);

            const posX = eStart.chartX,
                posY = eStart.chartY,
                alpha = chart.options.chart?.options3d?.alpha ?? 0,
                beta = chart.options.chart?.options3d?.beta ?? 0,
                sensitivity = 5;  // lower is more sensitive

            function drag(e: any) {
                // Get e.chartX and e.chartY
                e = chart.pointer.normalize(e);

                chart.update({
                    chart: {
                        options3d: {
                            alpha: alpha + (e.chartY - posY) / sensitivity,
                            beta: beta + (posX - e.chartX) / sensitivity
                        }
                    }
                }, undefined, undefined, false);
            }

            this._handlers.push(H.addEvent(document, 'mousemove', drag));
            this._handlers.push(H.addEvent(document, 'touchmove', drag));


            this._handlers.push(H.addEvent(document, 'mouseup', this.unbindDraggingEvents.bind(this)));
            this._handlers.push(H.addEvent(document, 'touchend', this.unbindDraggingEvents.bind(this)));
        }
        const mouseDown = H.addEvent(chart.container, 'mousedown', dragStart);
        const touchStart = H.addEvent(chart.container, 'touchstart', dragStart);
        this._unbindStartEvents = () => {
            mouseDown();
            touchStart();
        };
    }

    private unbindDraggingEvents() {
        this._handlers.forEach(function (unbind) {
            if (unbind) {
                unbind();
            }
        });
        this._handlers.length = 0;
    }

    private unbindAll() {
        this._unbindStartEvents?.();
        this.unbindDraggingEvents();
    }
}
