import { Injectable } from '@angular/core';

import { GraphQLMutation } from '../../internals/cl-gql';

type TorqueAndDragActivateModelResult = {
    torqndrag: {
        activateModel: {
            succeeded: boolean
        }
    }
}

type TorqueAndDragActivateModelInput = {
    modelId: string,
    wellId?: string
}

@Injectable({
    providedIn: 'root'
})
export class TorqueAndDragActivateModelMutation extends GraphQLMutation<TorqueAndDragActivateModelResult, TorqueAndDragActivateModelInput> {
    override readonly document = `
        mutation ActivateTorqueAndDragModel($modelId: ID!, $wellId: ID) {
            torqndrag {
                activateModel(id: $modelId, wellId: $wellId) {
                    ... on MutationResult {
                        succeeded
                    }
                }
            }
        }
    `;
}