import { Injectable } from '@angular/core';

import { ForecastRequest } from '@cyberloop/core';

import { GraphQLQuery } from '../../internals/cl-gql';

type QueryResponse = {
    getForecastDocument: {
        file: {
            type: 'Buffer';
            data: number[];
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class ForecastReportQuery extends GraphQLQuery<QueryResponse, ForecastRequest> {
    override document = `
    query GetForecastDocument(
        $wellName: String!
        $startDate: Float!
        $estimatedRigReleaseDate: Float!
        $updatedAt: Float!
        $events: [ForecastDocumentEventRequestInput!]!
        $equipmentAndPersonnelEvents: [ForecastDocumentEquipmentAndPersonnelEventRequestInput!]!
      ) {
        getForecastDocument(
          wellName: $wellName
          startDate: $startDate
          estimatedRigReleaseDate: $estimatedRigReleaseDate
          updatedAt: $updatedAt
          events: $events
          equipmentAndPersonnelEvents: $equipmentAndPersonnelEvents
        ) {
          file
        }
      }
    `;
}
