
import { CoreSelectors, Points, WellKnownParams } from '@cyberloop/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, map, of, switchMap, timer } from 'rxjs';

import * as moment from 'moment';

import { KpiDataService } from '../kpi-data.service';
import { getPointsFromTagHistoryData } from './utils';

import type { KpiRigStateSnapshot, RigStatePerHourWidgetData } from '@cyberloop/web/wells/model';
import type { TagHistoryInterval } from './utils';

const STEP = 3600;

export function getRigStatePerHourWidgetData(this: { store: Store, data: KpiDataService }): Observable<RigStatePerHourWidgetData> {
    return this.store.select(CoreSelectors.currentWell).pipe(
        switchMap(well => {
            if (!well) {
                return EMPTY;
            }

            // When was the well ending time
            const borderTime = moment(well.releaseTime ?? well.suspendTime ?? new Date());
            const canHaveData = moment().subtract(24, 'hours').isBefore(borderTime);

            if (!canHaveData) {
                return of('The well is closed more than 24 hours ago');
            }
            else {
                return timer(0, 3600000).pipe( // Refresh once per hour
                    switchMap(() => {
                        const until = moment().endOf('hour');
                        let since = moment().startOf('hour').add(1, 's').subtract(1, 'day').subtract(2, 'hours');

                        if (since.isBefore(well.startTime)) {
                            // Prevent from getting data from previous well
                            since = moment(well.startTime);
                        }

                        return combineLatest([
                            // TODO: Not watch, but fetch
                            this.data.watchRigStateHours(well.rig, since.toDate(), until.toDate()),
                            this.data.watchTagTimeHistory(well, [WellKnownParams.WDE, WellKnownParams.BDE], STEP, since.toDate(), until.toDate())
                        ]).pipe(
                            map(([hours, historyData]) => {
                                // Rig state hours

                                const rigStateHours = {} as Record<number, KpiRigStateSnapshot>;

                                for (const row of hours) {
                                    const snapshot = {} as KpiRigStateSnapshot;

                                    for (const item of row.durations) {
                                        snapshot[item.state] = item.duration;
                                    }

                                    const date = moment.utc(row.hour);

                                    rigStateHours[date.unix()] = snapshot;
                                }

                                // Make sure we have all the hours

                                for (let i = 0; i < 24; i++) {
                                    const m = since.clone().add(i, 'h');

                                    if (!rigStateHours[m.unix()]) {
                                        rigStateHours[m.unix()] = {} as KpiRigStateSnapshot;
                                    }
                                }

                                // WDE, BDE

                                let wdeData: TagHistoryInterval[] = [];
                                let bdeData: TagHistoryInterval[] = [];

                                for (const item of historyData) {
                                    switch (item.tagName) {

                                        case WellKnownParams.WDE:
                                            wdeData = item.intervals;
                                            break;

                                        case WellKnownParams.BDE:
                                            bdeData = item.intervals;
                                            break;

                                        default:
                                            break;
                                    }
                                }

                                const wde: Points = getPointsFromTagHistoryData(STEP, wdeData);
                                const bde: Points = getPointsFromTagHistoryData(STEP, bdeData);

                                const data: RigStatePerHourWidgetData = { data: rigStateHours, wde, bde };
                                return data;
                            })
                        );
                    })
                );
            }
        })
    );
}
