import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { TndEffects } from './tnd.effects';
import { planningEngineeringTndReducer } from './tnd.reducer';
import { PLANNING_ENGINEERING_TND_FEATURE } from './tnd.state';

export const TndStateProviders = [
    provideEffects([TndEffects]),
    provideState(PLANNING_ENGINEERING_TND_FEATURE, planningEngineeringTndReducer)
];