import { ID } from '@cyberloop/core';
import { CreatePlanningVersion, PlanningVersion } from '@cyberloop/web/planning/shared/model';

import type { Observable } from 'rxjs';

/** Base class for planning version provider. Used as Injection token */
export abstract class PlanningVersionProviderService {
    /** Watch list of planning versions */
    abstract watchAll(planningId: ID): Observable<PlanningVersion[]>;

    /** Watch planning version by ID */
    abstract watch(planningId: ID, versionId: ID): Observable<PlanningVersion | null>;

    /** Get planning version by ID */
    abstract get(planningId: ID, versionId: ID): Observable<PlanningVersion | null>;

    /** Create planning version */
    abstract create(planningId: ID, version: CreatePlanningVersion): Observable<PlanningVersion>

    /** Update planning version */
    abstract update(planningId: ID, version: PlanningVersion): Observable<PlanningVersion>;

    /** Replace planning version */
    abstract replace(planningId: ID, version: PlanningVersion): Observable<PlanningVersion>;

    /** Delete planning version by ID */
    abstract delete(planningId: ID, versionId: ID): Observable<PlanningVersion>
}
