export enum WellsTypeFilters {
    Active = 'active',
    Released = 'released',
    Favorite = 'favorite'
} 

export interface WellsFilters {
    type: string[];
    rig: string;
}

export const defaultWellsFilters: WellsFilters = {
    type: [],
    rig: ''
};

export type WellsSettings = {
    favorites: string[];
}

export const defaultWellsSettings: WellsSettings = Object.freeze({
    favorites: []
});