import { Injectable } from '@angular/core';

import { NotificationService } from '@cyberloop/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, createAction } from '@ngrx/store';
import { isNil } from 'lodash';
import { EMPTY, catchError, debounceTime, filter, from, map, of, switchMap, take, takeUntil, tap } from 'rxjs';

import { ForecastService } from '../../services';
import { ForecastDataService } from '../../services/forecast/forecast-data.service';
import { ForecastActions } from './forecast.actions';
import { FORECAST_FEATURE } from './forecast.state';

const initAction = createAction(`[${FORECAST_FEATURE}] Initialize forecast`);

@Injectable()
export class ForecastEffects implements OnInitEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly dataService: ForecastDataService,
        private readonly forecastService: ForecastService,
        private readonly notificationService: NotificationService
    ) { }

    //#region Common
    readonly handleError$ = createEffect(() => this.actions$.pipe(
        ofType(
            ForecastActions.loadForecastFailure,
            ForecastActions.loadEventListFailure,
            ForecastActions.createEventFailure,
            ForecastActions.updateEventFailure,
            ForecastActions.expandToNextEventFailure,
            ForecastActions.deleteEventFailure,
            ForecastActions.importFromWellPlanFailure,
            ForecastActions.exportFailure,
            ForecastActions.loadEquipmentAndPersonnelEventListFailure,
            ForecastActions.createEquipmentAndPersonnelEventFailure,
            ForecastActions.updateEquipmentAndPersonnelEventFailure
        ),
        tap(({ error }) => this.notificationService.error(error as any))
    ), { dispatch: false });
    //#endregion Common

    //#region Forecast

    readonly loadForecastByWellId$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.loadForecast),
        debounceTime(1000),
        switchMap(() => this.forecastService.wellId$.pipe(
            takeUntil(this.actions$.pipe(ofType(ForecastActions.unwatch))),
            filter(Boolean),
            switchMap((wellId) => this.dataService.watchByWellId(wellId).pipe(
                map(forecast => ForecastActions.loadForecastSuccess({ forecast })),
                catchError(error => [ForecastActions.loadForecastFailure({ error })])
            ))
        ))
    ));

    //#region Import from Well plan
    readonly tryImportFromWellPlan$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.tryImportFromWellPlan),
        switchMap(() => this.forecastService.forecastId$.pipe(
            take(1),
            switchMap((forecastId) => isNil(forecastId) ? of(true) : this.forecastService.confirmImportFromWellPlan()),
            filter(Boolean),
            map(() => ForecastActions.importFromWellPlan())
        ))
    ));

    readonly importFromWellPlan$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.importFromWellPlan),
        debounceTime(1000),
        switchMap(() => this.forecastService.wellId$.pipe(
            take(1),
            filter(Boolean),
            switchMap((wellId) => this.dataService.importFromWellPlanByWellID(wellId).pipe(
                map(forecast => ForecastActions.importFromWellPlanSuccess({ forecast })),
                catchError(error => [ForecastActions.importFromWellPlanFailure({ error })])
            ))
        ))
    ));

    readonly importFromWellPlanSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.importFromWellPlanSuccess),
        tap(() => this.notificationService.info('Well Plan data imported'))
    ), { dispatch: false });
    //#endregion Import from Well plan

    //#endregion Forecast

    //#region Event

    readonly resetEditableEventId$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.updateEvent),
        map(() => ForecastActions.setEditableEventId({ eventId: null }))
    ));

    //#region List
    readonly loadEventListAfterForecastLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.loadForecastSuccess),
        switchMap(() => this.forecastService.forecastId$.pipe(
            filter(Boolean),
            map((forecastId) => ForecastActions.loadEventList({ forecastId }))
        ))
    ));

    readonly loadEventList$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.loadEventList),
        switchMap(({ forecastId }) => this.dataService.watchAllEvents(forecastId).pipe(
            takeUntil(this.actions$.pipe(ofType(ForecastActions.unwatch))),
            map(eventList => ForecastActions.loadEventListSuccess({ eventList })),
            catchError(error => [ForecastActions.loadEventListFailure({ error })])
        ))
    ));
    //#endregion List

    //#region Create
    readonly createEvent$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.createEvent),
        switchMap(({ createEvent }) => this.forecastService.forecastId$.pipe(
            take(1),
            switchMap((forecastId) => {
                if (isNil(forecastId)) {
                    return EMPTY;
                }

                return this.dataService.createEvent(forecastId, createEvent).pipe(
                    map((createdEvent) => ForecastActions.createEventSuccess({ createdEvent })),
                    catchError(error => [ForecastActions.createEventFailure({ error })])
                );
            })
        ))
    ));
    readonly createEventSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.createEventSuccess),
        map(({ createdEvent }) => ForecastActions.setEditableEventId({ eventId: createdEvent.id }))
    ));
    //#endregion Create

    //#region Update
    readonly updateEvent$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.updateEvent),
        switchMap(({ eventId, updateEvent }) => this.forecastService.forecastId$.pipe(
            take(1),
            switchMap((forecastId) => {
                if (isNil(forecastId)) {
                    return EMPTY;
                }

                return this.dataService.updateEvent(forecastId, eventId, updateEvent).pipe(
                    map((updatedEvent) => ForecastActions.updateEventSuccess({ updatedEvent })),
                    catchError(error => [ForecastActions.updateEventFailure({ error })])
                );
            })
        ))
    ));

    readonly expandToNextEvent$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.expandToNextEvent),
        switchMap(({ eventId }) => this.forecastService.forecastId$.pipe(
            take(1),
            switchMap((forecastId) => {
                if (isNil(forecastId)) {
                    return EMPTY;
                }

                return this.dataService.expandToNextEvent(forecastId, eventId).pipe(
                    map((updatedEvent) => ForecastActions.expandToNextEventSuccess({ updatedEvent })),
                    catchError(error => [ForecastActions.expandToNextEventFailure({ error })])
                );
            })
        ))
    ));
    //#endregion Update

    //#region Delete
    readonly tryDeleteEvent$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.tryDeleteEvent),
        switchMap(({ eventId }) => from(this.forecastService.confirmDeleteEvent()).pipe(
            filter(Boolean),
            map(() => ForecastActions.deleteEvent({ eventId }))
        ))
    ));

    readonly deleteEvent$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.deleteEvent),
        switchMap(({ eventId }) => this.forecastService.forecastId$.pipe(
            take(1),
            switchMap((forecastId) => {
                if (isNil(forecastId)) {
                    return EMPTY;
                }

                return this.dataService.deleteEvent(forecastId, eventId).pipe(
                    map((deletedEvent) => ForecastActions.deleteEventSuccess({ deletedEvent })),
                    catchError(error => [ForecastActions.deleteEventFailure({ error })])
                );
            })
        ))
    ));
    //#endregion Delete

    //#endregion Event

    //#region Equipment and Personnel

    //#region List
    readonly loadEquipmentAndPersonnelEventListAfterForecastLoaded$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.loadForecastSuccess),
        switchMap(() => this.forecastService.forecastId$.pipe(
            filter(Boolean),
            map((forecastId) => ForecastActions.loadEquipmentAndPersonnelEventList({ forecastId }))
        ))
    ));

    readonly loadEquipmentAndPersonnelEventList$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.loadEquipmentAndPersonnelEventList),
        switchMap(({ forecastId }) => this.dataService.watchAllEquipmentAndPersonnelEvents(forecastId).pipe(
            takeUntil(this.actions$.pipe(ofType(ForecastActions.unwatch))),
            map(equipmentAndPersonnelEventList => ForecastActions.loadEquipmentAndPersonnelEventListSuccess({ equipmentAndPersonnelEventList })),
            catchError(error => [ForecastActions.loadEquipmentAndPersonnelEventListFailure({ error })])
        ))
    ));
    //#endregion List

    //#region Create
    readonly createEquipmentAndPersonnelEvent$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.createEquipmentAndPersonnelEvent),
        switchMap(({ createEquipmentAndPersonnelEvent }) => this.forecastService.forecastId$.pipe(
            take(1),
            switchMap((forecastId) => {
                if (isNil(forecastId)) {
                    return EMPTY;
                }

                return this.dataService.createEquipmentAndPersonnelEvent(forecastId, createEquipmentAndPersonnelEvent).pipe(
                    map((createdEquipmentAndPersonnelEvent) => ForecastActions.createEquipmentAndPersonnelEventSuccess({ createdEquipmentAndPersonnelEvent })),
                    catchError(error => [ForecastActions.createEquipmentAndPersonnelEventFailure({ error })])
                );
            })
        ))
    ));
    //#endregion Create

    //#region Update
    readonly updateEquipmentAndPersonnelEvent$ = createEffect(() => this.actions$.pipe(
        ofType(ForecastActions.updateEquipmentAndPersonnelEvent),
        switchMap(({ equipmentAndPersonnelEventId, updateEquipmentAndPersonnelEvent }) => this.forecastService.forecastId$.pipe(
            take(1),
            switchMap((forecastId) => {
                if (isNil(forecastId)) {
                    return EMPTY;
                }

                return this.dataService.updateEquipmentAndPersonnelEvent(forecastId, equipmentAndPersonnelEventId, updateEquipmentAndPersonnelEvent).pipe(
                    map((updatedEquipmentAndPersonnelEvent) => ForecastActions.updateEquipmentAndPersonnelEventSuccess({ updatedEquipmentAndPersonnelEvent })),
                    catchError(error => [ForecastActions.updateEquipmentAndPersonnelEventFailure({ error })])
                );
            })
        ))
    ));
    //#endregion Update

    //#endregion Equipment and Personnel

    ngrxOnInitEffects(): Action {
        return initAction();
    }
}
