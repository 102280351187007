<div class="gauge-wrapper">
  <div class="traces-container-left">
    <ng-container *ngFor="let trace of leftColumnOfTraces; let i = index">
      <cyberloop-dd-trace *ngIf="trace" [title]="trace.title" 
      [label]="trace.unitLabel" [value]="getTraceValue(trace) | async" (remove)="onRemoveTrace(trace.id)"></cyberloop-dd-trace>
      
      <button *ngIf="!trace" class="add-trace" (click)="onAddTrace(i)">add trace</button>
    </ng-container>
  </div>
  <div class="traces-container-right">
    <ng-container *ngFor="let trace of rightColumnOfTraces; let i = index">
      <cyberloop-dd-trace *ngIf="trace" [title]="trace.title" 
      [label]="trace.unitLabel" [value]="getTraceValue(trace) | async" (remove)="onRemoveTrace(trace.id)"></cyberloop-dd-trace>
      
      <button *ngIf="!trace" class="add-trace" (click)="onAddTrace(i, true)">add trace</button>
    </ng-container>
  </div>
  <div class="gauge-scalable-container">
      <div class="gauge-container">
          <scalable-content mode="fit">
            <cyberloop-chart [options]="getOptions()" class="chart"></cyberloop-chart>
            
            <div class="current-value-item">{{ currentValue.value | noData }}</div>
            <div class="tf-value-item">{{ currentValue.sourceName | noData }}</div>
          </scalable-content>
  
          <div class="indicator-container bottom-left">
            <span class="title">INC</span>
            <div class="value">{{ inc | fromSi:angleUnit | formatNumber | noData }}</div>
          </div>
  
          <div class="indicator-container bottom-right">
            <span class="title">AZI</span>
            <div class="value">{{ azi | fromSi:angleUnit | formatNumber | noData }}</div>
          </div>
  
          <div class="pumps-indicator" [class.on]="getPumpsIsOn() | async">
            <div class="value">pumps<br>{{(getPumpsIsOn() | async) ? 'on' : 'off'}}</div>
            <div class="bg"></div>
          </div>
      </div>
  </div>
</div>
