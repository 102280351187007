import { Injectable } from '@angular/core';

import { NotificationService } from '@cyberloop/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { catchError, filter, from, map, switchMap, takeUntil, tap } from 'rxjs';

import { FilesDataService } from '../../services/files-data.service';
import { FilesService } from '../../services/files.service';
import { FilesTagActions } from './tag.actions';
import { FILES_TAG_FEATURE } from './tag.state';

const initAction = createAction(`[${FILES_TAG_FEATURE}] Initialize files tag`);

@Injectable()
export class FilesTagEffects implements OnInitEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly filesService: FilesService,
        private readonly filesDataService: FilesDataService,
        private readonly notificationService: NotificationService
    ) { }

    //#region Common
    readonly handleError$ = createEffect(() => this.actions$.pipe(
        ofType(
            FilesTagActions.loadListFailure,
            FilesTagActions.createFailure
        ),
        tap(({ error }) => this.notificationService.error(error))
    ), { dispatch: false });
    //#endregion Common

    //#region List
    readonly watchAllTags$ = createEffect(() => this.actions$.pipe(
        ofType(FilesTagActions.loadList),
        switchMap(() => this.filesDataService.watchAllTags().pipe(
            takeUntil(this.actions$.pipe(ofType(FilesTagActions.unwatchList))),
            map(list => FilesTagActions.loadListSuccess({ list })),
            catchError(error => [FilesTagActions.loadListFailure({ error })])
        ))
    ));
    //#endregion List

    //#region Add
    readonly openAddTagPopup$ = createEffect(() => this.actions$.pipe(
        ofType(FilesTagActions.openCreatePopup),
        switchMap(() => from(this.filesService.openAddTagPopup()).pipe(
            filter(Boolean),
            map(tagName => FilesTagActions.create({ name: tagName }))
        ))
    ));

    readonly createTag$ = createEffect(() => this.actions$.pipe(
        ofType(FilesTagActions.create),
        switchMap(({ name: tagName }) => this.filesDataService.createTag(tagName).pipe(
            map(tag => FilesTagActions.createSuccess({ tag })),
            catchError(error => [FilesTagActions.createFailure({ error })])
        ))
    ));

    readonly showTagAddedSuccessNotification$ = createEffect(() => this.actions$.pipe(
        ofType(FilesTagActions.createSuccess),
        tap(({ tag }) => this.notificationService.info(`Tag '${tag.name}' added`))
    ), { dispatch: false });
    //#endregion Add

    ngrxOnInitEffects(): Action {
        return initAction();
    }
}
