import { ID } from '@cyberloop/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNil } from 'lodash';

import { FILES_FEATURE, FilesState, adapter } from './files.state';
import { FilesTagSelectors } from './tag/tag.selectors';

const feature = createFeatureSelector<FilesState>(FILES_FEATURE);
const { selectAll, selectEntities } = adapter.getSelectors();

const list = createSelector(feature, selectAll);
const dictionary = createSelector(feature, selectEntities);
const listLoading = createSelector(feature, state => state.listLoading);

const selectList = ({ wellId, planningId }: { wellId?: ID, planningId?: ID }) =>
    createSelector(list, state => {
        if (isNil(wellId) && isNil(planningId)) {
            return [];
        }

        if (!isNil(wellId) && isNil(planningId)) {
            return state.filter(x => x.wellId === wellId);
        }

        if (isNil(wellId) && !isNil(planningId)) {
            return state.filter(x => x.planningId === planningId);
        }

        return state.filter(x => x.wellId === wellId || x.planningId === planningId);
    });

const selectById = (id: ID) => createSelector(list, state => state.find(x => x.id === id));

//#region Loading
const downloadLoading = createSelector(feature, state => state.downloadLoading);
const uploadLoading = createSelector(feature, state => state.uploadLoading);
const updateLoading = createSelector(feature, state => state.updateLoading);
const deleteLoading = createSelector(feature, state => state.deleteLoading);

const loading = createSelector(
    feature,
    FilesTagSelectors.listLoading,
    FilesTagSelectors.createLoading,
    (
        state,
        tagListLoading,
        createTagLoading
    ) =>
        state.listLoading ||
        state.listByIDsLoading ||
        state.downloadLoading ||
        state.uploadLoading ||
        state.updateLoading ||
        state.deleteLoading ||
        tagListLoading ||
        createTagLoading
);
const fileListOrTagListLoading = createSelector(
    feature,
    FilesTagSelectors.listLoading,
    (state, tagListLoading) => state.listLoading || tagListLoading
);
//#endregion Loading

/**
 * Selectors for files
 * */
export const FilesSelectors = {
    list,
    dictionary,
    selectList,
    listLoading,

    selectById,

    downloadLoading,
    uploadLoading,
    updateLoading,
    deleteLoading,

    loading,
    fileListOrTagListLoading
};
