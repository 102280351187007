import { Injectable } from '@angular/core';

import { CoreSelectors, SettingsProviderService } from '@cyberloop/core';
import { TndModelProvider } from '@cyberloop/web/tnd/data';
import { TndChart, TndChartSettings, TndRealm } from '@cyberloop/web/tnd/model';
import { wellsTndChartDefaultSettings } from '@cyberloop/web/wells/model';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Store, createAction } from '@ngrx/store';
import { Observable, catchError, combineLatest, filter, first, from, of, switchMap, tap } from 'rxjs';

import { TndActions } from './tnd.actions';
import { TndSelectors } from './tnd.selectors';
import { WELLS_ENGINEERING_TND_FEATURE } from './tnd.state';

import type { Action } from '@ngrx/store';
import type { WellsTndChartSettings } from '@cyberloop/web/wells/model';
/**
 * Settings of a T&D that can be stored in a DB
 */
type WellsTndChartSettingsSerializable = Omit<WellsTndChartSettings, 'selectedModelId'>;

/** That's how data will be stored in DB */
type TndSettings = {
    weightsChartSettings: WellsTndChartSettingsSerializable,
    torqueChartSettings: WellsTndChartSettingsSerializable
}

const tndSettingsDefault: TndSettings = {
    weightsChartSettings: wellsTndChartDefaultSettings,
    torqueChartSettings: wellsTndChartDefaultSettings
};

const settingsKey = 'wells.engineering.tnd.settings';

const initAction = createAction(`[${WELLS_ENGINEERING_TND_FEATURE}] Initialize T&D`);

@Injectable()
export class TndEffects implements OnInitEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly modelsProvider: TndModelProvider,
        private readonly settings: SettingsProviderService
    ) { }

    /** @internal */
    readonly onInitAction$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),

        switchMap(() => combineLatest([

            // On init we'll watch for settings changes
            this.settings.watchSettings<TndSettings>(settingsKey, tndSettingsDefault).pipe(
                filter(Boolean),
                // tap(x => console.log(x)),
            ),

            // And load the active models
            this.store.select(CoreSelectors.currentWellId).pipe(
                filter(Boolean),
                switchMap(wellId => combineLatest([
                    this.modelsProvider.getActive(TndRealm.Well, wellId, TndChart.Weights),
                    this.modelsProvider.getActive(TndRealm.Well, wellId, TndChart.Torque),
                ]))
            )

        ])),

        // Put the settings to the store
        switchMap(([settings, [activeWeightsModel, activeTorqueModel]]) => ([
            TndActions.setWeightsChartSettings({ settings: {
                ...settings.weightsChartSettings,
                activeModelId: activeWeightsModel?.id
            } }),
            TndActions.setTorqueChartSettings({ settings: {
                ...settings.torqueChartSettings,
                activeModelId: activeTorqueModel?.id
            } })
        ])),

        catchError(error => ([
            TndActions.setError({ error })
        ]))
    ));


    /**
     * When settings of the Weights chart changes, we'll dispatch
     * setWeightsChartSettings action with the new chart settings.
     */
    readonly onUpdateWeightsSettings$ = createEffect(() => this.actions$.pipe(
        ofType(TndActions.updateWeightsChartSettings),

        switchMap(({ settings }) => {
            const newSettings = this.prepareForSave(settings);

            // Pick the current settings so we could roll it back in case of error
            return this.store.select(TndSelectors.weightsChartSettings).pipe(
                first(),

                // Put new settings to the store immediately
                switchMap(lastSettings => {
                    // And put new ones to the state
                    this.store.dispatch(TndActions.setWeightsChartSettings({
                        settings: newSettings
                    }));

                    let ob$: Observable<boolean>;

                    // Try to save settings to the storage
                    try {
                        ob$ = from(this.settings.updateSettings<TndSettings>(settingsKey, {
                            weightsChartSettings: newSettings
                        }));
                    }
                    catch (e) {
                        console.error('Could not save T&D weights settings', e);
                        ob$ = of(false);
                    }

                    return ob$.pipe(
                        switchMap(saved => {
                            if (!saved) {
                                // Roll back
                                return [ TndActions.setWeightsChartSettings({
                                    settings: lastSettings ?? wellsTndChartDefaultSettings
                                }) ];
                            }
                            else {
                                // Do nothing - widgets was saved
                                return [];
                            }
                        })
                    );
                })
            );
        })
    ));

    /**
     * When settings of the Torque chart changes, we'll dispatch
     * updateTorqueChartSettings action with the new chart settings.
     */
    readonly onUpdateTorqueSettings$ = createEffect(() => this.actions$.pipe(
        ofType(TndActions.updateTorqueChartSettings),

        switchMap(({ settings }) => {
            const newSettings = this.prepareForSave(settings);

            // Pick the current settings so we could roll it back in case of error
            return this.store.select(TndSelectors.torqueChartSettings).pipe(
                first(),

                // Put new settings to the store immediately
                switchMap(lastSettings => {
                    // And put new ones to the state
                    this.store.dispatch(TndActions.setTorqueChartSettings({
                        settings: newSettings
                    }));

                    let ob$: Observable<boolean>;

                    // Try to save settings to the storage
                    try {
                        ob$ = from(this.settings.updateSettings<TndSettings>(settingsKey, {
                            torqueChartSettings: newSettings
                        }));
                    }
                    catch (e) {
                        console.error('Could not save T&D torque settings', e);
                        ob$ = of(false);
                    }

                    return ob$.pipe(
                        switchMap(saved => {
                            if (!saved) {
                                // Roll back
                                return [ TndActions.setTorqueChartSettings({
                                    settings: lastSettings ?? wellsTndChartDefaultSettings
                                }) ];
                            }
                            else {
                                // Do nothing - widgets was saved
                                return [];
                            }
                        })
                    );
                })
            );
        })
    ));


    readonly onActivateWeightsModel$ = createEffect(() => this.actions$.pipe(
        ofType(TndActions.setActiveModelForWeightsChart),

        switchMap(({ modelId }) => {
            return this.store.select(TndSelectors.weightsChartSettings).pipe(
                first(),
                switchMap(settings => {
                    const newSettings = { ...settings };

                    if (modelId) {
                        newSettings.activeModelId = modelId;
                    }
                    else {
                        delete newSettings.activeModelId;
                    }

                    return [
                        TndActions.setWeightsChartSettings({
                            settings: newSettings
                        })
                    ];
                })
            );
        })
    ));

    readonly onActivateTorqueModel$ = createEffect(() => this.actions$.pipe(
        ofType(TndActions.setActiveModelForTorqueChart),

        switchMap(({ modelId }) => {
            return this.store.select(TndSelectors.torqueChartSettings).pipe(
                first(),
                switchMap(settings => {
                    const newSettings = { ...settings };

                    if (modelId) {
                        newSettings.activeModelId = modelId;
                    }
                    else {
                        delete newSettings.activeModelId;
                    }

                    return [
                        TndActions.setTorqueChartSettings({
                            settings: newSettings
                        })
                    ];
                })
            );
        })
    ));


    ngrxOnInitEffects(): Action {
        return initAction();
    }


    private prepareForSave(settings: WellsTndChartSettings): WellsTndChartSettingsSerializable {
        const newSettings = { ...settings };

        // Strip fields that shouldn't be stored in the DB

        if (newSettings?.selectedModelId) {
            delete newSettings.selectedModelId;
        }
        if (newSettings?.activeModelId) {
            delete newSettings.activeModelId;
        }

        return newSettings;
    }
}
