import { createReducer, on } from '@ngrx/store';

import { TndActions } from './tnd.actions';
import { TndState, getDefaultTndState } from './tnd.state';

/**
 * T&D reducer
 */
export const tndReducer = createReducer<TndState>(getDefaultTndState(),

    on(TndActions.setWeightsChartSettings, (state, { settings }): TndState => {
        return ({
            ...state,
            weightsSettings: settings
        });
    }),
    on(TndActions.setTorqueChartSettings, (state, { settings }): TndState => {
        return ({
            ...state,
            torqueSettings: settings
        });
    }),

    on(TndActions.setError, (state, { error }): TndState => ({
        ...state,
        error
        // dataState: 'NOT_LOADED'
    }))

);
