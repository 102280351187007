<div class="head">
    <span class="col skeleton">
        file name
    </span>
    <span class="col skeleton">
        activity
    </span>
    <span class="col skeleton">
        user
    </span>
    <span class="col skeleton">
        date
    </span>
    <span class="col skeleton">
        tags
    </span>
    <span class="col skeleton">
    </span>
</div>

<div class="content">
    <ng-container *ngFor="let file of skeletonCount; last as last">
        <span class="col name skeleton">
            <span class="text-overflow">
                skeleton
            </span>
        </span>

        <span class="col skeleton">
            <span class="activity text-overflow">
                skeleton
            </span>
        </span>

        <span class="col skeleton">
            <span class="text-overflow">
                skeleton
            </span>
        </span>

        <time class="col skeleton">
            skeleton
        </time>

        <div class="col tags">
            <div class="tag skeleton">skeleton</div>
        </div>

        <div class="col actions">
            <button [disabled]="true" class="file-download action cl-button skeleton">
                <span class="label">skeleton</span>
            </button>

            <div class="divider"></div>

            <button [disabled]="true" class="file-delete action cl-button skeleton">
                <span class="label">skeleton</span>
            </button>
        </div>

        <div *ngIf="!last" class="divider"></div>
    </ng-container>
</div>