import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { FilesEffects } from './files.effects';
import { filesReducer } from './files.reducer';
import { FILES_FEATURE } from './files.state';
import { FilesTagProviders } from './tag/tag-state.providers';

export const FilesProviders = [
    provideEffects([FilesEffects]),
    provideState(FILES_FEATURE, filesReducer),
    FilesTagProviders
];
