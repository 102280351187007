import { isNil } from 'lodash';

import { FirebaseApp } from 'firebase/app';
import { FirestoreDataConverter, SetOptions, Transaction, UpdateData } from 'firebase/firestore';

import { IClTransaction } from '../../../models/transaction';
import { ClBase } from './cl-firestore-base';

export class ClTransaction extends ClBase implements IClTransaction {
    constructor(app: FirebaseApp, private readonly transaction: Transaction) {
        super(app);
    }

    async get<T>(path: string, converter?: FirestoreDataConverter<T>): Promise<T | null> {
        try {
            let docRef = this.getDocumentReference<T>(path);

            if (!isNil(converter)) {
                docRef = docRef.withConverter(converter);
            }

            const snapshot = await this.transaction.get(docRef);
            return snapshot.exists() ? snapshot.data() : null;
        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    set<T>(path: string, data: T, converter?: FirestoreDataConverter<T>, options: SetOptions = {}): T {
        let docRef = this.getDocumentReference<T>(path);

        if (!isNil(converter)) {
            docRef = docRef.withConverter(converter);
        }

        try {
            this.transaction.set(docRef, data, options);
            return data;
        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    update<T>(path: string, data: UpdateData<T>, converter?: FirestoreDataConverter<T>): UpdateData<T> {
        let docRef = this.getDocumentReference<T>(path);

        if (!isNil(converter)) {
            docRef = docRef.withConverter(converter);
        }

        try {
            this.transaction.update(docRef, data);
            return data;
        }
        catch (error) {
            throw this.handleError(error);
        }
    }

    delete(path: string): boolean {
        try {
            this.transaction.delete(this.getDocumentReference(path));
            return true;
        }
        catch (error) {
            throw this.handleError(error);
        }
    }
}
