<carousel [displayDots]="true" [displayArrowsAlways]="true" (dotsClick)="dotsClick.emit()">
  <ng-container *ngIf="assetMetricsDataIsLoaded; else loadingBlock" carousel-item>
    <cyberloop-kpi-card 
      *ngFor="let metric of assetMetrics"
      [title]="metric.title"
      [value12]="metric.value12 | fromSi: metric.unit"
      [value24]="metric.value24 | fromSi: metric.unit"
      [unitLabel]="metric.unitLabel ?? ''"
      carousel-item
    ></cyberloop-kpi-card>
  </ng-container>
  <ng-template #loadingBlock>
    <cyberloop-kpi-card 
      *ngFor="let card of skeletons"
      class="skeleton" 
      carousel-item
    ></cyberloop-kpi-card>
  </ng-template>
</carousel>