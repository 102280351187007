import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { FullscreenDirective, IconComponent } from '@cyberloop/core';
import { BlockWithHeadingComponent } from '@cyberloop/web/app/ui';
import { WidgetSize } from '@cyberloop/web/wells/model';

@Component({
    selector: 'cyberloop-kpi-widget',
    standalone: true,
    imports: [
        NgIf,
        BlockWithHeadingComponent,
        MatButtonModule,
        IconComponent,
        FullscreenDirective,
        MatMenuModule
    ],
    templateUrl: './kpi-widget.component.html',
    styleUrls: ['./kpi-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiWidgetComponent {

    @Input() heading = 'Widget';

    @Input() size = WidgetSize.Medium;
    @Input() showSettings = true;

    @Output() readonly settingsRequested = new EventEmitter<void>();
    @Output() readonly fullscreenChanged = new EventEmitter<boolean>();
    @Output() readonly editRequested = new EventEmitter<void>();
    @Output() readonly deleteRequested = new EventEmitter<void>();

}
