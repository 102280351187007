<section class="gauge">
  <ng-container *ngIf="tracesNotEmpty; else gaugeSkeleton">
    <cyberloop-dd-gauge 
      [inc]="lastSurveyValue?.incCor ?? null"
      [azi]="lastSurveyValue?.aziCor ?? null"
      [tfArray]="tfArray ?? []"
      [traces]="traces"
      [angleUnit]="angleUnit"
      (addTrace)="onAddTrace($event)"
      (removeTrace)="onRemoveTrace($event)"
    ></cyberloop-dd-gauge>
  </ng-container>
  <ng-template #gaugeSkeleton>
    <div class="skeleton"></div>
  </ng-template>
</section>
<section class="right-block" [class.loading]="!(topChartData && bottomChartData)">
  <ng-container *ngIf="topChartData && bottomChartData; else chartsSkeleton">
    <div class="cards">
      <cyberloop-dd-dashboard-card title="Survey to plan" [distance]="123" [direction]="321" color="accent-1"></cyberloop-dd-dashboard-card>
      <cyberloop-dd-dashboard-card title="Drilled bit to plan" color="accent-3"></cyberloop-dd-dashboard-card>
      <cyberloop-dd-dashboard-card title="Projection to plan" color="accent-2"></cyberloop-dd-dashboard-card>
    </div>
    <div class="charts">
      <div class="chart">
        <cyberloop-dd-chart 
          [xAxisTitle]="topChartXAxisTitle" 
          [yAxisTitle]="topChartYAxisTitle"
          [data]="topChartData"
        ></cyberloop-dd-chart>
      </div>
      <div class="chart">
        <cyberloop-dd-chart 
          [xAxisTitle]="bottomChartXAxisTitle" 
          [yAxisTitle]="bottomChartYAxisTitle"
          [data]="bottomChartData"
        ></cyberloop-dd-chart>
      </div>
  
      <div class="chart-menu">
        <div class="type-selector">
          <cl-checkbox-group [selected]="[selectedChartType]" (selectedChange)="typeSelect.emit($event)" 
            [list]="chartTypes" class="button-toggle" [radio]="true"></cl-checkbox-group>
        </div>
  
        <div class="buttons">
          <button matTooltip="Refresh charts" matTooltipPosition="below"><icon name="dd__circle"></icon></button>
          <button matTooltip="Reset zoom" matTooltipPosition="below"><icon name="dd__full"></icon></button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #chartsSkeleton>
    <div class="skeleton"></div>
  </ng-template>
</section>
<section class="table">
  <ng-container *ngIf="surveyData; else tableSkeleton">
    <div class="table-container">
      <table mat-table [dataSource]="surveyData" [trackBy]="trackBySurveyNumber">
        <ng-container *ngFor="let col of tableColumns" [matColumnDef]="col.id">
            <th mat-header-cell *matHeaderCellDef>
              <div class="column-content">
                {{col.name}} <span class="unit">{{ col.unitsGroup ? (col.unitLabel | async) : '' }}</span>
              </div> 
            </th>
            <td mat-cell *matCellDef="let element; let i = index;">
                {{ (col.unitsGroup ? (element[col.id] | fromSi:(col.unit | async)) : element[col.id]) | formatNumber | noData }}
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </ng-container>
  
  <ng-template #tableSkeleton>
    <div class="skeleton"></div>
  </ng-template>
</section>