<block-with-heading heading="Select a Widget" class="container">
    <button class="cl-icon-button" block-header-content class="close" (click)="onCancel()">
        <icon name="close"></icon>
    </button>

    <div block-main-content>
        <div class="list">
            <button *ngFor="let item of widgetOptions" class="cl-flat-button" (click)="onSelect(item)">
                <span [class]="'icon icon-size-' + item.size"></span>
                <span class="label">{{ item.name }}</span>
            </button>
        </div>

        <div class="actions">
            <button class="cl-flat-button" (click)="onCancel()">
                Cancel
            </button>
        </div>
    </div>

</block-with-heading>