import { ClAccessDeniedNetworkError, ClBadRequestNetworkError, ClError, ClNetworkError, ClNotFoundNetworkError, ClServerNetworkError } from '@cyberloop/core';

import { FirebaseApp } from 'firebase/app';
import { CollectionReference, FirestoreError, FirestoreErrorCode, collection, doc, getFirestore } from 'firebase/firestore';

export class ClBase {
    constructor(protected readonly app: FirebaseApp) { }

    protected getDocumentReference<T>(path: string) {
        const pathes = path.split('/').filter(Boolean);

        if (pathes.length == 0 || pathes.length % 2 == 1) {
            throw new Error('Invalid path');
        }

        const docName = pathes.pop();
        const collName = pathes.join('/');
        const collRef = this.getCollectionReference<T>(collName);
        const docRef = doc(collRef, docName);

        return docRef;
    }

    protected getCollectionReference<T>(path: string) {
        return collection(getFirestore(this.app), path) as CollectionReference<T>;
    }

    protected handleError(error: unknown) {
        console.error(error);

        if (error instanceof ClError) {
            return error;
        }

        if (error instanceof FirestoreError) {
            switch (error.code as FirestoreErrorCode) {
                case 'permission-denied':
                    return new ClAccessDeniedNetworkError(error.message);

                case 'invalid-argument':
                    return new ClBadRequestNetworkError(error.message);

                case 'not-found':
                    return new ClNotFoundNetworkError(error.message);

                case 'internal':
                    return new ClServerNetworkError(error.message);

                default:
                    return new ClNetworkError(error.message);
            }
        }

        return new ClNetworkError('Unknown error');
    }
}
