import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { NotificationService } from '@cyberloop/core';
import { PlanningActions, PlanningSelectors, PlanningService } from '@cyberloop/web/planning/shared/data';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';

/**
 * Use this guard to check if user has Planning access
 */
@Injectable({
    providedIn: 'root'
})
export class PlanningAccessGuard implements CanActivate {
    /** @internal */
    constructor(
        private readonly store: Store,
        private readonly planningService: PlanningService,
        private readonly notificationService: NotificationService
    ) { }

    /** @internal */
    async canActivate(route: ActivatedRouteSnapshot) {
        this.store.dispatch(PlanningActions.loadPlanningByRouteParam());
        await firstValueFrom(this.store.select(PlanningSelectors.planningLoaded).pipe(filter(Boolean)));

        const { planningId } = route.params;
        const hasAccess = await this.planningService.hasPlanningAccess(planningId);

        if (hasAccess) {
            return true;
        }

        this.notificationService.error('You do not have access to this page');
        return false;
    }
}
