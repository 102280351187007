import { createReducer, on } from '@ngrx/store';

import { AuthActions } from './auth.actions';
import { AuthState, getDefaultAuthState } from './auth.state';

/** @internal */
export const authReducer = createReducer<AuthState>(getDefaultAuthState(),
    on(AuthActions.initialized, (state) => ({
        ...state,
        isInitialized: true
    })),
    on(AuthActions.tryLogin, (state) => ({
        ...state,
        user: undefined,
        loginState: 'LOGGING_IN',
        loginError: undefined
    })),
    on(AuthActions.logout, (state) => ({
        ...state,
        user: undefined,
        listOfTenants: undefined,
        loginState: 'NOT_LOGGED_IN'
    })),
    on(AuthActions.setLoginError, (state, { err }) => ({
        ...state,
        loginError: err,
        user: undefined,
        loginState: 'NOT_LOGGED_IN'
    })),
    on(AuthActions.setUser, (state, { user }) => ({
        ...state,
        user,
        loginState: 'LOGGED_IN'
    })),
    on(AuthActions.setListOfTenants, (state, { tenants }) => ({
        ...state,
        listOfTenants: tenants,
        loginState: 'NOT_LOGGED_IN'
    })),

    //#region Load tenant list
    on(AuthActions.loadTenantList, (state, { background }) => ({
        ...state,
        listOfTenantsState: 'LOADING',
        listOfTenantsBackgroundLoading: background,
        listOfTenantsError: undefined,
        listOfTenants: undefined
    })),
    on(AuthActions.loadTenantListSuccess, (state, { listOfTenants }) => ({
        ...state,
        listOfTenants,
        listOfTenantsState: 'LOADED',
        listOfTenantsBackgroundLoading: undefined,
        listOfTenantsError: undefined
    })),
    on(AuthActions.loadTenantListFailure, (state, { error }) => ({
        ...state,
        listOfTenantsState: 'NOT_LOADED',
        listOfTenantsBackgroundLoading: undefined,
        listOfTenantsError: error
    })),
    //#endregion Load tenant list

    //#region Reset password
    on(AuthActions.restorePassword, (state) => ({
        ...state,
        restorePasswordState: 'PROCESS',
        restorePasswordError: undefined
    })),
    on(AuthActions.restorePasswordSuccess, (state) => ({
        ...state,
        restorePasswordState: 'INITIAL',
        restorePasswordError: undefined
    })),
    on(AuthActions.restorePasswordFailure, (state, { error }) => ({
        ...state,
        restorePasswordState: 'INITIAL',
        restorePasswordError: error
    })),
    //#endregion Reset password

    //#region Set new password
    on(AuthActions.setNewPassword, (state) => ({
        ...state,
        setNewPasswordState: 'PROCESS',
        setNewPasswordError: undefined
    })),
    on(AuthActions.setNewPasswordSuccess, (state) => ({
        ...state,
        setNewPasswordState: 'INITIAL',
        setNewPasswordError: undefined
    })),
    on(AuthActions.setNewPasswordFailure, (state, { error }) => ({
        ...state,
        setNewPasswordState: 'INITIAL',
        setNewPasswordError: error
    })),
    //#endregion Set new password
);

