import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiRigRateOfAdvanceItem } from '@cyberloop/web/wells/model';
type KpiRigRateOfAdvanceInput = {
    rig: string,
    since: string,
    until: string
}

type KpiRigRateOfAdvance = {
    rig: {
        rigRateOfAdvance: KpiRigRateOfAdvanceItem[]
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiRigRateOfAdvanceQuery extends GraphQLQuery<KpiRigRateOfAdvance, KpiRigRateOfAdvanceInput> {
    override readonly document = `
        query GetRigRateOfAdvance($rig: String!, $since: DateTime!, $until: DateTime!) {
            rig(name: $rig) {
                ... on Rig {
                    rigRateOfAdvance(since: $since, until: $until) {
                        startTime
                        trippingIn
                        trippingOut
                        drilling
                        casingRunning
                    }
                }
            }
        }
    `;
}