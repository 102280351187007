import { createReducer, on } from '@ngrx/store';
import { isNil } from 'lodash';

import * as moment from 'moment';

import { PlanningActions } from '../planning.actions';
import { PlanningVersionActions } from './version.actions';
import { VersionState, adapter, initialState } from './version.state';

export const versionReducer = createReducer<VersionState>(initialState,
    //#region List
    on(PlanningVersionActions.loadList, (state): VersionState => ({
        ...state,
        listLoadingState: 'LOADING',
        listError: undefined
    })),
    on(PlanningVersionActions.loadListSuccess, (state, { list }): VersionState => (
        adapter.upsertMany<VersionState>(list, {
            ...state,
            listLoadingState: 'LOADED'
        })
    )),
    on(PlanningVersionActions.loadListFailure, (state, { error }): VersionState => ({
        ...state,
        listLoadingState: 'NOT_LOADED',
        listError: error
    })),
    //#endregion List

    //#region Version
    //#region Load
    on(PlanningVersionActions.loadVersion, (state): VersionState => ({
        ...state,
        versionLoadingState: 'LOADING',
        versionError: undefined
    })),
    on(PlanningVersionActions.loadVersionSuccess, (state, { version }): VersionState => {
        const newState: VersionState = {
            ...state,
            versionLoadingState: 'LOADED'
        };

        if (isNil(version)) {
            return newState;
        }

        return adapter.upsertOne<VersionState>(version, newState);
    }),
    on(PlanningVersionActions.loadVersionFailure, (state, { error }): VersionState => ({
        ...state,
        versionLoadingState: 'NOT_LOADED',
        versionError: error
    })),
    //#endregion Load

    //#region Create
    on(PlanningVersionActions.createVersion, (state): VersionState => ({
        ...state,
        createLoading: true,
        createError: undefined
    })),
    on(PlanningVersionActions.createVersionSuccess, (state, { version }): VersionState =>
        adapter.addOne<VersionState>(version, {
            ...state,
            createLoading: false
        })
    ),
    on(PlanningVersionActions.createVersionFailure, (state, { error }): VersionState => ({
        ...state,
        createLoading: false,
        createError: error
    })),
    //#endregion Create

    //#region Update
    on(PlanningVersionActions.updateVersion, (state): VersionState => ({
        ...state,
        updateLoading: true,
        updateError: undefined
    })),
    on(PlanningVersionActions.updateVersionSuccess, (state, { version }): VersionState =>
        adapter.upsertOne<VersionState>(version, {
            ...state,
            updateLoading: false
        })
    ),
    on(PlanningVersionActions.updateVersionFailure, (state, { error }): VersionState => ({
        ...state,
        updateLoading: false,
        updateError: error
    })),
    //#endregion Update

    //#region Replace
    on(PlanningVersionActions.replaceVersion, (state): VersionState => ({
        ...state,
        replaceLoading: true,
        replaceError: undefined
    })),
    on(PlanningVersionActions.replaceVersionSuccess, (state, { version }): VersionState =>
        adapter.upsertOne<VersionState>(version, {
            ...state,
            replaceLoading: false
        })
    ),
    on(PlanningVersionActions.replaceVersionFailure, (state, { error }): VersionState => ({
        ...state,
        replaceLoading: false,
        replaceError: error
    })),
    //#endregion Replace

    //#region Delete
    on(PlanningVersionActions.deleteVersion, (state): VersionState => ({
        ...state,
        deleteLoading: true,
        deleteError: undefined
    })),
    on(PlanningVersionActions.deleteVersionSuccess, (state, { version }): VersionState =>
        adapter.removeOne<VersionState>(version.id, {
            ...state,
            deleteLoading: false
        })
    ),
    on(PlanningVersionActions.deleteVersionFailure, (state, { error }): VersionState => ({
        ...state,
        deleteLoading: false,
        deleteError: error
    })),
    //#endregion Delete
    //#endregion Version

    //#region Stages
    on(
        PlanningActions.loadPlanningByRouteParam,
        PlanningActions.loadPlanningByCurrentWell,
        (state): VersionState => ({
            ...state,
            editedStages: undefined,
            stagesUpdatedAt: undefined
        })
    ),
    on(
        PlanningVersionActions.updateVersionSuccess,
        PlanningVersionActions.replaceVersionSuccess,
        PlanningVersionActions.createVersionSuccess,
        (state): VersionState => ({
            ...state,
            stagesUpdatedAt: undefined
        })
    ),
    on(PlanningVersionActions.setStages, (state, { stages }): VersionState => ({
        ...state,
        editedStages: stages,
        stagesUpdatedAt: undefined
    })),
    on(PlanningVersionActions.updateStages, (state, { stages }): VersionState => ({
        ...state,
        editedStages: stages,
        stagesUpdatedAt: moment()
    })),
    on(PlanningVersionActions.resetStages, (state): VersionState => ({
        ...state,
        editedStages: undefined,
        stagesUpdatedAt: undefined
    }))
    //#endregion Stages
);

