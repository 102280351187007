import { EnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { UnitsEffects } from './units.effects';
import { unitsReducer } from './units.reducer';
import { UNITS_FEATURE } from './units.state';

/** @internal */
export const UnitsProviders: EnvironmentProviders[] = [
    provideState(UNITS_FEATURE, unitsReducer),
    provideEffects([UnitsEffects])
];