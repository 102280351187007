import { createAction, props } from '@ngrx/store';

import { Assets, Rig, Well } from '../models';
import { ThemeName } from '../models/themes';
import { PublicAuthActions } from './auth/auth.actions';
import { CORE_FEATURE } from './core.state';
import { PublicUnitsActions } from './units/units.actions';

/** Set theme */
const setTheme = createAction(`[${CORE_FEATURE}] Set current theme`, props<{ theme: ThemeName }>());
/** Set rig meta information */
const setRigMeta = createAction(`[${CORE_FEATURE}] Set rigs meta`, props<{ rigs: Rig[] }>());
/** Set well meta information */
const setWellMeta = createAction(`[${CORE_FEATURE}] Set wells meta`, props<{ wells: Well[] }>());
/** Set current well by id */
const setCurrentWell = createAction(`[${CORE_FEATURE}] Set current well`, props<{ wellId: string | undefined }>());
/** Set current section by id */
const setCurrentSection = createAction(`[${CORE_FEATURE}] Set current section`, props<{ sectionId: number | undefined }>());
/** Set current assets available */
const setCurrentAssets = createAction(`[${CORE_FEATURE}] Set current assets`, props<{ assets: Assets }>());

/**
 * @internal
 * Actions for core used internally
 * */
export const CoreActions = {
    setTheme,
    setRigMeta,
    setWellMeta,
    setCurrentWell,
    setCurrentSection,
    setCurrentAssets
};

/**
 * Actions for core
 * */
export const PublicCoreActions = {
    setTheme,
    setCurrentWell,
    setCurrentSection,

    units: PublicUnitsActions,
    auth: PublicAuthActions
};
