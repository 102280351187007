import { Injectable, Type } from '@angular/core';

import { ConfirmationDialogService, CoreSelectors, PopupService, PromptDialogService } from '@cyberloop/core';
import { FilesService } from '@cyberloop/web/files/data';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { firstValueFrom, map, shareReplay } from 'rxjs';

import { Moment } from 'moment';

import { ForecastSelectors } from '../../state/forecast';
import { ForecastActions } from '../../state/forecast/forecast.actions';
import { ForecastDataService } from './forecast-data.service';

@Injectable({ providedIn: 'root' })
export class ForecastService {
    constructor(
        private readonly store: Store,
        private readonly confirmationDialogService: ConfirmationDialogService,
        private readonly dataService: ForecastDataService,
        private readonly filesService: FilesService,
        private readonly promptDialogService: PromptDialogService
    ) { }

    readonly wellId$ = this.store.select(CoreSelectors.currentWellId).pipe(shareReplay(1));
    readonly well$ = this.store.select(CoreSelectors.currentWell).pipe(shareReplay(1));

    readonly forecast$ = this.store.select(ForecastSelectors.forecast).pipe(shareReplay(1));
    readonly forecastId$ = this.store.select(ForecastSelectors.forecastId).pipe(shareReplay(1));
    readonly forecastUpdatedAt$ = this.store.select(ForecastSelectors.forecast).pipe(map(forecast => forecast?.updatedAt), shareReplay(1));
    readonly estimatedRigReleaseDate$ = this.store.select(ForecastSelectors.estimatedRigReleaseDate).pipe(shareReplay(1));

    readonly eventList$ = this.store.select(ForecastSelectors.eventList).pipe(shareReplay(1));
    readonly editableEventId$ = this.store.select(ForecastSelectors.editableEventId);

    readonly isLoading$ = this.store.select(ForecastSelectors.isLoading).pipe(shareReplay(1));

    readonly equipmentAndPersonnelEventList$ = this.store.select(ForecastSelectors.equipmentAndPersonnelEventList).pipe(shareReplay(1));

    confirmImportFromWellPlan() {
        return this.confirmationDialogService.show('Do you really want to import data from Well plan? The current Forecast will deleted');
    }

    confirmDeleteEvent() {
        return this.confirmationDialogService.show('Do you really want to delete this event?');
    }

    async openExportPopup(estimatedRigReleaseDate: Moment) {
        return await this.promptDialogService.promptDate({
            title: 'Estimated rig release',
            saveText: 'Ok',
            placeholder: 'Pick',
            required: true,
            value: estimatedRigReleaseDate
        });
    }

    async export(startDate: Moment) {
        try {
            this.store.dispatch(ForecastActions.export());

            const [
                well,
                forecast,
                eventList,
                equipmentAndPersonnelEventList,
                estimatedRigReleaseDate
            ] = await Promise.all([
                firstValueFrom(this.well$),
                firstValueFrom(this.forecast$),
                firstValueFrom(this.eventList$),
                firstValueFrom(this.equipmentAndPersonnelEventList$),
                firstValueFrom(this.estimatedRigReleaseDate$)
            ]);

            if (isNil(well) || isNil(forecast) || isNil(estimatedRigReleaseDate)) {
                return;
            }

            const events = eventList.filter(event => this.dateIsBetween(event.startDate, startDate) || this.dateIsBetween(event.endDate, startDate));
            const equipmentAndPersonnelEvents = equipmentAndPersonnelEventList.filter(event => this.dateIsBetween(event.date, startDate));

            const data = await firstValueFrom(this.dataService.generateReport({
                wellName: well.name,
                startDate,
                estimatedRigReleaseDate,
                updatedAt: forecast.updatedAt,
                eventList: events,
                equipmentAndPersonnelEventList: equipmentAndPersonnelEvents
            }));

            await this.filesService.save(data, {
                suggestedName: `Forecast - ${well.name}`,
                types: [
                    {
                        accept: {
                            'application/pdf': ['.pdf']
                        }
                    }
                ]
            });

            this.store.dispatch(ForecastActions.exportSuccess());
        }
        catch (error) {
            this.store.dispatch(ForecastActions.exportFailure({ error }));
        }
    }

    private dateIsBetween(date: Moment, startDate: Moment, dayCount: number = 5) {
        return date.isBetween(startDate, startDate.clone().add(dayCount, 'days'), undefined, '[)');
    }
}
