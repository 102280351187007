import { Well } from '@cyberloop/core';
import { cloneDeep, isNil } from 'lodash';

import * as moment from 'moment';

import { InfoObj, LenObj, NA, apiCode, curveInfoHeader, defaultNullValue, lasInfoDefault, suffixes, toExportDateFormat, unitHeader, unitLenObj, valueLen, versionInfo, wellHeader, wellInfoHeader, wellLenObj } from './las-defaults';
import { WriterBase, WriterParameters } from './writer-base.service';

export class LasWriterService extends WriterBase {
    override get fileExt(): string {
        return '.las';
    }
    override get mime(): string {
        return 'text/las';
    }

    public writeToString(params: WriterParameters): string {
        const { data, columns, from, to, precision, well } = params;
        const byDepth = params.byDepth ?? false;
        const step = params.step ?? 0;
        if (isNil(from)) {
            throw new Error('No from');
        }
        if (isNil(to)) {
            throw new Error('No to');
        }
        const lines = [];
        lines.push(...versionInfo);

        const nullValue = defaultNullValue;
        let str = '';

        lines.push(' ');

        lines.push(wellInfoHeader);
        lines.push(this.normalizeRow(wellHeader.header, wellLenObj, precision));
        lines.push(this.normalizeRow(wellHeader.line, wellLenObj, precision, true));
        const suff = suffixes(byDepth);
        const info = this.fillInWellInfo(from, to, step, byDepth, precision, well);

        for (const key in info) {
            if (key === 'comments') {
                continue;
            }
            if (key in suff) {
                info[key].id = `${info[key].id}.${suff[key].letter}`;
            }
            else {
                info[key].id = `${info[key].id}.`;
            }
            lines.push(this.normalizeRow(info[key], wellLenObj, precision));
        }

        lines.push(' ');

        lines.push(curveInfoHeader);
        lines.push(this.normalizeRow(unitHeader.header, unitLenObj, precision));
        lines.push(this.normalizeRow(unitHeader.line, unitLenObj, precision, true));
        for (let i = 0; i < columns.length; i++) {
            const obj = {
                id: `${columns[i].id.toUpperCase()}.${columns[i].unit}`,
                value: apiCode,
                name: columns[i].name.toUpperCase()
            };
            lines.push(this.normalizeRow(obj, unitLenObj, precision));
        }

        lines.push(' ');

        const comments = info['comments'].value.split('\n');
        for (const comment of comments) {
            lines.push(`#${comment}`);
        }

        lines.push(' ');

        str = '~A';
        for (const column of columns) {
            const isDate = column.id === 'date';
            str += column.id.toLocaleUpperCase().padStart(isDate ? 2 * valueLen : valueLen);
        }
        lines.push(str);
        const line = [];
        for (const row of data) {
            line.length = 0;
            for (const col of columns) {
                let v = (row as any)[col.secondId];
                if (typeof v === 'string') {
                    if (v === 'None' || v === 'NaN' || v === '') {
                        v = nullValue;
                    }
                    else if (/^\d\d\d\d-\d\d-\d\d$/gi.test(v)) {
                        v = v.replace(/-/gi, '');
                    }
                    else {
                        v = v.replace(/[^\w\d]/gi, '_');
                    }

                    if (!v) {
                        v = nullValue;
                    }
                }
                else if (v === null || v === undefined) {
                    v = nullValue;
                }
                else if (typeof v === 'number') {
                    if (Number.isFinite(v)) {
                        v = col.id === 'date' ? v.toFixed(0) : v.toFixed(precision);
                    }
                    else {
                        v = nullValue;
                    }
                }
                line.push((v as string).padStart(valueLen));
            }
            // TODO CHANGE TIME TO RIG TIMEZONE
            line[0] = byDepth ? '  ' + line[0] : '  ' + toExportDateFormat(Number(line[0])).padStart(2 * valueLen);
            lines.push(line.join(''));
        }

        return lines.join('\r\n');
    }

    private normalizeRow(obj: InfoObj, lenObj: LenObj, precision: number, line: boolean = false) {
        const repSymbol = line ? '-' : ' ';
        let str = '';
        for (const key in lenObj) {
            const k = key as keyof InfoObj;
            const value = obj[k];
            if (typeof value === 'boolean' || !value) {
                continue;
            }

            const val = this.getStringValue(value, precision);
            if (lenObj[key].pre) {
                str += val.padStart(lenObj[key].len, repSymbol);
            }
            else {
                str += val.padEnd(lenObj[key].len, repSymbol);
            }

            if (lenObj[key].separ && !line) {
                str += ': ';
            }
            else {
                str += ' ';
            }
        }
        return str;
    }

    private getStringValue(val: number | string, precision: number) {
        if (typeof val === 'number') {
            return val.toFixed(precision);
        }
        return val.toUpperCase();
    }

    private fillInWellInfo(
        from: number, to: number, step: number, byDepth: boolean,
        precision: number, well?: Well
    ) {
        const wellInfo: Record<string, InfoObj> = cloneDeep(lasInfoDefault);

        wellInfo['strt'].value = byDepth ? from.toString() : toExportDateFormat(from);
        wellInfo['stop'].value = byDepth ? to.toString() : toExportDateFormat(to);
        wellInfo['step'].value = byDepth ? step.toString() : '0';
        wellInfo['null'].value = defaultNullValue;
        wellInfo['srvc'].value = NA;
        wellInfo['cnty'].value = NA;
        wellInfo['well'].value = well?.name ?? NA;
        wellInfo['fld'].value = NA;
        wellInfo['ctry'].value = NA;
        wellInfo['prov'].value = NA;
        wellInfo['stat'].value = NA;
        wellInfo['comp'].value = NA;
        wellInfo['date'].value = toExportDateFormat(moment());
        wellInfo['api'].value = NA;
        wellInfo['uwi'].value = NA;
        wellInfo['loc'].value = well?.location?.latitiude && well?.location.longitude
            ? `${well.location.latitiude.toFixed(precision)} ${well?.location.longitude.toFixed(precision)}`
            : NA;

        const suff = suffixes(byDepth);
        for (const key in suff) {
            if (key in wellInfo) {
                wellInfo[key].name = suff[key].name;
            }
        }

        return wellInfo;
    }

}
