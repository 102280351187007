import { CdkDrag, DragDropModule } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { IconComponent, Points, SectionInput, Well, WellKnownParams } from '@cyberloop/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';

import * as moment from 'moment';

import { RangePickerDataService } from '../../services/range-picker-data.service';
import { ClWellChartComponent } from '../cl-well-chart/well-chart.component';

import type { PopupContent } from '../popup-host/popup-host.component';

type RangePickerInputs = Partial<{
    startTime: moment.Moment;
    endTime: moment.Moment;
    bde: Points;
    wde: Points;
    sections: SectionInput[];
}>;

export type RangePickerResult = { startDate: Date, endDate: Date };

/**
 * A component to pick some time range on the well by dragging a selection area
 * and/or inputting the date & time. Uses `cl-well-chart` to display the data.
 */
@Component({
    selector: 'cyberloop-range-picker',
    standalone: true,
    imports: [
        AsyncPipe,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DragDropModule,
        ClWellChartComponent,
        IconComponent
    ],
    templateUrl: './range-picker.component.html',
    styleUrls: ['./range-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class ClRangePickerComponent implements PopupContent<RangePickerResult, RangePickerInputs>, OnDestroy {

    // Chart

    private readonly _bde$ = new BehaviorSubject<Points>([]);
    private readonly _wde$ = new BehaviorSubject<Points>([]);
    private readonly _sections$ = new BehaviorSubject<SectionInput[]>([]);

    // Selection rect

    private readonly _selectionX$ = new BehaviorSubject<number>(0);
    private readonly _selectionWidth$ = new BehaviorSubject<number>(0);

    private _well?: Well;
    private _dataSub?: Subscription;

    // Dialog props

    private _closeFn?: (result: RangePickerResult | null) => void;
    private _result: RangePickerResult | null = null;

    // Internal dates

    private _startDateBoundary: moment.Moment | null = moment().subtract(3, 'days');
    private _endDateBoundary: moment.Moment | null = moment();

    private _selectionStartDate?: moment.Moment;
    private _selectionEndDate?: moment.Moment;

    // Handles position

    private _startHandlePos = 0;
    private _endHandlePos = 0;

    /**
     * @internal Should not be called directly - add the component to your HTML
     * template
     */
    constructor(
        private readonly data: RangePickerDataService
    ) {
        this.form.controls.startDate.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(this.onDateChange.bind(this, 'start'));

        this.form.controls.startTime.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(this.onTimeChange.bind(this, 'start'));

        this.form.controls.endDate.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(this.onDateChange.bind(this, 'end'));

        this.form.controls.endTime.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(this.onTimeChange.bind(this, 'end'));
    }

    // Public chart props

    /** @internal WDE data for Chart */
    readonly wde$ = this._wde$.asObservable();
    /** @internal BDE data for Chart */
    readonly bde$ = this._bde$.asObservable();
    /** @internal Sections list for Chart */
    readonly sections$ = this._sections$.asObservable();

    /** @internal Selection X position */
    readonly selectionX$ = this._selectionX$.asObservable();
    /** @internal Selection width */
    readonly selectionWidth$ = this._selectionWidth$.asObservable();

    /** @internal Form with date and time inputs */
    readonly form = new FormGroup({
        startDate: new FormControl(''),
        startTime: new FormControl('01', [ Validators.min(0), Validators.max(23) ]),
        endDate: new FormControl(''),
        endTime: new FormControl('23', [ Validators.min(0), Validators.max(23) ])
    });

    // Inputs

    /** The boundary Start date - the user won't be able to select date & time before this */
    @Input()
    get startDate(): Date | null | undefined {
        return this._startDateBoundary?.toDate() ?? undefined;
    }
    set startDate(value: Date | null | undefined) {
        this._startDateBoundary = value ? moment(value) : null;

        this.checkDates();
    }

    /** The boundary End date - the user won't be able to select date & time after this */
    @Input()
    get endDate(): Date | null | undefined {
        return this._endDateBoundary?.toDate() ?? undefined;
    }
    set endDate(value: Date | null | undefined) {
        this._endDateBoundary = value ? moment(value) : null;

        this.checkDates();
    }

    // Children elements

    /** @internal Chart component */
    @ViewChild('chart', { read: ClWellChartComponent, static: true })
        chart?: ClWellChartComponent;

    /** @internal Selection start handle */
    @ViewChild('selectionStart', { read: CdkDrag, static: true })
        startHandle?: CdkDrag;

    /** @internal Selection end handle */
    @ViewChild('selectionEnd', { read: CdkDrag, static: true })
        endHandle?: CdkDrag;

    // Methods

    ngOnDestroy(): void {
        this._dataSub?.unsubscribe();
    }

    /** @internal @inheritdoc */
    setData(data: RangePickerInputs): void {
        // If data was passed - we'll use it.
        // Otherwise we'll fetch it ourselves.

        const tagsToWatch: WellKnownParams[] = [];

        // Close prev sub (if present)

        this._dataSub?.unsubscribe();
        this._dataSub = undefined;

        let dateBoundariesObtained = false;

        // And check if we got the data

        if (data?.wde) {
            this._wde$.next(data.wde);

            this.getDateBoundariesFromData(data.wde);
            dateBoundariesObtained = true;
        }
        else {
            // Load WDE
            tagsToWatch.push(WellKnownParams.WDE);
        }

        if (data?.bde) {
            this._bde$.next(data.bde);

            if (!dateBoundariesObtained) {
                this.getDateBoundariesFromData(data.bde);
                dateBoundariesObtained = true;
            }
        }
        else {
            // Load BDE
            tagsToWatch.push(WellKnownParams.BDE);
        }

        // Same with sections

        let needSections = false;

        if (data?.sections) {
            this._sections$.next(data.sections);
        }
        else {
            // Get sections
            needSections = true;
        }

        if (dateBoundariesObtained) {
            // Set the selected values
            if (data.startTime && data.endTime) {
                this.update(data.startTime, data.endTime);
            }

            this.chart?.chart?.updateSize();
        }

        // Load the data

        if (tagsToWatch.length > 0 || needSections) {
            this._dataSub = this.data.getData(tagsToWatch, needSections).subscribe(chartData => {
                let shouldSetSelection = !dateBoundariesObtained;

                if (chartData?.wde) {
                    this._wde$.next(chartData.wde);

                    if (!dateBoundariesObtained) {
                        this.getDateBoundariesFromData(chartData.wde);
                        dateBoundariesObtained = true;

                        // Set the selected values
                        if (shouldSetSelection && data.startTime && data.endTime) {
                            this.update(data.startTime, data.endTime);
                            shouldSetSelection = false;
                        }
                    }
                }
                if (chartData?.bde) {
                    this._bde$.next(chartData.bde);

                    if (!dateBoundariesObtained) {
                        this.getDateBoundariesFromData(chartData.bde);

                        // Set the selected values
                        if (shouldSetSelection && data.startTime && data.endTime) {
                            this.update(data.startTime, data.endTime);
                        }
                    }
                }
                if (chartData?.sections) {
                    this._sections$.next(chartData.sections);
                }

                this.chart?.chart?.updateSize();
            });
        }
    }

    /** @internal Sets the close handler function */
    setClose(fn: (result: RangePickerResult | null) => void): void {
        this._closeFn = fn;
    }

    /** @internal */
    onCancel(): void {
        this._closeFn?.(null);
    }

    /** @internal */
    onSubmit(): void {
        this._closeFn?.(this._result);
    }

    // Drag-related stuff

    /** @internal Handles move handler */
    onHandleMoved(): void {
        this._startHandlePos = this.startHandle?.getFreeDragPosition().x ?? 0;
        this._endHandlePos = this.endHandle?.getFreeDragPosition().x ?? 0;

        const handles = this.getSortedHandles();

        if (!handles) {
            return;
        }

        this.updateSelectionRect(handles.startHandle, handles.endHandle);
    }

    /** @internal Handles release handler */
    async onHandleReleased(): Promise<void> {
        if (!this.chart) {
            return;
        }
        if (!this._endDateBoundary || !this._startDateBoundary) {
            console.warn(`You should provide both startDate and endDate to the ${this.constructor.name} to pick a range`);
            return;
        }

        const handles = this.getSortedHandles();

        if (!handles) {
            return;
        }

        // Calculate start and end dates

        const pixelsRange = await firstValueFrom(this.chart.plotAreaWidth$);
        const relativeStart = handles.startHandle.getFreeDragPosition().x / pixelsRange;
        const relativeEnd = handles.endHandle.getFreeDragPosition().x / pixelsRange;

        const dateRange = this._endDateBoundary.diff(this._startDateBoundary, 'ms');
        const startDate = this._startDateBoundary.clone().add(relativeStart * dateRange, 'ms');
        const endDate = this._startDateBoundary.clone().add(relativeEnd * dateRange, 'ms');

        this.updateSelectedDatesAndInputs(startDate, endDate);
    }

    /**
     * @internal
     * Happens only when input field is used (not when input's values are being
     * changed programmatically).
     */
    onDateChange(input: 'start' | 'end', value: string | null): void {
        const timeSource = this.form.get(input === 'end' ? 'endTime' : 'startTime');
        const dateTime = `${value} ${timeSource?.value}:00:00`;

        // NOTE: This is async operation
        this.onDateTimeChange(input, moment(dateTime));
    }

    /**
     * @internal
     * Happens only when input field is used (not when input's values are being
     * changed programmatically).
     */
    onTimeChange(input: 'start' | 'end', value: string | null): void {
        const dateSource = this.form.get(input === 'end' ? 'endDate' : 'startDate');
        const dateTime = `${dateSource?.value} ${value}:00:00`;

        // NOTE: This is async operation
        this.onDateTimeChange(input, moment(dateTime));
    }

    // --

    private getDateBoundariesFromData(data: Points): void {
        let min = Number.MAX_SAFE_INTEGER;
        let max = Number.MIN_SAFE_INTEGER;

        for (const pt of data) {
            if (pt.x < min) {
                min = pt.x;
            }
            if (pt.x > max) {
                max = pt.x;
            }
        }

        this._startDateBoundary = moment(min);
        this._endDateBoundary = moment(max);
    }

    private async onDateTimeChange(input: 'start' | 'end', m: moment.Moment): Promise<void> {
        if (!m.isValid()) {
            return;
        }

        // Update the selection (only if dates are valid)

        if (input === 'end') {
            await this.updateSelectedDatesAndSelection(undefined, m);
        }
        else {
            await this.updateSelectedDatesAndSelection(m, undefined);
        }
    }

    private checkDates(): void {
        if (this._startDateBoundary && this._endDateBoundary && !this.areDatesSorted(this._startDateBoundary, this._endDateBoundary)) {
            throw new Error('startDate is greater than endDate');
        }
    }

    private areDatesSorted(startDate: Date, endDate: Date): boolean;
    private areDatesSorted(startDate: moment.Moment, endDate: moment.Moment): boolean;
    private areDatesSorted(startDate: Date | moment.Moment, endDate: Date | moment.Moment): boolean {
        if (startDate instanceof Date) {
            startDate = moment(startDate);
        }
        if (endDate instanceof Date) {
            endDate = moment(endDate);
        }

        return startDate.isSameOrBefore(endDate);
    }

    private getSortedHandles(): { startHandle: CdkDrag, endHandle: CdkDrag } | null {
        if (!this.startHandle || !this.endHandle) {
            return null;
        }

        let startHandle: CdkDrag;
        let endHandle: CdkDrag;

        if (this._endHandlePos < this._startHandlePos) {
            startHandle = this.endHandle;
            endHandle = this.startHandle;
        }
        else {
            startHandle = this.startHandle;
            endHandle = this.endHandle;
        }

        return { startHandle, endHandle };
    }

    private update(startTime: moment.Moment, endTime: moment.Moment): void {
        this.updateSelectedDatesAndInputs(startTime, endTime);
        this.updateSelectedDatesAndSelection(startTime, endTime);
    }

    /**
     * Updates the selected start and end dates as well as their input fields.
     */
    private updateSelectedDatesAndInputs(startTime: moment.Moment, endTime: moment.Moment): void {
        this._selectionStartDate = startTime;
        this._selectionEndDate = endTime;
        // Update the result that will be returned from this dialog
        this._result = { startDate: startTime.toDate(), endDate: endTime.toDate() };

        // And update inputs

        this.form.get('startDate')?.setValue(startTime.format('YYYY-MM-DD'), { emitEvent: false });
        this.form.get('startTime')?.setValue(startTime.format('HH'), { emitEvent: false });

        this.form.get('endDate')?.setValue(endTime.format('YYYY-MM-DD'), { emitEvent: false });
        this.form.get('endTime')?.setValue(endTime.format('HH'), { emitEvent: false });
    }

    /**
     * Updates the selected start and end dates as well as the selection rect.
     */
    private async updateSelectedDatesAndSelection(startTime?: moment.Moment, endTime?: moment.Moment): Promise<void> {
        if (!this._startDateBoundary || !this._endDateBoundary || !this.chart) {
            // No boundary dates or chart - no game at all
            return;
        }

        if (!startTime) {
            startTime = this._selectionStartDate;
        }
        else {
            this._selectionStartDate = startTime;
        }

        if (!endTime) {
            endTime = this._selectionEndDate;
        }
        else {
            this._selectionEndDate = endTime;
        }

        if (!startTime || !endTime) {
            // Both dates should be present
            return;
        }
        else if (!this.areDatesSorted(startTime, endTime)) {
            // Dates should be sorted
            return;
        }
        else if (
            !startTime.isSameOrAfter(this._startDateBoundary) ||
            !startTime.isSameOrBefore(this._endDateBoundary) ||
            !endTime.isSameOrAfter(this._startDateBoundary) ||
            !endTime.isSameOrBefore(this._endDateBoundary)
        ) {
            // Dates should be within the selection boundaries
            return;
        }

        // Update the result that will be returned from this dialog
        this._result = { startDate: startTime.toDate(), endDate: endTime.toDate() };

        // Calculate handle positions in pixels

        const dateRange = this._endDateBoundary.diff(this._startDateBoundary, 'ms');
        const relativeStart = startTime.diff(this._startDateBoundary, 'ms') / dateRange;
        const relativeEnd = endTime.diff(this._startDateBoundary, 'ms') / dateRange;

        const pixelsRange = await firstValueFrom(this.chart.plotAreaWidth$);
        const pixelsStart = relativeStart * pixelsRange;
        const pixelsEnd = relativeEnd * pixelsRange;

        // Now update handles and the selection

        this.startHandle?.setFreeDragPosition({
            x: pixelsStart,
            y: this.startHandle.getFreeDragPosition().y
        });

        this.endHandle?.setFreeDragPosition({
            x: pixelsEnd,
            y: this.endHandle.getFreeDragPosition().y
        });

        this.updateSelectionRect(pixelsStart, pixelsEnd);
    }

    /**
     * Updates the selection rect position and size according to handles.
     * IMPORTANT: Handles should be sorted asc by their X positions.
     */
    private updateSelectionRect(startHandle: CdkDrag, endHandle: CdkDrag): void;
    private updateSelectionRect(startPos: number, endPos: number): void;
    private updateSelectionRect(start: CdkDrag | number, end: CdkDrag | number): void {
        if (start instanceof CdkDrag) {
            start = start.getFreeDragPosition().x;
        }
        if (end instanceof CdkDrag) {
            end = end.getFreeDragPosition().x;
        }

        this._selectionX$.next(start);
        this._selectionWidth$.next(end - start);
    }

}
