import { Meterage } from '@cyberloop/web/wells/model';

export const METERAGE_FEATURE = 'WELL_METERAGE';

export type LoadingState = 'NOT_LOADED' | 'LOADED' | 'LOADING';
/**
 * State for wells
 * */
export interface MeterageState {
    meterageLoadingState: LoadingState;
    meterageLoadError?: Error;
    meterage: Meterage | null;
    meterageIsLoaded: boolean;
}

export function getDefaultMeterageState(): MeterageState {
    return {
        meterageLoadingState: 'NOT_LOADED',
        meterage: null,
        meterageIsLoaded: false
    };
}
