import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

import { IconComponent, ThemeSwitcherComponent } from '@cyberloop/core';

@Component({
    selector: 'cyberloop-user-menu',
    standalone: true,
    imports: [
        IconComponent,
        ThemeSwitcherComponent,
        MatRippleModule,
        NgIf
    ],
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent {
    @Input() userAvatar: string | null = '';
    @Input() displayName: string | null = '';
    @Input() login: string | null = '';

    @Output() readonly logout = new EventEmitter<void>();
    @Output() readonly openUnitsSelector = new EventEmitter<void>();

    public onOpenUnitsSelector() {
        this.openUnitsSelector.emit();
    }
}
