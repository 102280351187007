import { ChartEx, Range } from '@cyberloop/core';

export abstract class ChartSyncHelper {
    
    /**
     * Register the chart 
     * @param chart 
     */
    abstract registerChart(chart: ChartEx): void; 

    /**
     * Unregister the chart 
     * @param chart 
     */
    abstract unregisterChart(chart: ChartEx): void; 

    /**
     * Sync pan
     * @param range 
     */
    abstract setViewport(source: ChartEx, range: Range): void;

    /**
     * Show tooltips on all registered charts
     * @param src charts when tooltips fired
     * @param val value to sync with all other registered charts
     */
    abstract syncTooltips(src: Highcharts.Chart | undefined, val: number): void

    /**
     * Hide tooltips on all registered charts
     * @param src charts when tooltips fired
     * @param val value to sync with all other registered charts
     */
    abstract hideAllTooltips(): void;

    /**
     * Return tooltip position object
     */
    abstract getTooltipPositioner(isMaster: boolean): Highcharts.TooltipPositionerCallbackFunction;

    /**
     * Set pan process status
     * @param status 
     */
    abstract panInProcess(status: boolean): void; 
}