import { Injectable } from '@angular/core';

import { ID } from '@cyberloop/core';
import { MeterageDataService } from '@cyberloop/web/app/data';
import { Meterage, MeterageItem } from '@cyberloop/web/wells/model';
import { cloneDeep } from 'lodash';
import { EMPTY, Observable, finalize, first, map, shareReplay, switchMap } from 'rxjs';

import * as moment from 'moment';

import { ClApplicationManager } from './internals/client-app/cl-app-manager';

export const METERAGES_COLLECTION_NAME = 'meterages';

@Injectable({
    providedIn: 'root'
})
export class FirebaseMeterageDataService extends MeterageDataService {

    private _listObservable: Observable<Meterage[]> | undefined;
    private _meterageObservables: Record<ID, Observable<Meterage | null> | undefined> = {};

    constructor(private readonly appMgr: ClApplicationManager) {
        super();
    }

    watchAll() {
        return this._listObservable ??= this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().watchCollection<Meterage>(
                    METERAGES_COLLECTION_NAME
                ) ?? EMPTY
            ),
            finalize(() => this._listObservable = undefined),
            shareReplay(1)
        );
    }

    watchById(id: ID) {
        return this._meterageObservables[id] ??= this.appMgr.tenantApp$.pipe(
            switchMap(x => {
                return x?.getFirestore().watch<Meterage>(
                    this.getPath(METERAGES_COLLECTION_NAME, id)
                ) ?? EMPTY;
            }),
            finalize(() => delete this._meterageObservables[id]),
            shareReplay(1),
            map(cloneDeep)
        );
    }

    saveMeterage(id: ID, itemList: MeterageItem[]): Observable<Meterage> {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x =>
                    x?.getFirestore().set<Meterage>(
                        this.getPath(METERAGES_COLLECTION_NAME, id),
                        {
                            id,
                            itemList,
                            updatedAt: moment.now()
                        }
                    ) ?? EMPTY
                ),
                switchMap(() => this.watchById(id).pipe(first()) as Observable<Meterage>),
                shareReplay(1)
            );
    }

    private getPath(...args: string[]) {
        return args.join('/');
    }

}
