import { Injectable } from '@angular/core';

import { Well, WellInfoProviderService } from '@cyberloop/core';
import { isEqual } from 'lodash';
import { Observable, distinctUntilChanged, map, shareReplay } from 'rxjs';

import { RigInfoProviderServiceImpl } from './rig-info-provider.service.impl';

@Injectable({
    providedIn: 'root'
})
export class WellInfoProviderServiceImpl extends WellInfoProviderService {
    /**
     *
     */
    constructor(
        private readonly rip: RigInfoProviderServiceImpl
    ) {
        super();
    }

    watchWells(): Observable<Well[]> {
        return this.rip.rigs$.pipe(
            map(rigs => {
                const wells: Well[] = [];

                for (const rig of rigs) {
                    for (const well of rig.wells) {
                        wells.push({
                            id: String(well.id),
                            name: well.name,
                            rig: rig.name,
                            startTime: new Date(well.startedAt),
                            releaseTime: well.releasedAt ? new Date(well.releasedAt) : undefined,
                            spudTime: well.spuddedAt ? new Date(well.spuddedAt) : undefined,
                            suspendTime: well.suspendedAt ? new Date(well.suspendedAt) : undefined,
                            location: {
                                latitiude: well?.location?.latitude ?? undefined,
                                longitude: well?.location?.longitude ?? undefined
                            },
                            sections: well.sections.map(s => ({
                                id: s.number,
                                name: s.name,
                                parent: s.parent?.number,
                                from: s.startDepth,
                                startTime: new Date(s.startedAt),
                                endTime: s.endedAt ? new Date(s.endedAt) : undefined,
                                to: s.endDepth ?? undefined
                            })),
                            stats: well.stats
                        });
                    }
                }

                return wells;
            }),
            distinctUntilChanged((a, b) => isEqual(a, b)),
            shareReplay(1)
        );
    }
}
