import { createReducer, on } from '@ngrx/store';

import { MeterageActions } from './meterage.actions';
import { MeterageState, getDefaultMeterageState } from './meterage.state';

export const meterageReducer = createReducer<MeterageState>(
    getDefaultMeterageState(),

    on(MeterageActions.loadMeterage, (state): MeterageState => ({
        ...state,
        meterageLoadingState: 'LOADING'
    })),
    on(MeterageActions.meterageLoaded, (state, { meterage }): MeterageState => ({
        ...state,
        meterage,
        meterageLoadingState: 'LOADED',
        meterageIsLoaded: true
    })),
    on(MeterageActions.meterageLoadError, (state, { error }): MeterageState => ({
        ...state,
        meterageLoadError: error,
        meterageLoadingState: 'NOT_LOADED'
    }))
);