export function trimFileExtension(filename: string) {
    const parts = filename.split('.');

    if (parts.length === 1) {
        return filename;
    }
    else if (parts.length === 2 && parts[0] === '') {
        return filename;
    }

    parts.pop();

    return parts.join('.');
}

export function getFileExtension(filename: string) {
    const parts = filename.split('.');

    if (parts.length === 1 || (parts.length === 2 && parts[0] === '')) {
        return null;
    }

    return parts.pop() ?? null;
}
