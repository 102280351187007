<cyberloop-kpi-widget-settings class="settings" (close)="onCancel()">

    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <fieldset>
            <legend>Select section</legend>
            <cl-selector formControlName="sectionId" [options]="sections$ | async" placeholder="Select a section..."></cl-selector>
        </fieldset>

        <!-- Units -->

        <fieldset>
            <legend>Select length units</legend>
            <cl-button-toggle
                formControlName="lengthUnits"
                selectionType="radio"
                [list]="(lengthUnits$ | async) ?? []"
                class="units-selector"
            ></cl-button-toggle>
        </fieldset>

        <fieldset>
            <legend>Select time units</legend>
            <cl-button-toggle
                formControlName="timeUnits"
                selectionType="radio"
                [list]="(timeUnits$ | async) ?? []"
                class="units-selector"
            ></cl-button-toggle>
        </fieldset>

        <section class="actions">
            <button type="button" class="cl-flat-button" (click)="onCancel()">Cancel</button>
            <button type="submit" class="cl-primary-button">Apply</button>
        </section>

    </form>

</cyberloop-kpi-widget-settings>