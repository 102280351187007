import { EnvironmentProviders } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { DrillingEffects } from './drilling.effects';
import { drillingReducer } from './drilling.reducer';
import { DRILLING_FEATURE } from './drilling.state';

export function getDrillingProviders(): EnvironmentProviders[] {
    return [
        provideEffects([DrillingEffects]),
        provideState(DRILLING_FEATURE, drillingReducer)
    ];
}