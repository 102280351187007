import { Observable } from 'rxjs';

import { AssetDailyMetricsWidgetData, BitBottomWidgetData, CasingRunningWidgetData, ConnectionTimeWidgetData, CustomVsTimeWidgetData, DaysVsDepthWidgetData, RateOfAdvanceWidgetData, RigActivityWidgetData, RigStatePerHourWidgetData, RigStateWidgetData, RopWidgetData, SlideRotaryWidgetData, SlideVsRotaryWidgetData, TrippingSpeedWidgetData } from './widget-data';
import { WidgetType } from './widget-type';

/**
 * Provides a data for KPI widget by it's type
 */
export abstract class WidgetDataProvider {

    /**
     * Provides Asset Daily Metrics widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.AssetDailyMetrics): Observable<AssetDailyMetricsWidgetData>;
    /**
     * Provides Bit On / Off Bottom widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.BitBottom): Observable<BitBottomWidgetData>;
    /**
     * Provides Connection Time widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.ConnectionTime): Observable<ConnectionTimeWidgetData>;
    /**
     * Provides Days vs Depth widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.DaysVsDepth): Observable<DaysVsDepthWidgetData>;
    /**
     * Provides Rig Activity widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.RigActivity): Observable<RigActivityWidgetData>;
    /**
     * Provides Rig State widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.RigState): Observable<RigStateWidgetData>;
    /**
     * Provides Rig State per Hour widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.RigStatePerHour): Observable<RigStatePerHourWidgetData>;
    /**
     * Provides Slide / Rotary widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.SlideRotary): Observable<SlideRotaryWidgetData>;
    /**
     * Provides Slide / Rotary per Joint widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.SlideVsRotary): Observable<SlideVsRotaryWidgetData>;
    /**
     * Provides Tripping Speed widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.TrippingSpeed): Observable<TrippingSpeedWidgetData>;
    /**
     * Provides Casing Running widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.CasingRunning): Observable<CasingRunningWidgetData>;
    /**
     * Provides Rate of Advance widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.RateOfAdvance): Observable<RateOfAdvanceWidgetData>;
    /**
     * Provides ROP widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.ROP): Observable<RopWidgetData>;
    /**
     * Provides Custom vs Time widget data
     * @param id Widget ID
     * @param type Widget type
     */
    abstract getData(id: string, type: WidgetType.CustomVsTime): Observable<CustomVsTimeWidgetData>;

}