import { WellKnownUnitGroupIds, WellKnownUnitIds } from '@cyberloop/core';

import { TndChart } from './common';

/**
 * Settings object of a T&D chart
 */
export type TndChartSettings = {
    xUnitId?: WellKnownUnitIds,
    yUnitId: WellKnownUnitIds
};

/**
 * Default settings of a T&D chart
 */
export const tndChartDefaultSettings: TndChartSettings = {
    yUnitId: WellKnownUnitIds.Meter
};

/**
 * Returns default X axis unit of a T&D chart depending on type
 */
export function getDefaultXUnitId(type: TndChart): WellKnownUnitIds {
    switch (type) {

        // Rotation
        case TndChart.Torque:
            return WellKnownUnitIds.NewtonMeter;

        // Weight
        default:
            return WellKnownUnitIds.Kilogram;
    }
}

/**
 * Returns default X axis unit group of a T&D chart depending on type
 */
export function getDefaultXUnitGroup(type: TndChart): WellKnownUnitGroupIds {
    switch (type) {

        // Rotation
        case TndChart.Torque:
            return WellKnownUnitGroupIds.Torque;

        // Weight
        default:
            return WellKnownUnitGroupIds.Weight;
    }
}
