import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { DatetimePipe, IconComponent } from '@cyberloop/core';
import { Link } from '@cyberloop/web/planning/shared/model';

import type { Moment } from 'moment';

@Component({
    selector: 'cyberloop-head',
    standalone: true,
    imports: [
        NgFor,
        DatetimePipe,
        RouterLink,
        RouterLinkActive,
        IconComponent
    ],
    templateUrl: './head.component.html',
    styleUrls: ['./head.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadComponent {
    @Input() well!: string;
    @Input() rig!: string;
    @Input() plannedStartDate?: Moment | null;
    @Input() nav?: Link[];
    @Input() loading?: boolean | null = false;

    @Output() readonly collapse = new EventEmitter();

    onCollapse() {
        this.collapse.emit();
    }
}
