import { Toolface } from '@cyberloop/web/wells/model';

import type { Observable } from 'rxjs';

/** Base class for well information provider. Used as Injection token */
export abstract class TFArrayProviderSerivce {
    /** Get toolface data */
    abstract getTFArrayData(rigName: string): Observable<Toolface[]>;

    /** Watch toolface data */
    abstract watchTFArrayData(rigName: string): Observable<Toolface[]>;
}