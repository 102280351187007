import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { isNil, round } from 'lodash';

/** The Direction type represents the scrolling direction of an element, which can be horizontal ('x') or vertical ('y'). */
type Direction = 'x' | 'y';

/**
    Synchronizes the scrolling of one or more elements with the same scrolling direction.
    Direction argument ('dir') represents the scrolling direction of an element, which can be horizontal ('x') or vertical ('y').
    Default: horizontal and vertical.
    @remarks
    This directive allows multiple elements to scroll in unison by synchronizing their scroll positions.
    @example
    <div *syncScroll="mate"></div>
    <div #mate></div>

    <div *syncScroll="[mate, threesome]; dir: 'x'"></div>
    <div #mate></div>
    <div #threesome></div>
*/
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[syncScroll]',
    standalone: true
})
export class SyncScrollDirective {
    /** @internal */
    private scrollableElements: Element[] = [];

    /** @internal */
    private hoveredElement?: Element

    /** @internal */
    private direction?: Direction;

    /** @internal */
    constructor(
        private readonly templateRef: TemplateRef<HTMLElement>,
        private readonly viewContainerRef: ViewContainerRef,
    ) { }

    /**
    Sets the scrolling direction of the synchronized elements.
    @param value - The scrolling direction of the synchronized elements. Can be 'x' for horizontal scrolling or 'y' for vertical scrolling.
    @input
    */
    @Input() set syncScrollDir(value: Direction) {
        this.direction = value;
    }

    /**
    Sets the elements to be synchronized.
    @param context - The element or elements to be synchronized.
    @input
    */
    @Input('syncScroll') set var(context: Element | Element[] | any) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);

        if (Array.isArray(context)) {
            this.scrollableElements = context;
        } else {
            this.scrollableElements = [context];
        }

        const hostElement = (this.templateRef.elementRef as ElementRef<Element>).nativeElement.previousElementSibling;

        if (isNil(hostElement)) {
            console.warn('hostElement not found');
            return;
        }

        if (!this.scrollableElements.length) {
            return;
        }

        this.scrollableElements.push(hostElement);

        this.scrollableElements.forEach(scrollableElement => {
            scrollableElement.addEventListener('scroll', this.scrollCallback.bind(this))
            scrollableElement.addEventListener('mouseover', this.onMouseOverCallback.bind(this))
            scrollableElement.addEventListener('mouseout', this.onMouseOutCallback.bind(this))
        })
    }

    /** @internal */
    private onMouseOverCallback(event: Event) {
        this.hoveredElement = event.target as Element
    }

    /** @internal */
    private onMouseOutCallback() {
        this.hoveredElement = undefined
    }

    /** @internal */
    private scrollTriggeredElements: Element[] = []

    /** @internal */
    private scrollCallback(event: Event) {
        const target = event.target as Element;

        let index = this.scrollTriggeredElements.indexOf(target)
        if(index !== -1) {
            this.scrollTriggeredElements.splice(index, 1)
            return;
            //If scroll event was triggered from this function -> no action
            //This is necessary to avoid looping and fps drops
        }

        this.scrollableElements.forEach(scrollableElement => {
            if (scrollableElement !== event.target && (target.contains(this.hoveredElement as Node) || this.hoveredElement?.contains(target as Node))) {
                if (isNil(this.direction) || this.direction === 'x') {
                    const scrollLeftPercent = target.scrollLeft / (target.scrollWidth - target.clientWidth);
                    this.scrollTriggeredElements.push(scrollableElement)
                    scrollableElement.scrollLeft = round((scrollableElement.scrollWidth - scrollableElement.clientWidth) * scrollLeftPercent);
                }

                if (isNil(this.direction) || this.direction === 'y') {
                    const scrollTopPercent = target.scrollTop / (target.scrollHeight - target.clientHeight);
                    this.scrollTriggeredElements.push(scrollableElement)
                    scrollableElement.scrollTop = round((scrollableElement.scrollHeight - scrollableElement.clientHeight) * scrollTopPercent);
                }
            }
        });
    }
}
