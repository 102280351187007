<div class="container" (click)="$event.stopPropagation();">
  <header>
    <div class="section">Section</div>
    <div class="start">Start depth</div>
    <div class="end">End depth</div>
    <div class="parent">Parent</div>
  </header>
  <main>
    <ng-container *ngIf="sections && sections.length > 0">
      <button class="section" *ngFor="let section of sections; let last = last" [class.active]="section.id === current" 
        (click)="selectSection.emit(section.id); $event.preventDefault()">
        <div class="section">
          <cyberloop-green-arrows *ngIf="last"></cyberloop-green-arrows>
          {{ section.name }}
        </div>
        <div class="start">{{ section.from | formatNumber }}</div>
        <div class="end">{{ section.to | formatNumber }}</div>
        <div class="parent">{{ section.parent ? getSectionWithID(section.parent)?.name : 'Top hole'}}</div>
      </button>
    </ng-container>
  </main>
</div>