import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, ClInputComponent, ConfirmationDialogService, Entity, FormErrorsComponent, IconComponent, PopupContent, SaveTemplateDialogData, Template, TemplateAccess } from '@cyberloop/core';

/** Controls enum */
enum Controls {
    /** Acess key */
    Access = 'access',
    /** Name key */
    Name = 'name',
    /** Description key */
    Description = 'description',
}

/** Popup save template component */
@Component({
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        IconComponent,
        ClButtonToggleComponent,
        ClInputComponent,
        FormErrorsComponent
    ],
    templateUrl: './save-template.component.html',
    styleUrls: ['./save-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveTemplateComponent<T extends Template> implements PopupContent<SaveTemplateDialogData<T> | undefined>  {
    /** @private */
    private _close!: (result?: SaveTemplateDialogData<T>) => void;

    /** @internal */
    constructor(
        private readonly fb: FormBuilder,
        private readonly confirmationDialogService: ConfirmationDialogService
    ) {
    }

    /** @internal */
    readonly templateAccessList: Entity<TemplateAccess>[] = [
        {
            id: TemplateAccess.Public,
            name: TemplateAccess.Public
        },
        {
            id: TemplateAccess.Personal,
            name: TemplateAccess.Personal
        }
    ];

    /** @internal */
    readonly Controls = Controls;

    /** @internal */
    readonly form = this.fb.group({
        [Controls.Access]: [TemplateAccess.Personal],
        [Controls.Name]: ['', Validators.required],
        [Controls.Description]: ['']
    });

    /** @internal */
    get nameControl() {
        return this.form.get(Controls.Name) as FormControl;
    }

    /** @internal */
    setData(data: SaveTemplateDialogData<T>): void {
        this.form.setValue(data);
    }

    /** @internal */
    setClose(fn: (result?: SaveTemplateDialogData<T>) => void): void {
        this._close = fn;
    }

    /** @internal */
    onSave() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this._close(this.form.value as SaveTemplateDialogData<T>);
    }

    /** @internal */
    async close() {
        if (this.form.dirty) {
            if (!await this.confirmationDialogService.show('You have unsaved changes. Do you really want to close?')) {
                return;
            }
        }

        this._close();
    }
}
