import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { TndModelKind, TorqueAndDragTorqueModel, TorqueAndDragWeightsModel } from '../../internals/tnd';

export type TorqueAndDragModelsListInWellResult = {
    well: {
        torqndrag: {
            models: {
                total: number,
                edges: (TorqueAndDragWeightsModel | TorqueAndDragTorqueModel)[]
            }
        }
    }
}

type TorqueAndDragModelsListInWellInput = {
    wellId: string,
    kind: TndModelKind,
    activeOnly?: boolean,
}

@Injectable({
    providedIn: 'root'
})
export class TorqueAndDragModelsListInWellQuery extends GraphQLQuery<TorqueAndDragModelsListInWellResult, TorqueAndDragModelsListInWellInput> {
    override readonly document = `
        query GetTorqueAndDragModelsInWell($wellId: ID!, $kind: TorqndragModelKind!, $activeOnly: Boolean) {
            well(id: $wellId) {
                ... on Well {
                    torqndrag {
                        models(kind: $kind, activeOnly: $activeOnly) {
                            total
                            edges {
                                id
                                name
                                valueUnitId
                                depthUnitId
                                startDepth
                                endDepth
                                pushAlarmLine
                                pullAlarmLine
                                highTorqueAlarmLine
                            }
                        }
                    }
                }
            }
        }
    `;
}
