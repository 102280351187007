import { createFeatureSelector, createSelector } from '@ngrx/store';

import { METERAGE_FEATURE, MeterageState } from './meterage.state';

const feature = createFeatureSelector<MeterageState>(METERAGE_FEATURE);
const meterageLoading = createSelector(feature, state => state.meterageLoadingState === 'LOADING');
const meterageLoaded = createSelector(feature, state => state.meterageLoadingState === 'LOADED');
const meterageNotLoaded = createSelector(feature, state => state.meterageLoadingState === 'NOT_LOADED');
const meterage = createSelector(feature, state => state.meterage);
const id = createSelector(feature, state => state.meterage?.id);
const itemList = createSelector(feature, state => state.meterage?.itemList ?? []);

const meterageIsLoading = createSelector(feature, state => !state.meterageIsLoaded);

/**
 * Selectors for meterage
 * */
export const MeterageSelectors = {
    meterageLoading,
    meterageLoaded,
    meterageNotLoaded,
    id,
    meterage,
    itemList,
    meterageIsLoading
};
