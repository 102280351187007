import { Injectable } from '@angular/core';

import { CoreActions, CoreSelectors, LiveData, LiveDataProviderService, NotificationService, Range, SettingsProviderService, Well, WellKnownParams, depthRangeIncludesParents, uuid } from '@cyberloop/core';
import { DrillingChartSettings, Mode, defaultDepthRange, defaultDrillingChartSettings, defaultTimeRange } from '@cyberloop/web/wells/model';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { cloneDeep, isEqual, isNil } from 'lodash';
import { combineLatest, debounceTime, distinctUntilChanged, filter, first, firstValueFrom, map, of, startWith, switchMap } from 'rxjs';

import { DrillingActions } from './drilling.actions';
import { DrillingSelectors } from './drilling.selectors';
import { DRILLING_FEATURE } from './drilling.state';

const DRILLING_CHART = 'drilling.chart';

const initAction = createAction(`[${DRILLING_FEATURE}] Initialize drilling`);

@Injectable()
export class DrillingEffects implements OnInitEffects {
    private _prevState: DrillingChartSettings = {} as any;
    private _currState: DrillingChartSettings = {} as any;

    constructor(
        private readonly actions$: Actions,
        private readonly settings: SettingsProviderService,
        private readonly store: Store,
        private readonly liveData: LiveDataProviderService,
        private readonly notify: NotificationService
    ) {
        store.select(DrillingSelectors.settings).pipe(
            distinctUntilChanged((a, b) => {
                this._prevState = a;
                this._currState = b;
                return isEqual(a, b);
            })
        ).subscribe();
    }

    readonly onInitAction$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(() => this.settings.watchSettings<DrillingChartSettings>(DRILLING_CHART, defaultDrillingChartSettings)),
        filter(Boolean),
        map(settings => DrillingActions.setSettings({ settings }))
    ));

    readonly updateSettings$ = createEffect(() => this.actions$.pipe(
        ofType(
            DrillingActions.setMode,
            DrillingActions.setOrientation,
            DrillingActions.setChartToShow,
            DrillingActions.setWorkspaceId,
            DrillingActions.editAsset,
            DrillingActions.createWorkspace,
            DrillingActions.editWorkspace,
            DrillingActions.deleteWorkspace,
            DrillingActions.setWorkspaceId
        ),
        debounceTime(300),
        switchMap(async () => {

            //#region remove after 21.07.2023
            const state = cloneDeep(this._currState);
            const depthWs = state.depthWs;
            const timeWs = state.timeWs;
            for (const modeWs of [depthWs, timeWs]) {
                for (const ws of modeWs) {
                    if (!ws.id) {
                        ws.id = uuid();
                    }
                }
            }
            //#endregion

            try {
                return await this.settings.updateSettings<DrillingChartSettings>(DRILLING_CHART, state/*this._currState*/);
            }
            catch (e) {
                return of(false);
            }
        }),
        switchMap(x => {
            if (!x) {
                this.notify.error('Cannot updated drilling settings');
                return [DrillingActions.rollBackSettings({ settings: this._prevState })];
            }
            else {
                this.notify.info('Drilling settings updated');
                return [];
            }
        })
    ));

    /** @deprecated get rid of this */
    readonly changeLiveViewport$ = createEffect(() => this.actions$.pipe(
        ofType(DrillingActions.setLiveViewport),
        switchMap(async (arg) => await firstValueFrom(
            combineLatest([
                this.store.select(DrillingSelectors.viewport),
                this.store.select(DrillingSelectors.isTime),
                this.store.select(DrillingSelectors.lastLiveWellDepth),
                of(arg)
            ])
        )),
        map(([vp, isTime, lastDepth, arg]) => {
            if (typeof vp?.from !== 'number' || typeof vp?.to !== 'number' || typeof lastDepth !== 'number') {
                return undefined;
            }
            // TODO Maybe add range in state?
            const reset = arg.reset;
            const diff = vp.to - vp.from;
            const to = isTime ? Date.now() : lastDepth;
            const range = {
                from: to - (reset ? (isTime ? defaultTimeRange : defaultDepthRange) : diff),
                to
            };

            reset && console.log('Viewport reseted to ', (range.to - range.from) / 1000);

            return { range, isTime };
        }),
        filter(Boolean),
        distinctUntilChanged((a, b) => isEqual(a, b)),
        map(({ range, isTime }) => isTime ? DrillingActions.setTimeViewport({ range }) : DrillingActions.setDepthViewport({ range }))
    ));

    readonly onLiveToggle$ = createEffect(() => this.actions$.pipe(
        ofType(DrillingActions.liveToggle),
        switchMap(async () => await firstValueFrom(combineLatest([
            this.store.select(DrillingSelectors.live),
            this.store.select(DrillingSelectors.viewport),
            this.store.select(DrillingSelectors.isTime),
            this.store.select(DrillingSelectors.lastLiveWellDepth)
        ]))),
        switchMap(([live, viewport, isTime, lastDepth]) => {
            if (live && viewport?.to && viewport?.from) {
                const duration = viewport.to - viewport.from;

                let range: Range | undefined;
                if (isTime) {
                    range = {
                        from: Date.now() - duration,
                        to: Date.now()
                    };
                    return [DrillingActions.setTimeViewport({ range })];
                }
                else {
                    if (lastDepth) {
                        range = {
                            from: lastDepth - duration,
                            to: lastDepth
                        };
                    }
                    return [DrillingActions.setDepthViewport({ range })];
                }
            }
            else {
                return [];
            }
        })
    ));

    readonly modeChanged$ = createEffect(() => this.actions$.pipe(
        ofType(DrillingActions.setMode),
        distinctUntilChanged(isEqual),
        switchMap(mode => combineLatest([
            this.store.select(DrillingSelectors.wellViewport),
            this.store.select(DrillingSelectors.viewport),
            this.store.select(DrillingSelectors.live),
            this.store.select(DrillingSelectors.lastLiveWellDepth).pipe(startWith(undefined))
        ]).pipe(
            map(([wellVp, vp, live, lastWde]) => ({ mode, wellVp, vp, live, lastWde })),
            first()
        )),
        switchMap(({ mode, wellVp, vp, live, lastWde }) => {
            const isTime = mode.mode === Mode.Time;

            const range = this.getInitRange(isTime, live, vp, wellVp, lastWde);
            return [isTime ? DrillingActions.setTimeViewport({ range }) : DrillingActions.setDepthViewport({ range })];
        })
    ));

    readonly sectionChanged$ = createEffect(() => this.actions$.pipe(
        ofType(DrillingActions.changeSectionId),
        switchMap(section => this.store.select(CoreSelectors.currentWell)
            .pipe(
                map((well => ({ section, well })),
                    first()
                ))),
        switchMap(({ section, well }) => {
            const isWellLive = !well?.releaseTime && !well?.suspendTime;
            const sections = well?.sections;
            const currentSection = sections?.find(x => x.id.toString() === section.sectionId);

            if (well && !well?.releaseTime) {
                return this.liveData.getForRigAndTag(well.rig, WellKnownParams.WDE).pipe(
                    map(liveData => liveData.value),
                    map(lastDepth => ({ sections, currentSection, lastDepth, isWellLive })),
                    first(),
                    startWith({ sections, currentSection, lastDepth: undefined, isWellLive })
                );
            }
            else {
                return of({ sections, currentSection, lastDepth: undefined, isWellLive });
            }
        }),
        map(({ sections, currentSection, lastDepth, isWellLive }) => {
            if (!currentSection) {
                return { range: undefined, live: false };
            }

            const live = !currentSection.endTime && !currentSection.to && isWellLive;
            const to = currentSection?.to ?? lastDepth; // TODO check if well/section is live

            const range = depthRangeIncludesParents(sections, currentSection.id);
            if (isNil(to)) {
                return { range: undefined, live };
            }

            return {
                range: {
                    from: range?.from ?? currentSection.from,
                    to
                },
                live: !currentSection.endTime && !currentSection.to
            };
        }),
        switchMap(({ range, live }) => combineLatest([
            this.store.select(DrillingSelectors.viewport),
            this.store.select(DrillingSelectors.isTime)
        ]).pipe(
            map(([vp, isTime]) => ({ range, vp, isTime, live })),
            first()
        )),
        switchMap(({ vp, range, isTime, live }) => {
            let newVp: Range | undefined;
            
            if (vp && typeof range?.to === 'number') {
                const duration = !isTime ? (vp?.to - vp?.from) ?? defaultDepthRange : defaultDepthRange;
                newVp = {
                    from: range.to - duration,
                    to: range.to
                };
            }
            else if (typeof range?.to === 'number') {
                newVp = {
                    from: range.to - defaultDepthRange,
                    to: range.to
                };

                if (!isTime && newVp.from < 0) {
                    newVp.from = 0;
                }
            } 
            else {
                if (!isTime) {
                    newVp = {
                        from: range?.from ?? 0,
                        to: (range?.from ?? 0) + defaultDepthRange
                    };
                }
                range = newVp;
            }

            return [
                DrillingActions.setDepthViewport({ range: newVp }),
                DrillingActions.setDepthWellViewport({ range }),
                DrillingActions.setLive(({ live }))
            ];
        })
    ));

    readonly panChanged$ = createEffect(() => this.actions$.pipe(
        ofType(DrillingActions.panChangedViewport),
        switchMap(pan => this.store.select(DrillingSelectors.isTime).pipe(
            map(isTime => ({ pan, isTime }))
        )),
        map(({ pan, isTime }) => isTime ? DrillingActions.setTimeViewport({ range: pan.range }) : DrillingActions.setDepthViewport({ range: pan.range })))
    );

    readonly onZoomViewport$ = createEffect(() => this.actions$.pipe(
        ofType(DrillingActions.zoomViewport),
        debounceTime(200),
        switchMap(({ zoomIn }) =>
            combineLatest([
                this.store.select(DrillingSelectors.live),
                this.store.select(DrillingSelectors.viewport),
                this.store.select(DrillingSelectors.wellViewport),
                of(zoomIn),
                this.store.select(CoreSelectors.currentWell),
                this.store.select(DrillingSelectors.isTime)
            ]).pipe(first())
        ),
        map(([live, vp, wellVp, zoomIn, well, isTime]) => {
            if (typeof wellVp?.to !== 'number' || typeof wellVp?.from !== 'number' || typeof vp?.to !== 'number' || typeof vp?.from !== 'number') {
                return;
            }
            const currentRange = vp?.to - vp.from;
            const wellRange = wellVp.to - wellVp.from;
            const defaultRange = isTime ? defaultTimeRange : defaultDepthRange;

            if (zoomIn && currentRange <= defaultRange || !zoomIn && currentRange === wellRange) {
                return;
            }

            const range = { ...vp };
            if (live) {
                range.from = zoomIn ? range.to - currentRange / 2 : range.to - currentRange * 2;
            }
            else {
                range.from = zoomIn ? range.from + currentRange / 4 : range.from - currentRange / 2;
                range.to = zoomIn ? range.to - currentRange / 4 : range.to + currentRange / 2;
            }

            const newRange = range.to - range.from;

            console.log('zoom changed ', currentRange / 1000, ' => ', newRange / 1000);

            if (newRange >= wellRange) {
                if (isNil(wellVp.to) || isNil(wellVp.from)) {
                    return;
                }
                return { range: { from: wellVp.from, to: wellVp.to }, isTime };
            }

            if (newRange < defaultRange) {
                if (live) {
                    const to = wellVp.to;
                    if (isNil(to)) {
                        return;
                    }
                    range.from = wellVp.to - defaultRange;
                    range.to = to;
                }
                else {
                    const diff = (defaultRange - newRange) / 2;
                    range.from = range.from - diff;
                    range.to = range.to + diff;
                }

                return { range, isTime };
            }

            const to = isTime ? well?.releaseTime?.getTime() ?? wellVp.to ?? Date.now() : wellVp.to;

            if (range.to > to) {
                range.to = to;
                range.from = range.to - newRange;
            }

            if (range.from < wellVp.from) {
                range.from = wellVp.from;
                range.to = range.from - newRange;
            }

            return { range, isTime };
        }),
        distinctUntilChanged((a, b) => isEqual(a, b)),
        filter(Boolean),
        map(({ range, isTime }) => isTime ? DrillingActions.setTimeViewport({ range }) : DrillingActions.setDepthViewport({ range }))
    ));

    readonly liveWDEValue$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(() => this.store.select(CoreSelectors.currentWell)),
        filter(Boolean),
        switchMap(well => this.liveData.getForRigAndTag(well.rig, WellKnownParams.WDE)),
        map(liveData => liveData.value),
        distinctUntilChanged(isEqual),
        map(depth => DrillingActions.setLastLiveWdeDepth({ depth }))
    ));

    readonly wellChange$ = createEffect(() => this.actions$.pipe(
        ofType(CoreActions.setCurrentWell),
        debounceTime(300),
        distinctUntilChanged(isEqual),
        switchMap(() => this.store.select(DrillingSelectors.settingsLoaded)),
        filter(isLoaded => isLoaded),
        switchMap(() => this.store.select(CoreSelectors.currentWell)
            .pipe(
                filter(Boolean),
                first()
            )),
        switchMap(well => combineLatest([
            of(well),
            this.store.select(DrillingSelectors.isTime),
            this.liveData.getForRigAndTag(well?.rig, WellKnownParams.WDE).pipe(
                startWith({})
            )
        ]).pipe(
            first()
        )),
        distinctUntilChanged(isEqual),
        switchMap(([well, isTime, lastWde]: [well: Well, isTime: boolean, lastWde: LiveData]) => {
            const sections = cloneDeep(well?.sections);
            sections?.sort((a, b) => b.id - a.id);
            const lastSection = sections[0];
            const id = lastSection?.id;
            const depthRange = depthRangeIncludesParents(sections, id);
            const wellVp = {
                from: isTime ? well.startTime.getTime() : depthRange?.from ?? 0,
                to: isTime ? well?.releaseTime?.getTime() ?? well?.suspendTime?.getTime() ?? Date.now() : depthRange?.to ?? 0
            };

            let live = false;
            if (well && !well.releaseTime && !well.suspendTime) {
                live = true;
            }

            const range = this.getInitRange(isTime, live, undefined, wellVp, lastWde.holeDepth);

            if (id) {
                return [
                    DrillingActions.changeSectionId({ sectionId: id.toString() }),
                    DrillingActions.setLive({ live }),
                    isTime ? DrillingActions.setTimeViewport({ range }) : DrillingActions.setDepthViewport({ range })
                ];
            }

            return [
                DrillingActions.setLive({ live }),
                isTime ? DrillingActions.setTimeViewport({ range }) : DrillingActions.setDepthViewport({ range })
            ];
        })
    ));

    ngrxOnInitEffects(): Action {
        return initAction();
    }

    private getInitRange(isTime: boolean, live: boolean, vp: Range | undefined, wellVp: Range | undefined, lastWde: number | undefined): Range | undefined {
        const duro = typeof vp?.from === 'number' ? vp.to - vp?.from : (isTime ? defaultTimeRange : defaultDepthRange);

        let range: Range | undefined = undefined;
        if (isTime) {
            if (live) {
                range = {
                    from: Date.now() - duro,
                    to: Date.now()
                };
            }
            else {
                if (typeof wellVp?.to === 'number') {
                    range = {
                        from: wellVp?.to - duro,
                        to: wellVp?.to
                    };
                }
            }
        }
        else {
            if (live) {
                if (typeof lastWde === 'number') {
                    const from = lastWde - duro;
                    range = {
                        from: from < 0 ? 0 : from,
                        to: lastWde
                    };
                }
            }
            else {
                if (typeof wellVp?.to === 'number') {
                    let from = wellVp.from;
                    let to = wellVp?.to;

                    if (from <= to) {
                        console.warn('Something wrong with depth range, "to" less or equal than "from');
                        if (!lastWde) {
                            console.warn('No last depth value');
                            to = from + duro;
                        }
                        else {
                            console.warn('Set "to" equal to last depth value');
                            to = lastWde;
                        }
                    }

                    from = to - duro;
                    range = {
                        from: from < 0 ? 0 : from,
                        to
                    };
                }
            }
        }

        return range;
    }
}