import { Injectable } from '@angular/core';

import { GraphQLQuery } from '../../internals/cl-gql';

import type { KpiDrillingMetricsItem } from '@cyberloop/web/wells/model';
type KpiDrillingMetricsInput = {
    rig: string,
    since: string,
    until: string
}

type KpiDrillingMetrics = {
    rig: {
        drillingMetrics: KpiDrillingMetricsItem
    }
}

// Singleton
@Injectable({ providedIn: 'root' })
export class KpiDrillingMetricsQuery extends GraphQLQuery<KpiDrillingMetrics, KpiDrillingMetricsInput> {
    override readonly document = `
        query GetRigDrillingMetrics($rig: String!, $since: DateTime!, $until: DateTime!) {
            rig(name: $rig) {
                ... on Rig {
                    drillingMetrics(since: $since, until: $until) {
                        ...on DrillingMetrics {
                            rotaryDrilled
                            slideDrilled
                        }
                    }
                }
            }
        }
    `;
}