<div class="head">
    <span class="col">version</span>
    <span class="col"></span>
    <span class="col">published</span>
    <span class="col">changed by</span>
    <span class="col comment">comment</span>
</div>
<div class="content">
    <ng-container *ngFor="let version of data">
        <span class="col version">
            {{ version.name }}
        </span>
        <span class="col active">
            <span *ngIf="version.id === activeId" class="label">active</span>
        </span>
        <time class="col">{{ version.updatedAt | datetime }}</time>
        <span class="col"><span class="text-overflow">{{ version.changedBy }}</span></span>
        <span class="col comment">{{ version.description }}</span>
        <div class="col actions">
            <ng-container *ngIf="version.id !== activeId && !readonly">
                <button (click)="restore.emit(version)" [disabled]="disableActions" class="action cl-button">
                    <icon name="restore"></icon>
                    <span class="label">restore</span>
                </button>

                <div class="divider vertical"></div>

                <button (click)="delete.emit(version)" [disabled]="disableActions" class="action cl-button">
                    <icon name="delete"></icon>
                    <span class="label">delete</span>
                </button>
            </ng-container>
        </div>
        <div class="divider horizontal"></div>
    </ng-container>
</div>
