export type Stores = Record<string, unknown>;
export type IIndexDBDatabase<TStores extends Stores> = {
    /**
     * Returns a list of the names of object stores in the database.
     *
     * [MDN Reference](https://developer.mozilla.org/docs/Web/API/IDBDatabase/objectStoreNames)
     */
    readonly objectStoreNames: DOMStringList;

    /**
     * Returns the version of the database.
     *
     * [MDN Reference](https://developer.mozilla.org/docs/Web/API/IDBDatabase/version)
     */
    readonly version: number;
    /**
     * Closes the connection once all running transactions have finished.
     *
     * [MDN Reference](https://developer.mozilla.org/docs/Web/API/IDBDatabase/close)
     */
    close(): void;
    /**
     * Creates a new object store with the given name and options and returns a new IDBObjectStore.
     *
     * Throws a "InvalidStateError" DOMException if not called within an upgrade transaction.
     *
     * [MDN Reference](https://developer.mozilla.org/docs/Web/API/IDBDatabase/createObjectStore)
     */
    createObjectStore(name: keyof TStores, options?: IDBObjectStoreParameters): IDBObjectStore;
    /**
     * Deletes the object store with the given name.
     *
     * Throws a "InvalidStateError" DOMException if not called within an upgrade transaction.
     *
     * [MDN Reference](https://developer.mozilla.org/docs/Web/API/IDBDatabase/deleteObjectStore)
     */
    deleteObjectStore(name: keyof TStores): void;
    /**
     * Returns a new transaction with the given mode ("readonly" or "readwrite") and scope which can be a single object store name or an array of names.
     *
     * [MDN Reference](https://developer.mozilla.org/docs/Web/API/IDBDatabase/transaction)
     */
    transaction(storeNames: keyof TStores | (keyof TStores)[], mode?: IDBTransactionMode, options?: IDBTransactionOptions): IDBTransaction;
    addEventListener<K extends keyof IDBDatabaseEventMap>(type: K, listener: (this: IDBDatabase, ev: IDBDatabaseEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
    addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
    removeEventListener<K extends keyof IDBDatabaseEventMap>(type: K, listener: (this: IDBDatabase, ev: IDBDatabaseEventMap[K]) => any, options?: boolean | EventListenerOptions): void;
    removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
}
/**
 * IndexedDb wrapper class
 */
export abstract class IndexedDBWrapper<TStores extends Stores> {
    /** @internal */
    private _cache: IDBDatabase | undefined;

    /**
     *
     */
    protected constructor(
        protected readonly dbName: string
    ) {

    }

    /** dbName getter */

    /**
     * Get specific data by id
     * @param storeName store name
     * @param id to searching data
     * @returns unknown data
     */
    protected async getInternal<T = unknown>(storeName: keyof TStores, id: string): Promise<T> {
        const db = await this.openDB();

        const transaction = db.transaction(storeName as string, 'readonly');

        try {
            const store = transaction.objectStore(storeName as string);
            return await new Promise<T>((res, rej) => {
                const storeRequest = store.get(id);
                storeRequest.addEventListener('success', () => {
                    transaction.commit();
                    res(storeRequest.result);
                });
                storeRequest.addEventListener('error', rej);
            });
        }
        catch (e) {
            transaction.abort();
            throw e;
        }
    }

    /**
     * Set specific data to key
     * @param storeName store name
     * @param data data to store
     * @param id related key
     */
    protected async setInternal<T = unknown>(storeName: keyof TStores, data: T, id?: string) {
        const db = await this.openDB();

        const transaction = db.transaction(storeName as string, 'readwrite');

        try {
            const store = transaction.objectStore(storeName as string);

            await new Promise<void>((res, rej) => {
                const storeRequest = store.add(data, id);
                storeRequest.addEventListener('success', () => res());
                storeRequest.addEventListener('error', rej);
            });

            transaction.commit();
        }
        catch (e) {
            transaction.abort();
            throw e;
        }
    }

    /**
     * Create object store
     * @param db data base
     */
    protected abstract migrateDb(db: IIndexDBDatabase<TStores>): void; // TODO make migration more dev-friendly. It must be based on TStores object

    /**
     * Open indexed db
     * @returns
     */
    private openDB(): Promise<IDBDatabase> {
        return new Promise<IDBDatabase>((res, rej) => {
            if (this._cache) {
                res(this._cache);
            }
            const store = indexedDB.open(this.dbName);

            store.addEventListener('success', () => {
                const db = store.result;
                this._cache = db;
                res(db);
            });
            store.addEventListener('upgradeneeded', (ev: IDBVersionChangeEvent) => {
                const db: IIndexDBDatabase<TStores> = (ev.target as any).result;
                this.migrateDb(db);
            });
            store.addEventListener('error', rej);
        });
    }
}
