import { Mode } from '@cyberloop/web/wells/model';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { DrillingActions, PublicDrillingActions } from './drilling.actions';
import { DrillingState, getDefaultDrillingState } from './drilling.state';

export const drillingReducer = createReducer<DrillingState>(getDefaultDrillingState(),
    on(DrillingActions.setSettings, (state, { settings }) => ({
        ...state,
        ...settings,
        settingsLoaded: true
    })),
    on(PublicDrillingActions.setMode, (state, { mode }) => ({
        ...state,
        mode
    })),
    on(PublicDrillingActions.setChartToShow, (state, { chartsToShow }) => {
        const newState = cloneDeep(state);
        const wss = newState.mode === Mode.Depth ? newState.depthWs : newState.timeWs;
        const id = newState.mode === Mode.Depth ? newState.selectedDepthWsId : newState.selectedTimeWsId;
        const ws = wss.find(x => x.id === id);
        if (ws) {
            ws.chartsToShow = chartsToShow;
            return newState;
        }

        return state;
    }),
    on(PublicDrillingActions.editAsset, (state, { assetIdx, chartIdx, asset }) => {
        const newState = cloneDeep(state);
        const wss = newState.mode === Mode.Depth ? newState.depthWs : newState.timeWs;
        const id = newState.mode === Mode.Depth ? newState.selectedDepthWsId : newState.selectedTimeWsId;
        const ws = wss.find(x => x.id === id);

        if (ws) {
            ws.charts[chartIdx][assetIdx] = asset;
            return newState;
        }
        else {
            return state;
        }
    }),
    on(PublicDrillingActions.setOrientation, (state, { orientation }) => ({ ...state, orientation })),
    on(PublicDrillingActions.setLive, (state, { live }) => ({ ...state, live })),
    on(PublicDrillingActions.liveToggle, (state) => ({ ...state, live: !state.live })),

    on(PublicDrillingActions.changeTimeViewport, (state, { range }) => ({ ...state, time: range })),
    on(PublicDrillingActions.changeDepthViewport, (state, { range }) => ({ ...state, depth: range })),

    on(PublicDrillingActions.changeTimeWellViewport, (state, { range }) => ({ ...state, naviTime: range })),
    on(PublicDrillingActions.changeDepthWellViewport, (state, { range }) => ({ ...state, naviDepth: range })),

    on(PublicDrillingActions.zoomViewport, (state, { zoomIn }) => ({ ...state, zoomIn })),
    on(PublicDrillingActions.changeSectionId, (state, { sectionId }) => ({ ...state, sectionId })),
    on(PublicDrillingActions.setLastLiveWDEDepth, (state, { depth }) => ({ ...state, lastLiveWellDepth: depth })),
    on(PublicDrillingActions.changeExportLoadingStatus, (state, { exportLoading }) => ({ ...state, exportLoading })),
    on(PublicDrillingActions.changeChartsLoading, (state, { num }) => {
        const chartsLoading = state.chartsLoading + num;
        return { ...state, chartsLoading: chartsLoading < 0 ? 0 : chartsLoading }; // FIXME 🩼
    }),
    on(PublicDrillingActions.changeLiveSubscriptionStatus, (state, { liveSubscriptionStatus }) => {
        return { ...state, liveSubscriptionStatus };
    }),
    on(PublicDrillingActions.resetChartsLoading, state => {
        return { ...state, chartsLoading: 0 };
    }),
    on(PublicDrillingActions.createWorkspace, (state, { ws }) => {
        const newState = cloneDeep(state);
        if (newState.mode === Mode.Time) {
            newState.timeWs = [...newState.timeWs, ws];
        }
        else {
            newState.depthWs = [...newState.depthWs, ws];
        }

        return newState;
    }),
    on(PublicDrillingActions.editWorkspace, (state, { id, name }) => {
        const newState = cloneDeep(state);
        const wss = newState.mode === Mode.Time ? newState.timeWs : newState.depthWs;
        const workspace = wss.find(x => x.id === id);

        if (workspace) {
            workspace.name = name;
            return newState;
        }

        return state;
    }),
    on(PublicDrillingActions.deleteWorkspace, (state, { id }) => {
        const newState = cloneDeep(state);

        if (newState.mode === Mode.Time) {
            const idx = newState.timeWs.findIndex(x => x.id === id);
            const newSelectedWs = idx > 0 ? newState.timeWs[idx - 1].id : newState.timeWs[idx + 1].id;

            newState.timeWs = newState.timeWs.filter(x => x.id !== id);
            newState.selectedTimeWsId = newSelectedWs;
        }
        else {
            const idx = newState.depthWs.findIndex(x => x.id === id);
            const newSelectedWs = idx > 0 ? newState.depthWs[idx - 1].id : newState.depthWs[idx + 1].id;

            newState.depthWs = newState.depthWs.filter(x => x.id !== id);
            newState.selectedDepthWsId = newSelectedWs;
        }

        return newState;
    }),
    on(PublicDrillingActions.setWorkspaceId, (state, { id }) => {
        const newState = cloneDeep(state);
        if (newState.mode === Mode.Depth) {
            newState.selectedDepthWsId = id;
        }
        else {
            newState.selectedTimeWsId = id;
        }
        return newState;
    })
);

