import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClButtonToggleComponent, UnitsSelectors, WellKnownUnitGroupIds, WellKnownUnitIds } from '@cyberloop/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { KpiWidgetSettingsComponent } from '../../widget-settings/kpi-widget-settings.component';

import type { PopupContent } from '@cyberloop/core';
import type { RigActivityPerHourWidgetSettings } from '@cyberloop/web/wells/model';
@Component({
    standalone: true,
    imports: [
        NgFor,
        AsyncPipe,
        KpiWidgetSettingsComponent,
        FormsModule,
        ReactiveFormsModule,
        ClButtonToggleComponent
    ],
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements PopupContent<RigActivityPerHourWidgetSettings> {

    private _closeFn?: (result: RigActivityPerHourWidgetSettings | null) => void;
    private _data?: RigActivityPerHourWidgetSettings;

    constructor(
        private readonly store: Store
    ) {}

    readonly form = new FormGroup({
        units: new FormControl<WellKnownUnitIds | null>(null, [ Validators.required ])
    });

    readonly units$ = this.store.select(UnitsSelectors.getUnitGroup(WellKnownUnitGroupIds.Length)).pipe(
        map(group => Object.values(group.units))
    );

    setData(data: RigActivityPerHourWidgetSettings): void {
        this._data = data;

        if (data.depthUnitId) {
            this.form.controls.units.setValue(data.depthUnitId as WellKnownUnitIds);
        }
    }

    setClose(fn: (result: RigActivityPerHourWidgetSettings | null) => void): void {
        this._closeFn = fn;
    }

    onCancel(): void {
        this._closeFn?.(null);
    }

    onSubmit(): void {
        // Compose settings

        const result: RigActivityPerHourWidgetSettings = {};

        if (this.form.controls.units.value) {
            result.depthUnitId = this.form.controls.units.value;
        }

        this._closeFn?.(result);
    }

}
