
export enum WellKnownParams {
    /** Well Depth */
    WDE = 'WDE',
    BDE = 'BDE',
    /** Azimuth */
    AZI = 'AZI',
    /** Inclination */
    INC = 'INC',
    /** RigState */
    RST = 'RST',
    /** RigActivity */
    RAC = 'RAC',
    ROP = 'ROP',
    /** Revolution Per Minute */
    RPM = 'RPM',
    WOB = 'WOB',
    FLR = 'FLR',
    SPP = 'SPP',
    DPR = 'DPR',
    DTQ = 'DTQ',
    GTF = 'GTF',
    TDV = 'TDV',
    TRQ = 'TRQ',
    HKL = 'HKL',
    GAM = 'GAM',
    TTF = 'TTF',
    ARP = 'ARP',
    MBX = 'MBX',
    MBY = 'MBY',
    MBZ = 'MBZ',
    MGX = 'MGX',
    MGY = 'MGY',
    MGZ = 'MGZ',

    DiffP = 'DiffP',
    DiffT = 'DiffT'
}
