import { Directive, ElementRef } from '@angular/core';

/**
 * This is used only for accessing carousel items inside of the CarouselComponent
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
    // eslint-disable-next-line @angular-eslint/component-selector, @angular-eslint/directive-selector
    selector: '[carousel-item]',
    standalone: true
})
export class CarouselItemDirective {

    constructor(
        public readonly element: ElementRef<HTMLElement>
    ) {
        //
    }

}
