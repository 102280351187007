import { Injectable } from '@angular/core';

import { ClError, CreateTemplate, ID, TemplateAccess, uuid } from '@cyberloop/core';
import { DrillingExportTemplatesProvideService } from '@cyberloop/web/wells/data';
import { ExportTemplate } from 'libs/wells/data/src/lib/services/drilling/export-template.service';
import { isNil } from 'lodash';
import { EMPTY, Observable, finalize, from, shareReplay, switchMap } from 'rxjs';

import { FirestoreDataConverter, or, orderBy, where } from 'firebase/firestore';
import * as moment from 'moment';

import { ClApplicationManager } from './internals/client-app/cl-app-manager';

import type { ClApplication } from './internals/client-app/cl-application';
const DRILLING_EXPORT_TEMPLATES_COLLECTION_NAME = 'export-templates';

/** DB template model */
export interface DBTemplate {
    /** Template id */
    id: ID;
    /** Template author - email */
    author: string;
    /** Template name */
    name: string;
    /** Template description */
    description: string;
    /** Template access type */
    access: TemplateAccess;
    /** Created at in milliseconds */
    createdAt: number;
    /** Created at in milliseconds */
    updatedAt: number;
}

@Injectable({
    providedIn: 'root'
})
export class FirebaseDrillingExportTemplatesProviderLinkService extends DrillingExportTemplatesProvideService {
    private readonly _templateConverter: FirestoreDataConverter<ExportTemplate> = {
        toFirestore: (data: ExportTemplate) => data,
        fromFirestore: (snapshot, options): ExportTemplate => {
            const data = snapshot.data(options) as DBTemplate;

            return {
                ...data,
                createdAt: moment(data.createdAt),
                updatedAt: moment(data.updatedAt)
            };
        }
    };

    private _templateListObservable: Observable<ExportTemplate[]> | undefined;

    constructor(private readonly appMgr: ClApplicationManager) {
        super();
    }

    //#region Template

    watchAll() {
        return this._templateListObservable ??= this.appMgr.tenantApp$.pipe(
            switchMap(x => x?.getFirestore().watchCollection<ExportTemplate>(
                DRILLING_EXPORT_TEMPLATES_COLLECTION_NAME,
                this._templateConverter,
                [
                    orderBy('createdAt', 'desc')
                ],
                or(
                    where('access', '==', 'public'),
                    where('author', '==', this.getUserEmail(x))
                )
            ) ?? EMPTY
            ),
            finalize(() => this._templateListObservable = undefined),
            shareReplay(1)
        );
    }

    get(templateId: ID) {
        return this.appMgr.tenantApp$.pipe(
            switchMap(x =>
                x?.getFirestore().get<ExportTemplate>(
                    this.getPath(DRILLING_EXPORT_TEMPLATES_COLLECTION_NAME, templateId),
                    this._templateConverter
                ) ?? EMPTY
            ),
            shareReplay(1)
        );
    }

    create(createTemplate: CreateTemplate) {
        const id = uuid();

        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => from(
                    x?.getFirestore().set<DBTemplate>(
                        this.getPath(DRILLING_EXPORT_TEMPLATES_COLLECTION_NAME, id),
                        {
                            ...createTemplate,
                            id,
                            author: this.getUserEmail(x),
                            createdAt: moment.now(),
                            updatedAt: moment.now()
                        }
                    ) ?? EMPTY
                )
                ),
                switchMap(() => this.get(id) as Observable<ExportTemplate>),
                shareReplay(1)
            );
    }

    delete(templateId: ID) {
        return this.appMgr
            .tenantApp$.pipe(
                switchMap(x => x?.getFirestore().runTransaction(async (transaction) => {
                    const template = await transaction.get<ExportTemplate>(
                        this.getPath(DRILLING_EXPORT_TEMPLATES_COLLECTION_NAME, templateId),
                        this._templateConverter
                    );

                    if (isNil(template)) {
                        throw new Error('Template not found');
                    }

                    transaction.delete(
                        this.getPath(DRILLING_EXPORT_TEMPLATES_COLLECTION_NAME, templateId)
                    );

                    return template;
                }) ?? EMPTY),
                shareReplay(1)
            );
    }

    //#endregion Template
    private getUserEmail(x?: ClApplication) {
        const email = x?.getAuth().currentUser?.email;
        if (isNil(email)) {
            throw new ClError('User email not found');
        }

        return email;
    }

    private getPath(...args: string[]) {
        return args.join('/');
    }
}
