
import { CoreSelectors, RigActivity } from '@cyberloop/core';
import { getMomentsFromTimeRange, rigActivityDefaultSettings } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { EMPTY, Observable, combineLatest, distinctUntilChanged, map, switchMap } from 'rxjs';

import { KpiSelectors } from '../../state/kpi/kpi.selectors';
import { KpiDataService } from '../kpi-data.service';

import type { RigActivityWidgetData, KpiRigActivityData, RigActivityWidgetSettings } from '@cyberloop/web/wells/model';

export function getRigActivityWidgetData(this: { store: Store, data: KpiDataService }, id: string): Observable<RigActivityWidgetData> {
    return combineLatest([
        this.store.select(CoreSelectors.currentWell),
        this.store.select(KpiSelectors.widgetSettings<RigActivityWidgetSettings>(id))
    ]).pipe(
        // Custom distinct function that prevents triggering query when things
        // needed for it have no changes
        distinctUntilChanged((prev, curr) =>
            prev[0]?.id === curr[0]?.id &&
            isEqual(prev[1]?.timeRange, curr[1]?.timeRange)
        ),
        switchMap(([well, settings]) => {
            if (!well) {
                return EMPTY;
            }

            const timeRangeObservable = getMomentsFromTimeRange(
                this.store,
                settings?.timeRange ?? rigActivityDefaultSettings.timeRange,
                well
            );

            return timeRangeObservable.pipe(
                switchMap(timeRange => this.data.watchRigActivityDurations(
                    well.rig,
                    timeRange.since.clone().startOf('second').toDate(),
                    timeRange.until.clone().startOf('second').toDate()
                )),
                map(rows => {
                    const combined = {} as Record<RigActivity, number>;

                    for (const item of rows) {
                        if (!combined[item.activity]) {
                            combined[item.activity] = 0;
                        }

                        combined[item.activity] += item.duration;
                    }

                    const combinedArray: KpiRigActivityData[] = [];

                    for (const key of Object.keys(combined)) {
                        const activity = Number(key) as RigActivity;

                        combinedArray.push({
                            activity,
                            duration: combined[activity]
                        });
                    }

                    return combinedArray;
                }),
                // Map to widget data structure
                map(data => ({ data }))
            );
        })
    );
}