import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import { ClButtonToggleComponent, ClSelectorComponent, ClSelectorOptions, Entity, FormatNumberPipe, FromSiPipe, NoDataPipe, UnitDescriptor } from '@cyberloop/core';
import { WellPlan, WellPlanDataType, WellPlanTable } from '@cyberloop/web/wells/model';

@Component({
    selector: 'cyberloop-dd-wellplan',
    standalone: true,
    imports: [
        CommonModule,
        ClButtonToggleComponent,
        MatTableModule,
        NoDataPipe,
        FromSiPipe,
        FormatNumberPipe,
        AsyncPipe,
        ClSelectorComponent
    ],
    templateUrl: './dd-wellplan.component.html',
    styleUrls: ['./dd-wellplan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DdWellplanComponent {

    constructor() { }

    @Input() planTypeOptions: Entity[] = [];
    @Input() selectedPlanType: string | null = null;
    @Input() angleUnit: UnitDescriptor | null | undefined = null;

    @Input() planData: any[] | null = [];
    @Input() wellPlansOptions: ClSelectorOptions[] = [];
    @Input() wellPlan: WellPlan | null | undefined = null;
    @Input() tableColumns: any[] = [];
    @Input() selectedPlanId: string | null | undefined = null;
    @Input() skeleton: boolean | null = null;

    @Output() typeSelect = new EventEmitter<string>();
    @Output() planSelect = new EventEmitter<string>();

    get displayedColumns() {
        return this.tableColumns.map(x => x.id);
    }

    get tableData() {
        switch (this.selectedPlanType) {
            case WellPlanDataType.Plan:
                return (this.wellPlan?.data ?? []) as WellPlanTable[];

            case WellPlanDataType.Targets:
                return (this.wellPlan?.targets ?? []) as WellPlanTable[];

            default:
                return [];
        }
    }
}
