
import { CoreSelectors } from '@cyberloop/core';
import { RateOfAdvanceWidgetDataItem, getMomentsFromWell } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, distinctUntilChanged, map, switchMap } from 'rxjs';

import { KpiDataService } from '../kpi-data.service';

import type { RateOfAdvanceWidgetData } from '@cyberloop/web/wells/model';

export function getRateOfAdvanceWidgetData(this: { store: Store, data: KpiDataService }, id: string): Observable<RateOfAdvanceWidgetData> {
    return combineLatest([
        this.store.select(CoreSelectors.currentWell) /*,
        this.store.select(KpiSelectors.widgetSettings<RateOfAdvanceWidgetSettings>(id))*/
    ]).pipe(
        // Custom distinct function that prevents triggering query when things
        // needed for it have no changes
        distinctUntilChanged((prev, curr) =>
            prev[0]?.id === curr[0]?.id
        ),
        switchMap(([well /*, settings*/]) => {
            if (!well) {
                return EMPTY;
            }

            /*const aggregationTimeSpan =
                settings?.aggregationTimeSpan ?? rateOfAdvanceDefaultSettings.aggregationTimeSpan;

            // Check if it's a multiplier of a hour

            const hours = aggregationTimeSpan / (3600 * 1000);
            const fraction = hours - Math.floor(hours);

            if (fraction !== 0) {
                throw new Error(`Invalid aggregation time span: It should be a multiple of one hour, but got ${aggregationTimeSpan} which is ${hours} hours`);
            }*/

            // Get time observable

            const timeRangeObservable = getMomentsFromWell(well);

            return timeRangeObservable.pipe(
                switchMap(timeRange => this.data.watchRigRateOfAdvance(
                    well.rig,
                    timeRange.since.clone().startOf('second').toDate(),
                    timeRange.until.clone().startOf('second').toDate()
                )),
                map(rows => {
                    const metrics: RateOfAdvanceWidgetDataItem[] = [];

                    // Each row representing 1 hour of data
                    /*for (let i = 0; i < rows.length; i += hours) {
                        const item: RateOfAdvanceWidgetDataItem = {
                            startTime: new Date(rows[i].startTime),
                            trippingIn: 0,
                            trippingOut: 0,
                            drilling: 0,
                            casingRunning: 0
                        };

                        // Process several hours per step
                        for (let j = 0; j < Math.min(hours, rows.length - i * hours); j++) {
                            item.trippingIn += rows[j].trippingIn;
                            item.trippingOut += rows[j].trippingOut;
                            item.drilling += rows[j].drilling;
                            item.casingRunning += rows[j].casingRunning;
                        }

                        metrics.push(item);
                    }*/

                    for (const row of rows) {
                        metrics.push({
                            startTime: new Date(row.startTime),
                            trippingIn: row.trippingIn,
                            trippingOut: row.trippingOut,
                            drilling: row.drilling,
                            casingRunning: row.casingRunning
                        });
                    }

                    return { metrics };
                })
            );
        })
    );
}
