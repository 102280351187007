<cyberloop-kpi-widget heading="Rig Activity" [size]="size" class="widget" (settingsRequested)="onSettings()" (deleteRequested)="onDelete()">
    <div widget-body class="content-wrap" [class.loading]="dataLoading$ | async">

        <!-- Chart -->
        <div class="chart-container">
            <cyberloop-chart
                [options]="options"
                class="chart"
            ></cyberloop-chart>
            <svg viewBox="0 0 100 100" class="placeholder" *ngIf="dataLoading$ | async">
                <circle cx="50" cy="50" r="50" fill="none" />
            </svg>
        </div>

        <!-- Table -->
        <div #tableContainer class="table-container">
            <div class="table-content">
                <div class="signal-item signal-item-top"></div>

                <table *ngIf="tableRows$ | async as rows" #table mat-table [dataSource]="rows">

                    <!-- Activity Column -->
                    <ng-container matColumnDef="activity">
                        <th mat-header-cell *matHeaderCellDef>Activity</th>
                        <td mat-cell *matCellDef="let element" [attr.data-text]="element.activity">
                            <span class="content">
                                <span class="dot" [class]="element.className"></span>
                                {{element.activity}}
                            </span>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>Totals</td>
                    </ng-container>

                    <!-- Value Column -->
                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef>Value <span class="unit">{{timeUnit}}</span></th>
                        <td mat-cell *matCellDef="let element" [attr.data-text]="element.value">{{element.value | number: '1.0-2'}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{valuesSum | number: '1.0-2'}}</td>
                    </ng-container>

                    <!-- Percent Column -->
                    <ng-container matColumnDef="percent">
                        <th mat-header-cell *matHeaderCellDef>Percent</th>
                        <td mat-cell *matCellDef="let element" [attr.data-text]="element.percent">{{element.percent | number: '1.0-2'}} %</td>
                        <td mat-footer-cell *matFooterCellDef>100 %</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>

                <div class="signal-item signal-item-bottom"></div>
            </div>
        </div>
    </div>
</cyberloop-kpi-widget>
