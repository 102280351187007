import { createAction, props } from '@ngrx/store';

import { METERAGE_FEATURE } from './meterage.state';

import type { Meterage, MeterageItem } from '@cyberloop/web/wells/model';

const loadMeterage = createAction(`[${METERAGE_FEATURE}] Load meterage`);
const meterageLoaded = createAction(`[${METERAGE_FEATURE}] Meterage loaded`, props<{ meterage: Meterage | null }>());
const meterageLoadError = createAction(`[${METERAGE_FEATURE}] Meterage load error`, props<{ error?: Error }>());

const addRun = createAction(`[${METERAGE_FEATURE}] Add run`);
const delRun = createAction(`[${METERAGE_FEATURE}] Del run`);
const updateMeterage = createAction(`[${METERAGE_FEATURE}] Update meterage`, props<{ itemList: MeterageItem[] }>());

/**
 * @internal
 * Actions for meterage used internally
 * */
export const MeterageActions = {
    loadMeterage,
    meterageLoaded,
    meterageLoadError,
    addRun,
    delRun,
    updateMeterage
};
