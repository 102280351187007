<cyberloop-kpi-widget heading="Tripping speed" [size]="size" class="widget" (settingsRequested)="onSettings()" (deleteRequested)="onDelete()">
    <div widget-header class="widget-header">
        <div class="column">
            <h4>Target <span class="units">min</span></h4>
            <div class="value" [class.skeleton]="dataLoading$ | async">{{target$ | async}}</div>
        </div>
        <div class="column">
            <h4>Average <span class="units">min</span></h4>
            <div class="value highlighted" [class.skeleton]="dataLoading$ | async">
                {{average$ | async}}
                <icon name="arrow__double__up" class="direction"></icon>
            </div>
        </div>
    </div>
    <div class="chart-container" widget-body [class.loading]="dataLoading$ | async">
        <cyberloop-chart
            [options]="options"
            class="chart"
        ></cyberloop-chart>
        <div class="placeholder" *ngIf="dataLoading$ | async">
            <div *ngFor="let column of placeholderColumns" class="column" [style.height]="(column * 10) + '%'"></div>
        </div>
    </div>
</cyberloop-kpi-widget>