import { UnitGroup } from '@cyberloop/core';

export interface ChartAsset {
    id: string;
    title: string;
    min?: number;
    max?: number;
    lo?: number;
    lolo?: number;
    hi?: number;
    hihi?: number;
    colorIndex: number;
    unitId?: string;
    unitLabel?: string;
    unitName?: string;
    unitGroupId: UnitGroup['id'];
    lineWeight?: number
}

export type NaviChart = Record<number, ChartAsset>;
export type SimpleChart = Record<number, (ChartAsset | null)>;
export type SimpleCharts = SimpleChart[];

export const PSEUDO_ASSET = 'PSEUDO';
export const pseudoAsset = { id: PSEUDO_ASSET } as ChartAsset;