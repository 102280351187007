import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { ClDatepickerComponent, ClInputComponent, ClSelectorComponent, ConfirmationDialogService, FormErrorsComponent, ID, IconComponent, PopupContent } from '@cyberloop/core';
import { PlanningPopupData, PlanningPopupResult } from '@cyberloop/web/planning/shared/model';
import { isNil } from 'lodash';

import { Moment } from 'moment';

enum Controls {
    Name = 'name',
    RigId = 'rigId',
    PlannedStartDate = 'plannedStartDate',
    Description = 'description',
}

@Component({
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        ReactiveFormsModule,
        IconComponent,
        ClInputComponent,
        ClSelectorComponent,
        ClDatepickerComponent,
        FormErrorsComponent
    ],
    templateUrl: './planning-popup.component.html',
    styleUrls: ['./planning-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningPopupComponent implements PopupContent<PlanningPopupResult | undefined> {
    private _data?: PlanningPopupData = undefined;
    private _close!: (result?: PlanningPopupResult) => void;

    constructor(
        private readonly fb: FormBuilder,
        private readonly confirmationDialogService: ConfirmationDialogService
    ) { }

    readonly Controls = Controls;
    readonly form = this.fb.group({
        [Controls.Name]: ['', Validators.required],
        [Controls.RigId]: this.fb.control<ID | null>(null),
        [Controls.PlannedStartDate]: this.fb.control<Moment | null>(null),
        [Controls.Description]: ['']
    });

    get nameControl() {
        return this.form.get(Controls.Name) as FormControl;
    }

    get rigIdControl() {
        return this.form.get(Controls.RigId) as FormControl;
    }

    get rigList$() {
        return this._data?.rigList$ ?? null;
    }

    get isEdit() {
        return !!this._data?.planning;
    }

    get hasWellId() {
        return !!this._data?.planning?.wellId;
    }


    setData(data: PlanningPopupData): void {
        this._data = data;

        if (!isNil(data.planning)) {
            this.form.patchValue(data.planning);
        }
    }

    setClose(fn: (result?: PlanningPopupResult) => void): void {
        this._close = fn;
    }

    save() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this._close(this.form.value as PlanningPopupResult);
    }

    async close() {
        if (this.form.dirty) {
            if (!await this.confirmationDialogService.show('You have unsaved changes. Do you really want to close?')) {
                return;
            }
        }

        this._close();
    }
}
