<cyberloop-kpi-widget heading="Days vs Depth" [size]="size" class="widget" (settingsRequested)="onSettings()" (deleteRequested)="onDelete()">
    <div *ngIf="!(dataLoading$ | async)" class="chart-header" widget-header>
        <cyberloop-well-chart-legend
            [showPlan]="hasPlan$ | async"
            [showPlanLimit]="hasPlanLimit$ | async"
            class="chart-legend"
        ></cyberloop-well-chart-legend>
    </div>

    <div class="chart-container" widget-body [class.loading]="dataLoading$ | async">
        <cyberloop-well-chart
            [wde]="wde$ | async"
            [bde]="bde$ | async"
            [plan]="plan$ | async"
            [planLimit]="planLimit$ | async"
            [sections]="sections$ | async"
            [unit]="depthUnit$ | async"
            [legend]="legend ?? null"
            class="chart"
        ></cyberloop-well-chart>
        <div class="placeholder" *ngIf="dataLoading$ | async">
            <svg viewBox="0 0 648 264" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 57.6007L59.8182 63.6187L64.9082 96.2878L79.6128 63.6187H105.629L109.587 80.8129L115.809 63.6187L141.259 103.165L148.045 69.6367L172.93 73.9352L187.635 90.2698H209.126L216.478 148.73L226.093 90.2698L248.715 98.0072L258.33 148.73L266.247 103.165L286.042 107.464L300.181 171.942L307.533 118.64L359.565 112.622L372.007 148.73L375.4 112.622L425.735 128.957L434.219 171.942L439.874 134.975L509.438 143.572L517.921 188.277L526.405 143.572H542.806L554.117 182.259L560.904 123.799H576.174L588.051 182.259L596.534 118.64H608.411L612.37 148.73L621.984 107.464H648" stroke-width="3"/>
                <path d="M1 66.1979L55.8114 75.6547L63.1572 98.8669L106.667 83.3921L143.397 110.043L177.866 98.8669L215.725 159.047L265.451 153.889L298.79 177.96L377.899 159.047L440.621 177.96H502.213L516.905 196.874L583.583 189.996L616.921 204.612H648" stroke-width="3"/>
            </svg>
        </div>
    </div>
</cyberloop-kpi-widget>