import { PrimaryColumnId } from '@cyberloop/core';

import * as moment from 'moment';

import { WriterBase, WriterParameters } from './writer-base.service';

export class CsvWriterService extends WriterBase {
    override get fileExt(): string {
        return '.csv';
    }
    override get mime(): string {
        return 'text/csv';
    }

    public writeToString(params: WriterParameters): string {
        const { data, columns, precision } = params;
        const lines = [];

        const cols = [];
        for (const col of columns) {
            cols.push(`"${col.name}"`);
        }

        // Column headers
        lines.push(cols.join(','));

        for (const row of data) {
            cols.length = 0;

            for (const col of columns) {
                let v = (row as any)[col.secondId];

                if (col.id === PrimaryColumnId.Date) {
                    v = moment(v).format('DD-MM-YYYY HH:mm:ss');
                }
                else if (typeof v === 'string') {
                    v = v.replace(/"/gi, '""');
                    v = `"${v}"`;
                }
                else if (v === null || v === undefined) {
                    v = '""';
                }
                else if (typeof v === 'number') {
                    v = v.toFixed(precision);
                }

                cols.push(v);
            }

            lines.push(cols.join(','));
        }

        return lines.join('\n');
    }
}