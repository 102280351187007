import * as moment from 'moment';

export interface InfoObj {
    id: string;
    value: string;
    name: string;
    readonly?: boolean;
}

export interface LenObj {
    [key: string]: {
        len: number;
        pre: boolean;
        separ: boolean;
    }
}

export interface InfoHeader {
    header: InfoObj;
    line: InfoObj;
}

export const suffixes = (byDepth: boolean): Record<string, { name: string, letter: string }> => {
    switch (byDepth) {
        case true:
            return ({
                strt: { name: 'Start depth (m)', letter: 'm' },
                stop: { name: 'Stop depth (m)', letter: 'm' },
                step: { name: 'Step depth (m)', letter: 'm' }
            });
        case false:
            return ({
                strt: { name: 'Start time', letter: 'timedate' },
                stop: { name: 'Stop time', letter: 'timedate' },
                step: { name: 'Step time (sec)', letter: 'timedate' }
            });
        default:
            return ({});
    }
};

export const toExportDateFormat = (date: moment.Moment | number | string) => moment(date).format(datePattern);

export const defaultNullValue = '-999.25';
export const datePattern = 'YYYYMMDDHHmmss';//'hh:mm:ss/DD-MMM-YY';

export const NA = 'N/A';

export const versionInfo = [
    '~Version Information',
    'VERS.                2: CWLS LOG ASCII STANDARD - VERSION 2.0',
    // eslint-disable-next-line @typescript-eslint/quotes
    `WRAP.               NO: One line per depth step`
];

export const wellInfoHeader = '~Well Information Block';
export const wellLenObj: LenObj = {
    id: { len: 15, pre: false, separ: false },
    value: { len: 20, pre: true, separ: true },
    name: { len: 30, pre: false, separ: false }
};
export const wellHeader: InfoHeader = {
    header: {
        id: '#MNEM.UNIT',
        value: 'VALUE/NAME',
        name: 'DESCRIPTION'
    },
    line: {
        id: '#',
        value: '',
        name: ''
    }
};

export const curveInfoHeader = '~Curve Information Section';
export const unitLenObj: LenObj = {
    id: { len: 15, pre: false, separ: false },
    value: { len: 20, pre: true, separ: true },
    name: { len: 30, pre: false, separ: false }
};
export const unitHeader = {
    header: {
        id: '#MNEM.UNIT',
        value: 'API CODE',
        name: 'CURVE DESCRIPTION'
    },
    line: {
        id: '#',
        value: '',
        name: ''
    }
};
export const apiCode = '              ';

export const valueLen = 10;

export const lasInfoParts: Record<string, InfoObj[]> = {
    one: [
        { id: 'strt', name: '', value: '', readonly: true },
        { id: 'stop', name: '', value: '', readonly: true },
        { id: 'step', name: '', value: '' },
        { id: 'null', name: 'Null value', value: defaultNullValue },
        { id: 'srvc', name: 'Contractor', value: '' },
        { id: 'cnty', name: 'County', value: '' },
        { id: 'well', name: 'Well', value: '' },
        { id: 'fld', name: 'Field', value: '' }
    ],
    two: [
        { id: 'ctry', name: 'Contrye', value: '' },
        { id: 'prov', name: 'Province', value: '' },
        { id: 'stat', name: 'State', value: '' },
        { id: 'comp', name: 'Company', value: '' },
        { id: 'date', name: 'End time', value: '' },
        { id: 'api', name: 'Api number', value: '' },
        { id: 'uwi', name: 'Unique well identifier', value: '' },
        { id: 'loc', name: 'Location', value: '' }
    ],
    three: [
        { id: 'comments', name: 'Comments', value: '' }
    ]
};

export const defaultInfo = (data: Record<string, InfoObj[]>): Record<string, InfoObj> => {
    const obj: Record<string, InfoObj> = {};
    for (const part in data) {
        data[part].forEach(item => {
            obj[item.id] = { ...item };
        });
    }
    return obj;
};

export const lasInfoDefault = defaultInfo(lasInfoParts);
