import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

import { FormatNumberPipe, IconComponent, NoDataPipe, UnitDescriptor } from '@cyberloop/core';

import { WellCardTextComponent } from '../../well-card-text/well-card-text.component';

@Component({
    selector: 'cyberloop-dd-trace',
    standalone: true,
    imports: [
        WellCardTextComponent,
        FormatNumberPipe,
        NoDataPipe,
        MatRippleModule,
        IconComponent
    ],
    templateUrl: './dd-trace.component.html',
    styleUrls: ['./dd-trace.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DdTraceComponent {
    @Input() title = '';
    @Input() label = '';
    @Input() unit: UnitDescriptor | null = null;
    @Input() value: number | null = null;

    @Output() remove = new EventEmitter();
}
