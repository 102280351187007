import { Injectable } from '@angular/core';

import { Points, Range, TagDataProviderService } from '@cyberloop/core';
import { EMPTY, Observable, filter, map, of, switchMap } from 'rxjs';

import * as moment from 'moment';

import { TagTimeDataQuery } from '../queries/tags-data.query';
import { TagDepthDataQuery } from '../queries/tags-depth-data.query';

@Injectable({
    providedIn: 'root'
})
export class TagDataProviderServiceImpl extends TagDataProviderService {
    /**
     *
     */
    constructor(
        private readonly q: TagTimeDataQuery,
        private readonly qDepth: TagDepthDataQuery
    ) {
        super();
    }

    override get(wellId: string, tagsToFetchAtOnce: string[], viewPort: Range, step: number = 1): Observable<Record<string, Points>> { 
        return this.q.fetch({
            wellId: Number(wellId) as any, // TODO must be fixed at backend
            since: moment.unix(viewPort.from / 1000).toISOString(),
            until: moment.unix(viewPort.to / 1000).toISOString(),
            tags: tagsToFetchAtOnce,
            step
        }).pipe(
            filter(x => !x.loading),
            switchMap(x => {
                if (x.errors) {
                    console.error(x.errors);
                    return EMPTY;
                }
                if (x.error) {
                    console.error(x.errors);
                    return EMPTY;
                }

                if (!x.data.well?.timeHistory) {
                    console.error('Invalid response from server', x);
                    return EMPTY;
                }

                return of(x.data.well.timeHistory);
            }),
            map((t) => t.reduce((p, c) => {
                p[c.tagName] = c.intervals.map(({ x, y }) => ({
                    x: new Date(x).getTime(),
                    y
                }));
                return p;
            }, {} as Record<string, Points>))
        );
    }

    override getDepth(wellId: string, sectionId: string, tagsToFetchAtOnce: string[], viewPort: Range, step: number = 0.1): Observable<Record<string, Points>> {       
        return this.qDepth.fetch({
            wellId: Number(wellId) as any, // TODO must be fixed at backend
            sectionNo: Number(sectionId), // TODO must be fixed at backend
            from: viewPort.from,
            to: viewPort.to,
            step,
            tags: tagsToFetchAtOnce
        }).pipe(
            filter(x => !x.loading),
            switchMap(x => {
                if (x.errors) {
                    console.error(x.errors);
                    return EMPTY;
                }
                if (x.error) {
                    console.error(x.errors);
                    return EMPTY;
                }

                if (!x.data.well?.depthHistory) {
                    console.error('Invalid response from server', x);
                    return EMPTY;
                }

                return of(x.data.well.depthHistory);
            }),
            map((t) => t.reduce((p, c) => {
                p[c.tagName] = c.intervals.map(({ x, y }) => ({
                    x,
                    y
                }));
                return p;
            }, {} as Record<string, Points>))
        );
    }

}