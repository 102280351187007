import { AsyncPipe, NgFor, NgIf, formatNumber } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnChanges, ViewChild } from '@angular/core';

import { ClChartComponent, CompactNumberFormatter, IconComponent, SimpleChangesOf, UnitDescriptor, UnitsConverterService, UnitsSelectors, WellKnownUnitGroupIds } from '@cyberloop/core';
import { KpiActions, KpiSelectors } from '@cyberloop/web/wells/data';
import { WidgetDataProvider, WidgetSettingsHandler, WidgetSize, WidgetType, connectionTimeDefaultSettings } from '@cyberloop/web/wells/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest, filter, map, switchMap, tap } from 'rxjs';

import * as Highcharts from 'highcharts';
import * as moment from 'moment';

import { formatTooltipWithDateAndTime } from '../../common/widget-tooltip-formatter';
import { KpiWidgetComponent } from '../widget/kpi-widget.component';
import { SettingsComponent } from './settings/settings.component';

import type { ConnectionTimeWidgetData, ConnectionTimeWidgetSettings, KpiWidget } from '@cyberloop/web/wells/model';

type InternalSettings = {
    timeUnit: UnitDescriptor;
    timeUnitId: string;
    yMin?: number;
    yMax?: number;
    target?: number;
};

const PLOT_LINE_AVERAGE_ID = 'average';
const PLOT_LINE_TARGET_ID = 'target';

const X_LABELS_MAX_COUNT = 10;
const Y_TICKS_COUNT = 3;

@Component({
    selector: 'cyberloop-connection-time-widget',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AsyncPipe,
        KpiWidgetComponent,
        ClChartComponent,
        IconComponent
    ],
    templateUrl: './connection-time-widget.component.html',
    styleUrls: ['./connection-time-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class ConnectionTimeWidgetComponent implements KpiWidget, AfterViewInit, OnChanges {

    private readonly _id$ = new BehaviorSubject<string>('-');
    private readonly _dataLoading$ = new BehaviorSubject<boolean>(true);

    private readonly _average$ = new BehaviorSubject<string>('-');

    private _data?: ConnectionTimeWidgetData;

    private _timeUnit?: UnitDescriptor;

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string,
        private readonly store: Store,
        private readonly settings: WidgetSettingsHandler,
        private readonly data: WidgetDataProvider,
        private readonly unitsConverter: UnitsConverterService
    ) {
        const idPipe = this._id$.pipe(
            untilDestroyed(this),
            filter(Boolean),
            tap(() => this._dataLoading$.next(true))
        );

        this.target$ = idPipe.pipe(
            switchMap(id => this.store.select(KpiSelectors.widgetSettings<ConnectionTimeWidgetSettings>(id))),
            map(widgetSettings => widgetSettings?.target
                ? formatNumber(this.getConvertedValue(widgetSettings?.target), this.locale, '1.0-2')
                : 'N/A')
        );

        combineLatest([
            // Get widget data
            idPipe.pipe(
                switchMap(id => this.data.getData(id, WidgetType.ConnectionTime))
            ),
            // Get widget settings (for units conversion)
            idPipe.pipe(
                switchMap(id => this.store.select(KpiSelectors.widgetSettings<ConnectionTimeWidgetSettings>(id))),
                map(widgetSettings => ({
                    timeUnitId: widgetSettings?.timeUnitId ?? connectionTimeDefaultSettings.timeUnitId,
                    yMin: widgetSettings?.yMin ?? connectionTimeDefaultSettings.yMin,
                    yMax: widgetSettings?.yMax ?? connectionTimeDefaultSettings.yMax,
                    target: widgetSettings?.target ?? connectionTimeDefaultSettings.target
                })),
                switchMap(widgetSettings => {
                    return this.store.select(UnitsSelectors.getUnit(WellKnownUnitGroupIds.Time, widgetSettings.timeUnitId)).pipe(
                        map(timeUnit => ({
                            ...widgetSettings,
                            timeUnit: timeUnit as UnitDescriptor
                        }))
                    );
                })
            )
        ]).pipe(
            untilDestroyed(this)
        ).subscribe(([widgetData, widgetSettings]) => {
            if (!this.chart) {
                return;
            }

            this._data = widgetData;
            this._timeUnit = widgetSettings.timeUnit;

            this.updateData(widgetSettings);

            this._dataLoading$.next(false);
        });
    }

    readonly options = this.getOptions();
    readonly dataLoading$ = this._dataLoading$.asObservable();

    readonly average$ = this._average$.asObservable();
    readonly target$: Observable<string>;

    readonly placeholderColumns = new Array(10).fill(0).map(() => Math.floor(Math.random() * 9) + 1);

    @Input() id = '-';
    @Input() size = WidgetSize.Medium;

    @ViewChild(ClChartComponent, { static: true }) chart?: ClChartComponent;

    ngAfterViewInit(): void {
        if (!this.chart) {
            throw new Error('<cyberloop-chart> component should be present');
        }
    }

    ngOnChanges(changes: SimpleChangesOf<ConnectionTimeWidgetComponent>): void {
        if (changes.id) {
            this._id$.next(changes.id.currentValue);
        }
    }

    async onSettings(): Promise<void> {
        await this.settings.showSettings<ConnectionTimeWidgetSettings>(
            this.id,
            SettingsComponent,
            connectionTimeDefaultSettings
        );
    }

    onDelete(): void {
        this.store.dispatch(KpiActions.deleteWidget({ widgetId: this.id }));
    }

    private getOptions(): Highcharts.Options {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        return {
            chart: {
                type: 'column',
                marginRight: 0,
                marginBottom: 72,
                marginLeft: 33,

                events: {
                    load: function () {
                        // Legend Items Hover

                        this.legend.allItems.forEach(item => {
                            // Here we'll add mouse event handlers to our
                            // legend items, so when we hover over our custom
                            // items (Average, Target), we'll highlight the
                            // hovered plot line. Otherwise we'll fade them.

                            const group = ((item as Highcharts.Series).legendItem as any)?.group as Highcharts.SVGElement;

                            if (!group) {
                                return;
                            }

                            Highcharts.addEvent(group.element, 'mouseover', () => {
                                const s = item as Highcharts.Series;

                                self.fadeOutPlotLines();
                                self.fadeInPlotLines(s.name.toLowerCase());
                            });
                            Highcharts.addEvent(group.element, 'mouseout', () => {
                                self.fadeInPlotLines();
                            });
                        });
                    },
                    render: function () {
                        // A hack to set the plot area radius
                        (this as any).plotBackground.attr({ rx: 6, ry: 6 });
                    }
                }
            },
            xAxis: {
                categories: [],
                minPadding: 0,
                maxPadding: 0,
                labels: {
                    y: 0,
                    autoRotation: undefined,
                    allowOverlap: true,
                    formatter: function (ctx: Highcharts.AxisLabelsFormatterContextObject) {
                        const labelsStep = self.getLabelsStep(ctx.axis);

                        if (labelsStep > 1 && (ctx.pos + 1) % labelsStep !== 0) {
                            return '';
                        }

                        const m = moment(parseInt(String(ctx.value), 10));

                        return `<span class="date">${m.format('D.MM')}</span><br><span class="time">${m.format('H:mm')}</span>`;
                    },
                    style: {
                        textOverflow: 'none'
                    }
                }
            },
            yAxis: {
                id: 'main',
                title: {
                    text: ''
                },
                labels: {
                    x: -8,
                    formatter: function () {
                        return CompactNumberFormatter.format(Math.round(this.pos));
                    }
                }
            },
            legend: {
                enabled: true,
                floating: false,
                align: 'center',
                verticalAlign: 'bottom',
                x: 0,
                y: 21,
                itemMarginTop: 5,
                itemMarginBottom: 5,
                alignColumns: false,
                symbolWidth: 8,
                symbolHeight: 8,
                symbolPadding: 10
            },
            tooltip: {
                valueDecimals: 2,
                padding: 0,
                useHTML: true,
                formatter: formatTooltipWithDateAndTime
            },
            plotOptions: {
                column: {
                    stacking: 'normal',

                    events: {
                        // Same here: on series hover we'll fade out our lines
                        mouseOver: function () {
                            self.fadeOutPlotLines();
                        },
                        mouseOut: function () {
                            self.fadeInPlotLines();
                        }
                    }
                },
                line: {
                    marker: {
                        enabled: true,
                        radius: 3
                    },

                    events: {
                        // Same here: on series hover we'll fade out our lines
                        mouseOver: function () {
                            self.fadeOutPlotLines();
                        },
                        mouseOut: function () {
                            self.fadeInPlotLines();
                        },

                        // Show/Hide plot lines on legend items click
                        legendItemClick: function () {
                            const id = this.name.toLowerCase();
                            const line = self.chart?.getYAxisPlotLineOrBand(id);

                            if (!line) {
                                return;
                            }

                            if (this.visible) {
                                line.svgElem.css({
                                    display: 'none'
                                });
                            }
                            else {
                                line.svgElem.css({
                                    display: 'block'
                                });
                            }
                        }
                    }
                }
            },
            series: [
                {
                    id: 'w2s',
                    name: 'w2s',
                    type: 'column',
                    colorIndex: 1,
                    data: []
                },
                {
                    id: 's2s',
                    name: 's2s',
                    type: 'column',
                    colorIndex: 2,
                    data: []
                },
                {
                    id: 's2w',
                    name: 's2w',
                    type: 'column',
                    colorIndex: 3,
                    data: []
                },
                {
                    id: 'total',
                    name: 'Total',
                    type: 'line',
                    colorIndex: 4,
                    data: []
                },

                // Dummy series just to have legend elements

                {
                    name: 'Target',
                    type: 'line',
                    colorIndex: 5,
                    data: []
                },
                {
                    name: 'Average',
                    type: 'line',
                    colorIndex: 6,
                    data: []
                }
            ]
        };
    }

    private fadeOutPlotLines(): void {
        for (const line of this.chart?.getYAxisPlotLinesAndBands() ?? []) {
            line.svgElem.css({
                opacity: 0.2
            });
        }
    }

    private fadeInPlotLines(specificLineId?: string): void {
        if (specificLineId) {
            this.chart?.getYAxisPlotLineOrBand(specificLineId)?.svgElem.css({
                opacity: 1
            });
        }
        else {
            for (const line of this.chart?.getYAxisPlotLinesAndBands() ?? []) {
                line.svgElem.css({
                    opacity: 1
                });
            }
        }
    }

    private updateData(settings: InternalSettings): void {
        if (!this._data || !this.chart) {
            return;
        }

        // Prepare the data for each series

        // Categories represented as an array of timestamps
        const categories: string[] = [];

        const w2sData: number[] = [];
        const s2sData: number[] = [];
        const s2wData: number[] = [];
        const totalData: number[] = [];
        let totalMax = 0;

        for (const item of this._data.connections) {
            categories.push(item.startDate.getTime().toString());

            w2sData.push(this.getConvertedValue(item.w2s));
            s2sData.push(this.getConvertedValue(item.s2s));
            s2wData.push(this.getConvertedValue(item.s2w));

            const total = this.getConvertedValue(item.w2s + item.s2s + item.s2w);
            totalData.push(total);

            if (total > totalMax) {
                totalMax = total;
            }
        }

        const average = totalData.length > 0
            ? totalData.reduce((sum, val) => sum + val, 0) / totalData.length
            : 0;

        this._average$.next(
            totalData.length > 0
                ? formatNumber(average, this.locale, '1.0-2')
                : 'N/A'
        );

        // Set categories

        this.chart.xAxis[0].setCategories(categories);

        // Set data

        this.chart.setSeriesData('w2s', w2sData);
        this.chart.setSeriesData('s2s', s2sData);
        this.chart.setSeriesData('s2w', s2wData);
        this.chart.setSeriesData('total', totalData);

        // Update plot lines

        const plotLines: Highcharts.YAxisPlotLinesOptions[] = [
            {
                id: PLOT_LINE_AVERAGE_ID,
                value: average,
                className: 'line-average',
                zIndex: 4
            }
        ];

        if (typeof settings.target !== 'undefined') {
            plotLines.push({
                id: PLOT_LINE_TARGET_ID,
                value: this.getConvertedValue(settings.target),
                className: 'line-target',
                zIndex: 4
            });
        }

        // Set Y axis options

        const min = settings.yMin ? this.getConvertedValue(settings.yMin) : 0;
        const max = settings.yMax ? this.getConvertedValue(settings.yMax) : totalMax * 1.15;

        const tickPositions: number[] = [
            min
        ];

        for (let i = 1; i < (Y_TICKS_COUNT - 1); i++) {
            tickPositions.push((max - min) * i / (Y_TICKS_COUNT - 1) + min);
        }

        tickPositions.push(max);

        // This will also redraw the chart
        this.chart.update({
            yAxis: [{
                id: 'main',
                min: settings.yMin ? this.getConvertedValue(settings.yMin) : settings.yMin,
                max: settings.yMax ? this.getConvertedValue(settings.yMax) : settings.yMax,
                tickPositions,
                plotLines
            }]
        });
    }

    private getConvertedValue(source: number): number {
        return this._timeUnit
            ? this.unitsConverter.convertToUnit(source, this._timeUnit)
            : source;
    }

    private getLabelsStep(axis: Highcharts.Axis): number {
        const extremes = axis.getExtremes();
        return Math.round(extremes.max / X_LABELS_MAX_COUNT);
    }

}
