import { Points, UnitDescriptor, UnitGroup } from 'libs/core/src/lib/models';

import type { Observable } from 'rxjs';
/** Provides data for current viewport */
export abstract class ViewPortDataProvider {
    /**
     * Get points for current viewport
     * @param wellId Well identifier
     * @param tagId Tag to get data for
     */
    abstract getByTag(wellId: string, tagId: string, unitGroup: UnitGroup['id'], unit?: Observable<UnitDescriptor['id'] | undefined>):Observable<Points>;

    /**
     * Reset cache
     */
    abstract reset(): void;
    
}