import { kebabCase } from 'lodash';

/**
 * Rig states used by system
 */
export enum RigState {
    /** This is used for any unknown rig state */
    Unknown = 0,

    /** Rotary Drilling */
    DrillingRotaryDrilling = 1,
    /** Reaming Out */
    DrillingReamingOut = 2,
    /** Pumping Out */
    DrillingPumpingOut = 3,
    /** Pumping In */
    DrillingPumpingIn = 4,
    /** Slide Drilling */
    DrillingSlideDrilling = 5,
    /** Reaming In */
    DrillingReamingIn = 6,
    /** Service Rig */
    DrillingServiceRig = 7,
    /** Breaking Connection */
    DrillingBreakingConnection = 8,
    /** Making Connection */
    DrillingMakingConnection = 9,
    /** Circulating */
    DrillingCirculating = 10,
    /** Pool Out Of Hole */
    DrillingPullOutOfHole = 11,
    /** Rotate Circulating */
    DrillingRotateCirculating = 12,
    /** Run In Hole */
    DrillingRunInHole = 13,
    /** Out */
    DrillingOut = 14,
    /** On Bottom Circulate */
    DrillingOnBottomCirculate = 15,
    /** Connection */
    DrillingConnection = 16,


    /** Casing Service Rig */
    CasingServiceRig = 101,
    /** Casing Breaking Connection */
    CasingBreakingConnection = 102,
    /** Casing Making Connection */
    CasingMakingConnection = 103,
    /** Casing Circulating */
    CasingCirculating = 104,
    /** Casing Pool Out Of Hole */
    CasingPullOutOfHole = 105,
    /** Casing Run In Hole */
    CasingRunInHole = 106,
    /** Casing Connection */
    CasingConnection = 107,
    /** Casing Pumping In */
    CasingPumpingIn = 108,
    /** Casing Pumping Out */
    CasingPumpingOut = 109,

    /** Cementing */
    Cementing = 201,
    /** Wireline */
    Wireline = 301,
    /** RigMove */
    RigMove = 401,
    /** BopTesting */
    BopTesting = 501
}

// TODO move to service
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RigState {
    /**
     * Get human readable name of rig state
     * @param state Rig state
     * @returns rig state name or unknown if state if not known
     */
    export function getName(state: RigState) {
        const rs = RIG_STATE_NAME[state];
        if (!rs) {
            return RIG_STATE_NAME[RigState.Unknown];
        }

        return rs;

    }

    /**
     * Get kebab-case id of rig state
     * @param state Rig state
     * @returns rig state id or unknown if state if not known
     */
    export function getId(state: RigState) {
        const name = RigState[state] ?? RigState[0];
        return kebabCase(name);
    }
}

/** @internal */
const RIG_STATE_NAME = {
    [RigState.Unknown]: 'Unknown',
    [RigState.DrillingRotaryDrilling]: 'Rotary Drilling',
    [RigState.DrillingReamingOut]: 'Reaming out',
    [RigState.DrillingPumpingOut]: 'Pumping out',
    [RigState.DrillingPumpingIn]: 'Pumping in',
    [RigState.DrillingSlideDrilling]: 'Slide Drilling',
    [RigState.DrillingReamingIn]: 'Reaming in',
    [RigState.DrillingServiceRig]: 'Service Rig',
    [RigState.DrillingBreakingConnection]: 'Breaking Connection',
    [RigState.DrillingMakingConnection]: 'Making Connection',
    [RigState.DrillingCirculating]: 'Circulating',
    [RigState.DrillingRotateCirculating]: 'Rotate Circulating',
    [RigState.DrillingPullOutOfHole]: 'POOH',
    [RigState.DrillingRunInHole]: 'RIH',
    [RigState.DrillingOut]: 'Drilling Out',
    [RigState.DrillingOnBottomCirculate]: 'On Bottom Circulating',
    [RigState.DrillingConnection]: 'Connection / Change JNT',

    [RigState.CasingServiceRig]: 'Service Rig',
    [RigState.CasingBreakingConnection]: 'Breaking Connection',
    [RigState.CasingMakingConnection]: 'Making Connection',
    [RigState.CasingCirculating]: 'Circulating',
    [RigState.CasingPullOutOfHole]: 'POOH',
    [RigState.CasingRunInHole]: 'RIH',
    [RigState.CasingConnection]: 'Connection',
    [RigState.CasingPumpingIn]: 'Pumping in',
    [RigState.CasingPumpingOut]: 'Pumping out',

    [RigState.Cementing]: 'Cementing',
    [RigState.Wireline]: 'Wireline',
    [RigState.RigMove]: 'Rig Move',
    [RigState.BopTesting]: 'BOP Testing'
}