import { isEqual } from 'lodash';
import { Observable, Subject, debounceTime, distinctUntilChanged, finalize, map, share } from 'rxjs';

/**
 * Return observable of IntersectionObserverEntry
 * @param element Element to observe
 * @param opts Observation options
 */
function observeIntersection(element: HTMLElement, opts?: IntersectionObserverInit): Observable<IntersectionObserverEntry>;
/**
 * Return observable of Array of IntersectionObserverEntry
 * @param elements Elements to observe
 * @param opts Observation options
 */
function observeIntersection(elements: HTMLElement[], opts?: IntersectionObserverInit): Observable<IntersectionObserverEntry[]>;
/** @private */
function observeIntersection(elements: HTMLElement[] | HTMLElement, opts?: IntersectionObserverInit): any {
    const subj = new Subject<IntersectionObserverEntry[]>();
    if (!Array.isArray(elements)) {
        elements = [elements];
    }
    elements = [...elements as HTMLElement[]];
    const observer = new IntersectionObserver((entries => {
        subj.next(entries);
    }), opts);

    let data:Observable<any> = subj.pipe(
        finalize(() => {
            for (const element of elements as HTMLElement[]) {
                observer.unobserve(element);
            }

            observer.disconnect();
        }),
        debounceTime(100),
    );

    if (elements.length === 1) {
        data = data.pipe(map(x => x[0]));
    }

    for (const element of elements) {
        observer.observe(element);
    }

    return data.pipe(
        distinctUntilChanged((a, b) => isEqual(a, b)),
        share()
    );
}

export {
    observeIntersection
};