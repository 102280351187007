import type { UnitGroups, Units, UnitsPreset, UnitsPresets } from '../../models';

/** @internal */
export const UNITS_FEATURE = 'UNITS';

/**
 * @internal
 * State for units
 * */
export interface UnitsState {
    groups: UnitGroups;
    presets: UnitsPresets;

    userPresetLoadingState: 'NOT_LOADED' | 'LOADING' | 'LOADED';
    userPreset: UnitsPreset | undefined;
}


/** @internal */
export function getDefaultUnitsState(): UnitsState {
    return {
        groups: {},
        presets: {},

        userPresetLoadingState: 'NOT_LOADED',
        userPreset: undefined
    };
}
