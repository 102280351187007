import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';

import { ScrollShadowDirective } from '@cyberloop/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { interval, map } from 'rxjs';

import * as moment from 'moment';

import { MinSizeComponentComponent } from '../min-size-component/min-size-component.component';
import { SideMenuTriggerComponent } from '../side-menu-trigger/side-menu-trigger.component';
import { UserMenuTriggerComponent } from '../user-menu-trigger/user-menu-trigger.component';

import type { NavigationItem, RecentItemWithWellMeta } from '@cyberloop/web/app/model';
@Component({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cl-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        CommonModule,
        RouterModule,
        MatRippleModule,
        MinSizeComponentComponent,
        SideMenuTriggerComponent,
        UserMenuTriggerComponent,
        ScrollShadowDirective
    ]
})
@UntilDestroy()
export class AppComponent {
    @Input() appName: string | null = null;

    @Input() navigationItems: NavigationItem[] | null = [];
    @Input() recentItems: RecentItemWithWellMeta[] | null = [];

    @Input() userAvatar: string | null = '';
    @Input() displayName: string | null = '';
    @Input() login: string | null = '';

    @Output() readonly logout = new EventEmitter<void>();

    @Output() readonly navigationRequest = new EventEmitter<string>();

    readonly time$ = interval(500).pipe(
        map(() => {
            const m = moment();
            return m.format(m.second() % 2 ? 'MMMM DD, HH:mm' : 'MMMM DD, HH mm');
        })
    );
}