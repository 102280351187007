<div [class.skeleton-section]="loading" class="element well">
    <span class="element-label">Well</span>
    <span [class.skeleton]="loading" class="element-text">{{ well }}</span>
</div>

<div class="divider"></div>

<div [class.skeleton-section]="loading" class="element rig">
    <span class="element-label">Rig</span>
    <span [class.skeleton]="loading" class="element-text">{{ rig }}</span>
</div>

<div class="divider"></div>

<div [class.skeleton-section]="loading" class="element start-date">
    <span class="element-label">Planned start</span>
    <time [class.skeleton]="loading" class="element-text">
        {{ plannedStartDate | datetime:'HH:mm dd.MM.YYYY':'UTC'}}
    </time>
</div>

<div class="divider"></div>

<nav class="nav grow">
    <button *ngFor="let link of nav" [routerLink]="link.routerLink" routerLinkActive="active"
        class="nav-link cl-button">
        <icon [name]="link.icon"></icon>
        <span class="label">{{ link.name }}</span>
    </button>
</nav>

<button (click)="onCollapse()" class="collapse-button cl-icon-button outlined">
    <icon name="arrow__rounded__up"></icon>
</button>