
import { CoreSelectors, WellKnownParams } from '@cyberloop/core';
import { customVsTimeDefaultSettings, getMomentsFromTimeRange } from '@cyberloop/web/wells/model';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, map, of, switchMap } from 'rxjs';

import { KpiSelectors } from '../../state/kpi/kpi.selectors';
import { KpiDataService } from '../kpi-data.service';
import { getPointsFromTagHistoryData } from './utils';

import type { CustomVsTimeWidgetData, CustomVsTimeWidgetSettings } from '@cyberloop/web/wells/model';
import type { TagHistoryInterval } from './utils';

export function getCustomVsTimeWidgetData(this: { store: Store, data: KpiDataService }, id: string): Observable<CustomVsTimeWidgetData> {
    return combineLatest([
        this.store.select(CoreSelectors.currentWell),
        this.store.select(KpiSelectors.widgetSettings<CustomVsTimeWidgetSettings>(id))
    ]).pipe(
        switchMap(([well, settings]) => {
            if (!well) {
                return EMPTY;
            }

            const tags: WellKnownParams[] = [];

            if (settings?.tag1) {
                tags.push(settings.tag1);
            }
            if (settings?.tag2) {
                tags.push(settings.tag2);
            }
            if (settings?.tag3) {
                tags.push(settings.tag3);
            }

            if (tags.length <= 0) {
                return of({
                    tag1: [],
                    tag2: [],
                    tag3: []
                });
            }

            const timeRangeObservable = getMomentsFromTimeRange(
                this.store,
                settings?.timeRange ?? customVsTimeDefaultSettings.timeRange,
                well
            );

            const step = 3600;

            return timeRangeObservable.pipe(
                switchMap(timeRange => this.data.watchTagTimeHistory(
                    well,
                    tags,
                    step,
                    timeRange.since.clone().startOf('second').toDate(),
                    timeRange.until.clone().startOf('second').toDate()
                )),
                map(historyData => {
                    let tag1Data: TagHistoryInterval[] = [];
                    let tag2Data: TagHistoryInterval[] = [];
                    let tag3Data: TagHistoryInterval[] = [];

                    for (const item of historyData) {
                        if (item.tagName === settings?.tag1) {
                            tag1Data = item.intervals;
                        }
                        else if (item.tagName === settings?.tag2) {
                            tag2Data = item.intervals;
                        }
                        else if (item.tagName === settings?.tag3) {
                            tag3Data = item.intervals;
                        }
                    }

                    const data: CustomVsTimeWidgetData = {
                        tag1: getPointsFromTagHistoryData(step, tag1Data),
                        tag2: getPointsFromTagHistoryData(step, tag2Data),
                        tag3: getPointsFromTagHistoryData(step, tag3Data)
                    };

                    return data;
                })
            );
        })
    );
}
