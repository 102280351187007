import { Params } from '@angular/router';

import { ID } from '@cyberloop/core';
import { WellsTypeFilters } from '@cyberloop/web/wells/model';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { APP_FEATURE, AppState, ROUTER_FEATURE } from './app.state';

const feature = createFeatureSelector<AppState>(APP_FEATURE);

const appsLoading = createSelector(feature, state => state.appsLoadingState === 'LOADING');
const appsLoaded = createSelector(feature, state => state.appsLoadingState === 'LOADED');
const appsNotLoaded = createSelector(feature, state => state.appsLoadingState === 'NOT_LOADED');
const apps = createSelector(feature, state => state.apps);

const recentItemsLoading = createSelector(feature, state => state.recentItemsLoadingState === 'LOADING');
const recentItemsLoaded = createSelector(feature, state => state.recentItemsLoadingState === 'LOADED');
const recentItemsNotLoaded = createSelector(feature, state => state.recentItemsLoadingState === 'NOT_LOADED');
const recentItems = createSelector(feature, state => state.recentItems);

const router = createFeatureSelector<RouterReducerState>(ROUTER_FEATURE);
const routeNestedParams = createSelector(router, (routerState) => {
    let currentRoute = routerState?.state?.root;
    let params: Params = {};
    while (currentRoute?.firstChild) {
        currentRoute = currentRoute.firstChild;
        params = {
            ...params,
            ...currentRoute.params
        };
    }
    return params;
});
const routeNestedParam = (param: string) =>
    createSelector<object, Params, ID>(routeNestedParams, (params) => params && params[param]);

const wellsFilters = createSelector(feature, state => state.wellsFilters);
const favorites = createSelector(feature, state => state.favorites);
const wellsDataIsLoaded = createSelector(feature, state => state.wellsDataIsLoaded);
const favoritesAreLoaded = createSelector(feature, state => state.favoritesAreLoaded);

const wellsWithData = createSelector(feature, (state) => {
    return state.wellsData.map(well => ({...well, favorite: state.favorites.includes(well.id)}));
});

const favoriteWells = createSelector(wellsWithData, (wells) => {
    return wells.filter(well => well.favorite);
});

const wellsWithDataFiltered = createSelector(wellsWithData, wellsFilters, (wells, filter) => {
    return wells.filter(well => {
        let rigFilterPassed = false;
        let typeFilterPassed = false;
        if(!filter.rig || filter.rig === 'all rigs' || filter.rig === well.rig) {
            rigFilterPassed = true;
        }

        if(!filter.type.length) {
            typeFilterPassed = true;
        }
        if(well.releaseTime && filter.type.includes(WellsTypeFilters.Released)) {
            typeFilterPassed = true;
        }
        if(!well.releaseTime && filter.type.includes(WellsTypeFilters.Active)) {
            typeFilterPassed = true;
        }
        if(well.favorite && filter.type.includes(WellsTypeFilters.Favorite)) {
            typeFilterPassed = true;
        }
        return rigFilterPassed && typeFilterPassed;
    });
});


/**
 * Public selectors for app
 * */
export const PublicAppSelectors = {
    appsLoading,
    appsLoaded,
    apps,

    recentItemsLoading,
    recentItemsLoaded,
    recentItems,

    routeNestedParams,
    routeNestedParam,

    wellsFilters,
    favorites,
    wellsDataIsLoaded,
    favoritesAreLoaded,
    favoriteWells,
    wellsWithDataFiltered
};

/**
 * Private selectors for app
 * */
export const AppSelectors = {
    ...PublicAppSelectors,
    appsLoading,
    appsLoaded,
    appsNotLoaded,
    apps,

    recentItemsLoading,
    recentItemsLoaded,
    recentItemsNotLoaded,
    recentItems
};

