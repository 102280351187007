import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { Subscription, filter } from 'rxjs';

import { HotkeysService } from '../services/hotkeys.service';

/** Handles hotkeys */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hotkey]',
    standalone: true
})
export class HotKeyDirective implements OnDestroy {
    /** @private */
    private _hotKey: string | undefined;

    /** @private */
    private _subscr: Subscription | undefined;

    /** @internal */
    constructor(
        private hk: HotkeysService,
        private vcr: ElementRef
    ) {
    }

    /** @internal */
    public get hotKey(): string|undefined {
        return this._hotKey;
    }

    /** Hotkey to monitor */
    @Input('hotkey')
    public set hotKey(value: string|undefined) {
        this._hotKey = value;

        this.stopListening();

        if (this._hotKey) {
            this._subscr = this.hk.addShortcut(this._hotKey)
                .pipe(
                    filter(() => !this.vcr.nativeElement?.disabled)
                )
                .subscribe(() => {
                    if (this.onHotkey.observed) {
                        this.onHotkey.emit();
                    }
                    else {
                        const el = this.vcr.nativeElement as HTMLElement;
                        el.click();
                    }
                });
        }
    }

    /** Fires when hotkey is pressed */
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() readonly onHotkey = new EventEmitter<void>();

    /** @internal */
    ngOnDestroy(): void {
        this.stopListening();
    }

    /** @internal */
    private stopListening() {
        if (this._subscr) {
            this._subscr.unsubscribe();
            this._subscr = undefined;
        }
    }
}
