<ul class="menu-items">
    <ng-container *ngIf="items && items.length>0; else noNavigationItems">
        <li class="menu-item" *ngFor="let item of items">
            <button matRipple [routerLink]="item.link" routerLinkActive="active" [disabled]="disabled" (click)="navigated.emit(item.link); $event.stopImmediatePropagation()">
                <svg class="icon" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <use [attr.href]="'assets/nav.svg#'+item.icon" fill="none" />
                </svg>

                <span class="title">{{item.text}}</span>
            </button>
        </li>
    </ng-container>

    <ng-template #noNavigationItems>
        <li class="warning">
            There are no apps available
        </li>
    </ng-template>
</ul>