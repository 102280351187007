import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import { ClCheckboxGroupComponent, FormatNumberPipe, FromSiPipe, IconComponent, NoDataPipe, Point, Trace, Traces, UnitDescriptor } from '@cyberloop/core';
import { Survey, Toolface } from '@cyberloop/web/wells/model';

import { DdChartComponent } from '../dd-chart/dd-chart.component';
import { DdDashboardCardComponent } from '../dd-dashboard-card/dd-dashboard-card.component';
import { DdGaugeComponent } from '../dd-gauge/dd-gauge.component';

@Component({
    selector: 'cyberloop-dd-dashboard',
    standalone: true,
    imports: [
        CommonModule,
        MatTableModule,
        DatePipe,
        DdGaugeComponent,
        AsyncPipe,
        DdChartComponent,
        DdDashboardCardComponent,
        ClCheckboxGroupComponent,
        IconComponent,
        FromSiPipe,
        NoDataPipe,
        FormatNumberPipe
    ],
    templateUrl: './dd-dashboard.component.html',
    styleUrls: ['./dd-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DdDashboardComponent {

    constructor() { }

    @Input() chartTypes: any[] = [];
    @Input() selectedChartType = '';
    @Input() surveyData: Survey[] | null = [];

    @Input() topChartData: Point[][] | null = null;
    @Input() topChartXAxisTitle = '';
    @Input() topChartYAxisTitle = '';

    @Input() bottomChartData: Point[][] | null = null;
    @Input() bottomChartXAxisTitle = '';
    @Input() bottomChartYAxisTitle = '';

    @Input() tableColumns: any[] = [];
    @Input() tfArray: Toolface[] | null = [];
    @Input() traces: Traces = [];
    @Input() angleUnit: UnitDescriptor | null | undefined = null;

    @Output() typeSelect = new EventEmitter<any[]>();
    @Output() addTrace = new EventEmitter<number>();
    @Output() removeTrace = new EventEmitter<string>();

    get displayedColumns() {
        return this.tableColumns.map(x => x.id);
    }

    get lastSurveyValue(): Survey | undefined {
        return (this.surveyData ?? [])[0];
    }

    get tracesNotEmpty() {
        return this.traces.length !== 0;
    }

    trackBySurveyNumber(index: number, item: Survey) {
        return item.number;
    }

    onAddTrace(index: number) {
        this.addTrace.emit(index);
    }

    onRemoveTrace(id: string) {
        this.removeTrace.emit(id);
    }
}
