<div class="head">
    <span class="col">
        file name
    </span>
    <span class="col">
        activity
    </span>
    <span class="col">
        user
    </span>
    <span class="col">
        date
    </span>
    <span class="col">
        tags
    </span>
    <span class="col">
    </span>
</div>

<div class="content">
    <ng-container *ngFor="let file of files; empty noData; last as last; index as index">
        <span class="col name">
            <span class="text-overflow">
                {{ file.name }}
            </span>
        </span>

        <span class="col">
            <ng-container *ngVar="file.activityId; let activityId">
                <span *ngIf="!isNil(activityId)" [ngClass]="RigActivity.getClassName(activityId)"
                    class="activity text-overflow">
                    {{ RigActivity.getName(activityId!) }}
                </span>
            </ng-container>
        </span>

        <span class="col">
            <span class="text-overflow">
                {{ file.createdBy }}
            </span>
        </span>

        <time class="col">
            {{ file.createdAt | datetime }}
        </time>

        <div class="col tags">
            <div *ngFor="let tag of file.tagIds" class="tag">{{ getTagName(tag) }}</div>
        </div>

        <div class="col actions">
            <button (click)="download.emit(file.id)" [disabled]="disableActions" class="file-download action cl-button">
                <icon name="download"></icon>
                <span class="label">download</span>
            </button>

            <ng-container *ngIf="!readonly">
                <div class="divider"></div>

                <button (click)="delete.emit(file.id)" [disabled]="disableActions" class="file-delete action cl-button">
                    <icon name="delete"></icon>
                    <span class="label">delete</span>
                </button>
            </ng-container>
        </div>

        <div *ngIf="!last" class="divider"></div>
    </ng-container>
    <ng-template #noData>
        <div class="no-data">No data</div>
    </ng-template>
</div>
