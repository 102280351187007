import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DatetimePipe, DurationPipe, IconComponent, NoDataPipe } from '@cyberloop/core';

import type { Duration, Moment } from 'moment';

@Component({
    selector: 'cyberloop-list-item',
    standalone: true,
    imports: [
        NgIf,
        IconComponent,
        DatetimePipe,
        DurationPipe,
        NoDataPipe
    ],
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemComponent {
    @Input() well!: string;
    @Input() rig?: string;
    @Input() plannedStartDate?: Moment | null;
    @Input() duration!: Duration;
    @Input() description?: string | null;

    @Output() readonly expand = new EventEmitter();
}
