import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { HcHelperService, IconComponent, Points } from '@cyberloop/core';
import { cloneDeep, isEqual } from 'lodash';

import * as Highcharts from 'highcharts';

@Component({
    selector: 'cyberloop-well-chart-dialog',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        IconComponent
    ],
    templateUrl: './well-chart-dialog.component.html',
    styleUrls: ['./well-chart-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WellChartDialogComponent implements AfterViewInit, OnDestroy {

    private _chart: Highcharts.Chart | undefined;

    private _current: Points = [];
    private _planned: Points = [];

    private _chartButton: Highcharts.SVGElement | undefined;
    private _loading = false;

    constructor(
        public chartDialogRef: MatDialogRef<WellChartDialogComponent>,
        private readonly hcHelper: HcHelperService,
        private readonly cdr: ChangeDetectorRef
    ) { }

    @Input() wellName = '';
    @ViewChild('chart', { static: true }) chartElementRef: ElementRef<HTMLElement> | undefined;

    public get current(): Points {
        return this._current;
    }

    public set current(value: Points) {
        value ||= [];
        if (!isEqual(value, this._current)) {
            this._current = cloneDeep(value);
            this.redraw();
        }
    }

    public get planned(): Points {
        return this._planned;
    }

    public set planned(value: Points) {
        value ||= [];
        if (!isEqual(value, this._planned)) {
            this._planned = cloneDeep(value);
            this.redraw();
        }
    }

    public get loading(): boolean {
        return this._loading;
    }

    public set loading(value: boolean) {
        this._loading = value;
        this.cdr.markForCheck();
    }

    ngAfterViewInit(): void {
        const el = this.chartElementRef?.nativeElement;

        if (!el) {
            return;
        }

        this._chart = this.hcHelper.create(el, this.getOptions());
    }

    private getOptions(): Highcharts.Options {

        const { min, max } = this.getExtremes();

        const res: Highcharts.Options = {

            chart: {
                type: 'area',
                styledMode: true,
                marginTop: 83,
                marginBottom: 68,
                marginLeft: 97,
                marginRight: 21
            },
            title: {
                text: this.wellName,
                align: 'left',
                x: 9,
                y: 30

            },
            subtitle: {
                text: 'Days vs Depth',
                align: 'left',
                x: 9,
                y: 48
            },
            legend: {
                enabled: true,
                navigation: {
                    enabled: false
                },
                width: 191,
                itemDistance: 10,
                x: 1,
                y: 1,
                align: 'left',
                symbolHeight: 4,
                symbolWidth: 4,
                symbolRadius: 2
            },
            xAxis: {
                labels: { enabled: true },
                title: {
                    text: 'DAYS',
                    align: 'middle'
                },
                type: 'datetime',
                showFirstLabel: true,
                showLastLabel: true,
                min,
                max
            },
            yAxis: {
                title: {
                    text: 'DEPTH',
                    align: 'middle'
                },
                labels: { enabled: true },
                tickInterval: 4,
                endOnTick: false,
                gridLineWidth: 1,
                reversed: true
            },
            tooltip: <Highcharts.TooltipOptions>{
                animation: false,
                enabled: true,
                shared: true,
                crosshairs: true,
                split: false,
                hideDelay: Number.MAX_SAFE_INTEGER,
                formatter: function () {

                    return this.points?.reduce((s, point) => {
                        
                        const valueY = point.y;
                        
                        return (
                            '<div class="chart-tooltip">'
                            + s +
                            `<div class="tooltip-${point.series.name.toLowerCase()}">` +point.series.name
                            + ':&nbsp;' +
                            Number(valueY)?.toFixed(2) +
                            '</div>'
                        );

                    }, '<b>' + new Date(this.x || 0).toLocaleString() + '</b><br/>');

                },
                useHTML: true,
                style: {
                    color: '#333'
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            defs: {
                gradient0: {
                    tagName: 'linearGradient',
                    attributes: {
                        id: 'gradient-0',
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    children: [{
                        tagName: 'stop',
                        attributes: {
                            offset: 0
                        }
                    }, {
                        tagName: 'stop',
                        attributes: {
                            offset: 1
                        }
                    }]
                }
            },
            series: [
                {
                    name: 'Current',
                    type: 'area',
                    data: this.current ?? []
                },
                {
                    type: 'area',
                    name: 'Planned',
                    data: this.planned ?? []
                }]
        };
        return res;
    }

    ngOnDestroy(): void {
        if (this._chart) {
            this._chart.destroy();
            this._chart = undefined;
        }
    }

    close() {
        this.chartDialogRef.close();
    }

    private redraw(): void {
        const chart = this._chart;

        if (!chart) {
            return;
        }

        chart.series[0]?.setData(this.current, true);
        chart.series[1]?.setData(this.planned, true);

        const { min, max } = this.getExtremes();

        chart.xAxis[0].setExtremes(min, max, true);
    }

    private getExtremes() {

        const xMin1 = this.current[0]?.x ?? Number.MAX_VALUE;
        const xMin2 = this.planned[0]?.x ?? Number.MAX_VALUE;

        const xMax1 = this.current[this.current.length - 1]?.x ?? Number.MIN_VALUE;
        const xMax2 = this.planned[this.planned.length - 1]?.x ?? Number.MIN_VALUE;

        const xMin = Math.min(xMin1, xMin2);
        const xMax = Math.max(xMax1, xMax2);

        if(Number.isSafeInteger(xMin) && Number.isSafeInteger(xMax)) {
            return { min: Math.min(xMin, xMax), max: Math.max(xMin, xMax) };
        }
        return { min: 0, max: 0};
    }
}
