import { Observable } from 'rxjs';

import { TenantInfo, User } from '../models';

/**  Base service for controlling user */
export abstract class AuthService {
    /**
     * Try to restore user info after app restart
     */
    abstract tryAutologin(): Promise<User | null>;

    /** Current user */
    abstract readonly currentUser$: Observable<User | null>;
    /** Access token change */
    abstract readonly tokenChanged$: Observable<string | null>;
    /**
     * Try login
     * @param login username
     * @param password password
     * @param tenant tenant to login as
     */
    abstract login(login: string, password: string, tenant?: string): Promise<User | TenantInfo[] | null>;
    /** Logout */
    abstract logout(): Promise<void>;

    /** Get Tenants list */
    abstract getTenantList(login: string): Promise<TenantInfo[]>;

    /** Send password reset email */
    abstract sendPasswordResetEmailByLoginAndTenantId(login: string, tenantId: string, link?: string): Promise<void>;
    /** Confirm password reset */
    abstract confirmPasswordReset(code: string, newPassword: string): Promise<void>;
}
