import { TagValueType } from '@cyberloop/core';

//#region Temp const
export const precision = [
    { id: 1, name: '1 decimal place' },
    { id: 2, name: '2 decimal places' },
    { id: 3, name: '3 decimal places' },
    { id: 4, name: '4 decimal places' },
    { id: 5, name: '5 decimal places' }
];

// TODO change units
export const depthSteps = [
    { id: 1, name: '0.1 m' },
    { id: 2, name: '0.2 m' },
    { id: 5, name: '0.5 m' },
    { id: 10, name: '1.0 m' }
];

// TODO change units
export const timeSteps = [
    { id: 1, name: '1 s' },
    { id: 5, name: '5 s' },
    { id: 10, name: '10 s' },
    { id: 60, name: '60 s' },
    { id: 360, name: '360 s' }
];

export const intervals = [
    { id: TagValueType.StartValue, name: '1st' },
    { id: TagValueType.AverageValue, name: 'Avg' },
    { id: TagValueType.MinimumValue, name: 'Min' },
    { id: TagValueType.MaximumValue, name: 'Max' }
];

export const preselectTimeIntervals = [
    { id: 0, name: 'Entire' },
    { id: 1, name: 'Last 1 hour' },
    { id: 2, name: 'Last 2 hours' },
    { id: 6, name: 'Last 6 hours' },
    { id: 12, name: 'Last 12 hours' },
    { id: 24, name: 'Last 24 hours' },
    { id: 48, name: 'Last 48 hours' },
    { id: 72, name: 'Last 72 hours' }
];
