import { Injectable } from '@angular/core';

import { NotificationService } from '@cyberloop/core';
import { FilesActions } from '@cyberloop/web/files/data';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { CoreSelectors } from 'libs/core/src/lib/state/core.selectors';
import { flatten } from 'lodash';
import { EMPTY, catchError, filter, first, map, of, switchMap } from 'rxjs';

import { MeterageService } from '../../services/meterage.service';
import { MeterageActions } from './meterage.actions';
import { MeterageSelectors } from './meterage.selectors';
import { METERAGE_FEATURE } from './meterage.state';

const initAction = createAction(`[${METERAGE_FEATURE}] Initialize meterage`);

@Injectable()
export class MeterageEffects implements OnInitEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly service: MeterageService,
        private readonly notify: NotificationService
    ) { }

    readonly getMeterageMeta$ = createEffect(() => this.actions$.pipe(
        ofType(initAction),
        switchMap(() => this.store.select(MeterageSelectors.meterageNotLoaded)),
        filter(Boolean),
        map(() => MeterageActions.loadMeterage())
    ));

    /**
     * Performs recent loading
     */
    readonly onLoadMeterage$ = createEffect(() => this.actions$.pipe(
        ofType(MeterageActions.loadMeterage),
        switchMap(() => this.store.select(MeterageSelectors.meterageNotLoaded)),
        filter(notLoaded => !notLoaded),
        switchMap(() => this.store.select(CoreSelectors.currentWellId)),
        // Fetch the meta
        switchMap((wellId) => wellId ? this.service.getMeterage(wellId, true) : of(null)),
        // Dispatch the success
        map(meterage => MeterageActions.meterageLoaded({ meterage })),
        // Dispatch the error
        catchError(error => {
            this.notify.error(error);
            return EMPTY;
        })
    ));

    readonly loadMeterageFiles$ = createEffect(() => this.actions$.pipe(
        ofType(MeterageActions.meterageLoaded),
        map(({ meterage }) => meterage ? flatten(meterage.itemList.map(section => section.ncr)) : []),
        map(ids => FilesActions.loadListByIds({ ids }))
    ));

    readonly onAddRuns$ = createEffect(() => this.actions$.pipe(
        ofType(MeterageActions.addRun),
        switchMap(() => this.store.select(CoreSelectors.currentWellId).pipe(first())),
        switchMap((wellId) => wellId ? this.service.addRun(wellId) : EMPTY),
        catchError((error) => {
            this.notify.error(error);
            return EMPTY;
        })
    ), { dispatch: false });

    readonly onDelRuns$ = createEffect(() => this.actions$.pipe(
        ofType(MeterageActions.delRun),
        switchMap(() => this.store.select(CoreSelectors.currentWellId).pipe(first())),
        switchMap((wellId) => wellId ? this.service.removeLastRun(wellId) : EMPTY),
        catchError((error) => {
            this.notify.error(error);
            return EMPTY;
        })
    ), { dispatch: false });

    readonly onUpdateMeterage$ = createEffect(() => this.actions$.pipe(
        ofType(MeterageActions.updateMeterage),
        switchMap(({ itemList }) => this.store.select(MeterageSelectors.id).pipe(
            first(),
            filter(Boolean),
            switchMap((id) => this.service.updateMeterage(id, itemList))
        )),

        catchError(error => {
            this.notify.error(error);
            return EMPTY;
        })
    ), { dispatch: false });

    ngrxOnInitEffects(): Action {
        return initAction();
    }
}
