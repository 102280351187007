import { Observable } from 'rxjs';

import type { TndItem } from '@cyberloop/web/tnd/model';

/**
 * Provides T&D data
 */
export abstract class TndDataProvider {

    /**
     * Provides T&D data with changes
     */
    abstract watch(wellId: string, sectionId: number, refreshIntervalSec?: number): Observable<TndItem[]>;

}