import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { PlanningEffects } from './planning.effects';
import { planningReducer } from './planning.reducer';
import { PLANNING_FEATURE } from './planning.state';
import { PlanningVersionProviders } from './version/version-state.providers';

export const PlanningProviders = [
    provideEffects([PlanningEffects]),
    provideState(PLANNING_FEATURE, planningReducer),
    PlanningVersionProviders
];
