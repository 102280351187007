import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { filesTagReducer } from './tag.reducer';
import { FILES_TAG_FEATURE } from './tag.state';
import { FilesTagEffects } from './tags.effects';

export const FilesTagProviders = [
    provideEffects([FilesTagEffects]),
    provideState(FILES_TAG_FEATURE, filesTagReducer)
];
