<div class="icon" aria-hidden="true">
    <svg viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3.53553 4.94975L0 1.41421L1.41421 0L4.94975 3.53553L8.48528 0L9.89949 1.41421L6.36396 4.94975L4.94975 6.36396L3.53553 4.94975Z"
            fill="currentColor" />
    </svg>
</div>

<mat-select class="cl-select" [formControl]="ctrl" [placeholder]="placeholder" [multiple]="multiple" panelClass="cl-select-overlay">
    <mat-option *ngIf="!multiple" [value]="null">{{ defaultValueName }}</mat-option>
    <mat-option class="option" [ngClass]="{'selected': row === selected}" *ngFor="let row of options" [value]="row.id">
        {{ row.name }}
    </mat-option>
</mat-select>