import { AssetMetric, DashboardSettings, Widget, WidgetSettings } from '@cyberloop/web/wells/model';

export const KPI_FEATURE = 'KPI';

export type DataState = 'NOT_LOADED' | 'LOADED' | 'LOADING';

/**
 * State for KPI
 * */
export interface KpiState {
    dataState: DataState;
    error?: Error;

    widgets: Widget[];
    widgetSettings: Record<string, WidgetSettings>;
    dashboards: DashboardSettings[];
    activeDashboard: string | undefined;
    selectedMetrics: AssetMetric[];
}

export function getDefaultKpiState(): KpiState {
    return {
        dataState: 'LOADING',
        widgets: [],
        widgetSettings: {},
        dashboards: [],
        activeDashboard: undefined,
        selectedMetrics: []
    };
}
