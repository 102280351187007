import { Injectable } from '@angular/core';

import { ForecastRequest, ReportingProviderService } from '@cyberloop/core';
import { Buffer } from 'buffer';
import { map } from 'rxjs';

import { ForecastReportQuery } from '../queries/reporting/forecast-report.query';

@Injectable({
    providedIn: 'root'
})
export class ReportingProviderServiceImpl extends ReportingProviderService {
    /**
     *
     */
    constructor(
        private readonly forecastReportQuery: ForecastReportQuery
    ) {
        super();
    }

    getForecast(request: ForecastRequest) {
        return this.forecastReportQuery.fetch(request).pipe(
            map((response) => Buffer.from(response.data.getForecastDocument.file.data))
        );
    }
}
