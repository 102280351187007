import type { Well } from '@cyberloop/core';
import { CoreSelectors } from '@cyberloop/core';
import { Store } from '@ngrx/store';
import { Observable, map, of, switchMap, timer } from 'rxjs';

import * as moment from 'moment';

export enum WidgetSettingsTimeRangeType {
    /** A time range counting from the current time */
    TimeRange = 'time-range',
    /** The whole section time span */
    Section = 'section',
    /** A custom time range with start & end timestamps */
    Custom = 'custom'
}

/** @internal A base type for time ranges in widget settings */
export type WidgetSettingsTimeRangeBase = {
    type: WidgetSettingsTimeRangeType
};

/**
 * A time range in milliseconds - represents some amount of time without
 * start/end
 */
export type WidgetSettingsTimeRangeTimeRange = WidgetSettingsTimeRangeBase & {
    /** Time range type */
    type: WidgetSettingsTimeRangeType.TimeRange,
    /** Time range in milliseconds */
    range: number;
};

export enum WidgetSettingsTimeRanges {
    Hours_1 = '1hr',
    Hours_2 = '2hr',
    Hours_4 = '4hr',
    Hours_6 = '6hr',
    Hours_10 = '10hr',
    Hours_12 = '12hr',
    Hours_24 = '24hr',
    Hours_48 = '48hr',
}

export const widgetSettingsAllowedTimeRanges: Record<WidgetSettingsTimeRanges, { value:  number, label: string }> = {
    [WidgetSettingsTimeRanges.Hours_1]:  { value:  1 * 60 * 60 * 1000, label:  '1 hour' },
    [WidgetSettingsTimeRanges.Hours_2]:  { value:  2 * 60 * 60 * 1000, label:  '2 hours' },
    [WidgetSettingsTimeRanges.Hours_4]:  { value:  4 * 60 * 60 * 1000, label:  '4 hours' },
    [WidgetSettingsTimeRanges.Hours_6]:  { value:  6 * 60 * 60 * 1000, label:  '6 hours' },
    [WidgetSettingsTimeRanges.Hours_10]: { value: 10 * 60 * 60 * 1000, label: '10 hours' },
    [WidgetSettingsTimeRanges.Hours_12]: { value: 12 * 60 * 60 * 1000, label: '12 hours' },
    [WidgetSettingsTimeRanges.Hours_24]: { value: 24 * 60 * 60 * 1000, label: '24 hours' },
    [WidgetSettingsTimeRanges.Hours_48]: { value: 48 * 60 * 60 * 1000, label: '48 hours' }
};

/** Time range of the whole section */
export type WidgetSettingsTimeRangeSection = WidgetSettingsTimeRangeBase & {
    /** Time range type */
    type: WidgetSettingsTimeRangeType.Section,
    wellId: string;
    sectionId: number;
};

/**
 * A custom time range represented by `start` and `end` time points in
 * milliseconds
 */
export type WidgetSettingsTimeRangeCustom = WidgetSettingsTimeRangeBase & {
    /** Time range type */
    type: WidgetSettingsTimeRangeType.Custom,
    /** Start point in milliseconds */
    start: number,
    /** End point in milliseconds */
    end: number,
};

/**
 * A time range for widgets. Can be in 3 forms:
 *   `WidgetSettingsTimeRangeTimeRange` - an amount of time without start/end points
 *   `WidgetSettingsTimeRangeSection` - whole section time range
 *   `WidgetSettingsTimeRangeCustom` - a custom time range with start & end points
 */
export type WidgetSettingsTimeRange =
    WidgetSettingsTimeRangeTimeRange |
    WidgetSettingsTimeRangeSection |
    WidgetSettingsTimeRangeCustom;


export function isWidgetSettingsTimeRange(obj: unknown): obj is WidgetSettingsTimeRange {
    return (
        typeof (obj as any)?.type !== 'undefined' &&
        Object.values(WidgetSettingsTimeRangeType).includes((obj as any).type)
    );
}

const TIME_RANGE_REFRESH_INTERVAL = 30000;

export function getMomentsFromTimeRange(
    store: Store,
    timeRange: WidgetSettingsTimeRange,
    well?: Well
): Observable<{ since: moment.Moment, until: moment.Moment }> {
    let until: moment.Moment;

    switch (timeRange.type) {

        case WidgetSettingsTimeRangeType.TimeRange:
            return timer(0, TIME_RANGE_REFRESH_INTERVAL).pipe(
                map(() => {
                    until = moment();
                    let since = until.clone().subtract(timeRange.range, 'ms');

                    if (well && since.isBefore(well.startTime)) {
                        // Prevent from getting data from previous well
                        since = moment(well.startTime);
                    }

                    return { since, until };
                })
            );

        case WidgetSettingsTimeRangeType.Section:
            return store.select(CoreSelectors.getWellById(timeRange.wellId)).pipe(
                switchMap(well => {
                    if (!well) {
                        throw new Error(`Could not obtain well ${timeRange.wellId}`);
                    }

                    const section = well.sections.find(item => item.id === timeRange.sectionId);

                    if (!section) {
                        throw new Error(`Could not find section ${timeRange.sectionId} in well ${well.name}`);
                    }

                    if (section.endTime) {
                        return of({
                            since: moment(section.startTime),
                            until: moment(section.endTime)
                        });
                    }
                    else {
                        return timer(0, TIME_RANGE_REFRESH_INTERVAL).pipe(
                            map(() => {
                                return {
                                    since: moment(section.startTime),
                                    until: moment()
                                };
                            })
                        );
                    }
                })
            );

        case WidgetSettingsTimeRangeType.Custom:
            return of({
                since: moment(timeRange.start),
                until: moment(timeRange.end)
            });

        default:
            throw new Error(`Unsupported time range: ${timeRange}`);
    }
}

export function getMomentsFromWell(
    well: Well
): Observable<{ since: moment.Moment, until: moment.Moment }> {
    if (well.releaseTime?.getTime() || well.suspendTime?.getTime()) {
        return of({
            since: moment(well.startTime),
            until: moment(well.releaseTime ?? well.suspendTime)
        });
    }
    else {
        return timer(0, TIME_RANGE_REFRESH_INTERVAL).pipe(
            map(() => {
                return {
                    since: moment(well.startTime),
                    until: moment()
                };
            })
        );
    }
}

export function getMomentsFromSection(
    well: Well,
    sectionId?: number
): Observable<{ since: moment.Moment, until: moment.Moment }> {
    if (sectionId) {
        // A single section

        const section = well.sections.find(item => item.id === sectionId);

        if (!section) {
            throw new Error(`Could not find section ${sectionId} in well ${well.name}`);
        }

        if (section.endTime) {
            return of({
                since: moment(section.startTime),
                until: moment(section.endTime)
            });
        }
        else {
            return timer(0, TIME_RANGE_REFRESH_INTERVAL).pipe(
                map(() => {
                    return {
                        since: moment(section.startTime),
                        until: moment()
                    };
                })
            );
        }
    }
    else {
        // All sections

        return getMomentsFromWell(well);
    }
}
