import { Injectable } from '@angular/core';

import { DepthExportQueryInput, ExportDataService, TagsValue, TimeExportQueryInput } from '@cyberloop/core';
import { Observable, map } from 'rxjs';

import { ExportByDepthQuery } from '../queries/export-by-depth.query';
import { ExportByTimeQuery } from '../queries/export-by-time.query';

@Injectable({ providedIn: 'root'})
export class ExportDataServiceImpl extends ExportDataService {
    constructor(
        private readonly _exportByTime: ExportByTimeQuery,
        private readonly _exportByDepth: ExportByDepthQuery
    ) {
        super();
    }

    getExportDataByTime(input: TimeExportQueryInput): Observable<TagsValue[]> {
        return this._exportByTime.fetch(input).pipe(
            map(x => x?.data?.rig?.tags ?? [])
        );
    }

    getExportDataByDepth(input: DepthExportQueryInput): Observable<TagsValue[]> {
        return this._exportByDepth.fetch(input).pipe(
            map(x => x?.data?.rig?.tags ?? [])
        );
    }

}