
/**
 * Settings object of a T&D chart in the Wells section
 */
export type WellsTndChartSettings = {
    selectedSectionId?: string,
    selectedModelId?: string,
    activeModelId?: string
};

/**
 * Default settings of a T&D chart in the Wells section
 */
export const wellsTndChartDefaultSettings = {};
