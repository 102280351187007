import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';

import { ClChartComponent, HighchartsOptions, Point, SimpleChangesOf } from '@cyberloop/core';
import { isEqual } from 'lodash';
import { ReplaySubject, distinctUntilChanged, map } from 'rxjs';

const MAX_SERIES_COUNT = 18;

@Component({
    selector: 'cyberloop-dd-chart-3d',
    standalone: true,
    imports: [
        ClChartComponent
    ],
    templateUrl: './dd-chart-3d.component.html',
    styleUrls: ['./dd-chart-3d.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DdChart3DComponent implements AfterViewInit, OnChanges {

    private readonly _data$ = new ReplaySubject<Point[][]>(1);
    @Input() set data(values: Point[][] | null) {
        this._data$.next(values ?? []);
    }
    @Input() activatedSeries: number[] | null = null;

    @ViewChild(ClChartComponent, {static: true}) chart!: ClChartComponent;

    options = this.getOptions();
    chartIsInitialized = false;

    ngAfterViewInit(): void {
        this.initChart();
    }

    initChart() {
        if (!this.chart || this.chartIsInitialized) {
            return;
        }

        for (let index = 0; index < MAX_SERIES_COUNT; index++) {
            this.chart.addSeries(
                {type: 'scatter',  name: `series-${index}`, colorIndex: index },
                this._data$.pipe(
                    distinctUntilChanged((a, b) => isEqual(a, b)),
                    map((series) => series[index] ?? [])
                )
            );
        }

        this.chartIsInitialized = true;
        this.showActivatedSeries();
    }

    ngOnChanges(changes: SimpleChangesOf<DdChart3DComponent>):void {
        if(!isEqual(changes.activatedSeries?.currentValue, changes.activatedSeries?.previousValue)) {
            this.showActivatedSeries();
        }
    }

    showActivatedSeries() {
        if(this.activatedSeries && this.chartIsInitialized) {
            //Hide all series and show only activated
            for (const series of this.chart.series) {
                if(series.index !== 0) {
                    series.hide();
                }
                if(this.activatedSeries.includes(series.index)) {
                    series.show();
                }
            }
        }
    }

    private getOptions(): HighchartsOptions {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        return {
            chart: {
                marginTop: 0,
                marginBottom: 30,
                marginLeft: 70,
                marginRight: 0,
                spacingTop: 50,

                options3d: {
                    enabled: true,
                    alpha: 10,
                    beta: 10,
                    depth: 1500,
                    viewDistance: 10,
                    fitToPlot: true,
                    frame: {
                        bottom: { size: 1, color: 'rgba(0,0,0,0.1)' },
                        back: { size: 1, color: 'rgba(0,0,0,0.1)' },
                        left: { size: 1, color: 'rgba(0,0,0,0.1)' }
                    }
                }
            },
            zAxis: {
                //tickInterval: 1,
                minPadding: 0,
                maxPadding: 0,
                showFirstLabel: true,
                gridLineWidth: 1,
                title: {
                    text: 'EW'
                },
                reversed: true
            },
            xAxis: {
                //tickInterval: 1,
                minPadding: 0,
                maxPadding: 0,
                showEmpty: false,
                showFirstLabel: true,
                gridLineWidth: 1,
                title: {
                    text: 'NS'
                }
            },
            yAxis: {
                reversed: true,
                minPadding: 0.001,
                maxPadding: 0.001,
                showEmpty: false,
                showFirstLabel: false,
                showLastLabel: false,
                title: {
                    text: 'TVD'
                }
            },
            plotOptions: {
                series: {
                    lineWidth: 1,
                    softThreshold: false,
                    marker: {
                        enabled: false,
                        symbol: 'circle'
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormatter() {
                            return `NS: ${this.x} <br> TVD: ${this.y} <br> EW: ${(this as any).z}`;
                        }
                    }
                }
            },
            disableUpdateExtremes: true
        };
    }

}
