<div class="cl-input">
    <ng-content select="[prefix]"></ng-content>
    <input type="text" *ngIf="forceText$|async; else standardInput" [class]="alignment" [placeholder]="placeholder"
        [formControl]="ctrl" [readonly]="readonly" autocomplete="off">
    <ng-template #standardInput>
        <input [type]="type" [class]="alignment" [placeholder]="placeholder ?? ''" [formControl]="ctrl"
            [readonly]="readonly" autocomplete="off">
    </ng-template>

    <ng-content select="[suffix]"></ng-content>

    <button *ngIf="type === 'password'" class="password-hint" (click)="togglePasswordVisibility()" type="button">
        <svg viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.2667 10.9667C11.3711 10.9667 12.3083 10.5817 13.0783 9.81167C13.8483 9.04167 14.2333 8.10444 14.2333 7C14.2333 5.89556 13.8483 4.95833 13.0783 4.18833C12.3083 3.41833 11.3711 3.03333 10.2667 3.03333C9.16222 3.03333 8.225 3.41833 7.455 4.18833C6.685 4.95833 6.3 5.89556 6.3 7C6.3 8.10444 6.685 9.04167 7.455 9.81167C8.225 10.5817 9.16222 10.9667 10.2667 10.9667ZM10.2667 9.61333C9.53556 9.61333 8.91722 9.36056 8.41167 8.855C7.90611 8.34944 7.65333 7.73111 7.65333 7C7.65333 6.26889 7.90611 5.65056 8.41167 5.145C8.91722 4.63944 9.53556 4.38667 10.2667 4.38667C10.9978 4.38667 11.6161 4.63944 12.1217 5.145C12.6272 5.65056 12.88 6.26889 12.88 7C12.88 7.73111 12.6272 8.34944 12.1217 8.855C11.6161 9.36056 10.9978 9.61333 10.2667 9.61333ZM10.2667 14C7.99556 14 5.94222 13.3544 4.10667 12.0633C2.27111 10.7722 0.902222 9.08444 0 7C0.902222 4.91556 2.27111 3.22778 4.10667 1.93667C5.94222 0.645555 7.99556 0 10.2667 0C12.5378 0 14.5911 0.645555 16.4267 1.93667C18.2622 3.22778 19.6311 4.91556 20.5333 7C19.6311 9.08444 18.2622 10.7722 16.4267 12.0633C14.5911 13.3544 12.5378 14 10.2667 14ZM10.2667 12.6C12.1489 12.6 13.8794 12.0906 15.4583 11.0717C17.0372 10.0528 18.2389 8.69556 19.0633 7C18.2389 5.30444 17.0372 3.94722 15.4583 2.92833C13.8794 1.90944 12.1489 1.4 10.2667 1.4C8.38445 1.4 6.65389 1.90944 5.075 2.92833C3.49611 3.94722 2.28667 5.30444 1.44667 7C2.28667 8.69556 3.49611 10.0528 5.075 11.0717C6.65389 12.0906 8.38445 12.6 10.2667 12.6Z" />
        </svg>

    </button>
</div>