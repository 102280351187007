import { Injectable } from '@angular/core';

import { DepthExportQueryInput, ExportDataResponse, SectionForDepthExport, TagValue, TagsValue } from '@cyberloop/core';
import { Observable, map } from 'rxjs';

import { GraphQLClient } from '../internals/cl-gql/client';
import { QueryResult } from '../internals/cl-gql/models';

type ExportDataResponseDto = {
    rig: {
        tags: TagsDataDto[];
    }
}

type TagsDataDto = {
    name: string;
} & {
    [section: string]: TagDataDto[]
}

type TagDataDto = {
    startDepth: number;
    startTime: string;
    startValue: number;
    avgValue: number;
    minValue: number;
    maxValue: number;
}



@Injectable({ providedIn: 'root' })
export class ExportByDepthQuery {
    constructor(private readonly _client: GraphQLClient) { }
    public fetch(input: DepthExportQueryInput): Observable<QueryResult<ExportDataResponse>> {
        const { rigName, wellId, step, tags, intervals, sections } = input;
        return this._client.query<any, { rigName: string, tags: string[] }>({
            query: `query export($rigName: String!, $tags:[TagName!]!) {
                        rig(name: $rigName) {
                            ... on Rig {
                                tags(only: $tags) {
                                    name
                                    ${sections.map(section => this.getSectionHistory(wellId, section, step, intervals))}
                                }
                            }
                        }
                    }`,
            variables: { rigName, tags }
        }).pipe(
            map(x => {
                if (x.loading || x.error || x.errors) {
                    return x;
                }

                return this.getPatchResponse(x);
            }));
    }

    private getSectionHistory(wellId: number, section: SectionForDepthExport, step: number, intervals: string[]) {
        return `
            ${section.name}: depthHistory(wellId: ${wellId}, sectionNo: ${section.id}, 
                from: ${section.from}, to: ${section.to}, step: ${step}) {
                startDepth
                ${intervals.join('\n')}
            }
        `;
    }

    private getPatchResponse(x: QueryResult<ExportDataResponseDto>): QueryResult<ExportDataResponse> {
        return {
            data: this.mapData(x.data),
            loading: x.loading,
            networkStatus: x.networkStatus
        };
    }

    private mapData(x: ExportDataResponseDto): ExportDataResponse {
        const mapData: TagsValue[] = x.rig.tags.map(tag => {
            const newTag: TagsValue = { name: tag.name } as TagsValue;
            for (const section of Object.keys(tag)) {
                if (section === 'name') {
                    continue;
                }

                const newArr: TagValue[] = [];
                for (const item of tag[section]) {
                    newArr.push({ ...item, startTime: new Date(item.startTime).getTime() });
                }

                newTag[section] = newArr;
            }

            return newTag;
        });

        return { rig: { tags: mapData } };
    }
}