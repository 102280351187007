import { Injectable } from '@angular/core';

import { WellPlan } from '@cyberloop/web/wells/model';

import { GraphQLSubscription } from '../internals/cl-gql';

type QueryRequest = {
    wellID: string
}

type QueryResponse = {
    wellPlansSubscription: WellPlan[];
}

@Injectable({
    providedIn: 'root'
})
export class WellPlansSubscription extends GraphQLSubscription<QueryResponse, QueryRequest> {
    override readonly document = `subscription wellplanQuery($wellID: String!) {
        wellPlansSubscription(wellId: $wellID) {
          id
          wellId
          name
          vsa
          vsaManually
          data {
            md
            ns
            ew
            dls
            inc
            azi
            tvd
            vsec
            hOffset
            thl
          }
          targets {
            comment
            inc
            azi
            tvd
            ns
            ew
            dls
            vsa
          }
        }
    }`;
}