import { Injectable } from '@angular/core';

import { ForecastEquipmentAndPersonnelEventRequest, ForecastEventRequest, ID, ReportingProviderService } from '@cyberloop/core';
import { CreateForecastEquipmentAndPersonnelEvent, CreateForecastEvent, Forecast, ForecastEquipmentAndPersonnelEvent, ForecastEvent, UpdateForecastEquipmentAndPersonnelEvent, UpdateForecastEvent } from '@cyberloop/web/wells/model';
import { Observable } from 'rxjs';

import { Moment } from 'moment';

import { ForecastProviderService } from './abstractions/forecast-provider.service';

@Injectable({ providedIn: 'root' })
export class ForecastDataService {
    constructor(
        private readonly forecastProviderService: ForecastProviderService,
        private readonly reportingProviderService: ReportingProviderService
    ) { }

    watchByWellId(wellId: ID): Observable<Forecast | null> {
        return this.forecastProviderService.watchByWellId(wellId);
    }

    importFromWellPlanByWellID(wellId: ID): Observable<Forecast> {
        return this.forecastProviderService.importFromWellPlanByWellID(wellId);
    }

    //#region Event

    watchAllEvents(forecastId: ID): Observable<ForecastEvent[]> {
        return this.forecastProviderService.watchAllEvents(forecastId);
    }

    createEvent(forecastId: ID, createEvent: CreateForecastEvent): Observable<ForecastEvent> {
        return this.forecastProviderService.createEvent(forecastId, createEvent);
    }

    updateEvent(forecastId: ID, eventId: ID, updateEvent: UpdateForecastEvent): Observable<ForecastEvent> {
        return this.forecastProviderService.updateEvent(forecastId, eventId, updateEvent);
    }

    expandToNextEvent(forecastId: ID, eventId: ID): Observable<ForecastEvent> {
        return this.forecastProviderService.expandToNextEvent(forecastId, eventId);
    }

    deleteEvent(forecastId: ID, eventId: ID): Observable<ForecastEvent> {
        return this.forecastProviderService.deleteEvent(forecastId, eventId);
    }

    //#endregion Event

    //#region Equipment and Personnel

    watchAllEquipmentAndPersonnelEvents(forecastId: ID): Observable<ForecastEquipmentAndPersonnelEvent[]> {
        return this.forecastProviderService.watchAllEquipmentAndPersonnelEvents(forecastId);
    }

    createEquipmentAndPersonnelEvent(forecastId: ID, createEquipmentAndPersonnel: CreateForecastEquipmentAndPersonnelEvent): Observable<ForecastEquipmentAndPersonnelEvent> {
        return this.forecastProviderService.createEquipmentAndPersonnelEvent(forecastId, createEquipmentAndPersonnel);
    }

    updateEquipmentAndPersonnelEvent(forecastId: ID, equipmentAndPersonnelId: ID, updateEquipmentAndPersonnel: UpdateForecastEquipmentAndPersonnelEvent) {
        return this.forecastProviderService.updateEquipmentAndPersonnelEvent(forecastId, equipmentAndPersonnelId, updateEquipmentAndPersonnel);
    }

    //#endregion Equipment and Personnel

    generateReport({
        wellName,
        startDate,
        estimatedRigReleaseDate,
        updatedAt,
        eventList,
        equipmentAndPersonnelEventList
    }: {
        wellName: string,
        startDate: Moment,
        estimatedRigReleaseDate: Moment,
        updatedAt: Moment,
        eventList: ForecastEvent[],
        equipmentAndPersonnelEventList: ForecastEquipmentAndPersonnelEvent[]
    }) {
        return this.reportingProviderService.getForecast({
            wellName,
            startDate: startDate.valueOf(),
            estimatedRigReleaseDate: estimatedRigReleaseDate.valueOf(),
            updatedAt: updatedAt.valueOf(),
            events: eventList.map((event): ForecastEventRequest => ({
                startDate: event.startDate.valueOf(),
                endDate: event.endDate.valueOf(),
                description: event.description,
                color: event.color
            })),
            equipmentAndPersonnelEvents: equipmentAndPersonnelEventList.map((event): ForecastEquipmentAndPersonnelEventRequest => ({
                date: event.date.valueOf(),
                description: event.description,
                direction: event.direction,
                type: event.type
            }))
        });
    }
}
