import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { range } from 'lodash';

@Component({
    selector: 'cyberloop-planning-stages-skeleton',
    standalone: true,
    imports: [NgFor],
    templateUrl: './planning-stages-skeleton.component.html',
    styleUrls: ['./planning-stages-skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningStagesSkeletonComponent {
    readonly skeletonCount = range(3);
}
